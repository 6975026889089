import acmilan from './acmilan.gif';
import bayer from './bayernmunchen.gif';
import benfica from './benfica.gif';
import dort from './dortmund.gif';
import inter from './intermilan.gif';
import manches from './manchestercity.gif';
import napoli from './napoli.gif';
import real from './realmadrid.gif';

export const NFT2 = {
    acmilan,
    bayer,
    benfica,
    dort,
    inter,
    manches,
    napoli,
    real,
};
