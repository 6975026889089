const router = {
    "abi": [
        {
            "members": [
                {
                    "name": "low",
                    "offset": 0,
                    "type": "felt"
                },
                {
                    "name": "high",
                    "offset": 1,
                    "type": "felt"
                }
            ],
            "name": "Uint256",
            "size": 2,
            "type": "struct"
        },
        {
            "data": [
                {
                    "name": "implementation",
                    "type": "felt"
                }
            ],
            "keys": [],
            "name": "Upgraded",
            "type": "event"
        },
        {
            "data": [
                {
                    "name": "previousAdmin",
                    "type": "felt"
                },
                {
                    "name": "newAdmin",
                    "type": "felt"
                }
            ],
            "keys": [],
            "name": "AdminChanged",
            "type": "event"
        },
        {
            "inputs": [
                {
                    "name": "factory",
                    "type": "felt"
                },
                {
                    "name": "proxy_admin",
                    "type": "felt"
                }
            ],
            "name": "initializer",
            "outputs": [],
            "type": "function"
        },
        {
            "inputs": [],
            "name": "factory",
            "outputs": [
                {
                    "name": "address",
                    "type": "felt"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "name": "tokenA",
                    "type": "felt"
                },
                {
                    "name": "tokenB",
                    "type": "felt"
                }
            ],
            "name": "sort_tokens",
            "outputs": [
                {
                    "name": "token0",
                    "type": "felt"
                },
                {
                    "name": "token1",
                    "type": "felt"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "name": "amountA",
                    "type": "Uint256"
                },
                {
                    "name": "reserveA",
                    "type": "Uint256"
                },
                {
                    "name": "reserveB",
                    "type": "Uint256"
                }
            ],
            "name": "quote",
            "outputs": [
                {
                    "name": "amountB",
                    "type": "Uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "name": "amountIn",
                    "type": "Uint256"
                },
                {
                    "name": "reserveIn",
                    "type": "Uint256"
                },
                {
                    "name": "reserveOut",
                    "type": "Uint256"
                }
            ],
            "name": "get_amount_out",
            "outputs": [
                {
                    "name": "amountOut",
                    "type": "Uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "name": "amountOut",
                    "type": "Uint256"
                },
                {
                    "name": "reserveIn",
                    "type": "Uint256"
                },
                {
                    "name": "reserveOut",
                    "type": "Uint256"
                }
            ],
            "name": "get_amount_in",
            "outputs": [
                {
                    "name": "amountIn",
                    "type": "Uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "name": "amountIn",
                    "type": "Uint256"
                },
                {
                    "name": "path_len",
                    "type": "felt"
                },
                {
                    "name": "path",
                    "type": "felt*"
                }
            ],
            "name": "get_amounts_out",
            "outputs": [
                {
                    "name": "amounts_len",
                    "type": "felt"
                },
                {
                    "name": "amounts",
                    "type": "Uint256*"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "name": "amountOut",
                    "type": "Uint256"
                },
                {
                    "name": "path_len",
                    "type": "felt"
                },
                {
                    "name": "path",
                    "type": "felt*"
                }
            ],
            "name": "get_amounts_in",
            "outputs": [
                {
                    "name": "amounts_len",
                    "type": "felt"
                },
                {
                    "name": "amounts",
                    "type": "Uint256*"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "name": "tokenA",
                    "type": "felt"
                },
                {
                    "name": "tokenB",
                    "type": "felt"
                },
                {
                    "name": "amountADesired",
                    "type": "Uint256"
                },
                {
                    "name": "amountBDesired",
                    "type": "Uint256"
                },
                {
                    "name": "amountAMin",
                    "type": "Uint256"
                },
                {
                    "name": "amountBMin",
                    "type": "Uint256"
                },
                {
                    "name": "to",
                    "type": "felt"
                },
                {
                    "name": "deadline",
                    "type": "felt"
                }
            ],
            "name": "add_liquidity",
            "outputs": [
                {
                    "name": "amountA",
                    "type": "Uint256"
                },
                {
                    "name": "amountB",
                    "type": "Uint256"
                },
                {
                    "name": "liquidity",
                    "type": "Uint256"
                }
            ],
            "type": "function"
        },
        {
            "inputs": [
                {
                    "name": "tokenA",
                    "type": "felt"
                },
                {
                    "name": "tokenB",
                    "type": "felt"
                },
                {
                    "name": "liquidity",
                    "type": "Uint256"
                },
                {
                    "name": "amountAMin",
                    "type": "Uint256"
                },
                {
                    "name": "amountBMin",
                    "type": "Uint256"
                },
                {
                    "name": "to",
                    "type": "felt"
                },
                {
                    "name": "deadline",
                    "type": "felt"
                }
            ],
            "name": "remove_liquidity",
            "outputs": [
                {
                    "name": "amountA",
                    "type": "Uint256"
                },
                {
                    "name": "amountB",
                    "type": "Uint256"
                }
            ],
            "type": "function"
        },
        {
            "inputs": [
                {
                    "name": "amountIn",
                    "type": "Uint256"
                },
                {
                    "name": "amountOutMin",
                    "type": "Uint256"
                },
                {
                    "name": "path_len",
                    "type": "felt"
                },
                {
                    "name": "path",
                    "type": "felt*"
                },
                {
                    "name": "to",
                    "type": "felt"
                },
                {
                    "name": "deadline",
                    "type": "felt"
                }
            ],
            "name": "swap_exact_tokens_for_tokens",
            "outputs": [
                {
                    "name": "amounts_len",
                    "type": "felt"
                },
                {
                    "name": "amounts",
                    "type": "Uint256*"
                }
            ],
            "type": "function"
        },
        {
            "inputs": [
                {
                    "name": "amountOut",
                    "type": "Uint256"
                },
                {
                    "name": "amountInMax",
                    "type": "Uint256"
                },
                {
                    "name": "path_len",
                    "type": "felt"
                },
                {
                    "name": "path",
                    "type": "felt*"
                },
                {
                    "name": "to",
                    "type": "felt"
                },
                {
                    "name": "deadline",
                    "type": "felt"
                }
            ],
            "name": "swap_tokens_for_exact_tokens",
            "outputs": [
                {
                    "name": "amounts_len",
                    "type": "felt"
                },
                {
                    "name": "amounts",
                    "type": "Uint256*"
                }
            ],
            "type": "function"
        }
    ],
    "entry_points_by_type": {
        "CONSTRUCTOR": [],
        "EXTERNAL": [
            {
                "offset": "0x3fd",
                "selector": "0x1296a38a65665d6f62a04f3a1ecf39f039c4e07898cd2549a802e43a33590b8"
            },
            {
                "offset": "0x516",
                "selector": "0x195dbe2353f8a41ea305d691a512bfd74e0c969f8254430ad6aad1f5dd2cc65"
            },
            {
                "offset": "0x453",
                "selector": "0x22db07240efd55449143fc6832df9627bb9e91ae79dd25445baafc8a6106a9a"
            },
            {
                "offset": "0x593",
                "selector": "0x2cfb12ff9e08412ec5009c65ea06e727119ad948d25c8a8cc2c86fec4adee70"
            },
            {
                "offset": "0x3ba",
                "selector": "0x2dd76e7ad84dbed81c314ffe5e7a7cacfb8f4836f01af4e913f275f89a3de1a"
            },
            {
                "offset": "0x615",
                "selector": "0x2e875d1c86df033547c5c7839d8b6e3641de29ee1f708bbce99743b34272ada"
            },
            {
                "offset": "0x480",
                "selector": "0x307013a8fee981262d9d46fc292ee37a6db7a025c5367dba0d6bfacb1078006"
            },
            {
                "offset": "0x694",
                "selector": "0x3276861cf5e05d6daf8f352cabb47df623eb10c383ab742fcc7abea94d5c5cc"
            },
            {
                "offset": "0x3dc",
                "selector": "0x366a98476020cb9ff8cc566d0cdeac414e546d2e7ede445f4e7032a4272c771"
            },
            {
                "offset": "0x4c6",
                "selector": "0x369f009830bb8f441fce54d2d19216c6ab4f99a3d96f496d136d6cc4e1f589c"
            },
            {
                "offset": "0x426",
                "selector": "0x38ad53218834f943da60c8bafd36c36692dcb35e6d76bdd93202f5c04c0baff"
            },
            {
                "offset": "0x713",
                "selector": "0x3b1bf5248b545038b97fc53525d5be840cf237a3faddfcaa7b9e4c8439fdaad"
            }
        ],
        "L1_HANDLER": []
    },
    "program": {
        "attributes": [
            {
                "accessible_scopes": [
                    "openzeppelin.upgrades.library",
                    "openzeppelin.upgrades.library.Proxy",
                    "openzeppelin.upgrades.library.Proxy.initializer"
                ],
                "end_pc": 701,
                "flow_tracking_data": {
                    "ap_tracking": {
                        "group": 41,
                        "offset": 23
                    },
                    "reference_ids": {}
                },
                "name": "error_message",
                "start_pc": 699,
                "value": "Proxy: contract already initialized"
            },
            {
                "accessible_scopes": [
                    "__main__",
                    "__main__",
                    "__main__.initializer"
                ],
                "end_pc": 944,
                "flow_tracking_data": {
                    "ap_tracking": {
                        "group": 55,
                        "offset": 0
                    },
                    "reference_ids": {}
                },
                "name": "error_message",
                "start_pc": 941,
                "value": "Router::constructor::factory can not be zero"
            },
            {
                "accessible_scopes": [
                    "__main__",
                    "__main__",
                    "__main__.remove_liquidity"
                ],
                "end_pc": 1528,
                "flow_tracking_data": {
                    "ap_tracking": {
                        "group": 106,
                        "offset": 51
                    },
                    "reference_ids": {}
                },
                "name": "error_message",
                "start_pc": 1526,
                "value": "Router::remove_liquidity::insufficient A amount"
            },
            {
                "accessible_scopes": [
                    "__main__",
                    "__main__",
                    "__main__.remove_liquidity"
                ],
                "end_pc": 1537,
                "flow_tracking_data": {
                    "ap_tracking": {
                        "group": 106,
                        "offset": 101
                    },
                    "reference_ids": {}
                },
                "name": "error_message",
                "start_pc": 1535,
                "value": "Router::remove_liquidity::insufficient B amount"
            },
            {
                "accessible_scopes": [
                    "__main__",
                    "__main__",
                    "__main__.swap_exact_tokens_for_tokens"
                ],
                "end_pc": 1627,
                "flow_tracking_data": {
                    "ap_tracking": {
                        "group": 112,
                        "offset": 57
                    },
                    "reference_ids": {}
                },
                "name": "error_message",
                "start_pc": 1625,
                "value": "Router::swap_exact_tokens_for_tokens::insufficient output amount"
            },
            {
                "accessible_scopes": [
                    "__main__",
                    "__main__",
                    "__main__.swap_tokens_for_exact_tokens"
                ],
                "end_pc": 1754,
                "flow_tracking_data": {
                    "ap_tracking": {
                        "group": 122,
                        "offset": 50
                    },
                    "reference_ids": {}
                },
                "name": "error_message",
                "start_pc": 1752,
                "value": "Router::swap_tokens_for_exact_tokens::excessive input amount"
            },
            {
                "accessible_scopes": [
                    "__main__",
                    "__main__._ensure_deadline"
                ],
                "end_pc": 1858,
                "flow_tracking_data": {
                    "ap_tracking": {
                        "group": 130,
                        "offset": 6
                    },
                    "reference_ids": {}
                },
                "name": "error_message",
                "start_pc": 1853,
                "value": "Router::_ensure_deadline::expired"
            },
            {
                "accessible_scopes": [
                    "__main__",
                    "__main__._add_liquidity"
                ],
                "end_pc": 1962,
                "flow_tracking_data": {
                    "ap_tracking": {
                        "group": 136,
                        "offset": 101
                    },
                    "reference_ids": {}
                },
                "name": "error_message",
                "start_pc": 1960,
                "value": "Router::_add_liquidity::insufficient B amount"
            },
            {
                "accessible_scopes": [
                    "__main__",
                    "__main__._add_liquidity"
                ],
                "end_pc": 2001,
                "flow_tracking_data": {
                    "ap_tracking": {
                        "group": 137,
                        "offset": 100
                    },
                    "reference_ids": {}
                },
                "name": "error_message",
                "start_pc": 1999,
                "value": "Router::_add_liquidity::insufficient A amount"
            },
            {
                "accessible_scopes": [
                    "__main__",
                    "__main__._quote"
                ],
                "end_pc": 2227,
                "flow_tracking_data": {
                    "ap_tracking": {
                        "group": 147,
                        "offset": 42
                    },
                    "reference_ids": {}
                },
                "name": "error_message",
                "start_pc": 2225,
                "value": "Router::_quote::insufficient amount"
            },
            {
                "accessible_scopes": [
                    "__main__",
                    "__main__._quote"
                ],
                "end_pc": 2249,
                "flow_tracking_data": {
                    "ap_tracking": {
                        "group": 147,
                        "offset": 122
                    },
                    "reference_ids": {}
                },
                "name": "error_message",
                "start_pc": 2245,
                "value": "Router::_quote::insufficient liquidity"
            },
            {
                "accessible_scopes": [
                    "__main__",
                    "__main__._get_amount_out"
                ],
                "end_pc": 2283,
                "flow_tracking_data": {
                    "ap_tracking": {
                        "group": 150,
                        "offset": 48
                    },
                    "reference_ids": {}
                },
                "name": "error_message",
                "start_pc": 2281,
                "value": "Router::_get_amount_out::insufficient input amount"
            },
            {
                "accessible_scopes": [
                    "__main__",
                    "__main__._get_amount_out"
                ],
                "end_pc": 2305,
                "flow_tracking_data": {
                    "ap_tracking": {
                        "group": 150,
                        "offset": 128
                    },
                    "reference_ids": {}
                },
                "name": "error_message",
                "start_pc": 2301,
                "value": "Router::_get_amount_out::insufficient liquidity"
            },
            {
                "accessible_scopes": [
                    "__main__",
                    "__main__._get_amount_in"
                ],
                "end_pc": 2370,
                "flow_tracking_data": {
                    "ap_tracking": {
                        "group": 155,
                        "offset": 46
                    },
                    "reference_ids": {}
                },
                "name": "error_message",
                "start_pc": 2368,
                "value": "Router::_get_amount_in::insufficient output amount"
            },
            {
                "accessible_scopes": [
                    "__main__",
                    "__main__._get_amount_in"
                ],
                "end_pc": 2392,
                "flow_tracking_data": {
                    "ap_tracking": {
                        "group": 155,
                        "offset": 126
                    },
                    "reference_ids": {}
                },
                "name": "error_message",
                "start_pc": 2388,
                "value": "Router::_get_amount_in::insufficient liquidity"
            },
            {
                "accessible_scopes": [
                    "__main__",
                    "__main__._get_amounts_out"
                ],
                "end_pc": 2456,
                "flow_tracking_data": {
                    "ap_tracking": {
                        "group": 160,
                        "offset": 1
                    },
                    "reference_ids": {}
                },
                "name": "error_message",
                "start_pc": 2450,
                "value": "Router::_get_amounts_out::invalid path"
            },
            {
                "accessible_scopes": [
                    "__main__",
                    "__main__._get_amounts_in"
                ],
                "end_pc": 2547,
                "flow_tracking_data": {
                    "ap_tracking": {
                        "group": 166,
                        "offset": 1
                    },
                    "reference_ids": {}
                },
                "name": "error_message",
                "start_pc": 2541,
                "value": "Router::_get_amounts_in::invalid path"
            }
        ],
        "builtins": [
            "pedersen",
            "range_check"
        ],
        "compiler_version": "0.10.3",
        "data": [
            "0x40780017fff7fff",
            "0x1",
            "0x208b7fff7fff7ffe",
            "0x20780017fff7ffd",
            "0x3",
            "0x208b7fff7fff7ffe",
            "0x480a7ffb7fff8000",
            "0x480a7ffc7fff8000",
            "0x480080007fff8000",
            "0x400080007ffd7fff",
            "0x482480017ffd8001",
            "0x1",
            "0x482480017ffd8001",
            "0x1",
            "0xa0680017fff7ffe",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffffb",
            "0x402a7ffc7ffd7fff",
            "0x208b7fff7fff7ffe",
            "0x20780017fff7ffd",
            "0x4",
            "0x400780017fff7ffd",
            "0x1",
            "0x208b7fff7fff7ffe",
            "0x48297ffd80007ffc",
            "0x20680017fff7fff",
            "0x4",
            "0x402780017ffc7ffc",
            "0x1",
            "0x208b7fff7fff7ffe",
            "0x400380007ffc7ffd",
            "0x482680017ffc8000",
            "0x1",
            "0x208b7fff7fff7ffe",
            "0x480a7ffb7fff8000",
            "0x48297ffc80007ffd",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffffb",
            "0x208b7fff7fff7ffe",
            "0x480280007ffb8000",
            "0x480280017ffb8000",
            "0x484480017fff8000",
            "0x2aaaaaaaaaaaab05555555555555556",
            "0x48307fff7ffd8000",
            "0x480280027ffb8000",
            "0x480280037ffb8000",
            "0x484480017fff8000",
            "0x4000000000000088000000000000001",
            "0x48307fff7ffd8000",
            "0xa0680017fff8000",
            "0xe",
            "0x480680017fff8000",
            "0x800000000000011000000000000000000000000000000000000000000000000",
            "0x48287ffc80007fff",
            "0x40307ffc7ff87fff",
            "0x48297ffd80007ffc",
            "0x482680017ffd8000",
            "0x1",
            "0x48507fff7ffe8000",
            "0x40507ff97ff57fff",
            "0x482680017ffb8000",
            "0x4",
            "0x208b7fff7fff7ffe",
            "0xa0680017fff8000",
            "0xc",
            "0x480680017fff8000",
            "0x800000000000011000000000000000000000000000000000000000000000000",
            "0x48287ffd80007fff",
            "0x48327fff7ffc8000",
            "0x40307ffa7ff67fff",
            "0x48527ffe7ffc8000",
            "0x40507ff97ff57fff",
            "0x482680017ffb8000",
            "0x4",
            "0x208b7fff7fff7ffe",
            "0x40317ffd7ff97ffd",
            "0x48297ffc80007ffd",
            "0x48527fff7ffc8000",
            "0x40507ffb7ff77fff",
            "0x40780017fff7fff",
            "0x2",
            "0x482680017ffb8000",
            "0x4",
            "0x208b7fff7fff7ffe",
            "0x48297ffd80007ffc",
            "0x20680017fff7fff",
            "0x4",
            "0x402780017ffc7ffc",
            "0x1",
            "0x480a7ffb7fff8000",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffcc",
            "0x208b7fff7fff7ffe",
            "0x480680017fff8000",
            "0x43616c6c436f6e7472616374",
            "0x400280007ff97fff",
            "0x400380017ff97ffa",
            "0x400380027ff97ffb",
            "0x400380037ff97ffc",
            "0x400380047ff97ffd",
            "0x482680017ff98000",
            "0x7",
            "0x480280057ff98000",
            "0x480280067ff98000",
            "0x208b7fff7fff7ffe",
            "0x480680017fff8000",
            "0x47657443616c6c657241646472657373",
            "0x400280007ffd7fff",
            "0x482680017ffd8000",
            "0x2",
            "0x480280017ffd8000",
            "0x208b7fff7fff7ffe",
            "0x480680017fff8000",
            "0x476574426c6f636b54696d657374616d70",
            "0x400280007ffd7fff",
            "0x482680017ffd8000",
            "0x2",
            "0x480280017ffd8000",
            "0x208b7fff7fff7ffe",
            "0x480680017fff8000",
            "0x53746f7261676552656164",
            "0x400280007ffc7fff",
            "0x400380017ffc7ffd",
            "0x482680017ffc8000",
            "0x3",
            "0x480280027ffc8000",
            "0x208b7fff7fff7ffe",
            "0x480680017fff8000",
            "0x53746f726167655772697465",
            "0x400280007ffb7fff",
            "0x400380017ffb7ffc",
            "0x400380027ffb7ffd",
            "0x482680017ffb8000",
            "0x3",
            "0x208b7fff7fff7ffe",
            "0x480680017fff8000",
            "0x456d69744576656e74",
            "0x400280007ff97fff",
            "0x400380017ff97ffa",
            "0x400380027ff97ffb",
            "0x400380037ff97ffc",
            "0x400380047ff97ffd",
            "0x482680017ff98000",
            "0x5",
            "0x208b7fff7fff7ffe",
            "0xa0680017fff8000",
            "0xa",
            "0x400380007ffc7ffd",
            "0x40780017fff7fff",
            "0x14",
            "0x482680017ffc8000",
            "0x1",
            "0x480680017fff8000",
            "0x1",
            "0x208b7fff7fff7ffe",
            "0xa0680017fff8000",
            "0xe",
            "0x484680017ffd8000",
            "0x800000000000011000000000000000000000000000000000000000000000000",
            "0x482480017fff8000",
            "0x800000000000011000000000000000000000000000000000000000000000000",
            "0x400280007ffc7fff",
            "0x40780017fff7fff",
            "0x11",
            "0x482680017ffc8000",
            "0x1",
            "0x480680017fff8000",
            "0x0",
            "0x208b7fff7fff7ffe",
            "0x480a7ffc7fff8000",
            "0x480680017fff8000",
            "0x100000000000000000000000000000000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffff79",
            "0x480680017fff8000",
            "0x0",
            "0x208b7fff7fff7ffe",
            "0x480a7ffb7fff8000",
            "0x48297ffc80007ffd",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffde",
            "0x208b7fff7fff7ffe",
            "0xa0680017fff8000",
            "0xc",
            "0x40780017fff7fff",
            "0x6",
            "0x480a7ffb7fff8000",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffff68",
            "0x480680017fff8000",
            "0x1",
            "0x208b7fff7fff7ffe",
            "0x480a7ffb7fff8000",
            "0x480a7ffd7fff8000",
            "0x480a7ffc7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffff8d",
            "0x480680017fff8000",
            "0x0",
            "0x208b7fff7fff7ffe",
            "0x400380007ffb7ffc",
            "0x400380017ffb7ffd",
            "0x482680017ffb8000",
            "0x2",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x4",
            "0x404b800280028002",
            "0x404b800380038003",
            "0x482a7ffc7ffa8000",
            "0x4846800180028000",
            "0x100000000000000000000000000000000",
            "0x40327fff80007ffe",
            "0x482a7ffd7ffb8000",
            "0x482880027fff8000",
            "0x4846800180038000",
            "0x100000000000000000000000000000000",
            "0x40327fff80017ffe",
            "0x480a7ff97fff8000",
            "0x480a80007fff8000",
            "0x480a80017fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffec",
            "0x480a80007fff8000",
            "0x480a80017fff8000",
            "0x480a80037fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x2",
            "0x4846800180018000",
            "0x10000000000000000",
            "0x40337fff80007ffd",
            "0x400380007ffc8000",
            "0x480680017fff8000",
            "0xffffffffffffffff",
            "0x4828800080007fff",
            "0x400280017ffc7fff",
            "0x400380027ffc8001",
            "0x482680017ffc8000",
            "0x3",
            "0x480a80007fff8000",
            "0x480a80017fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x0",
            "0x480a7ff97fff8000",
            "0x480a7ffa7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffed",
            "0x48127ffd7fff8000",
            "0x480a7ffb7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffe9",
            "0x48127ffd7fff8000",
            "0x480a7ffc7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffe5",
            "0x48127ffd7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffe1",
            "0x48127ffd7fff8000",
            "0x48507ff17fd98000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffdd",
            "0x48507fe67fcf8000",
            "0x48507fe67fcd8000",
            "0x48307fff7ffe8000",
            "0x48127ffa7fff8000",
            "0x48307ffb7ffe8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffd6",
            "0x48507fd77fcb8000",
            "0x48507fd77fbf8000",
            "0x48307fff7ffe8000",
            "0x48507fe07fbc8000",
            "0x48307fff7ffe8000",
            "0x48127ff87fff8000",
            "0x48307ff97ffe8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffcd",
            "0x48507fc67fbb8000",
            "0x48507fc67fb98000",
            "0x48307fff7ffe8000",
            "0x48507fcf7fac8000",
            "0x48307fff7ffe8000",
            "0x48507fce7fa98000",
            "0x48307fff7ffe8000",
            "0x48127ff67fff8000",
            "0x48307ff77ffe8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffc2",
            "0x48507fb47fa88000",
            "0x48507fbe7fa68000",
            "0x48307fff7ffe8000",
            "0x48507fbd7f998000",
            "0x48307fff7ffe8000",
            "0x48127ff87fff8000",
            "0x48307ff97ffe8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffb9",
            "0x48507fae7f978000",
            "0x48507fae7f958000",
            "0x48307fff7ffe8000",
            "0x48127ffa7fff8000",
            "0x48307ffb7ffe8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffb2",
            "0x48507fa07f888000",
            "0x48127ffc7fff8000",
            "0x48307ffd7ffe8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffad",
            "0x480680017fff8000",
            "0x10000000000000000",
            "0x48507fac7fff8000",
            "0x480680017fff8000",
            "0x10000000000000000",
            "0x48507fce7fff8000",
            "0x480680017fff8000",
            "0x10000000000000000",
            "0x48507fec7fff8000",
            "0x480680017fff8000",
            "0x10000000000000000",
            "0x48507ff87fff8000",
            "0x48127ff57fff8000",
            "0x48307ff87f958000",
            "0x48307ff97fb48000",
            "0x48307ffa7fd78000",
            "0x48307ffb7ff28000",
            "0x208b7fff7fff7ffe",
            "0x48297ffd80007ffb",
            "0x20680017fff7fff",
            "0x9",
            "0x480a7ff97fff8000",
            "0x482680017ffa8000",
            "0x1",
            "0x480a7ffc7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffff5e",
            "0x208b7fff7fff7ffe",
            "0x480a7ff97fff8000",
            "0x482680017ffb8000",
            "0x1",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffff57",
            "0x208b7fff7fff7ffe",
            "0x480a7ff97fff8000",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffeb",
            "0x480680017fff8000",
            "0x1",
            "0x48127ffd7fff8000",
            "0x48307ffd80007ffe",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x4",
            "0x482a7ffd7ffc8000",
            "0x20680017fff7fff",
            "0xc",
            "0x480a7ff97fff8000",
            "0x480680017fff8000",
            "0x0",
            "0x480680017fff8000",
            "0x0",
            "0x480680017fff8000",
            "0x0",
            "0x480680017fff8000",
            "0x0",
            "0x208b7fff7fff7ffe",
            "0x480a7ff97fff8000",
            "0x480a80007fff8000",
            "0x480a80017fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffff4f",
            "0x480a80027fff8000",
            "0x480a80037fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffff4b",
            "0x480a80007fff8000",
            "0x480a80017fff8000",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffff70",
            "0x400680017fff7ffe",
            "0x0",
            "0x400680017fff7fff",
            "0x0",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x480a80027fff8000",
            "0x480a80037fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffff3f",
            "0x400a7ffa7fff7ffd",
            "0x400a7ffb7fff7ffe",
            "0x400680017fff7fff",
            "0x0",
            "0x48127ffc7fff8000",
            "0x480a80027fff8000",
            "0x480a80037fff8000",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffb2",
            "0x400680017fff7fff",
            "0x1",
            "0x48127ffe7fff8000",
            "0x480a80007fff8000",
            "0x480a80017fff8000",
            "0x480a80027fff8000",
            "0x480a80037fff8000",
            "0x208b7fff7fff7ffe",
            "0x480680017fff8000",
            "0xffffffffffffffffffffffffffffffff",
            "0x480680017fff8000",
            "0xffffffffffffffffffffffffffffffff",
            "0x480a7ffb7fff8000",
            "0x48287ffc80007ffd",
            "0x48287ffd80007ffd",
            "0x208b7fff7fff7ffe",
            "0x480a7ffb7fff8000",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffff6",
            "0x480680017fff8000",
            "0x1",
            "0x480680017fff8000",
            "0x0",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffff19",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x208b7fff7fff7ffe",
            "0x480a7ff97fff8000",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffef",
            "0x48127ffd7fff8000",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffff09",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x208b7fff7fff7ffe",
            "0x48297ffd80007ffb",
            "0x20680017fff7fff",
            "0x4",
            "0x10780017fff7fff",
            "0x6",
            "0x480a7ff97fff8000",
            "0x480680017fff8000",
            "0x0",
            "0x208b7fff7fff7ffe",
            "0x48297ffc80007ffa",
            "0x20680017fff7fff",
            "0x4",
            "0x10780017fff7fff",
            "0x6",
            "0x480a7ff97fff8000",
            "0x480680017fff8000",
            "0x0",
            "0x208b7fff7fff7ffe",
            "0x480a7ff97fff8000",
            "0x480680017fff8000",
            "0x1",
            "0x208b7fff7fff7ffe",
            "0x480a7ff97fff8000",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffee5",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffee1",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffee0",
            "0x400680017fff7fff",
            "0x0",
            "0x480a7ff77fff8000",
            "0x480a7ff87fff8000",
            "0x48127ffa7fff8000",
            "0x48127ffa7fff8000",
            "0x48127ffa7fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x0",
            "0x480a7ff97fff8000",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffecc",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffec8",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffff45",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffe06",
            "0x48127ffc7fff8000",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffac",
            "0x480a7ff77fff8000",
            "0x480a7ff87fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x2",
            "0x480a7ff97fff8000",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffeac",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffea8",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffecd",
            "0x40137ffc7fff8000",
            "0x40137ffd7fff8001",
            "0x48127ffb7fff8000",
            "0x48127ffd7fff8000",
            "0x48127ffd7fff8000",
            "0x480680017fff8000",
            "0x0",
            "0x480680017fff8000",
            "0x0",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffff9a",
            "0x400680017fff7fff",
            "0x1",
            "0x480a7ff77fff8000",
            "0x480a7ff87fff8000",
            "0x48127ffc7fff8000",
            "0x480a80007fff8000",
            "0x480a80017fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x2",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x480a7ffc7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffe88",
            "0x480a7ffb7fff8000",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x480680017fff8000",
            "0x0",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffeac",
            "0x40137ffc7fff8000",
            "0x40137ffd7fff8001",
            "0x48127ffb7fff8000",
            "0x48127ffd7fff8000",
            "0x48127ffd7fff8000",
            "0x480680017fff8000",
            "0x0",
            "0x480680017fff8000",
            "0x0",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffff79",
            "0x400680017fff7fff",
            "0x1",
            "0x480a7ff87fff8000",
            "0x480a7ff97fff8000",
            "0x48127ffc7fff8000",
            "0x480a80007fff8000",
            "0x480a80017fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x2",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffd9e",
            "0x40137fff7fff8000",
            "0x480680017fff8000",
            "0x120650e571756796b93f65826a80b3511d4f3a06808e82cb37407903b09d995",
            "0x4002800080007fff",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffd98",
            "0x40137fff7fff8001",
            "0x4003800080017ffc",
            "0x4003800180017ffd",
            "0x4826800180018000",
            "0x2",
            "0x480a7ffa7fff8000",
            "0x480680017fff8000",
            "0x1",
            "0x480a80007fff8000",
            "0x4828800180007ffc",
            "0x480a80017fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffe13",
            "0x480a7ffb7fff8000",
            "0x208b7fff7fff7ffe",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x480680017fff8000",
            "0xee2b6c840729051a0d06a623ff093dcc01e03f2e0c0e07114ac2440394b889",
            "0x208b7fff7fff7ffe",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffffa",
            "0x480a7ffb7fff8000",
            "0x48127ffe7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffdf4",
            "0x48127ffe7fff8000",
            "0x48127ff57fff8000",
            "0x48127ff57fff8000",
            "0x48127ffc7fff8000",
            "0x208b7fff7fff7ffe",
            "0x480a7ffb7fff8000",
            "0x480a7ffc7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffed",
            "0x480a7ffa7fff8000",
            "0x48127ffe7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffdee",
            "0x48127ff67fff8000",
            "0x48127ff67fff8000",
            "0x208b7fff7fff7ffe",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x480680017fff8000",
            "0x387c153462d309d4b5a1fc5f90e85bc59eeb2094b2fcef46513ea5f1d1c9b85",
            "0x208b7fff7fff7ffe",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffffa",
            "0x480a7ffb7fff8000",
            "0x48127ffe7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffdd6",
            "0x48127ffe7fff8000",
            "0x48127ff57fff8000",
            "0x48127ff57fff8000",
            "0x48127ffc7fff8000",
            "0x208b7fff7fff7ffe",
            "0x480a7ffb7fff8000",
            "0x480a7ffc7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffed",
            "0x480a7ffa7fff8000",
            "0x48127ffe7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffdd0",
            "0x48127ff67fff8000",
            "0x48127ff67fff8000",
            "0x208b7fff7fff7ffe",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x480a7ffc7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffe5",
            "0x400680017fff7fff",
            "0x0",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x480680017fff8000",
            "0x1",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffe9",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x9",
            "0x208b7fff7fff7ffe",
            "0x480a7ffb7fff8000",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffb5",
            "0x208b7fff7fff7ffe",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x480a7ffc7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffff8",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffb6",
            "0x48127ffd7fff8000",
            "0x48127ffe7fff8000",
            "0x48127fe77fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffff85",
            "0x48127ffe7fff8000",
            "0x48127fe37fff8000",
            "0x48127ffd7fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x1",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffd1c",
            "0x40137fff7fff8000",
            "0x4003800080007ffa",
            "0x4003800180007ffb",
            "0x4003800280007ffc",
            "0x4003800380007ffd",
            "0x4826800180008000",
            "0x4",
            "0x480a7ff77fff8000",
            "0x480a7ff97fff8000",
            "0x480680017fff8000",
            "0x41b033f4a31df8067c24d1e9b550a2ce75fd4a29e1147af9752174f0e6cb20",
            "0x4828800080007ffc",
            "0x480a80007fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffd6b",
            "0x482480017fff8000",
            "0x1",
            "0x40307ffe7ffd7fff",
            "0x48127ffc7fff8000",
            "0x480a7ff87fff8000",
            "0x480080007ffc8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x1",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffd02",
            "0x40137fff7fff8000",
            "0x480a7ffb7fff8000",
            "0x480a7ffd7fff8000",
            "0x480680017fff8000",
            "0x3cb0e1486e633fbe3e2fafe8aedf12b70ca1860e7467ddb75a17858cde39312",
            "0x4829800080008000",
            "0x480a80007fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffd57",
            "0x482480017fff8000",
            "0x5",
            "0x40307ffe7ffd7fff",
            "0x48127ffc7fff8000",
            "0x480a7ffc7fff8000",
            "0x480080007ffc8000",
            "0x480080017ffb8000",
            "0x480080027ffa8000",
            "0x480080037ff98000",
            "0x480080047ff88000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x1",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffcea",
            "0x40137fff7fff8000",
            "0x4003800080007ffd",
            "0x4826800180008000",
            "0x1",
            "0x480a7ffa7fff8000",
            "0x480a7ffc7fff8000",
            "0x480680017fff8000",
            "0x2f0b3c5710379609eb5495f1ecd348cb28167711b73609fe565a72734550354",
            "0x4828800080007ffc",
            "0x480a80007fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffd3c",
            "0x482480017fff8000",
            "0x2",
            "0x40307ffe7ffd7fff",
            "0x48127ffc7fff8000",
            "0x480a7ffb7fff8000",
            "0x480080007ffc8000",
            "0x480080017ffb8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x1",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffcd2",
            "0x40137fff7fff8000",
            "0x4003800080007ffd",
            "0x4826800180008000",
            "0x1",
            "0x480a7ffa7fff8000",
            "0x480a7ffc7fff8000",
            "0x480680017fff8000",
            "0x3e8cfd4725c1e28fa4a6e3e468b4fcf75367166b850ac5f04e33ec843e82c1",
            "0x4828800080007ffc",
            "0x480a80007fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffd24",
            "0x482480017fff8000",
            "0x4",
            "0x40307ffe7ffd7fff",
            "0x48127ffc7fff8000",
            "0x480a7ffb7fff8000",
            "0x480080007ffc8000",
            "0x480080017ffb8000",
            "0x480080027ffa8000",
            "0x480080037ff98000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x1",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffcb8",
            "0x40137fff7fff8000",
            "0x4003800080007ff8",
            "0x4003800180007ff9",
            "0x4003800280007ffa",
            "0x4003800380007ffb",
            "0x4003800480007ffc",
            "0x4003800580007ffd",
            "0x4826800180008000",
            "0x6",
            "0x480a7ff57fff8000",
            "0x480a7ff77fff8000",
            "0x480680017fff8000",
            "0x15543c3708653cda9d418b4ccd3be11368e40636c10c44b18cfe756b6d88b29",
            "0x4828800080007ffc",
            "0x480a80007fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffd05",
            "0x48127ffd7fff8000",
            "0x480a7ff67fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x1",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffca0",
            "0x40137fff7fff8000",
            "0x4003800080007ffc",
            "0x4003800180007ffd",
            "0x4826800180008000",
            "0x2",
            "0x480a7ff97fff8000",
            "0x480a7ffb7fff8000",
            "0x480680017fff8000",
            "0x302cc8ef0eb956ecba01d93a13572cb13459e0a0e1ae945bd5a1bdccca6b954",
            "0x4828800080007ffc",
            "0x480a80007fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffcf1",
            "0x482480017fff8000",
            "0x1",
            "0x40307ffe7ffd7fff",
            "0x48127ffc7fff8000",
            "0x480a7ffa7fff8000",
            "0x480080007ffc8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x1",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffc88",
            "0x40137fff7fff8000",
            "0x4003800080007ffc",
            "0x4003800180007ffd",
            "0x4826800180008000",
            "0x2",
            "0x480a7ff97fff8000",
            "0x480a7ffb7fff8000",
            "0x480680017fff8000",
            "0x812246da5a485b2e5291dfc2a20d6baa83d62a0dfe6d2124e9c5c409dfed75",
            "0x4828800080007ffc",
            "0x480a80007fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffcd9",
            "0x482480017fff8000",
            "0x1",
            "0x40307ffe7ffd7fff",
            "0x48127ffc7fff8000",
            "0x480a7ffa7fff8000",
            "0x480080007ffc8000",
            "0x208b7fff7fff7ffe",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x480680017fff8000",
            "0x139825585c3389ee852d93d2706b57d5bf8d4ba85922ef0689a691627211b05",
            "0x208b7fff7fff7ffe",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffffa",
            "0x480a7ffb7fff8000",
            "0x48127ffe7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffcdf",
            "0x48127ffe7fff8000",
            "0x48127ff57fff8000",
            "0x48127ff57fff8000",
            "0x48127ffc7fff8000",
            "0x208b7fff7fff7ffe",
            "0x480a7ffb7fff8000",
            "0x480a7ffc7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffed",
            "0x480a7ffa7fff8000",
            "0x48127ffe7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffcd9",
            "0x48127ff67fff8000",
            "0x48127ff67fff8000",
            "0x208b7fff7fff7ffe",
            "0x480a7ffc7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffc65",
            "0x480a7ff97fff8000",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x480a7ffc7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffee",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffff00",
            "0x208b7fff7fff7ffe",
            "0x482680017ffd8000",
            "0x2",
            "0x402a7ffd7ffc7fff",
            "0x480280007ffb8000",
            "0x480280017ffb8000",
            "0x480280027ffb8000",
            "0x480280007ffd8000",
            "0x480280017ffd8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffec",
            "0x40780017fff7fff",
            "0x1",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x480680017fff8000",
            "0x0",
            "0x48127ffb7fff8000",
            "0x208b7fff7fff7ffe",
            "0x480a7ffb7fff8000",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffc5",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x1",
            "0x4003800080007ffc",
            "0x4826800180008000",
            "0x1",
            "0x480a7ffd7fff8000",
            "0x4828800080007ffe",
            "0x480a80007fff8000",
            "0x208b7fff7fff7ffe",
            "0x402b7ffd7ffc7ffd",
            "0x480280007ffb8000",
            "0x480280017ffb8000",
            "0x480280027ffb8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffee",
            "0x48127ffe7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffff1",
            "0x48127ff47fff8000",
            "0x48127ff47fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x208b7fff7fff7ffe",
            "0x480a7ff97fff8000",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x451",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x1",
            "0x4003800080007ffb",
            "0x4003800180007ffc",
            "0x4826800180008000",
            "0x2",
            "0x480a7ffd7fff8000",
            "0x4828800080007ffe",
            "0x480a80007fff8000",
            "0x208b7fff7fff7ffe",
            "0x482680017ffd8000",
            "0x2",
            "0x402a7ffd7ffc7fff",
            "0x480280007ffb8000",
            "0x480280017ffb8000",
            "0x480280027ffb8000",
            "0x480280007ffd8000",
            "0x480280017ffd8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffe7",
            "0x48127ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffec",
            "0x48127ff37fff8000",
            "0x48127ff37fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x208b7fff7fff7ffe",
            "0x480a7ff57fff8000",
            "0x480a7ff67fff8000",
            "0x480a7ff77fff8000",
            "0x480a7ff87fff8000",
            "0x480a7ff97fff8000",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x48d",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x1",
            "0x4003800080007ffb",
            "0x4003800180007ffc",
            "0x4826800180008000",
            "0x2",
            "0x480a7ffd7fff8000",
            "0x4828800080007ffe",
            "0x480a80007fff8000",
            "0x208b7fff7fff7ffe",
            "0x482680017ffd8000",
            "0x6",
            "0x402a7ffd7ffc7fff",
            "0x480280007ffb8000",
            "0x480280017ffb8000",
            "0x480280027ffb8000",
            "0x480280007ffd8000",
            "0x480280017ffd8000",
            "0x480280027ffd8000",
            "0x480280037ffd8000",
            "0x480280047ffd8000",
            "0x480280057ffd8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffdf",
            "0x48127ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffe8",
            "0x48127ff37fff8000",
            "0x48127ff37fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x208b7fff7fff7ffe",
            "0x480a7ff57fff8000",
            "0x480a7ff67fff8000",
            "0x480a7ff77fff8000",
            "0x480a7ff87fff8000",
            "0x480a7ff97fff8000",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x498",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x1",
            "0x4003800080007ffb",
            "0x4003800180007ffc",
            "0x4826800180008000",
            "0x2",
            "0x480a7ffd7fff8000",
            "0x4828800080007ffe",
            "0x480a80007fff8000",
            "0x208b7fff7fff7ffe",
            "0x482680017ffd8000",
            "0x6",
            "0x402a7ffd7ffc7fff",
            "0x480280007ffb8000",
            "0x480280017ffb8000",
            "0x480280027ffb8000",
            "0x480280007ffd8000",
            "0x480280017ffd8000",
            "0x480280027ffd8000",
            "0x480280037ffd8000",
            "0x480280047ffd8000",
            "0x480280057ffd8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffdf",
            "0x48127ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffe8",
            "0x48127ff37fff8000",
            "0x48127ff37fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x208b7fff7fff7ffe",
            "0x480a7ff57fff8000",
            "0x480a7ff67fff8000",
            "0x480a7ff77fff8000",
            "0x480a7ff87fff8000",
            "0x480a7ff97fff8000",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x4c2",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x1",
            "0x4003800080007ffb",
            "0x4003800180007ffc",
            "0x4826800180008000",
            "0x2",
            "0x480a7ffd7fff8000",
            "0x4828800080007ffe",
            "0x480a80007fff8000",
            "0x208b7fff7fff7ffe",
            "0x482680017ffd8000",
            "0x6",
            "0x402a7ffd7ffc7fff",
            "0x480280007ffb8000",
            "0x480280017ffb8000",
            "0x480280027ffb8000",
            "0x480280007ffd8000",
            "0x480280017ffd8000",
            "0x480280027ffd8000",
            "0x480280037ffd8000",
            "0x480280047ffd8000",
            "0x480280057ffd8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffdf",
            "0x48127ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffe8",
            "0x48127ff37fff8000",
            "0x48127ff37fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x2",
            "0x480a7ff77fff8000",
            "0x480a7ff87fff8000",
            "0x480a7ff97fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffef9",
            "0x40137fff7fff8000",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x480a80007fff8000",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x4e9",
            "0x40137fff7fff8001",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x480a7ffc7fff8000",
            "0x480a80017fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x3",
            "0x4003800080007ffb",
            "0x400380007ffd7ffb",
            "0x402780017ffd8001",
            "0x1",
            "0x4826800180008000",
            "0x1",
            "0x484680017ffb8000",
            "0x2",
            "0x40317fff7ffe8002",
            "0x4826800180008000",
            "0x1",
            "0x480a7ffc7fff8000",
            "0x484680017ffb8000",
            "0x2",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffb44",
            "0x480a80017fff8000",
            "0x4829800080008002",
            "0x480a80007fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x2",
            "0x480280027ffb8000",
            "0x480280027ffd8000",
            "0x400080007ffe7fff",
            "0x482680017ffd8000",
            "0x3",
            "0x480280027ffd8000",
            "0x48307fff7ffe8000",
            "0x402a7ffd7ffc7fff",
            "0x480280027ffb8000",
            "0x480280007ffb8000",
            "0x480280017ffb8000",
            "0x482480017ffd8000",
            "0x1",
            "0x480280007ffd8000",
            "0x480280017ffd8000",
            "0x480280027ffd8000",
            "0x482680017ffd8000",
            "0x3",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffbe",
            "0x40137ffb7fff8000",
            "0x40137ffc7fff8001",
            "0x48127ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffd2",
            "0x480a80007fff8000",
            "0x480a80017fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x2",
            "0x480a7ff77fff8000",
            "0x480a7ff87fff8000",
            "0x480a7ff97fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffea9",
            "0x40137fff7fff8000",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x480a80007fff8000",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x4f4",
            "0x40137fff7fff8001",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x480a7ffc7fff8000",
            "0x480a80017fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x3",
            "0x4003800080007ffb",
            "0x400380007ffd7ffb",
            "0x402780017ffd8001",
            "0x1",
            "0x4826800180008000",
            "0x1",
            "0x484680017ffb8000",
            "0x2",
            "0x40317fff7ffe8002",
            "0x4826800180008000",
            "0x1",
            "0x480a7ffc7fff8000",
            "0x484680017ffb8000",
            "0x2",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffaf4",
            "0x480a80017fff8000",
            "0x4829800080008002",
            "0x480a80007fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x2",
            "0x480280027ffb8000",
            "0x480280027ffd8000",
            "0x400080007ffe7fff",
            "0x482680017ffd8000",
            "0x3",
            "0x480280027ffd8000",
            "0x48307fff7ffe8000",
            "0x402a7ffd7ffc7fff",
            "0x480280027ffb8000",
            "0x480280007ffb8000",
            "0x480280017ffb8000",
            "0x482480017ffd8000",
            "0x1",
            "0x480280007ffd8000",
            "0x480280017ffd8000",
            "0x480280027ffd8000",
            "0x482680017ffd8000",
            "0x3",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffbe",
            "0x40137ffb7fff8000",
            "0x40137ffc7fff8001",
            "0x48127ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffd2",
            "0x480a80007fff8000",
            "0x480a80017fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x8",
            "0x480a7fef7fff8000",
            "0x480a7ff07fff8000",
            "0x480a7ff17fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x1fd",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffe56",
            "0x40137fff7fff8000",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x480a7ff27fff8000",
            "0x480a7ff37fff8000",
            "0x480a7ff47fff8000",
            "0x480a7ff57fff8000",
            "0x480a7ff67fff8000",
            "0x480a7ff77fff8000",
            "0x480a7ff87fff8000",
            "0x480a7ff97fff8000",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x1104800180018000",
            "0x1f7",
            "0x40137ffc7fff8001",
            "0x40137ffd7fff8002",
            "0x40137ffe7fff8003",
            "0x40137fff7fff8004",
            "0x48127ff97fff8000",
            "0x48127ff97fff8000",
            "0x48127ff97fff8000",
            "0x480a80007fff8000",
            "0x480a7ff27fff8000",
            "0x480a7ff37fff8000",
            "0x1104800180018000",
            "0x304",
            "0x40137fff7fff8005",
            "0x48127ffc7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffb0c",
            "0x48127ffe7fff8000",
            "0x48127ff77fff8000",
            "0x480a7ff27fff8000",
            "0x48127ffc7fff8000",
            "0x480a80057fff8000",
            "0x480a80017fff8000",
            "0x480a80027fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffd7c",
            "0x48127ffd7fff8000",
            "0x48127ffd7fff8000",
            "0x480a7ff37fff8000",
            "0x48127fdf7fff8000",
            "0x480a80057fff8000",
            "0x480a80037fff8000",
            "0x480a80047fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffd73",
            "0x48127ffd7fff8000",
            "0x48127ffd7fff8000",
            "0x480a80057fff8000",
            "0x480a7ffc7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffd9f",
            "0x40137ffe7fff8006",
            "0x40137fff7fff8007",
            "0x48127ffc7fff8000",
            "0x48127fa17fff8000",
            "0x48127ffb7fff8000",
            "0x480a80017fff8000",
            "0x480a80027fff8000",
            "0x480a80037fff8000",
            "0x480a80047fff8000",
            "0x480a80067fff8000",
            "0x480a80077fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x1",
            "0x4003800080007ff7",
            "0x4003800180007ff8",
            "0x4003800280007ff9",
            "0x4003800380007ffa",
            "0x4003800480007ffb",
            "0x4003800580007ffc",
            "0x4826800180008000",
            "0x6",
            "0x480a7ffd7fff8000",
            "0x4828800080007ffe",
            "0x480a80007fff8000",
            "0x208b7fff7fff7ffe",
            "0x482680017ffd8000",
            "0xc",
            "0x402a7ffd7ffc7fff",
            "0x480280007ffb8000",
            "0x480280017ffb8000",
            "0x480280027ffb8000",
            "0x480280007ffd8000",
            "0x480280017ffd8000",
            "0x480280027ffd8000",
            "0x480280037ffd8000",
            "0x480280047ffd8000",
            "0x480280057ffd8000",
            "0x480280067ffd8000",
            "0x480280077ffd8000",
            "0x480280087ffd8000",
            "0x480280097ffd8000",
            "0x4802800a7ffd8000",
            "0x4802800b7ffd8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffff93",
            "0x48127ff97fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffde",
            "0x48127fef7fff8000",
            "0x48127fef7fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0xb",
            "0x480a7ff17fff8000",
            "0x480a7ff27fff8000",
            "0x480a7ff37fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x184",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffddd",
            "0x40137fff7fff8000",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x480a80007fff8000",
            "0x480a7ff47fff8000",
            "0x480a7ff57fff8000",
            "0x1104800180018000",
            "0x29e",
            "0x40137fff7fff8001",
            "0x48127ffc7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffaa6",
            "0x48127ffe7fff8000",
            "0x48127ff77fff8000",
            "0x480a80017fff8000",
            "0x48127ffc7fff8000",
            "0x480a80017fff8000",
            "0x480a7ff67fff8000",
            "0x480a7ff77fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffd16",
            "0x48127ffd7fff8000",
            "0x48127ffd7fff8000",
            "0x480a80017fff8000",
            "0x480a7ffc7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffd5a",
            "0x40137ffc7fff8002",
            "0x40137ffd7fff8003",
            "0x40137ffe7fff8004",
            "0x40137fff7fff8005",
            "0x48127ffa7fff8000",
            "0x48127fbc7fff8000",
            "0x48127ff97fff8000",
            "0x480a7ff47fff8000",
            "0x480a7ff57fff8000",
            "0x1104800180018000",
            "0x262",
            "0x40137ffe7fff8006",
            "0x4829800680007ff4",
            "0x20680017fff7fff",
            "0x8",
            "0x400b80027fff8007",
            "0x400b80037fff8008",
            "0x400b80047fff8009",
            "0x400b80057fff800a",
            "0x10780017fff7fff",
            "0x6",
            "0x400b80047fff8007",
            "0x400b80057fff8008",
            "0x400b80027fff8009",
            "0x400b80037fff800a",
            "0x48127ffc7fff8000",
            "0x480a7ff87fff8000",
            "0x480a7ff97fff8000",
            "0x480a80077fff8000",
            "0x480a80087fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffb6d",
            "0x400680017fff7fff",
            "0x1",
            "0x48127ffe7fff8000",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x480a80097fff8000",
            "0x480a800a7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffb64",
            "0x400680017fff7fff",
            "0x1",
            "0x48127f967fff8000",
            "0x48127f967fff8000",
            "0x48127ffc7fff8000",
            "0x480a80077fff8000",
            "0x480a80087fff8000",
            "0x480a80097fff8000",
            "0x480a800a7fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x1",
            "0x4003800080007ff9",
            "0x4003800180007ffa",
            "0x4003800280007ffb",
            "0x4003800380007ffc",
            "0x4826800180008000",
            "0x4",
            "0x480a7ffd7fff8000",
            "0x4828800080007ffe",
            "0x480a80007fff8000",
            "0x208b7fff7fff7ffe",
            "0x482680017ffd8000",
            "0xa",
            "0x402a7ffd7ffc7fff",
            "0x480280007ffb8000",
            "0x480280017ffb8000",
            "0x480280027ffb8000",
            "0x480280007ffd8000",
            "0x480280017ffd8000",
            "0x480280027ffd8000",
            "0x480280037ffd8000",
            "0x480280047ffd8000",
            "0x480280057ffd8000",
            "0x480280067ffd8000",
            "0x480280077ffd8000",
            "0x480280087ffd8000",
            "0x480280097ffd8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffff8c",
            "0x48127ffb7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffe2",
            "0x48127ff17fff8000",
            "0x48127ff17fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x3",
            "0x480a7ff37fff8000",
            "0x480a7ff47fff8000",
            "0x480a7ff57fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x104",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffd5d",
            "0x40137fff7fff8000",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x480a80007fff8000",
            "0x480a7ff67fff8000",
            "0x480a7ff77fff8000",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x1104800180018000",
            "0x34d",
            "0x40137fff7fff8001",
            "0x482680017ffa8000",
            "0x800000000000011000000000000000000000000000000000000000000000000",
            "0x484480017fff8000",
            "0x2",
            "0x48327fff80018000",
            "0x482680017ffa8000",
            "0x800000000000011000000000000000000000000000000000000000000000000",
            "0x484480017fff8000",
            "0x2",
            "0x482480017fff8000",
            "0x1",
            "0x48327fff80018000",
            "0x48127ff77fff8000",
            "0x480a7ff87fff8000",
            "0x480a7ff97fff8000",
            "0x480080007ff88000",
            "0x480080007ffb8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffb0a",
            "0x400680017fff7fff",
            "0x1",
            "0x48127fc37fff8000",
            "0x48127fc37fff8000",
            "0x48127ffc7fff8000",
            "0x480a80007fff8000",
            "0x480280007ffb8000",
            "0x480280017ffb8000",
            "0x1104800180018000",
            "0x1fe",
            "0x40137fff7fff8002",
            "0x48127ffc7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffa06",
            "0x48127ffe7fff8000",
            "0x48127ff77fff8000",
            "0x480280007ffb8000",
            "0x48127ffc7fff8000",
            "0x480a80027fff8000",
            "0x4802800080018000",
            "0x4802800180018000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffc76",
            "0x48127ffd7fff8000",
            "0x48127fd97fff8000",
            "0x48127ffc7fff8000",
            "0x480680017fff8000",
            "0x0",
            "0x480a7ffa7fff8000",
            "0x480a80017fff8000",
            "0x480a7ffb7fff8000",
            "0x480a7ffc7fff8000",
            "0x1104800180018000",
            "0x160",
            "0x480a7ffa7fff8000",
            "0x480a80017fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x3",
            "0x4003800080007ffb",
            "0x400380007ffd7ffb",
            "0x402780017ffd8001",
            "0x1",
            "0x4826800180008000",
            "0x1",
            "0x484680017ffb8000",
            "0x2",
            "0x40317fff7ffe8002",
            "0x4826800180008000",
            "0x1",
            "0x480a7ffc7fff8000",
            "0x484680017ffb8000",
            "0x2",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff976",
            "0x480a80017fff8000",
            "0x4829800080008002",
            "0x480a80007fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x2",
            "0x480280027ffb8000",
            "0x480280047ffd8000",
            "0x400080007ffe7fff",
            "0x482680017ffd8000",
            "0x5",
            "0x480280047ffd8000",
            "0x48307fff7ffe8000",
            "0x482480017fff8000",
            "0x2",
            "0x402a7ffd7ffc7fff",
            "0x480280027ffb8000",
            "0x480280007ffb8000",
            "0x480280017ffb8000",
            "0x482480017ffd8000",
            "0x1",
            "0x480280007ffd8000",
            "0x480280017ffd8000",
            "0x480280027ffd8000",
            "0x480280037ffd8000",
            "0x480280047ffd8000",
            "0x482680017ffd8000",
            "0x5",
            "0x480080007ff48000",
            "0x480080017ff38000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffff83",
            "0x40137ffb7fff8000",
            "0x40137ffc7fff8001",
            "0x48127ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffcc",
            "0x480a80007fff8000",
            "0x480a80017fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x3",
            "0x480a7ff37fff8000",
            "0x480a7ff47fff8000",
            "0x480a7ff57fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x79",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffcd2",
            "0x40137fff7fff8000",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x480a80007fff8000",
            "0x480a7ff67fff8000",
            "0x480a7ff77fff8000",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x1104800180018000",
            "0x31d",
            "0x40137fff7fff8001",
            "0x48127ffe7fff8000",
            "0x4802800080018000",
            "0x4802800180018000",
            "0x480a7ff87fff8000",
            "0x480a7ff97fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffa8b",
            "0x400680017fff7fff",
            "0x1",
            "0x48127fca7fff8000",
            "0x48127fca7fff8000",
            "0x48127ffc7fff8000",
            "0x480a80007fff8000",
            "0x480280007ffb8000",
            "0x480280017ffb8000",
            "0x1104800180018000",
            "0x17f",
            "0x40137fff7fff8002",
            "0x48127ffc7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff987",
            "0x48127ffe7fff8000",
            "0x48127ff77fff8000",
            "0x480280007ffb8000",
            "0x48127ffc7fff8000",
            "0x480a80027fff8000",
            "0x4802800080018000",
            "0x4802800180018000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffbf7",
            "0x48127ffd7fff8000",
            "0x48127fd97fff8000",
            "0x48127ffc7fff8000",
            "0x480680017fff8000",
            "0x0",
            "0x480a7ffa7fff8000",
            "0x480a80017fff8000",
            "0x480a7ffb7fff8000",
            "0x480a7ffc7fff8000",
            "0x1104800180018000",
            "0xe1",
            "0x480a7ffa7fff8000",
            "0x480a80017fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x3",
            "0x4003800080007ffb",
            "0x400380007ffd7ffb",
            "0x402780017ffd8001",
            "0x1",
            "0x4826800180008000",
            "0x1",
            "0x484680017ffb8000",
            "0x2",
            "0x40317fff7ffe8002",
            "0x4826800180008000",
            "0x1",
            "0x480a7ffc7fff8000",
            "0x484680017ffb8000",
            "0x2",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff8f7",
            "0x480a80017fff8000",
            "0x4829800080008002",
            "0x480a80007fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x2",
            "0x480280027ffb8000",
            "0x480280047ffd8000",
            "0x400080007ffe7fff",
            "0x482680017ffd8000",
            "0x5",
            "0x480280047ffd8000",
            "0x48307fff7ffe8000",
            "0x482480017fff8000",
            "0x2",
            "0x402a7ffd7ffc7fff",
            "0x480280027ffb8000",
            "0x480280007ffb8000",
            "0x480280017ffb8000",
            "0x482480017ffd8000",
            "0x1",
            "0x480280007ffd8000",
            "0x480280017ffd8000",
            "0x480280027ffd8000",
            "0x480280037ffd8000",
            "0x480280047ffd8000",
            "0x482680017ffd8000",
            "0x5",
            "0x480080007ff48000",
            "0x480080017ff38000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffff8f",
            "0x40137ffb7fff8000",
            "0x40137ffc7fff8001",
            "0x48127ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffcc",
            "0x480a80007fff8000",
            "0x480a80017fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x208b7fff7fff7ffe",
            "0x480a7ffa7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff937",
            "0x480a7ffc7fff8000",
            "0x48127ffe7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff8e2",
            "0x48127ff47fff8000",
            "0x480a7ffb7fff8000",
            "0x48127ffd7fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0xd",
            "0x480a7ff17fff8000",
            "0x480a7ff27fff8000",
            "0x480a7ff37fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffc4a",
            "0x40137fff7fff8000",
            "0x40137ffd7fff8001",
            "0x48127ffc7fff8000",
            "0x48127ffd7fff8000",
            "0x480a80007fff8000",
            "0x480a7ff47fff8000",
            "0x480a7ff57fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffc0c",
            "0x40137fff7fff8002",
            "0x20780017fff8002",
            "0x9",
            "0x48127ffd7fff8000",
            "0x48127ffd7fff8000",
            "0x480a80007fff8000",
            "0x480a7ff47fff8000",
            "0x480a7ff57fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffc1a",
            "0x48127ffd7fff8000",
            "0x480a80017fff8000",
            "0x48127ffc7fff8000",
            "0x480a80007fff8000",
            "0x480a7ff47fff8000",
            "0x480a7ff57fff8000",
            "0x1104800180018000",
            "0x111",
            "0x40137ffc7fff8003",
            "0x40137ffd7fff8004",
            "0x40137ffe7fff8005",
            "0x40137fff7fff8006",
            "0x48127ff97fff8000",
            "0x48127ff97fff8000",
            "0x48127ff97fff8000",
            "0x480a80037fff8000",
            "0x480a80047fff8000",
            "0x480a80057fff8000",
            "0x480a80067fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffaaa",
            "0x40137ffb7fff8007",
            "0x40137ffc7fff8008",
            "0x480680017fff8000",
            "0x0",
            "0x480680017fff8000",
            "0x0",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffa55",
            "0x482480017fff8000",
            "0x800000000000011000000000000000000000000000000000000000000000000",
            "0x20680017fff7fff",
            "0xa",
            "0x480a80077fff8000",
            "0x480a80087fff8000",
            "0x48127ffb7fff8000",
            "0x480a7ff67fff8000",
            "0x480a7ff77fff8000",
            "0x480a7ff87fff8000",
            "0x480a7ff97fff8000",
            "0x208b7fff7fff7ffe",
            "0x480a80077fff8000",
            "0x480a80087fff8000",
            "0x48127ffb7fff8000",
            "0x480a7ff67fff8000",
            "0x480a7ff77fff8000",
            "0x480a80037fff8000",
            "0x480a80047fff8000",
            "0x480a80057fff8000",
            "0x480a80067fff8000",
            "0x1104800180018000",
            "0x114",
            "0x40137ffe7fff8009",
            "0x40137fff7fff800a",
            "0x48127ffd7fff8000",
            "0x480a80097fff8000",
            "0x480a800a7fff8000",
            "0x480a7ff87fff8000",
            "0x480a7ff97fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff9c6",
            "0x482480017fff8000",
            "0x800000000000011000000000000000000000000000000000000000000000000",
            "0x20680017fff7fff",
            "0x13",
            "0x48127ffd7fff8000",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x480a80097fff8000",
            "0x480a800a7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff9bb",
            "0x400680017fff7fff",
            "0x1",
            "0x48127f967fff8000",
            "0x48127f967fff8000",
            "0x48127ffc7fff8000",
            "0x480a7ff67fff8000",
            "0x480a7ff77fff8000",
            "0x480a80097fff8000",
            "0x480a800a7fff8000",
            "0x208b7fff7fff7ffe",
            "0x48127fc87fff8000",
            "0x48127fc87fff8000",
            "0x48127ffb7fff8000",
            "0x480a7ff87fff8000",
            "0x480a7ff97fff8000",
            "0x480a80057fff8000",
            "0x480a80067fff8000",
            "0x480a80037fff8000",
            "0x480a80047fff8000",
            "0x1104800180018000",
            "0xeb",
            "0x40137ffe7fff800b",
            "0x40137fff7fff800c",
            "0x48127ffd7fff8000",
            "0x480a800b7fff8000",
            "0x480a800c7fff8000",
            "0x480a7ff67fff8000",
            "0x480a7ff77fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff99d",
            "0x400680017fff7fff",
            "0x1",
            "0x48127ffe7fff8000",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x480a800b7fff8000",
            "0x480a800c7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff994",
            "0x400680017fff7fff",
            "0x1",
            "0x48127f977fff8000",
            "0x48127f977fff8000",
            "0x48127ffc7fff8000",
            "0x480a800b7fff8000",
            "0x480a800c7fff8000",
            "0x480a7ff87fff8000",
            "0x480a7ff97fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x9",
            "0x480a7ff67fff8000",
            "0x480a7ff77fff8000",
            "0x480a7ff87fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffbb7",
            "0x40137fff7fff8000",
            "0x482680017ffa8000",
            "0x800000000000011000000000000000000000000000000000000000000000000",
            "0x48317fff80007ff9",
            "0x20680017fff7fff",
            "0x6",
            "0x48127ffa7fff8000",
            "0x48127ffa7fff8000",
            "0x48127ffa7fff8000",
            "0x208b7fff7fff7ffe",
            "0x48127ffa7fff8000",
            "0x48127ffa7fff8000",
            "0x48127ffa7fff8000",
            "0x480280007ffc8000",
            "0x480280017ffc8000",
            "0x1104800180018000",
            "0x52",
            "0x40137ffe7fff8001",
            "0x480280007ffc8000",
            "0x4828800180007fff",
            "0x20680017fff7fff",
            "0xa",
            "0x400780017fff8002",
            "0x0",
            "0x400780017fff8003",
            "0x0",
            "0x400380027ffb8004",
            "0x400380037ffb8005",
            "0x10780017fff7fff",
            "0x8",
            "0x400380027ffb8002",
            "0x400380037ffb8003",
            "0x400780017fff8004",
            "0x0",
            "0x400780017fff8005",
            "0x0",
            "0x48127ffb7fff8000",
            "0x480a7ff97fff8000",
            "0x482680017ffa8000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffffe",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff8ac",
            "0x482480017fff8000",
            "0x800000000000011000000000000000000000000000000000000000000000000",
            "0x20680017fff7fff",
            "0x11",
            "0x48127fd87fff8000",
            "0x48127fd87fff8000",
            "0x48127ffb7fff8000",
            "0x480a80007fff8000",
            "0x480280017ffc8000",
            "0x480280027ffc8000",
            "0x1104800180018000",
            "0x4b",
            "0x40137fff7fff8007",
            "0x400b80077fff8006",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x10780017fff7fff",
            "0x6",
            "0x400b7ffd7fff8006",
            "0x48127fd87fff8000",
            "0x48127fd87fff8000",
            "0x48127ffb7fff8000",
            "0x480a80007fff8000",
            "0x480280007ffc8000",
            "0x480280017ffc8000",
            "0x1104800180018000",
            "0x3b",
            "0x40137fff7fff8008",
            "0x48127ffc7fff8000",
            "0x48127ffd7fff8000",
            "0x480a80087fff8000",
            "0x480a80027fff8000",
            "0x480a80037fff8000",
            "0x480a80047fff8000",
            "0x480a80057fff8000",
            "0x480a80067fff8000",
            "0x480680017fff8000",
            "0x0",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffb17",
            "0x48127ffe7fff8000",
            "0x48127fdf7fff8000",
            "0x48127ffd7fff8000",
            "0x482680017ff98000",
            "0x1",
            "0x480a7ffa7fff8000",
            "0x482680017ffb8000",
            "0x2",
            "0x482680017ffc8000",
            "0x1",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffff9c",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x2",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff7d3",
            "0x480a7ffb7fff8000",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff86f",
            "0x482480017fff8000",
            "0x800000000000011000000000000000000000000000000000000000000000000",
            "0x20680017fff7fff",
            "0x6",
            "0x400b7ffc7fff8000",
            "0x400b7ffd7fff8001",
            "0x10780017fff7fff",
            "0x4",
            "0x400b7ffd7fff8000",
            "0x400b7ffc7fff8001",
            "0x480a80007fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff7bc",
            "0x480a7ff97fff8000",
            "0x480a7ffa7fff8000",
            "0x48127ff87fff8000",
            "0x480a80007fff8000",
            "0x480a80017fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x3",
            "0x480a7ff87fff8000",
            "0x480a7ff97fff8000",
            "0x480a7ffa7fff8000",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffdc",
            "0x40137ffe7fff8000",
            "0x40137fff7fff8001",
            "0x48127ffb7fff8000",
            "0x48127ffc7fff8000",
            "0x480a7ffb7fff8000",
            "0x480a80007fff8000",
            "0x480a80017fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffaf1",
            "0x40137fff7fff8002",
            "0x48127ffd7fff8000",
            "0x48127fe07fff8000",
            "0x48127ffc7fff8000",
            "0x480a80027fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x6",
            "0x480a7ff87fff8000",
            "0x480a7ff97fff8000",
            "0x480a7ffa7fff8000",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffc4",
            "0x40137ffe7fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x480a7ffb7fff8000",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffd9",
            "0x40137fff7fff8001",
            "0x48127ffc7fff8000",
            "0x48127ffd7fff8000",
            "0x480a80017fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffa71",
            "0x40137ffb7fff8002",
            "0x40137ffc7fff8003",
            "0x40137ffd7fff8004",
            "0x40137ffe7fff8005",
            "0x4829800080007ffc",
            "0x20680017fff7fff",
            "0xa",
            "0x48127ff87fff8000",
            "0x48127fdf7fff8000",
            "0x48127ff77fff8000",
            "0x480a80027fff8000",
            "0x480a80037fff8000",
            "0x480a80047fff8000",
            "0x480a80057fff8000",
            "0x208b7fff7fff7ffe",
            "0x48127ff87fff8000",
            "0x48127fdf7fff8000",
            "0x48127ff77fff8000",
            "0x480a80047fff8000",
            "0x480a80057fff8000",
            "0x480a80027fff8000",
            "0x480a80037fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x2",
            "0x480a7ff77fff8000",
            "0x480680017fff8000",
            "0x0",
            "0x480680017fff8000",
            "0x0",
            "0x480a7ff87fff8000",
            "0x480a7ff97fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff8a1",
            "0x400680017fff7fff",
            "0x1",
            "0x48127ffe7fff8000",
            "0x480680017fff8000",
            "0x0",
            "0x480680017fff8000",
            "0x0",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff896",
            "0x48127ffe7fff8000",
            "0x480680017fff8000",
            "0x0",
            "0x480680017fff8000",
            "0x0",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff88d",
            "0x400680017fff7fd7",
            "0x1",
            "0x400680017fff7fff",
            "0x1",
            "0x480a7ff57fff8000",
            "0x480a7ff67fff8000",
            "0x48127ffc7fff8000",
            "0x480a7ff87fff8000",
            "0x480a7ff97fff8000",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff94d",
            "0x40137ffb7fff8000",
            "0x40137ffc7fff8001",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff897",
            "0x480a80007fff8000",
            "0x480a80017fff8000",
            "0x48127ff97fff8000",
            "0x48127ff97fff8000",
            "0x48127ff97fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x8",
            "0x480a7ff77fff8000",
            "0x480680017fff8000",
            "0x0",
            "0x480680017fff8000",
            "0x0",
            "0x480a7ff87fff8000",
            "0x480a7ff97fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff869",
            "0x400680017fff7fff",
            "0x1",
            "0x48127ffe7fff8000",
            "0x480680017fff8000",
            "0x0",
            "0x480680017fff8000",
            "0x0",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff85e",
            "0x48127ffe7fff8000",
            "0x480680017fff8000",
            "0x0",
            "0x480680017fff8000",
            "0x0",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff855",
            "0x400680017fff7fd7",
            "0x1",
            "0x400680017fff7fff",
            "0x1",
            "0x480a7ff57fff8000",
            "0x480a7ff67fff8000",
            "0x48127ffc7fff8000",
            "0x480a7ff87fff8000",
            "0x480a7ff97fff8000",
            "0x480680017fff8000",
            "0x3e5",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff939",
            "0x40137ffe7fff8000",
            "0x40137fff7fff8001",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x480a80007fff8000",
            "0x480a80017fff8000",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff90a",
            "0x40137ffe7fff8002",
            "0x40137fff7fff8003",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x480680017fff8000",
            "0x3e8",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff923",
            "0x480a80007fff8000",
            "0x480a80017fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff8c4",
            "0x40137ffe7fff8004",
            "0x40137fff7fff8005",
            "0x40137ffb7fff8006",
            "0x40137ffc7fff8007",
            "0x48127ffd7fff8000",
            "0x480a80027fff8000",
            "0x480a80037fff8000",
            "0x480a80047fff8000",
            "0x480a80057fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff840",
            "0x480a80067fff8000",
            "0x480a80077fff8000",
            "0x48127ff97fff8000",
            "0x48127ff97fff8000",
            "0x48127ff97fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x6",
            "0x480a7ff77fff8000",
            "0x480680017fff8000",
            "0x0",
            "0x480680017fff8000",
            "0x0",
            "0x480a7ff87fff8000",
            "0x480a7ff97fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff812",
            "0x400680017fff7fff",
            "0x1",
            "0x48127ffe7fff8000",
            "0x480680017fff8000",
            "0x0",
            "0x480680017fff8000",
            "0x0",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff807",
            "0x48127ffe7fff8000",
            "0x480680017fff8000",
            "0x0",
            "0x480680017fff8000",
            "0x0",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff7fe",
            "0x400680017fff7fd7",
            "0x1",
            "0x400680017fff7fff",
            "0x1",
            "0x480a7ff57fff8000",
            "0x480a7ff67fff8000",
            "0x48127ffc7fff8000",
            "0x480a7ff87fff8000",
            "0x480a7ff97fff8000",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff8be",
            "0x480680017fff8000",
            "0x3e8",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff8de",
            "0x40137ffe7fff8000",
            "0x40137fff7fff8001",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x480a7ff87fff8000",
            "0x480a7ff97fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff88f",
            "0x480680017fff8000",
            "0x3e5",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff8cf",
            "0x40137ffb7fff8002",
            "0x40137ffc7fff8003",
            "0x48127ffd7fff8000",
            "0x480a80007fff8000",
            "0x480a80017fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff7f2",
            "0x480a80027fff8000",
            "0x480a80037fff8000",
            "0x48127ff97fff8000",
            "0x48127ff97fff8000",
            "0x48127ff97fff8000",
            "0x480680017fff8000",
            "0x1",
            "0x480680017fff8000",
            "0x0",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff860",
            "0x40137ffe7fff8004",
            "0x40137fff7fff8005",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x480a80047fff8000",
            "0x480a80057fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x1",
            "0x480a7ff87fff8000",
            "0x480680017fff8000",
            "0x2",
            "0x480a7ffc7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff68c",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff669",
            "0x40137fff7fff8000",
            "0x480a7ff67fff8000",
            "0x480a7ff77fff8000",
            "0x48127ffa7fff8000",
            "0x480a7ff97fff8000",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x480680017fff8000",
            "0x0",
            "0x480a7ffc7fff8000",
            "0x480a7ffd7fff8000",
            "0x480a80007fff8000",
            "0x1104800180018000",
            "0x7",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x480a80007fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x6",
            "0x48297ffb80007ffa",
            "0x20680017fff7fff",
            "0x7",
            "0x480a7ff47fff8000",
            "0x480a7ff57fff8000",
            "0x480a7ff67fff8000",
            "0x480a7ffd7fff8000",
            "0x208b7fff7fff7ffe",
            "0x20780017fff7ffa",
            "0x9",
            "0x400380007ffd7ff8",
            "0x400380017ffd7ff9",
            "0x480a7ff47fff8000",
            "0x480a7ff57fff8000",
            "0x480a7ff67fff8000",
            "0x10780017fff7fff",
            "0x20",
            "0x480a7ff47fff8000",
            "0x480a7ff57fff8000",
            "0x480a7ff67fff8000",
            "0x480a7ff77fff8000",
            "0x48027fff7ffc8000",
            "0x480280007ffc8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffeb2",
            "0x40137ffc7fff8000",
            "0x40137ffd7fff8001",
            "0x40137ffe7fff8002",
            "0x40137fff7fff8003",
            "0x48127ff97fff8000",
            "0x48127ff97fff8000",
            "0x48127ff97fff8000",
            "0x48027ffe7ffd8000",
            "0x48027fff7ffd8000",
            "0x480a80007fff8000",
            "0x480a80017fff8000",
            "0x480a80027fff8000",
            "0x480a80037fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffff0a",
            "0x40137ffe7fff8004",
            "0x40137fff7fff8005",
            "0x400380007ffd8004",
            "0x400380017ffd8005",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x480a7ff77fff8000",
            "0x480a7ff87fff8000",
            "0x480a7ff97fff8000",
            "0x482680017ffa8000",
            "0x1",
            "0x480a7ffb7fff8000",
            "0x482680017ffc8000",
            "0x1",
            "0x482680017ffd8000",
            "0x2",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffc6",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x1",
            "0x480a7ff87fff8000",
            "0x480680017fff8000",
            "0x2",
            "0x480a7ffc7fff8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff631",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffff60e",
            "0x40137fff7fff8000",
            "0x482680017ffc8000",
            "0x800000000000011000000000000000000000000000000000000000000000000",
            "0x482680017ffc8000",
            "0x800000000000011000000000000000000000000000000000000000000000000",
            "0x484480017fff8000",
            "0x2",
            "0x480a7ff67fff8000",
            "0x480a7ff77fff8000",
            "0x48127ff77fff8000",
            "0x480a7ff97fff8000",
            "0x480a7ffa7fff8000",
            "0x480a7ffb7fff8000",
            "0x482680017ffc8000",
            "0x800000000000011000000000000000000000000000000000000000000000000",
            "0x480a7ffc7fff8000",
            "0x48327ff57ffd8000",
            "0x48327ff680008000",
            "0x1104800180018000",
            "0x7",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x48127ffc7fff8000",
            "0x480a80007fff8000",
            "0x208b7fff7fff7ffe",
            "0x40780017fff7fff",
            "0x6",
            "0x482680017ffb8000",
            "0x800000000000011000000000000000000000000000000000000000000000000",
            "0x48317fff80007ffa",
            "0x20680017fff7fff",
            "0x9",
            "0x400380007ffd7ff8",
            "0x400380017ffd7ff9",
            "0x480a7ff47fff8000",
            "0x480a7ff57fff8000",
            "0x480a7ff67fff8000",
            "0x10780017fff7fff",
            "0x20",
            "0x480a7ff47fff8000",
            "0x480a7ff57fff8000",
            "0x480a7ff67fff8000",
            "0x480a7ff77fff8000",
            "0x480280007ffc8000",
            "0x480280017ffc8000",
            "0x1104800180018000",
            "0x800000000000010fffffffffffffffffffffffffffffffffffffffffffffe56",
            "0x40137ffc7fff8000",
            "0x40137ffd7fff8001",
            "0x40137ffe7fff8002",
            "0x40137fff7fff8003",
            "0x48127ff97fff8000",
            "0x48127ff97fff8000",
            "0x48127ff97fff8000",
            "0x480280027ffd8000",
            "0x480280037ffd8000",
            "0x480a80007fff8000",
            "0x480a80017fff8000",
            "0x480a80027fff8000",
            "0x480a80037fff8000",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffff05",
            "0x40137ffe7fff8004",
            "0x40137fff7fff8005",
            "0x400380007ffd8004",
            "0x400380017ffd8005",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x48127ffb7fff8000",
            "0x20780017fff7ffa",
            "0x4",
            "0x480a7ffd7fff8000",
            "0x208b7fff7fff7ffe",
            "0x480a7ff77fff8000",
            "0x480a7ff87fff8000",
            "0x480a7ff97fff8000",
            "0x482680017ffa8000",
            "0x800000000000011000000000000000000000000000000000000000000000000",
            "0x480a7ffb7fff8000",
            "0x482680017ffc8000",
            "0x800000000000011000000000000000000000000000000000000000000000000",
            "0x482680017ffd8000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffff",
            "0x1104800180018000",
            "0x800000000000010ffffffffffffffffffffffffffffffffffffffffffffffc7",
            "0x208b7fff7fff7ffe"
        ],
        "debug_info": {
            "file_contents": {
                "autogen/starknet/arg_processor/00ba9f18f64c439d93f764476ea480f4a827df0fac1851a969a8530cf46ee8e6.cairo": "let __calldata_arg_reserveB = [\n    cast(__calldata_ptr, starkware.cairo.common.uint256.Uint256*)];\nlet __calldata_ptr = __calldata_ptr + 2;\n",
                "autogen/starknet/arg_processor/01cba52f8515996bb9d7070bde81ff39281d096d7024a558efcba6e1fd2402cf.cairo": "assert [cast(fp + (-4), felt*)] = __calldata_actual_size;\n",
                "autogen/starknet/arg_processor/0bbb4db1f683034b90d6f17e60bfa5cd5ee15bdb643a1d1dddc7c2f180240954.cairo": "let __return_value_arg_reserve0 = [\n    cast(__return_value_ptr, starkware.cairo.common.uint256.Uint256*)];\nlet __return_value_ptr = __return_value_ptr + 2;\n",
                "autogen/starknet/arg_processor/0e50dc7e3464c61dfc8bf6e8438814b90aa1f118ccaf9ae72a2f3ad40c8e2ab6.cairo": "let __calldata_arg_reserveA = [\n    cast(__calldata_ptr, starkware.cairo.common.uint256.Uint256*)];\nlet __calldata_ptr = __calldata_ptr + 2;\n",
                "autogen/starknet/arg_processor/10f4ffaf96982a2fff2ff72dc2d3b1a8878257148aab4051a8f2ef7f16687f01.cairo": "assert [__return_value_ptr] = ret_value.address;\nlet __return_value_ptr = __return_value_ptr + 1;\n",
                "autogen/starknet/arg_processor/17555969c7a8d33c61486b2958282949e335d6b0bb9fd10ced86d32ba19f948c.cairo": "let __calldata_arg_deadline = [__calldata_ptr];\nlet __calldata_ptr = __calldata_ptr + 1;\n",
                "autogen/starknet/arg_processor/1a2c645314c38f7cbacc4fe8f48f8ae2ef9e43d925286d804280d816fb1e6157.cairo": "let __calldata_arg_path_len = [__calldata_ptr];\nlet __calldata_ptr = __calldata_ptr + 1;\n",
                "autogen/starknet/arg_processor/1b89ef2fbbb6943f8dd42dcbdc1e699db576228e665ff8b5ae3c1c3c39cb1777.cairo": "assert [__calldata_ptr] = newAdmin;\nlet __calldata_ptr = __calldata_ptr + 1;\n",
                "autogen/starknet/arg_processor/2670bb539ede27446c75876e41bcf9ef5cab09b9eec143f3986635a545b089ab.cairo": "assert [__calldata_ptr] = to;\nlet __calldata_ptr = __calldata_ptr + 1;\n",
                "autogen/starknet/arg_processor/32dd5497db578c846b634f25154d0ffb622256766f5d2360a53f46e9c348ed3e.cairo": "assert [__calldata_ptr] = recipient;\nlet __calldata_ptr = __calldata_ptr + 1;\n",
                "autogen/starknet/arg_processor/3898d272a0eb2fc039a70373a33534479a08886bd010ab7cd60ff7481a0b36b0.cairo": "let __return_value_arg_liquidity = [\n    cast(__return_value_ptr, starkware.cairo.common.uint256.Uint256*)];\nlet __return_value_ptr = __return_value_ptr + 2;\n",
                "autogen/starknet/arg_processor/3d3dc23ab365a951396ce59f8eedd1e831e497c4570a1739aedd8a47e436f041.cairo": "let __calldata_arg_amountBMin = [\n    cast(__calldata_ptr, starkware.cairo.common.uint256.Uint256*)];\nlet __calldata_ptr = __calldata_ptr + 2;\n",
                "autogen/starknet/arg_processor/3d6e81bf1402793cfbd97151fd631ccb7424cd79d2c40557aa18360f58ac0d92.cairo": "// Create a reference to ret_value.amountB as felt*.\nlet __return_value_tmp: felt* = cast(&ret_value.amountB, felt*);\nassert [__return_value_ptr + 0] = [__return_value_tmp + 0];\nassert [__return_value_ptr + 1] = [__return_value_tmp + 1];\nlet __return_value_ptr = __return_value_ptr + 2;\n",
                "autogen/starknet/arg_processor/3f2af33c875170cc67ae2f432d9bbef7059830e387aaef0a4e6fb0dbf7fc2331.cairo": "let __calldata_arg_proxy_admin = [__calldata_ptr];\nlet __calldata_ptr = __calldata_ptr + 1;\n",
                "autogen/starknet/arg_processor/42a70584789ec6af58b985f7cfafbd6fdb41a760213209f7cdca7fe844ae35b0.cairo": "let __calldata_arg_amountBDesired = [\n    cast(__calldata_ptr, starkware.cairo.common.uint256.Uint256*)];\nlet __calldata_ptr = __calldata_ptr + 2;\n",
                "autogen/starknet/arg_processor/4b5f5725e23556473d888ce93982c14d400a4f093e48b12b62f71ccb4cc2b54d.cairo": "let __return_value_arg_pair = [__return_value_ptr];\nlet __return_value_ptr = __return_value_ptr + 1;\n",
                "autogen/starknet/arg_processor/502707e41b9f24cc24d98a88f76cd4304e8e77c4465a6a9402a5f1a76177b453.cairo": "// Create a reference to amount as felt*.\nlet __calldata_tmp: felt* = cast(&amount, felt*);\nassert [__calldata_ptr + 0] = [__calldata_tmp + 0];\nassert [__calldata_ptr + 1] = [__calldata_tmp + 1];\nlet __calldata_ptr = __calldata_ptr + 2;\n",
                "autogen/starknet/arg_processor/5acfd8e875f992ccc9524eb75bc3cf4970bff0987c9ed04b83bdbe0bdb94c8b5.cairo": "assert [__calldata_ptr] = data_len;\nlet __calldata_ptr = __calldata_ptr + 1;\n",
                "autogen/starknet/arg_processor/5ec6d3da377064fcca5c6ce7ac91ed695c6086c63198cd266d6f1f3f80005101.cairo": "let __calldata_arg_tokenA = [__calldata_ptr];\nlet __calldata_ptr = __calldata_ptr + 1;\n",
                "autogen/starknet/arg_processor/60d502dffaf75d94b1a0d2472940f82cad57b9f6a4b30ab530ff0d49bc6332b0.cairo": "let __return_value_arg_reserve1 = [\n    cast(__return_value_ptr, starkware.cairo.common.uint256.Uint256*)];\nlet __return_value_ptr = __return_value_ptr + 2;\n",
                "autogen/starknet/arg_processor/635ec603a576c378b96508409ebffc1de2cc10639922f57a6f56f1417ab7afa6.cairo": "assert [__calldata_ptr] = previousAdmin;\nlet __calldata_ptr = __calldata_ptr + 1;\n",
                "autogen/starknet/arg_processor/712c2a95dbae039e77112e72178da3f83d21edfc1285b387ccfa43e0f878d990.cairo": "assert [__calldata_ptr] = sender;\nlet __calldata_ptr = __calldata_ptr + 1;\n",
                "autogen/starknet/arg_processor/7502b492c5c118f29faec2b621e16fec645a16e1b8dcaa989b13a716493f50f1.cairo": "let __calldata_arg_amountInMax = [\n    cast(__calldata_ptr, starkware.cairo.common.uint256.Uint256*)];\nlet __calldata_ptr = __calldata_ptr + 2;\n",
                "autogen/starknet/arg_processor/7875b68d87f9cca58f508e76869fd2eebb18903ded7de666e8766355a432c2e8.cairo": "let __calldata_arg_amountOutMin = [\n    cast(__calldata_ptr, starkware.cairo.common.uint256.Uint256*)];\nlet __calldata_ptr = __calldata_ptr + 2;\n",
                "autogen/starknet/arg_processor/7e52648a617a4c3a88d50b1f12aea1669d4b3a7bb8d0d03929df3d5c4e884777.cairo": "assert [__calldata_ptr] = token1;\nlet __calldata_ptr = __calldata_ptr + 1;\n",
                "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo": "// Check that the length is non-negative.\nassert [range_check_ptr] = ret_value.amounts_len;\n// Store the updated range_check_ptr as a local variable to keep it available after\n// the memcpy.\nlocal range_check_ptr = range_check_ptr + 1;\n// Keep a reference to __return_value_ptr.\nlet __return_value_ptr_copy = __return_value_ptr;\n// Store the updated __return_value_ptr as a local variable to keep it available after\n// the memcpy.\nlocal __return_value_ptr: felt* = __return_value_ptr + ret_value.amounts_len * 2;\nmemcpy(\n    dst=__return_value_ptr_copy,\n    src=ret_value.amounts,\n    len=ret_value.amounts_len * 2);\n",
                "autogen/starknet/arg_processor/822bb83e1247a59010b6591225aa0f16d76cb779d44ca4ac9311332f265a6ded.cairo": "let __calldata_arg_reserveOut = [\n    cast(__calldata_ptr, starkware.cairo.common.uint256.Uint256*)];\nlet __calldata_ptr = __calldata_ptr + 2;\n",
                "autogen/starknet/arg_processor/8bc8cfbc6cba45ab45c5ec8589791e63546611f512de3c2af08c408ce66a1567.cairo": "let __return_value_arg_block_timestamp_last = [__return_value_ptr];\nlet __return_value_ptr = __return_value_ptr + 1;\n",
                "autogen/starknet/arg_processor/8ca78f1082aaedcf62c13aff4dffe5943aa04164c60cb6d9f03916c0319201df.cairo": "let __return_value_arg_success = [__return_value_ptr];\nlet __return_value_ptr = __return_value_ptr + 1;\n",
                "autogen/starknet/arg_processor/8e4bcd6f8d677f2e66b290c53d722de492b60870d51c9e55b49225a1070f28c7.cairo": "// Create a reference to ret_value.amountIn as felt*.\nlet __return_value_tmp: felt* = cast(&ret_value.amountIn, felt*);\nassert [__return_value_ptr + 0] = [__return_value_tmp + 0];\nassert [__return_value_ptr + 1] = [__return_value_tmp + 1];\nlet __return_value_ptr = __return_value_ptr + 2;\n",
                "autogen/starknet/arg_processor/8fc2d8ea495404974277495f157efdc441bd2985352b9f48e4f31d6c3ec03f54.cairo": "assert [__return_value_ptr] = ret_value.token1;\nlet __return_value_ptr = __return_value_ptr + 1;\n",
                "autogen/starknet/arg_processor/96447016176d161dae83f414c4d3e33e78856e0ef69bc33f8d419f1c9b90f9c5.cairo": "assert retdata_size = __return_value_actual_size;\n",
                "autogen/starknet/arg_processor/986c88e0c98997b84114350f0cec05c7e65a027842002306de74d06aec114045.cairo": "// Create a reference to ret_value.liquidity as felt*.\nlet __return_value_tmp: felt* = cast(&ret_value.liquidity, felt*);\nassert [__return_value_ptr + 0] = [__return_value_tmp + 0];\nassert [__return_value_ptr + 1] = [__return_value_tmp + 1];\nlet __return_value_ptr = __return_value_ptr + 2;\n",
                "autogen/starknet/arg_processor/9971733a46b660735efbd843b968648fbb6ab1512994825bb1137bbaa294d2fd.cairo": "// Create a reference to ret_value.amountA as felt*.\nlet __return_value_tmp: felt* = cast(&ret_value.amountA, felt*);\nassert [__return_value_ptr + 0] = [__return_value_tmp + 0];\nassert [__return_value_ptr + 1] = [__return_value_tmp + 1];\nlet __return_value_ptr = __return_value_ptr + 2;\n",
                "autogen/starknet/arg_processor/9a221054e9d7400afb4c8befc8f37dcc2ce98e6984fd9029ab1599acbf90b78c.cairo": "let __calldata_arg_amountAMin = [\n    cast(__calldata_ptr, starkware.cairo.common.uint256.Uint256*)];\nlet __calldata_ptr = __calldata_ptr + 2;\n",
                "autogen/starknet/arg_processor/accb388bcbbace5b6218198851efaad2a781c1339e305a37fab51afe90a3194a.cairo": "let __return_value_actual_size =  __return_value_ptr - cast(retdata, felt*);\n",
                "autogen/starknet/arg_processor/b534c21bfdbb6243e78caa5328497edbe6b5537b9fcdefafa8a58f0f9fc425ea.cairo": "let __calldata_arg_amountA = [\n    cast(__calldata_ptr, starkware.cairo.common.uint256.Uint256*)];\nlet __calldata_ptr = __calldata_ptr + 2;\n",
                "autogen/starknet/arg_processor/c31620b02d4d706f0542c989b2aadc01b0981d1f6a5933a8fe4937ace3d70d92.cairo": "let __calldata_actual_size =  __calldata_ptr - cast([cast(fp + (-3), felt**)], felt*);\n",
                "autogen/starknet/arg_processor/c6477613576c1865c350adaa5562664ab5c9cf232374f021ab29a9b963be2b8c.cairo": "let __calldata_arg_amountOut = [\n    cast(__calldata_ptr, starkware.cairo.common.uint256.Uint256*)];\nlet __calldata_ptr = __calldata_ptr + 2;\n",
                "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo": "// Check that the length is non-negative.\nassert [range_check_ptr] = __calldata_arg_path_len;\nlet range_check_ptr = range_check_ptr + 1;\n// Create the reference.\nlet __calldata_arg_path = cast(__calldata_ptr, felt*);\n// Use 'tempvar' instead of 'let' to avoid repeating this computation for the\n// following arguments.\ntempvar __calldata_ptr = __calldata_ptr + __calldata_arg_path_len * 1;\n",
                "autogen/starknet/arg_processor/c8616e403cdb3879194814d809769068a261ed6ee3d1ae1615c53d6ec43e5f09.cairo": "let __calldata_arg_reserveIn = [\n    cast(__calldata_ptr, starkware.cairo.common.uint256.Uint256*)];\nlet __calldata_ptr = __calldata_ptr + 2;\n",
                "autogen/starknet/arg_processor/cd240601384e2a470f06701d141f849870ca17bb9e1bfcb817820bb610b44b47.cairo": "let __calldata_arg_amountADesired = [\n    cast(__calldata_ptr, starkware.cairo.common.uint256.Uint256*)];\nlet __calldata_ptr = __calldata_ptr + 2;\n",
                "autogen/starknet/arg_processor/d0188e44f9bf394c59a5ca0aa00029b3d4a0b84a048a492cac1961341ac80755.cairo": "assert [__return_value_ptr] = ret_value.amounts_len;\nlet __return_value_ptr = __return_value_ptr + 1;\n",
                "autogen/starknet/arg_processor/d63583a300b69459989123381c86383cff8c41871521c1e51e9946f3d6326e08.cairo": "let __return_value_arg_amount0 = [\n    cast(__return_value_ptr, starkware.cairo.common.uint256.Uint256*)];\nlet __return_value_ptr = __return_value_ptr + 2;\n",
                "autogen/starknet/arg_processor/d63e3658a49e97559b3e34f5dbc952c8cd0187c1c7245eb59246b154161c4f62.cairo": "// Create a reference to amount0Out as felt*.\nlet __calldata_tmp: felt* = cast(&amount0Out, felt*);\nassert [__calldata_ptr + 0] = [__calldata_tmp + 0];\nassert [__calldata_ptr + 1] = [__calldata_tmp + 1];\nlet __calldata_ptr = __calldata_ptr + 2;\n",
                "autogen/starknet/arg_processor/d96c59fe9d07937fbeed76dc85f00c78962225e82bc2df89f7dc0a139faf5789.cairo": "assert [__return_value_ptr] = ret_value.token0;\nlet __return_value_ptr = __return_value_ptr + 1;\n",
                "autogen/starknet/arg_processor/dceaabc265216c0ff87c509d90446554648ec4d692c521cda952464d1fc22972.cairo": "let __calldata_arg_to = [__calldata_ptr];\nlet __calldata_ptr = __calldata_ptr + 1;\n",
                "autogen/starknet/arg_processor/dd895a53cb6996926540ce0f7dd696481177552e37c98f699525c5a80806fd21.cairo": "let __return_value_arg_amount1 = [\n    cast(__return_value_ptr, starkware.cairo.common.uint256.Uint256*)];\nlet __return_value_ptr = __return_value_ptr + 2;\n",
                "autogen/starknet/arg_processor/e2bbbf52ff1f738a8284ca33e1a014831bd4ccef09e7cd753ec10fd250c6130c.cairo": "let __calldata_arg_liquidity = [\n    cast(__calldata_ptr, starkware.cairo.common.uint256.Uint256*)];\nlet __calldata_ptr = __calldata_ptr + 2;\n",
                "autogen/starknet/arg_processor/f592e43c96a0e5d88593ef440a75312f8beb20e2d1d087f2f18887daf8570e62.cairo": "let __calldata_arg_tokenB = [__calldata_ptr];\nlet __calldata_ptr = __calldata_ptr + 1;\n",
                "autogen/starknet/arg_processor/f697f3f95693ecd76e2d0a3577b6e68442439e583882e0aea09c5a98b23da47b.cairo": "// Create a reference to amount1Out as felt*.\nlet __calldata_tmp: felt* = cast(&amount1Out, felt*);\nassert [__calldata_ptr + 0] = [__calldata_tmp + 0];\nassert [__calldata_ptr + 1] = [__calldata_tmp + 1];\nlet __calldata_ptr = __calldata_ptr + 2;\n",
                "autogen/starknet/arg_processor/f7de35c9223a7892c86748eb0198d5e7ca32c40f8e1de5c9a5ed02d3a6b2c64a.cairo": "let __calldata_arg_amountIn = [\n    cast(__calldata_ptr, starkware.cairo.common.uint256.Uint256*)];\nlet __calldata_ptr = __calldata_ptr + 2;\n",
                "autogen/starknet/arg_processor/f8e22acef04eaa1e1cd12a828bbdd24c5d7032751d174d946b29c2fd9059f4cb.cairo": "let __calldata_arg_factory = [__calldata_ptr];\nlet __calldata_ptr = __calldata_ptr + 1;\n",
                "autogen/starknet/arg_processor/fad4e10ec44d6e93cb02bb2a04f08ca713e54675fbc84bbd271475389af5f4e6.cairo": "// Create a reference to ret_value.amountOut as felt*.\nlet __return_value_tmp: felt* = cast(&ret_value.amountOut, felt*);\nassert [__return_value_ptr + 0] = [__return_value_tmp + 0];\nassert [__return_value_ptr + 1] = [__return_value_tmp + 1];\nlet __return_value_ptr = __return_value_ptr + 2;\n",
                "autogen/starknet/arg_processor/fc1a676dec8441f547a52cd0f6b0a697e33f09e4dfbd52f45ed2ceb1abcf4dac.cairo": "assert [__calldata_ptr] = token0;\nlet __calldata_ptr = __calldata_ptr + 1;\n",
                "autogen/starknet/contract_interface/IERC20/transferFrom/18abb29d0a089e1c243e4bd2b316687e6162b3321c14733ee37389da5d8b074f.cairo": "\nreturn (success=__return_value_arg_success,);\n",
                "autogen/starknet/contract_interface/IERC20/transferFrom/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo": "\nalloc_locals;\nlet (local calldata_ptr_start: felt*) = alloc();\nlet __calldata_ptr = calldata_ptr_start;\n",
                "autogen/starknet/contract_interface/IERC20/transferFrom/b016d077f46f58d4cffabd707b4d3495a859d0f543f418223b1ea57e409144f2.cairo": "\nlet (retdata_size, retdata) = call_contract(\n    contract_address=contract_address,\n    function_selector=TRANSFERFROM_SELECTOR,\n    calldata_size=__calldata_ptr - calldata_ptr_start,\n    calldata=calldata_ptr_start);\n",
                "autogen/starknet/contract_interface/IERC20/transferFrom/f8c7980cd46ed6d764c999318b5692736b724dc08c1bf96e92d1b77ddf37af10.cairo": "func transferFrom{syscall_ptr: felt*, range_check_ptr}(\n    contract_address: felt) {\n}\n",
                "autogen/starknet/contract_interface/IFactory/create_pair/618aec1c118db579d886a51a8bd51a40857c890f7d94163fb7cceb342c27e778.cairo": "\nreturn (pair=__return_value_arg_pair,);\n",
                "autogen/starknet/contract_interface/IFactory/create_pair/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo": "\nalloc_locals;\nlet (local calldata_ptr_start: felt*) = alloc();\nlet __calldata_ptr = calldata_ptr_start;\n",
                "autogen/starknet/contract_interface/IFactory/create_pair/9a3616088dd2ae01329a4d6c83171bbd7c656edfe550d843d4dde0a8869d322b.cairo": "\nlet (retdata_size, retdata) = call_contract(\n    contract_address=contract_address,\n    function_selector=CREATE_PAIR_SELECTOR,\n    calldata_size=__calldata_ptr - calldata_ptr_start,\n    calldata=calldata_ptr_start);\n",
                "autogen/starknet/contract_interface/IFactory/create_pair/ee84ebacd8edbc2e3304e1a9e2de38e564d83099cfaf093085de1c7a1339654f.cairo": "func create_pair{syscall_ptr: felt*, range_check_ptr}(\n    contract_address: felt) {\n}\n",
                "autogen/starknet/contract_interface/IFactory/get_pair/618aec1c118db579d886a51a8bd51a40857c890f7d94163fb7cceb342c27e778.cairo": "\nreturn (pair=__return_value_arg_pair,);\n",
                "autogen/starknet/contract_interface/IFactory/get_pair/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo": "\nalloc_locals;\nlet (local calldata_ptr_start: felt*) = alloc();\nlet __calldata_ptr = calldata_ptr_start;\n",
                "autogen/starknet/contract_interface/IFactory/get_pair/8b44652aeeab676a97be5d73e292165bb2089705221cc1f09c47aa21e53ee70a.cairo": "\nlet (retdata_size, retdata) = call_contract(\n    contract_address=contract_address,\n    function_selector=GET_PAIR_SELECTOR,\n    calldata_size=__calldata_ptr - calldata_ptr_start,\n    calldata=calldata_ptr_start);\n",
                "autogen/starknet/contract_interface/IFactory/get_pair/f3ae4d3d9703c6ba4fff0fd10db0740f6555616f3de5dffdec4e7ebb2533c4cb.cairo": "func get_pair{syscall_ptr: felt*, range_check_ptr}(\n    contract_address: felt) {\n}\n",
                "autogen/starknet/contract_interface/IPair/burn/11db19d274ee9484ba9eafc640748e2ada8ec4c37d82f4f687f64a32873361f1.cairo": "\nreturn (amount0=__return_value_arg_amount0, amount1=__return_value_arg_amount1,);\n",
                "autogen/starknet/contract_interface/IPair/burn/484b5f727aa4fe2fdbfbe21899bf52ecd5bf948dde26ab2d509b57812ab8a3cd.cairo": "func burn{syscall_ptr: felt*, range_check_ptr}(\n    contract_address: felt) {\n}\n",
                "autogen/starknet/contract_interface/IPair/burn/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo": "\nalloc_locals;\nlet (local calldata_ptr_start: felt*) = alloc();\nlet __calldata_ptr = calldata_ptr_start;\n",
                "autogen/starknet/contract_interface/IPair/burn/fb4dff06d8f249f0a46961fa65ce84ff34cc1bf90a1bb875b704d1f11cfcc4ee.cairo": "\nlet (retdata_size, retdata) = call_contract(\n    contract_address=contract_address,\n    function_selector=BURN_SELECTOR,\n    calldata_size=__calldata_ptr - calldata_ptr_start,\n    calldata=calldata_ptr_start);\n",
                "autogen/starknet/contract_interface/IPair/get_reserves/2e31409c9dc0642dd5cf35f3ef58c7f3f9235f4f67d2369960bb668d510e019a.cairo": "func get_reserves{syscall_ptr: felt*, range_check_ptr}(\n    contract_address: felt) {\n}\n",
                "autogen/starknet/contract_interface/IPair/get_reserves/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo": "\nalloc_locals;\nlet (local calldata_ptr_start: felt*) = alloc();\nlet __calldata_ptr = calldata_ptr_start;\n",
                "autogen/starknet/contract_interface/IPair/get_reserves/a9898d577a164da2fc5dd0b43022c9d1f1cc5882b74dc901eb9d81b08cdb34ef.cairo": "\nlet (retdata_size, retdata) = call_contract(\n    contract_address=contract_address,\n    function_selector=GET_RESERVES_SELECTOR,\n    calldata_size=__calldata_ptr - calldata_ptr_start,\n    calldata=calldata_ptr_start);\n",
                "autogen/starknet/contract_interface/IPair/get_reserves/ea038c1bf6d61b95181665c6d7a77d3c8844bc0516ba2d46de4161456f7280f7.cairo": "\nreturn (reserve0=__return_value_arg_reserve0, reserve1=__return_value_arg_reserve1, block_timestamp_last=__return_value_arg_block_timestamp_last,);\n",
                "autogen/starknet/contract_interface/IPair/mint/304ae580b78eb48c6532c7788d73eef2a89dcd3c406dfb71c0bdbeacea1728ca.cairo": "\nlet (retdata_size, retdata) = call_contract(\n    contract_address=contract_address,\n    function_selector=MINT_SELECTOR,\n    calldata_size=__calldata_ptr - calldata_ptr_start,\n    calldata=calldata_ptr_start);\n",
                "autogen/starknet/contract_interface/IPair/mint/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo": "\nalloc_locals;\nlet (local calldata_ptr_start: felt*) = alloc();\nlet __calldata_ptr = calldata_ptr_start;\n",
                "autogen/starknet/contract_interface/IPair/mint/d1654cc35d4e19a6f5eb06a6a8498269598158ae1678134b6b98c828f3eee941.cairo": "\nreturn (liquidity=__return_value_arg_liquidity,);\n",
                "autogen/starknet/contract_interface/IPair/mint/f331841f676ee38d6b90045cd4589cd9ff4e83c32234273d9e605b287d886cbc.cairo": "func mint{syscall_ptr: felt*, range_check_ptr}(\n    contract_address: felt) {\n}\n",
                "autogen/starknet/contract_interface/IPair/swap/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo": "\nalloc_locals;\nlet (local calldata_ptr_start: felt*) = alloc();\nlet __calldata_ptr = calldata_ptr_start;\n",
                "autogen/starknet/contract_interface/IPair/swap/a698a4a1c6e7fbaf8535ba0f61a54c85006889c49fbeabc3ae990c185771e4da.cairo": "func swap{syscall_ptr: felt*, range_check_ptr}(\n    contract_address: felt) {\n}\n",
                "autogen/starknet/contract_interface/IPair/swap/f126f38e3113fe38a10b48615a23c6c6af7986a8395c8dfdd4d166bf664e76dc.cairo": "\nlet (retdata_size, retdata) = call_contract(\n    contract_address=contract_address,\n    function_selector=SWAP_SELECTOR,\n    calldata_size=__calldata_ptr - calldata_ptr_start,\n    calldata=calldata_ptr_start);\n",
                "autogen/starknet/contract_interface/IPair/swap/fb06bdf6e8fa9c7ff7d919d712d494716b0b586b2a762687cfb72d93da06ab29.cairo": "\nreturn ();\n",
                "autogen/starknet/event/AdminChanged/6150feec30bd48bfd0f446ed8c155a6d911a2c3fb3ec7a980733900416819259.cairo": "emit_event(keys_len=1, keys=__keys_ptr, data_len=__calldata_ptr - __data_ptr, data=__data_ptr);\nreturn ();\n",
                "autogen/starknet/event/AdminChanged/8220fde17ca5479f12ae71a8036f4d354fe722f2c036da610b53511924e4ee84.cairo": "alloc_locals;\nlet (local __keys_ptr: felt*) = alloc();\nassert [__keys_ptr] = SELECTOR;\nlet (local __data_ptr: felt*) = alloc();\nlet __calldata_ptr = __data_ptr;\n",
                "autogen/starknet/event/AdminChanged/a7a8ae41be29ac9f4f6c3b7837c448d787ca051dd1ade98f409e54d33d112504.cairo": "func emit{syscall_ptr: felt*, range_check_ptr}() {\n}\n",
                "autogen/starknet/external/add_liquidity/741ea357d6336b0bed7bf0472425acd0311d543883b803388880e60a232040c7.cairo": "let range_check_ptr = [cast([cast(fp + (-5), felt**)] + 2, felt*)];\n",
                "autogen/starknet/external/add_liquidity/9684a85e93c782014ca14293edea4eb2502039a5a7b6538ecd39c56faaf12529.cairo": "let pedersen_ptr = [cast([cast(fp + (-5), felt**)] + 1, starkware.cairo.common.cairo_builtins.HashBuiltin**)];\n",
                "autogen/starknet/external/add_liquidity/969479459fd3b867ae28190d9fc610d9ccb75b0d7783925f9dbefd7a80e1ce80.cairo": "let ret_value = __wrapped_func{syscall_ptr=syscall_ptr, pedersen_ptr=pedersen_ptr, range_check_ptr=range_check_ptr}(tokenA=__calldata_arg_tokenA, tokenB=__calldata_arg_tokenB, amountADesired=__calldata_arg_amountADesired, amountBDesired=__calldata_arg_amountBDesired, amountAMin=__calldata_arg_amountAMin, amountBMin=__calldata_arg_amountBMin, to=__calldata_arg_to, deadline=__calldata_arg_deadline,);\nlet (range_check_ptr, retdata_size, retdata) = add_liquidity_encode_return(ret_value, range_check_ptr);\n",
                "autogen/starknet/external/add_liquidity/b2c52ca2d2a8fc8791a983086d8716c5eacd0c3d62934914d2286f84b98ff4cb.cairo": "let syscall_ptr = [cast([cast(fp + (-5), felt**)] + 0, felt**)];\n",
                "autogen/starknet/external/add_liquidity/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo": "return (syscall_ptr,pedersen_ptr,range_check_ptr,retdata_size,retdata);\n",
                "autogen/starknet/external/factory/27c19248f9d5737b8d0b8eb8d777316b4ad5ccb8330cd5903141b353f0f45097.cairo": "let ret_value = __wrapped_func{syscall_ptr=syscall_ptr, pedersen_ptr=pedersen_ptr, range_check_ptr=range_check_ptr}();\nlet (range_check_ptr, retdata_size, retdata) = factory_encode_return(ret_value, range_check_ptr);\n",
                "autogen/starknet/external/factory/741ea357d6336b0bed7bf0472425acd0311d543883b803388880e60a232040c7.cairo": "let range_check_ptr = [cast([cast(fp + (-5), felt**)] + 2, felt*)];\n",
                "autogen/starknet/external/factory/9684a85e93c782014ca14293edea4eb2502039a5a7b6538ecd39c56faaf12529.cairo": "let pedersen_ptr = [cast([cast(fp + (-5), felt**)] + 1, starkware.cairo.common.cairo_builtins.HashBuiltin**)];\n",
                "autogen/starknet/external/factory/b2c52ca2d2a8fc8791a983086d8716c5eacd0c3d62934914d2286f84b98ff4cb.cairo": "let syscall_ptr = [cast([cast(fp + (-5), felt**)] + 0, felt**)];\n",
                "autogen/starknet/external/factory/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo": "return (syscall_ptr,pedersen_ptr,range_check_ptr,retdata_size,retdata);\n",
                "autogen/starknet/external/get_amount_in/741ea357d6336b0bed7bf0472425acd0311d543883b803388880e60a232040c7.cairo": "let range_check_ptr = [cast([cast(fp + (-5), felt**)] + 2, felt*)];\n",
                "autogen/starknet/external/get_amount_in/9684a85e93c782014ca14293edea4eb2502039a5a7b6538ecd39c56faaf12529.cairo": "let pedersen_ptr = [cast([cast(fp + (-5), felt**)] + 1, starkware.cairo.common.cairo_builtins.HashBuiltin**)];\n",
                "autogen/starknet/external/get_amount_in/a2e351ac07307c3ab316554972b5d5d88d31275fc63904e6f842f771d70231fa.cairo": "let ret_value = __wrapped_func{syscall_ptr=syscall_ptr, pedersen_ptr=pedersen_ptr, range_check_ptr=range_check_ptr}(amountOut=__calldata_arg_amountOut, reserveIn=__calldata_arg_reserveIn, reserveOut=__calldata_arg_reserveOut,);\nlet (range_check_ptr, retdata_size, retdata) = get_amount_in_encode_return(ret_value, range_check_ptr);\n",
                "autogen/starknet/external/get_amount_in/b2c52ca2d2a8fc8791a983086d8716c5eacd0c3d62934914d2286f84b98ff4cb.cairo": "let syscall_ptr = [cast([cast(fp + (-5), felt**)] + 0, felt**)];\n",
                "autogen/starknet/external/get_amount_in/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo": "return (syscall_ptr,pedersen_ptr,range_check_ptr,retdata_size,retdata);\n",
                "autogen/starknet/external/get_amount_out/024b910625ecf54bddf1bfcd87e3ebd46c9b462a71e54688ed6788cb1861c3c2.cairo": "let ret_value = __wrapped_func{syscall_ptr=syscall_ptr, pedersen_ptr=pedersen_ptr, range_check_ptr=range_check_ptr}(amountIn=__calldata_arg_amountIn, reserveIn=__calldata_arg_reserveIn, reserveOut=__calldata_arg_reserveOut,);\nlet (range_check_ptr, retdata_size, retdata) = get_amount_out_encode_return(ret_value, range_check_ptr);\n",
                "autogen/starknet/external/get_amount_out/741ea357d6336b0bed7bf0472425acd0311d543883b803388880e60a232040c7.cairo": "let range_check_ptr = [cast([cast(fp + (-5), felt**)] + 2, felt*)];\n",
                "autogen/starknet/external/get_amount_out/9684a85e93c782014ca14293edea4eb2502039a5a7b6538ecd39c56faaf12529.cairo": "let pedersen_ptr = [cast([cast(fp + (-5), felt**)] + 1, starkware.cairo.common.cairo_builtins.HashBuiltin**)];\n",
                "autogen/starknet/external/get_amount_out/b2c52ca2d2a8fc8791a983086d8716c5eacd0c3d62934914d2286f84b98ff4cb.cairo": "let syscall_ptr = [cast([cast(fp + (-5), felt**)] + 0, felt**)];\n",
                "autogen/starknet/external/get_amount_out/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo": "return (syscall_ptr,pedersen_ptr,range_check_ptr,retdata_size,retdata);\n",
                "autogen/starknet/external/get_amounts_in/741ea357d6336b0bed7bf0472425acd0311d543883b803388880e60a232040c7.cairo": "let range_check_ptr = [cast([cast(fp + (-5), felt**)] + 2, felt*)];\n",
                "autogen/starknet/external/get_amounts_in/9684a85e93c782014ca14293edea4eb2502039a5a7b6538ecd39c56faaf12529.cairo": "let pedersen_ptr = [cast([cast(fp + (-5), felt**)] + 1, starkware.cairo.common.cairo_builtins.HashBuiltin**)];\n",
                "autogen/starknet/external/get_amounts_in/b2c52ca2d2a8fc8791a983086d8716c5eacd0c3d62934914d2286f84b98ff4cb.cairo": "let syscall_ptr = [cast([cast(fp + (-5), felt**)] + 0, felt**)];\n",
                "autogen/starknet/external/get_amounts_in/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo": "return (syscall_ptr,pedersen_ptr,range_check_ptr,retdata_size,retdata);\n",
                "autogen/starknet/external/get_amounts_in/ed2d8695ac4216cad63e0ec5e45721feca952c35316762f3bebcc47d12b90c6a.cairo": "func get_amounts_in() -> (syscall_ptr: felt*, pedersen_ptr: starkware.cairo.common.cairo_builtins.HashBuiltin*, range_check_ptr: felt, size: felt, retdata: felt*) {\n    alloc_locals;\n}\n",
                "autogen/starknet/external/get_amounts_in/fa515db73ec45eee0abaaf34ced410aed1f5bceca24399565ecfe6499c8f15cd.cairo": "let ret_value = __wrapped_func{syscall_ptr=syscall_ptr, pedersen_ptr=pedersen_ptr, range_check_ptr=range_check_ptr}(amountOut=__calldata_arg_amountOut, path_len=__calldata_arg_path_len, path=__calldata_arg_path,);\nlet (range_check_ptr, retdata_size, retdata) = get_amounts_in_encode_return(ret_value, range_check_ptr);\n",
                "autogen/starknet/external/get_amounts_out/741ea357d6336b0bed7bf0472425acd0311d543883b803388880e60a232040c7.cairo": "let range_check_ptr = [cast([cast(fp + (-5), felt**)] + 2, felt*)];\n",
                "autogen/starknet/external/get_amounts_out/9684a85e93c782014ca14293edea4eb2502039a5a7b6538ecd39c56faaf12529.cairo": "let pedersen_ptr = [cast([cast(fp + (-5), felt**)] + 1, starkware.cairo.common.cairo_builtins.HashBuiltin**)];\n",
                "autogen/starknet/external/get_amounts_out/b2c52ca2d2a8fc8791a983086d8716c5eacd0c3d62934914d2286f84b98ff4cb.cairo": "let syscall_ptr = [cast([cast(fp + (-5), felt**)] + 0, felt**)];\n",
                "autogen/starknet/external/get_amounts_out/b2e508f9ab38fe9974d87cebfa23f4daa10b9dd03a6eae53c48515e87ee6e37d.cairo": "let ret_value = __wrapped_func{syscall_ptr=syscall_ptr, pedersen_ptr=pedersen_ptr, range_check_ptr=range_check_ptr}(amountIn=__calldata_arg_amountIn, path_len=__calldata_arg_path_len, path=__calldata_arg_path,);\nlet (range_check_ptr, retdata_size, retdata) = get_amounts_out_encode_return(ret_value, range_check_ptr);\n",
                "autogen/starknet/external/get_amounts_out/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo": "return (syscall_ptr,pedersen_ptr,range_check_ptr,retdata_size,retdata);\n",
                "autogen/starknet/external/get_amounts_out/efba66db60bb26bebf1da90b4428c053b024d9a41b0cf0b702abc67365dd8923.cairo": "func get_amounts_out() -> (syscall_ptr: felt*, pedersen_ptr: starkware.cairo.common.cairo_builtins.HashBuiltin*, range_check_ptr: felt, size: felt, retdata: felt*) {\n    alloc_locals;\n}\n",
                "autogen/starknet/external/initializer/741ea357d6336b0bed7bf0472425acd0311d543883b803388880e60a232040c7.cairo": "let range_check_ptr = [cast([cast(fp + (-5), felt**)] + 2, felt*)];\n",
                "autogen/starknet/external/initializer/846f3ef64610338b47cbfae6677962f7b1742601466d51d025b5a0e0c571f866.cairo": "let ret_value = __wrapped_func{syscall_ptr=syscall_ptr, pedersen_ptr=pedersen_ptr, range_check_ptr=range_check_ptr}(factory=__calldata_arg_factory, proxy_admin=__calldata_arg_proxy_admin,);\n%{ memory[ap] = segments.add() %}        // Allocate memory for return value.\ntempvar retdata: felt*;\nlet retdata_size = 0;\n",
                "autogen/starknet/external/initializer/9684a85e93c782014ca14293edea4eb2502039a5a7b6538ecd39c56faaf12529.cairo": "let pedersen_ptr = [cast([cast(fp + (-5), felt**)] + 1, starkware.cairo.common.cairo_builtins.HashBuiltin**)];\n",
                "autogen/starknet/external/initializer/b2c52ca2d2a8fc8791a983086d8716c5eacd0c3d62934914d2286f84b98ff4cb.cairo": "let syscall_ptr = [cast([cast(fp + (-5), felt**)] + 0, felt**)];\n",
                "autogen/starknet/external/initializer/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo": "return (syscall_ptr,pedersen_ptr,range_check_ptr,retdata_size,retdata);\n",
                "autogen/starknet/external/quote/41599575d8946c0eefdcac23f65fe31201840e403e8c6d50e378dd8d9d6a904b.cairo": "let ret_value = __wrapped_func{syscall_ptr=syscall_ptr, pedersen_ptr=pedersen_ptr, range_check_ptr=range_check_ptr}(amountA=__calldata_arg_amountA, reserveA=__calldata_arg_reserveA, reserveB=__calldata_arg_reserveB,);\nlet (range_check_ptr, retdata_size, retdata) = quote_encode_return(ret_value, range_check_ptr);\n",
                "autogen/starknet/external/quote/741ea357d6336b0bed7bf0472425acd0311d543883b803388880e60a232040c7.cairo": "let range_check_ptr = [cast([cast(fp + (-5), felt**)] + 2, felt*)];\n",
                "autogen/starknet/external/quote/9684a85e93c782014ca14293edea4eb2502039a5a7b6538ecd39c56faaf12529.cairo": "let pedersen_ptr = [cast([cast(fp + (-5), felt**)] + 1, starkware.cairo.common.cairo_builtins.HashBuiltin**)];\n",
                "autogen/starknet/external/quote/b2c52ca2d2a8fc8791a983086d8716c5eacd0c3d62934914d2286f84b98ff4cb.cairo": "let syscall_ptr = [cast([cast(fp + (-5), felt**)] + 0, felt**)];\n",
                "autogen/starknet/external/quote/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo": "return (syscall_ptr,pedersen_ptr,range_check_ptr,retdata_size,retdata);\n",
                "autogen/starknet/external/remove_liquidity/741ea357d6336b0bed7bf0472425acd0311d543883b803388880e60a232040c7.cairo": "let range_check_ptr = [cast([cast(fp + (-5), felt**)] + 2, felt*)];\n",
                "autogen/starknet/external/remove_liquidity/9684a85e93c782014ca14293edea4eb2502039a5a7b6538ecd39c56faaf12529.cairo": "let pedersen_ptr = [cast([cast(fp + (-5), felt**)] + 1, starkware.cairo.common.cairo_builtins.HashBuiltin**)];\n",
                "autogen/starknet/external/remove_liquidity/b2c52ca2d2a8fc8791a983086d8716c5eacd0c3d62934914d2286f84b98ff4cb.cairo": "let syscall_ptr = [cast([cast(fp + (-5), felt**)] + 0, felt**)];\n",
                "autogen/starknet/external/remove_liquidity/bb85aef889fad46697afdd63715fdb112517bb7675f11cd5f538690e2ee74e37.cairo": "let ret_value = __wrapped_func{syscall_ptr=syscall_ptr, pedersen_ptr=pedersen_ptr, range_check_ptr=range_check_ptr}(tokenA=__calldata_arg_tokenA, tokenB=__calldata_arg_tokenB, liquidity=__calldata_arg_liquidity, amountAMin=__calldata_arg_amountAMin, amountBMin=__calldata_arg_amountBMin, to=__calldata_arg_to, deadline=__calldata_arg_deadline,);\nlet (range_check_ptr, retdata_size, retdata) = remove_liquidity_encode_return(ret_value, range_check_ptr);\n",
                "autogen/starknet/external/remove_liquidity/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo": "return (syscall_ptr,pedersen_ptr,range_check_ptr,retdata_size,retdata);\n",
                "autogen/starknet/external/return/add_liquidity/29fd096df52ff90a7412801ec1cc013fc12f5cad93beabab671ad152ae5c25ee.cairo": "func add_liquidity_encode_return(ret_value: (amountA: starkware.cairo.common.uint256.Uint256, amountB: starkware.cairo.common.uint256.Uint256, liquidity: starkware.cairo.common.uint256.Uint256), range_check_ptr) -> (\n        range_check_ptr: felt, data_len: felt, data: felt*) {\n    %{ memory[ap] = segments.add() %}\n    alloc_locals;\n    local __return_value_ptr_start: felt*;\n    let __return_value_ptr = __return_value_ptr_start;\n    with range_check_ptr {\n    }\n    return (\n        range_check_ptr=range_check_ptr,\n        data_len=__return_value_ptr - __return_value_ptr_start,\n        data=__return_value_ptr_start);\n}\n",
                "autogen/starknet/external/return/factory/4c6a74b9ab2b31333825257075a9c8364744c6f9f62af0f6b74f82630db79ea9.cairo": "func factory_encode_return(ret_value: (address: felt), range_check_ptr) -> (\n        range_check_ptr: felt, data_len: felt, data: felt*) {\n    %{ memory[ap] = segments.add() %}\n    alloc_locals;\n    local __return_value_ptr_start: felt*;\n    let __return_value_ptr = __return_value_ptr_start;\n    with range_check_ptr {\n    }\n    return (\n        range_check_ptr=range_check_ptr,\n        data_len=__return_value_ptr - __return_value_ptr_start,\n        data=__return_value_ptr_start);\n}\n",
                "autogen/starknet/external/return/get_amount_in/33c8a165a194e19ad4f8cdd00b54f617a048d42f3e20a4932df6da37980f9340.cairo": "func get_amount_in_encode_return(ret_value: (amountIn: starkware.cairo.common.uint256.Uint256), range_check_ptr) -> (\n        range_check_ptr: felt, data_len: felt, data: felt*) {\n    %{ memory[ap] = segments.add() %}\n    alloc_locals;\n    local __return_value_ptr_start: felt*;\n    let __return_value_ptr = __return_value_ptr_start;\n    with range_check_ptr {\n    }\n    return (\n        range_check_ptr=range_check_ptr,\n        data_len=__return_value_ptr - __return_value_ptr_start,\n        data=__return_value_ptr_start);\n}\n",
                "autogen/starknet/external/return/get_amount_out/1feb6aa8f4a51d25b9657ae25a0f05521dc942d6e349f2a67e53697c6535d1b5.cairo": "func get_amount_out_encode_return(ret_value: (amountOut: starkware.cairo.common.uint256.Uint256), range_check_ptr) -> (\n        range_check_ptr: felt, data_len: felt, data: felt*) {\n    %{ memory[ap] = segments.add() %}\n    alloc_locals;\n    local __return_value_ptr_start: felt*;\n    let __return_value_ptr = __return_value_ptr_start;\n    with range_check_ptr {\n    }\n    return (\n        range_check_ptr=range_check_ptr,\n        data_len=__return_value_ptr - __return_value_ptr_start,\n        data=__return_value_ptr_start);\n}\n",
                "autogen/starknet/external/return/get_amounts_in/9f64b8a682241557028dea181432b931ff4d42c2c30c42ee41744acc9448ecd9.cairo": "func get_amounts_in_encode_return(ret_value: (amounts_len: felt, amounts: starkware.cairo.common.uint256.Uint256*), range_check_ptr) -> (\n        range_check_ptr: felt, data_len: felt, data: felt*) {\n    %{ memory[ap] = segments.add() %}\n    alloc_locals;\n    local __return_value_ptr_start: felt*;\n    let __return_value_ptr = __return_value_ptr_start;\n    with range_check_ptr {\n    }\n    return (\n        range_check_ptr=range_check_ptr,\n        data_len=__return_value_ptr - __return_value_ptr_start,\n        data=__return_value_ptr_start);\n}\n",
                "autogen/starknet/external/return/get_amounts_out/b6666a504ab8a9b41998f4aab125d89d2f1350d9f95ea85684ab5e4468af4b39.cairo": "func get_amounts_out_encode_return(ret_value: (amounts_len: felt, amounts: starkware.cairo.common.uint256.Uint256*), range_check_ptr) -> (\n        range_check_ptr: felt, data_len: felt, data: felt*) {\n    %{ memory[ap] = segments.add() %}\n    alloc_locals;\n    local __return_value_ptr_start: felt*;\n    let __return_value_ptr = __return_value_ptr_start;\n    with range_check_ptr {\n    }\n    return (\n        range_check_ptr=range_check_ptr,\n        data_len=__return_value_ptr - __return_value_ptr_start,\n        data=__return_value_ptr_start);\n}\n",
                "autogen/starknet/external/return/quote/1c73b71691461623d1362f35ec8adab41dfa1b44cf13997036052a8e40a88507.cairo": "func quote_encode_return(ret_value: (amountB: starkware.cairo.common.uint256.Uint256), range_check_ptr) -> (\n        range_check_ptr: felt, data_len: felt, data: felt*) {\n    %{ memory[ap] = segments.add() %}\n    alloc_locals;\n    local __return_value_ptr_start: felt*;\n    let __return_value_ptr = __return_value_ptr_start;\n    with range_check_ptr {\n    }\n    return (\n        range_check_ptr=range_check_ptr,\n        data_len=__return_value_ptr - __return_value_ptr_start,\n        data=__return_value_ptr_start);\n}\n",
                "autogen/starknet/external/return/remove_liquidity/b66a28f571d574f2b8dcb5f4c22b39f9f529587f595e464b1814a852d2cdbc48.cairo": "func remove_liquidity_encode_return(ret_value: (amountA: starkware.cairo.common.uint256.Uint256, amountB: starkware.cairo.common.uint256.Uint256), range_check_ptr) -> (\n        range_check_ptr: felt, data_len: felt, data: felt*) {\n    %{ memory[ap] = segments.add() %}\n    alloc_locals;\n    local __return_value_ptr_start: felt*;\n    let __return_value_ptr = __return_value_ptr_start;\n    with range_check_ptr {\n    }\n    return (\n        range_check_ptr=range_check_ptr,\n        data_len=__return_value_ptr - __return_value_ptr_start,\n        data=__return_value_ptr_start);\n}\n",
                "autogen/starknet/external/return/sort_tokens/8f4b343957d504181762c667afb98181e707e9594c8819a901ce9897c26d6807.cairo": "func sort_tokens_encode_return(ret_value: (token0: felt, token1: felt), range_check_ptr) -> (\n        range_check_ptr: felt, data_len: felt, data: felt*) {\n    %{ memory[ap] = segments.add() %}\n    alloc_locals;\n    local __return_value_ptr_start: felt*;\n    let __return_value_ptr = __return_value_ptr_start;\n    with range_check_ptr {\n    }\n    return (\n        range_check_ptr=range_check_ptr,\n        data_len=__return_value_ptr - __return_value_ptr_start,\n        data=__return_value_ptr_start);\n}\n",
                "autogen/starknet/external/return/swap_exact_tokens_for_tokens/dc32f5d0914f96c001a00c9efce25a55e68a95d6f6a16c088a5a03c0190608fb.cairo": "func swap_exact_tokens_for_tokens_encode_return(ret_value: (amounts_len: felt, amounts: starkware.cairo.common.uint256.Uint256*), range_check_ptr) -> (\n        range_check_ptr: felt, data_len: felt, data: felt*) {\n    %{ memory[ap] = segments.add() %}\n    alloc_locals;\n    local __return_value_ptr_start: felt*;\n    let __return_value_ptr = __return_value_ptr_start;\n    with range_check_ptr {\n    }\n    return (\n        range_check_ptr=range_check_ptr,\n        data_len=__return_value_ptr - __return_value_ptr_start,\n        data=__return_value_ptr_start);\n}\n",
                "autogen/starknet/external/return/swap_tokens_for_exact_tokens/e6bc02655094226ec58b26f741d012521ee9eadc5e9077818a38e8fb63533cd9.cairo": "func swap_tokens_for_exact_tokens_encode_return(ret_value: (amounts_len: felt, amounts: starkware.cairo.common.uint256.Uint256*), range_check_ptr) -> (\n        range_check_ptr: felt, data_len: felt, data: felt*) {\n    %{ memory[ap] = segments.add() %}\n    alloc_locals;\n    local __return_value_ptr_start: felt*;\n    let __return_value_ptr = __return_value_ptr_start;\n    with range_check_ptr {\n    }\n    return (\n        range_check_ptr=range_check_ptr,\n        data_len=__return_value_ptr - __return_value_ptr_start,\n        data=__return_value_ptr_start);\n}\n",
                "autogen/starknet/external/sort_tokens/741ea357d6336b0bed7bf0472425acd0311d543883b803388880e60a232040c7.cairo": "let range_check_ptr = [cast([cast(fp + (-5), felt**)] + 2, felt*)];\n",
                "autogen/starknet/external/sort_tokens/9684a85e93c782014ca14293edea4eb2502039a5a7b6538ecd39c56faaf12529.cairo": "let pedersen_ptr = [cast([cast(fp + (-5), felt**)] + 1, starkware.cairo.common.cairo_builtins.HashBuiltin**)];\n",
                "autogen/starknet/external/sort_tokens/b2c52ca2d2a8fc8791a983086d8716c5eacd0c3d62934914d2286f84b98ff4cb.cairo": "let syscall_ptr = [cast([cast(fp + (-5), felt**)] + 0, felt**)];\n",
                "autogen/starknet/external/sort_tokens/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo": "return (syscall_ptr,pedersen_ptr,range_check_ptr,retdata_size,retdata);\n",
                "autogen/starknet/external/sort_tokens/ee47c9ba2c0dfcdd8291f626717e8662c253850c770a2709f1cc0721c88ecac4.cairo": "let ret_value = __wrapped_func{syscall_ptr=syscall_ptr, pedersen_ptr=pedersen_ptr, range_check_ptr=range_check_ptr}(tokenA=__calldata_arg_tokenA, tokenB=__calldata_arg_tokenB,);\nlet (range_check_ptr, retdata_size, retdata) = sort_tokens_encode_return(ret_value, range_check_ptr);\n",
                "autogen/starknet/external/swap_exact_tokens_for_tokens/10dbcdb9e76b3e9224c0632ffd81e9aac01948f3820e904391f49984014ee2f5.cairo": "let ret_value = __wrapped_func{syscall_ptr=syscall_ptr, pedersen_ptr=pedersen_ptr, range_check_ptr=range_check_ptr}(amountIn=__calldata_arg_amountIn, amountOutMin=__calldata_arg_amountOutMin, path_len=__calldata_arg_path_len, path=__calldata_arg_path, to=__calldata_arg_to, deadline=__calldata_arg_deadline,);\nlet (range_check_ptr, retdata_size, retdata) = swap_exact_tokens_for_tokens_encode_return(ret_value, range_check_ptr);\n",
                "autogen/starknet/external/swap_exact_tokens_for_tokens/17bd0551df5a8df79c6b70ac6dc0924fa6d2055a93458140faa9898c09939ea5.cairo": "func swap_exact_tokens_for_tokens() -> (syscall_ptr: felt*, pedersen_ptr: starkware.cairo.common.cairo_builtins.HashBuiltin*, range_check_ptr: felt, size: felt, retdata: felt*) {\n    alloc_locals;\n}\n",
                "autogen/starknet/external/swap_exact_tokens_for_tokens/741ea357d6336b0bed7bf0472425acd0311d543883b803388880e60a232040c7.cairo": "let range_check_ptr = [cast([cast(fp + (-5), felt**)] + 2, felt*)];\n",
                "autogen/starknet/external/swap_exact_tokens_for_tokens/9684a85e93c782014ca14293edea4eb2502039a5a7b6538ecd39c56faaf12529.cairo": "let pedersen_ptr = [cast([cast(fp + (-5), felt**)] + 1, starkware.cairo.common.cairo_builtins.HashBuiltin**)];\n",
                "autogen/starknet/external/swap_exact_tokens_for_tokens/b2c52ca2d2a8fc8791a983086d8716c5eacd0c3d62934914d2286f84b98ff4cb.cairo": "let syscall_ptr = [cast([cast(fp + (-5), felt**)] + 0, felt**)];\n",
                "autogen/starknet/external/swap_exact_tokens_for_tokens/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo": "return (syscall_ptr,pedersen_ptr,range_check_ptr,retdata_size,retdata);\n",
                "autogen/starknet/external/swap_tokens_for_exact_tokens/1de8d3225583e0fa7eb46d75fb1a7864c38dfc9fd281e2f1ece4335f1b3783d8.cairo": "func swap_tokens_for_exact_tokens() -> (syscall_ptr: felt*, pedersen_ptr: starkware.cairo.common.cairo_builtins.HashBuiltin*, range_check_ptr: felt, size: felt, retdata: felt*) {\n    alloc_locals;\n}\n",
                "autogen/starknet/external/swap_tokens_for_exact_tokens/741ea357d6336b0bed7bf0472425acd0311d543883b803388880e60a232040c7.cairo": "let range_check_ptr = [cast([cast(fp + (-5), felt**)] + 2, felt*)];\n",
                "autogen/starknet/external/swap_tokens_for_exact_tokens/9684a85e93c782014ca14293edea4eb2502039a5a7b6538ecd39c56faaf12529.cairo": "let pedersen_ptr = [cast([cast(fp + (-5), felt**)] + 1, starkware.cairo.common.cairo_builtins.HashBuiltin**)];\n",
                "autogen/starknet/external/swap_tokens_for_exact_tokens/b2c52ca2d2a8fc8791a983086d8716c5eacd0c3d62934914d2286f84b98ff4cb.cairo": "let syscall_ptr = [cast([cast(fp + (-5), felt**)] + 0, felt**)];\n",
                "autogen/starknet/external/swap_tokens_for_exact_tokens/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo": "return (syscall_ptr,pedersen_ptr,range_check_ptr,retdata_size,retdata);\n",
                "autogen/starknet/external/swap_tokens_for_exact_tokens/ec76a2ec5a5a0f40064f3d4c7f96755d0d1e6a807ed42b754ccb9b7bc0757ba0.cairo": "let ret_value = __wrapped_func{syscall_ptr=syscall_ptr, pedersen_ptr=pedersen_ptr, range_check_ptr=range_check_ptr}(amountOut=__calldata_arg_amountOut, amountInMax=__calldata_arg_amountInMax, path_len=__calldata_arg_path_len, path=__calldata_arg_path, to=__calldata_arg_to, deadline=__calldata_arg_deadline,);\nlet (range_check_ptr, retdata_size, retdata) = swap_tokens_for_exact_tokens_encode_return(ret_value, range_check_ptr);\n",
                "autogen/starknet/storage_var/Proxy_admin/decl.cairo": "namespace Proxy_admin {\n    from starkware.starknet.common.storage import normalize_address\n    from starkware.starknet.common.syscalls import storage_read, storage_write\n    from starkware.cairo.common.cairo_builtins import HashBuiltin\n    from starkware.cairo.common.hash import hash2\n\n    func addr{pedersen_ptr: HashBuiltin*, range_check_ptr}() -> (res: felt) {\n        let res = 0;\n        call hash2;\n        call normalize_address;\n    }\n\n    func read{syscall_ptr: felt*, pedersen_ptr: HashBuiltin*, range_check_ptr}() -> (admin: felt) {\n        let storage_addr = 0;\n        call addr;\n        call storage_read;\n    }\n\n    func write{syscall_ptr: felt*, pedersen_ptr: HashBuiltin*, range_check_ptr}(value: felt) {\n        let storage_addr = 0;\n        call addr;\n        call storage_write;\n    }\n}",
                "autogen/starknet/storage_var/Proxy_admin/impl.cairo": "namespace Proxy_admin {\n    from starkware.starknet.common.storage import normalize_address\n    from starkware.starknet.common.syscalls import storage_read, storage_write\n    from starkware.cairo.common.cairo_builtins import HashBuiltin\n    from starkware.cairo.common.hash import hash2\n\n    func addr{pedersen_ptr: HashBuiltin*, range_check_ptr}() -> (res: felt) {\n        let res = 420809302088575566661732159258626015934899866033455122060647015585080981641;\n        return (res=res);\n    }\n\n    func read{syscall_ptr: felt*, pedersen_ptr: HashBuiltin*, range_check_ptr}() -> (admin: felt) {\n        let (storage_addr) = addr();\n        let (__storage_var_temp0) = storage_read(address=storage_addr + 0);\n\n        tempvar syscall_ptr = syscall_ptr;\n        tempvar pedersen_ptr = pedersen_ptr;\n        tempvar range_check_ptr = range_check_ptr;\n        tempvar __storage_var_temp0: felt = __storage_var_temp0;\n        return ([cast(&__storage_var_temp0, felt*)],);\n    }\n\n    func write{syscall_ptr: felt*, pedersen_ptr: HashBuiltin*, range_check_ptr}(value: felt) {\n        let (storage_addr) = addr();\n        storage_write(address=storage_addr + 0, value=[cast(&value, felt) + 0]);\n        return ();\n    }\n}",
                "autogen/starknet/storage_var/Proxy_initialized/decl.cairo": "namespace Proxy_initialized {\n    from starkware.starknet.common.storage import normalize_address\n    from starkware.starknet.common.syscalls import storage_read, storage_write\n    from starkware.cairo.common.cairo_builtins import HashBuiltin\n    from starkware.cairo.common.hash import hash2\n\n    func addr{pedersen_ptr: HashBuiltin*, range_check_ptr}() -> (res: felt) {\n        let res = 0;\n        call hash2;\n        call normalize_address;\n    }\n\n    func read{syscall_ptr: felt*, pedersen_ptr: HashBuiltin*, range_check_ptr}() -> (\n        initialized: felt\n    ) {\n        let storage_addr = 0;\n        call addr;\n        call storage_read;\n    }\n\n    func write{syscall_ptr: felt*, pedersen_ptr: HashBuiltin*, range_check_ptr}(value: felt) {\n        let storage_addr = 0;\n        call addr;\n        call storage_write;\n    }\n}",
                "autogen/starknet/storage_var/Proxy_initialized/impl.cairo": "namespace Proxy_initialized {\n    from starkware.starknet.common.storage import normalize_address\n    from starkware.starknet.common.syscalls import storage_read, storage_write\n    from starkware.cairo.common.cairo_builtins import HashBuiltin\n    from starkware.cairo.common.hash import hash2\n\n    func addr{pedersen_ptr: HashBuiltin*, range_check_ptr}() -> (res: felt) {\n        let res = 1596797181605835465375385856756254615380245139672087060603160900530960374661;\n        return (res=res);\n    }\n\n    func read{syscall_ptr: felt*, pedersen_ptr: HashBuiltin*, range_check_ptr}() -> (\n        initialized: felt\n    ) {\n        let (storage_addr) = addr();\n        let (__storage_var_temp0) = storage_read(address=storage_addr + 0);\n\n        tempvar syscall_ptr = syscall_ptr;\n        tempvar pedersen_ptr = pedersen_ptr;\n        tempvar range_check_ptr = range_check_ptr;\n        tempvar __storage_var_temp0: felt = __storage_var_temp0;\n        return ([cast(&__storage_var_temp0, felt*)],);\n    }\n\n    func write{syscall_ptr: felt*, pedersen_ptr: HashBuiltin*, range_check_ptr}(value: felt) {\n        let (storage_addr) = addr();\n        storage_write(address=storage_addr + 0, value=[cast(&value, felt) + 0]);\n        return ();\n    }\n}",
                "autogen/starknet/storage_var/_factory/decl.cairo": "namespace _factory {\n    from starkware.starknet.common.storage import normalize_address\n    from starkware.starknet.common.syscalls import storage_read, storage_write\n    from starkware.cairo.common.cairo_builtins import HashBuiltin\n    from starkware.cairo.common.hash import hash2\n\n    func addr{pedersen_ptr: HashBuiltin*, range_check_ptr}() -> (res: felt) {\n        let res = 0;\n        call hash2;\n        call normalize_address;\n    }\n\n    func read{syscall_ptr: felt*, pedersen_ptr: HashBuiltin*, range_check_ptr}() -> (\n        address: felt\n    ) {\n        let storage_addr = 0;\n        call addr;\n        call storage_read;\n    }\n\n    func write{syscall_ptr: felt*, pedersen_ptr: HashBuiltin*, range_check_ptr}(value: felt) {\n        let storage_addr = 0;\n        call addr;\n        call storage_write;\n    }\n}",
                "autogen/starknet/storage_var/_factory/impl.cairo": "namespace _factory {\n    from starkware.starknet.common.storage import normalize_address\n    from starkware.starknet.common.syscalls import storage_read, storage_write\n    from starkware.cairo.common.cairo_builtins import HashBuiltin\n    from starkware.cairo.common.hash import hash2\n\n    func addr{pedersen_ptr: HashBuiltin*, range_check_ptr}() -> (res: felt) {\n        let res = 553922663983027901352947268674529631585049616106261936186081990544946436869;\n        return (res=res);\n    }\n\n    func read{syscall_ptr: felt*, pedersen_ptr: HashBuiltin*, range_check_ptr}() -> (\n        address: felt\n    ) {\n        let (storage_addr) = addr();\n        let (__storage_var_temp0) = storage_read(address=storage_addr + 0);\n\n        tempvar syscall_ptr = syscall_ptr;\n        tempvar pedersen_ptr = pedersen_ptr;\n        tempvar range_check_ptr = range_check_ptr;\n        tempvar __storage_var_temp0: felt = __storage_var_temp0;\n        return ([cast(&__storage_var_temp0, felt*)],);\n    }\n\n    func write{syscall_ptr: felt*, pedersen_ptr: HashBuiltin*, range_check_ptr}(value: felt) {\n        let (storage_addr) = addr();\n        storage_write(address=storage_addr + 0, value=[cast(&value, felt) + 0]);\n        return ();\n    }\n}"
            },
            "instruction_locations": {
                "0": {
                    "accessible_scopes": [
                        "starkware.cairo.common.alloc",
                        "starkware.cairo.common.alloc.alloc"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 38,
                                "end_line": 3,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/alloc.cairo"
                                },
                                "start_col": 5,
                                "start_line": 3
                            },
                            "n_prefix_newlines": 0
                        }
                    ],
                    "inst": {
                        "end_col": 12,
                        "end_line": 4,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/alloc.cairo"
                        },
                        "start_col": 5,
                        "start_line": 4
                    }
                },
                "2": {
                    "accessible_scopes": [
                        "starkware.cairo.common.alloc",
                        "starkware.cairo.common.alloc.alloc"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 5,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/alloc.cairo"
                        },
                        "start_col": 5,
                        "start_line": 5
                    }
                },
                "3": {
                    "accessible_scopes": [
                        "starkware.cairo.common.memcpy",
                        "starkware.cairo.common.memcpy.memcpy"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 8,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/memcpy.cairo"
                        },
                        "start_col": 5,
                        "start_line": 8
                    }
                },
                "5": {
                    "accessible_scopes": [
                        "starkware.cairo.common.memcpy",
                        "starkware.cairo.common.memcpy.memcpy"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 19,
                        "end_line": 9,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/memcpy.cairo"
                        },
                        "start_col": 9,
                        "start_line": 9
                    }
                },
                "6": {
                    "accessible_scopes": [
                        "starkware.cairo.common.memcpy",
                        "starkware.cairo.common.memcpy.memcpy"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 41,
                                "end_line": 12,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/memcpy.cairo"
                                },
                                "start_col": 5,
                                "start_line": 12
                            },
                            "n_prefix_newlines": 0
                        }
                    ],
                    "inst": {
                        "end_col": 23,
                        "end_line": 2,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/memcpy.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 38,
                                "end_line": 13,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/memcpy.cairo"
                                },
                                "start_col": 35,
                                "start_line": 13
                            },
                            "While expanding the reference 'dst' in:"
                        ],
                        "start_col": 13,
                        "start_line": 2
                    }
                },
                "7": {
                    "accessible_scopes": [
                        "starkware.cairo.common.memcpy",
                        "starkware.cairo.common.memcpy.memcpy"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 2,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/memcpy.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 47,
                                "end_line": 13,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/memcpy.cairo"
                                },
                                "start_col": 44,
                                "start_line": 13
                            },
                            "While expanding the reference 'src' in:"
                        ],
                        "start_col": 25,
                        "start_line": 2
                    }
                },
                "8": {
                    "accessible_scopes": [
                        "starkware.cairo.common.memcpy",
                        "starkware.cairo.common.memcpy.memcpy"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 17,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/memcpy.cairo"
                        },
                        "start_col": 26,
                        "start_line": 17
                    }
                },
                "9": {
                    "accessible_scopes": [
                        "starkware.cairo.common.memcpy",
                        "starkware.cairo.common.memcpy.memcpy"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 17,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/memcpy.cairo"
                        },
                        "start_col": 5,
                        "start_line": 17
                    }
                },
                "10": {
                    "accessible_scopes": [
                        "starkware.cairo.common.memcpy",
                        "starkware.cairo.common.memcpy.memcpy"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 22,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/memcpy.cairo"
                        },
                        "start_col": 5,
                        "start_line": 22
                    }
                },
                "12": {
                    "accessible_scopes": [
                        "starkware.cairo.common.memcpy",
                        "starkware.cairo.common.memcpy.memcpy"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 23,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/memcpy.cairo"
                        },
                        "start_col": 5,
                        "start_line": 23
                    }
                },
                "14": {
                    "accessible_scopes": [
                        "starkware.cairo.common.memcpy",
                        "starkware.cairo.common.memcpy.memcpy"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 7,
                                "end_line": 27,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/memcpy.cairo"
                                },
                                "start_col": 5,
                                "start_line": 24
                            },
                            "n_prefix_newlines": 1
                        }
                    ],
                    "inst": {
                        "end_col": 44,
                        "end_line": 29,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/memcpy.cairo"
                        },
                        "start_col": 5,
                        "start_line": 29
                    }
                },
                "16": {
                    "accessible_scopes": [
                        "starkware.cairo.common.memcpy",
                        "starkware.cairo.common.memcpy.memcpy"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 55,
                        "end_line": 31,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/memcpy.cairo"
                        },
                        "start_col": 5,
                        "start_line": 31
                    }
                },
                "17": {
                    "accessible_scopes": [
                        "starkware.cairo.common.memcpy",
                        "starkware.cairo.common.memcpy.memcpy"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 26,
                                "end_line": 33,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/memcpy.cairo"
                                },
                                "start_col": 5,
                                "start_line": 33
                            },
                            "n_prefix_newlines": 0
                        }
                    ],
                    "inst": {
                        "end_col": 15,
                        "end_line": 34,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/memcpy.cairo"
                        },
                        "start_col": 5,
                        "start_line": 34
                    }
                },
                "18": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_not_zero"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 7,
                                "end_line": 11,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                                },
                                "start_col": 5,
                                "start_line": 7
                            },
                            "n_prefix_newlines": 1
                        }
                    ],
                    "inst": {
                        "end_col": 7,
                        "end_line": 12,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 12
                    }
                },
                "20": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_not_zero"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 14,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 9,
                        "start_line": 14
                    }
                },
                "22": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_not_zero"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 17,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 17
                    }
                },
                "23": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_not_equal"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 7,
                                "end_line": 31,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                                },
                                "start_col": 5,
                                "start_line": 22
                            },
                            "n_prefix_newlines": 1
                        }
                    ],
                    "inst": {
                        "end_col": 15,
                        "end_line": 32,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 9,
                        "start_line": 32
                    }
                },
                "24": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_not_equal"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 32,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 32
                    }
                },
                "26": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_not_equal"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 34,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 9,
                        "start_line": 34
                    }
                },
                "28": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_not_equal"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 37,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 37
                    }
                },
                "29": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_nn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 7,
                                "end_line": 46,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                                },
                                "start_col": 5,
                                "start_line": 42
                            },
                            "n_prefix_newlines": 1
                        }
                    ],
                    "inst": {
                        "end_col": 26,
                        "end_line": 47,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 47
                    }
                },
                "30": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_nn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 46,
                        "end_line": 48,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 31,
                                "end_line": 41,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 15,
                                        "end_line": 49,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 49
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 16,
                                "start_line": 41
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 27,
                        "start_line": 48
                    }
                },
                "32": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_nn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 49,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 49
                    }
                },
                "33": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 31,
                        "end_line": 53,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 31,
                                "end_line": 41,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 21,
                                        "end_line": 54,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 54
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 16,
                                "start_line": 41
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 16,
                        "start_line": 53
                    }
                },
                "34": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 54,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 15,
                        "start_line": 54
                    }
                },
                "35": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 54,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 54
                    }
                },
                "37": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 55,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 55
                    }
                },
                "38": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 7,
                                "end_line": 184,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                                },
                                "start_col": 5,
                                "start_line": 164
                            },
                            "n_prefix_newlines": 1
                        }
                    ],
                    "inst": {
                        "end_col": 42,
                        "end_line": 186,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 25,
                        "start_line": 186
                    }
                },
                "39": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 66,
                        "end_line": 186,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 45,
                        "start_line": 186
                    }
                },
                "40": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 86,
                        "end_line": 186,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 45,
                        "start_line": 186
                    }
                },
                "42": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 86,
                        "end_line": 186,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 25,
                        "start_line": 186
                    }
                },
                "43": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 45,
                        "end_line": 187,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 24,
                        "start_line": 187
                    }
                },
                "44": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 69,
                        "end_line": 187,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 48,
                        "start_line": 187
                    }
                },
                "45": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 89,
                        "end_line": 187,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 48,
                        "start_line": 187
                    }
                },
                "47": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 89,
                        "end_line": 187,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 24,
                        "start_line": 187
                    }
                },
                "48": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 49,
                                "end_line": 196,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                                },
                                "start_col": 5,
                                "start_line": 196
                            },
                            "n_prefix_newlines": 0
                        }
                    ],
                    "inst": {
                        "end_col": 42,
                        "end_line": 197,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 197
                    }
                },
                "50": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 25,
                        "end_line": 198,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 23,
                        "start_line": 198
                    }
                },
                "52": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 198,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 22,
                        "start_line": 198
                    }
                },
                "53": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 31,
                        "end_line": 198,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 198
                    }
                },
                "54": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 29,
                        "end_line": 199,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 24,
                        "start_line": 199
                    }
                },
                "55": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 39,
                        "end_line": 199,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 34,
                        "start_line": 199
                    }
                },
                "57": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 199,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 23,
                        "start_line": 199
                    }
                },
                "58": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 199,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 199
                    }
                },
                "59": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 46,
                        "end_line": 188,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 154,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 15,
                                        "end_line": 200,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 200
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 21,
                                "start_line": 154
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 27,
                        "start_line": 188
                    }
                },
                "61": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 200,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 200
                    }
                },
                "62": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 49,
                                "end_line": 204,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                                },
                                "start_col": 5,
                                "start_line": 204
                            },
                            "n_prefix_newlines": 0
                        }
                    ],
                    "inst": {
                        "end_col": 50,
                        "end_line": 205,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 205
                    }
                },
                "64": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 206,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 21,
                        "start_line": 206
                    }
                },
                "66": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 206,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 20,
                        "start_line": 206
                    }
                },
                "67": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 207,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 22,
                        "start_line": 207
                    }
                },
                "68": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 31,
                        "end_line": 207,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 207
                    }
                },
                "69": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 31,
                        "end_line": 208,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 23,
                        "start_line": 208
                    }
                },
                "70": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 208,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 208
                    }
                },
                "71": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 46,
                        "end_line": 188,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 154,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 15,
                                        "end_line": 209,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 209
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 21,
                                "start_line": 154
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 27,
                        "start_line": 188
                    }
                },
                "73": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 209,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 209
                    }
                },
                "74": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 31,
                                "end_line": 213,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                                },
                                "start_col": 5,
                                "start_line": 213
                            },
                            "n_prefix_newlines": 0
                        }
                    ],
                    "inst": {
                        "end_col": 24,
                        "end_line": 214,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 214
                    }
                },
                "75": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 215,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 28,
                        "start_line": 215
                    }
                },
                "76": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 215,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 23,
                        "start_line": 215
                    }
                },
                "77": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 215,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 215
                    }
                },
                "78": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 12,
                        "end_line": 216,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 216
                    }
                },
                "80": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 46,
                        "end_line": 188,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 154,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 15,
                                        "end_line": 217,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 217
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 21,
                                "start_line": 154
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 27,
                        "start_line": 188
                    }
                },
                "82": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 217,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 217
                    }
                },
                "83": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_lt_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 7,
                                "end_line": 230,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                                },
                                "start_col": 5,
                                "start_line": 224
                            },
                            "n_prefix_newlines": 1
                        }
                    ],
                    "inst": {
                        "end_col": 15,
                        "end_line": 231,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 9,
                        "start_line": 231
                    }
                },
                "84": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_lt_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 231,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 231
                    }
                },
                "86": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_lt_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 233,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 9,
                        "start_line": 233
                    }
                },
                "88": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_lt_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 36,
                        "end_line": 223,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 154,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 25,
                                        "end_line": 235,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 235
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 21,
                                "start_line": 154
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 21,
                        "start_line": 223
                    }
                },
                "89": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_lt_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 39,
                        "end_line": 223,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 235,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                                },
                                "start_col": 20,
                                "start_line": 235
                            },
                            "While expanding the reference 'a' in:"
                        ],
                        "start_col": 38,
                        "start_line": 223
                    }
                },
                "90": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_lt_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 42,
                        "end_line": 223,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 235,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                                },
                                "start_col": 23,
                                "start_line": 235
                            },
                            "While expanding the reference 'b' in:"
                        ],
                        "start_col": 41,
                        "start_line": 223
                    }
                },
                "91": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_lt_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 25,
                        "end_line": 235,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 235
                    }
                },
                "93": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_lt_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 236,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 236
                    }
                },
                "94": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.call_contract"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 47,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 18,
                        "start_line": 47
                    }
                },
                "96": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.call_contract"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 52,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 5,
                        "start_line": 46
                    }
                },
                "97": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.call_contract"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 52,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 5,
                        "start_line": 46
                    }
                },
                "98": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.call_contract"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 52,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 5,
                        "start_line": 46
                    }
                },
                "99": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.call_contract"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 52,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 5,
                        "start_line": 46
                    }
                },
                "100": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.call_contract"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 52,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 5,
                        "start_line": 46
                    }
                },
                "101": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.call_contract"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 88,
                                "end_line": 53,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                },
                                "start_col": 5,
                                "start_line": 53
                            },
                            "n_prefix_newlines": 0
                        }
                    ],
                    "inst": {
                        "end_col": 54,
                        "end_line": 56,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 38,
                                "end_line": 42,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 75,
                                        "end_line": 57,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 57
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 20,
                                "start_line": 42
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 23,
                        "start_line": 56
                    }
                },
                "103": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.call_contract"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 47,
                        "end_line": 57,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 26,
                        "start_line": 57
                    }
                },
                "104": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.call_contract"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 73,
                        "end_line": 57,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 57,
                        "start_line": 57
                    }
                },
                "105": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.call_contract"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 75,
                        "end_line": 57,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 5,
                        "start_line": 57
                    }
                },
                "106": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.get_caller_address"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 90,
                        "end_line": 202,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 63,
                        "start_line": 202
                    }
                },
                "108": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.get_caller_address"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 92,
                        "end_line": 202,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 5,
                        "start_line": 202
                    }
                },
                "109": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.get_caller_address"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 93,
                                "end_line": 203,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                },
                                "start_col": 5,
                                "start_line": 203
                            },
                            "n_prefix_newlines": 0
                        }
                    ],
                    "inst": {
                        "end_col": 58,
                        "end_line": 204,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 200,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 61,
                                        "end_line": 205,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 205
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 25,
                                "start_line": 200
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 23,
                        "start_line": 204
                    }
                },
                "111": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.get_caller_address"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 59,
                        "end_line": 205,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 28,
                        "start_line": 205
                    }
                },
                "112": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.get_caller_address"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 205,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 5,
                        "start_line": 205
                    }
                },
                "113": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.get_block_timestamp"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 92,
                        "end_line": 300,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 64,
                        "start_line": 300
                    }
                },
                "115": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.get_block_timestamp"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 94,
                        "end_line": 300,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 5,
                        "start_line": 300
                    }
                },
                "116": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.get_block_timestamp"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 94,
                                "end_line": 301,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                },
                                "start_col": 5,
                                "start_line": 301
                            },
                            "n_prefix_newlines": 0
                        }
                    ],
                    "inst": {
                        "end_col": 59,
                        "end_line": 302,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 44,
                                "end_line": 298,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 63,
                                        "end_line": 303,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 303
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 26,
                                "start_line": 298
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 23,
                        "start_line": 302
                    }
                },
                "118": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.get_block_timestamp"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 303,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 29,
                        "start_line": 303
                    }
                },
                "119": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.get_block_timestamp"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 63,
                        "end_line": 303,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 5,
                        "start_line": 303
                    }
                },
                "120": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.storage_read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 79,
                        "end_line": 354,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 58,
                        "start_line": 354
                    }
                },
                "122": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.storage_read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 98,
                        "end_line": 354,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 5,
                        "start_line": 354
                    }
                },
                "123": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.storage_read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 98,
                        "end_line": 354,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 5,
                        "start_line": 354
                    }
                },
                "124": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.storage_read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 87,
                                "end_line": 355,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                },
                                "start_col": 5,
                                "start_line": 355
                            },
                            "n_prefix_newlines": 0
                        }
                    ],
                    "inst": {
                        "end_col": 53,
                        "end_line": 357,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 37,
                                "end_line": 352,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 35,
                                        "end_line": 358,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 358
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 19,
                                "start_line": 352
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 23,
                        "start_line": 357
                    }
                },
                "126": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.storage_read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 358,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 19,
                        "start_line": 358
                    }
                },
                "127": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.storage_read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 358,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 5,
                        "start_line": 358
                    }
                },
                "128": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.storage_write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 372,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 18,
                        "start_line": 372
                    }
                },
                "130": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.storage_write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 373,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 5,
                        "start_line": 371
                    }
                },
                "131": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.storage_write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 373,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 5,
                        "start_line": 371
                    }
                },
                "132": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.storage_write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 373,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 5,
                        "start_line": 371
                    }
                },
                "133": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.storage_write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 88,
                                "end_line": 374,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                },
                                "start_col": 5,
                                "start_line": 374
                            },
                            "n_prefix_newlines": 0
                        }
                    ],
                    "inst": {
                        "end_col": 54,
                        "end_line": 375,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 38,
                                "end_line": 370,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 15,
                                        "end_line": 376,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 376
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 20,
                                "start_line": 370
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 23,
                        "start_line": 375
                    }
                },
                "135": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.storage_write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 376,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 5,
                        "start_line": 376
                    }
                },
                "136": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.emit_event"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 392,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 18,
                        "start_line": 392
                    }
                },
                "138": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.emit_event"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 393,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 5,
                        "start_line": 391
                    }
                },
                "139": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.emit_event"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 393,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 5,
                        "start_line": 391
                    }
                },
                "140": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.emit_event"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 393,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 5,
                        "start_line": 391
                    }
                },
                "141": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.emit_event"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 393,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 5,
                        "start_line": 391
                    }
                },
                "142": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.emit_event"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 393,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 5,
                        "start_line": 391
                    }
                },
                "143": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.emit_event"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 85,
                                "end_line": 394,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                },
                                "start_col": 5,
                                "start_line": 394
                            },
                            "n_prefix_newlines": 0
                        }
                    ],
                    "inst": {
                        "end_col": 51,
                        "end_line": 395,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 35,
                                "end_line": 390,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 15,
                                        "end_line": 396,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 396
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 17,
                                "start_line": 390
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 23,
                        "start_line": 395
                    }
                },
                "145": {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.emit_event"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 396,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "start_col": 5,
                        "start_line": 396
                    }
                },
                "146": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_nn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 84,
                                "end_line": 19,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                                },
                                "start_col": 5,
                                "start_line": 19
                            },
                            "n_prefix_newlines": 0
                        }
                    ],
                    "inst": {
                        "end_col": 40,
                        "end_line": 20,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "start_col": 5,
                        "start_line": 20
                    }
                },
                "148": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_nn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 26,
                        "end_line": 21,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "start_col": 5,
                        "start_line": 21
                    }
                },
                "149": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_nn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 13,
                        "end_line": 22,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "start_col": 5,
                        "start_line": 22
                    }
                },
                "151": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_nn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 46,
                        "end_line": 23,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 27,
                                "end_line": 18,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 14,
                                        "end_line": 24,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 24
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 12,
                                "start_line": 18
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 27,
                        "start_line": 23
                    }
                },
                "153": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_nn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 13,
                        "end_line": 24,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "start_col": 12,
                        "start_line": 24
                    }
                },
                "155": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_nn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 14,
                        "end_line": 24,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "start_col": 5,
                        "start_line": 24
                    }
                },
                "156": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_nn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 91,
                                "end_line": 27,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                                },
                                "start_col": 5,
                                "start_line": 27
                            },
                            "n_prefix_newlines": 0
                        }
                    ],
                    "inst": {
                        "end_col": 48,
                        "end_line": 28,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "start_col": 5,
                        "start_line": 28
                    }
                },
                "158": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_nn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 29,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "start_col": 33,
                        "start_line": 29
                    }
                },
                "160": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_nn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 29,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "start_col": 32,
                        "start_line": 29
                    }
                },
                "162": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_nn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 29,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "start_col": 5,
                        "start_line": 29
                    }
                },
                "163": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_nn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 13,
                        "end_line": 30,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "start_col": 5,
                        "start_line": 30
                    }
                },
                "165": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_nn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 46,
                        "end_line": 31,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 27,
                                "end_line": 18,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 14,
                                        "end_line": 32,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 32
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 12,
                                "start_line": 18
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 27,
                        "start_line": 31
                    }
                },
                "167": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_nn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 13,
                        "end_line": 32,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "start_col": 12,
                        "start_line": 32
                    }
                },
                "169": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_nn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 14,
                        "end_line": 32,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "start_col": 5,
                        "start_line": 32
                    }
                },
                "170": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_nn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 18,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 154,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 32,
                                        "end_line": 35,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 35
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 21,
                                "start_line": 154
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 12,
                        "start_line": 18
                    }
                },
                "171": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_nn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 35,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "start_col": 20,
                        "start_line": 35
                    }
                },
                "173": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_nn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 18,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 31,
                                "end_line": 35,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                                },
                                "start_col": 30,
                                "start_line": 35
                            },
                            "While expanding the reference 'a' in:"
                        ],
                        "start_col": 29,
                        "start_line": 18
                    }
                },
                "174": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_nn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 35,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "start_col": 5,
                        "start_line": 35
                    }
                },
                "176": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_nn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 13,
                        "end_line": 36,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "start_col": 12,
                        "start_line": 36
                    }
                },
                "178": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_nn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 14,
                        "end_line": 36,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "start_col": 5,
                        "start_line": 36
                    }
                },
                "179": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_le"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 42,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 27,
                                "end_line": 18,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 24,
                                        "end_line": 43,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                                        },
                                        "start_col": 12,
                                        "start_line": 43
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 12,
                                "start_line": 18
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 12,
                        "start_line": 42
                    }
                },
                "180": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_le"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 43,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "start_col": 18,
                        "start_line": 43
                    }
                },
                "181": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_le"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 43,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "start_col": 12,
                        "start_line": 43
                    }
                },
                "183": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_le"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 25,
                        "end_line": 43,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "start_col": 5,
                        "start_line": 43
                    }
                },
                "184": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 70,
                                "end_line": 80,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                                },
                                "start_col": 5,
                                "start_line": 80
                            },
                            "n_prefix_newlines": 0
                        }
                    ],
                    "inst": {
                        "end_col": 34,
                        "end_line": 81,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "start_col": 5,
                        "start_line": 81
                    }
                },
                "186": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 12,
                        "end_line": 82,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "start_col": 5,
                        "start_line": 82
                    }
                },
                "188": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 79,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 154,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 25,
                                        "end_line": 83,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 83
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 21,
                                "start_line": 154
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 79
                    }
                },
                "189": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 79,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 83,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                                },
                                "start_col": 20,
                                "start_line": 83
                            },
                            "While expanding the reference 'a' in:"
                        ],
                        "start_col": 34,
                        "start_line": 79
                    }
                },
                "190": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 79,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 83,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                                },
                                "start_col": 23,
                                "start_line": 83
                            },
                            "While expanding the reference 'b' in:"
                        ],
                        "start_col": 37,
                        "start_line": 79
                    }
                },
                "191": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 25,
                        "end_line": 83,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "start_col": 5,
                        "start_line": 83
                    }
                },
                "193": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 13,
                        "end_line": 84,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "start_col": 12,
                        "start_line": 84
                    }
                },
                "195": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 14,
                        "end_line": 84,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "start_col": 5,
                        "start_line": 84
                    }
                },
                "196": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 79,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 223,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 25,
                                        "end_line": 87,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 87
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 21,
                                "start_line": 223
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 79
                    }
                },
                "197": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 79,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 87,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                                },
                                "start_col": 20,
                                "start_line": 87
                            },
                            "While expanding the reference 'b' in:"
                        ],
                        "start_col": 37,
                        "start_line": 79
                    }
                },
                "198": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 79,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 87,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                                },
                                "start_col": 23,
                                "start_line": 87
                            },
                            "While expanding the reference 'a' in:"
                        ],
                        "start_col": 34,
                        "start_line": 79
                    }
                },
                "199": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 25,
                        "end_line": 87,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "start_col": 5,
                        "start_line": 87
                    }
                },
                "201": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 13,
                        "end_line": 88,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "start_col": 12,
                        "start_line": 88
                    }
                },
                "203": {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_le_felt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 14,
                        "end_line": 88,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "start_col": 5,
                        "start_line": 88
                    }
                },
                "204": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_check"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 22,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 22
                    }
                },
                "205": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_check"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 23,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 23
                    }
                },
                "206": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_check"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 46,
                        "end_line": 24,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 35,
                                "end_line": 21,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 15,
                                        "end_line": 25,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 25
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 20,
                                "start_line": 21
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 27,
                        "start_line": 24
                    }
                },
                "208": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_check"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 25,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 25
                    }
                },
                "209": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 32,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 32
                    }
                },
                "211": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 7,
                                "end_line": 41,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 5,
                                "start_line": 36
                            },
                            "n_prefix_newlines": 1
                        }
                    ],
                    "inst": {
                        "end_col": 46,
                        "end_line": 43,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 43
                    }
                },
                "212": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 49,
                        "end_line": 44,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 44
                    }
                },
                "213": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 46,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 22,
                        "start_line": 46
                    }
                },
                "214": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 55,
                        "end_line": 46,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 38,
                        "start_line": 46
                    }
                },
                "216": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 56,
                        "end_line": 46,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 46
                    }
                },
                "217": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 47,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 23,
                        "start_line": 47
                    }
                },
                "218": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 50,
                        "end_line": 47,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 23,
                        "start_line": 47
                    }
                },
                "219": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 71,
                        "end_line": 47,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 53,
                        "start_line": 47
                    }
                },
                "221": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 47,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 47
                    }
                },
                "222": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 31,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 35,
                                "end_line": 21,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 23,
                                        "end_line": 48,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 48
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 20,
                                "start_line": 21
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 18,
                        "start_line": 31
                    }
                },
                "223": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 14,
                        "end_line": 33,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 48,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 19,
                                "start_line": 48
                            },
                            "While expanding the reference 'res' in:"
                        ],
                        "start_col": 11,
                        "start_line": 33
                    }
                },
                "224": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 14,
                        "end_line": 33,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 48,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 19,
                                "start_line": 48
                            },
                            "While expanding the reference 'res' in:"
                        ],
                        "start_col": 11,
                        "start_line": 33
                    }
                },
                "225": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 48,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 48
                    }
                },
                "227": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 14,
                        "end_line": 33,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 16,
                                "end_line": 50,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 13,
                                "start_line": 50
                            },
                            "While expanding the reference 'res' in:"
                        ],
                        "start_col": 11,
                        "start_line": 33
                    }
                },
                "228": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 14,
                        "end_line": 33,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 16,
                                "end_line": 50,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 13,
                                "start_line": 50
                            },
                            "While expanding the reference 'res' in:"
                        ],
                        "start_col": 11,
                        "start_line": 33
                    }
                },
                "229": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 35,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 28,
                                "end_line": 50,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 18,
                                "start_line": 50
                            },
                            "While expanding the reference 'carry_high' in:"
                        ],
                        "start_col": 11,
                        "start_line": 35
                    }
                },
                "230": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 50,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 50
                    }
                },
                "231": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.split_64"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 56,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 56
                    }
                },
                "233": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.split_64"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 7,
                                "end_line": 63,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 5,
                                "start_line": 60
                            },
                            "n_prefix_newlines": 1
                        }
                    ],
                    "inst": {
                        "end_col": 39,
                        "end_line": 64,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 22,
                        "start_line": 64
                    }
                },
                "235": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.split_64"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 64,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 64
                    }
                },
                "236": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.split_64"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 65,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 65
                    }
                },
                "237": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.split_64"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 50,
                        "end_line": 66,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 36,
                        "start_line": 66
                    }
                },
                "239": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.split_64"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 56,
                        "end_line": 66,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 36,
                        "start_line": 66
                    }
                },
                "240": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.split_64"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 57,
                        "end_line": 66,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 66
                    }
                },
                "241": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.split_64"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 67,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 67
                    }
                },
                "242": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.split_64"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 46,
                        "end_line": 68,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 30,
                                "end_line": 55,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 24,
                                        "end_line": 69,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 69
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 15,
                                "start_line": 55
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 27,
                        "start_line": 68
                    }
                },
                "244": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.split_64"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 14,
                        "end_line": 57,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 16,
                                "end_line": 69,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 13,
                                "start_line": 69
                            },
                            "While expanding the reference 'low' in:"
                        ],
                        "start_col": 11,
                        "start_line": 57
                    }
                },
                "245": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.split_64"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 58,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 69,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 18,
                                "start_line": 69
                            },
                            "While expanding the reference 'high' in:"
                        ],
                        "start_col": 11,
                        "start_line": 58
                    }
                },
                "246": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.split_64"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 69,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 69
                    }
                },
                "247": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 74,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 74
                    }
                },
                "249": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 73,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 30,
                                "end_line": 55,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 35,
                                        "end_line": 75,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "start_col": 20,
                                        "start_line": 75
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 15,
                                "start_line": 55
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 18,
                        "start_line": 73
                    }
                },
                "250": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 75,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 29,
                        "start_line": 75
                    }
                },
                "251": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 75,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 20,
                        "start_line": 75
                    }
                },
                "253": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 55,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 35,
                                "end_line": 75,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 30,
                                        "end_line": 55,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 36,
                                                "end_line": 76,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                                },
                                                "start_col": 20,
                                                "start_line": 76
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 15,
                                        "start_line": 55
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 20,
                                "start_line": 75
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 55
                    }
                },
                "254": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 76,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 29,
                        "start_line": 76
                    }
                },
                "255": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 36,
                        "end_line": 76,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 20,
                        "start_line": 76
                    }
                },
                "257": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 55,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 76,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 30,
                                        "end_line": 55,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 35,
                                                "end_line": 77,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                                },
                                                "start_col": 20,
                                                "start_line": 77
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 15,
                                        "start_line": 55
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 20,
                                "start_line": 76
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 55
                    }
                },
                "258": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 77,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 29,
                        "start_line": 77
                    }
                },
                "259": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 77,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 20,
                        "start_line": 77
                    }
                },
                "261": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 55,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 35,
                                "end_line": 77,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 30,
                                        "end_line": 55,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 36,
                                                "end_line": 78,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                                },
                                                "start_col": 20,
                                                "start_line": 78
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 15,
                                        "start_line": 55
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 20,
                                "start_line": 77
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 55
                    }
                },
                "262": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 78,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 29,
                        "start_line": 78
                    }
                },
                "263": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 36,
                        "end_line": 78,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 20,
                        "start_line": 78
                    }
                },
                "265": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 55,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 78,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 30,
                                        "end_line": 55,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 42,
                                                "end_line": 80,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                                },
                                                "start_col": 25,
                                                "start_line": 80
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 15,
                                        "start_line": 55
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 20,
                                "start_line": 78
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 55
                    }
                },
                "266": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 80,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 34,
                        "start_line": 80
                    }
                },
                "267": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 42,
                        "end_line": 80,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 25,
                        "start_line": 80
                    }
                },
                "269": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 81,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 34,
                        "start_line": 81
                    }
                },
                "270": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 51,
                        "end_line": 81,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 44,
                        "start_line": 81
                    }
                },
                "271": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 51,
                        "end_line": 81,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 34,
                        "start_line": 81
                    }
                },
                "272": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 55,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 80,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 30,
                                        "end_line": 55,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 60,
                                                "end_line": 81,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                                },
                                                "start_col": 25,
                                                "start_line": 81
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 15,
                                        "start_line": 55
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 25,
                                "start_line": 80
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 55
                    }
                },
                "273": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 59,
                        "end_line": 81,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 34,
                        "start_line": 81
                    }
                },
                "274": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 60,
                        "end_line": 81,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 25,
                        "start_line": 81
                    }
                },
                "276": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 82,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 34,
                        "start_line": 82
                    }
                },
                "277": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 51,
                        "end_line": 82,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 44,
                        "start_line": 82
                    }
                },
                "278": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 51,
                        "end_line": 82,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 34,
                        "start_line": 82
                    }
                },
                "279": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 82,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 54,
                        "start_line": 82
                    }
                },
                "280": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 82,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 34,
                        "start_line": 82
                    }
                },
                "281": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 55,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 60,
                                "end_line": 81,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 30,
                                        "end_line": 55,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 70,
                                                "end_line": 82,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                                },
                                                "start_col": 25,
                                                "start_line": 82
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 15,
                                        "start_line": 55
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 25,
                                "start_line": 81
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 55
                    }
                },
                "282": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 69,
                        "end_line": 82,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 34,
                        "start_line": 82
                    }
                },
                "283": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 70,
                        "end_line": 82,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 25,
                        "start_line": 82
                    }
                },
                "285": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 83,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 34,
                        "start_line": 83
                    }
                },
                "286": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 51,
                        "end_line": 83,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 44,
                        "start_line": 83
                    }
                },
                "287": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 51,
                        "end_line": 83,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 34,
                        "start_line": 83
                    }
                },
                "288": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 83,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 54,
                        "start_line": 83
                    }
                },
                "289": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 83,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 34,
                        "start_line": 83
                    }
                },
                "290": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 71,
                        "end_line": 83,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 64,
                        "start_line": 83
                    }
                },
                "291": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 71,
                        "end_line": 83,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 34,
                        "start_line": 83
                    }
                },
                "292": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 55,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 70,
                                "end_line": 82,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 30,
                                        "end_line": 55,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 80,
                                                "end_line": 83,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                                },
                                                "start_col": 25,
                                                "start_line": 83
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 15,
                                        "start_line": 55
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 25,
                                "start_line": 82
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 55
                    }
                },
                "293": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 79,
                        "end_line": 83,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 34,
                        "start_line": 83
                    }
                },
                "294": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 80,
                        "end_line": 83,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 25,
                        "start_line": 83
                    }
                },
                "296": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 84,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 34,
                        "start_line": 84
                    }
                },
                "297": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 51,
                        "end_line": 84,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 44,
                        "start_line": 84
                    }
                },
                "298": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 51,
                        "end_line": 84,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 34,
                        "start_line": 84
                    }
                },
                "299": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 84,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 54,
                        "start_line": 84
                    }
                },
                "300": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 84,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 34,
                        "start_line": 84
                    }
                },
                "301": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 55,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 80,
                                "end_line": 83,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 30,
                                        "end_line": 55,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 70,
                                                "end_line": 84,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                                },
                                                "start_col": 25,
                                                "start_line": 84
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 15,
                                        "start_line": 55
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 25,
                                "start_line": 83
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 55
                    }
                },
                "302": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 69,
                        "end_line": 84,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 34,
                        "start_line": 84
                    }
                },
                "303": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 70,
                        "end_line": 84,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 25,
                        "start_line": 84
                    }
                },
                "305": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 85,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 34,
                        "start_line": 85
                    }
                },
                "306": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 51,
                        "end_line": 85,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 44,
                        "start_line": 85
                    }
                },
                "307": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 51,
                        "end_line": 85,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 34,
                        "start_line": 85
                    }
                },
                "308": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 55,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 70,
                                "end_line": 84,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 30,
                                        "end_line": 55,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 60,
                                                "end_line": 85,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                                },
                                                "start_col": 25,
                                                "start_line": 85
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 15,
                                        "start_line": 55
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 25,
                                "start_line": 84
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 55
                    }
                },
                "309": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 59,
                        "end_line": 85,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 34,
                        "start_line": 85
                    }
                },
                "310": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 60,
                        "end_line": 85,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 25,
                        "start_line": 85
                    }
                },
                "312": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 86,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 34,
                        "start_line": 86
                    }
                },
                "313": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 55,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 60,
                                "end_line": 85,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 30,
                                        "end_line": 55,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 50,
                                                "end_line": 86,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                                },
                                                "start_col": 25,
                                                "start_line": 86
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 15,
                                        "start_line": 55
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 25,
                                "start_line": 85
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 55
                    }
                },
                "314": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 49,
                        "end_line": 86,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 34,
                        "start_line": 86
                    }
                },
                "315": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 50,
                        "end_line": 86,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 25,
                        "start_line": 86
                    }
                },
                "317": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 42,
                        "end_line": 89,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 32,
                        "start_line": 89
                    }
                },
                "319": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 49,
                        "end_line": 89,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 32,
                        "start_line": 89
                    }
                },
                "320": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 73,
                        "end_line": 89,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 63,
                        "start_line": 89
                    }
                },
                "322": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 80,
                        "end_line": 89,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 63,
                        "start_line": 89
                    }
                },
                "323": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 43,
                        "end_line": 90,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 33,
                        "start_line": 90
                    }
                },
                "325": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 50,
                        "end_line": 90,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 33,
                        "start_line": 90
                    }
                },
                "326": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 74,
                        "end_line": 90,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 64,
                        "start_line": 90
                    }
                },
                "328": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 90,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 64,
                        "start_line": 90
                    }
                },
                "329": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 55,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 50,
                                "end_line": 86,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 33,
                                        "end_line": 73,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 7,
                                                "end_line": 91,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 88
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 18,
                                        "start_line": 73
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 25,
                                "start_line": 86
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 55
                    }
                },
                "330": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 49,
                        "end_line": 89,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 25,
                        "start_line": 89
                    }
                },
                "331": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 80,
                        "end_line": 89,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 56,
                        "start_line": 89
                    }
                },
                "332": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 50,
                        "end_line": 90,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 26,
                        "start_line": 90
                    }
                },
                "333": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 90,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 57,
                        "start_line": 90
                    }
                },
                "334": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 91,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 88
                    }
                },
                "335": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 25,
                        "end_line": 134,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 9,
                        "start_line": 134
                    }
                },
                "336": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 134,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 134
                    }
                },
                "338": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 133,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 27,
                                "end_line": 42,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 40,
                                        "end_line": 135,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "start_col": 17,
                                        "start_line": 135
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 12,
                                "start_line": 42
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 133
                    }
                },
                "339": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 135,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 23,
                        "start_line": 135
                    }
                },
                "341": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 39,
                        "end_line": 135,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 34,
                        "start_line": 135
                    }
                },
                "342": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 135,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 17,
                        "start_line": 135
                    }
                },
                "344": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 43,
                        "end_line": 135,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 9,
                        "start_line": 135
                    }
                },
                "345": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 133,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 27,
                                "end_line": 42,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 38,
                                        "end_line": 137,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "start_col": 13,
                                        "start_line": 137
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 12,
                                "start_line": 42
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 133
                    }
                },
                "346": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 29,
                        "end_line": 137,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 19,
                        "start_line": 137
                    }
                },
                "348": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 137,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 31,
                        "start_line": 137
                    }
                },
                "349": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 137,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 13,
                        "start_line": 137
                    }
                },
                "351": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 137,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 137
                    }
                },
                "352": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_le"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 148,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 32,
                                "end_line": 133,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 40,
                                        "end_line": 149,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "start_col": 20,
                                        "start_line": 149
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 17,
                                "start_line": 133
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 148
                    }
                },
                "353": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_le"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 56,
                        "end_line": 148,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 149,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 33,
                                "start_line": 149
                            },
                            "While expanding the reference 'b' in:"
                        ],
                        "start_col": 46,
                        "start_line": 148
                    }
                },
                "354": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_le"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 56,
                        "end_line": 148,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 149,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 33,
                                "start_line": 149
                            },
                            "While expanding the reference 'b' in:"
                        ],
                        "start_col": 46,
                        "start_line": 148
                    }
                },
                "355": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_le"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 44,
                        "end_line": 148,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 39,
                                "end_line": 149,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 38,
                                "start_line": 149
                            },
                            "While expanding the reference 'a' in:"
                        ],
                        "start_col": 34,
                        "start_line": 148
                    }
                },
                "356": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_le"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 44,
                        "end_line": 148,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 39,
                                "end_line": 149,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 38,
                                "start_line": 149
                            },
                            "While expanding the reference 'a' in:"
                        ],
                        "start_col": 34,
                        "start_line": 148
                    }
                },
                "357": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_le"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 149,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 20,
                        "start_line": 149
                    }
                },
                "359": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_le"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 150,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 17,
                        "start_line": 150
                    }
                },
                "361": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_le"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 133,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 40,
                                "end_line": 149,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 32,
                                        "end_line": 148,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 29,
                                                "end_line": 150,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 150
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 17,
                                        "start_line": 148
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 20,
                                "start_line": 149
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 133
                    }
                },
                "362": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_le"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 150,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 17,
                        "start_line": 150
                    }
                },
                "363": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_le"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 29,
                        "end_line": 150,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 150
                    }
                },
                "364": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 191,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 191
                    }
                },
                "366": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 194,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 9,
                        "start_line": 194
                    }
                },
                "367": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 194,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 194
                    }
                },
                "369": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 46,
                        "end_line": 188,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 46,
                                "end_line": 188,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 66,
                                        "end_line": 195,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "start_col": 9,
                                        "start_line": 195
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 31,
                                "start_line": 188
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 31,
                        "start_line": 188
                    }
                },
                "370": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 195,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 34,
                        "start_line": 195
                    }
                },
                "372": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 195,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 37,
                        "start_line": 195
                    }
                },
                "374": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 60,
                        "end_line": 195,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 59,
                        "start_line": 195
                    }
                },
                "376": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 63,
                        "end_line": 195,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 62,
                        "start_line": 195
                    }
                },
                "378": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 66,
                        "end_line": 195,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 9,
                        "start_line": 195
                    }
                },
                "379": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 7,
                                "end_line": 210,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 5,
                                "start_line": 201
                            },
                            "n_prefix_newlines": 1
                        }
                    ],
                    "inst": {
                        "end_col": 46,
                        "end_line": 188,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 35,
                                "end_line": 21,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 28,
                                        "end_line": 211,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 211
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 20,
                                "start_line": 21
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 31,
                        "start_line": 188
                    }
                },
                "380": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 19,
                        "end_line": 199,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 27,
                                "end_line": 211,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 19,
                                "start_line": 211
                            },
                            "While expanding the reference 'quotient' in:"
                        ],
                        "start_col": 11,
                        "start_line": 199
                    }
                },
                "381": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 19,
                        "end_line": 199,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 27,
                                "end_line": 211,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 19,
                                "start_line": 211
                            },
                            "While expanding the reference 'quotient' in:"
                        ],
                        "start_col": 11,
                        "start_line": 199
                    }
                },
                "382": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 211,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 211
                    }
                },
                "384": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 200,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 28,
                                "end_line": 212,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 19,
                                "start_line": 212
                            },
                            "While expanding the reference 'remainder' in:"
                        ],
                        "start_col": 11,
                        "start_line": 200
                    }
                },
                "385": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 200,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 28,
                                "end_line": 212,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 19,
                                "start_line": 212
                            },
                            "While expanding the reference 'remainder' in:"
                        ],
                        "start_col": 11,
                        "start_line": 200
                    }
                },
                "386": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 29,
                        "end_line": 212,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 212
                    }
                },
                "388": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 19,
                        "end_line": 199,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 48,
                                "end_line": 213,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 40,
                                "start_line": 213
                            },
                            "While expanding the reference 'quotient' in:"
                        ],
                        "start_col": 11,
                        "start_line": 199
                    }
                },
                "389": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 19,
                        "end_line": 199,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 48,
                                "end_line": 213,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 40,
                                "start_line": 213
                            },
                            "While expanding the reference 'quotient' in:"
                        ],
                        "start_col": 11,
                        "start_line": 199
                    }
                },
                "390": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 188,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 53,
                                "end_line": 213,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 50,
                                "start_line": 213
                            },
                            "While expanding the reference 'div' in:"
                        ],
                        "start_col": 60,
                        "start_line": 188
                    }
                },
                "391": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 188,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 53,
                                "end_line": 213,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 50,
                                "start_line": 213
                            },
                            "While expanding the reference 'div' in:"
                        ],
                        "start_col": 60,
                        "start_line": 188
                    }
                },
                "392": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 213,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 28,
                        "start_line": 213
                    }
                },
                "394": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 214,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 214
                    }
                },
                "396": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 214,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 214
                    }
                },
                "398": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 73,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 54,
                                "end_line": 213,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 33,
                                        "end_line": 31,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 65,
                                                "end_line": 216,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                                },
                                                "start_col": 34,
                                                "start_line": 216
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 18,
                                        "start_line": 31
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 28,
                                "start_line": 213
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 18,
                        "start_line": 73
                    }
                },
                "399": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 17,
                        "end_line": 213,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 53,
                                "end_line": 216,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 46,
                                "start_line": 216
                            },
                            "While expanding the reference 'res_mul' in:"
                        ],
                        "start_col": 10,
                        "start_line": 213
                    }
                },
                "400": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 17,
                        "end_line": 213,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 53,
                                "end_line": 216,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 46,
                                "start_line": 216
                            },
                            "While expanding the reference 'res_mul' in:"
                        ],
                        "start_col": 10,
                        "start_line": 213
                    }
                },
                "401": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 200,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 64,
                                "end_line": 216,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 55,
                                "start_line": 216
                            },
                            "While expanding the reference 'remainder' in:"
                        ],
                        "start_col": 11,
                        "start_line": 200
                    }
                },
                "402": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 200,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 64,
                                "end_line": 216,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 55,
                                "start_line": 216
                            },
                            "While expanding the reference 'remainder' in:"
                        ],
                        "start_col": 11,
                        "start_line": 200
                    }
                },
                "403": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 65,
                        "end_line": 216,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 34,
                        "start_line": 216
                    }
                },
                "405": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 26,
                        "end_line": 217,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 217
                    }
                },
                "406": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 26,
                        "end_line": 217,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 217
                    }
                },
                "407": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 26,
                        "end_line": 218,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 218
                    }
                },
                "409": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 31,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 65,
                                "end_line": 216,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 32,
                                        "end_line": 133,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 48,
                                                "end_line": 220,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                                },
                                                "start_col": 22,
                                                "start_line": 220
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 17,
                                        "start_line": 133
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 34,
                                "start_line": 216
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 18,
                        "start_line": 31
                    }
                },
                "410": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 200,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 220,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 33,
                                "start_line": 220
                            },
                            "While expanding the reference 'remainder' in:"
                        ],
                        "start_col": 11,
                        "start_line": 200
                    }
                },
                "411": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 200,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 220,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 33,
                                "start_line": 220
                            },
                            "While expanding the reference 'remainder' in:"
                        ],
                        "start_col": 11,
                        "start_line": 200
                    }
                },
                "412": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 188,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 47,
                                "end_line": 220,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 44,
                                "start_line": 220
                            },
                            "While expanding the reference 'div' in:"
                        ],
                        "start_col": 60,
                        "start_line": 188
                    }
                },
                "413": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 188,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 47,
                                "end_line": 220,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 44,
                                "start_line": 220
                            },
                            "While expanding the reference 'div' in:"
                        ],
                        "start_col": 60,
                        "start_line": 188
                    }
                },
                "414": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 220,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 22,
                        "start_line": 220
                    }
                },
                "416": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 25,
                        "end_line": 221,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 221
                    }
                },
                "418": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 133,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 48,
                                "end_line": 220,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 46,
                                        "end_line": 188,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 53,
                                                "end_line": 222,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 222
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 31,
                                        "start_line": 188
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 22,
                                "start_line": 220
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 133
                    }
                },
                "419": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 19,
                        "end_line": 199,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 30,
                                "end_line": 222,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 22,
                                "start_line": 222
                            },
                            "While expanding the reference 'quotient' in:"
                        ],
                        "start_col": 11,
                        "start_line": 199
                    }
                },
                "420": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 19,
                        "end_line": 199,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 30,
                                "end_line": 222,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 22,
                                "start_line": 222
                            },
                            "While expanding the reference 'quotient' in:"
                        ],
                        "start_col": 11,
                        "start_line": 199
                    }
                },
                "421": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 200,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 51,
                                "end_line": 222,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 42,
                                "start_line": 222
                            },
                            "While expanding the reference 'remainder' in:"
                        ],
                        "start_col": 11,
                        "start_line": 200
                    }
                },
                "422": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 200,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 51,
                                "end_line": 222,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 42,
                                "start_line": 222
                            },
                            "While expanding the reference 'remainder' in:"
                        ],
                        "start_col": 11,
                        "start_line": 200
                    }
                },
                "423": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 53,
                        "end_line": 222,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 222
                    }
                },
                "424": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_not"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 285,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 29,
                        "start_line": 285
                    }
                },
                "426": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_not"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 60,
                        "end_line": 285,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 52,
                        "start_line": 285
                    }
                },
                "428": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_not"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 284,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 33,
                                "end_line": 284,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 72,
                                        "end_line": 285,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 285
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 18,
                                "start_line": 284
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 18,
                        "start_line": 284
                    }
                },
                "429": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_not"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 45,
                        "end_line": 285,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 29,
                        "start_line": 285
                    }
                },
                "430": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_not"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 69,
                        "end_line": 285,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 52,
                        "start_line": 285
                    }
                },
                "431": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_not"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 285,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 285
                    }
                },
                "432": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_neg"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 290,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 33,
                                "end_line": 284,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 35,
                                        "end_line": 291,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "start_col": 21,
                                        "start_line": 291
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 18,
                                "start_line": 284
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 18,
                        "start_line": 290
                    }
                },
                "433": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_neg"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 45,
                        "end_line": 290,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 291,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 33,
                                "start_line": 291
                            },
                            "While expanding the reference 'a' in:"
                        ],
                        "start_col": 35,
                        "start_line": 290
                    }
                },
                "434": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_neg"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 45,
                        "end_line": 290,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 291,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 33,
                                "start_line": 291
                            },
                            "While expanding the reference 'a' in:"
                        ],
                        "start_col": 35,
                        "start_line": 290
                    }
                },
                "435": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_neg"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 291,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 21,
                        "start_line": 291
                    }
                },
                "437": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_neg"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 292,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 53,
                        "start_line": 292
                    }
                },
                "439": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_neg"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 62,
                        "end_line": 292,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 61,
                        "start_line": 292
                    }
                },
                "441": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_neg"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 64,
                        "end_line": 292,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 20,
                        "start_line": 292
                    }
                },
                "443": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_neg"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 31,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 64,
                                "end_line": 292,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 33,
                                        "end_line": 290,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 293,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 293
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 18,
                                        "start_line": 290
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 20,
                                "start_line": 292
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 18,
                        "start_line": 31
                    }
                },
                "444": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_neg"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 13,
                        "end_line": 292,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 293,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 17,
                                "start_line": 293
                            },
                            "While expanding the reference 'res' in:"
                        ],
                        "start_col": 10,
                        "start_line": 292
                    }
                },
                "445": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_neg"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 13,
                        "end_line": 292,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 293,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 17,
                                "start_line": 293
                            },
                            "While expanding the reference 'res' in:"
                        ],
                        "start_col": 10,
                        "start_line": 292
                    }
                },
                "446": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_neg"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 293,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 293
                    }
                },
                "447": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_sub"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 348,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 33,
                                "end_line": 290,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 33,
                                        "end_line": 349,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "start_col": 19,
                                        "start_line": 349
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 18,
                                "start_line": 290
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 18,
                        "start_line": 348
                    }
                },
                "448": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_sub"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 57,
                        "end_line": 348,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 32,
                                "end_line": 349,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 31,
                                "start_line": 349
                            },
                            "While expanding the reference 'b' in:"
                        ],
                        "start_col": 47,
                        "start_line": 348
                    }
                },
                "449": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_sub"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 57,
                        "end_line": 348,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 32,
                                "end_line": 349,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 31,
                                "start_line": 349
                            },
                            "While expanding the reference 'b' in:"
                        ],
                        "start_col": 47,
                        "start_line": 348
                    }
                },
                "450": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_sub"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 349,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 19,
                        "start_line": 349
                    }
                },
                "452": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_sub"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 290,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 33,
                                "end_line": 349,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 33,
                                        "end_line": 31,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 41,
                                                "end_line": 350,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                                },
                                                "start_col": 20,
                                                "start_line": 350
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 18,
                                        "start_line": 31
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 19,
                                "start_line": 349
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 18,
                        "start_line": 290
                    }
                },
                "453": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_sub"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 45,
                        "end_line": 348,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 33,
                                "end_line": 350,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 32,
                                "start_line": 350
                            },
                            "While expanding the reference 'a' in:"
                        ],
                        "start_col": 35,
                        "start_line": 348
                    }
                },
                "454": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_sub"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 45,
                        "end_line": 348,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 33,
                                "end_line": 350,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 32,
                                "start_line": 350
                            },
                            "While expanding the reference 'a' in:"
                        ],
                        "start_col": 35,
                        "start_line": 348
                    }
                },
                "455": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_sub"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 349,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 40,
                                "end_line": 350,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 35,
                                "start_line": 350
                            },
                            "While expanding the reference 'b_neg' in:"
                        ],
                        "start_col": 10,
                        "start_line": 349
                    }
                },
                "456": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_sub"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 349,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 40,
                                "end_line": 350,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 35,
                                "start_line": 350
                            },
                            "While expanding the reference 'b_neg' in:"
                        ],
                        "start_col": 10,
                        "start_line": 349
                    }
                },
                "457": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_sub"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 350,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 20,
                        "start_line": 350
                    }
                },
                "459": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_sub"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 31,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 41,
                                "end_line": 350,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 33,
                                        "end_line": 348,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 351,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 351
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 18,
                                        "start_line": 348
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 20,
                                "start_line": 350
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 18,
                        "start_line": 31
                    }
                },
                "460": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_sub"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 13,
                        "end_line": 350,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 351,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 17,
                                "start_line": 351
                            },
                            "While expanding the reference 'res' in:"
                        ],
                        "start_col": 10,
                        "start_line": 350
                    }
                },
                "461": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_sub"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 13,
                        "end_line": 350,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 351,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "start_col": 17,
                                "start_line": 351
                            },
                            "While expanding the reference 'res' in:"
                        ],
                        "start_col": 10,
                        "start_line": 350
                    }
                },
                "462": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_sub"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 351,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 351
                    }
                },
                "463": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_eq"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 25,
                        "end_line": 358,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 9,
                        "start_line": 358
                    }
                },
                "464": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_eq"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 358,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 358
                    }
                },
                "466": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_eq"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 358,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 358
                    }
                },
                "468": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_eq"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 357,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 32,
                                "end_line": 357,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 24,
                                        "end_line": 359,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "start_col": 9,
                                        "start_line": 359
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 17,
                                "start_line": 357
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 357
                    }
                },
                "469": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_eq"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 359,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 21,
                        "start_line": 359
                    }
                },
                "471": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_eq"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 359,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 9,
                        "start_line": 359
                    }
                },
                "472": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_eq"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 361,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 9,
                        "start_line": 361
                    }
                },
                "473": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_eq"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 361,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 361
                    }
                },
                "475": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_eq"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 361,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 361
                    }
                },
                "477": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_eq"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 357,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 32,
                                "end_line": 357,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 24,
                                        "end_line": 362,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "start_col": 9,
                                        "start_line": 362
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 17,
                                "start_line": 357
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 357
                    }
                },
                "478": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_eq"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 362,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 21,
                        "start_line": 362
                    }
                },
                "480": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_eq"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 362,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 9,
                        "start_line": 362
                    }
                },
                "481": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_eq"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 357,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 32,
                                "end_line": 357,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 20,
                                        "end_line": 364,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 364
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 17,
                                "start_line": 357
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 357
                    }
                },
                "482": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_eq"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 364,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 17,
                        "start_line": 364
                    }
                },
                "484": {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_eq"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 364,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "start_col": 5,
                        "start_line": 364
                    }
                },
                "485": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 89,
                        "end_line": 18,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 35,
                                "end_line": 21,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 21,
                                        "end_line": 21,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 21
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 20,
                                "start_line": 21
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 74,
                        "start_line": 18
                    }
                },
                "486": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 19,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 21,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 19,
                                "start_line": 21
                            },
                            "While expanding the reference 'a' in:"
                        ],
                        "start_col": 5,
                        "start_line": 19
                    }
                },
                "487": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 19,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 21,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 19,
                                "start_line": 21
                            },
                            "While expanding the reference 'a' in:"
                        ],
                        "start_col": 5,
                        "start_line": 19
                    }
                },
                "488": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 21,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 21
                    }
                },
                "490": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 19,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 22,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 19,
                                "start_line": 22
                            },
                            "While expanding the reference 'b' in:"
                        ],
                        "start_col": 17,
                        "start_line": 19
                    }
                },
                "491": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 19,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 22,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 19,
                                "start_line": 22
                            },
                            "While expanding the reference 'b' in:"
                        ],
                        "start_col": 17,
                        "start_line": 19
                    }
                },
                "492": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 22,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 22
                    }
                },
                "494": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 19,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 50,
                                "end_line": 23,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 49,
                                "start_line": 23
                            },
                            "While expanding the reference 'a' in:"
                        ],
                        "start_col": 5,
                        "start_line": 19
                    }
                },
                "495": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 19,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 50,
                                "end_line": 23,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 49,
                                "start_line": 23
                            },
                            "While expanding the reference 'a' in:"
                        ],
                        "start_col": 5,
                        "start_line": 19
                    }
                },
                "496": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 19,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 53,
                                "end_line": 23,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 52,
                                "start_line": 23
                            },
                            "While expanding the reference 'b' in:"
                        ],
                        "start_col": 17,
                        "start_line": 19
                    }
                },
                "497": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 19,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 53,
                                "end_line": 23,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 52,
                                "start_line": 23
                            },
                            "While expanding the reference 'b' in:"
                        ],
                        "start_col": 17,
                        "start_line": 19
                    }
                },
                "498": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 23,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "start_col": 37,
                        "start_line": 23
                    }
                },
                "500": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 24,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 24
                    }
                },
                "502": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 44,
                        "end_line": 18,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 44,
                                "end_line": 18,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 17,
                                        "end_line": 25,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 25
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 26,
                                "start_line": 18
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 26,
                        "start_line": 18
                    }
                },
                "503": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 18,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 72,
                                "end_line": 18,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 17,
                                        "end_line": 25,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 25
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 46,
                                "start_line": 18
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 46,
                        "start_line": 18
                    }
                },
                "504": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 31,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 54,
                                "end_line": 23,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 89,
                                        "end_line": 18,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 17,
                                                "end_line": 25,
                                                "input_file": {
                                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 25
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 74,
                                        "start_line": 18
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 37,
                                "start_line": 23
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 18,
                        "start_line": 31
                    }
                },
                "505": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 23,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 25,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 13,
                                "start_line": 25
                            },
                            "While expanding the reference 'c' in:"
                        ],
                        "start_col": 10,
                        "start_line": 23
                    }
                },
                "506": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 23,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 25,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 13,
                                "start_line": 25
                            },
                            "While expanding the reference 'c' in:"
                        ],
                        "start_col": 10,
                        "start_line": 23
                    }
                },
                "507": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_add"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 17,
                        "end_line": 25,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 25
                    }
                },
                "508": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_sub_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 47,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 47
                    }
                },
                "510": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_sub_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 92,
                        "end_line": 44,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 35,
                                "end_line": 21,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 21,
                                        "end_line": 48,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 48
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 20,
                                "start_line": 21
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 77,
                        "start_line": 44
                    }
                },
                "511": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_sub_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 45,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 48,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 19,
                                "start_line": 48
                            },
                            "While expanding the reference 'a' in:"
                        ],
                        "start_col": 5,
                        "start_line": 45
                    }
                },
                "512": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_sub_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 45,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 48,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 19,
                                "start_line": 48
                            },
                            "While expanding the reference 'a' in:"
                        ],
                        "start_col": 5,
                        "start_line": 45
                    }
                },
                "513": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_sub_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 48,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 48
                    }
                },
                "515": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_sub_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 45,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 49,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 19,
                                "start_line": 49
                            },
                            "While expanding the reference 'b' in:"
                        ],
                        "start_col": 17,
                        "start_line": 45
                    }
                },
                "516": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_sub_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 45,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 49,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 19,
                                "start_line": 49
                            },
                            "While expanding the reference 'b' in:"
                        ],
                        "start_col": 17,
                        "start_line": 45
                    }
                },
                "517": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_sub_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 49,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 49
                    }
                },
                "519": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_sub_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 45,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 31,
                                "end_line": 51,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 30,
                                "start_line": 51
                            },
                            "While expanding the reference 'b' in:"
                        ],
                        "start_col": 17,
                        "start_line": 45
                    }
                },
                "520": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_sub_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 45,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 31,
                                "end_line": 51,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 30,
                                "start_line": 51
                            },
                            "While expanding the reference 'b' in:"
                        ],
                        "start_col": 17,
                        "start_line": 45
                    }
                },
                "521": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_sub_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 45,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 51,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 33,
                                "start_line": 51
                            },
                            "While expanding the reference 'a' in:"
                        ],
                        "start_col": 5,
                        "start_line": 45
                    }
                },
                "522": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_sub_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 45,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 51,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 33,
                                "start_line": 51
                            },
                            "While expanding the reference 'a' in:"
                        ],
                        "start_col": 5,
                        "start_line": 45
                    }
                },
                "523": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_sub_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 51,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "start_col": 19,
                        "start_line": 51
                    }
                },
                "525": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_sub_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 52,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 52
                    }
                },
                "527": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_sub_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 133,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 35,
                                "end_line": 51,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 33,
                                        "end_line": 348,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 41,
                                                "end_line": 53,
                                                "input_file": {
                                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                                },
                                                "start_col": 24,
                                                "start_line": 53
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 18,
                                        "start_line": 348
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 19,
                                "start_line": 51
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 133
                    }
                },
                "528": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_sub_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 45,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 37,
                                "end_line": 53,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 36,
                                "start_line": 53
                            },
                            "While expanding the reference 'a' in:"
                        ],
                        "start_col": 5,
                        "start_line": 45
                    }
                },
                "529": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_sub_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 45,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 37,
                                "end_line": 53,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 36,
                                "start_line": 53
                            },
                            "While expanding the reference 'a' in:"
                        ],
                        "start_col": 5,
                        "start_line": 45
                    }
                },
                "530": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_sub_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 45,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 40,
                                "end_line": 53,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 39,
                                "start_line": 53
                            },
                            "While expanding the reference 'b' in:"
                        ],
                        "start_col": 17,
                        "start_line": 45
                    }
                },
                "531": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_sub_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 45,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 40,
                                "end_line": 53,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 39,
                                "start_line": 53
                            },
                            "While expanding the reference 'b' in:"
                        ],
                        "start_col": 17,
                        "start_line": 45
                    }
                },
                "532": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_sub_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 53,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "start_col": 24,
                        "start_line": 53
                    }
                },
                "534": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_sub_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 47,
                        "end_line": 44,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 47,
                                "end_line": 44,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 17,
                                        "end_line": 54,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 54
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 29,
                                "start_line": 44
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 29,
                        "start_line": 44
                    }
                },
                "535": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_sub_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 75,
                        "end_line": 44,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 75,
                                "end_line": 44,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 17,
                                        "end_line": 54,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 54
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 49,
                                "start_line": 44
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 49,
                        "start_line": 44
                    }
                },
                "536": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_sub_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 348,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 41,
                                "end_line": 53,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 92,
                                        "end_line": 44,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 17,
                                                "end_line": 54,
                                                "input_file": {
                                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 54
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 77,
                                        "start_line": 44
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 24,
                                "start_line": 53
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 18,
                        "start_line": 348
                    }
                },
                "537": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_sub_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 53,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 54,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 13,
                                "start_line": 54
                            },
                            "While expanding the reference 'c' in:"
                        ],
                        "start_col": 10,
                        "start_line": 53
                    }
                },
                "538": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_sub_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 53,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 54,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 13,
                                "start_line": 54
                            },
                            "While expanding the reference 'c' in:"
                        ],
                        "start_col": 10,
                        "start_line": 53
                    }
                },
                "539": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_sub_lt"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 17,
                        "end_line": 54,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 54
                    }
                },
                "540": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 62,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 62
                    }
                },
                "542": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 89,
                        "end_line": 59,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 35,
                                "end_line": 21,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 21,
                                        "end_line": 63,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 63
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 20,
                                "start_line": 21
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 74,
                        "start_line": 59
                    }
                },
                "543": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 60,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 63,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 19,
                                "start_line": 63
                            },
                            "While expanding the reference 'a' in:"
                        ],
                        "start_col": 5,
                        "start_line": 60
                    }
                },
                "544": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 60,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 63,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 19,
                                "start_line": 63
                            },
                            "While expanding the reference 'a' in:"
                        ],
                        "start_col": 5,
                        "start_line": 60
                    }
                },
                "545": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 63,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 63
                    }
                },
                "547": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 60,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 64,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 19,
                                "start_line": 64
                            },
                            "While expanding the reference 'b' in:"
                        ],
                        "start_col": 17,
                        "start_line": 60
                    }
                },
                "548": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 60,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 64,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 19,
                                "start_line": 64
                            },
                            "While expanding the reference 'b' in:"
                        ],
                        "start_col": 17,
                        "start_line": 60
                    }
                },
                "549": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 64,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 64
                    }
                },
                "551": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 60,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 56,
                                "end_line": 66,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 55,
                                "start_line": 66
                            },
                            "While expanding the reference 'a' in:"
                        ],
                        "start_col": 5,
                        "start_line": 60
                    }
                },
                "552": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 60,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 56,
                                "end_line": 66,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 55,
                                "start_line": 66
                            },
                            "While expanding the reference 'a' in:"
                        ],
                        "start_col": 5,
                        "start_line": 60
                    }
                },
                "553": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 60,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 59,
                                "end_line": 66,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 58,
                                "start_line": 66
                            },
                            "While expanding the reference 'b' in:"
                        ],
                        "start_col": 17,
                        "start_line": 60
                    }
                },
                "554": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 60,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 59,
                                "end_line": 66,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 58,
                                "start_line": 66
                            },
                            "While expanding the reference 'b' in:"
                        ],
                        "start_col": 17,
                        "start_line": 60
                    }
                },
                "555": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 60,
                        "end_line": 66,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "start_col": 43,
                        "start_line": 66
                    }
                },
                "557": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 66,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 66,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 10,
                                "start_line": 66
                            },
                            "While auto generating local variable for 'c'."
                        ],
                        "start_col": 10,
                        "start_line": 66
                    }
                },
                "558": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 66,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 66,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 10,
                                "start_line": 66
                            },
                            "While auto generating local variable for 'c'."
                        ],
                        "start_col": 10,
                        "start_line": 66
                    }
                },
                "559": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 73,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 60,
                                "end_line": 66,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 32,
                                        "end_line": 357,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 65,
                                                "end_line": 67,
                                                "input_file": {
                                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                                },
                                                "start_col": 30,
                                                "start_line": 67
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 17,
                                        "start_line": 357
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 43,
                                "start_line": 66
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 18,
                        "start_line": 73
                    }
                },
                "560": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 39,
                        "end_line": 66,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 49,
                                "end_line": 67,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 41,
                                "start_line": 67
                            },
                            "While expanding the reference 'mul_high' in:"
                        ],
                        "start_col": 22,
                        "start_line": 66
                    }
                },
                "561": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 39,
                        "end_line": 66,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 49,
                                "end_line": 67,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 41,
                                "start_line": 67
                            },
                            "While expanding the reference 'mul_high' in:"
                        ],
                        "start_col": 22,
                        "start_line": 66
                    }
                },
                "562": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 60,
                        "end_line": 67,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "start_col": 59,
                        "start_line": 67
                    }
                },
                "564": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 63,
                        "end_line": 67,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "start_col": 62,
                        "start_line": 67
                    }
                },
                "566": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 65,
                        "end_line": 67,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "start_col": 30,
                        "start_line": 67
                    }
                },
                "568": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 68,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 68
                    }
                },
                "570": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 44,
                        "end_line": 59,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 44,
                                "end_line": 59,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 17,
                                        "end_line": 69,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 69
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 26,
                                "start_line": 59
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 26,
                        "start_line": 59
                    }
                },
                "571": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 59,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 72,
                                "end_line": 59,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 17,
                                        "end_line": 69,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 69
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 46,
                                "start_line": 59
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 46,
                        "start_line": 59
                    }
                },
                "572": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 357,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 65,
                                "end_line": 67,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 89,
                                        "end_line": 59,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 17,
                                                "end_line": 69,
                                                "input_file": {
                                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 69
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 74,
                                        "start_line": 59
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 30,
                                "start_line": 67
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 357
                    }
                },
                "573": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 66,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 66,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 14,
                                        "end_line": 69,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "start_col": 13,
                                        "start_line": 69
                                    },
                                    "While expanding the reference 'c' in:"
                                ],
                                "start_col": 10,
                                "start_line": 66
                            },
                            "While auto generating local variable for 'c'."
                        ],
                        "start_col": 10,
                        "start_line": 66
                    }
                },
                "574": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 66,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 66,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 14,
                                        "end_line": 69,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "start_col": 13,
                                        "start_line": 69
                                    },
                                    "While expanding the reference 'c' in:"
                                ],
                                "start_col": 10,
                                "start_line": 66
                            },
                            "While auto generating local variable for 'c'."
                        ],
                        "start_col": 10,
                        "start_line": 66
                    }
                },
                "575": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 17,
                        "end_line": 69,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 69
                    }
                },
                "576": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_felt_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 77,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 77
                    }
                },
                "578": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_felt_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 94,
                        "end_line": 74,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 35,
                                "end_line": 21,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 21,
                                        "end_line": 78,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 78
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 20,
                                "start_line": 21
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 79,
                        "start_line": 74
                    }
                },
                "579": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_felt_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 75,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 78,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 19,
                                "start_line": 78
                            },
                            "While expanding the reference 'a' in:"
                        ],
                        "start_col": 5,
                        "start_line": 75
                    }
                },
                "580": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_felt_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 75,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 78,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 19,
                                "start_line": 78
                            },
                            "While expanding the reference 'a' in:"
                        ],
                        "start_col": 5,
                        "start_line": 75
                    }
                },
                "581": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_felt_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 78,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 78
                    }
                },
                "583": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_felt_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 75,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 56,
                                "end_line": 80,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 55,
                                "start_line": 80
                            },
                            "While expanding the reference 'a' in:"
                        ],
                        "start_col": 5,
                        "start_line": 75
                    }
                },
                "584": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_felt_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 75,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 56,
                                "end_line": 80,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 55,
                                "start_line": 80
                            },
                            "While expanding the reference 'a' in:"
                        ],
                        "start_col": 5,
                        "start_line": 75
                    }
                },
                "585": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_felt_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 75,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 67,
                                "end_line": 80,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 66,
                                "start_line": 80
                            },
                            "While expanding the reference 'b' in:"
                        ],
                        "start_col": 17,
                        "start_line": 75
                    }
                },
                "586": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_felt_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 70,
                        "end_line": 80,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "start_col": 69,
                        "start_line": 80
                    }
                },
                "588": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_felt_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 80,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "start_col": 43,
                        "start_line": 80
                    }
                },
                "590": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_felt_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 80,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 80,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 10,
                                "start_line": 80
                            },
                            "While auto generating local variable for 'c'."
                        ],
                        "start_col": 10,
                        "start_line": 80
                    }
                },
                "591": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_felt_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 80,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 80,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 10,
                                "start_line": 80
                            },
                            "While auto generating local variable for 'c'."
                        ],
                        "start_col": 10,
                        "start_line": 80
                    }
                },
                "592": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_felt_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 73,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 72,
                                "end_line": 80,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 32,
                                        "end_line": 357,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 74,
                                                "end_line": 81,
                                                "input_file": {
                                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                                },
                                                "start_col": 39,
                                                "start_line": 81
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 17,
                                        "start_line": 357
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 43,
                                "start_line": 80
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 18,
                        "start_line": 73
                    }
                },
                "593": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_felt_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 39,
                        "end_line": 80,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 58,
                                "end_line": 81,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 50,
                                "start_line": 81
                            },
                            "While expanding the reference 'mul_high' in:"
                        ],
                        "start_col": 22,
                        "start_line": 80
                    }
                },
                "594": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_felt_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 39,
                        "end_line": 80,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 58,
                                "end_line": 81,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "start_col": 50,
                                "start_line": 81
                            },
                            "While expanding the reference 'mul_high' in:"
                        ],
                        "start_col": 22,
                        "start_line": 80
                    }
                },
                "595": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_felt_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 69,
                        "end_line": 81,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "start_col": 68,
                        "start_line": 81
                    }
                },
                "597": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_felt_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 81,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "start_col": 71,
                        "start_line": 81
                    }
                },
                "599": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_felt_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 74,
                        "end_line": 81,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "start_col": 39,
                        "start_line": 81
                    }
                },
                "601": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_felt_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 42,
                        "end_line": 82,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 82
                    }
                },
                "603": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_felt_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 49,
                        "end_line": 74,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 49,
                                "end_line": 74,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 17,
                                        "end_line": 83,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 83
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 31,
                                "start_line": 74
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 31,
                        "start_line": 74
                    }
                },
                "604": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_felt_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 77,
                        "end_line": 74,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 77,
                                "end_line": 74,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 17,
                                        "end_line": 83,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 83
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 51,
                                "start_line": 74
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 51,
                        "start_line": 74
                    }
                },
                "605": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_felt_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 357,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 74,
                                "end_line": 81,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 94,
                                        "end_line": 74,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 17,
                                                "end_line": 83,
                                                "input_file": {
                                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 83
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 79,
                                        "start_line": 74
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 39,
                                "start_line": 81
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 357
                    }
                },
                "606": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_felt_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 80,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 80,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 14,
                                        "end_line": 83,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "start_col": 13,
                                        "start_line": 83
                                    },
                                    "While expanding the reference 'c' in:"
                                ],
                                "start_col": 10,
                                "start_line": 80
                            },
                            "While auto generating local variable for 'c'."
                        ],
                        "start_col": 10,
                        "start_line": 80
                    }
                },
                "607": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_felt_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 80,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 80,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 14,
                                        "end_line": 83,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "start_col": 13,
                                        "start_line": 83
                                    },
                                    "While expanding the reference 'c' in:"
                                ],
                                "start_col": 10,
                                "start_line": 80
                            },
                            "While auto generating local variable for 'c'."
                        ],
                        "start_col": 10,
                        "start_line": 80
                    }
                },
                "608": {
                    "accessible_scopes": [
                        "contracts.utils.math",
                        "contracts.utils.math.uint256_felt_checked_mul"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 17,
                        "end_line": 83,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "start_col": 5,
                        "start_line": 83
                    }
                },
                "609": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.AdminChanged",
                        "openzeppelin.upgrades.library.AdminChanged.emit"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 14,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/event/AdminChanged/8220fde17ca5479f12ae71a8036f4d354fe722f2c036da610b53511924e4ee84.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 20,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "start_col": 6,
                                "start_line": 20
                            },
                            "While handling event:"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "611": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.AdminChanged",
                        "openzeppelin.upgrades.library.AdminChanged.emit"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/event/AdminChanged/8220fde17ca5479f12ae71a8036f4d354fe722f2c036da610b53511924e4ee84.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 20,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "start_col": 6,
                                "start_line": 20
                            },
                            "While handling event:"
                        ],
                        "start_col": 33,
                        "start_line": 2
                    }
                },
                "613": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.AdminChanged",
                        "openzeppelin.upgrades.library.AdminChanged.emit"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 29,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/event/AdminChanged/8220fde17ca5479f12ae71a8036f4d354fe722f2c036da610b53511924e4ee84.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 20,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "start_col": 6,
                                "start_line": 20
                            },
                            "While handling event:"
                        ],
                        "start_col": 6,
                        "start_line": 2
                    }
                },
                "614": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.AdminChanged",
                        "openzeppelin.upgrades.library.AdminChanged.emit"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 31,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/event/AdminChanged/8220fde17ca5479f12ae71a8036f4d354fe722f2c036da610b53511924e4ee84.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 20,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "start_col": 6,
                                "start_line": 20
                            },
                            "While handling event:"
                        ],
                        "start_col": 23,
                        "start_line": 3
                    }
                },
                "616": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.AdminChanged",
                        "openzeppelin.upgrades.library.AdminChanged.emit"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/event/AdminChanged/8220fde17ca5479f12ae71a8036f4d354fe722f2c036da610b53511924e4ee84.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 20,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "start_col": 6,
                                "start_line": 20
                            },
                            "While handling event:"
                        ],
                        "start_col": 1,
                        "start_line": 3
                    }
                },
                "617": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.AdminChanged",
                        "openzeppelin.upgrades.library.AdminChanged.emit"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/event/AdminChanged/8220fde17ca5479f12ae71a8036f4d354fe722f2c036da610b53511924e4ee84.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 20,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "start_col": 6,
                                "start_line": 20
                            },
                            "While handling event:"
                        ],
                        "start_col": 33,
                        "start_line": 4
                    }
                },
                "619": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.AdminChanged",
                        "openzeppelin.upgrades.library.AdminChanged.emit"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 29,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/event/AdminChanged/8220fde17ca5479f12ae71a8036f4d354fe722f2c036da610b53511924e4ee84.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 20,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "start_col": 6,
                                "start_line": 20
                            },
                            "While handling event:"
                        ],
                        "start_col": 6,
                        "start_line": 4
                    }
                },
                "620": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.AdminChanged",
                        "openzeppelin.upgrades.library.AdminChanged.emit"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/635ec603a576c378b96508409ebffc1de2cc10639922f57a6f56f1417ab7afa6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 32,
                                "end_line": 20,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "start_col": 19,
                                "start_line": 20
                            },
                            "While handling calldata argument 'previousAdmin'"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "621": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.AdminChanged",
                        "openzeppelin.upgrades.library.AdminChanged.emit"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 36,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/1b89ef2fbbb6943f8dd42dcbdc1e699db576228e665ff8b5ae3c1c3c39cb1777.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 48,
                                "end_line": 20,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "start_col": 40,
                                "start_line": 20
                            },
                            "While handling calldata argument 'newAdmin'"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "622": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.AdminChanged",
                        "openzeppelin.upgrades.library.AdminChanged.emit"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/1b89ef2fbbb6943f8dd42dcbdc1e699db576228e665ff8b5ae3c1c3c39cb1777.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 48,
                                "end_line": 20,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 64,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/event/AdminChanged/6150feec30bd48bfd0f446ed8c155a6d911a2c3fb3ec7a980733900416819259.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 18,
                                                "end_line": 20,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 20
                                            },
                                            "While handling event:"
                                        ],
                                        "start_col": 50,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_ptr' in:"
                                ],
                                "start_col": 40,
                                "start_line": 20
                            },
                            "While handling calldata argument 'newAdmin'"
                        ],
                        "start_col": 22,
                        "start_line": 2
                    }
                },
                "624": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.AdminChanged",
                        "openzeppelin.upgrades.library.AdminChanged.emit"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 29,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/event/AdminChanged/a7a8ae41be29ac9f4f6c3b7837c448d787ca051dd1ade98f409e54d33d112504.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 20,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 35,
                                        "end_line": 390,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 95,
                                                "end_line": 1,
                                                "input_file": {
                                                    "filename": "autogen/starknet/event/AdminChanged/6150feec30bd48bfd0f446ed8c155a6d911a2c3fb3ec7a980733900416819259.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 18,
                                                        "end_line": 20,
                                                        "input_file": {
                                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                                        },
                                                        "start_col": 6,
                                                        "start_line": 20
                                                    },
                                                    "While handling event:"
                                                ],
                                                "start_col": 1,
                                                "start_line": 1
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 17,
                                        "start_line": 390
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 20
                            },
                            "While handling event:"
                        ],
                        "start_col": 11,
                        "start_line": 1
                    }
                },
                "625": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.AdminChanged",
                        "openzeppelin.upgrades.library.AdminChanged.emit"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/event/AdminChanged/6150feec30bd48bfd0f446ed8c155a6d911a2c3fb3ec7a980733900416819259.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 20,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "start_col": 6,
                                "start_line": 20
                            },
                            "While handling event:"
                        ],
                        "start_col": 21,
                        "start_line": 1
                    }
                },
                "627": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.AdminChanged",
                        "openzeppelin.upgrades.library.AdminChanged.emit"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/event/AdminChanged/8220fde17ca5479f12ae71a8036f4d354fe722f2c036da610b53511924e4ee84.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 20,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 39,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/event/AdminChanged/6150feec30bd48bfd0f446ed8c155a6d911a2c3fb3ec7a980733900416819259.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 18,
                                                "end_line": 20,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 20
                                            },
                                            "While handling event:"
                                        ],
                                        "start_col": 29,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__keys_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 20
                            },
                            "While handling event:"
                        ],
                        "start_col": 12,
                        "start_line": 2
                    }
                },
                "628": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.AdminChanged",
                        "openzeppelin.upgrades.library.AdminChanged.emit"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 77,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/event/AdminChanged/6150feec30bd48bfd0f446ed8c155a6d911a2c3fb3ec7a980733900416819259.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 20,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "start_col": 6,
                                "start_line": 20
                            },
                            "While handling event:"
                        ],
                        "start_col": 50,
                        "start_line": 1
                    }
                },
                "629": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.AdminChanged",
                        "openzeppelin.upgrades.library.AdminChanged.emit"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/event/AdminChanged/8220fde17ca5479f12ae71a8036f4d354fe722f2c036da610b53511924e4ee84.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 20,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 94,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/event/AdminChanged/6150feec30bd48bfd0f446ed8c155a6d911a2c3fb3ec7a980733900416819259.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 18,
                                                "end_line": 20,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 20
                                            },
                                            "While handling event:"
                                        ],
                                        "start_col": 84,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__data_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 20
                            },
                            "While handling event:"
                        ],
                        "start_col": 12,
                        "start_line": 4
                    }
                },
                "630": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.AdminChanged",
                        "openzeppelin.upgrades.library.AdminChanged.emit"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 95,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/event/AdminChanged/6150feec30bd48bfd0f446ed8c155a6d911a2c3fb3ec7a980733900416819259.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 20,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "start_col": 6,
                                "start_line": 20
                            },
                            "While handling event:"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "632": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.AdminChanged",
                        "openzeppelin.upgrades.library.AdminChanged.emit"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 46,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/event/AdminChanged/a7a8ae41be29ac9f4f6c3b7837c448d787ca051dd1ade98f409e54d33d112504.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 20,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 46,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/event/AdminChanged/a7a8ae41be29ac9f4f6c3b7837c448d787ca051dd1ade98f409e54d33d112504.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 18,
                                                "end_line": 20,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 11,
                                                        "end_line": 2,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/event/AdminChanged/6150feec30bd48bfd0f446ed8c155a6d911a2c3fb3ec7a980733900416819259.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 18,
                                                                "end_line": 20,
                                                                "input_file": {
                                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                                                },
                                                                "start_col": 6,
                                                                "start_line": 20
                                                            },
                                                            "While handling event:"
                                                        ],
                                                        "start_col": 1,
                                                        "start_line": 2
                                                    },
                                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                ],
                                                "start_col": 6,
                                                "start_line": 20
                                            },
                                            "While handling event:"
                                        ],
                                        "start_col": 31,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 20
                            },
                            "While handling event:"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "633": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.AdminChanged",
                        "openzeppelin.upgrades.library.AdminChanged.emit"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 11,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/event/AdminChanged/6150feec30bd48bfd0f446ed8c155a6d911a2c3fb3ec7a980733900416819259.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 20,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "start_col": 6,
                                "start_line": 20
                            },
                            "While handling event:"
                        ],
                        "start_col": 1,
                        "start_line": 2
                    }
                },
                "634": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_admin",
                        "openzeppelin.upgrades.library.Proxy_admin.addr"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 7,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 41,
                                "end_line": 7,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_admin/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 26,
                                        "end_line": 9,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                                        },
                                        "start_col": 9,
                                        "start_line": 9
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 15,
                                "start_line": 7
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 7
                    }
                },
                "635": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_admin",
                        "openzeppelin.upgrades.library.Proxy_admin.addr"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 58,
                        "end_line": 7,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 58,
                                "end_line": 7,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_admin/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 26,
                                        "end_line": 9,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                                        },
                                        "start_col": 9,
                                        "start_line": 9
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 43,
                                "start_line": 7
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 43,
                        "start_line": 7
                    }
                },
                "636": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_admin",
                        "openzeppelin.upgrades.library.Proxy_admin.addr"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 94,
                        "end_line": 8,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 9,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                                },
                                "start_col": 21,
                                "start_line": 9
                            },
                            "While expanding the reference 'res' in:"
                        ],
                        "start_col": 19,
                        "start_line": 8
                    }
                },
                "638": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_admin",
                        "openzeppelin.upgrades.library.Proxy_admin.addr"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 26,
                        "end_line": 9,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                        },
                        "start_col": 9,
                        "start_line": 9
                    }
                },
                "639": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_admin",
                        "openzeppelin.upgrades.library.Proxy_admin.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 12,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 41,
                                "end_line": 7,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_admin/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 36,
                                        "end_line": 13,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                                        },
                                        "start_col": 30,
                                        "start_line": 13
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 15,
                                "start_line": 7
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 35,
                        "start_line": 12
                    }
                },
                "640": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_admin",
                        "openzeppelin.upgrades.library.Proxy_admin.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 78,
                        "end_line": 12,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 58,
                                "end_line": 7,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_admin/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 36,
                                        "end_line": 13,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                                        },
                                        "start_col": 30,
                                        "start_line": 13
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 43,
                                "start_line": 7
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 63,
                        "start_line": 12
                    }
                },
                "641": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_admin",
                        "openzeppelin.upgrades.library.Proxy_admin.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 36,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                        },
                        "start_col": 30,
                        "start_line": 13
                    }
                },
                "643": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_admin",
                        "openzeppelin.upgrades.library.Proxy_admin.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 12,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 37,
                                "end_line": 352,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 75,
                                        "end_line": 14,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                                        },
                                        "start_col": 37,
                                        "start_line": 14
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 19,
                                "start_line": 352
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 12
                    }
                },
                "644": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_admin",
                        "openzeppelin.upgrades.library.Proxy_admin.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 26,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 70,
                                "end_line": 14,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                                },
                                "start_col": 58,
                                "start_line": 14
                            },
                            "While expanding the reference 'storage_addr' in:"
                        ],
                        "start_col": 14,
                        "start_line": 13
                    }
                },
                "645": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_admin",
                        "openzeppelin.upgrades.library.Proxy_admin.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 75,
                        "end_line": 14,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                        },
                        "start_col": 37,
                        "start_line": 14
                    }
                },
                "647": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_admin",
                        "openzeppelin.upgrades.library.Proxy_admin.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 352,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 75,
                                "end_line": 14,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 42,
                                        "end_line": 16,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                                        },
                                        "start_col": 31,
                                        "start_line": 16
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 37,
                                "start_line": 14
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 19,
                        "start_line": 352
                    }
                },
                "648": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_admin",
                        "openzeppelin.upgrades.library.Proxy_admin.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 7,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_admin/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 13,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 44,
                                        "end_line": 17,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                                        },
                                        "start_col": 32,
                                        "start_line": 17
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 30,
                                "start_line": 13
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 7
                    }
                },
                "649": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_admin",
                        "openzeppelin.upgrades.library.Proxy_admin.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 58,
                        "end_line": 7,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_admin/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 13,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 50,
                                        "end_line": 18,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                                        },
                                        "start_col": 35,
                                        "start_line": 18
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 30,
                                "start_line": 13
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 43,
                        "start_line": 7
                    }
                },
                "650": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_admin",
                        "openzeppelin.upgrades.library.Proxy_admin.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 14,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 64,
                                "end_line": 19,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                                },
                                "start_col": 45,
                                "start_line": 19
                            },
                            "While expanding the reference '__storage_var_temp0' in:"
                        ],
                        "start_col": 14,
                        "start_line": 14
                    }
                },
                "651": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_admin",
                        "openzeppelin.upgrades.library.Proxy_admin.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 55,
                        "end_line": 20,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                        },
                        "start_col": 9,
                        "start_line": 20
                    }
                },
                "652": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_admin",
                        "openzeppelin.upgrades.library.Proxy_admin.write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 62,
                        "end_line": 23,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 41,
                                "end_line": 7,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_admin/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 36,
                                        "end_line": 24,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                                        },
                                        "start_col": 30,
                                        "start_line": 24
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 15,
                                "start_line": 7
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 36,
                        "start_line": 23
                    }
                },
                "653": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_admin",
                        "openzeppelin.upgrades.library.Proxy_admin.write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 79,
                        "end_line": 23,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 58,
                                "end_line": 7,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_admin/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 36,
                                        "end_line": 24,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                                        },
                                        "start_col": 30,
                                        "start_line": 24
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 43,
                                "start_line": 7
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 64,
                        "start_line": 23
                    }
                },
                "654": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_admin",
                        "openzeppelin.upgrades.library.Proxy_admin.write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 36,
                        "end_line": 24,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                        },
                        "start_col": 30,
                        "start_line": 24
                    }
                },
                "656": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_admin",
                        "openzeppelin.upgrades.library.Proxy_admin.write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 23,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 38,
                                "end_line": 370,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 80,
                                        "end_line": 25,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                                        },
                                        "start_col": 9,
                                        "start_line": 25
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 20,
                                "start_line": 370
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 16,
                        "start_line": 23
                    }
                },
                "657": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_admin",
                        "openzeppelin.upgrades.library.Proxy_admin.write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 26,
                        "end_line": 24,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 25,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                                },
                                "start_col": 31,
                                "start_line": 25
                            },
                            "While expanding the reference 'storage_addr' in:"
                        ],
                        "start_col": 14,
                        "start_line": 24
                    }
                },
                "658": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_admin",
                        "openzeppelin.upgrades.library.Proxy_admin.write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 79,
                        "end_line": 25,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                        },
                        "start_col": 55,
                        "start_line": 25
                    }
                },
                "659": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_admin",
                        "openzeppelin.upgrades.library.Proxy_admin.write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 80,
                        "end_line": 25,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                        },
                        "start_col": 9,
                        "start_line": 25
                    }
                },
                "661": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_admin",
                        "openzeppelin.upgrades.library.Proxy_admin.write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 7,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_admin/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 24,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 62,
                                        "end_line": 19,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_admin/decl.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 26,
                                                "input_file": {
                                                    "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                                                },
                                                "start_col": 9,
                                                "start_line": 26
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 36,
                                        "start_line": 19
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 30,
                                "start_line": 24
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 7
                    }
                },
                "662": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_admin",
                        "openzeppelin.upgrades.library.Proxy_admin.write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 58,
                        "end_line": 7,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_admin/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 24,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 79,
                                        "end_line": 19,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_admin/decl.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 26,
                                                "input_file": {
                                                    "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                                                },
                                                "start_col": 9,
                                                "start_line": 26
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 64,
                                        "start_line": 19
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 30,
                                "start_line": 24
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 43,
                        "start_line": 7
                    }
                },
                "663": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_admin",
                        "openzeppelin.upgrades.library.Proxy_admin.write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 19,
                        "end_line": 26,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_admin/impl.cairo"
                        },
                        "start_col": 9,
                        "start_line": 26
                    }
                },
                "664": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_initialized",
                        "openzeppelin.upgrades.library.Proxy_initialized.addr"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 7,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 41,
                                "end_line": 7,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_initialized/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 26,
                                        "end_line": 9,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                                        },
                                        "start_col": 9,
                                        "start_line": 9
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 15,
                                "start_line": 7
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 7
                    }
                },
                "665": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_initialized",
                        "openzeppelin.upgrades.library.Proxy_initialized.addr"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 58,
                        "end_line": 7,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 58,
                                "end_line": 7,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_initialized/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 26,
                                        "end_line": 9,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                                        },
                                        "start_col": 9,
                                        "start_line": 9
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 43,
                                "start_line": 7
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 43,
                        "start_line": 7
                    }
                },
                "666": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_initialized",
                        "openzeppelin.upgrades.library.Proxy_initialized.addr"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 95,
                        "end_line": 8,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 9,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                                },
                                "start_col": 21,
                                "start_line": 9
                            },
                            "While expanding the reference 'res' in:"
                        ],
                        "start_col": 19,
                        "start_line": 8
                    }
                },
                "668": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_initialized",
                        "openzeppelin.upgrades.library.Proxy_initialized.addr"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 26,
                        "end_line": 9,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                        },
                        "start_col": 9,
                        "start_line": 9
                    }
                },
                "669": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_initialized",
                        "openzeppelin.upgrades.library.Proxy_initialized.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 12,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 41,
                                "end_line": 7,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_initialized/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 36,
                                        "end_line": 15,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                                        },
                                        "start_col": 30,
                                        "start_line": 15
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 15,
                                "start_line": 7
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 35,
                        "start_line": 12
                    }
                },
                "670": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_initialized",
                        "openzeppelin.upgrades.library.Proxy_initialized.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 78,
                        "end_line": 12,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 58,
                                "end_line": 7,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_initialized/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 36,
                                        "end_line": 15,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                                        },
                                        "start_col": 30,
                                        "start_line": 15
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 43,
                                "start_line": 7
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 63,
                        "start_line": 12
                    }
                },
                "671": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_initialized",
                        "openzeppelin.upgrades.library.Proxy_initialized.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 36,
                        "end_line": 15,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                        },
                        "start_col": 30,
                        "start_line": 15
                    }
                },
                "673": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_initialized",
                        "openzeppelin.upgrades.library.Proxy_initialized.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 12,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 37,
                                "end_line": 352,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 75,
                                        "end_line": 16,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                                        },
                                        "start_col": 37,
                                        "start_line": 16
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 19,
                                "start_line": 352
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 12
                    }
                },
                "674": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_initialized",
                        "openzeppelin.upgrades.library.Proxy_initialized.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 26,
                        "end_line": 15,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 70,
                                "end_line": 16,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                                },
                                "start_col": 58,
                                "start_line": 16
                            },
                            "While expanding the reference 'storage_addr' in:"
                        ],
                        "start_col": 14,
                        "start_line": 15
                    }
                },
                "675": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_initialized",
                        "openzeppelin.upgrades.library.Proxy_initialized.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 75,
                        "end_line": 16,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                        },
                        "start_col": 37,
                        "start_line": 16
                    }
                },
                "677": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_initialized",
                        "openzeppelin.upgrades.library.Proxy_initialized.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 352,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 75,
                                "end_line": 16,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 42,
                                        "end_line": 18,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                                        },
                                        "start_col": 31,
                                        "start_line": 18
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 37,
                                "start_line": 16
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 19,
                        "start_line": 352
                    }
                },
                "678": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_initialized",
                        "openzeppelin.upgrades.library.Proxy_initialized.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 7,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_initialized/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 15,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 44,
                                        "end_line": 19,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                                        },
                                        "start_col": 32,
                                        "start_line": 19
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 30,
                                "start_line": 15
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 7
                    }
                },
                "679": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_initialized",
                        "openzeppelin.upgrades.library.Proxy_initialized.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 58,
                        "end_line": 7,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_initialized/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 15,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 50,
                                        "end_line": 20,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                                        },
                                        "start_col": 35,
                                        "start_line": 20
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 30,
                                "start_line": 15
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 43,
                        "start_line": 7
                    }
                },
                "680": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_initialized",
                        "openzeppelin.upgrades.library.Proxy_initialized.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 16,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 64,
                                "end_line": 21,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                                },
                                "start_col": 45,
                                "start_line": 21
                            },
                            "While expanding the reference '__storage_var_temp0' in:"
                        ],
                        "start_col": 14,
                        "start_line": 16
                    }
                },
                "681": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_initialized",
                        "openzeppelin.upgrades.library.Proxy_initialized.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 55,
                        "end_line": 22,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                        },
                        "start_col": 9,
                        "start_line": 22
                    }
                },
                "682": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_initialized",
                        "openzeppelin.upgrades.library.Proxy_initialized.write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 62,
                        "end_line": 25,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 41,
                                "end_line": 7,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_initialized/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 36,
                                        "end_line": 26,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                                        },
                                        "start_col": 30,
                                        "start_line": 26
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 15,
                                "start_line": 7
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 36,
                        "start_line": 25
                    }
                },
                "683": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_initialized",
                        "openzeppelin.upgrades.library.Proxy_initialized.write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 79,
                        "end_line": 25,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 58,
                                "end_line": 7,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_initialized/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 36,
                                        "end_line": 26,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                                        },
                                        "start_col": 30,
                                        "start_line": 26
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 43,
                                "start_line": 7
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 64,
                        "start_line": 25
                    }
                },
                "684": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_initialized",
                        "openzeppelin.upgrades.library.Proxy_initialized.write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 36,
                        "end_line": 26,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                        },
                        "start_col": 30,
                        "start_line": 26
                    }
                },
                "686": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_initialized",
                        "openzeppelin.upgrades.library.Proxy_initialized.write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 25,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 38,
                                "end_line": 370,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 80,
                                        "end_line": 27,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                                        },
                                        "start_col": 9,
                                        "start_line": 27
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 20,
                                "start_line": 370
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 16,
                        "start_line": 25
                    }
                },
                "687": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_initialized",
                        "openzeppelin.upgrades.library.Proxy_initialized.write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 26,
                        "end_line": 26,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 27,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                                },
                                "start_col": 31,
                                "start_line": 27
                            },
                            "While expanding the reference 'storage_addr' in:"
                        ],
                        "start_col": 14,
                        "start_line": 26
                    }
                },
                "688": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_initialized",
                        "openzeppelin.upgrades.library.Proxy_initialized.write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 79,
                        "end_line": 27,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                        },
                        "start_col": 55,
                        "start_line": 27
                    }
                },
                "689": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_initialized",
                        "openzeppelin.upgrades.library.Proxy_initialized.write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 80,
                        "end_line": 27,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                        },
                        "start_col": 9,
                        "start_line": 27
                    }
                },
                "691": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_initialized",
                        "openzeppelin.upgrades.library.Proxy_initialized.write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 7,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_initialized/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 26,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 62,
                                        "end_line": 21,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_initialized/decl.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 28,
                                                "input_file": {
                                                    "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                                                },
                                                "start_col": 9,
                                                "start_line": 28
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 36,
                                        "start_line": 21
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 30,
                                "start_line": 26
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 7
                    }
                },
                "692": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_initialized",
                        "openzeppelin.upgrades.library.Proxy_initialized.write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 58,
                        "end_line": 7,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_initialized/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 26,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 79,
                                        "end_line": 21,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_initialized/decl.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 28,
                                                "input_file": {
                                                    "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                                                },
                                                "start_col": 9,
                                                "start_line": 28
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 64,
                                        "start_line": 21
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 30,
                                "start_line": 26
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 43,
                        "start_line": 7
                    }
                },
                "693": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy_initialized",
                        "openzeppelin.upgrades.library.Proxy_initialized.write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 19,
                        "end_line": 28,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_initialized/impl.cairo"
                        },
                        "start_col": 9,
                        "start_line": 28
                    }
                },
                "694": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 44,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 33,
                                "end_line": 13,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_initialized/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 53,
                                        "end_line": 47,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                        },
                                        "start_col": 29,
                                        "start_line": 47
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 15,
                                "start_line": 13
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 22,
                        "start_line": 44
                    }
                },
                "695": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 68,
                        "end_line": 44,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 61,
                                "end_line": 13,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_initialized/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 53,
                                        "end_line": 47,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                        },
                                        "start_col": 29,
                                        "start_line": 47
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 35,
                                "start_line": 13
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 42,
                        "start_line": 44
                    }
                },
                "696": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 85,
                        "end_line": 44,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 78,
                                "end_line": 13,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_initialized/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 53,
                                        "end_line": 47,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                        },
                                        "start_col": 29,
                                        "start_line": 47
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 63,
                                "start_line": 13
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 70,
                        "start_line": 44
                    }
                },
                "697": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 53,
                        "end_line": 47,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                        },
                        "start_col": 29,
                        "start_line": 47
                    }
                },
                "699": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 49,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                        },
                        "start_col": 13,
                        "start_line": 49
                    }
                },
                "701": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_initialized/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 53,
                                "end_line": 47,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 34,
                                        "end_line": 21,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_initialized/decl.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 38,
                                                "end_line": 52,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                                },
                                                "start_col": 9,
                                                "start_line": 52
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 16,
                                        "start_line": 21
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 29,
                                "start_line": 47
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 13
                    }
                },
                "702": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_initialized/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 53,
                                "end_line": 47,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 62,
                                        "end_line": 21,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_initialized/decl.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 38,
                                                "end_line": 52,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                                },
                                                "start_col": 9,
                                                "start_line": 52
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 36,
                                        "start_line": 21
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 29,
                                "start_line": 47
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 35,
                        "start_line": 13
                    }
                },
                "703": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 78,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_initialized/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 53,
                                "end_line": 47,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 79,
                                        "end_line": 21,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_initialized/decl.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 38,
                                                "end_line": 52,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                                },
                                                "start_col": 9,
                                                "start_line": 52
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 64,
                                        "start_line": 21
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 29,
                                "start_line": 47
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 63,
                        "start_line": 13
                    }
                },
                "704": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 52,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                        },
                        "start_col": 33,
                        "start_line": 52
                    }
                },
                "706": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 52,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                        },
                        "start_col": 9,
                        "start_line": 52
                    }
                },
                "708": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 26,
                        "end_line": 45,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 31,
                                "end_line": 53,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "start_col": 20,
                                "start_line": 53
                            },
                            "While expanding the reference 'proxy_admin' in:"
                        ],
                        "start_col": 9,
                        "start_line": 45
                    }
                },
                "709": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 53,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                        },
                        "start_col": 9,
                        "start_line": 53
                    }
                },
                "711": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 19,
                        "end_line": 54,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                        },
                        "start_col": 9,
                        "start_line": 54
                    }
                },
                "712": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy.get_admin"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 79,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 33,
                                "end_line": 13,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_admin/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 34,
                                        "end_line": 82,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                        },
                                        "start_col": 16,
                                        "start_line": 82
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 15,
                                "start_line": 13
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 20,
                        "start_line": 79
                    }
                },
                "713": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy.get_admin"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 66,
                        "end_line": 79,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 61,
                                "end_line": 13,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_admin/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 34,
                                        "end_line": 82,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                        },
                                        "start_col": 16,
                                        "start_line": 82
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 35,
                                "start_line": 13
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 40,
                        "start_line": 79
                    }
                },
                "714": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy.get_admin"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 83,
                        "end_line": 79,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 78,
                                "end_line": 13,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/Proxy_admin/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 34,
                                        "end_line": 82,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                        },
                                        "start_col": 16,
                                        "start_line": 82
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 63,
                                "start_line": 13
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 68,
                        "start_line": 79
                    }
                },
                "715": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy.get_admin"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 82,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                        },
                        "start_col": 16,
                        "start_line": 82
                    }
                },
                "717": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy.get_admin"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 82,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                        },
                        "start_col": 9,
                        "start_line": 82
                    }
                },
                "718": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy._set_admin"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 39,
                        "end_line": 89,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 38,
                                "end_line": 79,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 43,
                                        "end_line": 92,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                        },
                                        "start_col": 32,
                                        "start_line": 92
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 20,
                                "start_line": 79
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 21,
                        "start_line": 89
                    }
                },
                "719": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy._set_admin"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 89,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 66,
                                "end_line": 79,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 43,
                                        "end_line": 92,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                        },
                                        "start_col": 32,
                                        "start_line": 92
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 40,
                                "start_line": 79
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 41,
                        "start_line": 89
                    }
                },
                "720": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy._set_admin"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 84,
                        "end_line": 89,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 83,
                                "end_line": 79,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 43,
                                        "end_line": 92,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                        },
                                        "start_col": 32,
                                        "start_line": 92
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 68,
                                "start_line": 79
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 69,
                        "start_line": 89
                    }
                },
                "721": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy._set_admin"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 43,
                        "end_line": 92,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                        },
                        "start_col": 32,
                        "start_line": 92
                    }
                },
                "723": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy._set_admin"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 79,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 92,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 34,
                                        "end_line": 19,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_admin/decl.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 37,
                                                "end_line": 93,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                                },
                                                "start_col": 9,
                                                "start_line": 93
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 16,
                                        "start_line": 19
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 32,
                                "start_line": 92
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 20,
                        "start_line": 79
                    }
                },
                "724": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy._set_admin"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 66,
                        "end_line": 79,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 92,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 62,
                                        "end_line": 19,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_admin/decl.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 37,
                                                "end_line": 93,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                                },
                                                "start_col": 9,
                                                "start_line": 93
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 36,
                                        "start_line": 19
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 32,
                                "start_line": 92
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 40,
                        "start_line": 79
                    }
                },
                "725": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy._set_admin"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 83,
                        "end_line": 79,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 92,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 79,
                                        "end_line": 19,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/Proxy_admin/decl.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 37,
                                                "end_line": 93,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                                },
                                                "start_col": 9,
                                                "start_line": 93
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 64,
                                        "start_line": 19
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 32,
                                "start_line": 92
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 68,
                        "start_line": 79
                    }
                },
                "726": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy._set_admin"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 90,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 93,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "start_col": 27,
                                "start_line": 93
                            },
                            "While expanding the reference 'new_admin' in:"
                        ],
                        "start_col": 9,
                        "start_line": 90
                    }
                },
                "727": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy._set_admin"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 93,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                        },
                        "start_col": 9,
                        "start_line": 93
                    }
                },
                "729": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy._set_admin"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 19,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_admin/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 37,
                                "end_line": 93,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 29,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/event/AdminChanged/a7a8ae41be29ac9f4f6c3b7837c448d787ca051dd1ade98f409e54d33d112504.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 18,
                                                "end_line": 20,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 53,
                                                        "end_line": 94,
                                                        "input_file": {
                                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                                        },
                                                        "start_col": 9,
                                                        "start_line": 94
                                                    },
                                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                ],
                                                "start_col": 6,
                                                "start_line": 20
                                            },
                                            "While handling event:"
                                        ],
                                        "start_col": 11,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 9,
                                "start_line": 93
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 16,
                        "start_line": 19
                    }
                },
                "730": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy._set_admin"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 79,
                        "end_line": 19,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_admin/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 37,
                                "end_line": 93,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 46,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/event/AdminChanged/a7a8ae41be29ac9f4f6c3b7837c448d787ca051dd1ade98f409e54d33d112504.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 18,
                                                "end_line": 20,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 53,
                                                        "end_line": 94,
                                                        "input_file": {
                                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                                        },
                                                        "start_col": 9,
                                                        "start_line": 94
                                                    },
                                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                ],
                                                "start_col": 6,
                                                "start_line": 20
                                            },
                                            "While handling event:"
                                        ],
                                        "start_col": 31,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 9,
                                "start_line": 93
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 64,
                        "start_line": 19
                    }
                },
                "731": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy._set_admin"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 92,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 41,
                                "end_line": 94,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "start_col": 27,
                                "start_line": 94
                            },
                            "While expanding the reference 'previous_admin' in:"
                        ],
                        "start_col": 14,
                        "start_line": 92
                    }
                },
                "732": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy._set_admin"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 90,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 52,
                                "end_line": 94,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "start_col": 43,
                                "start_line": 94
                            },
                            "While expanding the reference 'new_admin' in:"
                        ],
                        "start_col": 9,
                        "start_line": 90
                    }
                },
                "733": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy._set_admin"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 53,
                        "end_line": 94,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                        },
                        "start_col": 9,
                        "start_line": 94
                    }
                },
                "735": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy._set_admin"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 29,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/event/AdminChanged/a7a8ae41be29ac9f4f6c3b7837c448d787ca051dd1ade98f409e54d33d112504.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 20,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 53,
                                        "end_line": 94,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 39,
                                                "end_line": 89,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 19,
                                                        "end_line": 95,
                                                        "input_file": {
                                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                                        },
                                                        "start_col": 9,
                                                        "start_line": 95
                                                    },
                                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                ],
                                                "start_col": 21,
                                                "start_line": 89
                                            },
                                            "While expanding the reference 'syscall_ptr' in:"
                                        ],
                                        "start_col": 9,
                                        "start_line": 94
                                    },
                                    "While trying to update the implicit return value 'syscall_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 20
                            },
                            "While handling event:"
                        ],
                        "start_col": 11,
                        "start_line": 1
                    }
                },
                "736": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy._set_admin"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 62,
                        "end_line": 19,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/Proxy_admin/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 37,
                                "end_line": 93,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 67,
                                        "end_line": 89,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 95,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                                },
                                                "start_col": 9,
                                                "start_line": 95
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 41,
                                        "start_line": 89
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 9,
                                "start_line": 93
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 36,
                        "start_line": 19
                    }
                },
                "737": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy._set_admin"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 46,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/event/AdminChanged/a7a8ae41be29ac9f4f6c3b7837c448d787ca051dd1ade98f409e54d33d112504.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 20,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 53,
                                        "end_line": 94,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 84,
                                                "end_line": 89,
                                                "input_file": {
                                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 19,
                                                        "end_line": 95,
                                                        "input_file": {
                                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                                                        },
                                                        "start_col": 9,
                                                        "start_line": 95
                                                    },
                                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                ],
                                                "start_col": 69,
                                                "start_line": 89
                                            },
                                            "While expanding the reference 'range_check_ptr' in:"
                                        ],
                                        "start_col": 9,
                                        "start_line": 94
                                    },
                                    "While trying to update the implicit return value 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 20
                            },
                            "While handling event:"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "738": {
                    "accessible_scopes": [
                        "openzeppelin.upgrades.library",
                        "openzeppelin.upgrades.library.Proxy",
                        "openzeppelin.upgrades.library.Proxy._set_admin"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 19,
                        "end_line": 95,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/openzeppelin/upgrades/library.cairo"
                        },
                        "start_col": 9,
                        "start_line": 95
                    }
                },
                "739": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IERC20",
                        "__main__.IERC20.transferFrom"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 14,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 34,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 34
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 1,
                        "start_line": 2
                    }
                },
                "741": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IERC20",
                        "__main__.IERC20.transferFrom"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 34,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 34
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 41,
                        "start_line": 3
                    }
                },
                "743": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IERC20",
                        "__main__.IERC20.transferFrom"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 34,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 34
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 6,
                        "start_line": 3
                    }
                },
                "744": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IERC20",
                        "__main__.IERC20.transferFrom"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/712c2a95dbae039e77112e72178da3f83d21edfc1285b387ccfa43e0f878d990.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 29,
                                "end_line": 34,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 23,
                                "start_line": 34
                            },
                            "While handling calldata argument 'sender'"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "745": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IERC20",
                        "__main__.IERC20.transferFrom"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/32dd5497db578c846b634f25154d0ffb622256766f5d2360a53f46e9c348ed3e.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 46,
                                "end_line": 34,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 37,
                                "start_line": 34
                            },
                            "While handling calldata argument 'recipient'"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "746": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IERC20",
                        "__main__.IERC20.transferFrom"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 52,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/502707e41b9f24cc24d98a88f76cd4304e8e77c4465a6a9402a5f1a76177b453.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 60,
                                "end_line": 34,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 54,
                                "start_line": 34
                            },
                            "While handling calldata argument 'amount'"
                        ],
                        "start_col": 1,
                        "start_line": 3
                    }
                },
                "747": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IERC20",
                        "__main__.IERC20.transferFrom"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 52,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/502707e41b9f24cc24d98a88f76cd4304e8e77c4465a6a9402a5f1a76177b453.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 60,
                                "end_line": 34,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 54,
                                "start_line": 34
                            },
                            "While handling calldata argument 'amount'"
                        ],
                        "start_col": 1,
                        "start_line": 4
                    }
                },
                "748": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IERC20",
                        "__main__.IERC20.transferFrom"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/502707e41b9f24cc24d98a88f76cd4304e8e77c4465a6a9402a5f1a76177b453.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 60,
                                "end_line": 34,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 33,
                                        "end_line": 5,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/b016d077f46f58d4cffabd707b4d3495a859d0f543f418223b1ea57e409144f2.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 34,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 34
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 19,
                                        "start_line": 5
                                    },
                                    "While expanding the reference '__calldata_ptr' in:"
                                ],
                                "start_col": 54,
                                "start_line": 34
                            },
                            "While handling calldata argument 'amount'"
                        ],
                        "start_col": 22,
                        "start_line": 5
                    }
                },
                "750": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IERC20",
                        "__main__.IERC20.transferFrom"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/f8c7980cd46ed6d764c999318b5692736b724dc08c1bf96e92d1b77ddf37af10.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 34,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 38,
                                        "end_line": 42,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 33,
                                                "end_line": 6,
                                                "input_file": {
                                                    "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/b016d077f46f58d4cffabd707b4d3495a859d0f543f418223b1ea57e409144f2.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 22,
                                                        "end_line": 34,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 10,
                                                        "start_line": 34
                                                    },
                                                    "While handling contract interface function:"
                                                ],
                                                "start_col": 31,
                                                "start_line": 2
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 20,
                                        "start_line": 42
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 34
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 19,
                        "start_line": 1
                    }
                },
                "751": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IERC20",
                        "__main__.IERC20.transferFrom"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/f8c7980cd46ed6d764c999318b5692736b724dc08c1bf96e92d1b77ddf37af10.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 34,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 38,
                                        "end_line": 3,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/b016d077f46f58d4cffabd707b4d3495a859d0f543f418223b1ea57e409144f2.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 34,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 34
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 22,
                                        "start_line": 3
                                    },
                                    "While expanding the reference 'contract_address' in:"
                                ],
                                "start_col": 10,
                                "start_line": 34
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 5,
                        "start_line": 2
                    }
                },
                "752": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IERC20",
                        "__main__.IERC20.transferFrom"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 44,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/b016d077f46f58d4cffabd707b4d3495a859d0f543f418223b1ea57e409144f2.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 34,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 34
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 23,
                        "start_line": 4
                    }
                },
                "754": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IERC20",
                        "__main__.IERC20.transferFrom"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/b016d077f46f58d4cffabd707b4d3495a859d0f543f418223b1ea57e409144f2.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 34,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 34
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 19,
                        "start_line": 5
                    }
                },
                "755": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IERC20",
                        "__main__.IERC20.transferFrom"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 34,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 32,
                                        "end_line": 6,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/b016d077f46f58d4cffabd707b4d3495a859d0f543f418223b1ea57e409144f2.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 34,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 34
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 14,
                                        "start_line": 6
                                    },
                                    "While expanding the reference 'calldata_ptr_start' in:"
                                ],
                                "start_col": 10,
                                "start_line": 34
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 12,
                        "start_line": 3
                    }
                },
                "756": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IERC20",
                        "__main__.IERC20.transferFrom"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 6,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/b016d077f46f58d4cffabd707b4d3495a859d0f543f418223b1ea57e409144f2.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 34,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 34
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 31,
                        "start_line": 2
                    }
                },
                "758": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IERC20",
                        "__main__.IERC20.transferFrom"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8ca78f1082aaedcf62c13aff4dffe5943aa04164c60cb6d9f03916c0319201df.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 88,
                                "end_line": 34,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 53,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/accb388bcbbace5b6218198851efaad2a781c1339e305a37fab51afe90a3194a.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 34,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 49,
                                                        "end_line": 1,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/arg_processor/96447016176d161dae83f414c4d3e33e78856e0ef69bc33f8d419f1c9b90f9c5.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 22,
                                                                "end_line": 34,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 10,
                                                                "start_line": 34
                                                            },
                                                            "While handling return values of"
                                                        ],
                                                        "start_col": 23,
                                                        "start_line": 1
                                                    },
                                                    "While expanding the reference '__return_value_actual_size' in:"
                                                ],
                                                "start_col": 10,
                                                "start_line": 34
                                            },
                                            "While handling return values of"
                                        ],
                                        "start_col": 35,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__return_value_ptr' in:"
                                ],
                                "start_col": 75,
                                "start_line": 34
                            },
                            "While handling return value 'success'"
                        ],
                        "start_col": 26,
                        "start_line": 2
                    }
                },
                "760": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IERC20",
                        "__main__.IERC20.transferFrom"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 50,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/96447016176d161dae83f414c4d3e33e78856e0ef69bc33f8d419f1c9b90f9c5.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 34,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 34
                            },
                            "While handling return values of"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "761": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IERC20",
                        "__main__.IERC20.transferFrom"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 42,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 33,
                                "end_line": 6,
                                "input_file": {
                                    "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/b016d077f46f58d4cffabd707b4d3495a859d0f543f418223b1ea57e409144f2.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 22,
                                        "end_line": 34,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 37,
                                                "end_line": 1,
                                                "input_file": {
                                                    "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/f8c7980cd46ed6d764c999318b5692736b724dc08c1bf96e92d1b77ddf37af10.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 22,
                                                        "end_line": 34,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 46,
                                                                "end_line": 2,
                                                                "input_file": {
                                                                    "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/18abb29d0a089e1c243e4bd2b316687e6162b3321c14733ee37389da5d8b074f.cairo"
                                                                },
                                                                "parent_location": [
                                                                    {
                                                                        "end_col": 22,
                                                                        "end_line": 34,
                                                                        "input_file": {
                                                                            "filename": "contracts/Router.cairo"
                                                                        },
                                                                        "start_col": 10,
                                                                        "start_line": 34
                                                                    },
                                                                    "While handling contract interface function:"
                                                                ],
                                                                "start_col": 1,
                                                                "start_line": 2
                                                            },
                                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                        ],
                                                        "start_col": 10,
                                                        "start_line": 34
                                                    },
                                                    "While handling contract interface function:"
                                                ],
                                                "start_col": 19,
                                                "start_line": 1
                                            },
                                            "While expanding the reference 'syscall_ptr' in:"
                                        ],
                                        "start_col": 10,
                                        "start_line": 34
                                    },
                                    "While handling contract interface function:"
                                ],
                                "start_col": 31,
                                "start_line": 2
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 20,
                        "start_line": 42
                    }
                },
                "762": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IERC20",
                        "__main__.IERC20.transferFrom"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/f8c7980cd46ed6d764c999318b5692736b724dc08c1bf96e92d1b77ddf37af10.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 34,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 54,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/f8c7980cd46ed6d764c999318b5692736b724dc08c1bf96e92d1b77ddf37af10.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 34,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 46,
                                                        "end_line": 2,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/18abb29d0a089e1c243e4bd2b316687e6162b3321c14733ee37389da5d8b074f.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 22,
                                                                "end_line": 34,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 10,
                                                                "start_line": 34
                                                            },
                                                            "While handling contract interface function:"
                                                        ],
                                                        "start_col": 1,
                                                        "start_line": 2
                                                    },
                                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                ],
                                                "start_col": 10,
                                                "start_line": 34
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 39,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 34
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 39,
                        "start_line": 1
                    }
                },
                "763": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IERC20",
                        "__main__.IERC20.transferFrom"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8ca78f1082aaedcf62c13aff4dffe5943aa04164c60cb6d9f03916c0319201df.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 88,
                                "end_line": 34,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 43,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/18abb29d0a089e1c243e4bd2b316687e6162b3321c14733ee37389da5d8b074f.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 34,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 34
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 17,
                                        "start_line": 2
                                    },
                                    "While expanding the reference '__return_value_arg_success' in:"
                                ],
                                "start_col": 75,
                                "start_line": 34
                            },
                            "While handling return value 'success'"
                        ],
                        "start_col": 34,
                        "start_line": 1
                    }
                },
                "764": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IERC20",
                        "__main__.IERC20.transferFrom"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 46,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/18abb29d0a089e1c243e4bd2b316687e6162b3321c14733ee37389da5d8b074f.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 34,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 34
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 1,
                        "start_line": 2
                    }
                },
                "765": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 14,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/get_reserves/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 40,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 40
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 1,
                        "start_line": 2
                    }
                },
                "767": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/get_reserves/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 40,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 40
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 41,
                        "start_line": 3
                    }
                },
                "769": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/get_reserves/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 40,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 40
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 6,
                        "start_line": 3
                    }
                },
                "770": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/get_reserves/2e31409c9dc0642dd5cf35f3ef58c7f3f9235f4f67d2369960bb668d510e019a.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 40,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 38,
                                        "end_line": 42,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 33,
                                                "end_line": 6,
                                                "input_file": {
                                                    "filename": "autogen/starknet/contract_interface/IPair/get_reserves/a9898d577a164da2fc5dd0b43022c9d1f1cc5882b74dc901eb9d81b08cdb34ef.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 22,
                                                        "end_line": 40,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 10,
                                                        "start_line": 40
                                                    },
                                                    "While handling contract interface function:"
                                                ],
                                                "start_col": 31,
                                                "start_line": 2
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 20,
                                        "start_line": 42
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 40
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 19,
                        "start_line": 1
                    }
                },
                "771": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/get_reserves/2e31409c9dc0642dd5cf35f3ef58c7f3f9235f4f67d2369960bb668d510e019a.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 40,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 38,
                                        "end_line": 3,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IPair/get_reserves/a9898d577a164da2fc5dd0b43022c9d1f1cc5882b74dc901eb9d81b08cdb34ef.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 40,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 40
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 22,
                                        "start_line": 3
                                    },
                                    "While expanding the reference 'contract_address' in:"
                                ],
                                "start_col": 10,
                                "start_line": 40
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 5,
                        "start_line": 2
                    }
                },
                "772": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 44,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/get_reserves/a9898d577a164da2fc5dd0b43022c9d1f1cc5882b74dc901eb9d81b08cdb34ef.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 40,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 40
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 23,
                        "start_line": 4
                    }
                },
                "774": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/get_reserves/a9898d577a164da2fc5dd0b43022c9d1f1cc5882b74dc901eb9d81b08cdb34ef.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 40,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 40
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 19,
                        "start_line": 5
                    }
                },
                "775": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/get_reserves/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 40,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 32,
                                        "end_line": 6,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IPair/get_reserves/a9898d577a164da2fc5dd0b43022c9d1f1cc5882b74dc901eb9d81b08cdb34ef.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 40,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 40
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 14,
                                        "start_line": 6
                                    },
                                    "While expanding the reference 'calldata_ptr_start' in:"
                                ],
                                "start_col": 10,
                                "start_line": 40
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 12,
                        "start_line": 3
                    }
                },
                "776": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 6,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/get_reserves/a9898d577a164da2fc5dd0b43022c9d1f1cc5882b74dc901eb9d81b08cdb34ef.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 40,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 40
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 31,
                        "start_line": 2
                    }
                },
                "778": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8bc8cfbc6cba45ab45c5ec8589791e63546611f512de3c2af08c408ce66a1567.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 93,
                                "end_line": 40,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 53,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/accb388bcbbace5b6218198851efaad2a781c1339e305a37fab51afe90a3194a.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 40,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 49,
                                                        "end_line": 1,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/arg_processor/96447016176d161dae83f414c4d3e33e78856e0ef69bc33f8d419f1c9b90f9c5.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 22,
                                                                "end_line": 40,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 10,
                                                                "start_line": 40
                                                            },
                                                            "While handling return values of"
                                                        ],
                                                        "start_col": 23,
                                                        "start_line": 1
                                                    },
                                                    "While expanding the reference '__return_value_actual_size' in:"
                                                ],
                                                "start_col": 10,
                                                "start_line": 40
                                            },
                                            "While handling return values of"
                                        ],
                                        "start_col": 35,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__return_value_ptr' in:"
                                ],
                                "start_col": 67,
                                "start_line": 40
                            },
                            "While handling return value 'block_timestamp_last'"
                        ],
                        "start_col": 26,
                        "start_line": 2
                    }
                },
                "780": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 50,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/96447016176d161dae83f414c4d3e33e78856e0ef69bc33f8d419f1c9b90f9c5.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 40,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 40
                            },
                            "While handling return values of"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "781": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 42,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 33,
                                "end_line": 6,
                                "input_file": {
                                    "filename": "autogen/starknet/contract_interface/IPair/get_reserves/a9898d577a164da2fc5dd0b43022c9d1f1cc5882b74dc901eb9d81b08cdb34ef.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 22,
                                        "end_line": 40,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 37,
                                                "end_line": 1,
                                                "input_file": {
                                                    "filename": "autogen/starknet/contract_interface/IPair/get_reserves/2e31409c9dc0642dd5cf35f3ef58c7f3f9235f4f67d2369960bb668d510e019a.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 22,
                                                        "end_line": 40,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 148,
                                                                "end_line": 2,
                                                                "input_file": {
                                                                    "filename": "autogen/starknet/contract_interface/IPair/get_reserves/ea038c1bf6d61b95181665c6d7a77d3c8844bc0516ba2d46de4161456f7280f7.cairo"
                                                                },
                                                                "parent_location": [
                                                                    {
                                                                        "end_col": 22,
                                                                        "end_line": 40,
                                                                        "input_file": {
                                                                            "filename": "contracts/Router.cairo"
                                                                        },
                                                                        "start_col": 10,
                                                                        "start_line": 40
                                                                    },
                                                                    "While handling contract interface function:"
                                                                ],
                                                                "start_col": 1,
                                                                "start_line": 2
                                                            },
                                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                        ],
                                                        "start_col": 10,
                                                        "start_line": 40
                                                    },
                                                    "While handling contract interface function:"
                                                ],
                                                "start_col": 19,
                                                "start_line": 1
                                            },
                                            "While expanding the reference 'syscall_ptr' in:"
                                        ],
                                        "start_col": 10,
                                        "start_line": 40
                                    },
                                    "While handling contract interface function:"
                                ],
                                "start_col": 31,
                                "start_line": 2
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 20,
                        "start_line": 42
                    }
                },
                "782": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/get_reserves/2e31409c9dc0642dd5cf35f3ef58c7f3f9235f4f67d2369960bb668d510e019a.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 40,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 54,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IPair/get_reserves/2e31409c9dc0642dd5cf35f3ef58c7f3f9235f4f67d2369960bb668d510e019a.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 40,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 148,
                                                        "end_line": 2,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/contract_interface/IPair/get_reserves/ea038c1bf6d61b95181665c6d7a77d3c8844bc0516ba2d46de4161456f7280f7.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 22,
                                                                "end_line": 40,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 10,
                                                                "start_line": 40
                                                            },
                                                            "While handling contract interface function:"
                                                        ],
                                                        "start_col": 1,
                                                        "start_line": 2
                                                    },
                                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                ],
                                                "start_col": 10,
                                                "start_line": 40
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 39,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 40
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 39,
                        "start_line": 1
                    }
                },
                "783": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 71,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/0bbb4db1f683034b90d6f17e60bfa5cd5ee15bdb643a1d1dddc7c2f180240954.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 46,
                                "end_line": 40,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 45,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IPair/get_reserves/ea038c1bf6d61b95181665c6d7a77d3c8844bc0516ba2d46de4161456f7280f7.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 40,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 40
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 18,
                                        "start_line": 2
                                    },
                                    "While expanding the reference '__return_value_arg_reserve0' in:"
                                ],
                                "start_col": 29,
                                "start_line": 40
                            },
                            "While handling return value 'reserve0'"
                        ],
                        "start_col": 35,
                        "start_line": 1
                    }
                },
                "784": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 71,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/0bbb4db1f683034b90d6f17e60bfa5cd5ee15bdb643a1d1dddc7c2f180240954.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 46,
                                "end_line": 40,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 45,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IPair/get_reserves/ea038c1bf6d61b95181665c6d7a77d3c8844bc0516ba2d46de4161456f7280f7.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 40,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 40
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 18,
                                        "start_line": 2
                                    },
                                    "While expanding the reference '__return_value_arg_reserve0' in:"
                                ],
                                "start_col": 29,
                                "start_line": 40
                            },
                            "While handling return value 'reserve0'"
                        ],
                        "start_col": 35,
                        "start_line": 1
                    }
                },
                "785": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 71,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/60d502dffaf75d94b1a0d2472940f82cad57b9f6a4b30ab530ff0d49bc6332b0.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 65,
                                "end_line": 40,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 83,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IPair/get_reserves/ea038c1bf6d61b95181665c6d7a77d3c8844bc0516ba2d46de4161456f7280f7.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 40,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 40
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 56,
                                        "start_line": 2
                                    },
                                    "While expanding the reference '__return_value_arg_reserve1' in:"
                                ],
                                "start_col": 48,
                                "start_line": 40
                            },
                            "While handling return value 'reserve1'"
                        ],
                        "start_col": 35,
                        "start_line": 1
                    }
                },
                "786": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 71,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/60d502dffaf75d94b1a0d2472940f82cad57b9f6a4b30ab530ff0d49bc6332b0.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 65,
                                "end_line": 40,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 83,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IPair/get_reserves/ea038c1bf6d61b95181665c6d7a77d3c8844bc0516ba2d46de4161456f7280f7.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 40,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 40
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 56,
                                        "start_line": 2
                                    },
                                    "While expanding the reference '__return_value_arg_reserve1' in:"
                                ],
                                "start_col": 48,
                                "start_line": 40
                            },
                            "While handling return value 'reserve1'"
                        ],
                        "start_col": 35,
                        "start_line": 1
                    }
                },
                "787": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8bc8cfbc6cba45ab45c5ec8589791e63546611f512de3c2af08c408ce66a1567.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 93,
                                "end_line": 40,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 145,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IPair/get_reserves/ea038c1bf6d61b95181665c6d7a77d3c8844bc0516ba2d46de4161456f7280f7.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 40,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 40
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 106,
                                        "start_line": 2
                                    },
                                    "While expanding the reference '__return_value_arg_block_timestamp_last' in:"
                                ],
                                "start_col": 67,
                                "start_line": 40
                            },
                            "While handling return value 'block_timestamp_last'"
                        ],
                        "start_col": 47,
                        "start_line": 1
                    }
                },
                "788": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 148,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/get_reserves/ea038c1bf6d61b95181665c6d7a77d3c8844bc0516ba2d46de4161456f7280f7.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 40,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 40
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 1,
                        "start_line": 2
                    }
                },
                "789": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.mint"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 14,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/mint/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 43,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 43
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 1,
                        "start_line": 2
                    }
                },
                "791": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.mint"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/mint/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 43,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 43
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 41,
                        "start_line": 3
                    }
                },
                "793": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.mint"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/mint/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 43,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 43
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 6,
                        "start_line": 3
                    }
                },
                "794": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.mint"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/2670bb539ede27446c75876e41bcf9ef5cab09b9eec143f3986635a545b089ab.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 43,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 15,
                                "start_line": 43
                            },
                            "While handling calldata argument 'to'"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "795": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.mint"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/2670bb539ede27446c75876e41bcf9ef5cab09b9eec143f3986635a545b089ab.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 43,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 33,
                                        "end_line": 5,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IPair/mint/304ae580b78eb48c6532c7788d73eef2a89dcd3c406dfb71c0bdbeacea1728ca.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 14,
                                                "end_line": 43,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 43
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 19,
                                        "start_line": 5
                                    },
                                    "While expanding the reference '__calldata_ptr' in:"
                                ],
                                "start_col": 15,
                                "start_line": 43
                            },
                            "While handling calldata argument 'to'"
                        ],
                        "start_col": 22,
                        "start_line": 2
                    }
                },
                "797": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.mint"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 29,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/mint/f331841f676ee38d6b90045cd4589cd9ff4e83c32234273d9e605b287d886cbc.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 43,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 38,
                                        "end_line": 42,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 33,
                                                "end_line": 6,
                                                "input_file": {
                                                    "filename": "autogen/starknet/contract_interface/IPair/mint/304ae580b78eb48c6532c7788d73eef2a89dcd3c406dfb71c0bdbeacea1728ca.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 14,
                                                        "end_line": 43,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 10,
                                                        "start_line": 43
                                                    },
                                                    "While handling contract interface function:"
                                                ],
                                                "start_col": 31,
                                                "start_line": 2
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 20,
                                        "start_line": 42
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 43
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 11,
                        "start_line": 1
                    }
                },
                "798": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.mint"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/mint/f331841f676ee38d6b90045cd4589cd9ff4e83c32234273d9e605b287d886cbc.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 43,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 38,
                                        "end_line": 3,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IPair/mint/304ae580b78eb48c6532c7788d73eef2a89dcd3c406dfb71c0bdbeacea1728ca.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 14,
                                                "end_line": 43,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 43
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 22,
                                        "start_line": 3
                                    },
                                    "While expanding the reference 'contract_address' in:"
                                ],
                                "start_col": 10,
                                "start_line": 43
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 5,
                        "start_line": 2
                    }
                },
                "799": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.mint"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 36,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/mint/304ae580b78eb48c6532c7788d73eef2a89dcd3c406dfb71c0bdbeacea1728ca.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 43,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 43
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 23,
                        "start_line": 4
                    }
                },
                "801": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.mint"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/mint/304ae580b78eb48c6532c7788d73eef2a89dcd3c406dfb71c0bdbeacea1728ca.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 43,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 43
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 19,
                        "start_line": 5
                    }
                },
                "802": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.mint"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/mint/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 43,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 32,
                                        "end_line": 6,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IPair/mint/304ae580b78eb48c6532c7788d73eef2a89dcd3c406dfb71c0bdbeacea1728ca.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 14,
                                                "end_line": 43,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 43
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 14,
                                        "start_line": 6
                                    },
                                    "While expanding the reference 'calldata_ptr_start' in:"
                                ],
                                "start_col": 10,
                                "start_line": 43
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 12,
                        "start_line": 3
                    }
                },
                "803": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.mint"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 6,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/mint/304ae580b78eb48c6532c7788d73eef2a89dcd3c406dfb71c0bdbeacea1728ca.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 43,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 43
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 31,
                        "start_line": 2
                    }
                },
                "805": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.mint"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/3898d272a0eb2fc039a70373a33534479a08886bd010ab7cd60ff7481a0b36b0.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 47,
                                "end_line": 43,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 53,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/accb388bcbbace5b6218198851efaad2a781c1339e305a37fab51afe90a3194a.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 14,
                                                "end_line": 43,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 49,
                                                        "end_line": 1,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/arg_processor/96447016176d161dae83f414c4d3e33e78856e0ef69bc33f8d419f1c9b90f9c5.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 14,
                                                                "end_line": 43,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 10,
                                                                "start_line": 43
                                                            },
                                                            "While handling return values of"
                                                        ],
                                                        "start_col": 23,
                                                        "start_line": 1
                                                    },
                                                    "While expanding the reference '__return_value_actual_size' in:"
                                                ],
                                                "start_col": 10,
                                                "start_line": 43
                                            },
                                            "While handling return values of"
                                        ],
                                        "start_col": 35,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__return_value_ptr' in:"
                                ],
                                "start_col": 29,
                                "start_line": 43
                            },
                            "While handling return value 'liquidity'"
                        ],
                        "start_col": 26,
                        "start_line": 3
                    }
                },
                "807": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.mint"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 50,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/96447016176d161dae83f414c4d3e33e78856e0ef69bc33f8d419f1c9b90f9c5.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 43,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 43
                            },
                            "While handling return values of"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "808": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.mint"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 42,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 33,
                                "end_line": 6,
                                "input_file": {
                                    "filename": "autogen/starknet/contract_interface/IPair/mint/304ae580b78eb48c6532c7788d73eef2a89dcd3c406dfb71c0bdbeacea1728ca.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 14,
                                        "end_line": 43,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 29,
                                                "end_line": 1,
                                                "input_file": {
                                                    "filename": "autogen/starknet/contract_interface/IPair/mint/f331841f676ee38d6b90045cd4589cd9ff4e83c32234273d9e605b287d886cbc.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 14,
                                                        "end_line": 43,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 50,
                                                                "end_line": 2,
                                                                "input_file": {
                                                                    "filename": "autogen/starknet/contract_interface/IPair/mint/d1654cc35d4e19a6f5eb06a6a8498269598158ae1678134b6b98c828f3eee941.cairo"
                                                                },
                                                                "parent_location": [
                                                                    {
                                                                        "end_col": 14,
                                                                        "end_line": 43,
                                                                        "input_file": {
                                                                            "filename": "contracts/Router.cairo"
                                                                        },
                                                                        "start_col": 10,
                                                                        "start_line": 43
                                                                    },
                                                                    "While handling contract interface function:"
                                                                ],
                                                                "start_col": 1,
                                                                "start_line": 2
                                                            },
                                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                        ],
                                                        "start_col": 10,
                                                        "start_line": 43
                                                    },
                                                    "While handling contract interface function:"
                                                ],
                                                "start_col": 11,
                                                "start_line": 1
                                            },
                                            "While expanding the reference 'syscall_ptr' in:"
                                        ],
                                        "start_col": 10,
                                        "start_line": 43
                                    },
                                    "While handling contract interface function:"
                                ],
                                "start_col": 31,
                                "start_line": 2
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 20,
                        "start_line": 42
                    }
                },
                "809": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.mint"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 46,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/mint/f331841f676ee38d6b90045cd4589cd9ff4e83c32234273d9e605b287d886cbc.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 43,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 46,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IPair/mint/f331841f676ee38d6b90045cd4589cd9ff4e83c32234273d9e605b287d886cbc.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 14,
                                                "end_line": 43,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 50,
                                                        "end_line": 2,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/contract_interface/IPair/mint/d1654cc35d4e19a6f5eb06a6a8498269598158ae1678134b6b98c828f3eee941.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 14,
                                                                "end_line": 43,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 10,
                                                                "start_line": 43
                                                            },
                                                            "While handling contract interface function:"
                                                        ],
                                                        "start_col": 1,
                                                        "start_line": 2
                                                    },
                                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                ],
                                                "start_col": 10,
                                                "start_line": 43
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 31,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 43
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "810": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.mint"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 71,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/3898d272a0eb2fc039a70373a33534479a08886bd010ab7cd60ff7481a0b36b0.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 47,
                                "end_line": 43,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 47,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IPair/mint/d1654cc35d4e19a6f5eb06a6a8498269598158ae1678134b6b98c828f3eee941.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 14,
                                                "end_line": 43,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 43
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 19,
                                        "start_line": 2
                                    },
                                    "While expanding the reference '__return_value_arg_liquidity' in:"
                                ],
                                "start_col": 29,
                                "start_line": 43
                            },
                            "While handling return value 'liquidity'"
                        ],
                        "start_col": 36,
                        "start_line": 1
                    }
                },
                "811": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.mint"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 71,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/3898d272a0eb2fc039a70373a33534479a08886bd010ab7cd60ff7481a0b36b0.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 47,
                                "end_line": 43,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 47,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IPair/mint/d1654cc35d4e19a6f5eb06a6a8498269598158ae1678134b6b98c828f3eee941.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 14,
                                                "end_line": 43,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 43
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 19,
                                        "start_line": 2
                                    },
                                    "While expanding the reference '__return_value_arg_liquidity' in:"
                                ],
                                "start_col": 29,
                                "start_line": 43
                            },
                            "While handling return value 'liquidity'"
                        ],
                        "start_col": 36,
                        "start_line": 1
                    }
                },
                "812": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.mint"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 50,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/mint/d1654cc35d4e19a6f5eb06a6a8498269598158ae1678134b6b98c828f3eee941.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 43,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 43
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 1,
                        "start_line": 2
                    }
                },
                "813": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.burn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 14,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/burn/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 46,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 46
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 1,
                        "start_line": 2
                    }
                },
                "815": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.burn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/burn/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 46,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 46
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 41,
                        "start_line": 3
                    }
                },
                "817": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.burn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/burn/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 46,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 46
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 6,
                        "start_line": 3
                    }
                },
                "818": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.burn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/2670bb539ede27446c75876e41bcf9ef5cab09b9eec143f3986635a545b089ab.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 46,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 15,
                                "start_line": 46
                            },
                            "While handling calldata argument 'to'"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "819": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.burn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/2670bb539ede27446c75876e41bcf9ef5cab09b9eec143f3986635a545b089ab.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 46,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 33,
                                        "end_line": 5,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IPair/burn/fb4dff06d8f249f0a46961fa65ce84ff34cc1bf90a1bb875b704d1f11cfcc4ee.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 14,
                                                "end_line": 46,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 46
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 19,
                                        "start_line": 5
                                    },
                                    "While expanding the reference '__calldata_ptr' in:"
                                ],
                                "start_col": 15,
                                "start_line": 46
                            },
                            "While handling calldata argument 'to'"
                        ],
                        "start_col": 22,
                        "start_line": 2
                    }
                },
                "821": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.burn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 29,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/burn/484b5f727aa4fe2fdbfbe21899bf52ecd5bf948dde26ab2d509b57812ab8a3cd.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 46,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 38,
                                        "end_line": 42,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 33,
                                                "end_line": 6,
                                                "input_file": {
                                                    "filename": "autogen/starknet/contract_interface/IPair/burn/fb4dff06d8f249f0a46961fa65ce84ff34cc1bf90a1bb875b704d1f11cfcc4ee.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 14,
                                                        "end_line": 46,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 10,
                                                        "start_line": 46
                                                    },
                                                    "While handling contract interface function:"
                                                ],
                                                "start_col": 31,
                                                "start_line": 2
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 20,
                                        "start_line": 42
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 46
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 11,
                        "start_line": 1
                    }
                },
                "822": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.burn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/burn/484b5f727aa4fe2fdbfbe21899bf52ecd5bf948dde26ab2d509b57812ab8a3cd.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 46,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 38,
                                        "end_line": 3,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IPair/burn/fb4dff06d8f249f0a46961fa65ce84ff34cc1bf90a1bb875b704d1f11cfcc4ee.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 14,
                                                "end_line": 46,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 46
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 22,
                                        "start_line": 3
                                    },
                                    "While expanding the reference 'contract_address' in:"
                                ],
                                "start_col": 10,
                                "start_line": 46
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 5,
                        "start_line": 2
                    }
                },
                "823": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.burn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 36,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/burn/fb4dff06d8f249f0a46961fa65ce84ff34cc1bf90a1bb875b704d1f11cfcc4ee.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 46,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 46
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 23,
                        "start_line": 4
                    }
                },
                "825": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.burn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/burn/fb4dff06d8f249f0a46961fa65ce84ff34cc1bf90a1bb875b704d1f11cfcc4ee.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 46,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 46
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 19,
                        "start_line": 5
                    }
                },
                "826": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.burn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/burn/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 46,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 32,
                                        "end_line": 6,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IPair/burn/fb4dff06d8f249f0a46961fa65ce84ff34cc1bf90a1bb875b704d1f11cfcc4ee.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 14,
                                                "end_line": 46,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 46
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 14,
                                        "start_line": 6
                                    },
                                    "While expanding the reference 'calldata_ptr_start' in:"
                                ],
                                "start_col": 10,
                                "start_line": 46
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 12,
                        "start_line": 3
                    }
                },
                "827": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.burn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 6,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/burn/fb4dff06d8f249f0a46961fa65ce84ff34cc1bf90a1bb875b704d1f11cfcc4ee.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 46,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 46
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 31,
                        "start_line": 2
                    }
                },
                "829": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.burn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/dd895a53cb6996926540ce0f7dd696481177552e37c98f699525c5a80806fd21.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 63,
                                "end_line": 46,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 53,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/accb388bcbbace5b6218198851efaad2a781c1339e305a37fab51afe90a3194a.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 14,
                                                "end_line": 46,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 49,
                                                        "end_line": 1,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/arg_processor/96447016176d161dae83f414c4d3e33e78856e0ef69bc33f8d419f1c9b90f9c5.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 14,
                                                                "end_line": 46,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 10,
                                                                "start_line": 46
                                                            },
                                                            "While handling return values of"
                                                        ],
                                                        "start_col": 23,
                                                        "start_line": 1
                                                    },
                                                    "While expanding the reference '__return_value_actual_size' in:"
                                                ],
                                                "start_col": 10,
                                                "start_line": 46
                                            },
                                            "While handling return values of"
                                        ],
                                        "start_col": 35,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__return_value_ptr' in:"
                                ],
                                "start_col": 47,
                                "start_line": 46
                            },
                            "While handling return value 'amount1'"
                        ],
                        "start_col": 26,
                        "start_line": 3
                    }
                },
                "831": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.burn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 50,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/96447016176d161dae83f414c4d3e33e78856e0ef69bc33f8d419f1c9b90f9c5.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 46,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 46
                            },
                            "While handling return values of"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "832": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.burn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 42,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 33,
                                "end_line": 6,
                                "input_file": {
                                    "filename": "autogen/starknet/contract_interface/IPair/burn/fb4dff06d8f249f0a46961fa65ce84ff34cc1bf90a1bb875b704d1f11cfcc4ee.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 14,
                                        "end_line": 46,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 29,
                                                "end_line": 1,
                                                "input_file": {
                                                    "filename": "autogen/starknet/contract_interface/IPair/burn/484b5f727aa4fe2fdbfbe21899bf52ecd5bf948dde26ab2d509b57812ab8a3cd.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 14,
                                                        "end_line": 46,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 82,
                                                                "end_line": 2,
                                                                "input_file": {
                                                                    "filename": "autogen/starknet/contract_interface/IPair/burn/11db19d274ee9484ba9eafc640748e2ada8ec4c37d82f4f687f64a32873361f1.cairo"
                                                                },
                                                                "parent_location": [
                                                                    {
                                                                        "end_col": 14,
                                                                        "end_line": 46,
                                                                        "input_file": {
                                                                            "filename": "contracts/Router.cairo"
                                                                        },
                                                                        "start_col": 10,
                                                                        "start_line": 46
                                                                    },
                                                                    "While handling contract interface function:"
                                                                ],
                                                                "start_col": 1,
                                                                "start_line": 2
                                                            },
                                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                        ],
                                                        "start_col": 10,
                                                        "start_line": 46
                                                    },
                                                    "While handling contract interface function:"
                                                ],
                                                "start_col": 11,
                                                "start_line": 1
                                            },
                                            "While expanding the reference 'syscall_ptr' in:"
                                        ],
                                        "start_col": 10,
                                        "start_line": 46
                                    },
                                    "While handling contract interface function:"
                                ],
                                "start_col": 31,
                                "start_line": 2
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 20,
                        "start_line": 42
                    }
                },
                "833": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.burn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 46,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/burn/484b5f727aa4fe2fdbfbe21899bf52ecd5bf948dde26ab2d509b57812ab8a3cd.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 46,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 46,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IPair/burn/484b5f727aa4fe2fdbfbe21899bf52ecd5bf948dde26ab2d509b57812ab8a3cd.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 14,
                                                "end_line": 46,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 82,
                                                        "end_line": 2,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/contract_interface/IPair/burn/11db19d274ee9484ba9eafc640748e2ada8ec4c37d82f4f687f64a32873361f1.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 14,
                                                                "end_line": 46,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 10,
                                                                "start_line": 46
                                                            },
                                                            "While handling contract interface function:"
                                                        ],
                                                        "start_col": 1,
                                                        "start_line": 2
                                                    },
                                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                ],
                                                "start_col": 10,
                                                "start_line": 46
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 31,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 46
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "834": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.burn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 71,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/d63583a300b69459989123381c86383cff8c41871521c1e51e9946f3d6326e08.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 45,
                                "end_line": 46,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 43,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IPair/burn/11db19d274ee9484ba9eafc640748e2ada8ec4c37d82f4f687f64a32873361f1.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 14,
                                                "end_line": 46,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 46
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 17,
                                        "start_line": 2
                                    },
                                    "While expanding the reference '__return_value_arg_amount0' in:"
                                ],
                                "start_col": 29,
                                "start_line": 46
                            },
                            "While handling return value 'amount0'"
                        ],
                        "start_col": 34,
                        "start_line": 1
                    }
                },
                "835": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.burn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 71,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/d63583a300b69459989123381c86383cff8c41871521c1e51e9946f3d6326e08.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 45,
                                "end_line": 46,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 43,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IPair/burn/11db19d274ee9484ba9eafc640748e2ada8ec4c37d82f4f687f64a32873361f1.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 14,
                                                "end_line": 46,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 46
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 17,
                                        "start_line": 2
                                    },
                                    "While expanding the reference '__return_value_arg_amount0' in:"
                                ],
                                "start_col": 29,
                                "start_line": 46
                            },
                            "While handling return value 'amount0'"
                        ],
                        "start_col": 34,
                        "start_line": 1
                    }
                },
                "836": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.burn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 71,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/dd895a53cb6996926540ce0f7dd696481177552e37c98f699525c5a80806fd21.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 63,
                                "end_line": 46,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 79,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IPair/burn/11db19d274ee9484ba9eafc640748e2ada8ec4c37d82f4f687f64a32873361f1.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 14,
                                                "end_line": 46,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 46
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 53,
                                        "start_line": 2
                                    },
                                    "While expanding the reference '__return_value_arg_amount1' in:"
                                ],
                                "start_col": 47,
                                "start_line": 46
                            },
                            "While handling return value 'amount1'"
                        ],
                        "start_col": 34,
                        "start_line": 1
                    }
                },
                "837": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.burn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 71,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/dd895a53cb6996926540ce0f7dd696481177552e37c98f699525c5a80806fd21.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 63,
                                "end_line": 46,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 79,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IPair/burn/11db19d274ee9484ba9eafc640748e2ada8ec4c37d82f4f687f64a32873361f1.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 14,
                                                "end_line": 46,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 46
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 53,
                                        "start_line": 2
                                    },
                                    "While expanding the reference '__return_value_arg_amount1' in:"
                                ],
                                "start_col": 47,
                                "start_line": 46
                            },
                            "While handling return value 'amount1'"
                        ],
                        "start_col": 34,
                        "start_line": 1
                    }
                },
                "838": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.burn"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/burn/11db19d274ee9484ba9eafc640748e2ada8ec4c37d82f4f687f64a32873361f1.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 46,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 46
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 1,
                        "start_line": 2
                    }
                },
                "839": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 14,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/swap/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 49,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 49
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 1,
                        "start_line": 2
                    }
                },
                "841": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/swap/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 49,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 49
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 41,
                        "start_line": 3
                    }
                },
                "843": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/swap/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 49,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 49
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 6,
                        "start_line": 3
                    }
                },
                "844": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 52,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/d63e3658a49e97559b3e34f5dbc952c8cd0187c1c7245eb59246b154161c4f62.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 25,
                                "end_line": 49,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 15,
                                "start_line": 49
                            },
                            "While handling calldata argument 'amount0Out'"
                        ],
                        "start_col": 1,
                        "start_line": 3
                    }
                },
                "845": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 52,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/d63e3658a49e97559b3e34f5dbc952c8cd0187c1c7245eb59246b154161c4f62.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 25,
                                "end_line": 49,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 15,
                                "start_line": 49
                            },
                            "While handling calldata argument 'amount0Out'"
                        ],
                        "start_col": 1,
                        "start_line": 4
                    }
                },
                "846": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 52,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/f697f3f95693ecd76e2d0a3577b6e68442439e583882e0aea09c5a98b23da47b.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 46,
                                "end_line": 49,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 36,
                                "start_line": 49
                            },
                            "While handling calldata argument 'amount1Out'"
                        ],
                        "start_col": 1,
                        "start_line": 3
                    }
                },
                "847": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 52,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/f697f3f95693ecd76e2d0a3577b6e68442439e583882e0aea09c5a98b23da47b.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 46,
                                "end_line": 49,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 36,
                                "start_line": 49
                            },
                            "While handling calldata argument 'amount1Out'"
                        ],
                        "start_col": 1,
                        "start_line": 4
                    }
                },
                "848": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/2670bb539ede27446c75876e41bcf9ef5cab09b9eec143f3986635a545b089ab.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 59,
                                "end_line": 49,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 57,
                                "start_line": 49
                            },
                            "While handling calldata argument 'to'"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "849": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 36,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/5acfd8e875f992ccc9524eb75bc3cf4970bff0987c9ed04b83bdbe0bdb94c8b5.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 75,
                                "end_line": 49,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 67,
                                "start_line": 49
                            },
                            "While handling calldata argument 'data_len'"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "850": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/5acfd8e875f992ccc9524eb75bc3cf4970bff0987c9ed04b83bdbe0bdb94c8b5.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 75,
                                "end_line": 49,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 33,
                                        "end_line": 5,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IPair/swap/f126f38e3113fe38a10b48615a23c6c6af7986a8395c8dfdd4d166bf664e76dc.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 14,
                                                "end_line": 49,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 49
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 19,
                                        "start_line": 5
                                    },
                                    "While expanding the reference '__calldata_ptr' in:"
                                ],
                                "start_col": 67,
                                "start_line": 49
                            },
                            "While handling calldata argument 'data_len'"
                        ],
                        "start_col": 22,
                        "start_line": 2
                    }
                },
                "852": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 29,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/swap/a698a4a1c6e7fbaf8535ba0f61a54c85006889c49fbeabc3ae990c185771e4da.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 49,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 38,
                                        "end_line": 42,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 33,
                                                "end_line": 6,
                                                "input_file": {
                                                    "filename": "autogen/starknet/contract_interface/IPair/swap/f126f38e3113fe38a10b48615a23c6c6af7986a8395c8dfdd4d166bf664e76dc.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 14,
                                                        "end_line": 49,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 10,
                                                        "start_line": 49
                                                    },
                                                    "While handling contract interface function:"
                                                ],
                                                "start_col": 31,
                                                "start_line": 2
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 20,
                                        "start_line": 42
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 49
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 11,
                        "start_line": 1
                    }
                },
                "853": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/swap/a698a4a1c6e7fbaf8535ba0f61a54c85006889c49fbeabc3ae990c185771e4da.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 49,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 38,
                                        "end_line": 3,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IPair/swap/f126f38e3113fe38a10b48615a23c6c6af7986a8395c8dfdd4d166bf664e76dc.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 14,
                                                "end_line": 49,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 49
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 22,
                                        "start_line": 3
                                    },
                                    "While expanding the reference 'contract_address' in:"
                                ],
                                "start_col": 10,
                                "start_line": 49
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 5,
                        "start_line": 2
                    }
                },
                "854": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 36,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/swap/f126f38e3113fe38a10b48615a23c6c6af7986a8395c8dfdd4d166bf664e76dc.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 49,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 49
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 23,
                        "start_line": 4
                    }
                },
                "856": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/swap/f126f38e3113fe38a10b48615a23c6c6af7986a8395c8dfdd4d166bf664e76dc.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 49,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 49
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 19,
                        "start_line": 5
                    }
                },
                "857": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/swap/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 49,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 32,
                                        "end_line": 6,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IPair/swap/f126f38e3113fe38a10b48615a23c6c6af7986a8395c8dfdd4d166bf664e76dc.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 14,
                                                "end_line": 49,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 49
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 14,
                                        "start_line": 6
                                    },
                                    "While expanding the reference 'calldata_ptr_start' in:"
                                ],
                                "start_col": 10,
                                "start_line": 49
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 12,
                        "start_line": 3
                    }
                },
                "858": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 6,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/swap/f126f38e3113fe38a10b48615a23c6c6af7986a8395c8dfdd4d166bf664e76dc.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 49,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 49
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 31,
                        "start_line": 2
                    }
                },
                "860": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 42,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 33,
                                "end_line": 6,
                                "input_file": {
                                    "filename": "autogen/starknet/contract_interface/IPair/swap/f126f38e3113fe38a10b48615a23c6c6af7986a8395c8dfdd4d166bf664e76dc.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 14,
                                        "end_line": 49,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 29,
                                                "end_line": 1,
                                                "input_file": {
                                                    "filename": "autogen/starknet/contract_interface/IPair/swap/a698a4a1c6e7fbaf8535ba0f61a54c85006889c49fbeabc3ae990c185771e4da.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 14,
                                                        "end_line": 49,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 11,
                                                                "end_line": 2,
                                                                "input_file": {
                                                                    "filename": "autogen/starknet/contract_interface/IPair/swap/fb06bdf6e8fa9c7ff7d919d712d494716b0b586b2a762687cfb72d93da06ab29.cairo"
                                                                },
                                                                "parent_location": [
                                                                    {
                                                                        "end_col": 14,
                                                                        "end_line": 49,
                                                                        "input_file": {
                                                                            "filename": "contracts/Router.cairo"
                                                                        },
                                                                        "start_col": 10,
                                                                        "start_line": 49
                                                                    },
                                                                    "While handling contract interface function:"
                                                                ],
                                                                "start_col": 1,
                                                                "start_line": 2
                                                            },
                                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                        ],
                                                        "start_col": 10,
                                                        "start_line": 49
                                                    },
                                                    "While handling contract interface function:"
                                                ],
                                                "start_col": 11,
                                                "start_line": 1
                                            },
                                            "While expanding the reference 'syscall_ptr' in:"
                                        ],
                                        "start_col": 10,
                                        "start_line": 49
                                    },
                                    "While handling contract interface function:"
                                ],
                                "start_col": 31,
                                "start_line": 2
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 20,
                        "start_line": 42
                    }
                },
                "861": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 46,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/swap/a698a4a1c6e7fbaf8535ba0f61a54c85006889c49fbeabc3ae990c185771e4da.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 49,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 46,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IPair/swap/a698a4a1c6e7fbaf8535ba0f61a54c85006889c49fbeabc3ae990c185771e4da.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 14,
                                                "end_line": 49,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 11,
                                                        "end_line": 2,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/contract_interface/IPair/swap/fb06bdf6e8fa9c7ff7d919d712d494716b0b586b2a762687cfb72d93da06ab29.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 14,
                                                                "end_line": 49,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 10,
                                                                "start_line": 49
                                                            },
                                                            "While handling contract interface function:"
                                                        ],
                                                        "start_col": 1,
                                                        "start_line": 2
                                                    },
                                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                ],
                                                "start_col": 10,
                                                "start_line": 49
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 31,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 49
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "862": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IPair",
                        "__main__.IPair.swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 11,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/swap/fb06bdf6e8fa9c7ff7d919d712d494716b0b586b2a762687cfb72d93da06ab29.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 49,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 49
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 1,
                        "start_line": 2
                    }
                },
                "863": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.get_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 14,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IFactory/get_pair/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 55,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 55
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 1,
                        "start_line": 2
                    }
                },
                "865": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.get_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IFactory/get_pair/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 55,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 55
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 41,
                        "start_line": 3
                    }
                },
                "867": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.get_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IFactory/get_pair/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 55,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 55
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 6,
                        "start_line": 3
                    }
                },
                "868": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.get_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/fc1a676dec8441f547a52cd0f6b0a697e33f09e4dfbd52f45ed2ceb1abcf4dac.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 25,
                                "end_line": 55,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 19,
                                "start_line": 55
                            },
                            "While handling calldata argument 'token0'"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "869": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.get_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/7e52648a617a4c3a88d50b1f12aea1669d4b3a7bb8d0d03929df3d5c4e884777.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 39,
                                "end_line": 55,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 33,
                                "start_line": 55
                            },
                            "While handling calldata argument 'token1'"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "870": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.get_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/7e52648a617a4c3a88d50b1f12aea1669d4b3a7bb8d0d03929df3d5c4e884777.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 39,
                                "end_line": 55,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 33,
                                        "end_line": 5,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IFactory/get_pair/8b44652aeeab676a97be5d73e292165bb2089705221cc1f09c47aa21e53ee70a.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 18,
                                                "end_line": 55,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 55
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 19,
                                        "start_line": 5
                                    },
                                    "While expanding the reference '__calldata_ptr' in:"
                                ],
                                "start_col": 33,
                                "start_line": 55
                            },
                            "While handling calldata argument 'token1'"
                        ],
                        "start_col": 22,
                        "start_line": 2
                    }
                },
                "872": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.get_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IFactory/get_pair/f3ae4d3d9703c6ba4fff0fd10db0740f6555616f3de5dffdec4e7ebb2533c4cb.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 55,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 38,
                                        "end_line": 42,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 33,
                                                "end_line": 6,
                                                "input_file": {
                                                    "filename": "autogen/starknet/contract_interface/IFactory/get_pair/8b44652aeeab676a97be5d73e292165bb2089705221cc1f09c47aa21e53ee70a.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 18,
                                                        "end_line": 55,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 10,
                                                        "start_line": 55
                                                    },
                                                    "While handling contract interface function:"
                                                ],
                                                "start_col": 31,
                                                "start_line": 2
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 20,
                                        "start_line": 42
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 55
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 15,
                        "start_line": 1
                    }
                },
                "873": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.get_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IFactory/get_pair/f3ae4d3d9703c6ba4fff0fd10db0740f6555616f3de5dffdec4e7ebb2533c4cb.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 55,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 38,
                                        "end_line": 3,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IFactory/get_pair/8b44652aeeab676a97be5d73e292165bb2089705221cc1f09c47aa21e53ee70a.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 18,
                                                "end_line": 55,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 55
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 22,
                                        "start_line": 3
                                    },
                                    "While expanding the reference 'contract_address' in:"
                                ],
                                "start_col": 10,
                                "start_line": 55
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 5,
                        "start_line": 2
                    }
                },
                "874": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.get_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IFactory/get_pair/8b44652aeeab676a97be5d73e292165bb2089705221cc1f09c47aa21e53ee70a.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 55,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 55
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 23,
                        "start_line": 4
                    }
                },
                "876": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.get_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IFactory/get_pair/8b44652aeeab676a97be5d73e292165bb2089705221cc1f09c47aa21e53ee70a.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 55,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 55
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 19,
                        "start_line": 5
                    }
                },
                "877": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.get_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IFactory/get_pair/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 55,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 32,
                                        "end_line": 6,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IFactory/get_pair/8b44652aeeab676a97be5d73e292165bb2089705221cc1f09c47aa21e53ee70a.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 18,
                                                "end_line": 55,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 55
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 14,
                                        "start_line": 6
                                    },
                                    "While expanding the reference 'calldata_ptr_start' in:"
                                ],
                                "start_col": 10,
                                "start_line": 55
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 12,
                        "start_line": 3
                    }
                },
                "878": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.get_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 6,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IFactory/get_pair/8b44652aeeab676a97be5d73e292165bb2089705221cc1f09c47aa21e53ee70a.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 55,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 55
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 31,
                        "start_line": 2
                    }
                },
                "880": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.get_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/4b5f5725e23556473d888ce93982c14d400a4f093e48b12b62f71ccb4cc2b54d.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 61,
                                "end_line": 55,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 53,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/accb388bcbbace5b6218198851efaad2a781c1339e305a37fab51afe90a3194a.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 18,
                                                "end_line": 55,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 49,
                                                        "end_line": 1,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/arg_processor/96447016176d161dae83f414c4d3e33e78856e0ef69bc33f8d419f1c9b90f9c5.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 18,
                                                                "end_line": 55,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 10,
                                                                "start_line": 55
                                                            },
                                                            "While handling return values of"
                                                        ],
                                                        "start_col": 23,
                                                        "start_line": 1
                                                    },
                                                    "While expanding the reference '__return_value_actual_size' in:"
                                                ],
                                                "start_col": 10,
                                                "start_line": 55
                                            },
                                            "While handling return values of"
                                        ],
                                        "start_col": 35,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__return_value_ptr' in:"
                                ],
                                "start_col": 51,
                                "start_line": 55
                            },
                            "While handling return value 'pair'"
                        ],
                        "start_col": 26,
                        "start_line": 2
                    }
                },
                "882": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.get_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 50,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/96447016176d161dae83f414c4d3e33e78856e0ef69bc33f8d419f1c9b90f9c5.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 55,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 55
                            },
                            "While handling return values of"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "883": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.get_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 42,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 33,
                                "end_line": 6,
                                "input_file": {
                                    "filename": "autogen/starknet/contract_interface/IFactory/get_pair/8b44652aeeab676a97be5d73e292165bb2089705221cc1f09c47aa21e53ee70a.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 18,
                                        "end_line": 55,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 33,
                                                "end_line": 1,
                                                "input_file": {
                                                    "filename": "autogen/starknet/contract_interface/IFactory/get_pair/f3ae4d3d9703c6ba4fff0fd10db0740f6555616f3de5dffdec4e7ebb2533c4cb.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 18,
                                                        "end_line": 55,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 40,
                                                                "end_line": 2,
                                                                "input_file": {
                                                                    "filename": "autogen/starknet/contract_interface/IFactory/get_pair/618aec1c118db579d886a51a8bd51a40857c890f7d94163fb7cceb342c27e778.cairo"
                                                                },
                                                                "parent_location": [
                                                                    {
                                                                        "end_col": 18,
                                                                        "end_line": 55,
                                                                        "input_file": {
                                                                            "filename": "contracts/Router.cairo"
                                                                        },
                                                                        "start_col": 10,
                                                                        "start_line": 55
                                                                    },
                                                                    "While handling contract interface function:"
                                                                ],
                                                                "start_col": 1,
                                                                "start_line": 2
                                                            },
                                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                        ],
                                                        "start_col": 10,
                                                        "start_line": 55
                                                    },
                                                    "While handling contract interface function:"
                                                ],
                                                "start_col": 15,
                                                "start_line": 1
                                            },
                                            "While expanding the reference 'syscall_ptr' in:"
                                        ],
                                        "start_col": 10,
                                        "start_line": 55
                                    },
                                    "While handling contract interface function:"
                                ],
                                "start_col": 31,
                                "start_line": 2
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 20,
                        "start_line": 42
                    }
                },
                "884": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.get_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 50,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IFactory/get_pair/f3ae4d3d9703c6ba4fff0fd10db0740f6555616f3de5dffdec4e7ebb2533c4cb.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 55,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 50,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IFactory/get_pair/f3ae4d3d9703c6ba4fff0fd10db0740f6555616f3de5dffdec4e7ebb2533c4cb.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 18,
                                                "end_line": 55,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 40,
                                                        "end_line": 2,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/contract_interface/IFactory/get_pair/618aec1c118db579d886a51a8bd51a40857c890f7d94163fb7cceb342c27e778.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 18,
                                                                "end_line": 55,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 10,
                                                                "start_line": 55
                                                            },
                                                            "While handling contract interface function:"
                                                        ],
                                                        "start_col": 1,
                                                        "start_line": 2
                                                    },
                                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                ],
                                                "start_col": 10,
                                                "start_line": 55
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 35,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 55
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 35,
                        "start_line": 1
                    }
                },
                "885": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.get_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 51,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/4b5f5725e23556473d888ce93982c14d400a4f093e48b12b62f71ccb4cc2b54d.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 61,
                                "end_line": 55,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 37,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IFactory/get_pair/618aec1c118db579d886a51a8bd51a40857c890f7d94163fb7cceb342c27e778.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 18,
                                                "end_line": 55,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 55
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 14,
                                        "start_line": 2
                                    },
                                    "While expanding the reference '__return_value_arg_pair' in:"
                                ],
                                "start_col": 51,
                                "start_line": 55
                            },
                            "While handling return value 'pair'"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "886": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.get_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IFactory/get_pair/618aec1c118db579d886a51a8bd51a40857c890f7d94163fb7cceb342c27e778.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 55,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 55
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 1,
                        "start_line": 2
                    }
                },
                "887": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.create_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 14,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IFactory/create_pair/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 58,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 58
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 1,
                        "start_line": 2
                    }
                },
                "889": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.create_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IFactory/create_pair/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 58,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 58
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 41,
                        "start_line": 3
                    }
                },
                "891": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.create_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IFactory/create_pair/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 58,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 58
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 6,
                        "start_line": 3
                    }
                },
                "892": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.create_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/fc1a676dec8441f547a52cd0f6b0a697e33f09e4dfbd52f45ed2ceb1abcf4dac.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 28,
                                "end_line": 58,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 22,
                                "start_line": 58
                            },
                            "While handling calldata argument 'token0'"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "893": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.create_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/7e52648a617a4c3a88d50b1f12aea1669d4b3a7bb8d0d03929df3d5c4e884777.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 58,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 36,
                                "start_line": 58
                            },
                            "While handling calldata argument 'token1'"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "894": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.create_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/7e52648a617a4c3a88d50b1f12aea1669d4b3a7bb8d0d03929df3d5c4e884777.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 58,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 33,
                                        "end_line": 5,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IFactory/create_pair/9a3616088dd2ae01329a4d6c83171bbd7c656edfe550d843d4dde0a8869d322b.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 21,
                                                "end_line": 58,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 58
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 19,
                                        "start_line": 5
                                    },
                                    "While expanding the reference '__calldata_ptr' in:"
                                ],
                                "start_col": 36,
                                "start_line": 58
                            },
                            "While handling calldata argument 'token1'"
                        ],
                        "start_col": 22,
                        "start_line": 2
                    }
                },
                "896": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.create_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 36,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IFactory/create_pair/ee84ebacd8edbc2e3304e1a9e2de38e564d83099cfaf093085de1c7a1339654f.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 58,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 38,
                                        "end_line": 42,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 33,
                                                "end_line": 6,
                                                "input_file": {
                                                    "filename": "autogen/starknet/contract_interface/IFactory/create_pair/9a3616088dd2ae01329a4d6c83171bbd7c656edfe550d843d4dde0a8869d322b.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 21,
                                                        "end_line": 58,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 10,
                                                        "start_line": 58
                                                    },
                                                    "While handling contract interface function:"
                                                ],
                                                "start_col": 31,
                                                "start_line": 2
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 20,
                                        "start_line": 42
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 58
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 18,
                        "start_line": 1
                    }
                },
                "897": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.create_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IFactory/create_pair/ee84ebacd8edbc2e3304e1a9e2de38e564d83099cfaf093085de1c7a1339654f.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 58,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 38,
                                        "end_line": 3,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IFactory/create_pair/9a3616088dd2ae01329a4d6c83171bbd7c656edfe550d843d4dde0a8869d322b.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 21,
                                                "end_line": 58,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 58
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 22,
                                        "start_line": 3
                                    },
                                    "While expanding the reference 'contract_address' in:"
                                ],
                                "start_col": 10,
                                "start_line": 58
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 5,
                        "start_line": 2
                    }
                },
                "898": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.create_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 43,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IFactory/create_pair/9a3616088dd2ae01329a4d6c83171bbd7c656edfe550d843d4dde0a8869d322b.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 58,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 58
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 23,
                        "start_line": 4
                    }
                },
                "900": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.create_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IFactory/create_pair/9a3616088dd2ae01329a4d6c83171bbd7c656edfe550d843d4dde0a8869d322b.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 58,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 58
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 19,
                        "start_line": 5
                    }
                },
                "901": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.create_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IFactory/create_pair/64f7842b108e76706927b78270ac73c789c558c54431b3359e4441e63ec2a868.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 58,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 32,
                                        "end_line": 6,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IFactory/create_pair/9a3616088dd2ae01329a4d6c83171bbd7c656edfe550d843d4dde0a8869d322b.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 21,
                                                "end_line": 58,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 58
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 14,
                                        "start_line": 6
                                    },
                                    "While expanding the reference 'calldata_ptr_start' in:"
                                ],
                                "start_col": 10,
                                "start_line": 58
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 12,
                        "start_line": 3
                    }
                },
                "902": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.create_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 6,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IFactory/create_pair/9a3616088dd2ae01329a4d6c83171bbd7c656edfe550d843d4dde0a8869d322b.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 58,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 58
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 31,
                        "start_line": 2
                    }
                },
                "904": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.create_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/4b5f5725e23556473d888ce93982c14d400a4f093e48b12b62f71ccb4cc2b54d.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 64,
                                "end_line": 58,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 53,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/accb388bcbbace5b6218198851efaad2a781c1339e305a37fab51afe90a3194a.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 21,
                                                "end_line": 58,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 49,
                                                        "end_line": 1,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/arg_processor/96447016176d161dae83f414c4d3e33e78856e0ef69bc33f8d419f1c9b90f9c5.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 21,
                                                                "end_line": 58,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 10,
                                                                "start_line": 58
                                                            },
                                                            "While handling return values of"
                                                        ],
                                                        "start_col": 23,
                                                        "start_line": 1
                                                    },
                                                    "While expanding the reference '__return_value_actual_size' in:"
                                                ],
                                                "start_col": 10,
                                                "start_line": 58
                                            },
                                            "While handling return values of"
                                        ],
                                        "start_col": 35,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__return_value_ptr' in:"
                                ],
                                "start_col": 54,
                                "start_line": 58
                            },
                            "While handling return value 'pair'"
                        ],
                        "start_col": 26,
                        "start_line": 2
                    }
                },
                "906": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.create_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 50,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/96447016176d161dae83f414c4d3e33e78856e0ef69bc33f8d419f1c9b90f9c5.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 58,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 58
                            },
                            "While handling return values of"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "907": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.create_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 42,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 33,
                                "end_line": 6,
                                "input_file": {
                                    "filename": "autogen/starknet/contract_interface/IFactory/create_pair/9a3616088dd2ae01329a4d6c83171bbd7c656edfe550d843d4dde0a8869d322b.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 21,
                                        "end_line": 58,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 36,
                                                "end_line": 1,
                                                "input_file": {
                                                    "filename": "autogen/starknet/contract_interface/IFactory/create_pair/ee84ebacd8edbc2e3304e1a9e2de38e564d83099cfaf093085de1c7a1339654f.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 21,
                                                        "end_line": 58,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 40,
                                                                "end_line": 2,
                                                                "input_file": {
                                                                    "filename": "autogen/starknet/contract_interface/IFactory/create_pair/618aec1c118db579d886a51a8bd51a40857c890f7d94163fb7cceb342c27e778.cairo"
                                                                },
                                                                "parent_location": [
                                                                    {
                                                                        "end_col": 21,
                                                                        "end_line": 58,
                                                                        "input_file": {
                                                                            "filename": "contracts/Router.cairo"
                                                                        },
                                                                        "start_col": 10,
                                                                        "start_line": 58
                                                                    },
                                                                    "While handling contract interface function:"
                                                                ],
                                                                "start_col": 1,
                                                                "start_line": 2
                                                            },
                                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                        ],
                                                        "start_col": 10,
                                                        "start_line": 58
                                                    },
                                                    "While handling contract interface function:"
                                                ],
                                                "start_col": 18,
                                                "start_line": 1
                                            },
                                            "While expanding the reference 'syscall_ptr' in:"
                                        ],
                                        "start_col": 10,
                                        "start_line": 58
                                    },
                                    "While handling contract interface function:"
                                ],
                                "start_col": 31,
                                "start_line": 2
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 20,
                        "start_line": 42
                    }
                },
                "908": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.create_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 53,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IFactory/create_pair/ee84ebacd8edbc2e3304e1a9e2de38e564d83099cfaf093085de1c7a1339654f.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 58,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 53,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IFactory/create_pair/ee84ebacd8edbc2e3304e1a9e2de38e564d83099cfaf093085de1c7a1339654f.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 21,
                                                "end_line": 58,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 40,
                                                        "end_line": 2,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/contract_interface/IFactory/create_pair/618aec1c118db579d886a51a8bd51a40857c890f7d94163fb7cceb342c27e778.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 21,
                                                                "end_line": 58,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 10,
                                                                "start_line": 58
                                                            },
                                                            "While handling contract interface function:"
                                                        ],
                                                        "start_col": 1,
                                                        "start_line": 2
                                                    },
                                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                ],
                                                "start_col": 10,
                                                "start_line": 58
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 38,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 58
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 38,
                        "start_line": 1
                    }
                },
                "909": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.create_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 51,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/4b5f5725e23556473d888ce93982c14d400a4f093e48b12b62f71ccb4cc2b54d.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 64,
                                "end_line": 58,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 37,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IFactory/create_pair/618aec1c118db579d886a51a8bd51a40857c890f7d94163fb7cceb342c27e778.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 21,
                                                "end_line": 58,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 10,
                                                "start_line": 58
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 14,
                                        "start_line": 2
                                    },
                                    "While expanding the reference '__return_value_arg_pair' in:"
                                ],
                                "start_col": 54,
                                "start_line": 58
                            },
                            "While handling return value 'pair'"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "910": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__.IFactory",
                        "__main__.IFactory.create_pair"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IFactory/create_pair/618aec1c118db579d886a51a8bd51a40857c890f7d94163fb7cceb342c27e778.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 58,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 58
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 1,
                        "start_line": 2
                    }
                },
                "911": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._factory",
                        "__main__._factory.addr"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 7,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 41,
                                "end_line": 7,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 26,
                                        "end_line": 9,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                                        },
                                        "start_col": 9,
                                        "start_line": 9
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 15,
                                "start_line": 7
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 7
                    }
                },
                "912": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._factory",
                        "__main__._factory.addr"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 58,
                        "end_line": 7,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 58,
                                "end_line": 7,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 26,
                                        "end_line": 9,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                                        },
                                        "start_col": 9,
                                        "start_line": 9
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 43,
                                "start_line": 7
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 43,
                        "start_line": 7
                    }
                },
                "913": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._factory",
                        "__main__._factory.addr"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 94,
                        "end_line": 8,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 9,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                                },
                                "start_col": 21,
                                "start_line": 9
                            },
                            "While expanding the reference 'res' in:"
                        ],
                        "start_col": 19,
                        "start_line": 8
                    }
                },
                "915": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._factory",
                        "__main__._factory.addr"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 26,
                        "end_line": 9,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                        },
                        "start_col": 9,
                        "start_line": 9
                    }
                },
                "916": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._factory",
                        "__main__._factory.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 12,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 41,
                                "end_line": 7,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 36,
                                        "end_line": 15,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                                        },
                                        "start_col": 30,
                                        "start_line": 15
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 15,
                                "start_line": 7
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 35,
                        "start_line": 12
                    }
                },
                "917": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._factory",
                        "__main__._factory.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 78,
                        "end_line": 12,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 58,
                                "end_line": 7,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 36,
                                        "end_line": 15,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                                        },
                                        "start_col": 30,
                                        "start_line": 15
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 43,
                                "start_line": 7
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 63,
                        "start_line": 12
                    }
                },
                "918": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._factory",
                        "__main__._factory.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 36,
                        "end_line": 15,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                        },
                        "start_col": 30,
                        "start_line": 15
                    }
                },
                "920": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._factory",
                        "__main__._factory.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 12,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 37,
                                "end_line": 352,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 75,
                                        "end_line": 16,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                                        },
                                        "start_col": 37,
                                        "start_line": 16
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 19,
                                "start_line": 352
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 12
                    }
                },
                "921": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._factory",
                        "__main__._factory.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 26,
                        "end_line": 15,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 70,
                                "end_line": 16,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                                },
                                "start_col": 58,
                                "start_line": 16
                            },
                            "While expanding the reference 'storage_addr' in:"
                        ],
                        "start_col": 14,
                        "start_line": 15
                    }
                },
                "922": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._factory",
                        "__main__._factory.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 75,
                        "end_line": 16,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                        },
                        "start_col": 37,
                        "start_line": 16
                    }
                },
                "924": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._factory",
                        "__main__._factory.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 352,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 75,
                                "end_line": 16,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 42,
                                        "end_line": 18,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                                        },
                                        "start_col": 31,
                                        "start_line": 18
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 37,
                                "start_line": 16
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 19,
                        "start_line": 352
                    }
                },
                "925": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._factory",
                        "__main__._factory.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 7,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 15,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 44,
                                        "end_line": 19,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                                        },
                                        "start_col": 32,
                                        "start_line": 19
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 30,
                                "start_line": 15
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 7
                    }
                },
                "926": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._factory",
                        "__main__._factory.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 58,
                        "end_line": 7,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 15,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 50,
                                        "end_line": 20,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                                        },
                                        "start_col": 35,
                                        "start_line": 20
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 30,
                                "start_line": 15
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 43,
                        "start_line": 7
                    }
                },
                "927": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._factory",
                        "__main__._factory.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 16,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 64,
                                "end_line": 21,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                                },
                                "start_col": 45,
                                "start_line": 21
                            },
                            "While expanding the reference '__storage_var_temp0' in:"
                        ],
                        "start_col": 14,
                        "start_line": 16
                    }
                },
                "928": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._factory",
                        "__main__._factory.read"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 55,
                        "end_line": 22,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                        },
                        "start_col": 9,
                        "start_line": 22
                    }
                },
                "929": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._factory",
                        "__main__._factory.write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 62,
                        "end_line": 25,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 41,
                                "end_line": 7,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 36,
                                        "end_line": 26,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                                        },
                                        "start_col": 30,
                                        "start_line": 26
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 15,
                                "start_line": 7
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 36,
                        "start_line": 25
                    }
                },
                "930": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._factory",
                        "__main__._factory.write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 79,
                        "end_line": 25,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 58,
                                "end_line": 7,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 36,
                                        "end_line": 26,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                                        },
                                        "start_col": 30,
                                        "start_line": 26
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 43,
                                "start_line": 7
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 64,
                        "start_line": 25
                    }
                },
                "931": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._factory",
                        "__main__._factory.write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 36,
                        "end_line": 26,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                        },
                        "start_col": 30,
                        "start_line": 26
                    }
                },
                "933": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._factory",
                        "__main__._factory.write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 25,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 38,
                                "end_line": 370,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 80,
                                        "end_line": 27,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                                        },
                                        "start_col": 9,
                                        "start_line": 27
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 20,
                                "start_line": 370
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 16,
                        "start_line": 25
                    }
                },
                "934": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._factory",
                        "__main__._factory.write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 26,
                        "end_line": 26,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 27,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                                },
                                "start_col": 31,
                                "start_line": 27
                            },
                            "While expanding the reference 'storage_addr' in:"
                        ],
                        "start_col": 14,
                        "start_line": 26
                    }
                },
                "935": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._factory",
                        "__main__._factory.write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 79,
                        "end_line": 27,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                        },
                        "start_col": 55,
                        "start_line": 27
                    }
                },
                "936": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._factory",
                        "__main__._factory.write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 80,
                        "end_line": 27,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                        },
                        "start_col": 9,
                        "start_line": 27
                    }
                },
                "938": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._factory",
                        "__main__._factory.write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 7,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 26,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 62,
                                        "end_line": 21,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 28,
                                                "input_file": {
                                                    "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                                                },
                                                "start_col": 9,
                                                "start_line": 28
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 36,
                                        "start_line": 21
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 30,
                                "start_line": 26
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 7
                    }
                },
                "939": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._factory",
                        "__main__._factory.write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 58,
                        "end_line": 7,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 26,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 79,
                                        "end_line": 21,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 28,
                                                "input_file": {
                                                    "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                                                },
                                                "start_col": 9,
                                                "start_line": 28
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 64,
                                        "start_line": 21
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 30,
                                "start_line": 26
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 43,
                        "start_line": 7
                    }
                },
                "940": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._factory",
                        "__main__._factory.write"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 19,
                        "end_line": 28,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/impl.cairo"
                        },
                        "start_col": 9,
                        "start_line": 28
                    }
                },
                "941": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 96,
                        "end_line": 78,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 32,
                                "end_line": 80,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 25,
                                "start_line": 80
                            },
                            "While expanding the reference 'factory' in:"
                        ],
                        "start_col": 83,
                        "start_line": 78
                    }
                },
                "942": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 80,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 80
                    }
                },
                "944": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 36,
                        "end_line": 78,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 21,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 28,
                                        "end_line": 82,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 82
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 16,
                                "start_line": 21
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 18,
                        "start_line": 78
                    }
                },
                "945": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 64,
                        "end_line": 78,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 62,
                                "end_line": 21,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 28,
                                        "end_line": 82,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 82
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 36,
                                "start_line": 21
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 38,
                        "start_line": 78
                    }
                },
                "946": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 81,
                        "end_line": 78,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 79,
                                "end_line": 21,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 28,
                                        "end_line": 82,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 82
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 64,
                                "start_line": 21
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 66,
                        "start_line": 78
                    }
                },
                "947": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 96,
                        "end_line": 78,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 27,
                                "end_line": 82,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 20,
                                "start_line": 82
                            },
                            "While expanding the reference 'factory' in:"
                        ],
                        "start_col": 83,
                        "start_line": 78
                    }
                },
                "948": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 82,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 82
                    }
                },
                "950": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 115,
                        "end_line": 78,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 83,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 23,
                                "start_line": 83
                            },
                            "While expanding the reference 'proxy_admin' in:"
                        ],
                        "start_col": 98,
                        "start_line": 78
                    }
                },
                "951": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 83,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 83
                    }
                },
                "953": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 84,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 84
                    }
                },
                "954": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/3f2af33c875170cc67ae2f432d9bbef7059830e387aaef0a4e6fb0dbf7fc2331.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 115,
                                "end_line": 78,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 45,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/c31620b02d4d706f0542c989b2aadc01b0981d1f6a5933a8fe4937ace3d70d92.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 17,
                                                "end_line": 78,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 57,
                                                        "end_line": 1,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/arg_processor/01cba52f8515996bb9d7070bde81ff39281d096d7024a558efcba6e1fd2402cf.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 17,
                                                                "end_line": 78,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 6,
                                                                "start_line": 78
                                                            },
                                                            "While handling calldata of"
                                                        ],
                                                        "start_col": 35,
                                                        "start_line": 1
                                                    },
                                                    "While expanding the reference '__calldata_actual_size' in:"
                                                ],
                                                "start_col": 6,
                                                "start_line": 78
                                            },
                                            "While handling calldata of"
                                        ],
                                        "start_col": 31,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_ptr' in:"
                                ],
                                "start_col": 98,
                                "start_line": 78
                            },
                            "While handling calldata argument 'proxy_admin'"
                        ],
                        "start_col": 22,
                        "start_line": 2
                    }
                },
                "956": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 58,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/01cba52f8515996bb9d7070bde81ff39281d096d7024a558efcba6e1fd2402cf.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 78,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 78
                            },
                            "While handling calldata of"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "957": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 64,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/initializer/b2c52ca2d2a8fc8791a983086d8716c5eacd0c3d62934914d2286f84b98ff4cb.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 78,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 55,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/initializer/846f3ef64610338b47cbfae6677962f7b1742601466d51d025b5a0e0c571f866.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 17,
                                                "end_line": 78,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 78
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 44,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 18,
                                "start_line": 78
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 19,
                        "start_line": 1
                    }
                },
                "958": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 110,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/initializer/9684a85e93c782014ca14293edea4eb2502039a5a7b6538ecd39c56faaf12529.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 64,
                                "end_line": 78,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 82,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/initializer/846f3ef64610338b47cbfae6677962f7b1742601466d51d025b5a0e0c571f866.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 17,
                                                "end_line": 78,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 78
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 70,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 38,
                                "start_line": 78
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 20,
                        "start_line": 1
                    }
                },
                "959": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/initializer/741ea357d6336b0bed7bf0472425acd0311d543883b803388880e60a232040c7.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 81,
                                "end_line": 78,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 115,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/initializer/846f3ef64610338b47cbfae6677962f7b1742601466d51d025b5a0e0c571f866.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 17,
                                                "end_line": 78,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 78
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 100,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 66,
                                "start_line": 78
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 23,
                        "start_line": 1
                    }
                },
                "960": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 46,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/f8e22acef04eaa1e1cd12a828bbdd24c5d7032751d174d946b29c2fd9059f4cb.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 96,
                                "end_line": 78,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 147,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/initializer/846f3ef64610338b47cbfae6677962f7b1742601466d51d025b5a0e0c571f866.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 17,
                                                "end_line": 78,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 78
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 125,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_factory' in:"
                                ],
                                "start_col": 83,
                                "start_line": 78
                            },
                            "While handling calldata argument 'factory'"
                        ],
                        "start_col": 30,
                        "start_line": 1
                    }
                },
                "961": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 50,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/3f2af33c875170cc67ae2f432d9bbef7059830e387aaef0a4e6fb0dbf7fc2331.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 115,
                                "end_line": 78,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 187,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/initializer/846f3ef64610338b47cbfae6677962f7b1742601466d51d025b5a0e0c571f866.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 17,
                                                "end_line": 78,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 78
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 161,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_proxy_admin' in:"
                                ],
                                "start_col": 98,
                                "start_line": 78
                            },
                            "While handling calldata argument 'proxy_admin'"
                        ],
                        "start_col": 34,
                        "start_line": 1
                    }
                },
                "962": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 17,
                        "end_line": 78,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 6,
                        "start_line": 78
                    }
                },
                "964": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 34,
                                "end_line": 2,
                                "input_file": {
                                    "filename": "autogen/starknet/external/initializer/846f3ef64610338b47cbfae6677962f7b1742601466d51d025b5a0e0c571f866.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 17,
                                        "end_line": 78,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 6,
                                        "start_line": 78
                                    },
                                    "While constructing the external wrapper for:"
                                ],
                                "start_col": 1,
                                "start_line": 2
                            },
                            "n_prefix_newlines": 0
                        }
                    ],
                    "inst": {
                        "end_col": 24,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/external/initializer/846f3ef64610338b47cbfae6677962f7b1742601466d51d025b5a0e0c571f866.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 78,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 78
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 1,
                        "start_line": 3
                    }
                },
                "966": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 55,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/initializer/846f3ef64610338b47cbfae6677962f7b1742601466d51d025b5a0e0c571f866.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 78,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 20,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/initializer/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 17,
                                                "end_line": 78,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 78
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 9,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 78
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 44,
                        "start_line": 1
                    }
                },
                "967": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/initializer/846f3ef64610338b47cbfae6677962f7b1742601466d51d025b5a0e0c571f866.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 78,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 33,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/initializer/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 17,
                                                "end_line": 78,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 78
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 21,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 78
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 70,
                        "start_line": 1
                    }
                },
                "968": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 115,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/initializer/846f3ef64610338b47cbfae6677962f7b1742601466d51d025b5a0e0c571f866.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 78,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 49,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/initializer/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 17,
                                                "end_line": 78,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 78
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 34,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 78
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 100,
                        "start_line": 1
                    }
                },
                "969": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/external/initializer/846f3ef64610338b47cbfae6677962f7b1742601466d51d025b5a0e0c571f866.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 78,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 62,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/initializer/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 17,
                                                "end_line": 78,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 78
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 50,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'retdata_size' in:"
                                ],
                                "start_col": 6,
                                "start_line": 78
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 20,
                        "start_line": 4
                    }
                },
                "971": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 16,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/external/initializer/846f3ef64610338b47cbfae6677962f7b1742601466d51d025b5a0e0c571f866.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 78,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 70,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/initializer/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 17,
                                                "end_line": 78,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 78
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 63,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'retdata' in:"
                                ],
                                "start_col": 6,
                                "start_line": 78
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 9,
                        "start_line": 3
                    }
                },
                "972": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.initializer"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/initializer/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 78,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 78
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "973": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.factory"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 94,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 33,
                                "end_line": 13,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 36,
                                        "end_line": 95,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 21,
                                        "start_line": 95
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 15,
                                "start_line": 13
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 14,
                        "start_line": 94
                    }
                },
                "974": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.factory"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 60,
                        "end_line": 94,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 61,
                                "end_line": 13,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 36,
                                        "end_line": 95,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 21,
                                        "start_line": 95
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 35,
                                "start_line": 13
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 34,
                        "start_line": 94
                    }
                },
                "975": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.factory"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 77,
                        "end_line": 94,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 78,
                                "end_line": 13,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 36,
                                        "end_line": 95,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 21,
                                        "start_line": 95
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 63,
                                "start_line": 13
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 62,
                        "start_line": 94
                    }
                },
                "976": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.factory"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 36,
                        "end_line": 95,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 21,
                        "start_line": 95
                    }
                },
                "978": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.factory"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 96,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 96
                    }
                },
                "979": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.factory_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 38,
                                "end_line": 3,
                                "input_file": {
                                    "filename": "autogen/starknet/external/return/factory/4c6a74b9ab2b31333825257075a9c8364744c6f9f62af0f6b74f82630db79ea9.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 13,
                                        "end_line": 94,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 6,
                                        "start_line": 94
                                    },
                                    "While handling return value of"
                                ],
                                "start_col": 5,
                                "start_line": 3
                            },
                            "n_prefix_newlines": 0
                        }
                    ],
                    "inst": {
                        "end_col": 18,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/factory/4c6a74b9ab2b31333825257075a9c8364744c6f9f62af0f6b74f82630db79ea9.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 13,
                                "end_line": 94,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 94
                            },
                            "While handling return value of"
                        ],
                        "start_col": 5,
                        "start_line": 4
                    }
                },
                "981": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.factory_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 49,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/10f4ffaf96982a2fff2ff72dc2d3b1a8878257148aab4051a8f2ef7f16687f01.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 98,
                                "end_line": 94,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 85,
                                "start_line": 94
                            },
                            "While handling return value 'address'"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "982": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.factory_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/10f4ffaf96982a2fff2ff72dc2d3b1a8878257148aab4051a8f2ef7f16687f01.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 98,
                                "end_line": 94,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 36,
                                        "end_line": 11,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/return/factory/4c6a74b9ab2b31333825257075a9c8364744c6f9f62af0f6b74f82630db79ea9.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 13,
                                                "end_line": 94,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 94
                                            },
                                            "While handling return value of"
                                        ],
                                        "start_col": 18,
                                        "start_line": 11
                                    },
                                    "While expanding the reference '__return_value_ptr' in:"
                                ],
                                "start_col": 85,
                                "start_line": 94
                            },
                            "While handling return value 'address'"
                        ],
                        "start_col": 26,
                        "start_line": 2
                    }
                },
                "984": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.factory_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 71,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/factory/4c6a74b9ab2b31333825257075a9c8364744c6f9f62af0f6b74f82630db79ea9.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 13,
                                "end_line": 94,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 40,
                                        "end_line": 10,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/return/factory/4c6a74b9ab2b31333825257075a9c8364744c6f9f62af0f6b74f82630db79ea9.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 13,
                                                "end_line": 94,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 94
                                            },
                                            "While handling return value of"
                                        ],
                                        "start_col": 25,
                                        "start_line": 10
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 94
                            },
                            "While handling return value of"
                        ],
                        "start_col": 56,
                        "start_line": 1
                    }
                },
                "985": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.factory_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 63,
                        "end_line": 11,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/factory/4c6a74b9ab2b31333825257075a9c8364744c6f9f62af0f6b74f82630db79ea9.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 13,
                                "end_line": 94,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 94
                            },
                            "While handling return value of"
                        ],
                        "start_col": 18,
                        "start_line": 11
                    }
                },
                "986": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.factory_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/factory/4c6a74b9ab2b31333825257075a9c8364744c6f9f62af0f6b74f82630db79ea9.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 13,
                                "end_line": 94,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 38,
                                        "end_line": 12,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/return/factory/4c6a74b9ab2b31333825257075a9c8364744c6f9f62af0f6b74f82630db79ea9.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 13,
                                                "end_line": 94,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 94
                                            },
                                            "While handling return value of"
                                        ],
                                        "start_col": 14,
                                        "start_line": 12
                                    },
                                    "While expanding the reference '__return_value_ptr_start' in:"
                                ],
                                "start_col": 6,
                                "start_line": 94
                            },
                            "While handling return value of"
                        ],
                        "start_col": 11,
                        "start_line": 5
                    }
                },
                "987": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.factory_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 12,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/factory/4c6a74b9ab2b31333825257075a9c8364744c6f9f62af0f6b74f82630db79ea9.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 13,
                                "end_line": 94,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 94
                            },
                            "While handling return value of"
                        ],
                        "start_col": 5,
                        "start_line": 9
                    }
                },
                "988": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.factory"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 58,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/01cba52f8515996bb9d7070bde81ff39281d096d7024a558efcba6e1fd2402cf.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 13,
                                "end_line": 94,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 94
                            },
                            "While handling calldata of"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "989": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.factory"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 64,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/factory/b2c52ca2d2a8fc8791a983086d8716c5eacd0c3d62934914d2286f84b98ff4cb.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 32,
                                "end_line": 94,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 55,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/factory/27c19248f9d5737b8d0b8eb8d777316b4ad5ccb8330cd5903141b353f0f45097.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 13,
                                                "end_line": 94,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 94
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 44,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 14,
                                "start_line": 94
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 19,
                        "start_line": 1
                    }
                },
                "990": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.factory"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 110,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/factory/9684a85e93c782014ca14293edea4eb2502039a5a7b6538ecd39c56faaf12529.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 60,
                                "end_line": 94,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 82,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/factory/27c19248f9d5737b8d0b8eb8d777316b4ad5ccb8330cd5903141b353f0f45097.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 13,
                                                "end_line": 94,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 94
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 70,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 34,
                                "start_line": 94
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 20,
                        "start_line": 1
                    }
                },
                "991": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.factory"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/factory/741ea357d6336b0bed7bf0472425acd0311d543883b803388880e60a232040c7.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 77,
                                "end_line": 94,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 115,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/factory/27c19248f9d5737b8d0b8eb8d777316b4ad5ccb8330cd5903141b353f0f45097.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 13,
                                                "end_line": 94,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 94
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 100,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 62,
                                "start_line": 94
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 23,
                        "start_line": 1
                    }
                },
                "992": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.factory"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 13,
                        "end_line": 94,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 6,
                        "start_line": 94
                    }
                },
                "994": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.factory"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 115,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/factory/27c19248f9d5737b8d0b8eb8d777316b4ad5ccb8330cd5903141b353f0f45097.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 13,
                                "end_line": 94,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 96,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/factory/27c19248f9d5737b8d0b8eb8d777316b4ad5ccb8330cd5903141b353f0f45097.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 13,
                                                "end_line": 94,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 94
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 81,
                                        "start_line": 2
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 94
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 100,
                        "start_line": 1
                    }
                },
                "995": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.factory"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 97,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/factory/27c19248f9d5737b8d0b8eb8d777316b4ad5ccb8330cd5903141b353f0f45097.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 13,
                                "end_line": 94,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 94
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 48,
                        "start_line": 2
                    }
                },
                "997": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.factory"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 55,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/factory/27c19248f9d5737b8d0b8eb8d777316b4ad5ccb8330cd5903141b353f0f45097.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 13,
                                "end_line": 94,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 20,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/factory/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 13,
                                                "end_line": 94,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 94
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 9,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 94
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 44,
                        "start_line": 1
                    }
                },
                "998": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.factory"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/factory/27c19248f9d5737b8d0b8eb8d777316b4ad5ccb8330cd5903141b353f0f45097.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 13,
                                "end_line": 94,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 33,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/factory/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 13,
                                                "end_line": 94,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 94
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 21,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 94
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 70,
                        "start_line": 1
                    }
                },
                "999": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.factory"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/factory/27c19248f9d5737b8d0b8eb8d777316b4ad5ccb8330cd5903141b353f0f45097.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 13,
                                "end_line": 94,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 49,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/factory/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 13,
                                                "end_line": 94,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 94
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 34,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 94
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 6,
                        "start_line": 2
                    }
                },
                "1000": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.factory"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/factory/27c19248f9d5737b8d0b8eb8d777316b4ad5ccb8330cd5903141b353f0f45097.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 13,
                                "end_line": 94,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 62,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/factory/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 13,
                                                "end_line": 94,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 94
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 50,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'retdata_size' in:"
                                ],
                                "start_col": 6,
                                "start_line": 94
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 23,
                        "start_line": 2
                    }
                },
                "1001": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.factory"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 44,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/factory/27c19248f9d5737b8d0b8eb8d777316b4ad5ccb8330cd5903141b353f0f45097.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 13,
                                "end_line": 94,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 70,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/factory/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 13,
                                                "end_line": 94,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 94
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 63,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'retdata' in:"
                                ],
                                "start_col": 6,
                                "start_line": 94
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 37,
                        "start_line": 2
                    }
                },
                "1002": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.factory"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/factory/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 13,
                                "end_line": 94,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 94
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "1003": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 36,
                        "end_line": 105,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 37,
                                "end_line": 440,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 40,
                                        "end_line": 108,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 12,
                                        "start_line": 108
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 19,
                                "start_line": 440
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 18,
                        "start_line": 105
                    }
                },
                "1004": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 64,
                        "end_line": 105,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 65,
                                "end_line": 440,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 40,
                                        "end_line": 108,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 12,
                                        "start_line": 108
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 39,
                                "start_line": 440
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 38,
                        "start_line": 105
                    }
                },
                "1005": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 81,
                        "end_line": 105,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 82,
                                "end_line": 440,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 40,
                                        "end_line": 108,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 12,
                                        "start_line": 108
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 67,
                                "start_line": 440
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 66,
                        "start_line": 105
                    }
                },
                "1006": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 17,
                        "end_line": 106,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 31,
                                "end_line": 108,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 25,
                                "start_line": 108
                            },
                            "While expanding the reference 'tokenA' in:"
                        ],
                        "start_col": 5,
                        "start_line": 106
                    }
                },
                "1007": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 31,
                        "end_line": 106,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 39,
                                "end_line": 108,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 33,
                                "start_line": 108
                            },
                            "While expanding the reference 'tokenB' in:"
                        ],
                        "start_col": 19,
                        "start_line": 106
                    }
                },
                "1008": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 108,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 12,
                        "start_line": 108
                    }
                },
                "1010": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 108,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 108
                    }
                },
                "1011": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.sort_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 38,
                                "end_line": 3,
                                "input_file": {
                                    "filename": "autogen/starknet/external/return/sort_tokens/8f4b343957d504181762c667afb98181e707e9594c8819a901ce9897c26d6807.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 17,
                                        "end_line": 105,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 6,
                                        "start_line": 105
                                    },
                                    "While handling return value of"
                                ],
                                "start_col": 5,
                                "start_line": 3
                            },
                            "n_prefix_newlines": 0
                        }
                    ],
                    "inst": {
                        "end_col": 18,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/sort_tokens/8f4b343957d504181762c667afb98181e707e9594c8819a901ce9897c26d6807.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 105,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 105
                            },
                            "While handling return value of"
                        ],
                        "start_col": 5,
                        "start_line": 4
                    }
                },
                "1013": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.sort_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/d96c59fe9d07937fbeed76dc85f00c78962225e82bc2df89f7dc0a139faf5789.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 107,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 7,
                                "start_line": 107
                            },
                            "While handling return value 'token0'"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "1014": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.sort_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8fc2d8ea495404974277495f157efdc441bd2985352b9f48e4f31d6c3ec03f54.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 33,
                                "end_line": 107,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 21,
                                "start_line": 107
                            },
                            "While handling return value 'token1'"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "1015": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.sort_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8fc2d8ea495404974277495f157efdc441bd2985352b9f48e4f31d6c3ec03f54.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 33,
                                "end_line": 107,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 36,
                                        "end_line": 11,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/return/sort_tokens/8f4b343957d504181762c667afb98181e707e9594c8819a901ce9897c26d6807.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 17,
                                                "end_line": 105,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 105
                                            },
                                            "While handling return value of"
                                        ],
                                        "start_col": 18,
                                        "start_line": 11
                                    },
                                    "While expanding the reference '__return_value_ptr' in:"
                                ],
                                "start_col": 21,
                                "start_line": 107
                            },
                            "While handling return value 'token1'"
                        ],
                        "start_col": 26,
                        "start_line": 2
                    }
                },
                "1017": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.sort_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 88,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/sort_tokens/8f4b343957d504181762c667afb98181e707e9594c8819a901ce9897c26d6807.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 105,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 40,
                                        "end_line": 10,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/return/sort_tokens/8f4b343957d504181762c667afb98181e707e9594c8819a901ce9897c26d6807.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 17,
                                                "end_line": 105,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 105
                                            },
                                            "While handling return value of"
                                        ],
                                        "start_col": 25,
                                        "start_line": 10
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 105
                            },
                            "While handling return value of"
                        ],
                        "start_col": 73,
                        "start_line": 1
                    }
                },
                "1018": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.sort_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 63,
                        "end_line": 11,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/sort_tokens/8f4b343957d504181762c667afb98181e707e9594c8819a901ce9897c26d6807.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 105,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 105
                            },
                            "While handling return value of"
                        ],
                        "start_col": 18,
                        "start_line": 11
                    }
                },
                "1019": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.sort_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/sort_tokens/8f4b343957d504181762c667afb98181e707e9594c8819a901ce9897c26d6807.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 105,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 38,
                                        "end_line": 12,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/return/sort_tokens/8f4b343957d504181762c667afb98181e707e9594c8819a901ce9897c26d6807.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 17,
                                                "end_line": 105,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 105
                                            },
                                            "While handling return value of"
                                        ],
                                        "start_col": 14,
                                        "start_line": 12
                                    },
                                    "While expanding the reference '__return_value_ptr_start' in:"
                                ],
                                "start_col": 6,
                                "start_line": 105
                            },
                            "While handling return value of"
                        ],
                        "start_col": 11,
                        "start_line": 5
                    }
                },
                "1020": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.sort_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 12,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/sort_tokens/8f4b343957d504181762c667afb98181e707e9594c8819a901ce9897c26d6807.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 105,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 105
                            },
                            "While handling return value of"
                        ],
                        "start_col": 5,
                        "start_line": 9
                    }
                },
                "1021": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/f592e43c96a0e5d88593ef440a75312f8beb20e2d1d087f2f18887daf8570e62.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 31,
                                "end_line": 106,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 45,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/c31620b02d4d706f0542c989b2aadc01b0981d1f6a5933a8fe4937ace3d70d92.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 17,
                                                "end_line": 105,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 57,
                                                        "end_line": 1,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/arg_processor/01cba52f8515996bb9d7070bde81ff39281d096d7024a558efcba6e1fd2402cf.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 17,
                                                                "end_line": 105,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 6,
                                                                "start_line": 105
                                                            },
                                                            "While handling calldata of"
                                                        ],
                                                        "start_col": 35,
                                                        "start_line": 1
                                                    },
                                                    "While expanding the reference '__calldata_actual_size' in:"
                                                ],
                                                "start_col": 6,
                                                "start_line": 105
                                            },
                                            "While handling calldata of"
                                        ],
                                        "start_col": 31,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_ptr' in:"
                                ],
                                "start_col": 19,
                                "start_line": 106
                            },
                            "While handling calldata argument 'tokenB'"
                        ],
                        "start_col": 22,
                        "start_line": 2
                    }
                },
                "1023": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 58,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/01cba52f8515996bb9d7070bde81ff39281d096d7024a558efcba6e1fd2402cf.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 105,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 105
                            },
                            "While handling calldata of"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "1024": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 64,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/sort_tokens/b2c52ca2d2a8fc8791a983086d8716c5eacd0c3d62934914d2286f84b98ff4cb.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 105,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 55,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/sort_tokens/ee47c9ba2c0dfcdd8291f626717e8662c253850c770a2709f1cc0721c88ecac4.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 17,
                                                "end_line": 105,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 105
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 44,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 18,
                                "start_line": 105
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 19,
                        "start_line": 1
                    }
                },
                "1025": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 110,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/sort_tokens/9684a85e93c782014ca14293edea4eb2502039a5a7b6538ecd39c56faaf12529.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 64,
                                "end_line": 105,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 82,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/sort_tokens/ee47c9ba2c0dfcdd8291f626717e8662c253850c770a2709f1cc0721c88ecac4.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 17,
                                                "end_line": 105,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 105
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 70,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 38,
                                "start_line": 105
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 20,
                        "start_line": 1
                    }
                },
                "1026": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/sort_tokens/741ea357d6336b0bed7bf0472425acd0311d543883b803388880e60a232040c7.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 81,
                                "end_line": 105,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 115,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/sort_tokens/ee47c9ba2c0dfcdd8291f626717e8662c253850c770a2709f1cc0721c88ecac4.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 17,
                                                "end_line": 105,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 105
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 100,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 66,
                                "start_line": 105
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 23,
                        "start_line": 1
                    }
                },
                "1027": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 45,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/5ec6d3da377064fcca5c6ce7ac91ed695c6086c63198cd266d6f1f3f80005101.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 106,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 145,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/sort_tokens/ee47c9ba2c0dfcdd8291f626717e8662c253850c770a2709f1cc0721c88ecac4.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 17,
                                                "end_line": 105,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 105
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 124,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_tokenA' in:"
                                ],
                                "start_col": 5,
                                "start_line": 106
                            },
                            "While handling calldata argument 'tokenA'"
                        ],
                        "start_col": 29,
                        "start_line": 1
                    }
                },
                "1028": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 45,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/f592e43c96a0e5d88593ef440a75312f8beb20e2d1d087f2f18887daf8570e62.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 31,
                                "end_line": 106,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 175,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/sort_tokens/ee47c9ba2c0dfcdd8291f626717e8662c253850c770a2709f1cc0721c88ecac4.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 17,
                                                "end_line": 105,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 105
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 154,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_tokenB' in:"
                                ],
                                "start_col": 19,
                                "start_line": 106
                            },
                            "While handling calldata argument 'tokenB'"
                        ],
                        "start_col": 29,
                        "start_line": 1
                    }
                },
                "1029": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 17,
                        "end_line": 105,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 6,
                        "start_line": 105
                    }
                },
                "1031": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 115,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/sort_tokens/ee47c9ba2c0dfcdd8291f626717e8662c253850c770a2709f1cc0721c88ecac4.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 105,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 100,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/sort_tokens/ee47c9ba2c0dfcdd8291f626717e8662c253850c770a2709f1cc0721c88ecac4.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 17,
                                                "end_line": 105,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 105
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 85,
                                        "start_line": 2
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 105
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 100,
                        "start_line": 1
                    }
                },
                "1032": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 101,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/sort_tokens/ee47c9ba2c0dfcdd8291f626717e8662c253850c770a2709f1cc0721c88ecac4.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 105,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 105
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 48,
                        "start_line": 2
                    }
                },
                "1034": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 55,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/sort_tokens/ee47c9ba2c0dfcdd8291f626717e8662c253850c770a2709f1cc0721c88ecac4.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 105,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 20,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/sort_tokens/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 17,
                                                "end_line": 105,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 105
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 9,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 105
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 44,
                        "start_line": 1
                    }
                },
                "1035": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/sort_tokens/ee47c9ba2c0dfcdd8291f626717e8662c253850c770a2709f1cc0721c88ecac4.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 105,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 33,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/sort_tokens/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 17,
                                                "end_line": 105,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 105
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 21,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 105
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 70,
                        "start_line": 1
                    }
                },
                "1036": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/sort_tokens/ee47c9ba2c0dfcdd8291f626717e8662c253850c770a2709f1cc0721c88ecac4.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 105,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 49,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/sort_tokens/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 17,
                                                "end_line": 105,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 105
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 34,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 105
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 6,
                        "start_line": 2
                    }
                },
                "1037": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/sort_tokens/ee47c9ba2c0dfcdd8291f626717e8662c253850c770a2709f1cc0721c88ecac4.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 105,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 62,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/sort_tokens/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 17,
                                                "end_line": 105,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 105
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 50,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'retdata_size' in:"
                                ],
                                "start_col": 6,
                                "start_line": 105
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 23,
                        "start_line": 2
                    }
                },
                "1038": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 44,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/sort_tokens/ee47c9ba2c0dfcdd8291f626717e8662c253850c770a2709f1cc0721c88ecac4.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 105,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 70,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/sort_tokens/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 17,
                                                "end_line": 105,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 105
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 63,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'retdata' in:"
                                ],
                                "start_col": 6,
                                "start_line": 105
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 37,
                        "start_line": 2
                    }
                },
                "1039": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/sort_tokens/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 105,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 105
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "1040": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 117,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 31,
                                "end_line": 489,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 47,
                                        "end_line": 120,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 12,
                                        "start_line": 120
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 13,
                                "start_line": 489
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 12,
                        "start_line": 117
                    }
                },
                "1041": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 58,
                        "end_line": 117,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 59,
                                "end_line": 489,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 47,
                                        "end_line": 120,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 12,
                                        "start_line": 120
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 33,
                                "start_line": 489
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 32,
                        "start_line": 117
                    }
                },
                "1042": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 75,
                        "end_line": 117,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 76,
                                "end_line": 489,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 47,
                                        "end_line": 120,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 12,
                                        "start_line": 120
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 61,
                                "start_line": 489
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 60,
                        "start_line": 117
                    }
                },
                "1043": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 118,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 26,
                                "end_line": 120,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 19,
                                "start_line": 120
                            },
                            "While expanding the reference 'amountA' in:"
                        ],
                        "start_col": 5,
                        "start_line": 118
                    }
                },
                "1044": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 118,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 26,
                                "end_line": 120,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 19,
                                "start_line": 120
                            },
                            "While expanding the reference 'amountA' in:"
                        ],
                        "start_col": 5,
                        "start_line": 118
                    }
                },
                "1045": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 118,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 120,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 28,
                                "start_line": 120
                            },
                            "While expanding the reference 'reserveA' in:"
                        ],
                        "start_col": 23,
                        "start_line": 118
                    }
                },
                "1046": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 118,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 120,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 28,
                                "start_line": 120
                            },
                            "While expanding the reference 'reserveA' in:"
                        ],
                        "start_col": 23,
                        "start_line": 118
                    }
                },
                "1047": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 59,
                        "end_line": 118,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 46,
                                "end_line": 120,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 38,
                                "start_line": 120
                            },
                            "While expanding the reference 'reserveB' in:"
                        ],
                        "start_col": 42,
                        "start_line": 118
                    }
                },
                "1048": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 59,
                        "end_line": 118,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 46,
                                "end_line": 120,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 38,
                                "start_line": 120
                            },
                            "While expanding the reference 'reserveB' in:"
                        ],
                        "start_col": 42,
                        "start_line": 118
                    }
                },
                "1049": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 47,
                        "end_line": 120,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 12,
                        "start_line": 120
                    }
                },
                "1051": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 120,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 120
                    }
                },
                "1052": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.quote_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 38,
                                "end_line": 3,
                                "input_file": {
                                    "filename": "autogen/starknet/external/return/quote/1c73b71691461623d1362f35ec8adab41dfa1b44cf13997036052a8e40a88507.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 11,
                                        "end_line": 117,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 6,
                                        "start_line": 117
                                    },
                                    "While handling return value of"
                                ],
                                "start_col": 5,
                                "start_line": 3
                            },
                            "n_prefix_newlines": 0
                        }
                    ],
                    "inst": {
                        "end_col": 18,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/quote/1c73b71691461623d1362f35ec8adab41dfa1b44cf13997036052a8e40a88507.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 11,
                                "end_line": 117,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 117
                            },
                            "While handling return value of"
                        ],
                        "start_col": 5,
                        "start_line": 4
                    }
                },
                "1054": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.quote_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 60,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/3d6e81bf1402793cfbd97151fd631ccb7424cd79d2c40557aa18360f58ac0d92.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 23,
                                "end_line": 119,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 7,
                                "start_line": 119
                            },
                            "While handling return value 'amountB'"
                        ],
                        "start_col": 1,
                        "start_line": 3
                    }
                },
                "1055": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.quote_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 60,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/3d6e81bf1402793cfbd97151fd631ccb7424cd79d2c40557aa18360f58ac0d92.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 23,
                                "end_line": 119,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 7,
                                "start_line": 119
                            },
                            "While handling return value 'amountB'"
                        ],
                        "start_col": 1,
                        "start_line": 4
                    }
                },
                "1056": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.quote_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/3d6e81bf1402793cfbd97151fd631ccb7424cd79d2c40557aa18360f58ac0d92.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 23,
                                "end_line": 119,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 36,
                                        "end_line": 11,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/return/quote/1c73b71691461623d1362f35ec8adab41dfa1b44cf13997036052a8e40a88507.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 11,
                                                "end_line": 117,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 117
                                            },
                                            "While handling return value of"
                                        ],
                                        "start_col": 18,
                                        "start_line": 11
                                    },
                                    "While expanding the reference '__return_value_ptr' in:"
                                ],
                                "start_col": 7,
                                "start_line": 119
                            },
                            "While handling return value 'amountB'"
                        ],
                        "start_col": 26,
                        "start_line": 5
                    }
                },
                "1058": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.quote_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 103,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/quote/1c73b71691461623d1362f35ec8adab41dfa1b44cf13997036052a8e40a88507.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 11,
                                "end_line": 117,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 40,
                                        "end_line": 10,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/return/quote/1c73b71691461623d1362f35ec8adab41dfa1b44cf13997036052a8e40a88507.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 11,
                                                "end_line": 117,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 117
                                            },
                                            "While handling return value of"
                                        ],
                                        "start_col": 25,
                                        "start_line": 10
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 117
                            },
                            "While handling return value of"
                        ],
                        "start_col": 88,
                        "start_line": 1
                    }
                },
                "1059": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.quote_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 63,
                        "end_line": 11,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/quote/1c73b71691461623d1362f35ec8adab41dfa1b44cf13997036052a8e40a88507.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 11,
                                "end_line": 117,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 117
                            },
                            "While handling return value of"
                        ],
                        "start_col": 18,
                        "start_line": 11
                    }
                },
                "1060": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.quote_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/quote/1c73b71691461623d1362f35ec8adab41dfa1b44cf13997036052a8e40a88507.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 11,
                                "end_line": 117,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 38,
                                        "end_line": 12,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/return/quote/1c73b71691461623d1362f35ec8adab41dfa1b44cf13997036052a8e40a88507.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 11,
                                                "end_line": 117,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 117
                                            },
                                            "While handling return value of"
                                        ],
                                        "start_col": 14,
                                        "start_line": 12
                                    },
                                    "While expanding the reference '__return_value_ptr_start' in:"
                                ],
                                "start_col": 6,
                                "start_line": 117
                            },
                            "While handling return value of"
                        ],
                        "start_col": 11,
                        "start_line": 5
                    }
                },
                "1061": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.quote_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 12,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/quote/1c73b71691461623d1362f35ec8adab41dfa1b44cf13997036052a8e40a88507.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 11,
                                "end_line": 117,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 117
                            },
                            "While handling return value of"
                        ],
                        "start_col": 5,
                        "start_line": 9
                    }
                },
                "1062": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/00ba9f18f64c439d93f764476ea480f4a827df0fac1851a969a8530cf46ee8e6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 59,
                                "end_line": 118,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 45,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/c31620b02d4d706f0542c989b2aadc01b0981d1f6a5933a8fe4937ace3d70d92.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 11,
                                                "end_line": 117,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 57,
                                                        "end_line": 1,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/arg_processor/01cba52f8515996bb9d7070bde81ff39281d096d7024a558efcba6e1fd2402cf.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 11,
                                                                "end_line": 117,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 6,
                                                                "start_line": 117
                                                            },
                                                            "While handling calldata of"
                                                        ],
                                                        "start_col": 35,
                                                        "start_line": 1
                                                    },
                                                    "While expanding the reference '__calldata_actual_size' in:"
                                                ],
                                                "start_col": 6,
                                                "start_line": 117
                                            },
                                            "While handling calldata of"
                                        ],
                                        "start_col": 31,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_ptr' in:"
                                ],
                                "start_col": 42,
                                "start_line": 118
                            },
                            "While handling calldata argument 'reserveB'"
                        ],
                        "start_col": 22,
                        "start_line": 3
                    }
                },
                "1064": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 58,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/01cba52f8515996bb9d7070bde81ff39281d096d7024a558efcba6e1fd2402cf.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 11,
                                "end_line": 117,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 117
                            },
                            "While handling calldata of"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "1065": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 64,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/quote/b2c52ca2d2a8fc8791a983086d8716c5eacd0c3d62934914d2286f84b98ff4cb.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 30,
                                "end_line": 117,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 55,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/quote/41599575d8946c0eefdcac23f65fe31201840e403e8c6d50e378dd8d9d6a904b.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 11,
                                                "end_line": 117,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 117
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 44,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 12,
                                "start_line": 117
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 19,
                        "start_line": 1
                    }
                },
                "1066": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 110,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/quote/9684a85e93c782014ca14293edea4eb2502039a5a7b6538ecd39c56faaf12529.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 58,
                                "end_line": 117,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 82,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/quote/41599575d8946c0eefdcac23f65fe31201840e403e8c6d50e378dd8d9d6a904b.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 11,
                                                "end_line": 117,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 117
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 70,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 32,
                                "start_line": 117
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 20,
                        "start_line": 1
                    }
                },
                "1067": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/quote/741ea357d6336b0bed7bf0472425acd0311d543883b803388880e60a232040c7.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 75,
                                "end_line": 117,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 115,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/quote/41599575d8946c0eefdcac23f65fe31201840e403e8c6d50e378dd8d9d6a904b.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 11,
                                                "end_line": 117,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 117
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 100,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 60,
                                "start_line": 117
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 23,
                        "start_line": 1
                    }
                },
                "1068": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/b534c21bfdbb6243e78caa5328497edbe6b5537b9fcdefafa8a58f0f9fc425ea.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 118,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 147,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/quote/41599575d8946c0eefdcac23f65fe31201840e403e8c6d50e378dd8d9d6a904b.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 11,
                                                "end_line": 117,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 117
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 125,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_amountA' in:"
                                ],
                                "start_col": 5,
                                "start_line": 118
                            },
                            "While handling calldata argument 'amountA'"
                        ],
                        "start_col": 30,
                        "start_line": 1
                    }
                },
                "1069": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/b534c21bfdbb6243e78caa5328497edbe6b5537b9fcdefafa8a58f0f9fc425ea.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 118,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 147,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/quote/41599575d8946c0eefdcac23f65fe31201840e403e8c6d50e378dd8d9d6a904b.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 11,
                                                "end_line": 117,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 117
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 125,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_amountA' in:"
                                ],
                                "start_col": 5,
                                "start_line": 118
                            },
                            "While handling calldata argument 'amountA'"
                        ],
                        "start_col": 30,
                        "start_line": 1
                    }
                },
                "1070": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/0e50dc7e3464c61dfc8bf6e8438814b90aa1f118ccaf9ae72a2f3ad40c8e2ab6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 40,
                                "end_line": 118,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 181,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/quote/41599575d8946c0eefdcac23f65fe31201840e403e8c6d50e378dd8d9d6a904b.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 11,
                                                "end_line": 117,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 117
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 158,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_reserveA' in:"
                                ],
                                "start_col": 23,
                                "start_line": 118
                            },
                            "While handling calldata argument 'reserveA'"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "1071": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/0e50dc7e3464c61dfc8bf6e8438814b90aa1f118ccaf9ae72a2f3ad40c8e2ab6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 40,
                                "end_line": 118,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 181,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/quote/41599575d8946c0eefdcac23f65fe31201840e403e8c6d50e378dd8d9d6a904b.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 11,
                                                "end_line": 117,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 117
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 158,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_reserveA' in:"
                                ],
                                "start_col": 23,
                                "start_line": 118
                            },
                            "While handling calldata argument 'reserveA'"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "1072": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/00ba9f18f64c439d93f764476ea480f4a827df0fac1851a969a8530cf46ee8e6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 59,
                                "end_line": 118,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 215,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/quote/41599575d8946c0eefdcac23f65fe31201840e403e8c6d50e378dd8d9d6a904b.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 11,
                                                "end_line": 117,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 117
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 192,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_reserveB' in:"
                                ],
                                "start_col": 42,
                                "start_line": 118
                            },
                            "While handling calldata argument 'reserveB'"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "1073": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/00ba9f18f64c439d93f764476ea480f4a827df0fac1851a969a8530cf46ee8e6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 59,
                                "end_line": 118,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 215,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/quote/41599575d8946c0eefdcac23f65fe31201840e403e8c6d50e378dd8d9d6a904b.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 11,
                                                "end_line": 117,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 117
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 192,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_reserveB' in:"
                                ],
                                "start_col": 42,
                                "start_line": 118
                            },
                            "While handling calldata argument 'reserveB'"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "1074": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 11,
                        "end_line": 117,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 6,
                        "start_line": 117
                    }
                },
                "1076": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 115,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/quote/41599575d8946c0eefdcac23f65fe31201840e403e8c6d50e378dd8d9d6a904b.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 11,
                                "end_line": 117,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 94,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/quote/41599575d8946c0eefdcac23f65fe31201840e403e8c6d50e378dd8d9d6a904b.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 11,
                                                "end_line": 117,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 117
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 79,
                                        "start_line": 2
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 117
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 100,
                        "start_line": 1
                    }
                },
                "1077": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 95,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/quote/41599575d8946c0eefdcac23f65fe31201840e403e8c6d50e378dd8d9d6a904b.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 11,
                                "end_line": 117,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 117
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 48,
                        "start_line": 2
                    }
                },
                "1079": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 55,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/quote/41599575d8946c0eefdcac23f65fe31201840e403e8c6d50e378dd8d9d6a904b.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 11,
                                "end_line": 117,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 20,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/quote/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 11,
                                                "end_line": 117,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 117
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 9,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 117
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 44,
                        "start_line": 1
                    }
                },
                "1080": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/quote/41599575d8946c0eefdcac23f65fe31201840e403e8c6d50e378dd8d9d6a904b.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 11,
                                "end_line": 117,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 33,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/quote/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 11,
                                                "end_line": 117,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 117
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 21,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 117
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 70,
                        "start_line": 1
                    }
                },
                "1081": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/quote/41599575d8946c0eefdcac23f65fe31201840e403e8c6d50e378dd8d9d6a904b.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 11,
                                "end_line": 117,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 49,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/quote/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 11,
                                                "end_line": 117,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 117
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 34,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 117
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 6,
                        "start_line": 2
                    }
                },
                "1082": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/quote/41599575d8946c0eefdcac23f65fe31201840e403e8c6d50e378dd8d9d6a904b.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 11,
                                "end_line": 117,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 62,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/quote/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 11,
                                                "end_line": 117,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 117
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 50,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'retdata_size' in:"
                                ],
                                "start_col": 6,
                                "start_line": 117
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 23,
                        "start_line": 2
                    }
                },
                "1083": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 44,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/quote/41599575d8946c0eefdcac23f65fe31201840e403e8c6d50e378dd8d9d6a904b.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 11,
                                "end_line": 117,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 70,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/quote/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 11,
                                                "end_line": 117,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 117
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 63,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'retdata' in:"
                                ],
                                "start_col": 6,
                                "start_line": 117
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 37,
                        "start_line": 2
                    }
                },
                "1084": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/quote/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 11,
                                "end_line": 117,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 117
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "1085": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 39,
                        "end_line": 129,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 40,
                                "end_line": 509,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 60,
                                        "end_line": 132,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 12,
                                        "start_line": 132
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 22,
                                "start_line": 509
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 21,
                        "start_line": 129
                    }
                },
                "1086": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 129,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 68,
                                "end_line": 509,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 60,
                                        "end_line": 132,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 12,
                                        "start_line": 132
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 42,
                                "start_line": 509
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 41,
                        "start_line": 129
                    }
                },
                "1087": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 84,
                        "end_line": 129,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 85,
                                "end_line": 509,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 60,
                                        "end_line": 132,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 12,
                                        "start_line": 132
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 70,
                                "start_line": 509
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 69,
                        "start_line": 129
                    }
                },
                "1088": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 130,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 132,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 28,
                                "start_line": 132
                            },
                            "While expanding the reference 'amountIn' in:"
                        ],
                        "start_col": 5,
                        "start_line": 130
                    }
                },
                "1089": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 130,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 132,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 28,
                                "start_line": 132
                            },
                            "While expanding the reference 'amountIn' in:"
                        ],
                        "start_col": 5,
                        "start_line": 130
                    }
                },
                "1090": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 42,
                        "end_line": 130,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 47,
                                "end_line": 132,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 38,
                                "start_line": 132
                            },
                            "While expanding the reference 'reserveIn' in:"
                        ],
                        "start_col": 24,
                        "start_line": 130
                    }
                },
                "1091": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 42,
                        "end_line": 130,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 47,
                                "end_line": 132,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 38,
                                "start_line": 132
                            },
                            "While expanding the reference 'reserveIn' in:"
                        ],
                        "start_col": 24,
                        "start_line": 130
                    }
                },
                "1092": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 63,
                        "end_line": 130,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 59,
                                "end_line": 132,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 49,
                                "start_line": 132
                            },
                            "While expanding the reference 'reserveOut' in:"
                        ],
                        "start_col": 44,
                        "start_line": 130
                    }
                },
                "1093": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 63,
                        "end_line": 130,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 59,
                                "end_line": 132,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 49,
                                "start_line": 132
                            },
                            "While expanding the reference 'reserveOut' in:"
                        ],
                        "start_col": 44,
                        "start_line": 130
                    }
                },
                "1094": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 60,
                        "end_line": 132,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 12,
                        "start_line": 132
                    }
                },
                "1096": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 132,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 132
                    }
                },
                "1097": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_out_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 38,
                                "end_line": 3,
                                "input_file": {
                                    "filename": "autogen/starknet/external/return/get_amount_out/1feb6aa8f4a51d25b9657ae25a0f05521dc942d6e349f2a67e53697c6535d1b5.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 20,
                                        "end_line": 129,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 6,
                                        "start_line": 129
                                    },
                                    "While handling return value of"
                                ],
                                "start_col": 5,
                                "start_line": 3
                            },
                            "n_prefix_newlines": 0
                        }
                    ],
                    "inst": {
                        "end_col": 18,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/get_amount_out/1feb6aa8f4a51d25b9657ae25a0f05521dc942d6e349f2a67e53697c6535d1b5.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 129,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 129
                            },
                            "While handling return value of"
                        ],
                        "start_col": 5,
                        "start_line": 4
                    }
                },
                "1099": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_out_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 60,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/fad4e10ec44d6e93cb02bb2a04f08ca713e54675fbc84bbd271475389af5f4e6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 25,
                                "end_line": 131,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 7,
                                "start_line": 131
                            },
                            "While handling return value 'amountOut'"
                        ],
                        "start_col": 1,
                        "start_line": 3
                    }
                },
                "1100": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_out_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 60,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/fad4e10ec44d6e93cb02bb2a04f08ca713e54675fbc84bbd271475389af5f4e6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 25,
                                "end_line": 131,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 7,
                                "start_line": 131
                            },
                            "While handling return value 'amountOut'"
                        ],
                        "start_col": 1,
                        "start_line": 4
                    }
                },
                "1101": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_out_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/fad4e10ec44d6e93cb02bb2a04f08ca713e54675fbc84bbd271475389af5f4e6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 25,
                                "end_line": 131,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 36,
                                        "end_line": 11,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/return/get_amount_out/1feb6aa8f4a51d25b9657ae25a0f05521dc942d6e349f2a67e53697c6535d1b5.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 129,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 129
                                            },
                                            "While handling return value of"
                                        ],
                                        "start_col": 18,
                                        "start_line": 11
                                    },
                                    "While expanding the reference '__return_value_ptr' in:"
                                ],
                                "start_col": 7,
                                "start_line": 131
                            },
                            "While handling return value 'amountOut'"
                        ],
                        "start_col": 26,
                        "start_line": 5
                    }
                },
                "1103": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_out_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 114,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/get_amount_out/1feb6aa8f4a51d25b9657ae25a0f05521dc942d6e349f2a67e53697c6535d1b5.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 129,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 40,
                                        "end_line": 10,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/return/get_amount_out/1feb6aa8f4a51d25b9657ae25a0f05521dc942d6e349f2a67e53697c6535d1b5.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 129,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 129
                                            },
                                            "While handling return value of"
                                        ],
                                        "start_col": 25,
                                        "start_line": 10
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 129
                            },
                            "While handling return value of"
                        ],
                        "start_col": 99,
                        "start_line": 1
                    }
                },
                "1104": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_out_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 63,
                        "end_line": 11,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/get_amount_out/1feb6aa8f4a51d25b9657ae25a0f05521dc942d6e349f2a67e53697c6535d1b5.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 129,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 129
                            },
                            "While handling return value of"
                        ],
                        "start_col": 18,
                        "start_line": 11
                    }
                },
                "1105": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_out_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/get_amount_out/1feb6aa8f4a51d25b9657ae25a0f05521dc942d6e349f2a67e53697c6535d1b5.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 129,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 38,
                                        "end_line": 12,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/return/get_amount_out/1feb6aa8f4a51d25b9657ae25a0f05521dc942d6e349f2a67e53697c6535d1b5.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 129,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 129
                                            },
                                            "While handling return value of"
                                        ],
                                        "start_col": 14,
                                        "start_line": 12
                                    },
                                    "While expanding the reference '__return_value_ptr_start' in:"
                                ],
                                "start_col": 6,
                                "start_line": 129
                            },
                            "While handling return value of"
                        ],
                        "start_col": 11,
                        "start_line": 5
                    }
                },
                "1106": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_out_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 12,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/get_amount_out/1feb6aa8f4a51d25b9657ae25a0f05521dc942d6e349f2a67e53697c6535d1b5.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 129,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 129
                            },
                            "While handling return value of"
                        ],
                        "start_col": 5,
                        "start_line": 9
                    }
                },
                "1107": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/822bb83e1247a59010b6591225aa0f16d76cb779d44ca4ac9311332f265a6ded.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 63,
                                "end_line": 130,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 45,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/c31620b02d4d706f0542c989b2aadc01b0981d1f6a5933a8fe4937ace3d70d92.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 129,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 57,
                                                        "end_line": 1,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/arg_processor/01cba52f8515996bb9d7070bde81ff39281d096d7024a558efcba6e1fd2402cf.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 20,
                                                                "end_line": 129,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 6,
                                                                "start_line": 129
                                                            },
                                                            "While handling calldata of"
                                                        ],
                                                        "start_col": 35,
                                                        "start_line": 1
                                                    },
                                                    "While expanding the reference '__calldata_actual_size' in:"
                                                ],
                                                "start_col": 6,
                                                "start_line": 129
                                            },
                                            "While handling calldata of"
                                        ],
                                        "start_col": 31,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_ptr' in:"
                                ],
                                "start_col": 44,
                                "start_line": 130
                            },
                            "While handling calldata argument 'reserveOut'"
                        ],
                        "start_col": 22,
                        "start_line": 3
                    }
                },
                "1109": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 58,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/01cba52f8515996bb9d7070bde81ff39281d096d7024a558efcba6e1fd2402cf.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 129,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 129
                            },
                            "While handling calldata of"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "1110": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 64,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amount_out/b2c52ca2d2a8fc8791a983086d8716c5eacd0c3d62934914d2286f84b98ff4cb.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 39,
                                "end_line": 129,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 55,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amount_out/024b910625ecf54bddf1bfcd87e3ebd46c9b462a71e54688ed6788cb1861c3c2.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 129,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 129
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 44,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 21,
                                "start_line": 129
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 19,
                        "start_line": 1
                    }
                },
                "1111": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 110,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amount_out/9684a85e93c782014ca14293edea4eb2502039a5a7b6538ecd39c56faaf12529.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 67,
                                "end_line": 129,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 82,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amount_out/024b910625ecf54bddf1bfcd87e3ebd46c9b462a71e54688ed6788cb1861c3c2.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 129,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 129
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 70,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 41,
                                "start_line": 129
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 20,
                        "start_line": 1
                    }
                },
                "1112": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amount_out/741ea357d6336b0bed7bf0472425acd0311d543883b803388880e60a232040c7.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 84,
                                "end_line": 129,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 115,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amount_out/024b910625ecf54bddf1bfcd87e3ebd46c9b462a71e54688ed6788cb1861c3c2.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 129,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 129
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 100,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 69,
                                "start_line": 129
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 23,
                        "start_line": 1
                    }
                },
                "1113": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/f7de35c9223a7892c86748eb0198d5e7ca32c40f8e1de5c9a5ed02d3a6b2c64a.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 130,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 149,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amount_out/024b910625ecf54bddf1bfcd87e3ebd46c9b462a71e54688ed6788cb1861c3c2.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 129,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 129
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 126,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_amountIn' in:"
                                ],
                                "start_col": 5,
                                "start_line": 130
                            },
                            "While handling calldata argument 'amountIn'"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "1114": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/f7de35c9223a7892c86748eb0198d5e7ca32c40f8e1de5c9a5ed02d3a6b2c64a.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 130,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 149,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amount_out/024b910625ecf54bddf1bfcd87e3ebd46c9b462a71e54688ed6788cb1861c3c2.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 129,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 129
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 126,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_amountIn' in:"
                                ],
                                "start_col": 5,
                                "start_line": 130
                            },
                            "While handling calldata argument 'amountIn'"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "1115": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/c8616e403cdb3879194814d809769068a261ed6ee3d1ae1615c53d6ec43e5f09.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 130,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 185,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amount_out/024b910625ecf54bddf1bfcd87e3ebd46c9b462a71e54688ed6788cb1861c3c2.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 129,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 129
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 161,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_reserveIn' in:"
                                ],
                                "start_col": 24,
                                "start_line": 130
                            },
                            "While handling calldata argument 'reserveIn'"
                        ],
                        "start_col": 32,
                        "start_line": 1
                    }
                },
                "1116": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/c8616e403cdb3879194814d809769068a261ed6ee3d1ae1615c53d6ec43e5f09.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 130,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 185,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amount_out/024b910625ecf54bddf1bfcd87e3ebd46c9b462a71e54688ed6788cb1861c3c2.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 129,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 129
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 161,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_reserveIn' in:"
                                ],
                                "start_col": 24,
                                "start_line": 130
                            },
                            "While handling calldata argument 'reserveIn'"
                        ],
                        "start_col": 32,
                        "start_line": 1
                    }
                },
                "1117": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/822bb83e1247a59010b6591225aa0f16d76cb779d44ca4ac9311332f265a6ded.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 63,
                                "end_line": 130,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 223,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amount_out/024b910625ecf54bddf1bfcd87e3ebd46c9b462a71e54688ed6788cb1861c3c2.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 129,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 129
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 198,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_reserveOut' in:"
                                ],
                                "start_col": 44,
                                "start_line": 130
                            },
                            "While handling calldata argument 'reserveOut'"
                        ],
                        "start_col": 33,
                        "start_line": 1
                    }
                },
                "1118": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/822bb83e1247a59010b6591225aa0f16d76cb779d44ca4ac9311332f265a6ded.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 63,
                                "end_line": 130,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 223,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amount_out/024b910625ecf54bddf1bfcd87e3ebd46c9b462a71e54688ed6788cb1861c3c2.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 129,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 129
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 198,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_reserveOut' in:"
                                ],
                                "start_col": 44,
                                "start_line": 130
                            },
                            "While handling calldata argument 'reserveOut'"
                        ],
                        "start_col": 33,
                        "start_line": 1
                    }
                },
                "1119": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 129,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 6,
                        "start_line": 129
                    }
                },
                "1121": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 115,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amount_out/024b910625ecf54bddf1bfcd87e3ebd46c9b462a71e54688ed6788cb1861c3c2.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 129,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 103,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amount_out/024b910625ecf54bddf1bfcd87e3ebd46c9b462a71e54688ed6788cb1861c3c2.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 129,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 129
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 88,
                                        "start_line": 2
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 129
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 100,
                        "start_line": 1
                    }
                },
                "1122": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 104,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amount_out/024b910625ecf54bddf1bfcd87e3ebd46c9b462a71e54688ed6788cb1861c3c2.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 129,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 129
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 48,
                        "start_line": 2
                    }
                },
                "1124": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 55,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amount_out/024b910625ecf54bddf1bfcd87e3ebd46c9b462a71e54688ed6788cb1861c3c2.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 129,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 20,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amount_out/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 129,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 129
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 9,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 129
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 44,
                        "start_line": 1
                    }
                },
                "1125": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amount_out/024b910625ecf54bddf1bfcd87e3ebd46c9b462a71e54688ed6788cb1861c3c2.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 129,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 33,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amount_out/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 129,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 129
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 21,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 129
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 70,
                        "start_line": 1
                    }
                },
                "1126": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amount_out/024b910625ecf54bddf1bfcd87e3ebd46c9b462a71e54688ed6788cb1861c3c2.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 129,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 49,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amount_out/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 129,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 129
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 34,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 129
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 6,
                        "start_line": 2
                    }
                },
                "1127": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amount_out/024b910625ecf54bddf1bfcd87e3ebd46c9b462a71e54688ed6788cb1861c3c2.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 129,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 62,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amount_out/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 129,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 129
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 50,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'retdata_size' in:"
                                ],
                                "start_col": 6,
                                "start_line": 129
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 23,
                        "start_line": 2
                    }
                },
                "1128": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 44,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amount_out/024b910625ecf54bddf1bfcd87e3ebd46c9b462a71e54688ed6788cb1861c3c2.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 129,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 70,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amount_out/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 129,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 129
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 63,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'retdata' in:"
                                ],
                                "start_col": 6,
                                "start_line": 129
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 37,
                        "start_line": 2
                    }
                },
                "1129": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amount_out/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 129,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 129
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "1130": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 141,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 39,
                                "end_line": 533,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 60,
                                        "end_line": 144,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 12,
                                        "start_line": 144
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 21,
                                "start_line": 533
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 20,
                        "start_line": 141
                    }
                },
                "1131": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 66,
                        "end_line": 141,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 67,
                                "end_line": 533,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 60,
                                        "end_line": 144,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 12,
                                        "start_line": 144
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 41,
                                "start_line": 533
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 40,
                        "start_line": 141
                    }
                },
                "1132": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 83,
                        "end_line": 141,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 84,
                                "end_line": 533,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 60,
                                        "end_line": 144,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 12,
                                        "start_line": 144
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 69,
                                "start_line": 533
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 68,
                        "start_line": 141
                    }
                },
                "1133": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 142,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 144,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 27,
                                "start_line": 144
                            },
                            "While expanding the reference 'amountOut' in:"
                        ],
                        "start_col": 5,
                        "start_line": 142
                    }
                },
                "1134": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 142,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 144,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 27,
                                "start_line": 144
                            },
                            "While expanding the reference 'amountOut' in:"
                        ],
                        "start_col": 5,
                        "start_line": 142
                    }
                },
                "1135": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 43,
                        "end_line": 142,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 47,
                                "end_line": 144,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 38,
                                "start_line": 144
                            },
                            "While expanding the reference 'reserveIn' in:"
                        ],
                        "start_col": 25,
                        "start_line": 142
                    }
                },
                "1136": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 43,
                        "end_line": 142,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 47,
                                "end_line": 144,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 38,
                                "start_line": 144
                            },
                            "While expanding the reference 'reserveIn' in:"
                        ],
                        "start_col": 25,
                        "start_line": 142
                    }
                },
                "1137": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 64,
                        "end_line": 142,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 59,
                                "end_line": 144,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 49,
                                "start_line": 144
                            },
                            "While expanding the reference 'reserveOut' in:"
                        ],
                        "start_col": 45,
                        "start_line": 142
                    }
                },
                "1138": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 64,
                        "end_line": 142,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 59,
                                "end_line": 144,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 49,
                                "start_line": 144
                            },
                            "While expanding the reference 'reserveOut' in:"
                        ],
                        "start_col": 45,
                        "start_line": 142
                    }
                },
                "1139": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 60,
                        "end_line": 144,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 12,
                        "start_line": 144
                    }
                },
                "1141": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 144,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 144
                    }
                },
                "1142": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_in_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 38,
                                "end_line": 3,
                                "input_file": {
                                    "filename": "autogen/starknet/external/return/get_amount_in/33c8a165a194e19ad4f8cdd00b54f617a048d42f3e20a4932df6da37980f9340.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 19,
                                        "end_line": 141,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 6,
                                        "start_line": 141
                                    },
                                    "While handling return value of"
                                ],
                                "start_col": 5,
                                "start_line": 3
                            },
                            "n_prefix_newlines": 0
                        }
                    ],
                    "inst": {
                        "end_col": 18,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/get_amount_in/33c8a165a194e19ad4f8cdd00b54f617a048d42f3e20a4932df6da37980f9340.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 141,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 141
                            },
                            "While handling return value of"
                        ],
                        "start_col": 5,
                        "start_line": 4
                    }
                },
                "1144": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_in_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 60,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8e4bcd6f8d677f2e66b290c53d722de492b60870d51c9e55b49225a1070f28c7.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 143,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 7,
                                "start_line": 143
                            },
                            "While handling return value 'amountIn'"
                        ],
                        "start_col": 1,
                        "start_line": 3
                    }
                },
                "1145": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_in_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 60,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8e4bcd6f8d677f2e66b290c53d722de492b60870d51c9e55b49225a1070f28c7.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 143,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 7,
                                "start_line": 143
                            },
                            "While handling return value 'amountIn'"
                        ],
                        "start_col": 1,
                        "start_line": 4
                    }
                },
                "1146": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_in_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8e4bcd6f8d677f2e66b290c53d722de492b60870d51c9e55b49225a1070f28c7.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 143,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 36,
                                        "end_line": 11,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/return/get_amount_in/33c8a165a194e19ad4f8cdd00b54f617a048d42f3e20a4932df6da37980f9340.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 141,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 141
                                            },
                                            "While handling return value of"
                                        ],
                                        "start_col": 18,
                                        "start_line": 11
                                    },
                                    "While expanding the reference '__return_value_ptr' in:"
                                ],
                                "start_col": 7,
                                "start_line": 143
                            },
                            "While handling return value 'amountIn'"
                        ],
                        "start_col": 26,
                        "start_line": 5
                    }
                },
                "1148": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_in_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 112,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/get_amount_in/33c8a165a194e19ad4f8cdd00b54f617a048d42f3e20a4932df6da37980f9340.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 141,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 40,
                                        "end_line": 10,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/return/get_amount_in/33c8a165a194e19ad4f8cdd00b54f617a048d42f3e20a4932df6da37980f9340.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 141,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 141
                                            },
                                            "While handling return value of"
                                        ],
                                        "start_col": 25,
                                        "start_line": 10
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 141
                            },
                            "While handling return value of"
                        ],
                        "start_col": 97,
                        "start_line": 1
                    }
                },
                "1149": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_in_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 63,
                        "end_line": 11,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/get_amount_in/33c8a165a194e19ad4f8cdd00b54f617a048d42f3e20a4932df6da37980f9340.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 141,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 141
                            },
                            "While handling return value of"
                        ],
                        "start_col": 18,
                        "start_line": 11
                    }
                },
                "1150": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_in_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/get_amount_in/33c8a165a194e19ad4f8cdd00b54f617a048d42f3e20a4932df6da37980f9340.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 141,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 38,
                                        "end_line": 12,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/return/get_amount_in/33c8a165a194e19ad4f8cdd00b54f617a048d42f3e20a4932df6da37980f9340.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 141,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 141
                                            },
                                            "While handling return value of"
                                        ],
                                        "start_col": 14,
                                        "start_line": 12
                                    },
                                    "While expanding the reference '__return_value_ptr_start' in:"
                                ],
                                "start_col": 6,
                                "start_line": 141
                            },
                            "While handling return value of"
                        ],
                        "start_col": 11,
                        "start_line": 5
                    }
                },
                "1151": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_in_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 12,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/get_amount_in/33c8a165a194e19ad4f8cdd00b54f617a048d42f3e20a4932df6da37980f9340.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 141,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 141
                            },
                            "While handling return value of"
                        ],
                        "start_col": 5,
                        "start_line": 9
                    }
                },
                "1152": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/822bb83e1247a59010b6591225aa0f16d76cb779d44ca4ac9311332f265a6ded.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 64,
                                "end_line": 142,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 45,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/c31620b02d4d706f0542c989b2aadc01b0981d1f6a5933a8fe4937ace3d70d92.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 141,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 57,
                                                        "end_line": 1,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/arg_processor/01cba52f8515996bb9d7070bde81ff39281d096d7024a558efcba6e1fd2402cf.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 19,
                                                                "end_line": 141,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 6,
                                                                "start_line": 141
                                                            },
                                                            "While handling calldata of"
                                                        ],
                                                        "start_col": 35,
                                                        "start_line": 1
                                                    },
                                                    "While expanding the reference '__calldata_actual_size' in:"
                                                ],
                                                "start_col": 6,
                                                "start_line": 141
                                            },
                                            "While handling calldata of"
                                        ],
                                        "start_col": 31,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_ptr' in:"
                                ],
                                "start_col": 45,
                                "start_line": 142
                            },
                            "While handling calldata argument 'reserveOut'"
                        ],
                        "start_col": 22,
                        "start_line": 3
                    }
                },
                "1154": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 58,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/01cba52f8515996bb9d7070bde81ff39281d096d7024a558efcba6e1fd2402cf.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 141,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 141
                            },
                            "While handling calldata of"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "1155": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 64,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amount_in/b2c52ca2d2a8fc8791a983086d8716c5eacd0c3d62934914d2286f84b98ff4cb.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 38,
                                "end_line": 141,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 55,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amount_in/a2e351ac07307c3ab316554972b5d5d88d31275fc63904e6f842f771d70231fa.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 141,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 141
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 44,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 20,
                                "start_line": 141
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 19,
                        "start_line": 1
                    }
                },
                "1156": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 110,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amount_in/9684a85e93c782014ca14293edea4eb2502039a5a7b6538ecd39c56faaf12529.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 66,
                                "end_line": 141,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 82,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amount_in/a2e351ac07307c3ab316554972b5d5d88d31275fc63904e6f842f771d70231fa.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 141,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 141
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 70,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 40,
                                "start_line": 141
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 20,
                        "start_line": 1
                    }
                },
                "1157": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amount_in/741ea357d6336b0bed7bf0472425acd0311d543883b803388880e60a232040c7.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 83,
                                "end_line": 141,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 115,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amount_in/a2e351ac07307c3ab316554972b5d5d88d31275fc63904e6f842f771d70231fa.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 141,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 141
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 100,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 68,
                                "start_line": 141
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 23,
                        "start_line": 1
                    }
                },
                "1158": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/c6477613576c1865c350adaa5562664ab5c9cf232374f021ab29a9b963be2b8c.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 23,
                                "end_line": 142,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 151,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amount_in/a2e351ac07307c3ab316554972b5d5d88d31275fc63904e6f842f771d70231fa.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 141,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 141
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 127,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_amountOut' in:"
                                ],
                                "start_col": 5,
                                "start_line": 142
                            },
                            "While handling calldata argument 'amountOut'"
                        ],
                        "start_col": 32,
                        "start_line": 1
                    }
                },
                "1159": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/c6477613576c1865c350adaa5562664ab5c9cf232374f021ab29a9b963be2b8c.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 23,
                                "end_line": 142,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 151,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amount_in/a2e351ac07307c3ab316554972b5d5d88d31275fc63904e6f842f771d70231fa.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 141,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 141
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 127,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_amountOut' in:"
                                ],
                                "start_col": 5,
                                "start_line": 142
                            },
                            "While handling calldata argument 'amountOut'"
                        ],
                        "start_col": 32,
                        "start_line": 1
                    }
                },
                "1160": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/c8616e403cdb3879194814d809769068a261ed6ee3d1ae1615c53d6ec43e5f09.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 142,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 187,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amount_in/a2e351ac07307c3ab316554972b5d5d88d31275fc63904e6f842f771d70231fa.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 141,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 141
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 163,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_reserveIn' in:"
                                ],
                                "start_col": 25,
                                "start_line": 142
                            },
                            "While handling calldata argument 'reserveIn'"
                        ],
                        "start_col": 32,
                        "start_line": 1
                    }
                },
                "1161": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/c8616e403cdb3879194814d809769068a261ed6ee3d1ae1615c53d6ec43e5f09.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 142,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 187,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amount_in/a2e351ac07307c3ab316554972b5d5d88d31275fc63904e6f842f771d70231fa.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 141,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 141
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 163,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_reserveIn' in:"
                                ],
                                "start_col": 25,
                                "start_line": 142
                            },
                            "While handling calldata argument 'reserveIn'"
                        ],
                        "start_col": 32,
                        "start_line": 1
                    }
                },
                "1162": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/822bb83e1247a59010b6591225aa0f16d76cb779d44ca4ac9311332f265a6ded.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 64,
                                "end_line": 142,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 225,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amount_in/a2e351ac07307c3ab316554972b5d5d88d31275fc63904e6f842f771d70231fa.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 141,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 141
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 200,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_reserveOut' in:"
                                ],
                                "start_col": 45,
                                "start_line": 142
                            },
                            "While handling calldata argument 'reserveOut'"
                        ],
                        "start_col": 33,
                        "start_line": 1
                    }
                },
                "1163": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/822bb83e1247a59010b6591225aa0f16d76cb779d44ca4ac9311332f265a6ded.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 64,
                                "end_line": 142,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 225,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amount_in/a2e351ac07307c3ab316554972b5d5d88d31275fc63904e6f842f771d70231fa.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 141,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 141
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 200,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_reserveOut' in:"
                                ],
                                "start_col": 45,
                                "start_line": 142
                            },
                            "While handling calldata argument 'reserveOut'"
                        ],
                        "start_col": 33,
                        "start_line": 1
                    }
                },
                "1164": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 19,
                        "end_line": 141,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 6,
                        "start_line": 141
                    }
                },
                "1166": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 115,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amount_in/a2e351ac07307c3ab316554972b5d5d88d31275fc63904e6f842f771d70231fa.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 141,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 102,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amount_in/a2e351ac07307c3ab316554972b5d5d88d31275fc63904e6f842f771d70231fa.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 141,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 141
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 87,
                                        "start_line": 2
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 141
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 100,
                        "start_line": 1
                    }
                },
                "1167": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 103,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amount_in/a2e351ac07307c3ab316554972b5d5d88d31275fc63904e6f842f771d70231fa.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 141,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 141
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 48,
                        "start_line": 2
                    }
                },
                "1169": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 55,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amount_in/a2e351ac07307c3ab316554972b5d5d88d31275fc63904e6f842f771d70231fa.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 141,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 20,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amount_in/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 141,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 141
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 9,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 141
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 44,
                        "start_line": 1
                    }
                },
                "1170": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amount_in/a2e351ac07307c3ab316554972b5d5d88d31275fc63904e6f842f771d70231fa.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 141,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 33,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amount_in/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 141,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 141
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 21,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 141
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 70,
                        "start_line": 1
                    }
                },
                "1171": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amount_in/a2e351ac07307c3ab316554972b5d5d88d31275fc63904e6f842f771d70231fa.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 141,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 49,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amount_in/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 141,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 141
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 34,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 141
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 6,
                        "start_line": 2
                    }
                },
                "1172": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amount_in/a2e351ac07307c3ab316554972b5d5d88d31275fc63904e6f842f771d70231fa.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 141,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 62,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amount_in/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 141,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 141
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 50,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'retdata_size' in:"
                                ],
                                "start_col": 6,
                                "start_line": 141
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 23,
                        "start_line": 2
                    }
                },
                "1173": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 44,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amount_in/a2e351ac07307c3ab316554972b5d5d88d31275fc63904e6f842f771d70231fa.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 141,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 70,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amount_in/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 141,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 141
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 63,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'retdata' in:"
                                ],
                                "start_col": 6,
                                "start_line": 141
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 37,
                        "start_line": 2
                    }
                },
                "1174": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amount_in/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 141,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 141
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "1175": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 157,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 157
                    }
                },
                "1177": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 154,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 33,
                                "end_line": 13,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 42,
                                        "end_line": 158,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 27,
                                        "start_line": 158
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 15,
                                "start_line": 13
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 22,
                        "start_line": 154
                    }
                },
                "1178": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 68,
                        "end_line": 154,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 61,
                                "end_line": 13,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 42,
                                        "end_line": 158,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 27,
                                        "start_line": 158
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 35,
                                "start_line": 13
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 42,
                        "start_line": 154
                    }
                },
                "1179": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 85,
                        "end_line": 154,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 78,
                                "end_line": 13,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 42,
                                        "end_line": 158,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 27,
                                        "start_line": 158
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 63,
                                "start_line": 13
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 70,
                        "start_line": 154
                    }
                },
                "1180": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 42,
                        "end_line": 158,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 27,
                        "start_line": 158
                    }
                },
                "1182": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 158,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 158
                    }
                },
                "1183": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 158,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 41,
                                        "end_line": 559,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 88,
                                                "end_line": 159,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 37,
                                                "start_line": 159
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 23,
                                        "start_line": 559
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 27,
                                "start_line": 158
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 13
                    }
                },
                "1184": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 158,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 69,
                                        "end_line": 559,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 88,
                                                "end_line": 159,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 37,
                                                "start_line": 159
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 43,
                                        "start_line": 559
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 27,
                                "start_line": 158
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 35,
                        "start_line": 13
                    }
                },
                "1185": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 78,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 158,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 86,
                                        "end_line": 559,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 88,
                                                "end_line": 159,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 37,
                                                "start_line": 159
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 71,
                                        "start_line": 559
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 27,
                                "start_line": 158
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 63,
                        "start_line": 13
                    }
                },
                "1186": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 158,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 61,
                                "end_line": 159,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 54,
                                "start_line": 159
                            },
                            "While expanding the reference 'factory' in:"
                        ],
                        "start_col": 16,
                        "start_line": 158
                    }
                },
                "1187": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 155,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 71,
                                "end_line": 159,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 63,
                                "start_line": 159
                            },
                            "While expanding the reference 'amountIn' in:"
                        ],
                        "start_col": 5,
                        "start_line": 155
                    }
                },
                "1188": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 155,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 71,
                                "end_line": 159,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 63,
                                "start_line": 159
                            },
                            "While expanding the reference 'amountIn' in:"
                        ],
                        "start_col": 5,
                        "start_line": 155
                    }
                },
                "1189": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 155,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 81,
                                "end_line": 159,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 73,
                                "start_line": 159
                            },
                            "While expanding the reference 'path_len' in:"
                        ],
                        "start_col": 24,
                        "start_line": 155
                    }
                },
                "1190": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 51,
                        "end_line": 155,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 87,
                                "end_line": 159,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 83,
                                "start_line": 159
                            },
                            "While expanding the reference 'path' in:"
                        ],
                        "start_col": 40,
                        "start_line": 155
                    }
                },
                "1191": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 88,
                        "end_line": 159,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 37,
                        "start_line": 159
                    }
                },
                "1193": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 159,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 159
                    }
                },
                "1194": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 559,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 88,
                                "end_line": 159,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 40,
                                        "end_line": 154,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 32,
                                                "end_line": 160,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 160
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 22,
                                        "start_line": 154
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 37,
                                "start_line": 159
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 23,
                        "start_line": 559
                    }
                },
                "1195": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 69,
                        "end_line": 559,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 88,
                                "end_line": 159,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 68,
                                        "end_line": 154,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 32,
                                                "end_line": 160,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 160
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 42,
                                        "start_line": 154
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 37,
                                "start_line": 159
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 43,
                        "start_line": 559
                    }
                },
                "1196": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 86,
                        "end_line": 559,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 88,
                                "end_line": 159,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 85,
                                        "end_line": 154,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 32,
                                                "end_line": 160,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 160
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 70,
                                        "start_line": 154
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 37,
                                "start_line": 159
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 71,
                        "start_line": 559
                    }
                },
                "1197": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 155,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 160,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 13,
                                "start_line": 160
                            },
                            "While expanding the reference 'path_len' in:"
                        ],
                        "start_col": 24,
                        "start_line": 155
                    }
                },
                "1198": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 159,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 30,
                                "end_line": 160,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 23,
                                "start_line": 160
                            },
                            "While expanding the reference 'amounts' in:"
                        ],
                        "start_col": 16,
                        "start_line": 159
                    }
                },
                "1199": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 160,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 160
                    }
                },
                "1200": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 38,
                                "end_line": 3,
                                "input_file": {
                                    "filename": "autogen/starknet/external/return/get_amounts_out/b6666a504ab8a9b41998f4aab125d89d2f1350d9f95ea85684ab5e4468af4b39.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 21,
                                        "end_line": 154,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 6,
                                        "start_line": 154
                                    },
                                    "While handling return value of"
                                ],
                                "start_col": 5,
                                "start_line": 3
                            },
                            "n_prefix_newlines": 0
                        }
                    ],
                    "inst": {
                        "end_col": 18,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/get_amounts_out/b6666a504ab8a9b41998f4aab125d89d2f1350d9f95ea85684ab5e4468af4b39.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 154,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 154
                            },
                            "While handling return value of"
                        ],
                        "start_col": 5,
                        "start_line": 4
                    }
                },
                "1202": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 53,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/d0188e44f9bf394c59a5ca0aa00029b3d4a0b84a048a492cac1961341ac80755.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 156,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 7,
                                "start_line": 156
                            },
                            "While handling return value 'amounts_len'"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "1203": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 50,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 156,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 26,
                                "start_line": 156
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 1,
                        "start_line": 2
                    }
                },
                "1204": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 45,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 156,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 26,
                                "start_line": 156
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 1,
                        "start_line": 5
                    }
                },
                "1206": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/d0188e44f9bf394c59a5ca0aa00029b3d4a0b84a048a492cac1961341ac80755.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 156,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 53,
                                        "end_line": 10,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 43,
                                                "end_line": 156,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 26,
                                                "start_line": 156
                                            },
                                            "While handling return value 'amounts'"
                                        ],
                                        "start_col": 35,
                                        "start_line": 10
                                    },
                                    "While expanding the reference '__return_value_ptr' in:"
                                ],
                                "start_col": 7,
                                "start_line": 156
                            },
                            "While handling return value 'amounts_len'"
                        ],
                        "start_col": 26,
                        "start_line": 2
                    }
                },
                "1208": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 81,
                        "end_line": 10,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 156,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 26,
                                "start_line": 156
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 56,
                        "start_line": 10
                    }
                },
                "1210": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 10,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 156,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 26,
                                "start_line": 156
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 1,
                        "start_line": 10
                    }
                },
                "1211": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/d0188e44f9bf394c59a5ca0aa00029b3d4a0b84a048a492cac1961341ac80755.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 156,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 49,
                                        "end_line": 7,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 43,
                                                "end_line": 156,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 32,
                                                        "end_line": 12,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 43,
                                                                "end_line": 156,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 26,
                                                                "start_line": 156
                                                            },
                                                            "While handling return value 'amounts'"
                                                        ],
                                                        "start_col": 9,
                                                        "start_line": 12
                                                    },
                                                    "While expanding the reference '__return_value_ptr_copy' in:"
                                                ],
                                                "start_col": 26,
                                                "start_line": 156
                                            },
                                            "While handling return value 'amounts'"
                                        ],
                                        "start_col": 31,
                                        "start_line": 7
                                    },
                                    "While expanding the reference '__return_value_ptr' in:"
                                ],
                                "start_col": 7,
                                "start_line": 156
                            },
                            "While handling return value 'amounts_len'"
                        ],
                        "start_col": 26,
                        "start_line": 2
                    }
                },
                "1213": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 26,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 156,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 26,
                                "start_line": 156
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 9,
                        "start_line": 13
                    }
                },
                "1214": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 14,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 156,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 26,
                                "start_line": 156
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 9,
                        "start_line": 14
                    }
                },
                "1216": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 14,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 156,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 26,
                                "start_line": 156
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 1,
                        "start_line": 11
                    }
                },
                "1218": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 156,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 40,
                                        "end_line": 10,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/return/get_amounts_out/b6666a504ab8a9b41998f4aab125d89d2f1350d9f95ea85684ab5e4468af4b39.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 21,
                                                "end_line": 154,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 154
                                            },
                                            "While handling return value of"
                                        ],
                                        "start_col": 25,
                                        "start_line": 10
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 26,
                                "start_line": 156
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 7,
                        "start_line": 5
                    }
                },
                "1219": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 63,
                        "end_line": 11,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/get_amounts_out/b6666a504ab8a9b41998f4aab125d89d2f1350d9f95ea85684ab5e4468af4b39.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 154,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 154
                            },
                            "While handling return value of"
                        ],
                        "start_col": 18,
                        "start_line": 11
                    }
                },
                "1220": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/get_amounts_out/b6666a504ab8a9b41998f4aab125d89d2f1350d9f95ea85684ab5e4468af4b39.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 154,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 38,
                                        "end_line": 12,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/return/get_amounts_out/b6666a504ab8a9b41998f4aab125d89d2f1350d9f95ea85684ab5e4468af4b39.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 21,
                                                "end_line": 154,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 154
                                            },
                                            "While handling return value of"
                                        ],
                                        "start_col": 14,
                                        "start_line": 12
                                    },
                                    "While expanding the reference '__return_value_ptr_start' in:"
                                ],
                                "start_col": 6,
                                "start_line": 154
                            },
                            "While handling return value of"
                        ],
                        "start_col": 11,
                        "start_line": 5
                    }
                },
                "1221": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 12,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/get_amounts_out/b6666a504ab8a9b41998f4aab125d89d2f1350d9f95ea85684ab5e4468af4b39.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 154,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 154
                            },
                            "While handling return value of"
                        ],
                        "start_col": 5,
                        "start_line": 9
                    }
                },
                "1222": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amounts_out/efba66db60bb26bebf1da90b4428c053b024d9a41b0cf0b702abc67365dd8923.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 154,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 154
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 5,
                        "start_line": 2
                    }
                },
                "1224": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amounts_out/741ea357d6336b0bed7bf0472425acd0311d543883b803388880e60a232040c7.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 85,
                                "end_line": 154,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 24,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 51,
                                                "end_line": 155,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 40,
                                                "start_line": 155
                                            },
                                            "While handling calldata argument 'path'"
                                        ],
                                        "start_col": 9,
                                        "start_line": 2
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 70,
                                "start_line": 154
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 23,
                        "start_line": 1
                    }
                },
                "1225": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 47,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/1a2c645314c38f7cbacc4fe8f48f8ae2ef9e43d925286d804280d816fb1e6157.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 38,
                                "end_line": 155,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 51,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 51,
                                                "end_line": 155,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 40,
                                                "start_line": 155
                                            },
                                            "While handling calldata argument 'path'"
                                        ],
                                        "start_col": 28,
                                        "start_line": 2
                                    },
                                    "While expanding the reference '__calldata_arg_path_len' in:"
                                ],
                                "start_col": 24,
                                "start_line": 155
                            },
                            "While handling calldata argument 'path_len'"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "1226": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 52,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 51,
                                "end_line": 155,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 40,
                                "start_line": 155
                            },
                            "While handling calldata argument 'path'"
                        ],
                        "start_col": 1,
                        "start_line": 2
                    }
                },
                "1227": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/1a2c645314c38f7cbacc4fe8f48f8ae2ef9e43d925286d804280d816fb1e6157.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 38,
                                "end_line": 155,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 40,
                                        "end_line": 8,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 51,
                                                "end_line": 155,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 40,
                                                "start_line": 155
                                            },
                                            "While handling calldata argument 'path'"
                                        ],
                                        "start_col": 26,
                                        "start_line": 8
                                    },
                                    "While expanding the reference '__calldata_ptr' in:"
                                ],
                                "start_col": 24,
                                "start_line": 155
                            },
                            "While handling calldata argument 'path_len'"
                        ],
                        "start_col": 22,
                        "start_line": 2
                    }
                },
                "1229": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 47,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/1a2c645314c38f7cbacc4fe8f48f8ae2ef9e43d925286d804280d816fb1e6157.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 38,
                                "end_line": 155,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 66,
                                        "end_line": 8,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 51,
                                                "end_line": 155,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 40,
                                                "start_line": 155
                                            },
                                            "While handling calldata argument 'path'"
                                        ],
                                        "start_col": 43,
                                        "start_line": 8
                                    },
                                    "While expanding the reference '__calldata_arg_path_len' in:"
                                ],
                                "start_col": 24,
                                "start_line": 155
                            },
                            "While handling calldata argument 'path_len'"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "1230": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 70,
                        "end_line": 8,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 51,
                                "end_line": 155,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 40,
                                "start_line": 155
                            },
                            "While handling calldata argument 'path'"
                        ],
                        "start_col": 26,
                        "start_line": 8
                    }
                },
                "1231": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 58,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/01cba52f8515996bb9d7070bde81ff39281d096d7024a558efcba6e1fd2402cf.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 154,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 154
                            },
                            "While handling calldata of"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "1232": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amounts_out/741ea357d6336b0bed7bf0472425acd0311d543883b803388880e60a232040c7.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 85,
                                "end_line": 154,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 38,
                                        "end_line": 3,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 51,
                                                "end_line": 155,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 115,
                                                        "end_line": 1,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/external/get_amounts_out/b2e508f9ab38fe9974d87cebfa23f4daa10b9dd03a6eae53c48515e87ee6e37d.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 21,
                                                                "end_line": 154,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 6,
                                                                "start_line": 154
                                                            },
                                                            "While constructing the external wrapper for:"
                                                        ],
                                                        "start_col": 100,
                                                        "start_line": 1
                                                    },
                                                    "While expanding the reference 'range_check_ptr' in:"
                                                ],
                                                "start_col": 40,
                                                "start_line": 155
                                            },
                                            "While handling calldata argument 'path'"
                                        ],
                                        "start_col": 23,
                                        "start_line": 3
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 70,
                                "start_line": 154
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 23,
                        "start_line": 1
                    }
                },
                "1233": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 64,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amounts_out/b2c52ca2d2a8fc8791a983086d8716c5eacd0c3d62934914d2286f84b98ff4cb.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 40,
                                "end_line": 154,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 55,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amounts_out/b2e508f9ab38fe9974d87cebfa23f4daa10b9dd03a6eae53c48515e87ee6e37d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 21,
                                                "end_line": 154,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 154
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 44,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 22,
                                "start_line": 154
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 19,
                        "start_line": 1
                    }
                },
                "1234": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 110,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amounts_out/9684a85e93c782014ca14293edea4eb2502039a5a7b6538ecd39c56faaf12529.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 68,
                                "end_line": 154,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 82,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amounts_out/b2e508f9ab38fe9974d87cebfa23f4daa10b9dd03a6eae53c48515e87ee6e37d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 21,
                                                "end_line": 154,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 154
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 70,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 42,
                                "start_line": 154
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 20,
                        "start_line": 1
                    }
                },
                "1235": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 42,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 51,
                                "end_line": 155,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 115,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amounts_out/b2e508f9ab38fe9974d87cebfa23f4daa10b9dd03a6eae53c48515e87ee6e37d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 21,
                                                "end_line": 154,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 154
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 100,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 40,
                                "start_line": 155
                            },
                            "While handling calldata argument 'path'"
                        ],
                        "start_col": 23,
                        "start_line": 3
                    }
                },
                "1237": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/f7de35c9223a7892c86748eb0198d5e7ca32c40f8e1de5c9a5ed02d3a6b2c64a.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 155,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 149,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amounts_out/b2e508f9ab38fe9974d87cebfa23f4daa10b9dd03a6eae53c48515e87ee6e37d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 21,
                                                "end_line": 154,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 154
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 126,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_amountIn' in:"
                                ],
                                "start_col": 5,
                                "start_line": 155
                            },
                            "While handling calldata argument 'amountIn'"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "1238": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/f7de35c9223a7892c86748eb0198d5e7ca32c40f8e1de5c9a5ed02d3a6b2c64a.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 155,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 149,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amounts_out/b2e508f9ab38fe9974d87cebfa23f4daa10b9dd03a6eae53c48515e87ee6e37d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 21,
                                                "end_line": 154,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 154
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 126,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_amountIn' in:"
                                ],
                                "start_col": 5,
                                "start_line": 155
                            },
                            "While handling calldata argument 'amountIn'"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "1239": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 47,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/1a2c645314c38f7cbacc4fe8f48f8ae2ef9e43d925286d804280d816fb1e6157.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 38,
                                "end_line": 155,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 183,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amounts_out/b2e508f9ab38fe9974d87cebfa23f4daa10b9dd03a6eae53c48515e87ee6e37d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 21,
                                                "end_line": 154,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 154
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 160,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_path_len' in:"
                                ],
                                "start_col": 24,
                                "start_line": 155
                            },
                            "While handling calldata argument 'path_len'"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "1240": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 51,
                                "end_line": 155,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 209,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amounts_out/b2e508f9ab38fe9974d87cebfa23f4daa10b9dd03a6eae53c48515e87ee6e37d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 21,
                                                "end_line": 154,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 154
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 190,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_path' in:"
                                ],
                                "start_col": 40,
                                "start_line": 155
                            },
                            "While handling calldata argument 'path'"
                        ],
                        "start_col": 27,
                        "start_line": 5
                    }
                },
                "1242": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 154,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 6,
                        "start_line": 154
                    }
                },
                "1244": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 55,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amounts_out/b2e508f9ab38fe9974d87cebfa23f4daa10b9dd03a6eae53c48515e87ee6e37d.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 154,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 55,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amounts_out/b2e508f9ab38fe9974d87cebfa23f4daa10b9dd03a6eae53c48515e87ee6e37d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 21,
                                                "end_line": 154,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 154
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 44,
                                        "start_line": 1
                                    },
                                    "While auto generating local variable for 'syscall_ptr'."
                                ],
                                "start_col": 6,
                                "start_line": 154
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 44,
                        "start_line": 1
                    }
                },
                "1245": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amounts_out/b2e508f9ab38fe9974d87cebfa23f4daa10b9dd03a6eae53c48515e87ee6e37d.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 154,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 82,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amounts_out/b2e508f9ab38fe9974d87cebfa23f4daa10b9dd03a6eae53c48515e87ee6e37d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 21,
                                                "end_line": 154,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 154
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 70,
                                        "start_line": 1
                                    },
                                    "While auto generating local variable for 'pedersen_ptr'."
                                ],
                                "start_col": 6,
                                "start_line": 154
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 70,
                        "start_line": 1
                    }
                },
                "1246": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 115,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amounts_out/b2e508f9ab38fe9974d87cebfa23f4daa10b9dd03a6eae53c48515e87ee6e37d.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 154,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 104,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amounts_out/b2e508f9ab38fe9974d87cebfa23f4daa10b9dd03a6eae53c48515e87ee6e37d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 21,
                                                "end_line": 154,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 154
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 89,
                                        "start_line": 2
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 154
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 100,
                        "start_line": 1
                    }
                },
                "1247": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 105,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amounts_out/b2e508f9ab38fe9974d87cebfa23f4daa10b9dd03a6eae53c48515e87ee6e37d.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 154,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 154
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 48,
                        "start_line": 2
                    }
                },
                "1249": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 55,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amounts_out/b2e508f9ab38fe9974d87cebfa23f4daa10b9dd03a6eae53c48515e87ee6e37d.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 154,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 55,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amounts_out/b2e508f9ab38fe9974d87cebfa23f4daa10b9dd03a6eae53c48515e87ee6e37d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 21,
                                                "end_line": 154,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 20,
                                                        "end_line": 1,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/external/get_amounts_out/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 21,
                                                                "end_line": 154,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 6,
                                                                "start_line": 154
                                                            },
                                                            "While constructing the external wrapper for:"
                                                        ],
                                                        "start_col": 9,
                                                        "start_line": 1
                                                    },
                                                    "While expanding the reference 'syscall_ptr' in:"
                                                ],
                                                "start_col": 6,
                                                "start_line": 154
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 44,
                                        "start_line": 1
                                    },
                                    "While auto generating local variable for 'syscall_ptr'."
                                ],
                                "start_col": 6,
                                "start_line": 154
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 44,
                        "start_line": 1
                    }
                },
                "1250": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amounts_out/b2e508f9ab38fe9974d87cebfa23f4daa10b9dd03a6eae53c48515e87ee6e37d.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 154,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 82,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amounts_out/b2e508f9ab38fe9974d87cebfa23f4daa10b9dd03a6eae53c48515e87ee6e37d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 21,
                                                "end_line": 154,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 33,
                                                        "end_line": 1,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/external/get_amounts_out/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 21,
                                                                "end_line": 154,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 6,
                                                                "start_line": 154
                                                            },
                                                            "While constructing the external wrapper for:"
                                                        ],
                                                        "start_col": 21,
                                                        "start_line": 1
                                                    },
                                                    "While expanding the reference 'pedersen_ptr' in:"
                                                ],
                                                "start_col": 6,
                                                "start_line": 154
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 70,
                                        "start_line": 1
                                    },
                                    "While auto generating local variable for 'pedersen_ptr'."
                                ],
                                "start_col": 6,
                                "start_line": 154
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 70,
                        "start_line": 1
                    }
                },
                "1251": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amounts_out/b2e508f9ab38fe9974d87cebfa23f4daa10b9dd03a6eae53c48515e87ee6e37d.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 154,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 49,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amounts_out/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 21,
                                                "end_line": 154,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 154
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 34,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 154
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 6,
                        "start_line": 2
                    }
                },
                "1252": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amounts_out/b2e508f9ab38fe9974d87cebfa23f4daa10b9dd03a6eae53c48515e87ee6e37d.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 154,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 62,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amounts_out/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 21,
                                                "end_line": 154,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 154
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 50,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'retdata_size' in:"
                                ],
                                "start_col": 6,
                                "start_line": 154
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 23,
                        "start_line": 2
                    }
                },
                "1253": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 44,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amounts_out/b2e508f9ab38fe9974d87cebfa23f4daa10b9dd03a6eae53c48515e87ee6e37d.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 154,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 70,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amounts_out/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 21,
                                                "end_line": 154,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 154
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 63,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'retdata' in:"
                                ],
                                "start_col": 6,
                                "start_line": 154
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 37,
                        "start_line": 2
                    }
                },
                "1254": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amounts_out/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 154,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 154
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "1255": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 173,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 173
                    }
                },
                "1257": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 39,
                        "end_line": 170,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 33,
                                "end_line": 13,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 42,
                                        "end_line": 174,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 27,
                                        "start_line": 174
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 15,
                                "start_line": 13
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 21,
                        "start_line": 170
                    }
                },
                "1258": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 170,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 61,
                                "end_line": 13,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 42,
                                        "end_line": 174,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 27,
                                        "start_line": 174
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 35,
                                "start_line": 13
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 41,
                        "start_line": 170
                    }
                },
                "1259": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 84,
                        "end_line": 170,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 78,
                                "end_line": 13,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 42,
                                        "end_line": 174,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 27,
                                        "start_line": 174
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 63,
                                "start_line": 13
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 69,
                        "start_line": 170
                    }
                },
                "1260": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 42,
                        "end_line": 174,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 27,
                        "start_line": 174
                    }
                },
                "1262": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 174,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 174
                    }
                },
                "1263": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 174,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 40,
                                        "end_line": 610,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 88,
                                                "end_line": 175,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 37,
                                                "start_line": 175
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 22,
                                        "start_line": 610
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 27,
                                "start_line": 174
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 13
                    }
                },
                "1264": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 174,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 68,
                                        "end_line": 610,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 88,
                                                "end_line": 175,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 37,
                                                "start_line": 175
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 42,
                                        "start_line": 610
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 27,
                                "start_line": 174
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 35,
                        "start_line": 13
                    }
                },
                "1265": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 78,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 174,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 85,
                                        "end_line": 610,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 88,
                                                "end_line": 175,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 37,
                                                "start_line": 175
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 70,
                                        "start_line": 610
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 27,
                                "start_line": 174
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 63,
                        "start_line": 13
                    }
                },
                "1266": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 174,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 60,
                                "end_line": 175,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 53,
                                "start_line": 175
                            },
                            "While expanding the reference 'factory' in:"
                        ],
                        "start_col": 16,
                        "start_line": 174
                    }
                },
                "1267": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 171,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 71,
                                "end_line": 175,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 62,
                                "start_line": 175
                            },
                            "While expanding the reference 'amountOut' in:"
                        ],
                        "start_col": 5,
                        "start_line": 171
                    }
                },
                "1268": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 171,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 71,
                                "end_line": 175,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 62,
                                "start_line": 175
                            },
                            "While expanding the reference 'amountOut' in:"
                        ],
                        "start_col": 5,
                        "start_line": 171
                    }
                },
                "1269": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 39,
                        "end_line": 171,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 81,
                                "end_line": 175,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 73,
                                "start_line": 175
                            },
                            "While expanding the reference 'path_len' in:"
                        ],
                        "start_col": 25,
                        "start_line": 171
                    }
                },
                "1270": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 52,
                        "end_line": 171,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 87,
                                "end_line": 175,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 83,
                                "start_line": 175
                            },
                            "While expanding the reference 'path' in:"
                        ],
                        "start_col": 41,
                        "start_line": 171
                    }
                },
                "1271": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 88,
                        "end_line": 175,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 37,
                        "start_line": 175
                    }
                },
                "1273": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 175,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 175
                    }
                },
                "1274": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 610,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 88,
                                "end_line": 175,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 39,
                                        "end_line": 170,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 32,
                                                "end_line": 176,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 176
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 21,
                                        "start_line": 170
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 37,
                                "start_line": 175
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 22,
                        "start_line": 610
                    }
                },
                "1275": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 68,
                        "end_line": 610,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 88,
                                "end_line": 175,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 67,
                                        "end_line": 170,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 32,
                                                "end_line": 176,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 176
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 41,
                                        "start_line": 170
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 37,
                                "start_line": 175
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 42,
                        "start_line": 610
                    }
                },
                "1276": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 85,
                        "end_line": 610,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 88,
                                "end_line": 175,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 84,
                                        "end_line": 170,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 32,
                                                "end_line": 176,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 176
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 69,
                                        "start_line": 170
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 37,
                                "start_line": 175
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 70,
                        "start_line": 610
                    }
                },
                "1277": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 39,
                        "end_line": 171,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 176,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 13,
                                "start_line": 176
                            },
                            "While expanding the reference 'path_len' in:"
                        ],
                        "start_col": 25,
                        "start_line": 171
                    }
                },
                "1278": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 175,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 30,
                                "end_line": 176,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 23,
                                "start_line": 176
                            },
                            "While expanding the reference 'amounts' in:"
                        ],
                        "start_col": 16,
                        "start_line": 175
                    }
                },
                "1279": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 176,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 176
                    }
                },
                "1280": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 38,
                                "end_line": 3,
                                "input_file": {
                                    "filename": "autogen/starknet/external/return/get_amounts_in/9f64b8a682241557028dea181432b931ff4d42c2c30c42ee41744acc9448ecd9.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 20,
                                        "end_line": 170,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 6,
                                        "start_line": 170
                                    },
                                    "While handling return value of"
                                ],
                                "start_col": 5,
                                "start_line": 3
                            },
                            "n_prefix_newlines": 0
                        }
                    ],
                    "inst": {
                        "end_col": 18,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/get_amounts_in/9f64b8a682241557028dea181432b931ff4d42c2c30c42ee41744acc9448ecd9.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 170,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 170
                            },
                            "While handling return value of"
                        ],
                        "start_col": 5,
                        "start_line": 4
                    }
                },
                "1282": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 53,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/d0188e44f9bf394c59a5ca0aa00029b3d4a0b84a048a492cac1961341ac80755.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 172,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 7,
                                "start_line": 172
                            },
                            "While handling return value 'amounts_len'"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "1283": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 50,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 172,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 26,
                                "start_line": 172
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 1,
                        "start_line": 2
                    }
                },
                "1284": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 45,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 172,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 26,
                                "start_line": 172
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 1,
                        "start_line": 5
                    }
                },
                "1286": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/d0188e44f9bf394c59a5ca0aa00029b3d4a0b84a048a492cac1961341ac80755.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 172,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 53,
                                        "end_line": 10,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 43,
                                                "end_line": 172,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 26,
                                                "start_line": 172
                                            },
                                            "While handling return value 'amounts'"
                                        ],
                                        "start_col": 35,
                                        "start_line": 10
                                    },
                                    "While expanding the reference '__return_value_ptr' in:"
                                ],
                                "start_col": 7,
                                "start_line": 172
                            },
                            "While handling return value 'amounts_len'"
                        ],
                        "start_col": 26,
                        "start_line": 2
                    }
                },
                "1288": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 81,
                        "end_line": 10,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 172,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 26,
                                "start_line": 172
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 56,
                        "start_line": 10
                    }
                },
                "1290": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 10,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 172,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 26,
                                "start_line": 172
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 1,
                        "start_line": 10
                    }
                },
                "1291": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/d0188e44f9bf394c59a5ca0aa00029b3d4a0b84a048a492cac1961341ac80755.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 172,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 49,
                                        "end_line": 7,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 43,
                                                "end_line": 172,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 32,
                                                        "end_line": 12,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 43,
                                                                "end_line": 172,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 26,
                                                                "start_line": 172
                                                            },
                                                            "While handling return value 'amounts'"
                                                        ],
                                                        "start_col": 9,
                                                        "start_line": 12
                                                    },
                                                    "While expanding the reference '__return_value_ptr_copy' in:"
                                                ],
                                                "start_col": 26,
                                                "start_line": 172
                                            },
                                            "While handling return value 'amounts'"
                                        ],
                                        "start_col": 31,
                                        "start_line": 7
                                    },
                                    "While expanding the reference '__return_value_ptr' in:"
                                ],
                                "start_col": 7,
                                "start_line": 172
                            },
                            "While handling return value 'amounts_len'"
                        ],
                        "start_col": 26,
                        "start_line": 2
                    }
                },
                "1293": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 26,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 172,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 26,
                                "start_line": 172
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 9,
                        "start_line": 13
                    }
                },
                "1294": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 14,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 172,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 26,
                                "start_line": 172
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 9,
                        "start_line": 14
                    }
                },
                "1296": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 14,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 172,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 26,
                                "start_line": 172
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 1,
                        "start_line": 11
                    }
                },
                "1298": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 172,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 40,
                                        "end_line": 10,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/return/get_amounts_in/9f64b8a682241557028dea181432b931ff4d42c2c30c42ee41744acc9448ecd9.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 170,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 170
                                            },
                                            "While handling return value of"
                                        ],
                                        "start_col": 25,
                                        "start_line": 10
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 26,
                                "start_line": 172
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 7,
                        "start_line": 5
                    }
                },
                "1299": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 63,
                        "end_line": 11,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/get_amounts_in/9f64b8a682241557028dea181432b931ff4d42c2c30c42ee41744acc9448ecd9.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 170,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 170
                            },
                            "While handling return value of"
                        ],
                        "start_col": 18,
                        "start_line": 11
                    }
                },
                "1300": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/get_amounts_in/9f64b8a682241557028dea181432b931ff4d42c2c30c42ee41744acc9448ecd9.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 170,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 38,
                                        "end_line": 12,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/return/get_amounts_in/9f64b8a682241557028dea181432b931ff4d42c2c30c42ee41744acc9448ecd9.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 170,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 170
                                            },
                                            "While handling return value of"
                                        ],
                                        "start_col": 14,
                                        "start_line": 12
                                    },
                                    "While expanding the reference '__return_value_ptr_start' in:"
                                ],
                                "start_col": 6,
                                "start_line": 170
                            },
                            "While handling return value of"
                        ],
                        "start_col": 11,
                        "start_line": 5
                    }
                },
                "1301": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 12,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/get_amounts_in/9f64b8a682241557028dea181432b931ff4d42c2c30c42ee41744acc9448ecd9.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 170,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 170
                            },
                            "While handling return value of"
                        ],
                        "start_col": 5,
                        "start_line": 9
                    }
                },
                "1302": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amounts_in/ed2d8695ac4216cad63e0ec5e45721feca952c35316762f3bebcc47d12b90c6a.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 170,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 170
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 5,
                        "start_line": 2
                    }
                },
                "1304": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amounts_in/741ea357d6336b0bed7bf0472425acd0311d543883b803388880e60a232040c7.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 84,
                                "end_line": 170,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 24,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 52,
                                                "end_line": 171,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 41,
                                                "start_line": 171
                                            },
                                            "While handling calldata argument 'path'"
                                        ],
                                        "start_col": 9,
                                        "start_line": 2
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 69,
                                "start_line": 170
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 23,
                        "start_line": 1
                    }
                },
                "1305": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 47,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/1a2c645314c38f7cbacc4fe8f48f8ae2ef9e43d925286d804280d816fb1e6157.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 39,
                                "end_line": 171,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 51,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 52,
                                                "end_line": 171,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 41,
                                                "start_line": 171
                                            },
                                            "While handling calldata argument 'path'"
                                        ],
                                        "start_col": 28,
                                        "start_line": 2
                                    },
                                    "While expanding the reference '__calldata_arg_path_len' in:"
                                ],
                                "start_col": 25,
                                "start_line": 171
                            },
                            "While handling calldata argument 'path_len'"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "1306": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 52,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 52,
                                "end_line": 171,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 41,
                                "start_line": 171
                            },
                            "While handling calldata argument 'path'"
                        ],
                        "start_col": 1,
                        "start_line": 2
                    }
                },
                "1307": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/1a2c645314c38f7cbacc4fe8f48f8ae2ef9e43d925286d804280d816fb1e6157.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 39,
                                "end_line": 171,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 40,
                                        "end_line": 8,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 52,
                                                "end_line": 171,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 41,
                                                "start_line": 171
                                            },
                                            "While handling calldata argument 'path'"
                                        ],
                                        "start_col": 26,
                                        "start_line": 8
                                    },
                                    "While expanding the reference '__calldata_ptr' in:"
                                ],
                                "start_col": 25,
                                "start_line": 171
                            },
                            "While handling calldata argument 'path_len'"
                        ],
                        "start_col": 22,
                        "start_line": 2
                    }
                },
                "1309": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 47,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/1a2c645314c38f7cbacc4fe8f48f8ae2ef9e43d925286d804280d816fb1e6157.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 39,
                                "end_line": 171,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 66,
                                        "end_line": 8,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 52,
                                                "end_line": 171,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 41,
                                                "start_line": 171
                                            },
                                            "While handling calldata argument 'path'"
                                        ],
                                        "start_col": 43,
                                        "start_line": 8
                                    },
                                    "While expanding the reference '__calldata_arg_path_len' in:"
                                ],
                                "start_col": 25,
                                "start_line": 171
                            },
                            "While handling calldata argument 'path_len'"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "1310": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 70,
                        "end_line": 8,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 52,
                                "end_line": 171,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 41,
                                "start_line": 171
                            },
                            "While handling calldata argument 'path'"
                        ],
                        "start_col": 26,
                        "start_line": 8
                    }
                },
                "1311": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 58,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/01cba52f8515996bb9d7070bde81ff39281d096d7024a558efcba6e1fd2402cf.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 170,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 170
                            },
                            "While handling calldata of"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "1312": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amounts_in/741ea357d6336b0bed7bf0472425acd0311d543883b803388880e60a232040c7.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 84,
                                "end_line": 170,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 38,
                                        "end_line": 3,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 52,
                                                "end_line": 171,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 115,
                                                        "end_line": 1,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/external/get_amounts_in/fa515db73ec45eee0abaaf34ced410aed1f5bceca24399565ecfe6499c8f15cd.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 20,
                                                                "end_line": 170,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 6,
                                                                "start_line": 170
                                                            },
                                                            "While constructing the external wrapper for:"
                                                        ],
                                                        "start_col": 100,
                                                        "start_line": 1
                                                    },
                                                    "While expanding the reference 'range_check_ptr' in:"
                                                ],
                                                "start_col": 41,
                                                "start_line": 171
                                            },
                                            "While handling calldata argument 'path'"
                                        ],
                                        "start_col": 23,
                                        "start_line": 3
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 69,
                                "start_line": 170
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 23,
                        "start_line": 1
                    }
                },
                "1313": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 64,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amounts_in/b2c52ca2d2a8fc8791a983086d8716c5eacd0c3d62934914d2286f84b98ff4cb.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 39,
                                "end_line": 170,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 55,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amounts_in/fa515db73ec45eee0abaaf34ced410aed1f5bceca24399565ecfe6499c8f15cd.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 170,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 170
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 44,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 21,
                                "start_line": 170
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 19,
                        "start_line": 1
                    }
                },
                "1314": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 110,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amounts_in/9684a85e93c782014ca14293edea4eb2502039a5a7b6538ecd39c56faaf12529.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 67,
                                "end_line": 170,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 82,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amounts_in/fa515db73ec45eee0abaaf34ced410aed1f5bceca24399565ecfe6499c8f15cd.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 170,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 170
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 70,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 41,
                                "start_line": 170
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 20,
                        "start_line": 1
                    }
                },
                "1315": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 42,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 52,
                                "end_line": 171,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 115,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amounts_in/fa515db73ec45eee0abaaf34ced410aed1f5bceca24399565ecfe6499c8f15cd.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 170,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 170
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 100,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 41,
                                "start_line": 171
                            },
                            "While handling calldata argument 'path'"
                        ],
                        "start_col": 23,
                        "start_line": 3
                    }
                },
                "1317": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/c6477613576c1865c350adaa5562664ab5c9cf232374f021ab29a9b963be2b8c.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 23,
                                "end_line": 171,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 151,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amounts_in/fa515db73ec45eee0abaaf34ced410aed1f5bceca24399565ecfe6499c8f15cd.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 170,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 170
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 127,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_amountOut' in:"
                                ],
                                "start_col": 5,
                                "start_line": 171
                            },
                            "While handling calldata argument 'amountOut'"
                        ],
                        "start_col": 32,
                        "start_line": 1
                    }
                },
                "1318": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/c6477613576c1865c350adaa5562664ab5c9cf232374f021ab29a9b963be2b8c.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 23,
                                "end_line": 171,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 151,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amounts_in/fa515db73ec45eee0abaaf34ced410aed1f5bceca24399565ecfe6499c8f15cd.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 170,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 170
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 127,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_amountOut' in:"
                                ],
                                "start_col": 5,
                                "start_line": 171
                            },
                            "While handling calldata argument 'amountOut'"
                        ],
                        "start_col": 32,
                        "start_line": 1
                    }
                },
                "1319": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 47,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/1a2c645314c38f7cbacc4fe8f48f8ae2ef9e43d925286d804280d816fb1e6157.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 39,
                                "end_line": 171,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 185,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amounts_in/fa515db73ec45eee0abaaf34ced410aed1f5bceca24399565ecfe6499c8f15cd.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 170,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 170
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 162,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_path_len' in:"
                                ],
                                "start_col": 25,
                                "start_line": 171
                            },
                            "While handling calldata argument 'path_len'"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "1320": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 52,
                                "end_line": 171,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 211,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amounts_in/fa515db73ec45eee0abaaf34ced410aed1f5bceca24399565ecfe6499c8f15cd.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 170,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 170
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 192,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_path' in:"
                                ],
                                "start_col": 41,
                                "start_line": 171
                            },
                            "While handling calldata argument 'path'"
                        ],
                        "start_col": 27,
                        "start_line": 5
                    }
                },
                "1322": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 170,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 6,
                        "start_line": 170
                    }
                },
                "1324": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 55,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amounts_in/fa515db73ec45eee0abaaf34ced410aed1f5bceca24399565ecfe6499c8f15cd.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 170,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 55,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amounts_in/fa515db73ec45eee0abaaf34ced410aed1f5bceca24399565ecfe6499c8f15cd.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 170,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 170
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 44,
                                        "start_line": 1
                                    },
                                    "While auto generating local variable for 'syscall_ptr'."
                                ],
                                "start_col": 6,
                                "start_line": 170
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 44,
                        "start_line": 1
                    }
                },
                "1325": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amounts_in/fa515db73ec45eee0abaaf34ced410aed1f5bceca24399565ecfe6499c8f15cd.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 170,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 82,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amounts_in/fa515db73ec45eee0abaaf34ced410aed1f5bceca24399565ecfe6499c8f15cd.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 170,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 170
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 70,
                                        "start_line": 1
                                    },
                                    "While auto generating local variable for 'pedersen_ptr'."
                                ],
                                "start_col": 6,
                                "start_line": 170
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 70,
                        "start_line": 1
                    }
                },
                "1326": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 115,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amounts_in/fa515db73ec45eee0abaaf34ced410aed1f5bceca24399565ecfe6499c8f15cd.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 170,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 103,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amounts_in/fa515db73ec45eee0abaaf34ced410aed1f5bceca24399565ecfe6499c8f15cd.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 170,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 170
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 88,
                                        "start_line": 2
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 170
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 100,
                        "start_line": 1
                    }
                },
                "1327": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 104,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amounts_in/fa515db73ec45eee0abaaf34ced410aed1f5bceca24399565ecfe6499c8f15cd.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 170,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 170
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 48,
                        "start_line": 2
                    }
                },
                "1329": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 55,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amounts_in/fa515db73ec45eee0abaaf34ced410aed1f5bceca24399565ecfe6499c8f15cd.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 170,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 55,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amounts_in/fa515db73ec45eee0abaaf34ced410aed1f5bceca24399565ecfe6499c8f15cd.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 170,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 20,
                                                        "end_line": 1,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/external/get_amounts_in/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 20,
                                                                "end_line": 170,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 6,
                                                                "start_line": 170
                                                            },
                                                            "While constructing the external wrapper for:"
                                                        ],
                                                        "start_col": 9,
                                                        "start_line": 1
                                                    },
                                                    "While expanding the reference 'syscall_ptr' in:"
                                                ],
                                                "start_col": 6,
                                                "start_line": 170
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 44,
                                        "start_line": 1
                                    },
                                    "While auto generating local variable for 'syscall_ptr'."
                                ],
                                "start_col": 6,
                                "start_line": 170
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 44,
                        "start_line": 1
                    }
                },
                "1330": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amounts_in/fa515db73ec45eee0abaaf34ced410aed1f5bceca24399565ecfe6499c8f15cd.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 170,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 82,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amounts_in/fa515db73ec45eee0abaaf34ced410aed1f5bceca24399565ecfe6499c8f15cd.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 170,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 33,
                                                        "end_line": 1,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/external/get_amounts_in/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 20,
                                                                "end_line": 170,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 6,
                                                                "start_line": 170
                                                            },
                                                            "While constructing the external wrapper for:"
                                                        ],
                                                        "start_col": 21,
                                                        "start_line": 1
                                                    },
                                                    "While expanding the reference 'pedersen_ptr' in:"
                                                ],
                                                "start_col": 6,
                                                "start_line": 170
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 70,
                                        "start_line": 1
                                    },
                                    "While auto generating local variable for 'pedersen_ptr'."
                                ],
                                "start_col": 6,
                                "start_line": 170
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 70,
                        "start_line": 1
                    }
                },
                "1331": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amounts_in/fa515db73ec45eee0abaaf34ced410aed1f5bceca24399565ecfe6499c8f15cd.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 170,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 49,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amounts_in/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 170,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 170
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 34,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 170
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 6,
                        "start_line": 2
                    }
                },
                "1332": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amounts_in/fa515db73ec45eee0abaaf34ced410aed1f5bceca24399565ecfe6499c8f15cd.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 170,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 62,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amounts_in/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 170,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 170
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 50,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'retdata_size' in:"
                                ],
                                "start_col": 6,
                                "start_line": 170
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 23,
                        "start_line": 2
                    }
                },
                "1333": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 44,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amounts_in/fa515db73ec45eee0abaaf34ced410aed1f5bceca24399565ecfe6499c8f15cd.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 170,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 70,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/get_amounts_in/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 170,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 170
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 63,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'retdata' in:"
                                ],
                                "start_col": 6,
                                "start_line": 170
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 37,
                        "start_line": 2
                    }
                },
                "1334": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/get_amounts_in/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 170,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 170
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "1335": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 207,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 207
                    }
                },
                "1337": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 197,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 41,
                                "end_line": 337,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 31,
                                        "end_line": 208,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 208
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 23,
                                "start_line": 337
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 20,
                        "start_line": 197
                    }
                },
                "1338": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 66,
                        "end_line": 197,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 69,
                                "end_line": 337,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 31,
                                        "end_line": 208,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 208
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 43,
                                "start_line": 337
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 40,
                        "start_line": 197
                    }
                },
                "1339": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 83,
                        "end_line": 197,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 86,
                                "end_line": 337,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 31,
                                        "end_line": 208,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 208
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 71,
                                "start_line": 337
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 68,
                        "start_line": 197
                    }
                },
                "1340": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 19,
                        "end_line": 205,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 30,
                                "end_line": 208,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 22,
                                "start_line": 208
                            },
                            "While expanding the reference 'deadline' in:"
                        ],
                        "start_col": 5,
                        "start_line": 205
                    }
                },
                "1341": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 31,
                        "end_line": 208,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 208
                    }
                },
                "1343": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 42,
                        "end_line": 209,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 27,
                        "start_line": 209
                    }
                },
                "1345": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 209,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 209
                    }
                },
                "1346": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 209,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 39,
                                        "end_line": 347,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 6,
                                                "end_line": 212,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 60,
                                                "start_line": 210
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 21,
                                        "start_line": 347
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 27,
                                "start_line": 209
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 13
                    }
                },
                "1347": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 209,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 67,
                                        "end_line": 347,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 6,
                                                "end_line": 212,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 60,
                                                "start_line": 210
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 41,
                                        "start_line": 347
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 27,
                                "start_line": 209
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 35,
                        "start_line": 13
                    }
                },
                "1348": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 78,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 209,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 84,
                                        "end_line": 347,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 6,
                                                "end_line": 212,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 60,
                                                "start_line": 210
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 69,
                                        "start_line": 347
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 27,
                                "start_line": 209
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 63,
                        "start_line": 13
                    }
                },
                "1349": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 17,
                        "end_line": 198,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 15,
                                "end_line": 211,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 9,
                                "start_line": 211
                            },
                            "While expanding the reference 'tokenA' in:"
                        ],
                        "start_col": 5,
                        "start_line": 198
                    }
                },
                "1350": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 17,
                        "end_line": 199,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 23,
                                "end_line": 211,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 17,
                                "start_line": 211
                            },
                            "While expanding the reference 'tokenB' in:"
                        ],
                        "start_col": 5,
                        "start_line": 199
                    }
                },
                "1351": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 200,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 39,
                                "end_line": 211,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 25,
                                "start_line": 211
                            },
                            "While expanding the reference 'amountADesired' in:"
                        ],
                        "start_col": 5,
                        "start_line": 200
                    }
                },
                "1352": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 200,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 39,
                                "end_line": 211,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 25,
                                "start_line": 211
                            },
                            "While expanding the reference 'amountADesired' in:"
                        ],
                        "start_col": 5,
                        "start_line": 200
                    }
                },
                "1353": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 201,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 55,
                                "end_line": 211,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 41,
                                "start_line": 211
                            },
                            "While expanding the reference 'amountBDesired' in:"
                        ],
                        "start_col": 5,
                        "start_line": 201
                    }
                },
                "1354": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 201,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 55,
                                "end_line": 211,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 41,
                                "start_line": 211
                            },
                            "While expanding the reference 'amountBDesired' in:"
                        ],
                        "start_col": 5,
                        "start_line": 201
                    }
                },
                "1355": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 202,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 67,
                                "end_line": 211,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 57,
                                "start_line": 211
                            },
                            "While expanding the reference 'amountAMin' in:"
                        ],
                        "start_col": 5,
                        "start_line": 202
                    }
                },
                "1356": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 202,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 67,
                                "end_line": 211,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 57,
                                "start_line": 211
                            },
                            "While expanding the reference 'amountAMin' in:"
                        ],
                        "start_col": 5,
                        "start_line": 202
                    }
                },
                "1357": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 203,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 79,
                                "end_line": 211,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 69,
                                "start_line": 211
                            },
                            "While expanding the reference 'amountBMin' in:"
                        ],
                        "start_col": 5,
                        "start_line": 203
                    }
                },
                "1358": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 203,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 79,
                                "end_line": 211,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 69,
                                "start_line": 211
                            },
                            "While expanding the reference 'amountBMin' in:"
                        ],
                        "start_col": 5,
                        "start_line": 203
                    }
                },
                "1359": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 6,
                        "end_line": 212,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 60,
                        "start_line": 210
                    }
                },
                "1361": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 210,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 210
                    }
                },
                "1362": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 210,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 210
                    }
                },
                "1363": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 56,
                        "end_line": 210,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 34,
                        "start_line": 210
                    }
                },
                "1364": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 56,
                        "end_line": 210,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 34,
                        "start_line": 210
                    }
                },
                "1365": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 39,
                        "end_line": 347,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 6,
                                "end_line": 212,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 34,
                                        "end_line": 460,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 58,
                                                "end_line": 213,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 24,
                                                "start_line": 213
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 16,
                                        "start_line": 460
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 60,
                                "start_line": 210
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 21,
                        "start_line": 347
                    }
                },
                "1366": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 347,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 6,
                                "end_line": 212,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 62,
                                        "end_line": 460,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 58,
                                                "end_line": 213,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 24,
                                                "start_line": 213
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 36,
                                        "start_line": 460
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 60,
                                "start_line": 210
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 41,
                        "start_line": 347
                    }
                },
                "1367": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 84,
                        "end_line": 347,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 6,
                                "end_line": 212,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 79,
                                        "end_line": 460,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 58,
                                                "end_line": 213,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 24,
                                                "start_line": 213
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 64,
                                        "start_line": 460
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 60,
                                "start_line": 210
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 69,
                        "start_line": 347
                    }
                },
                "1368": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 209,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 41,
                                "end_line": 213,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 34,
                                "start_line": 213
                            },
                            "While expanding the reference 'factory' in:"
                        ],
                        "start_col": 16,
                        "start_line": 209
                    }
                },
                "1369": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 17,
                        "end_line": 198,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 49,
                                "end_line": 213,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 43,
                                "start_line": 213
                            },
                            "While expanding the reference 'tokenA' in:"
                        ],
                        "start_col": 5,
                        "start_line": 198
                    }
                },
                "1370": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 17,
                        "end_line": 199,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 57,
                                "end_line": 213,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 51,
                                "start_line": 213
                            },
                            "While expanding the reference 'tokenB' in:"
                        ],
                        "start_col": 5,
                        "start_line": 199
                    }
                },
                "1371": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 58,
                        "end_line": 213,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 24,
                        "start_line": 213
                    }
                },
                "1373": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 213,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 213
                    }
                },
                "1374": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 460,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 58,
                                "end_line": 213,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 43,
                                        "end_line": 200,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 40,
                                                "end_line": 214,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 20,
                                                "start_line": 214
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 25,
                                        "start_line": 200
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 24,
                                "start_line": 213
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 16,
                        "start_line": 460
                    }
                },
                "1375": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 214,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 20,
                        "start_line": 214
                    }
                },
                "1377": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 43,
                        "end_line": 200,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 40,
                                "end_line": 214,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 37,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/f8c7980cd46ed6d764c999318b5692736b724dc08c1bf96e92d1b77ddf37af10.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 34,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 96,
                                                        "end_line": 215,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 5,
                                                        "start_line": 215
                                                    },
                                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                ],
                                                "start_col": 10,
                                                "start_line": 34
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 19,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 20,
                                "start_line": 214
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 25,
                        "start_line": 200
                    }
                },
                "1378": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 79,
                        "end_line": 460,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 58,
                                "end_line": 213,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 54,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/f8c7980cd46ed6d764c999318b5692736b724dc08c1bf96e92d1b77ddf37af10.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 34,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 96,
                                                        "end_line": 215,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 5,
                                                        "start_line": 215
                                                    },
                                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                ],
                                                "start_col": 10,
                                                "start_line": 34
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 39,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 24,
                                "start_line": 213
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 64,
                        "start_line": 460
                    }
                },
                "1379": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 17,
                        "end_line": 198,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 48,
                                "end_line": 215,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 42,
                                "start_line": 215
                            },
                            "While expanding the reference 'tokenA' in:"
                        ],
                        "start_col": 5,
                        "start_line": 198
                    }
                },
                "1380": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 16,
                        "end_line": 214,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 63,
                                "end_line": 215,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 57,
                                "start_line": 215
                            },
                            "While expanding the reference 'sender' in:"
                        ],
                        "start_col": 10,
                        "start_line": 214
                    }
                },
                "1381": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 213,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 79,
                                "end_line": 215,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 75,
                                "start_line": 215
                            },
                            "While expanding the reference 'pair' in:"
                        ],
                        "start_col": 16,
                        "start_line": 213
                    }
                },
                "1382": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 210,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 95,
                                "end_line": 215,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 88,
                                "start_line": 215
                            },
                            "While expanding the reference 'amountA' in:"
                        ],
                        "start_col": 16,
                        "start_line": 210
                    }
                },
                "1383": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 210,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 95,
                                "end_line": 215,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 88,
                                "start_line": 215
                            },
                            "While expanding the reference 'amountA' in:"
                        ],
                        "start_col": 16,
                        "start_line": 210
                    }
                },
                "1384": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 96,
                        "end_line": 215,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 215
                    }
                },
                "1386": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/f8c7980cd46ed6d764c999318b5692736b724dc08c1bf96e92d1b77ddf37af10.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 34,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 96,
                                        "end_line": 215,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 37,
                                                "end_line": 1,
                                                "input_file": {
                                                    "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/f8c7980cd46ed6d764c999318b5692736b724dc08c1bf96e92d1b77ddf37af10.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 22,
                                                        "end_line": 34,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 96,
                                                                "end_line": 216,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 5,
                                                                "start_line": 216
                                                            },
                                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                        ],
                                                        "start_col": 10,
                                                        "start_line": 34
                                                    },
                                                    "While handling contract interface function:"
                                                ],
                                                "start_col": 19,
                                                "start_line": 1
                                            },
                                            "While expanding the reference 'syscall_ptr' in:"
                                        ],
                                        "start_col": 5,
                                        "start_line": 215
                                    },
                                    "While trying to update the implicit return value 'syscall_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 34
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 19,
                        "start_line": 1
                    }
                },
                "1387": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/f8c7980cd46ed6d764c999318b5692736b724dc08c1bf96e92d1b77ddf37af10.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 34,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 96,
                                        "end_line": 215,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 54,
                                                "end_line": 1,
                                                "input_file": {
                                                    "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/f8c7980cd46ed6d764c999318b5692736b724dc08c1bf96e92d1b77ddf37af10.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 22,
                                                        "end_line": 34,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 96,
                                                                "end_line": 216,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 5,
                                                                "start_line": 216
                                                            },
                                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                        ],
                                                        "start_col": 10,
                                                        "start_line": 34
                                                    },
                                                    "While handling contract interface function:"
                                                ],
                                                "start_col": 39,
                                                "start_line": 1
                                            },
                                            "While expanding the reference 'range_check_ptr' in:"
                                        ],
                                        "start_col": 5,
                                        "start_line": 215
                                    },
                                    "While trying to update the implicit return value 'range_check_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 34
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 39,
                        "start_line": 1
                    }
                },
                "1388": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 17,
                        "end_line": 199,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 48,
                                "end_line": 216,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 42,
                                "start_line": 216
                            },
                            "While expanding the reference 'tokenB' in:"
                        ],
                        "start_col": 5,
                        "start_line": 199
                    }
                },
                "1389": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 16,
                        "end_line": 214,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 63,
                                "end_line": 216,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 57,
                                "start_line": 216
                            },
                            "While expanding the reference 'sender' in:"
                        ],
                        "start_col": 10,
                        "start_line": 214
                    }
                },
                "1390": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 213,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 79,
                                "end_line": 216,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 75,
                                "start_line": 216
                            },
                            "While expanding the reference 'pair' in:"
                        ],
                        "start_col": 16,
                        "start_line": 213
                    }
                },
                "1391": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 47,
                        "end_line": 210,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 95,
                                "end_line": 216,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 88,
                                "start_line": 216
                            },
                            "While expanding the reference 'amountB' in:"
                        ],
                        "start_col": 40,
                        "start_line": 210
                    }
                },
                "1392": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 47,
                        "end_line": 210,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 95,
                                "end_line": 216,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 88,
                                "start_line": 216
                            },
                            "While expanding the reference 'amountB' in:"
                        ],
                        "start_col": 40,
                        "start_line": 210
                    }
                },
                "1393": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 96,
                        "end_line": 216,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 216
                    }
                },
                "1395": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/f8c7980cd46ed6d764c999318b5692736b724dc08c1bf96e92d1b77ddf37af10.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 34,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 96,
                                        "end_line": 216,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 29,
                                                "end_line": 1,
                                                "input_file": {
                                                    "filename": "autogen/starknet/contract_interface/IPair/mint/f331841f676ee38d6b90045cd4589cd9ff4e83c32234273d9e605b287d886cbc.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 14,
                                                        "end_line": 43,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 78,
                                                                "end_line": 217,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 38,
                                                                "start_line": 217
                                                            },
                                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                        ],
                                                        "start_col": 10,
                                                        "start_line": 43
                                                    },
                                                    "While handling contract interface function:"
                                                ],
                                                "start_col": 11,
                                                "start_line": 1
                                            },
                                            "While expanding the reference 'syscall_ptr' in:"
                                        ],
                                        "start_col": 5,
                                        "start_line": 216
                                    },
                                    "While trying to update the implicit return value 'syscall_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 34
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 19,
                        "start_line": 1
                    }
                },
                "1396": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/f8c7980cd46ed6d764c999318b5692736b724dc08c1bf96e92d1b77ddf37af10.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 34,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 96,
                                        "end_line": 216,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 46,
                                                "end_line": 1,
                                                "input_file": {
                                                    "filename": "autogen/starknet/contract_interface/IPair/mint/f331841f676ee38d6b90045cd4589cd9ff4e83c32234273d9e605b287d886cbc.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 14,
                                                        "end_line": 43,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 78,
                                                                "end_line": 217,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 38,
                                                                "start_line": 217
                                                            },
                                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                        ],
                                                        "start_col": 10,
                                                        "start_line": 43
                                                    },
                                                    "While handling contract interface function:"
                                                ],
                                                "start_col": 31,
                                                "start_line": 1
                                            },
                                            "While expanding the reference 'range_check_ptr' in:"
                                        ],
                                        "start_col": 5,
                                        "start_line": 216
                                    },
                                    "While trying to update the implicit return value 'range_check_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 34
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 39,
                        "start_line": 1
                    }
                },
                "1397": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 213,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 70,
                                "end_line": 217,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 66,
                                "start_line": 217
                            },
                            "While expanding the reference 'pair' in:"
                        ],
                        "start_col": 16,
                        "start_line": 213
                    }
                },
                "1398": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 13,
                        "end_line": 204,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 77,
                                "end_line": 217,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 75,
                                "start_line": 217
                            },
                            "While expanding the reference 'to' in:"
                        ],
                        "start_col": 5,
                        "start_line": 204
                    }
                },
                "1399": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 78,
                        "end_line": 217,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 38,
                        "start_line": 217
                    }
                },
                "1401": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 217,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 217
                    }
                },
                "1402": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 217,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 217
                    }
                },
                "1403": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 29,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/mint/f331841f676ee38d6b90045cd4589cd9ff4e83c32234273d9e605b287d886cbc.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 43,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 78,
                                        "end_line": 217,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 38,
                                                "end_line": 197,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 42,
                                                        "end_line": 218,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 5,
                                                        "start_line": 218
                                                    },
                                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                ],
                                                "start_col": 20,
                                                "start_line": 197
                                            },
                                            "While expanding the reference 'syscall_ptr' in:"
                                        ],
                                        "start_col": 38,
                                        "start_line": 217
                                    },
                                    "While trying to update the implicit return value 'syscall_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 43
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 11,
                        "start_line": 1
                    }
                },
                "1404": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 62,
                        "end_line": 460,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 58,
                                "end_line": 213,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 66,
                                        "end_line": 197,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 42,
                                                "end_line": 218,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 218
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 40,
                                        "start_line": 197
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 24,
                                "start_line": 213
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 36,
                        "start_line": 460
                    }
                },
                "1405": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 46,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/mint/f331841f676ee38d6b90045cd4589cd9ff4e83c32234273d9e605b287d886cbc.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 43,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 78,
                                        "end_line": 217,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 83,
                                                "end_line": 197,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 42,
                                                        "end_line": 218,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 5,
                                                        "start_line": 218
                                                    },
                                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                ],
                                                "start_col": 68,
                                                "start_line": 197
                                            },
                                            "While expanding the reference 'range_check_ptr' in:"
                                        ],
                                        "start_col": 38,
                                        "start_line": 217
                                    },
                                    "While trying to update the implicit return value 'range_check_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 43
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "1406": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 210,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 218,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 13,
                                "start_line": 218
                            },
                            "While expanding the reference 'amountA' in:"
                        ],
                        "start_col": 16,
                        "start_line": 210
                    }
                },
                "1407": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 210,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 218,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 13,
                                "start_line": 218
                            },
                            "While expanding the reference 'amountA' in:"
                        ],
                        "start_col": 16,
                        "start_line": 210
                    }
                },
                "1408": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 47,
                        "end_line": 210,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 29,
                                "end_line": 218,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 22,
                                "start_line": 218
                            },
                            "While expanding the reference 'amountB' in:"
                        ],
                        "start_col": 40,
                        "start_line": 210
                    }
                },
                "1409": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 47,
                        "end_line": 210,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 29,
                                "end_line": 218,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 22,
                                "start_line": 218
                            },
                            "While expanding the reference 'amountB' in:"
                        ],
                        "start_col": 40,
                        "start_line": 210
                    }
                },
                "1410": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 25,
                        "end_line": 217,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 40,
                                "end_line": 218,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 31,
                                "start_line": 218
                            },
                            "While expanding the reference 'liquidity' in:"
                        ],
                        "start_col": 16,
                        "start_line": 217
                    }
                },
                "1411": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 25,
                        "end_line": 217,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 40,
                                "end_line": 218,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 31,
                                "start_line": 218
                            },
                            "While expanding the reference 'liquidity' in:"
                        ],
                        "start_col": 16,
                        "start_line": 217
                    }
                },
                "1412": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 42,
                        "end_line": 218,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 218
                    }
                },
                "1413": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 38,
                                "end_line": 3,
                                "input_file": {
                                    "filename": "autogen/starknet/external/return/add_liquidity/29fd096df52ff90a7412801ec1cc013fc12f5cad93beabab671ad152ae5c25ee.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 19,
                                        "end_line": 197,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 6,
                                        "start_line": 197
                                    },
                                    "While handling return value of"
                                ],
                                "start_col": 5,
                                "start_line": 3
                            },
                            "n_prefix_newlines": 0
                        }
                    ],
                    "inst": {
                        "end_col": 18,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/add_liquidity/29fd096df52ff90a7412801ec1cc013fc12f5cad93beabab671ad152ae5c25ee.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 197,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 197
                            },
                            "While handling return value of"
                        ],
                        "start_col": 5,
                        "start_line": 4
                    }
                },
                "1415": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 60,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/9971733a46b660735efbd843b968648fbb6ab1512994825bb1137bbaa294d2fd.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 23,
                                "end_line": 206,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 7,
                                "start_line": 206
                            },
                            "While handling return value 'amountA'"
                        ],
                        "start_col": 1,
                        "start_line": 3
                    }
                },
                "1416": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 60,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/9971733a46b660735efbd843b968648fbb6ab1512994825bb1137bbaa294d2fd.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 23,
                                "end_line": 206,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 7,
                                "start_line": 206
                            },
                            "While handling return value 'amountA'"
                        ],
                        "start_col": 1,
                        "start_line": 4
                    }
                },
                "1417": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 60,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/3d6e81bf1402793cfbd97151fd631ccb7424cd79d2c40557aa18360f58ac0d92.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 41,
                                "end_line": 206,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 25,
                                "start_line": 206
                            },
                            "While handling return value 'amountB'"
                        ],
                        "start_col": 1,
                        "start_line": 3
                    }
                },
                "1418": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 60,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/3d6e81bf1402793cfbd97151fd631ccb7424cd79d2c40557aa18360f58ac0d92.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 41,
                                "end_line": 206,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 25,
                                "start_line": 206
                            },
                            "While handling return value 'amountB'"
                        ],
                        "start_col": 1,
                        "start_line": 4
                    }
                },
                "1419": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 60,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/986c88e0c98997b84114350f0cec05c7e65a027842002306de74d06aec114045.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 61,
                                "end_line": 206,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 43,
                                "start_line": 206
                            },
                            "While handling return value 'liquidity'"
                        ],
                        "start_col": 1,
                        "start_line": 3
                    }
                },
                "1420": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 60,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/986c88e0c98997b84114350f0cec05c7e65a027842002306de74d06aec114045.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 61,
                                "end_line": 206,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 43,
                                "start_line": 206
                            },
                            "While handling return value 'liquidity'"
                        ],
                        "start_col": 1,
                        "start_line": 4
                    }
                },
                "1421": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/986c88e0c98997b84114350f0cec05c7e65a027842002306de74d06aec114045.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 61,
                                "end_line": 206,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 36,
                                        "end_line": 11,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/return/add_liquidity/29fd096df52ff90a7412801ec1cc013fc12f5cad93beabab671ad152ae5c25ee.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 197,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 197
                                            },
                                            "While handling return value of"
                                        ],
                                        "start_col": 18,
                                        "start_line": 11
                                    },
                                    "While expanding the reference '__return_value_ptr' in:"
                                ],
                                "start_col": 43,
                                "start_line": 206
                            },
                            "While handling return value 'liquidity'"
                        ],
                        "start_col": 26,
                        "start_line": 5
                    }
                },
                "1423": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 211,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/add_liquidity/29fd096df52ff90a7412801ec1cc013fc12f5cad93beabab671ad152ae5c25ee.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 197,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 40,
                                        "end_line": 10,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/return/add_liquidity/29fd096df52ff90a7412801ec1cc013fc12f5cad93beabab671ad152ae5c25ee.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 197,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 197
                                            },
                                            "While handling return value of"
                                        ],
                                        "start_col": 25,
                                        "start_line": 10
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 197
                            },
                            "While handling return value of"
                        ],
                        "start_col": 196,
                        "start_line": 1
                    }
                },
                "1424": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 63,
                        "end_line": 11,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/add_liquidity/29fd096df52ff90a7412801ec1cc013fc12f5cad93beabab671ad152ae5c25ee.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 197,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 197
                            },
                            "While handling return value of"
                        ],
                        "start_col": 18,
                        "start_line": 11
                    }
                },
                "1425": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/add_liquidity/29fd096df52ff90a7412801ec1cc013fc12f5cad93beabab671ad152ae5c25ee.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 197,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 38,
                                        "end_line": 12,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/return/add_liquidity/29fd096df52ff90a7412801ec1cc013fc12f5cad93beabab671ad152ae5c25ee.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 197,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 197
                                            },
                                            "While handling return value of"
                                        ],
                                        "start_col": 14,
                                        "start_line": 12
                                    },
                                    "While expanding the reference '__return_value_ptr_start' in:"
                                ],
                                "start_col": 6,
                                "start_line": 197
                            },
                            "While handling return value of"
                        ],
                        "start_col": 11,
                        "start_line": 5
                    }
                },
                "1426": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 12,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/add_liquidity/29fd096df52ff90a7412801ec1cc013fc12f5cad93beabab671ad152ae5c25ee.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 197,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 197
                            },
                            "While handling return value of"
                        ],
                        "start_col": 5,
                        "start_line": 9
                    }
                },
                "1427": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/17555969c7a8d33c61486b2958282949e335d6b0bb9fd10ced86d32ba19f948c.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 205,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 45,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/c31620b02d4d706f0542c989b2aadc01b0981d1f6a5933a8fe4937ace3d70d92.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 197,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 57,
                                                        "end_line": 1,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/arg_processor/01cba52f8515996bb9d7070bde81ff39281d096d7024a558efcba6e1fd2402cf.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 19,
                                                                "end_line": 197,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 6,
                                                                "start_line": 197
                                                            },
                                                            "While handling calldata of"
                                                        ],
                                                        "start_col": 35,
                                                        "start_line": 1
                                                    },
                                                    "While expanding the reference '__calldata_actual_size' in:"
                                                ],
                                                "start_col": 6,
                                                "start_line": 197
                                            },
                                            "While handling calldata of"
                                        ],
                                        "start_col": 31,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_ptr' in:"
                                ],
                                "start_col": 5,
                                "start_line": 205
                            },
                            "While handling calldata argument 'deadline'"
                        ],
                        "start_col": 22,
                        "start_line": 2
                    }
                },
                "1429": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 58,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/01cba52f8515996bb9d7070bde81ff39281d096d7024a558efcba6e1fd2402cf.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 197,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 197
                            },
                            "While handling calldata of"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "1430": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 64,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/add_liquidity/b2c52ca2d2a8fc8791a983086d8716c5eacd0c3d62934914d2286f84b98ff4cb.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 38,
                                "end_line": 197,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 55,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/add_liquidity/969479459fd3b867ae28190d9fc610d9ccb75b0d7783925f9dbefd7a80e1ce80.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 197,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 197
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 44,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 20,
                                "start_line": 197
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 19,
                        "start_line": 1
                    }
                },
                "1431": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 110,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/add_liquidity/9684a85e93c782014ca14293edea4eb2502039a5a7b6538ecd39c56faaf12529.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 66,
                                "end_line": 197,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 82,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/add_liquidity/969479459fd3b867ae28190d9fc610d9ccb75b0d7783925f9dbefd7a80e1ce80.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 197,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 197
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 70,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 40,
                                "start_line": 197
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 20,
                        "start_line": 1
                    }
                },
                "1432": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/add_liquidity/741ea357d6336b0bed7bf0472425acd0311d543883b803388880e60a232040c7.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 83,
                                "end_line": 197,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 115,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/add_liquidity/969479459fd3b867ae28190d9fc610d9ccb75b0d7783925f9dbefd7a80e1ce80.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 197,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 197
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 100,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 68,
                                "start_line": 197
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 23,
                        "start_line": 1
                    }
                },
                "1433": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 45,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/5ec6d3da377064fcca5c6ce7ac91ed695c6086c63198cd266d6f1f3f80005101.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 198,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 145,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/add_liquidity/969479459fd3b867ae28190d9fc610d9ccb75b0d7783925f9dbefd7a80e1ce80.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 197,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 197
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 124,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_tokenA' in:"
                                ],
                                "start_col": 5,
                                "start_line": 198
                            },
                            "While handling calldata argument 'tokenA'"
                        ],
                        "start_col": 29,
                        "start_line": 1
                    }
                },
                "1434": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 45,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/f592e43c96a0e5d88593ef440a75312f8beb20e2d1d087f2f18887daf8570e62.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 199,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 175,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/add_liquidity/969479459fd3b867ae28190d9fc610d9ccb75b0d7783925f9dbefd7a80e1ce80.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 197,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 197
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 154,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_tokenB' in:"
                                ],
                                "start_col": 5,
                                "start_line": 199
                            },
                            "While handling calldata argument 'tokenB'"
                        ],
                        "start_col": 29,
                        "start_line": 1
                    }
                },
                "1435": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/cd240601384e2a470f06701d141f849870ca17bb9e1bfcb817820bb610b44b47.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 28,
                                "end_line": 200,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 221,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/add_liquidity/969479459fd3b867ae28190d9fc610d9ccb75b0d7783925f9dbefd7a80e1ce80.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 197,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 197
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 192,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_amountADesired' in:"
                                ],
                                "start_col": 5,
                                "start_line": 200
                            },
                            "While handling calldata argument 'amountADesired'"
                        ],
                        "start_col": 37,
                        "start_line": 1
                    }
                },
                "1436": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/cd240601384e2a470f06701d141f849870ca17bb9e1bfcb817820bb610b44b47.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 28,
                                "end_line": 200,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 221,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/add_liquidity/969479459fd3b867ae28190d9fc610d9ccb75b0d7783925f9dbefd7a80e1ce80.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 197,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 197
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 192,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_amountADesired' in:"
                                ],
                                "start_col": 5,
                                "start_line": 200
                            },
                            "While handling calldata argument 'amountADesired'"
                        ],
                        "start_col": 37,
                        "start_line": 1
                    }
                },
                "1437": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/42a70584789ec6af58b985f7cfafbd6fdb41a760213209f7cdca7fe844ae35b0.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 28,
                                "end_line": 201,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 267,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/add_liquidity/969479459fd3b867ae28190d9fc610d9ccb75b0d7783925f9dbefd7a80e1ce80.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 197,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 197
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 238,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_amountBDesired' in:"
                                ],
                                "start_col": 5,
                                "start_line": 201
                            },
                            "While handling calldata argument 'amountBDesired'"
                        ],
                        "start_col": 37,
                        "start_line": 1
                    }
                },
                "1438": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/42a70584789ec6af58b985f7cfafbd6fdb41a760213209f7cdca7fe844ae35b0.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 28,
                                "end_line": 201,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 267,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/add_liquidity/969479459fd3b867ae28190d9fc610d9ccb75b0d7783925f9dbefd7a80e1ce80.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 197,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 197
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 238,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_amountBDesired' in:"
                                ],
                                "start_col": 5,
                                "start_line": 201
                            },
                            "While handling calldata argument 'amountBDesired'"
                        ],
                        "start_col": 37,
                        "start_line": 1
                    }
                },
                "1439": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/9a221054e9d7400afb4c8befc8f37dcc2ce98e6984fd9029ab1599acbf90b78c.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 202,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 305,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/add_liquidity/969479459fd3b867ae28190d9fc610d9ccb75b0d7783925f9dbefd7a80e1ce80.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 197,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 197
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 280,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_amountAMin' in:"
                                ],
                                "start_col": 5,
                                "start_line": 202
                            },
                            "While handling calldata argument 'amountAMin'"
                        ],
                        "start_col": 33,
                        "start_line": 1
                    }
                },
                "1440": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/9a221054e9d7400afb4c8befc8f37dcc2ce98e6984fd9029ab1599acbf90b78c.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 202,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 305,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/add_liquidity/969479459fd3b867ae28190d9fc610d9ccb75b0d7783925f9dbefd7a80e1ce80.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 197,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 197
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 280,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_amountAMin' in:"
                                ],
                                "start_col": 5,
                                "start_line": 202
                            },
                            "While handling calldata argument 'amountAMin'"
                        ],
                        "start_col": 33,
                        "start_line": 1
                    }
                },
                "1441": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/3d3dc23ab365a951396ce59f8eedd1e831e497c4570a1739aedd8a47e436f041.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 203,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 343,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/add_liquidity/969479459fd3b867ae28190d9fc610d9ccb75b0d7783925f9dbefd7a80e1ce80.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 197,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 197
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 318,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_amountBMin' in:"
                                ],
                                "start_col": 5,
                                "start_line": 203
                            },
                            "While handling calldata argument 'amountBMin'"
                        ],
                        "start_col": 33,
                        "start_line": 1
                    }
                },
                "1442": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/3d3dc23ab365a951396ce59f8eedd1e831e497c4570a1739aedd8a47e436f041.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 203,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 343,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/add_liquidity/969479459fd3b867ae28190d9fc610d9ccb75b0d7783925f9dbefd7a80e1ce80.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 197,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 197
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 318,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_amountBMin' in:"
                                ],
                                "start_col": 5,
                                "start_line": 203
                            },
                            "While handling calldata argument 'amountBMin'"
                        ],
                        "start_col": 33,
                        "start_line": 1
                    }
                },
                "1443": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/dceaabc265216c0ff87c509d90446554648ec4d692c521cda952464d1fc22972.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 13,
                                "end_line": 204,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 365,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/add_liquidity/969479459fd3b867ae28190d9fc610d9ccb75b0d7783925f9dbefd7a80e1ce80.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 197,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 197
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 348,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_to' in:"
                                ],
                                "start_col": 5,
                                "start_line": 204
                            },
                            "While handling calldata argument 'to'"
                        ],
                        "start_col": 25,
                        "start_line": 1
                    }
                },
                "1444": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 47,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/17555969c7a8d33c61486b2958282949e335d6b0bb9fd10ced86d32ba19f948c.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 205,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 399,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/add_liquidity/969479459fd3b867ae28190d9fc610d9ccb75b0d7783925f9dbefd7a80e1ce80.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 197,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 197
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 376,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_deadline' in:"
                                ],
                                "start_col": 5,
                                "start_line": 205
                            },
                            "While handling calldata argument 'deadline'"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "1445": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 19,
                        "end_line": 197,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 6,
                        "start_line": 197
                    }
                },
                "1447": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 115,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/add_liquidity/969479459fd3b867ae28190d9fc610d9ccb75b0d7783925f9dbefd7a80e1ce80.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 197,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 102,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/add_liquidity/969479459fd3b867ae28190d9fc610d9ccb75b0d7783925f9dbefd7a80e1ce80.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 197,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 197
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 87,
                                        "start_line": 2
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 197
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 100,
                        "start_line": 1
                    }
                },
                "1448": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 103,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/add_liquidity/969479459fd3b867ae28190d9fc610d9ccb75b0d7783925f9dbefd7a80e1ce80.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 197,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 197
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 48,
                        "start_line": 2
                    }
                },
                "1450": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 55,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/add_liquidity/969479459fd3b867ae28190d9fc610d9ccb75b0d7783925f9dbefd7a80e1ce80.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 197,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 20,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/add_liquidity/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 197,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 197
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 9,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 197
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 44,
                        "start_line": 1
                    }
                },
                "1451": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/add_liquidity/969479459fd3b867ae28190d9fc610d9ccb75b0d7783925f9dbefd7a80e1ce80.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 197,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 33,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/add_liquidity/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 197,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 197
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 21,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 197
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 70,
                        "start_line": 1
                    }
                },
                "1452": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/add_liquidity/969479459fd3b867ae28190d9fc610d9ccb75b0d7783925f9dbefd7a80e1ce80.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 197,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 49,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/add_liquidity/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 197,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 197
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 34,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 197
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 6,
                        "start_line": 2
                    }
                },
                "1453": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/add_liquidity/969479459fd3b867ae28190d9fc610d9ccb75b0d7783925f9dbefd7a80e1ce80.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 197,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 62,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/add_liquidity/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 197,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 197
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 50,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'retdata_size' in:"
                                ],
                                "start_col": 6,
                                "start_line": 197
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 23,
                        "start_line": 2
                    }
                },
                "1454": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 44,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/add_liquidity/969479459fd3b867ae28190d9fc610d9ccb75b0d7783925f9dbefd7a80e1ce80.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 197,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 70,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/add_liquidity/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 197,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 197
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 63,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'retdata' in:"
                                ],
                                "start_col": 6,
                                "start_line": 197
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 37,
                        "start_line": 2
                    }
                },
                "1455": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/add_liquidity/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 197,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 197
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "1456": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 243,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 243
                    }
                },
                "1458": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 234,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 41,
                                "end_line": 337,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 31,
                                        "end_line": 244,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 244
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 23,
                                "start_line": 337
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 23,
                        "start_line": 234
                    }
                },
                "1459": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 69,
                        "end_line": 234,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 69,
                                "end_line": 337,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 31,
                                        "end_line": 244,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 244
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 43,
                                "start_line": 337
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 43,
                        "start_line": 234
                    }
                },
                "1460": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 86,
                        "end_line": 234,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 86,
                                "end_line": 337,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 31,
                                        "end_line": 244,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 244
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 71,
                                "start_line": 337
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 71,
                        "start_line": 234
                    }
                },
                "1461": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 19,
                        "end_line": 241,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 30,
                                "end_line": 244,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 22,
                                "start_line": 244
                            },
                            "While expanding the reference 'deadline' in:"
                        ],
                        "start_col": 5,
                        "start_line": 241
                    }
                },
                "1462": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 31,
                        "end_line": 244,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 244
                    }
                },
                "1464": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 42,
                        "end_line": 245,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 27,
                        "start_line": 245
                    }
                },
                "1466": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 245,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 245
                    }
                },
                "1467": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 245,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 34,
                                        "end_line": 460,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 58,
                                                "end_line": 246,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 24,
                                                "start_line": 246
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 16,
                                        "start_line": 460
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 27,
                                "start_line": 245
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 13
                    }
                },
                "1468": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 245,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 62,
                                        "end_line": 460,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 58,
                                                "end_line": 246,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 24,
                                                "start_line": 246
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 36,
                                        "start_line": 460
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 27,
                                "start_line": 245
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 35,
                        "start_line": 13
                    }
                },
                "1469": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 78,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 245,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 79,
                                        "end_line": 460,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 58,
                                                "end_line": 246,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 24,
                                                "start_line": 246
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 64,
                                        "start_line": 460
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 27,
                                "start_line": 245
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 63,
                        "start_line": 13
                    }
                },
                "1470": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 245,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 41,
                                "end_line": 246,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 34,
                                "start_line": 246
                            },
                            "While expanding the reference 'factory' in:"
                        ],
                        "start_col": 16,
                        "start_line": 245
                    }
                },
                "1471": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 17,
                        "end_line": 235,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 49,
                                "end_line": 246,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 43,
                                "start_line": 246
                            },
                            "While expanding the reference 'tokenA' in:"
                        ],
                        "start_col": 5,
                        "start_line": 235
                    }
                },
                "1472": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 17,
                        "end_line": 236,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 57,
                                "end_line": 246,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 51,
                                "start_line": 246
                            },
                            "While expanding the reference 'tokenB' in:"
                        ],
                        "start_col": 5,
                        "start_line": 236
                    }
                },
                "1473": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 58,
                        "end_line": 246,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 24,
                        "start_line": 246
                    }
                },
                "1475": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 246,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 246
                    }
                },
                "1476": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 460,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 58,
                                "end_line": 246,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 43,
                                        "end_line": 200,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 40,
                                                "end_line": 247,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 20,
                                                "start_line": 247
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 25,
                                        "start_line": 200
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 24,
                                "start_line": 246
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 16,
                        "start_line": 460
                    }
                },
                "1477": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 247,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 20,
                        "start_line": 247
                    }
                },
                "1479": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 43,
                        "end_line": 200,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 40,
                                "end_line": 247,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 37,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/f8c7980cd46ed6d764c999318b5692736b724dc08c1bf96e92d1b77ddf37af10.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 34,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 96,
                                                        "end_line": 248,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 5,
                                                        "start_line": 248
                                                    },
                                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                ],
                                                "start_col": 10,
                                                "start_line": 34
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 19,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 20,
                                "start_line": 247
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 25,
                        "start_line": 200
                    }
                },
                "1480": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 79,
                        "end_line": 460,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 58,
                                "end_line": 246,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 54,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/f8c7980cd46ed6d764c999318b5692736b724dc08c1bf96e92d1b77ddf37af10.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 34,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 96,
                                                        "end_line": 248,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 5,
                                                        "start_line": 248
                                                    },
                                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                ],
                                                "start_col": 10,
                                                "start_line": 34
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 39,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 24,
                                "start_line": 246
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 64,
                        "start_line": 460
                    }
                },
                "1481": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 246,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 46,
                                "end_line": 248,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 42,
                                "start_line": 248
                            },
                            "While expanding the reference 'pair' in:"
                        ],
                        "start_col": 16,
                        "start_line": 246
                    }
                },
                "1482": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 16,
                        "end_line": 247,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 61,
                                "end_line": 248,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 55,
                                "start_line": 248
                            },
                            "While expanding the reference 'sender' in:"
                        ],
                        "start_col": 10,
                        "start_line": 247
                    }
                },
                "1483": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 246,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 77,
                                "end_line": 248,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 73,
                                "start_line": 248
                            },
                            "While expanding the reference 'pair' in:"
                        ],
                        "start_col": 16,
                        "start_line": 246
                    }
                },
                "1484": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 237,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 95,
                                "end_line": 248,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 86,
                                "start_line": 248
                            },
                            "While expanding the reference 'liquidity' in:"
                        ],
                        "start_col": 5,
                        "start_line": 237
                    }
                },
                "1485": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 237,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 95,
                                "end_line": 248,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 86,
                                "start_line": 248
                            },
                            "While expanding the reference 'liquidity' in:"
                        ],
                        "start_col": 5,
                        "start_line": 237
                    }
                },
                "1486": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 96,
                        "end_line": 248,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 248
                    }
                },
                "1488": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/f8c7980cd46ed6d764c999318b5692736b724dc08c1bf96e92d1b77ddf37af10.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 34,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 96,
                                        "end_line": 248,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 29,
                                                "end_line": 1,
                                                "input_file": {
                                                    "filename": "autogen/starknet/contract_interface/IPair/burn/484b5f727aa4fe2fdbfbe21899bf52ecd5bf948dde26ab2d509b57812ab8a3cd.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 14,
                                                        "end_line": 46,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 100,
                                                                "end_line": 249,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 60,
                                                                "start_line": 249
                                                            },
                                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                        ],
                                                        "start_col": 10,
                                                        "start_line": 46
                                                    },
                                                    "While handling contract interface function:"
                                                ],
                                                "start_col": 11,
                                                "start_line": 1
                                            },
                                            "While expanding the reference 'syscall_ptr' in:"
                                        ],
                                        "start_col": 5,
                                        "start_line": 248
                                    },
                                    "While trying to update the implicit return value 'syscall_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 34
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 19,
                        "start_line": 1
                    }
                },
                "1489": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/f8c7980cd46ed6d764c999318b5692736b724dc08c1bf96e92d1b77ddf37af10.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 34,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 96,
                                        "end_line": 248,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 46,
                                                "end_line": 1,
                                                "input_file": {
                                                    "filename": "autogen/starknet/contract_interface/IPair/burn/484b5f727aa4fe2fdbfbe21899bf52ecd5bf948dde26ab2d509b57812ab8a3cd.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 14,
                                                        "end_line": 46,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 100,
                                                                "end_line": 249,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 60,
                                                                "start_line": 249
                                                            },
                                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                        ],
                                                        "start_col": 10,
                                                        "start_line": 46
                                                    },
                                                    "While handling contract interface function:"
                                                ],
                                                "start_col": 31,
                                                "start_line": 1
                                            },
                                            "While expanding the reference 'range_check_ptr' in:"
                                        ],
                                        "start_col": 5,
                                        "start_line": 248
                                    },
                                    "While trying to update the implicit return value 'range_check_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 34
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 39,
                        "start_line": 1
                    }
                },
                "1490": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 246,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 92,
                                "end_line": 249,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 88,
                                "start_line": 249
                            },
                            "While expanding the reference 'pair' in:"
                        ],
                        "start_col": 16,
                        "start_line": 246
                    }
                },
                "1491": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 13,
                        "end_line": 240,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 99,
                                "end_line": 249,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 97,
                                "start_line": 249
                            },
                            "While expanding the reference 'to' in:"
                        ],
                        "start_col": 5,
                        "start_line": 240
                    }
                },
                "1492": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 100,
                        "end_line": 249,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 60,
                        "start_line": 249
                    }
                },
                "1494": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 249,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 249
                    }
                },
                "1495": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 249,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 249
                    }
                },
                "1496": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 56,
                        "end_line": 249,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 34,
                        "start_line": 249
                    }
                },
                "1497": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 56,
                        "end_line": 249,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 34,
                        "start_line": 249
                    }
                },
                "1498": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 29,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/burn/484b5f727aa4fe2fdbfbe21899bf52ecd5bf948dde26ab2d509b57812ab8a3cd.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 46,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 100,
                                        "end_line": 249,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 37,
                                                "end_line": 440,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 57,
                                                        "end_line": 250,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 29,
                                                        "start_line": 250
                                                    },
                                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                ],
                                                "start_col": 19,
                                                "start_line": 440
                                            },
                                            "While expanding the reference 'syscall_ptr' in:"
                                        ],
                                        "start_col": 60,
                                        "start_line": 249
                                    },
                                    "While trying to update the implicit return value 'syscall_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 46
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 11,
                        "start_line": 1
                    }
                },
                "1499": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 62,
                        "end_line": 460,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 58,
                                "end_line": 246,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 65,
                                        "end_line": 440,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 57,
                                                "end_line": 250,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 29,
                                                "start_line": 250
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 39,
                                        "start_line": 440
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 24,
                                "start_line": 246
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 36,
                        "start_line": 460
                    }
                },
                "1500": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 46,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/burn/484b5f727aa4fe2fdbfbe21899bf52ecd5bf948dde26ab2d509b57812ab8a3cd.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 46,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 100,
                                        "end_line": 249,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 82,
                                                "end_line": 440,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 57,
                                                        "end_line": 250,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 29,
                                                        "start_line": 250
                                                    },
                                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                ],
                                                "start_col": 67,
                                                "start_line": 440
                                            },
                                            "While expanding the reference 'range_check_ptr' in:"
                                        ],
                                        "start_col": 60,
                                        "start_line": 249
                                    },
                                    "While trying to update the implicit return value 'range_check_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 46
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "1501": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 17,
                        "end_line": 235,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 48,
                                "end_line": 250,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 42,
                                "start_line": 250
                            },
                            "While expanding the reference 'tokenA' in:"
                        ],
                        "start_col": 5,
                        "start_line": 235
                    }
                },
                "1502": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 17,
                        "end_line": 236,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 56,
                                "end_line": 250,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 50,
                                "start_line": 250
                            },
                            "While expanding the reference 'tokenB' in:"
                        ],
                        "start_col": 5,
                        "start_line": 236
                    }
                },
                "1503": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 57,
                        "end_line": 250,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 29,
                        "start_line": 250
                    }
                },
                "1505": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 250,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 250
                    }
                },
                "1506": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 25,
                        "end_line": 253,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 253
                    }
                },
                "1507": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 253,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 253
                    }
                },
                "1509": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 254,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 254
                    }
                },
                "1510": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 254,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 254
                    }
                },
                "1511": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 255,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 255
                    }
                },
                "1512": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 255,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 255
                    }
                },
                "1513": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 253,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 253
                    }
                },
                "1515": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 257,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 257
                    }
                },
                "1516": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 257,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 257
                    }
                },
                "1517": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 258,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 258
                    }
                },
                "1518": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 258,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 258
                    }
                },
                "1519": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 440,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 57,
                                "end_line": 250,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 32,
                                        "end_line": 148,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 85,
                                                "end_line": 261,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 54,
                                                "start_line": 261
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 17,
                                        "start_line": 148
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 29,
                                "start_line": 250
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 67,
                        "start_line": 440
                    }
                },
                "1520": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 238,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 75,
                                "end_line": 261,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 65,
                                "start_line": 261
                            },
                            "While expanding the reference 'amountAMin' in:"
                        ],
                        "start_col": 5,
                        "start_line": 238
                    }
                },
                "1521": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 238,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 75,
                                "end_line": 261,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 65,
                                "start_line": 261
                            },
                            "While expanding the reference 'amountAMin' in:"
                        ],
                        "start_col": 5,
                        "start_line": 238
                    }
                },
                "1522": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 251,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 84,
                                "end_line": 261,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 77,
                                "start_line": 261
                            },
                            "While expanding the reference 'amountA' in:"
                        ],
                        "start_col": 11,
                        "start_line": 251
                    }
                },
                "1523": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 251,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 84,
                                "end_line": 261,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 77,
                                "start_line": 261
                            },
                            "While expanding the reference 'amountA' in:"
                        ],
                        "start_col": 11,
                        "start_line": 251
                    }
                },
                "1524": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 85,
                        "end_line": 261,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 54,
                        "start_line": 261
                    }
                },
                "1526": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 263,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 263
                    }
                },
                "1528": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 148,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 85,
                                "end_line": 261,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 32,
                                        "end_line": 148,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 85,
                                                "end_line": 265,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 54,
                                                "start_line": 265
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 17,
                                        "start_line": 148
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 54,
                                "start_line": 261
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 148
                    }
                },
                "1529": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 239,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 75,
                                "end_line": 265,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 65,
                                "start_line": 265
                            },
                            "While expanding the reference 'amountBMin' in:"
                        ],
                        "start_col": 5,
                        "start_line": 239
                    }
                },
                "1530": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 239,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 75,
                                "end_line": 265,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 65,
                                "start_line": 265
                            },
                            "While expanding the reference 'amountBMin' in:"
                        ],
                        "start_col": 5,
                        "start_line": 239
                    }
                },
                "1531": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 252,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 84,
                                "end_line": 265,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 77,
                                "start_line": 265
                            },
                            "While expanding the reference 'amountB' in:"
                        ],
                        "start_col": 11,
                        "start_line": 252
                    }
                },
                "1532": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 252,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 84,
                                "end_line": 265,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 77,
                                "start_line": 265
                            },
                            "While expanding the reference 'amountB' in:"
                        ],
                        "start_col": 11,
                        "start_line": 252
                    }
                },
                "1533": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 85,
                        "end_line": 265,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 54,
                        "start_line": 265
                    }
                },
                "1535": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 267,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 267
                    }
                },
                "1537": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 440,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 57,
                                "end_line": 250,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 41,
                                        "end_line": 234,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 31,
                                                "end_line": 270,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 270
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 23,
                                        "start_line": 234
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 29,
                                "start_line": 250
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 19,
                        "start_line": 440
                    }
                },
                "1538": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 65,
                        "end_line": 440,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 57,
                                "end_line": 250,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 69,
                                        "end_line": 234,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 31,
                                                "end_line": 270,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 270
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 43,
                                        "start_line": 234
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 29,
                                "start_line": 250
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 39,
                        "start_line": 440
                    }
                },
                "1539": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 148,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 85,
                                "end_line": 265,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 86,
                                        "end_line": 234,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 31,
                                                "end_line": 270,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 270
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 71,
                                        "start_line": 234
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 54,
                                "start_line": 265
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 148
                    }
                },
                "1540": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 251,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 270,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 13,
                                "start_line": 270
                            },
                            "While expanding the reference 'amountA' in:"
                        ],
                        "start_col": 11,
                        "start_line": 251
                    }
                },
                "1541": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 251,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 270,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 13,
                                "start_line": 270
                            },
                            "While expanding the reference 'amountA' in:"
                        ],
                        "start_col": 11,
                        "start_line": 251
                    }
                },
                "1542": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 252,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 29,
                                "end_line": 270,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 22,
                                "start_line": 270
                            },
                            "While expanding the reference 'amountB' in:"
                        ],
                        "start_col": 11,
                        "start_line": 252
                    }
                },
                "1543": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 252,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 29,
                                "end_line": 270,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 22,
                                "start_line": 270
                            },
                            "While expanding the reference 'amountB' in:"
                        ],
                        "start_col": 11,
                        "start_line": 252
                    }
                },
                "1544": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 31,
                        "end_line": 270,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 270
                    }
                },
                "1545": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 38,
                                "end_line": 3,
                                "input_file": {
                                    "filename": "autogen/starknet/external/return/remove_liquidity/b66a28f571d574f2b8dcb5f4c22b39f9f529587f595e464b1814a852d2cdbc48.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 22,
                                        "end_line": 234,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 6,
                                        "start_line": 234
                                    },
                                    "While handling return value of"
                                ],
                                "start_col": 5,
                                "start_line": 3
                            },
                            "n_prefix_newlines": 0
                        }
                    ],
                    "inst": {
                        "end_col": 18,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/remove_liquidity/b66a28f571d574f2b8dcb5f4c22b39f9f529587f595e464b1814a852d2cdbc48.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 234,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 234
                            },
                            "While handling return value of"
                        ],
                        "start_col": 5,
                        "start_line": 4
                    }
                },
                "1547": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 60,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/9971733a46b660735efbd843b968648fbb6ab1512994825bb1137bbaa294d2fd.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 23,
                                "end_line": 242,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 7,
                                "start_line": 242
                            },
                            "While handling return value 'amountA'"
                        ],
                        "start_col": 1,
                        "start_line": 3
                    }
                },
                "1548": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 60,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/9971733a46b660735efbd843b968648fbb6ab1512994825bb1137bbaa294d2fd.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 23,
                                "end_line": 242,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 7,
                                "start_line": 242
                            },
                            "While handling return value 'amountA'"
                        ],
                        "start_col": 1,
                        "start_line": 4
                    }
                },
                "1549": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 60,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/3d6e81bf1402793cfbd97151fd631ccb7424cd79d2c40557aa18360f58ac0d92.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 41,
                                "end_line": 242,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 25,
                                "start_line": 242
                            },
                            "While handling return value 'amountB'"
                        ],
                        "start_col": 1,
                        "start_line": 3
                    }
                },
                "1550": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 60,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/3d6e81bf1402793cfbd97151fd631ccb7424cd79d2c40557aa18360f58ac0d92.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 41,
                                "end_line": 242,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 25,
                                "start_line": 242
                            },
                            "While handling return value 'amountB'"
                        ],
                        "start_col": 1,
                        "start_line": 4
                    }
                },
                "1551": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/3d6e81bf1402793cfbd97151fd631ccb7424cd79d2c40557aa18360f58ac0d92.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 41,
                                "end_line": 242,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 36,
                                        "end_line": 11,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/return/remove_liquidity/b66a28f571d574f2b8dcb5f4c22b39f9f529587f595e464b1814a852d2cdbc48.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 234,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 234
                                            },
                                            "While handling return value of"
                                        ],
                                        "start_col": 18,
                                        "start_line": 11
                                    },
                                    "While expanding the reference '__return_value_ptr' in:"
                                ],
                                "start_col": 25,
                                "start_line": 242
                            },
                            "While handling return value 'amountB'"
                        ],
                        "start_col": 26,
                        "start_line": 5
                    }
                },
                "1553": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 163,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/remove_liquidity/b66a28f571d574f2b8dcb5f4c22b39f9f529587f595e464b1814a852d2cdbc48.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 234,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 40,
                                        "end_line": 10,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/return/remove_liquidity/b66a28f571d574f2b8dcb5f4c22b39f9f529587f595e464b1814a852d2cdbc48.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 234,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 234
                                            },
                                            "While handling return value of"
                                        ],
                                        "start_col": 25,
                                        "start_line": 10
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 234
                            },
                            "While handling return value of"
                        ],
                        "start_col": 148,
                        "start_line": 1
                    }
                },
                "1554": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 63,
                        "end_line": 11,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/remove_liquidity/b66a28f571d574f2b8dcb5f4c22b39f9f529587f595e464b1814a852d2cdbc48.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 234,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 234
                            },
                            "While handling return value of"
                        ],
                        "start_col": 18,
                        "start_line": 11
                    }
                },
                "1555": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/remove_liquidity/b66a28f571d574f2b8dcb5f4c22b39f9f529587f595e464b1814a852d2cdbc48.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 234,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 38,
                                        "end_line": 12,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/return/remove_liquidity/b66a28f571d574f2b8dcb5f4c22b39f9f529587f595e464b1814a852d2cdbc48.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 234,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 234
                                            },
                                            "While handling return value of"
                                        ],
                                        "start_col": 14,
                                        "start_line": 12
                                    },
                                    "While expanding the reference '__return_value_ptr_start' in:"
                                ],
                                "start_col": 6,
                                "start_line": 234
                            },
                            "While handling return value of"
                        ],
                        "start_col": 11,
                        "start_line": 5
                    }
                },
                "1556": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 12,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/remove_liquidity/b66a28f571d574f2b8dcb5f4c22b39f9f529587f595e464b1814a852d2cdbc48.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 234,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 234
                            },
                            "While handling return value of"
                        ],
                        "start_col": 5,
                        "start_line": 9
                    }
                },
                "1557": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/17555969c7a8d33c61486b2958282949e335d6b0bb9fd10ced86d32ba19f948c.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 241,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 45,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/c31620b02d4d706f0542c989b2aadc01b0981d1f6a5933a8fe4937ace3d70d92.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 234,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 57,
                                                        "end_line": 1,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/arg_processor/01cba52f8515996bb9d7070bde81ff39281d096d7024a558efcba6e1fd2402cf.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 22,
                                                                "end_line": 234,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 6,
                                                                "start_line": 234
                                                            },
                                                            "While handling calldata of"
                                                        ],
                                                        "start_col": 35,
                                                        "start_line": 1
                                                    },
                                                    "While expanding the reference '__calldata_actual_size' in:"
                                                ],
                                                "start_col": 6,
                                                "start_line": 234
                                            },
                                            "While handling calldata of"
                                        ],
                                        "start_col": 31,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_ptr' in:"
                                ],
                                "start_col": 5,
                                "start_line": 241
                            },
                            "While handling calldata argument 'deadline'"
                        ],
                        "start_col": 22,
                        "start_line": 2
                    }
                },
                "1559": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 58,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/01cba52f8515996bb9d7070bde81ff39281d096d7024a558efcba6e1fd2402cf.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 234,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 234
                            },
                            "While handling calldata of"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "1560": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 64,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/remove_liquidity/b2c52ca2d2a8fc8791a983086d8716c5eacd0c3d62934914d2286f84b98ff4cb.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 41,
                                "end_line": 234,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 55,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/remove_liquidity/bb85aef889fad46697afdd63715fdb112517bb7675f11cd5f538690e2ee74e37.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 234,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 234
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 44,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 23,
                                "start_line": 234
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 19,
                        "start_line": 1
                    }
                },
                "1561": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 110,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/remove_liquidity/9684a85e93c782014ca14293edea4eb2502039a5a7b6538ecd39c56faaf12529.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 69,
                                "end_line": 234,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 82,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/remove_liquidity/bb85aef889fad46697afdd63715fdb112517bb7675f11cd5f538690e2ee74e37.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 234,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 234
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 70,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 43,
                                "start_line": 234
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 20,
                        "start_line": 1
                    }
                },
                "1562": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/remove_liquidity/741ea357d6336b0bed7bf0472425acd0311d543883b803388880e60a232040c7.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 86,
                                "end_line": 234,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 115,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/remove_liquidity/bb85aef889fad46697afdd63715fdb112517bb7675f11cd5f538690e2ee74e37.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 234,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 234
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 100,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 71,
                                "start_line": 234
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 23,
                        "start_line": 1
                    }
                },
                "1563": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 45,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/5ec6d3da377064fcca5c6ce7ac91ed695c6086c63198cd266d6f1f3f80005101.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 235,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 145,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/remove_liquidity/bb85aef889fad46697afdd63715fdb112517bb7675f11cd5f538690e2ee74e37.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 234,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 234
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 124,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_tokenA' in:"
                                ],
                                "start_col": 5,
                                "start_line": 235
                            },
                            "While handling calldata argument 'tokenA'"
                        ],
                        "start_col": 29,
                        "start_line": 1
                    }
                },
                "1564": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 45,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/f592e43c96a0e5d88593ef440a75312f8beb20e2d1d087f2f18887daf8570e62.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 236,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 175,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/remove_liquidity/bb85aef889fad46697afdd63715fdb112517bb7675f11cd5f538690e2ee74e37.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 234,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 234
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 154,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_tokenB' in:"
                                ],
                                "start_col": 5,
                                "start_line": 236
                            },
                            "While handling calldata argument 'tokenB'"
                        ],
                        "start_col": 29,
                        "start_line": 1
                    }
                },
                "1565": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/e2bbbf52ff1f738a8284ca33e1a014831bd4ccef09e7cd753ec10fd250c6130c.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 23,
                                "end_line": 237,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 211,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/remove_liquidity/bb85aef889fad46697afdd63715fdb112517bb7675f11cd5f538690e2ee74e37.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 234,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 234
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 187,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_liquidity' in:"
                                ],
                                "start_col": 5,
                                "start_line": 237
                            },
                            "While handling calldata argument 'liquidity'"
                        ],
                        "start_col": 32,
                        "start_line": 1
                    }
                },
                "1566": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/e2bbbf52ff1f738a8284ca33e1a014831bd4ccef09e7cd753ec10fd250c6130c.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 23,
                                "end_line": 237,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 211,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/remove_liquidity/bb85aef889fad46697afdd63715fdb112517bb7675f11cd5f538690e2ee74e37.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 234,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 234
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 187,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_liquidity' in:"
                                ],
                                "start_col": 5,
                                "start_line": 237
                            },
                            "While handling calldata argument 'liquidity'"
                        ],
                        "start_col": 32,
                        "start_line": 1
                    }
                },
                "1567": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/9a221054e9d7400afb4c8befc8f37dcc2ce98e6984fd9029ab1599acbf90b78c.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 238,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 249,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/remove_liquidity/bb85aef889fad46697afdd63715fdb112517bb7675f11cd5f538690e2ee74e37.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 234,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 234
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 224,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_amountAMin' in:"
                                ],
                                "start_col": 5,
                                "start_line": 238
                            },
                            "While handling calldata argument 'amountAMin'"
                        ],
                        "start_col": 33,
                        "start_line": 1
                    }
                },
                "1568": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/9a221054e9d7400afb4c8befc8f37dcc2ce98e6984fd9029ab1599acbf90b78c.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 238,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 249,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/remove_liquidity/bb85aef889fad46697afdd63715fdb112517bb7675f11cd5f538690e2ee74e37.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 234,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 234
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 224,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_amountAMin' in:"
                                ],
                                "start_col": 5,
                                "start_line": 238
                            },
                            "While handling calldata argument 'amountAMin'"
                        ],
                        "start_col": 33,
                        "start_line": 1
                    }
                },
                "1569": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/3d3dc23ab365a951396ce59f8eedd1e831e497c4570a1739aedd8a47e436f041.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 239,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 287,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/remove_liquidity/bb85aef889fad46697afdd63715fdb112517bb7675f11cd5f538690e2ee74e37.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 234,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 234
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 262,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_amountBMin' in:"
                                ],
                                "start_col": 5,
                                "start_line": 239
                            },
                            "While handling calldata argument 'amountBMin'"
                        ],
                        "start_col": 33,
                        "start_line": 1
                    }
                },
                "1570": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/3d3dc23ab365a951396ce59f8eedd1e831e497c4570a1739aedd8a47e436f041.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 239,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 287,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/remove_liquidity/bb85aef889fad46697afdd63715fdb112517bb7675f11cd5f538690e2ee74e37.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 234,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 234
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 262,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_amountBMin' in:"
                                ],
                                "start_col": 5,
                                "start_line": 239
                            },
                            "While handling calldata argument 'amountBMin'"
                        ],
                        "start_col": 33,
                        "start_line": 1
                    }
                },
                "1571": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/dceaabc265216c0ff87c509d90446554648ec4d692c521cda952464d1fc22972.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 13,
                                "end_line": 240,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 309,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/remove_liquidity/bb85aef889fad46697afdd63715fdb112517bb7675f11cd5f538690e2ee74e37.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 234,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 234
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 292,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_to' in:"
                                ],
                                "start_col": 5,
                                "start_line": 240
                            },
                            "While handling calldata argument 'to'"
                        ],
                        "start_col": 25,
                        "start_line": 1
                    }
                },
                "1572": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 47,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/17555969c7a8d33c61486b2958282949e335d6b0bb9fd10ced86d32ba19f948c.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 241,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 343,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/remove_liquidity/bb85aef889fad46697afdd63715fdb112517bb7675f11cd5f538690e2ee74e37.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 234,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 234
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 320,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_deadline' in:"
                                ],
                                "start_col": 5,
                                "start_line": 241
                            },
                            "While handling calldata argument 'deadline'"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "1573": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 234,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 6,
                        "start_line": 234
                    }
                },
                "1575": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 115,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/remove_liquidity/bb85aef889fad46697afdd63715fdb112517bb7675f11cd5f538690e2ee74e37.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 234,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 105,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/remove_liquidity/bb85aef889fad46697afdd63715fdb112517bb7675f11cd5f538690e2ee74e37.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 234,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 234
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 90,
                                        "start_line": 2
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 234
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 100,
                        "start_line": 1
                    }
                },
                "1576": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 106,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/remove_liquidity/bb85aef889fad46697afdd63715fdb112517bb7675f11cd5f538690e2ee74e37.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 234,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 234
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 48,
                        "start_line": 2
                    }
                },
                "1578": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 55,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/remove_liquidity/bb85aef889fad46697afdd63715fdb112517bb7675f11cd5f538690e2ee74e37.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 234,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 20,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/remove_liquidity/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 234,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 234
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 9,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 234
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 44,
                        "start_line": 1
                    }
                },
                "1579": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/remove_liquidity/bb85aef889fad46697afdd63715fdb112517bb7675f11cd5f538690e2ee74e37.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 234,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 33,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/remove_liquidity/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 234,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 234
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 21,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 234
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 70,
                        "start_line": 1
                    }
                },
                "1580": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/remove_liquidity/bb85aef889fad46697afdd63715fdb112517bb7675f11cd5f538690e2ee74e37.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 234,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 49,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/remove_liquidity/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 234,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 234
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 34,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 234
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 6,
                        "start_line": 2
                    }
                },
                "1581": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/remove_liquidity/bb85aef889fad46697afdd63715fdb112517bb7675f11cd5f538690e2ee74e37.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 234,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 62,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/remove_liquidity/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 234,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 234
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 50,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'retdata_size' in:"
                                ],
                                "start_col": 6,
                                "start_line": 234
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 23,
                        "start_line": 2
                    }
                },
                "1582": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 44,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/remove_liquidity/bb85aef889fad46697afdd63715fdb112517bb7675f11cd5f538690e2ee74e37.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 234,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 70,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/remove_liquidity/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 234,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 234
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 63,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'retdata' in:"
                                ],
                                "start_col": 6,
                                "start_line": 234
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 37,
                        "start_line": 2
                    }
                },
                "1583": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/remove_liquidity/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 234,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 234
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "1584": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 287,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 287
                    }
                },
                "1586": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 53,
                        "end_line": 284,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 41,
                                "end_line": 337,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 31,
                                        "end_line": 288,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 288
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 23,
                                "start_line": 337
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 35,
                        "start_line": 284
                    }
                },
                "1587": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 81,
                        "end_line": 284,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 69,
                                "end_line": 337,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 31,
                                        "end_line": 288,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 288
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 43,
                                "start_line": 337
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 55,
                        "start_line": 284
                    }
                },
                "1588": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 98,
                        "end_line": 284,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 86,
                                "end_line": 337,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 31,
                                        "end_line": 288,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 288
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 71,
                                "start_line": 337
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 83,
                        "start_line": 284
                    }
                },
                "1589": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 100,
                        "end_line": 285,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 30,
                                "end_line": 288,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 22,
                                "start_line": 288
                            },
                            "While expanding the reference 'deadline' in:"
                        ],
                        "start_col": 86,
                        "start_line": 285
                    }
                },
                "1590": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 31,
                        "end_line": 288,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 288
                    }
                },
                "1592": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 42,
                        "end_line": 289,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 27,
                        "start_line": 289
                    }
                },
                "1594": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 289,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 289
                    }
                },
                "1595": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 289,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 41,
                                        "end_line": 559,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 88,
                                                "end_line": 290,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 37,
                                                "start_line": 290
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 23,
                                        "start_line": 559
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 27,
                                "start_line": 289
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 13
                    }
                },
                "1596": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 289,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 69,
                                        "end_line": 559,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 88,
                                                "end_line": 290,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 37,
                                                "start_line": 290
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 43,
                                        "start_line": 559
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 27,
                                "start_line": 289
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 35,
                        "start_line": 13
                    }
                },
                "1597": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 78,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 289,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 86,
                                        "end_line": 559,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 88,
                                                "end_line": 290,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 37,
                                                "start_line": 290
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 71,
                                        "start_line": 559
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 27,
                                "start_line": 289
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 63,
                        "start_line": 13
                    }
                },
                "1598": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 289,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 61,
                                "end_line": 290,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 54,
                                "start_line": 290
                            },
                            "While expanding the reference 'factory' in:"
                        ],
                        "start_col": 16,
                        "start_line": 289
                    }
                },
                "1599": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 285,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 71,
                                "end_line": 290,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 63,
                                "start_line": 290
                            },
                            "While expanding the reference 'amountIn' in:"
                        ],
                        "start_col": 5,
                        "start_line": 285
                    }
                },
                "1600": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 285,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 71,
                                "end_line": 290,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 63,
                                "start_line": 290
                            },
                            "While expanding the reference 'amountIn' in:"
                        ],
                        "start_col": 5,
                        "start_line": 285
                    }
                },
                "1601": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 285,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 81,
                                "end_line": 290,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 73,
                                "start_line": 290
                            },
                            "While expanding the reference 'path_len' in:"
                        ],
                        "start_col": 47,
                        "start_line": 285
                    }
                },
                "1602": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 74,
                        "end_line": 285,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 87,
                                "end_line": 290,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 83,
                                "start_line": 290
                            },
                            "While expanding the reference 'path' in:"
                        ],
                        "start_col": 63,
                        "start_line": 285
                    }
                },
                "1603": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 88,
                        "end_line": 290,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 37,
                        "start_line": 290
                    }
                },
                "1605": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 290,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 290
                    }
                },
                "1606": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 47,
                        "end_line": 292,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 35,
                        "start_line": 292
                    }
                },
                "1608": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 63,
                        "end_line": 292,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 34,
                        "start_line": 292
                    }
                },
                "1610": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 63,
                        "end_line": 292,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 24,
                        "start_line": 292
                    }
                },
                "1611": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 47,
                        "end_line": 292,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 35,
                        "start_line": 292
                    }
                },
                "1613": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 63,
                        "end_line": 292,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 34,
                        "start_line": 292
                    }
                },
                "1615": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 64,
                        "end_line": 292,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 23,
                        "start_line": 292
                    }
                },
                "1617": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 64,
                        "end_line": 292,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 23,
                        "start_line": 292
                    }
                },
                "1618": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 86,
                        "end_line": 559,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 88,
                                "end_line": 290,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 32,
                                        "end_line": 148,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 6,
                                                "end_line": 293,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 60,
                                                "start_line": 291
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 17,
                                        "start_line": 148
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 37,
                                "start_line": 290
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 71,
                        "start_line": 559
                    }
                },
                "1619": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 45,
                        "end_line": 285,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 292,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 9,
                                "start_line": 292
                            },
                            "While expanding the reference 'amountOutMin' in:"
                        ],
                        "start_col": 24,
                        "start_line": 285
                    }
                },
                "1620": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 45,
                        "end_line": 285,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 292,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 9,
                                "start_line": 292
                            },
                            "While expanding the reference 'amountOutMin' in:"
                        ],
                        "start_col": 24,
                        "start_line": 285
                    }
                },
                "1621": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 64,
                        "end_line": 292,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 23,
                        "start_line": 292
                    }
                },
                "1622": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 64,
                        "end_line": 292,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 23,
                        "start_line": 292
                    }
                },
                "1623": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 6,
                        "end_line": 293,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 60,
                        "start_line": 291
                    }
                },
                "1625": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 295,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 295
                    }
                },
                "1627": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 559,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 88,
                                "end_line": 290,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 34,
                                        "end_line": 460,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 62,
                                                "end_line": 297,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 24,
                                                "start_line": 297
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 16,
                                        "start_line": 460
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 37,
                                "start_line": 290
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 23,
                        "start_line": 559
                    }
                },
                "1628": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 69,
                        "end_line": 559,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 88,
                                "end_line": 290,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 62,
                                        "end_line": 460,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 62,
                                                "end_line": 297,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 24,
                                                "start_line": 297
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 36,
                                        "start_line": 460
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 37,
                                "start_line": 290
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 43,
                        "start_line": 559
                    }
                },
                "1629": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 148,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 6,
                                "end_line": 293,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 79,
                                        "end_line": 460,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 62,
                                                "end_line": 297,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 24,
                                                "start_line": 297
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 64,
                                        "start_line": 460
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 60,
                                "start_line": 291
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 148
                    }
                },
                "1630": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 289,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 41,
                                "end_line": 297,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 34,
                                "start_line": 297
                            },
                            "While expanding the reference 'factory' in:"
                        ],
                        "start_col": 16,
                        "start_line": 289
                    }
                },
                "1631": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 49,
                        "end_line": 297,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 43,
                        "start_line": 297
                    }
                },
                "1632": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 297,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 51,
                        "start_line": 297
                    }
                },
                "1633": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 62,
                        "end_line": 297,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 24,
                        "start_line": 297
                    }
                },
                "1635": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 297,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 297
                    }
                },
                "1636": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 460,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 62,
                                "end_line": 297,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 43,
                                        "end_line": 200,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 40,
                                                "end_line": 298,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 20,
                                                "start_line": 298
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 25,
                                        "start_line": 200
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 24,
                                "start_line": 297
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 16,
                        "start_line": 460
                    }
                },
                "1637": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 298,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 20,
                        "start_line": 298
                    }
                },
                "1639": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 43,
                        "end_line": 200,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 40,
                                "end_line": 298,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 37,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/f8c7980cd46ed6d764c999318b5692736b724dc08c1bf96e92d1b77ddf37af10.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 34,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 98,
                                                        "end_line": 299,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 5,
                                                        "start_line": 299
                                                    },
                                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                ],
                                                "start_col": 10,
                                                "start_line": 34
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 19,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 20,
                                "start_line": 298
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 25,
                        "start_line": 200
                    }
                },
                "1640": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 79,
                        "end_line": 460,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 62,
                                "end_line": 297,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 54,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/f8c7980cd46ed6d764c999318b5692736b724dc08c1bf96e92d1b77ddf37af10.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 34,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 98,
                                                        "end_line": 299,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 5,
                                                        "start_line": 299
                                                    },
                                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                ],
                                                "start_col": 10,
                                                "start_line": 34
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 39,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 24,
                                "start_line": 297
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 64,
                        "start_line": 460
                    }
                },
                "1641": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 299,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 42,
                        "start_line": 299
                    }
                },
                "1642": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 16,
                        "end_line": 298,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 63,
                                "end_line": 299,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 57,
                                "start_line": 299
                            },
                            "While expanding the reference 'sender' in:"
                        ],
                        "start_col": 10,
                        "start_line": 298
                    }
                },
                "1643": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 297,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 79,
                                "end_line": 299,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 75,
                                "start_line": 299
                            },
                            "While expanding the reference 'pair' in:"
                        ],
                        "start_col": 16,
                        "start_line": 297
                    }
                },
                "1644": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 97,
                        "end_line": 299,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 88,
                        "start_line": 299
                    }
                },
                "1645": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 97,
                        "end_line": 299,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 88,
                        "start_line": 299
                    }
                },
                "1646": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 98,
                        "end_line": 299,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 299
                    }
                },
                "1648": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/f8c7980cd46ed6d764c999318b5692736b724dc08c1bf96e92d1b77ddf37af10.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 34,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 98,
                                        "end_line": 299,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 30,
                                                "end_line": 401,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 42,
                                                        "end_line": 300,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 5,
                                                        "start_line": 300
                                                    },
                                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                ],
                                                "start_col": 12,
                                                "start_line": 401
                                            },
                                            "While expanding the reference 'syscall_ptr' in:"
                                        ],
                                        "start_col": 5,
                                        "start_line": 299
                                    },
                                    "While trying to update the implicit return value 'syscall_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 34
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 19,
                        "start_line": 1
                    }
                },
                "1649": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 62,
                        "end_line": 460,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 62,
                                "end_line": 297,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 58,
                                        "end_line": 401,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 42,
                                                "end_line": 300,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 300
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 32,
                                        "start_line": 401
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 24,
                                "start_line": 297
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 36,
                        "start_line": 460
                    }
                },
                "1650": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/f8c7980cd46ed6d764c999318b5692736b724dc08c1bf96e92d1b77ddf37af10.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 34,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 98,
                                        "end_line": 299,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 75,
                                                "end_line": 401,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 42,
                                                        "end_line": 300,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 5,
                                                        "start_line": 300
                                                    },
                                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                ],
                                                "start_col": 60,
                                                "start_line": 401
                                            },
                                            "While expanding the reference 'range_check_ptr' in:"
                                        ],
                                        "start_col": 5,
                                        "start_line": 299
                                    },
                                    "While trying to update the implicit return value 'range_check_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 34
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 39,
                        "start_line": 1
                    }
                },
                "1651": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 12,
                        "end_line": 300,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 11,
                        "start_line": 300
                    }
                },
                "1653": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 285,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 300,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 14,
                                "start_line": 300
                            },
                            "While expanding the reference 'path_len' in:"
                        ],
                        "start_col": 47,
                        "start_line": 285
                    }
                },
                "1654": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 290,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 31,
                                "end_line": 300,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 24,
                                "start_line": 300
                            },
                            "While expanding the reference 'amounts' in:"
                        ],
                        "start_col": 16,
                        "start_line": 290
                    }
                },
                "1655": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 74,
                        "end_line": 285,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 37,
                                "end_line": 300,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 33,
                                "start_line": 300
                            },
                            "While expanding the reference 'path' in:"
                        ],
                        "start_col": 63,
                        "start_line": 285
                    }
                },
                "1656": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 84,
                        "end_line": 285,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 41,
                                "end_line": 300,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 39,
                                "start_line": 300
                            },
                            "While expanding the reference 'to' in:"
                        ],
                        "start_col": 76,
                        "start_line": 285
                    }
                },
                "1657": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 42,
                        "end_line": 300,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 300
                    }
                },
                "1659": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 285,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 301,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 13,
                                "start_line": 301
                            },
                            "While expanding the reference 'path_len' in:"
                        ],
                        "start_col": 47,
                        "start_line": 285
                    }
                },
                "1660": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 290,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 30,
                                "end_line": 301,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 23,
                                "start_line": 301
                            },
                            "While expanding the reference 'amounts' in:"
                        ],
                        "start_col": 16,
                        "start_line": 290
                    }
                },
                "1661": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 301,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 301
                    }
                },
                "1662": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 38,
                                "end_line": 3,
                                "input_file": {
                                    "filename": "autogen/starknet/external/return/swap_exact_tokens_for_tokens/dc32f5d0914f96c001a00c9efce25a55e68a95d6f6a16c088a5a03c0190608fb.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 34,
                                        "end_line": 284,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 6,
                                        "start_line": 284
                                    },
                                    "While handling return value of"
                                ],
                                "start_col": 5,
                                "start_line": 3
                            },
                            "n_prefix_newlines": 0
                        }
                    ],
                    "inst": {
                        "end_col": 18,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/swap_exact_tokens_for_tokens/dc32f5d0914f96c001a00c9efce25a55e68a95d6f6a16c088a5a03c0190608fb.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 284,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 284
                            },
                            "While handling return value of"
                        ],
                        "start_col": 5,
                        "start_line": 4
                    }
                },
                "1664": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 53,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/d0188e44f9bf394c59a5ca0aa00029b3d4a0b84a048a492cac1961341ac80755.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 286,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 7,
                                "start_line": 286
                            },
                            "While handling return value 'amounts_len'"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "1665": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 50,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 286,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 26,
                                "start_line": 286
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 1,
                        "start_line": 2
                    }
                },
                "1666": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 45,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 286,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 26,
                                "start_line": 286
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 1,
                        "start_line": 5
                    }
                },
                "1668": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/d0188e44f9bf394c59a5ca0aa00029b3d4a0b84a048a492cac1961341ac80755.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 286,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 53,
                                        "end_line": 10,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 43,
                                                "end_line": 286,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 26,
                                                "start_line": 286
                                            },
                                            "While handling return value 'amounts'"
                                        ],
                                        "start_col": 35,
                                        "start_line": 10
                                    },
                                    "While expanding the reference '__return_value_ptr' in:"
                                ],
                                "start_col": 7,
                                "start_line": 286
                            },
                            "While handling return value 'amounts_len'"
                        ],
                        "start_col": 26,
                        "start_line": 2
                    }
                },
                "1670": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 81,
                        "end_line": 10,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 286,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 26,
                                "start_line": 286
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 56,
                        "start_line": 10
                    }
                },
                "1672": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 10,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 286,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 26,
                                "start_line": 286
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 1,
                        "start_line": 10
                    }
                },
                "1673": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/d0188e44f9bf394c59a5ca0aa00029b3d4a0b84a048a492cac1961341ac80755.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 286,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 49,
                                        "end_line": 7,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 43,
                                                "end_line": 286,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 32,
                                                        "end_line": 12,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 43,
                                                                "end_line": 286,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 26,
                                                                "start_line": 286
                                                            },
                                                            "While handling return value 'amounts'"
                                                        ],
                                                        "start_col": 9,
                                                        "start_line": 12
                                                    },
                                                    "While expanding the reference '__return_value_ptr_copy' in:"
                                                ],
                                                "start_col": 26,
                                                "start_line": 286
                                            },
                                            "While handling return value 'amounts'"
                                        ],
                                        "start_col": 31,
                                        "start_line": 7
                                    },
                                    "While expanding the reference '__return_value_ptr' in:"
                                ],
                                "start_col": 7,
                                "start_line": 286
                            },
                            "While handling return value 'amounts_len'"
                        ],
                        "start_col": 26,
                        "start_line": 2
                    }
                },
                "1675": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 26,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 286,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 26,
                                "start_line": 286
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 9,
                        "start_line": 13
                    }
                },
                "1676": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 14,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 286,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 26,
                                "start_line": 286
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 9,
                        "start_line": 14
                    }
                },
                "1678": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 14,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 286,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 26,
                                "start_line": 286
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 1,
                        "start_line": 11
                    }
                },
                "1680": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 286,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 40,
                                        "end_line": 10,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/return/swap_exact_tokens_for_tokens/dc32f5d0914f96c001a00c9efce25a55e68a95d6f6a16c088a5a03c0190608fb.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 284,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 284
                                            },
                                            "While handling return value of"
                                        ],
                                        "start_col": 25,
                                        "start_line": 10
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 26,
                                "start_line": 286
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 7,
                        "start_line": 5
                    }
                },
                "1681": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 63,
                        "end_line": 11,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/swap_exact_tokens_for_tokens/dc32f5d0914f96c001a00c9efce25a55e68a95d6f6a16c088a5a03c0190608fb.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 284,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 284
                            },
                            "While handling return value of"
                        ],
                        "start_col": 18,
                        "start_line": 11
                    }
                },
                "1682": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/swap_exact_tokens_for_tokens/dc32f5d0914f96c001a00c9efce25a55e68a95d6f6a16c088a5a03c0190608fb.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 284,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 38,
                                        "end_line": 12,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/return/swap_exact_tokens_for_tokens/dc32f5d0914f96c001a00c9efce25a55e68a95d6f6a16c088a5a03c0190608fb.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 284,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 284
                                            },
                                            "While handling return value of"
                                        ],
                                        "start_col": 14,
                                        "start_line": 12
                                    },
                                    "While expanding the reference '__return_value_ptr_start' in:"
                                ],
                                "start_col": 6,
                                "start_line": 284
                            },
                            "While handling return value of"
                        ],
                        "start_col": 11,
                        "start_line": 5
                    }
                },
                "1683": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 12,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/swap_exact_tokens_for_tokens/dc32f5d0914f96c001a00c9efce25a55e68a95d6f6a16c088a5a03c0190608fb.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 284,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 284
                            },
                            "While handling return value of"
                        ],
                        "start_col": 5,
                        "start_line": 9
                    }
                },
                "1684": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/17bd0551df5a8df79c6b70ac6dc0924fa6d2055a93458140faa9898c09939ea5.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 284,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 284
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 5,
                        "start_line": 2
                    }
                },
                "1686": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/741ea357d6336b0bed7bf0472425acd0311d543883b803388880e60a232040c7.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 98,
                                "end_line": 284,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 24,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 74,
                                                "end_line": 285,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 63,
                                                "start_line": 285
                                            },
                                            "While handling calldata argument 'path'"
                                        ],
                                        "start_col": 9,
                                        "start_line": 2
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 83,
                                "start_line": 284
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 23,
                        "start_line": 1
                    }
                },
                "1687": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 47,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/1a2c645314c38f7cbacc4fe8f48f8ae2ef9e43d925286d804280d816fb1e6157.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 61,
                                "end_line": 285,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 51,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 74,
                                                "end_line": 285,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 63,
                                                "start_line": 285
                                            },
                                            "While handling calldata argument 'path'"
                                        ],
                                        "start_col": 28,
                                        "start_line": 2
                                    },
                                    "While expanding the reference '__calldata_arg_path_len' in:"
                                ],
                                "start_col": 47,
                                "start_line": 285
                            },
                            "While handling calldata argument 'path_len'"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "1688": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 52,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 74,
                                "end_line": 285,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 63,
                                "start_line": 285
                            },
                            "While handling calldata argument 'path'"
                        ],
                        "start_col": 1,
                        "start_line": 2
                    }
                },
                "1689": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/1a2c645314c38f7cbacc4fe8f48f8ae2ef9e43d925286d804280d816fb1e6157.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 61,
                                "end_line": 285,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 40,
                                        "end_line": 8,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 74,
                                                "end_line": 285,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 63,
                                                "start_line": 285
                                            },
                                            "While handling calldata argument 'path'"
                                        ],
                                        "start_col": 26,
                                        "start_line": 8
                                    },
                                    "While expanding the reference '__calldata_ptr' in:"
                                ],
                                "start_col": 47,
                                "start_line": 285
                            },
                            "While handling calldata argument 'path_len'"
                        ],
                        "start_col": 22,
                        "start_line": 2
                    }
                },
                "1691": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 47,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/1a2c645314c38f7cbacc4fe8f48f8ae2ef9e43d925286d804280d816fb1e6157.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 61,
                                "end_line": 285,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 66,
                                        "end_line": 8,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 74,
                                                "end_line": 285,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 63,
                                                "start_line": 285
                                            },
                                            "While handling calldata argument 'path'"
                                        ],
                                        "start_col": 43,
                                        "start_line": 8
                                    },
                                    "While expanding the reference '__calldata_arg_path_len' in:"
                                ],
                                "start_col": 47,
                                "start_line": 285
                            },
                            "While handling calldata argument 'path_len'"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "1692": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 70,
                        "end_line": 8,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 74,
                                "end_line": 285,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 63,
                                "start_line": 285
                            },
                            "While handling calldata argument 'path'"
                        ],
                        "start_col": 26,
                        "start_line": 8
                    }
                },
                "1693": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/17555969c7a8d33c61486b2958282949e335d6b0bb9fd10ced86d32ba19f948c.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 100,
                                "end_line": 285,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 45,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/c31620b02d4d706f0542c989b2aadc01b0981d1f6a5933a8fe4937ace3d70d92.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 284,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 57,
                                                        "end_line": 1,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/arg_processor/01cba52f8515996bb9d7070bde81ff39281d096d7024a558efcba6e1fd2402cf.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 34,
                                                                "end_line": 284,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 6,
                                                                "start_line": 284
                                                            },
                                                            "While handling calldata of"
                                                        ],
                                                        "start_col": 35,
                                                        "start_line": 1
                                                    },
                                                    "While expanding the reference '__calldata_actual_size' in:"
                                                ],
                                                "start_col": 6,
                                                "start_line": 284
                                            },
                                            "While handling calldata of"
                                        ],
                                        "start_col": 31,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_ptr' in:"
                                ],
                                "start_col": 86,
                                "start_line": 285
                            },
                            "While handling calldata argument 'deadline'"
                        ],
                        "start_col": 22,
                        "start_line": 2
                    }
                },
                "1695": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 58,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/01cba52f8515996bb9d7070bde81ff39281d096d7024a558efcba6e1fd2402cf.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 284,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 284
                            },
                            "While handling calldata of"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "1696": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/741ea357d6336b0bed7bf0472425acd0311d543883b803388880e60a232040c7.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 98,
                                "end_line": 284,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 38,
                                        "end_line": 3,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 74,
                                                "end_line": 285,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 115,
                                                        "end_line": 1,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/10dbcdb9e76b3e9224c0632ffd81e9aac01948f3820e904391f49984014ee2f5.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 34,
                                                                "end_line": 284,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 6,
                                                                "start_line": 284
                                                            },
                                                            "While constructing the external wrapper for:"
                                                        ],
                                                        "start_col": 100,
                                                        "start_line": 1
                                                    },
                                                    "While expanding the reference 'range_check_ptr' in:"
                                                ],
                                                "start_col": 63,
                                                "start_line": 285
                                            },
                                            "While handling calldata argument 'path'"
                                        ],
                                        "start_col": 23,
                                        "start_line": 3
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 83,
                                "start_line": 284
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 23,
                        "start_line": 1
                    }
                },
                "1697": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 64,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/b2c52ca2d2a8fc8791a983086d8716c5eacd0c3d62934914d2286f84b98ff4cb.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 53,
                                "end_line": 284,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 55,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/10dbcdb9e76b3e9224c0632ffd81e9aac01948f3820e904391f49984014ee2f5.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 284,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 284
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 44,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 35,
                                "start_line": 284
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 19,
                        "start_line": 1
                    }
                },
                "1698": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 110,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/9684a85e93c782014ca14293edea4eb2502039a5a7b6538ecd39c56faaf12529.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 81,
                                "end_line": 284,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 82,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/10dbcdb9e76b3e9224c0632ffd81e9aac01948f3820e904391f49984014ee2f5.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 284,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 284
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 70,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 55,
                                "start_line": 284
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 20,
                        "start_line": 1
                    }
                },
                "1699": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 42,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 74,
                                "end_line": 285,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 115,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/10dbcdb9e76b3e9224c0632ffd81e9aac01948f3820e904391f49984014ee2f5.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 284,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 284
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 100,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 63,
                                "start_line": 285
                            },
                            "While handling calldata argument 'path'"
                        ],
                        "start_col": 23,
                        "start_line": 3
                    }
                },
                "1701": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/f7de35c9223a7892c86748eb0198d5e7ca32c40f8e1de5c9a5ed02d3a6b2c64a.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 285,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 149,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/10dbcdb9e76b3e9224c0632ffd81e9aac01948f3820e904391f49984014ee2f5.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 284,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 284
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 126,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_amountIn' in:"
                                ],
                                "start_col": 5,
                                "start_line": 285
                            },
                            "While handling calldata argument 'amountIn'"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "1702": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/f7de35c9223a7892c86748eb0198d5e7ca32c40f8e1de5c9a5ed02d3a6b2c64a.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 285,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 149,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/10dbcdb9e76b3e9224c0632ffd81e9aac01948f3820e904391f49984014ee2f5.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 284,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 284
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 126,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_amountIn' in:"
                                ],
                                "start_col": 5,
                                "start_line": 285
                            },
                            "While handling calldata argument 'amountIn'"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "1703": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/7875b68d87f9cca58f508e76869fd2eebb18903ded7de666e8766355a432c2e8.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 45,
                                "end_line": 285,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 191,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/10dbcdb9e76b3e9224c0632ffd81e9aac01948f3820e904391f49984014ee2f5.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 284,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 284
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 164,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_amountOutMin' in:"
                                ],
                                "start_col": 24,
                                "start_line": 285
                            },
                            "While handling calldata argument 'amountOutMin'"
                        ],
                        "start_col": 35,
                        "start_line": 1
                    }
                },
                "1704": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/7875b68d87f9cca58f508e76869fd2eebb18903ded7de666e8766355a432c2e8.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 45,
                                "end_line": 285,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 191,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/10dbcdb9e76b3e9224c0632ffd81e9aac01948f3820e904391f49984014ee2f5.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 284,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 284
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 164,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_amountOutMin' in:"
                                ],
                                "start_col": 24,
                                "start_line": 285
                            },
                            "While handling calldata argument 'amountOutMin'"
                        ],
                        "start_col": 35,
                        "start_line": 1
                    }
                },
                "1705": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 47,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/1a2c645314c38f7cbacc4fe8f48f8ae2ef9e43d925286d804280d816fb1e6157.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 61,
                                "end_line": 285,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 225,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/10dbcdb9e76b3e9224c0632ffd81e9aac01948f3820e904391f49984014ee2f5.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 284,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 284
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 202,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_path_len' in:"
                                ],
                                "start_col": 47,
                                "start_line": 285
                            },
                            "While handling calldata argument 'path_len'"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "1706": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 74,
                                "end_line": 285,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 251,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/10dbcdb9e76b3e9224c0632ffd81e9aac01948f3820e904391f49984014ee2f5.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 284,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 284
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 232,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_path' in:"
                                ],
                                "start_col": 63,
                                "start_line": 285
                            },
                            "While handling calldata argument 'path'"
                        ],
                        "start_col": 27,
                        "start_line": 5
                    }
                },
                "1708": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/dceaabc265216c0ff87c509d90446554648ec4d692c521cda952464d1fc22972.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 84,
                                "end_line": 285,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 273,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/10dbcdb9e76b3e9224c0632ffd81e9aac01948f3820e904391f49984014ee2f5.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 284,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 284
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 256,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_to' in:"
                                ],
                                "start_col": 76,
                                "start_line": 285
                            },
                            "While handling calldata argument 'to'"
                        ],
                        "start_col": 25,
                        "start_line": 1
                    }
                },
                "1709": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 47,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/17555969c7a8d33c61486b2958282949e335d6b0bb9fd10ced86d32ba19f948c.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 100,
                                "end_line": 285,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 307,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/10dbcdb9e76b3e9224c0632ffd81e9aac01948f3820e904391f49984014ee2f5.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 284,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 284
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 284,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_deadline' in:"
                                ],
                                "start_col": 86,
                                "start_line": 285
                            },
                            "While handling calldata argument 'deadline'"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "1710": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 284,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 6,
                        "start_line": 284
                    }
                },
                "1712": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 55,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/10dbcdb9e76b3e9224c0632ffd81e9aac01948f3820e904391f49984014ee2f5.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 284,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 55,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/10dbcdb9e76b3e9224c0632ffd81e9aac01948f3820e904391f49984014ee2f5.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 284,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 284
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 44,
                                        "start_line": 1
                                    },
                                    "While auto generating local variable for 'syscall_ptr'."
                                ],
                                "start_col": 6,
                                "start_line": 284
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 44,
                        "start_line": 1
                    }
                },
                "1713": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/10dbcdb9e76b3e9224c0632ffd81e9aac01948f3820e904391f49984014ee2f5.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 284,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 82,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/10dbcdb9e76b3e9224c0632ffd81e9aac01948f3820e904391f49984014ee2f5.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 284,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 284
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 70,
                                        "start_line": 1
                                    },
                                    "While auto generating local variable for 'pedersen_ptr'."
                                ],
                                "start_col": 6,
                                "start_line": 284
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 70,
                        "start_line": 1
                    }
                },
                "1714": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 115,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/10dbcdb9e76b3e9224c0632ffd81e9aac01948f3820e904391f49984014ee2f5.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 284,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 117,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/10dbcdb9e76b3e9224c0632ffd81e9aac01948f3820e904391f49984014ee2f5.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 284,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 284
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 102,
                                        "start_line": 2
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 284
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 100,
                        "start_line": 1
                    }
                },
                "1715": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 118,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/10dbcdb9e76b3e9224c0632ffd81e9aac01948f3820e904391f49984014ee2f5.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 284,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 284
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 48,
                        "start_line": 2
                    }
                },
                "1717": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 55,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/10dbcdb9e76b3e9224c0632ffd81e9aac01948f3820e904391f49984014ee2f5.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 284,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 55,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/10dbcdb9e76b3e9224c0632ffd81e9aac01948f3820e904391f49984014ee2f5.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 284,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 20,
                                                        "end_line": 1,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 34,
                                                                "end_line": 284,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 6,
                                                                "start_line": 284
                                                            },
                                                            "While constructing the external wrapper for:"
                                                        ],
                                                        "start_col": 9,
                                                        "start_line": 1
                                                    },
                                                    "While expanding the reference 'syscall_ptr' in:"
                                                ],
                                                "start_col": 6,
                                                "start_line": 284
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 44,
                                        "start_line": 1
                                    },
                                    "While auto generating local variable for 'syscall_ptr'."
                                ],
                                "start_col": 6,
                                "start_line": 284
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 44,
                        "start_line": 1
                    }
                },
                "1718": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/10dbcdb9e76b3e9224c0632ffd81e9aac01948f3820e904391f49984014ee2f5.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 284,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 82,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/10dbcdb9e76b3e9224c0632ffd81e9aac01948f3820e904391f49984014ee2f5.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 284,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 33,
                                                        "end_line": 1,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 34,
                                                                "end_line": 284,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 6,
                                                                "start_line": 284
                                                            },
                                                            "While constructing the external wrapper for:"
                                                        ],
                                                        "start_col": 21,
                                                        "start_line": 1
                                                    },
                                                    "While expanding the reference 'pedersen_ptr' in:"
                                                ],
                                                "start_col": 6,
                                                "start_line": 284
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 70,
                                        "start_line": 1
                                    },
                                    "While auto generating local variable for 'pedersen_ptr'."
                                ],
                                "start_col": 6,
                                "start_line": 284
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 70,
                        "start_line": 1
                    }
                },
                "1719": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/10dbcdb9e76b3e9224c0632ffd81e9aac01948f3820e904391f49984014ee2f5.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 284,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 49,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 284,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 284
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 34,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 284
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 6,
                        "start_line": 2
                    }
                },
                "1720": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/10dbcdb9e76b3e9224c0632ffd81e9aac01948f3820e904391f49984014ee2f5.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 284,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 62,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 284,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 284
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 50,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'retdata_size' in:"
                                ],
                                "start_col": 6,
                                "start_line": 284
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 23,
                        "start_line": 2
                    }
                },
                "1721": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 44,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/10dbcdb9e76b3e9224c0632ffd81e9aac01948f3820e904391f49984014ee2f5.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 284,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 70,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 284,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 284
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 63,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'retdata' in:"
                                ],
                                "start_col": 6,
                                "start_line": 284
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 37,
                        "start_line": 2
                    }
                },
                "1722": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/swap_exact_tokens_for_tokens/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 284,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 284
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "1723": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 318,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 318
                    }
                },
                "1725": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 53,
                        "end_line": 315,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 41,
                                "end_line": 337,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 31,
                                        "end_line": 319,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 319
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 23,
                                "start_line": 337
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 35,
                        "start_line": 315
                    }
                },
                "1726": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 81,
                        "end_line": 315,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 69,
                                "end_line": 337,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 31,
                                        "end_line": 319,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 319
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 43,
                                "start_line": 337
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 55,
                        "start_line": 315
                    }
                },
                "1727": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 98,
                        "end_line": 315,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 86,
                                "end_line": 337,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 31,
                                        "end_line": 319,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 319
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 71,
                                "start_line": 337
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 83,
                        "start_line": 315
                    }
                },
                "1728": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 100,
                        "end_line": 316,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 30,
                                "end_line": 319,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 22,
                                "start_line": 319
                            },
                            "While expanding the reference 'deadline' in:"
                        ],
                        "start_col": 86,
                        "start_line": 316
                    }
                },
                "1729": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 31,
                        "end_line": 319,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 319
                    }
                },
                "1731": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 42,
                        "end_line": 320,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 27,
                        "start_line": 320
                    }
                },
                "1733": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 320,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 320
                    }
                },
                "1734": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 320,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 40,
                                        "end_line": 610,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 88,
                                                "end_line": 321,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 37,
                                                "start_line": 321
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 22,
                                        "start_line": 610
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 27,
                                "start_line": 320
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 13
                    }
                },
                "1735": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 320,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 68,
                                        "end_line": 610,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 88,
                                                "end_line": 321,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 37,
                                                "start_line": 321
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 42,
                                        "start_line": 610
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 27,
                                "start_line": 320
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 35,
                        "start_line": 13
                    }
                },
                "1736": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 78,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 320,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 85,
                                        "end_line": 610,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 88,
                                                "end_line": 321,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 37,
                                                "start_line": 321
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 70,
                                        "start_line": 610
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 27,
                                "start_line": 320
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 63,
                        "start_line": 13
                    }
                },
                "1737": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 320,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 60,
                                "end_line": 321,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 53,
                                "start_line": 321
                            },
                            "While expanding the reference 'factory' in:"
                        ],
                        "start_col": 16,
                        "start_line": 320
                    }
                },
                "1738": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 316,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 71,
                                "end_line": 321,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 62,
                                "start_line": 321
                            },
                            "While expanding the reference 'amountOut' in:"
                        ],
                        "start_col": 5,
                        "start_line": 316
                    }
                },
                "1739": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 316,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 71,
                                "end_line": 321,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 62,
                                "start_line": 321
                            },
                            "While expanding the reference 'amountOut' in:"
                        ],
                        "start_col": 5,
                        "start_line": 316
                    }
                },
                "1740": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 316,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 81,
                                "end_line": 321,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 73,
                                "start_line": 321
                            },
                            "While expanding the reference 'path_len' in:"
                        ],
                        "start_col": 47,
                        "start_line": 316
                    }
                },
                "1741": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 74,
                        "end_line": 316,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 87,
                                "end_line": 321,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 83,
                                "start_line": 321
                            },
                            "While expanding the reference 'path' in:"
                        ],
                        "start_col": 63,
                        "start_line": 316
                    }
                },
                "1742": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 88,
                        "end_line": 321,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 37,
                        "start_line": 321
                    }
                },
                "1744": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 321,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 321
                    }
                },
                "1745": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 85,
                        "end_line": 610,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 88,
                                "end_line": 321,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 32,
                                        "end_line": 148,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 91,
                                                "end_line": 322,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 57,
                                                "start_line": 322
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 17,
                                        "start_line": 148
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 37,
                                "start_line": 321
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 70,
                        "start_line": 610
                    }
                },
                "1746": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 77,
                        "end_line": 322,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 68,
                        "start_line": 322
                    }
                },
                "1747": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 77,
                        "end_line": 322,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 68,
                        "start_line": 322
                    }
                },
                "1748": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 45,
                        "end_line": 316,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 90,
                                "end_line": 322,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 79,
                                "start_line": 322
                            },
                            "While expanding the reference 'amountInMax' in:"
                        ],
                        "start_col": 25,
                        "start_line": 316
                    }
                },
                "1749": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 45,
                        "end_line": 316,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 90,
                                "end_line": 322,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 79,
                                "start_line": 322
                            },
                            "While expanding the reference 'amountInMax' in:"
                        ],
                        "start_col": 25,
                        "start_line": 316
                    }
                },
                "1750": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 91,
                        "end_line": 322,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 57,
                        "start_line": 322
                    }
                },
                "1752": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 64,
                        "end_line": 324,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 324
                    }
                },
                "1754": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 610,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 88,
                                "end_line": 321,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 34,
                                        "end_line": 460,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 62,
                                                "end_line": 326,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 24,
                                                "start_line": 326
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 16,
                                        "start_line": 460
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 37,
                                "start_line": 321
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 22,
                        "start_line": 610
                    }
                },
                "1755": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 68,
                        "end_line": 610,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 88,
                                "end_line": 321,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 62,
                                        "end_line": 460,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 62,
                                                "end_line": 326,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 24,
                                                "start_line": 326
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 36,
                                        "start_line": 460
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 37,
                                "start_line": 321
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 42,
                        "start_line": 610
                    }
                },
                "1756": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 148,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 91,
                                "end_line": 322,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 79,
                                        "end_line": 460,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 62,
                                                "end_line": 326,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 24,
                                                "start_line": 326
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 64,
                                        "start_line": 460
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 57,
                                "start_line": 322
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 148
                    }
                },
                "1757": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 320,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 41,
                                "end_line": 326,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 34,
                                "start_line": 326
                            },
                            "While expanding the reference 'factory' in:"
                        ],
                        "start_col": 16,
                        "start_line": 320
                    }
                },
                "1758": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 49,
                        "end_line": 326,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 43,
                        "start_line": 326
                    }
                },
                "1759": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 326,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 51,
                        "start_line": 326
                    }
                },
                "1760": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 62,
                        "end_line": 326,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 24,
                        "start_line": 326
                    }
                },
                "1762": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 326,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 326
                    }
                },
                "1763": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 460,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 62,
                                "end_line": 326,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 43,
                                        "end_line": 200,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 40,
                                                "end_line": 327,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 20,
                                                "start_line": 327
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 25,
                                        "start_line": 200
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 24,
                                "start_line": 326
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 16,
                        "start_line": 460
                    }
                },
                "1764": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 327,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 20,
                        "start_line": 327
                    }
                },
                "1766": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 43,
                        "end_line": 200,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 40,
                                "end_line": 327,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 37,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/f8c7980cd46ed6d764c999318b5692736b724dc08c1bf96e92d1b77ddf37af10.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 34,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 98,
                                                        "end_line": 328,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 5,
                                                        "start_line": 328
                                                    },
                                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                ],
                                                "start_col": 10,
                                                "start_line": 34
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 19,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 20,
                                "start_line": 327
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 25,
                        "start_line": 200
                    }
                },
                "1767": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 79,
                        "end_line": 460,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 62,
                                "end_line": 326,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 54,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/f8c7980cd46ed6d764c999318b5692736b724dc08c1bf96e92d1b77ddf37af10.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 34,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 98,
                                                        "end_line": 328,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 5,
                                                        "start_line": 328
                                                    },
                                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                ],
                                                "start_col": 10,
                                                "start_line": 34
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 39,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 24,
                                "start_line": 326
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 64,
                        "start_line": 460
                    }
                },
                "1768": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 328,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 42,
                        "start_line": 328
                    }
                },
                "1769": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 16,
                        "end_line": 327,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 63,
                                "end_line": 328,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 57,
                                "start_line": 328
                            },
                            "While expanding the reference 'sender' in:"
                        ],
                        "start_col": 10,
                        "start_line": 327
                    }
                },
                "1770": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 326,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 79,
                                "end_line": 328,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 75,
                                "start_line": 328
                            },
                            "While expanding the reference 'pair' in:"
                        ],
                        "start_col": 16,
                        "start_line": 326
                    }
                },
                "1771": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 97,
                        "end_line": 328,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 88,
                        "start_line": 328
                    }
                },
                "1772": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 97,
                        "end_line": 328,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 88,
                        "start_line": 328
                    }
                },
                "1773": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 98,
                        "end_line": 328,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 328
                    }
                },
                "1775": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/f8c7980cd46ed6d764c999318b5692736b724dc08c1bf96e92d1b77ddf37af10.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 34,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 98,
                                        "end_line": 328,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 30,
                                                "end_line": 401,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 42,
                                                        "end_line": 329,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 5,
                                                        "start_line": 329
                                                    },
                                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                ],
                                                "start_col": 12,
                                                "start_line": 401
                                            },
                                            "While expanding the reference 'syscall_ptr' in:"
                                        ],
                                        "start_col": 5,
                                        "start_line": 328
                                    },
                                    "While trying to update the implicit return value 'syscall_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 34
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 19,
                        "start_line": 1
                    }
                },
                "1776": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 62,
                        "end_line": 460,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 62,
                                "end_line": 326,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 58,
                                        "end_line": 401,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 42,
                                                "end_line": 329,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 329
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 32,
                                        "start_line": 401
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 24,
                                "start_line": 326
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 36,
                        "start_line": 460
                    }
                },
                "1777": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IERC20/transferFrom/f8c7980cd46ed6d764c999318b5692736b724dc08c1bf96e92d1b77ddf37af10.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 34,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 98,
                                        "end_line": 328,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 75,
                                                "end_line": 401,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 42,
                                                        "end_line": 329,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 5,
                                                        "start_line": 329
                                                    },
                                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                ],
                                                "start_col": 60,
                                                "start_line": 401
                                            },
                                            "While expanding the reference 'range_check_ptr' in:"
                                        ],
                                        "start_col": 5,
                                        "start_line": 328
                                    },
                                    "While trying to update the implicit return value 'range_check_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 34
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 39,
                        "start_line": 1
                    }
                },
                "1778": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 12,
                        "end_line": 329,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 11,
                        "start_line": 329
                    }
                },
                "1780": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 316,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 329,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 14,
                                "start_line": 329
                            },
                            "While expanding the reference 'path_len' in:"
                        ],
                        "start_col": 47,
                        "start_line": 316
                    }
                },
                "1781": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 321,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 31,
                                "end_line": 329,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 24,
                                "start_line": 329
                            },
                            "While expanding the reference 'amounts' in:"
                        ],
                        "start_col": 16,
                        "start_line": 321
                    }
                },
                "1782": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 74,
                        "end_line": 316,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 37,
                                "end_line": 329,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 33,
                                "start_line": 329
                            },
                            "While expanding the reference 'path' in:"
                        ],
                        "start_col": 63,
                        "start_line": 316
                    }
                },
                "1783": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 84,
                        "end_line": 316,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 41,
                                "end_line": 329,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 39,
                                "start_line": 329
                            },
                            "While expanding the reference 'to' in:"
                        ],
                        "start_col": 76,
                        "start_line": 316
                    }
                },
                "1784": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 42,
                        "end_line": 329,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 329
                    }
                },
                "1786": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 316,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 330,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 13,
                                "start_line": 330
                            },
                            "While expanding the reference 'path_len' in:"
                        ],
                        "start_col": 47,
                        "start_line": 316
                    }
                },
                "1787": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 321,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 30,
                                "end_line": 330,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 23,
                                "start_line": 330
                            },
                            "While expanding the reference 'amounts' in:"
                        ],
                        "start_col": 16,
                        "start_line": 321
                    }
                },
                "1788": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__main__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 330,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 330
                    }
                },
                "1789": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [
                        {
                            "location": {
                                "end_col": 38,
                                "end_line": 3,
                                "input_file": {
                                    "filename": "autogen/starknet/external/return/swap_tokens_for_exact_tokens/e6bc02655094226ec58b26f741d012521ee9eadc5e9077818a38e8fb63533cd9.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 34,
                                        "end_line": 315,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 6,
                                        "start_line": 315
                                    },
                                    "While handling return value of"
                                ],
                                "start_col": 5,
                                "start_line": 3
                            },
                            "n_prefix_newlines": 0
                        }
                    ],
                    "inst": {
                        "end_col": 18,
                        "end_line": 4,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/swap_tokens_for_exact_tokens/e6bc02655094226ec58b26f741d012521ee9eadc5e9077818a38e8fb63533cd9.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 315,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 315
                            },
                            "While handling return value of"
                        ],
                        "start_col": 5,
                        "start_line": 4
                    }
                },
                "1791": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 53,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/d0188e44f9bf394c59a5ca0aa00029b3d4a0b84a048a492cac1961341ac80755.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 317,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 7,
                                "start_line": 317
                            },
                            "While handling return value 'amounts_len'"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "1792": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 50,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 317,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 26,
                                "start_line": 317
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 1,
                        "start_line": 2
                    }
                },
                "1793": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 45,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 317,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 26,
                                "start_line": 317
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 1,
                        "start_line": 5
                    }
                },
                "1795": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/d0188e44f9bf394c59a5ca0aa00029b3d4a0b84a048a492cac1961341ac80755.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 317,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 53,
                                        "end_line": 10,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 43,
                                                "end_line": 317,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 26,
                                                "start_line": 317
                                            },
                                            "While handling return value 'amounts'"
                                        ],
                                        "start_col": 35,
                                        "start_line": 10
                                    },
                                    "While expanding the reference '__return_value_ptr' in:"
                                ],
                                "start_col": 7,
                                "start_line": 317
                            },
                            "While handling return value 'amounts_len'"
                        ],
                        "start_col": 26,
                        "start_line": 2
                    }
                },
                "1797": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 81,
                        "end_line": 10,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 317,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 26,
                                "start_line": 317
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 56,
                        "start_line": 10
                    }
                },
                "1799": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 10,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 317,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 26,
                                "start_line": 317
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 1,
                        "start_line": 10
                    }
                },
                "1800": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/d0188e44f9bf394c59a5ca0aa00029b3d4a0b84a048a492cac1961341ac80755.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 317,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 49,
                                        "end_line": 7,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 43,
                                                "end_line": 317,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 32,
                                                        "end_line": 12,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 43,
                                                                "end_line": 317,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 26,
                                                                "start_line": 317
                                                            },
                                                            "While handling return value 'amounts'"
                                                        ],
                                                        "start_col": 9,
                                                        "start_line": 12
                                                    },
                                                    "While expanding the reference '__return_value_ptr_copy' in:"
                                                ],
                                                "start_col": 26,
                                                "start_line": 317
                                            },
                                            "While handling return value 'amounts'"
                                        ],
                                        "start_col": 31,
                                        "start_line": 7
                                    },
                                    "While expanding the reference '__return_value_ptr' in:"
                                ],
                                "start_col": 7,
                                "start_line": 317
                            },
                            "While handling return value 'amounts_len'"
                        ],
                        "start_col": 26,
                        "start_line": 2
                    }
                },
                "1802": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 26,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 317,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 26,
                                "start_line": 317
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 9,
                        "start_line": 13
                    }
                },
                "1803": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 14,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 317,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 26,
                                "start_line": 317
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 9,
                        "start_line": 14
                    }
                },
                "1805": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 14,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 317,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 26,
                                "start_line": 317
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 1,
                        "start_line": 11
                    }
                },
                "1807": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/8222a54ac90164a313c5a6c3cc76dd1561f20b6e6b4e1e4b8ec2a5ab174aa6b6.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 317,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 40,
                                        "end_line": 10,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/return/swap_tokens_for_exact_tokens/e6bc02655094226ec58b26f741d012521ee9eadc5e9077818a38e8fb63533cd9.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 315,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 315
                                            },
                                            "While handling return value of"
                                        ],
                                        "start_col": 25,
                                        "start_line": 10
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 26,
                                "start_line": 317
                            },
                            "While handling return value 'amounts'"
                        ],
                        "start_col": 7,
                        "start_line": 5
                    }
                },
                "1808": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 63,
                        "end_line": 11,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/swap_tokens_for_exact_tokens/e6bc02655094226ec58b26f741d012521ee9eadc5e9077818a38e8fb63533cd9.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 315,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 315
                            },
                            "While handling return value of"
                        ],
                        "start_col": 18,
                        "start_line": 11
                    }
                },
                "1809": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/swap_tokens_for_exact_tokens/e6bc02655094226ec58b26f741d012521ee9eadc5e9077818a38e8fb63533cd9.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 315,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 38,
                                        "end_line": 12,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/return/swap_tokens_for_exact_tokens/e6bc02655094226ec58b26f741d012521ee9eadc5e9077818a38e8fb63533cd9.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 315,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 315
                                            },
                                            "While handling return value of"
                                        ],
                                        "start_col": 14,
                                        "start_line": 12
                                    },
                                    "While expanding the reference '__return_value_ptr_start' in:"
                                ],
                                "start_col": 6,
                                "start_line": 315
                            },
                            "While handling return value of"
                        ],
                        "start_col": 11,
                        "start_line": 5
                    }
                },
                "1810": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens_encode_return"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 12,
                        "input_file": {
                            "filename": "autogen/starknet/external/return/swap_tokens_for_exact_tokens/e6bc02655094226ec58b26f741d012521ee9eadc5e9077818a38e8fb63533cd9.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 315,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 315
                            },
                            "While handling return value of"
                        ],
                        "start_col": 5,
                        "start_line": 9
                    }
                },
                "1811": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/1de8d3225583e0fa7eb46d75fb1a7864c38dfc9fd281e2f1ece4335f1b3783d8.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 315,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 315
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 5,
                        "start_line": 2
                    }
                },
                "1813": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/741ea357d6336b0bed7bf0472425acd0311d543883b803388880e60a232040c7.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 98,
                                "end_line": 315,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 24,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 74,
                                                "end_line": 316,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 63,
                                                "start_line": 316
                                            },
                                            "While handling calldata argument 'path'"
                                        ],
                                        "start_col": 9,
                                        "start_line": 2
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 83,
                                "start_line": 315
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 23,
                        "start_line": 1
                    }
                },
                "1814": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 47,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/1a2c645314c38f7cbacc4fe8f48f8ae2ef9e43d925286d804280d816fb1e6157.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 61,
                                "end_line": 316,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 51,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 74,
                                                "end_line": 316,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 63,
                                                "start_line": 316
                                            },
                                            "While handling calldata argument 'path'"
                                        ],
                                        "start_col": 28,
                                        "start_line": 2
                                    },
                                    "While expanding the reference '__calldata_arg_path_len' in:"
                                ],
                                "start_col": 47,
                                "start_line": 316
                            },
                            "While handling calldata argument 'path_len'"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "1815": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 52,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 74,
                                "end_line": 316,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 63,
                                "start_line": 316
                            },
                            "While handling calldata argument 'path'"
                        ],
                        "start_col": 1,
                        "start_line": 2
                    }
                },
                "1816": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/1a2c645314c38f7cbacc4fe8f48f8ae2ef9e43d925286d804280d816fb1e6157.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 61,
                                "end_line": 316,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 40,
                                        "end_line": 8,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 74,
                                                "end_line": 316,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 63,
                                                "start_line": 316
                                            },
                                            "While handling calldata argument 'path'"
                                        ],
                                        "start_col": 26,
                                        "start_line": 8
                                    },
                                    "While expanding the reference '__calldata_ptr' in:"
                                ],
                                "start_col": 47,
                                "start_line": 316
                            },
                            "While handling calldata argument 'path_len'"
                        ],
                        "start_col": 22,
                        "start_line": 2
                    }
                },
                "1818": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 47,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/1a2c645314c38f7cbacc4fe8f48f8ae2ef9e43d925286d804280d816fb1e6157.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 61,
                                "end_line": 316,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 66,
                                        "end_line": 8,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 74,
                                                "end_line": 316,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 63,
                                                "start_line": 316
                                            },
                                            "While handling calldata argument 'path'"
                                        ],
                                        "start_col": 43,
                                        "start_line": 8
                                    },
                                    "While expanding the reference '__calldata_arg_path_len' in:"
                                ],
                                "start_col": 47,
                                "start_line": 316
                            },
                            "While handling calldata argument 'path_len'"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "1819": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 70,
                        "end_line": 8,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 74,
                                "end_line": 316,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 63,
                                "start_line": 316
                            },
                            "While handling calldata argument 'path'"
                        ],
                        "start_col": 26,
                        "start_line": 8
                    }
                },
                "1820": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/17555969c7a8d33c61486b2958282949e335d6b0bb9fd10ced86d32ba19f948c.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 100,
                                "end_line": 316,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 45,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/c31620b02d4d706f0542c989b2aadc01b0981d1f6a5933a8fe4937ace3d70d92.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 315,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 57,
                                                        "end_line": 1,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/arg_processor/01cba52f8515996bb9d7070bde81ff39281d096d7024a558efcba6e1fd2402cf.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 34,
                                                                "end_line": 315,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 6,
                                                                "start_line": 315
                                                            },
                                                            "While handling calldata of"
                                                        ],
                                                        "start_col": 35,
                                                        "start_line": 1
                                                    },
                                                    "While expanding the reference '__calldata_actual_size' in:"
                                                ],
                                                "start_col": 6,
                                                "start_line": 315
                                            },
                                            "While handling calldata of"
                                        ],
                                        "start_col": 31,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_ptr' in:"
                                ],
                                "start_col": 86,
                                "start_line": 316
                            },
                            "While handling calldata argument 'deadline'"
                        ],
                        "start_col": 22,
                        "start_line": 2
                    }
                },
                "1822": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 58,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/01cba52f8515996bb9d7070bde81ff39281d096d7024a558efcba6e1fd2402cf.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 315,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 315
                            },
                            "While handling calldata of"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "1823": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/741ea357d6336b0bed7bf0472425acd0311d543883b803388880e60a232040c7.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 98,
                                "end_line": 315,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 38,
                                        "end_line": 3,
                                        "input_file": {
                                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 74,
                                                "end_line": 316,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 115,
                                                        "end_line": 1,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/ec76a2ec5a5a0f40064f3d4c7f96755d0d1e6a807ed42b754ccb9b7bc0757ba0.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 34,
                                                                "end_line": 315,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 6,
                                                                "start_line": 315
                                                            },
                                                            "While constructing the external wrapper for:"
                                                        ],
                                                        "start_col": 100,
                                                        "start_line": 1
                                                    },
                                                    "While expanding the reference 'range_check_ptr' in:"
                                                ],
                                                "start_col": 63,
                                                "start_line": 316
                                            },
                                            "While handling calldata argument 'path'"
                                        ],
                                        "start_col": 23,
                                        "start_line": 3
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 83,
                                "start_line": 315
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 23,
                        "start_line": 1
                    }
                },
                "1824": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 64,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/b2c52ca2d2a8fc8791a983086d8716c5eacd0c3d62934914d2286f84b98ff4cb.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 53,
                                "end_line": 315,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 55,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/ec76a2ec5a5a0f40064f3d4c7f96755d0d1e6a807ed42b754ccb9b7bc0757ba0.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 315,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 315
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 44,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 35,
                                "start_line": 315
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 19,
                        "start_line": 1
                    }
                },
                "1825": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 110,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/9684a85e93c782014ca14293edea4eb2502039a5a7b6538ecd39c56faaf12529.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 81,
                                "end_line": 315,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 82,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/ec76a2ec5a5a0f40064f3d4c7f96755d0d1e6a807ed42b754ccb9b7bc0757ba0.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 315,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 315
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 70,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 55,
                                "start_line": 315
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 20,
                        "start_line": 1
                    }
                },
                "1826": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 42,
                        "end_line": 3,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 74,
                                "end_line": 316,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 115,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/ec76a2ec5a5a0f40064f3d4c7f96755d0d1e6a807ed42b754ccb9b7bc0757ba0.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 315,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 315
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 100,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 63,
                                "start_line": 316
                            },
                            "While handling calldata argument 'path'"
                        ],
                        "start_col": 23,
                        "start_line": 3
                    }
                },
                "1828": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/c6477613576c1865c350adaa5562664ab5c9cf232374f021ab29a9b963be2b8c.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 23,
                                "end_line": 316,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 151,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/ec76a2ec5a5a0f40064f3d4c7f96755d0d1e6a807ed42b754ccb9b7bc0757ba0.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 315,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 315
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 127,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_amountOut' in:"
                                ],
                                "start_col": 5,
                                "start_line": 316
                            },
                            "While handling calldata argument 'amountOut'"
                        ],
                        "start_col": 32,
                        "start_line": 1
                    }
                },
                "1829": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/c6477613576c1865c350adaa5562664ab5c9cf232374f021ab29a9b963be2b8c.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 23,
                                "end_line": 316,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 151,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/ec76a2ec5a5a0f40064f3d4c7f96755d0d1e6a807ed42b754ccb9b7bc0757ba0.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 315,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 315
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 127,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_amountOut' in:"
                                ],
                                "start_col": 5,
                                "start_line": 316
                            },
                            "While handling calldata argument 'amountOut'"
                        ],
                        "start_col": 32,
                        "start_line": 1
                    }
                },
                "1830": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/7502b492c5c118f29faec2b621e16fec645a16e1b8dcaa989b13a716493f50f1.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 45,
                                "end_line": 316,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 191,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/ec76a2ec5a5a0f40064f3d4c7f96755d0d1e6a807ed42b754ccb9b7bc0757ba0.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 315,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 315
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 165,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_amountInMax' in:"
                                ],
                                "start_col": 25,
                                "start_line": 316
                            },
                            "While handling calldata argument 'amountInMax'"
                        ],
                        "start_col": 34,
                        "start_line": 1
                    }
                },
                "1831": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/7502b492c5c118f29faec2b621e16fec645a16e1b8dcaa989b13a716493f50f1.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 45,
                                "end_line": 316,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 191,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/ec76a2ec5a5a0f40064f3d4c7f96755d0d1e6a807ed42b754ccb9b7bc0757ba0.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 315,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 315
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 165,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_amountInMax' in:"
                                ],
                                "start_col": 25,
                                "start_line": 316
                            },
                            "While handling calldata argument 'amountInMax'"
                        ],
                        "start_col": 34,
                        "start_line": 1
                    }
                },
                "1832": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 47,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/1a2c645314c38f7cbacc4fe8f48f8ae2ef9e43d925286d804280d816fb1e6157.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 61,
                                "end_line": 316,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 225,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/ec76a2ec5a5a0f40064f3d4c7f96755d0d1e6a807ed42b754ccb9b7bc0757ba0.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 315,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 315
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 202,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_path_len' in:"
                                ],
                                "start_col": 47,
                                "start_line": 316
                            },
                            "While handling calldata argument 'path_len'"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "1833": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 5,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/c6ed5f6e8320bc318ba4b362b7f4f3cbcdac77dc7abee415491205bbc3301930.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 74,
                                "end_line": 316,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 251,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/ec76a2ec5a5a0f40064f3d4c7f96755d0d1e6a807ed42b754ccb9b7bc0757ba0.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 315,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 315
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 232,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_path' in:"
                                ],
                                "start_col": 63,
                                "start_line": 316
                            },
                            "While handling calldata argument 'path'"
                        ],
                        "start_col": 27,
                        "start_line": 5
                    }
                },
                "1835": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/dceaabc265216c0ff87c509d90446554648ec4d692c521cda952464d1fc22972.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 84,
                                "end_line": 316,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 273,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/ec76a2ec5a5a0f40064f3d4c7f96755d0d1e6a807ed42b754ccb9b7bc0757ba0.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 315,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 315
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 256,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_to' in:"
                                ],
                                "start_col": 76,
                                "start_line": 316
                            },
                            "While handling calldata argument 'to'"
                        ],
                        "start_col": 25,
                        "start_line": 1
                    }
                },
                "1836": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 47,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/arg_processor/17555969c7a8d33c61486b2958282949e335d6b0bb9fd10ced86d32ba19f948c.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 100,
                                "end_line": 316,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 307,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/ec76a2ec5a5a0f40064f3d4c7f96755d0d1e6a807ed42b754ccb9b7bc0757ba0.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 315,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 315
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 284,
                                        "start_line": 1
                                    },
                                    "While expanding the reference '__calldata_arg_deadline' in:"
                                ],
                                "start_col": 86,
                                "start_line": 316
                            },
                            "While handling calldata argument 'deadline'"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "1837": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 315,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 6,
                        "start_line": 315
                    }
                },
                "1839": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 55,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/ec76a2ec5a5a0f40064f3d4c7f96755d0d1e6a807ed42b754ccb9b7bc0757ba0.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 315,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 55,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/ec76a2ec5a5a0f40064f3d4c7f96755d0d1e6a807ed42b754ccb9b7bc0757ba0.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 315,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 315
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 44,
                                        "start_line": 1
                                    },
                                    "While auto generating local variable for 'syscall_ptr'."
                                ],
                                "start_col": 6,
                                "start_line": 315
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 44,
                        "start_line": 1
                    }
                },
                "1840": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/ec76a2ec5a5a0f40064f3d4c7f96755d0d1e6a807ed42b754ccb9b7bc0757ba0.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 315,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 82,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/ec76a2ec5a5a0f40064f3d4c7f96755d0d1e6a807ed42b754ccb9b7bc0757ba0.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 315,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 315
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 70,
                                        "start_line": 1
                                    },
                                    "While auto generating local variable for 'pedersen_ptr'."
                                ],
                                "start_col": 6,
                                "start_line": 315
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 70,
                        "start_line": 1
                    }
                },
                "1841": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 115,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/ec76a2ec5a5a0f40064f3d4c7f96755d0d1e6a807ed42b754ccb9b7bc0757ba0.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 315,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 117,
                                        "end_line": 2,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/ec76a2ec5a5a0f40064f3d4c7f96755d0d1e6a807ed42b754ccb9b7bc0757ba0.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 315,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 315
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 102,
                                        "start_line": 2
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 315
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 100,
                        "start_line": 1
                    }
                },
                "1842": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 118,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/ec76a2ec5a5a0f40064f3d4c7f96755d0d1e6a807ed42b754ccb9b7bc0757ba0.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 315,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 315
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 48,
                        "start_line": 2
                    }
                },
                "1844": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 55,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/ec76a2ec5a5a0f40064f3d4c7f96755d0d1e6a807ed42b754ccb9b7bc0757ba0.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 315,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 55,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/ec76a2ec5a5a0f40064f3d4c7f96755d0d1e6a807ed42b754ccb9b7bc0757ba0.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 315,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 20,
                                                        "end_line": 1,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 34,
                                                                "end_line": 315,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 6,
                                                                "start_line": 315
                                                            },
                                                            "While constructing the external wrapper for:"
                                                        ],
                                                        "start_col": 9,
                                                        "start_line": 1
                                                    },
                                                    "While expanding the reference 'syscall_ptr' in:"
                                                ],
                                                "start_col": 6,
                                                "start_line": 315
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 44,
                                        "start_line": 1
                                    },
                                    "While auto generating local variable for 'syscall_ptr'."
                                ],
                                "start_col": 6,
                                "start_line": 315
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 44,
                        "start_line": 1
                    }
                },
                "1845": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/ec76a2ec5a5a0f40064f3d4c7f96755d0d1e6a807ed42b754ccb9b7bc0757ba0.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 315,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 82,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/ec76a2ec5a5a0f40064f3d4c7f96755d0d1e6a807ed42b754ccb9b7bc0757ba0.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 315,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 33,
                                                        "end_line": 1,
                                                        "input_file": {
                                                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 34,
                                                                "end_line": 315,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 6,
                                                                "start_line": 315
                                                            },
                                                            "While constructing the external wrapper for:"
                                                        ],
                                                        "start_col": 21,
                                                        "start_line": 1
                                                    },
                                                    "While expanding the reference 'pedersen_ptr' in:"
                                                ],
                                                "start_col": 6,
                                                "start_line": 315
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 70,
                                        "start_line": 1
                                    },
                                    "While auto generating local variable for 'pedersen_ptr'."
                                ],
                                "start_col": 6,
                                "start_line": 315
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 70,
                        "start_line": 1
                    }
                },
                "1846": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/ec76a2ec5a5a0f40064f3d4c7f96755d0d1e6a807ed42b754ccb9b7bc0757ba0.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 315,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 49,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 315,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 315
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 34,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 6,
                                "start_line": 315
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 6,
                        "start_line": 2
                    }
                },
                "1847": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/ec76a2ec5a5a0f40064f3d4c7f96755d0d1e6a807ed42b754ccb9b7bc0757ba0.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 315,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 62,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 315,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 315
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 50,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'retdata_size' in:"
                                ],
                                "start_col": 6,
                                "start_line": 315
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 23,
                        "start_line": 2
                    }
                },
                "1848": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 44,
                        "end_line": 2,
                        "input_file": {
                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/ec76a2ec5a5a0f40064f3d4c7f96755d0d1e6a807ed42b754ccb9b7bc0757ba0.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 315,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 70,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 315,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 6,
                                                "start_line": 315
                                            },
                                            "While constructing the external wrapper for:"
                                        ],
                                        "start_col": 63,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'retdata' in:"
                                ],
                                "start_col": 6,
                                "start_line": 315
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 37,
                        "start_line": 2
                    }
                },
                "1849": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/external/swap_tokens_for_exact_tokens/da17921a4e81c09e730800bbf23bfdbe5e9e6bfaedc59d80fbf62087fa43c27d.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 315,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 6,
                                "start_line": 315
                            },
                            "While constructing the external wrapper for:"
                        ],
                        "start_col": 1,
                        "start_line": 1
                    }
                },
                "1850": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._ensure_deadline"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 337,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 44,
                                "end_line": 298,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 50,
                                        "end_line": 340,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 29,
                                        "start_line": 340
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 26,
                                "start_line": 298
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 23,
                        "start_line": 337
                    }
                },
                "1851": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._ensure_deadline"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 50,
                        "end_line": 340,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 29,
                        "start_line": 340
                    }
                },
                "1853": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._ensure_deadline"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 86,
                        "end_line": 337,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 31,
                                "end_line": 53,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 45,
                                        "end_line": 342,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 9,
                                        "start_line": 342
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 16,
                                "start_line": 53
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 71,
                        "start_line": 337
                    }
                },
                "1854": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._ensure_deadline"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 25,
                        "end_line": 340,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 34,
                                "end_line": 342,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 19,
                                "start_line": 342
                            },
                            "While expanding the reference 'block_timestamp' in:"
                        ],
                        "start_col": 10,
                        "start_line": 340
                    }
                },
                "1855": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._ensure_deadline"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 19,
                        "end_line": 338,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 44,
                                "end_line": 342,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 36,
                                "start_line": 342
                            },
                            "While expanding the reference 'deadline' in:"
                        ],
                        "start_col": 5,
                        "start_line": 338
                    }
                },
                "1856": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._ensure_deadline"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 45,
                        "end_line": 342,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 342
                    }
                },
                "1858": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._ensure_deadline"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 44,
                        "end_line": 298,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/starknet/common/syscalls.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 50,
                                "end_line": 340,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 41,
                                        "end_line": 337,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 15,
                                                "end_line": 344,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 344
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 23,
                                        "start_line": 337
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 29,
                                "start_line": 340
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 26,
                        "start_line": 298
                    }
                },
                "1859": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._ensure_deadline"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 69,
                        "end_line": 337,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 69,
                                "end_line": 337,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 15,
                                        "end_line": 344,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 344
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 43,
                                "start_line": 337
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 43,
                        "start_line": 337
                    }
                },
                "1860": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._ensure_deadline"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 31,
                        "end_line": 53,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 45,
                                "end_line": 342,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 86,
                                        "end_line": 337,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 15,
                                                "end_line": 344,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 344
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 71,
                                        "start_line": 337
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 9,
                                "start_line": 342
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 16,
                        "start_line": 53
                    }
                },
                "1861": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._ensure_deadline"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 344,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 344
                    }
                },
                "1862": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 355,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 355
                    }
                },
                "1864": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 39,
                        "end_line": 347,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 33,
                                "end_line": 13,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 42,
                                        "end_line": 356,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 27,
                                        "start_line": 356
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 15,
                                "start_line": 13
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 21,
                        "start_line": 347
                    }
                },
                "1865": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 347,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 61,
                                "end_line": 13,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 42,
                                        "end_line": 356,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 27,
                                        "start_line": 356
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 35,
                                "start_line": 13
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 41,
                        "start_line": 347
                    }
                },
                "1866": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 84,
                        "end_line": 347,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 78,
                                "end_line": 13,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 42,
                                        "end_line": 356,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 27,
                                        "start_line": 356
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 63,
                                "start_line": 13
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 69,
                        "start_line": 347
                    }
                },
                "1867": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 42,
                        "end_line": 356,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 27,
                        "start_line": 356
                    }
                },
                "1869": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 356,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 356
                    }
                },
                "1870": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 356,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 61,
                                        "end_line": 13,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 42,
                                                "end_line": 356,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 27,
                                                "start_line": 356
                                            },
                                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 35,
                                        "start_line": 13
                                    },
                                    "While auto generating local variable for 'pedersen_ptr'."
                                ],
                                "start_col": 27,
                                "start_line": 356
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 35,
                        "start_line": 13
                    }
                },
                "1871": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 356,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 33,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IFactory/get_pair/f3ae4d3d9703c6ba4fff0fd10db0740f6555616f3de5dffdec4e7ebb2533c4cb.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 18,
                                                "end_line": 55,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 97,
                                                        "end_line": 357,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 24,
                                                        "start_line": 357
                                                    },
                                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                ],
                                                "start_col": 10,
                                                "start_line": 55
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 15,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 27,
                                "start_line": 356
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 13
                    }
                },
                "1872": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 78,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 356,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 50,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IFactory/get_pair/f3ae4d3d9703c6ba4fff0fd10db0740f6555616f3de5dffdec4e7ebb2533c4cb.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 18,
                                                "end_line": 55,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 97,
                                                        "end_line": 357,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 24,
                                                        "start_line": 357
                                                    },
                                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                ],
                                                "start_col": 10,
                                                "start_line": 55
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 35,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 27,
                                "start_line": 356
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 63,
                        "start_line": 13
                    }
                },
                "1873": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 356,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 66,
                                "end_line": 357,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 59,
                                "start_line": 357
                            },
                            "While expanding the reference 'factory' in:"
                        ],
                        "start_col": 16,
                        "start_line": 356
                    }
                },
                "1874": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 17,
                        "end_line": 348,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 81,
                                "end_line": 357,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 75,
                                "start_line": 357
                            },
                            "While expanding the reference 'tokenA' in:"
                        ],
                        "start_col": 5,
                        "start_line": 348
                    }
                },
                "1875": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 17,
                        "end_line": 349,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 96,
                                "end_line": 357,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 90,
                                "start_line": 357
                            },
                            "While expanding the reference 'tokenB' in:"
                        ],
                        "start_col": 5,
                        "start_line": 349
                    }
                },
                "1876": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 97,
                        "end_line": 357,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 24,
                        "start_line": 357
                    }
                },
                "1878": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 357,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 357
                    }
                },
                "1879": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 359,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 359
                    }
                },
                "1881": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IFactory/get_pair/f3ae4d3d9703c6ba4fff0fd10db0740f6555616f3de5dffdec4e7ebb2533c4cb.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 55,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 97,
                                        "end_line": 357,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 36,
                                                "end_line": 1,
                                                "input_file": {
                                                    "filename": "autogen/starknet/contract_interface/IFactory/create_pair/ee84ebacd8edbc2e3304e1a9e2de38e564d83099cfaf093085de1c7a1339654f.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 21,
                                                        "end_line": 58,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 10,
                                                                "end_line": 362,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 26,
                                                                "start_line": 360
                                                            },
                                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                        ],
                                                        "start_col": 10,
                                                        "start_line": 58
                                                    },
                                                    "While handling contract interface function:"
                                                ],
                                                "start_col": 18,
                                                "start_line": 1
                                            },
                                            "While expanding the reference 'syscall_ptr' in:"
                                        ],
                                        "start_col": 24,
                                        "start_line": 357
                                    },
                                    "While trying to update the implicit return value 'syscall_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 55
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 15,
                        "start_line": 1
                    }
                },
                "1882": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 50,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IFactory/get_pair/f3ae4d3d9703c6ba4fff0fd10db0740f6555616f3de5dffdec4e7ebb2533c4cb.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 55,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 97,
                                        "end_line": 357,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 53,
                                                "end_line": 1,
                                                "input_file": {
                                                    "filename": "autogen/starknet/contract_interface/IFactory/create_pair/ee84ebacd8edbc2e3304e1a9e2de38e564d83099cfaf093085de1c7a1339654f.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 21,
                                                        "end_line": 58,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 10,
                                                                "end_line": 362,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 26,
                                                                "start_line": 360
                                                            },
                                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                        ],
                                                        "start_col": 10,
                                                        "start_line": 58
                                                    },
                                                    "While handling contract interface function:"
                                                ],
                                                "start_col": 38,
                                                "start_line": 1
                                            },
                                            "While expanding the reference 'range_check_ptr' in:"
                                        ],
                                        "start_col": 24,
                                        "start_line": 357
                                    },
                                    "While trying to update the implicit return value 'range_check_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 55
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 35,
                        "start_line": 1
                    }
                },
                "1883": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 356,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 37,
                                "end_line": 361,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 30,
                                "start_line": 361
                            },
                            "While expanding the reference 'factory' in:"
                        ],
                        "start_col": 16,
                        "start_line": 356
                    }
                },
                "1884": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 17,
                        "end_line": 348,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 52,
                                "end_line": 361,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 46,
                                "start_line": 361
                            },
                            "While expanding the reference 'tokenA' in:"
                        ],
                        "start_col": 5,
                        "start_line": 348
                    }
                },
                "1885": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 17,
                        "end_line": 349,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 67,
                                "end_line": 361,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 61,
                                "start_line": 361
                            },
                            "While expanding the reference 'tokenB' in:"
                        ],
                        "start_col": 5,
                        "start_line": 349
                    }
                },
                "1886": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 10,
                        "end_line": 362,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 26,
                        "start_line": 360
                    }
                },
                "1888": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 36,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IFactory/create_pair/ee84ebacd8edbc2e3304e1a9e2de38e564d83099cfaf093085de1c7a1339654f.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 58,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 10,
                                        "end_line": 362,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 38,
                                                "end_line": 469,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 100,
                                                        "end_line": 365,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 62,
                                                        "start_line": 365
                                                    },
                                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                ],
                                                "start_col": 20,
                                                "start_line": 469
                                            },
                                            "While expanding the reference 'syscall_ptr' in:"
                                        ],
                                        "start_col": 26,
                                        "start_line": 360
                                    },
                                    "While trying to update the implicit return value 'syscall_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 58
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 18,
                        "start_line": 1
                    }
                },
                "1889": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 356,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 61,
                                        "end_line": 13,
                                        "input_file": {
                                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 42,
                                                "end_line": 356,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 66,
                                                        "end_line": 469,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 100,
                                                                "end_line": 365,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 62,
                                                                "start_line": 365
                                                            },
                                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                                        ],
                                                        "start_col": 40,
                                                        "start_line": 469
                                                    },
                                                    "While expanding the reference 'pedersen_ptr' in:"
                                                ],
                                                "start_col": 27,
                                                "start_line": 356
                                            },
                                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 35,
                                        "start_line": 13
                                    },
                                    "While auto generating local variable for 'pedersen_ptr'."
                                ],
                                "start_col": 27,
                                "start_line": 356
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 35,
                        "start_line": 13
                    }
                },
                "1890": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 53,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IFactory/create_pair/ee84ebacd8edbc2e3304e1a9e2de38e564d83099cfaf093085de1c7a1339654f.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 58,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 10,
                                        "end_line": 362,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 83,
                                                "end_line": 469,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 100,
                                                        "end_line": 365,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 62,
                                                        "start_line": 365
                                                    },
                                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                ],
                                                "start_col": 68,
                                                "start_line": 469
                                            },
                                            "While expanding the reference 'range_check_ptr' in:"
                                        ],
                                        "start_col": 26,
                                        "start_line": 360
                                    },
                                    "While trying to update the implicit return value 'range_check_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 58
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 38,
                        "start_line": 1
                    }
                },
                "1891": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 356,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 83,
                                "end_line": 365,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 76,
                                "start_line": 365
                            },
                            "While expanding the reference 'factory' in:"
                        ],
                        "start_col": 16,
                        "start_line": 356
                    }
                },
                "1892": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 17,
                        "end_line": 348,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 91,
                                "end_line": 365,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 85,
                                "start_line": 365
                            },
                            "While expanding the reference 'tokenA' in:"
                        ],
                        "start_col": 5,
                        "start_line": 348
                    }
                },
                "1893": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 17,
                        "end_line": 349,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 99,
                                "end_line": 365,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 93,
                                "start_line": 365
                            },
                            "While expanding the reference 'tokenB' in:"
                        ],
                        "start_col": 5,
                        "start_line": 349
                    }
                },
                "1894": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 100,
                        "end_line": 365,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 62,
                        "start_line": 365
                    }
                },
                "1896": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 365,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 365
                    }
                },
                "1897": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 365,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 365
                    }
                },
                "1898": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 58,
                        "end_line": 365,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 35,
                        "start_line": 365
                    }
                },
                "1899": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 58,
                        "end_line": 365,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 35,
                        "start_line": 365
                    }
                },
                "1900": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 469,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 100,
                                "end_line": 365,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 44,
                                        "end_line": 59,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 83,
                                                "end_line": 366,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 44,
                                                "start_line": 366
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 26,
                                        "start_line": 59
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 62,
                                "start_line": 365
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 20,
                        "start_line": 469
                    }
                },
                "1901": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 66,
                        "end_line": 469,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 100,
                                "end_line": 365,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 72,
                                        "end_line": 59,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 83,
                                                "end_line": 366,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 44,
                                                "start_line": 366
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 46,
                                        "start_line": 59
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 62,
                                "start_line": 365
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 40,
                        "start_line": 469
                    }
                },
                "1902": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 83,
                        "end_line": 469,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 100,
                                "end_line": 365,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 89,
                                        "end_line": 59,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 83,
                                                "end_line": 366,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 44,
                                                "start_line": 366
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 74,
                                        "start_line": 59
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 62,
                                "start_line": 365
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 68,
                        "start_line": 469
                    }
                },
                "1903": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 365,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 72,
                                "end_line": 366,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 64,
                                "start_line": 366
                            },
                            "While expanding the reference 'reserveA' in:"
                        ],
                        "start_col": 16,
                        "start_line": 365
                    }
                },
                "1904": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 365,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 72,
                                "end_line": 366,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 64,
                                "start_line": 366
                            },
                            "While expanding the reference 'reserveA' in:"
                        ],
                        "start_col": 16,
                        "start_line": 365
                    }
                },
                "1905": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 49,
                        "end_line": 365,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 82,
                                "end_line": 366,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 74,
                                "start_line": 366
                            },
                            "While expanding the reference 'reserveB' in:"
                        ],
                        "start_col": 41,
                        "start_line": 365
                    }
                },
                "1906": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 49,
                        "end_line": 365,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 82,
                                "end_line": 366,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 74,
                                "start_line": 366
                            },
                            "While expanding the reference 'reserveB' in:"
                        ],
                        "start_col": 41,
                        "start_line": 365
                    }
                },
                "1907": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 83,
                        "end_line": 366,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 44,
                        "start_line": 366
                    }
                },
                "1909": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 44,
                        "end_line": 59,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 83,
                                "end_line": 366,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 44,
                                        "end_line": 59,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 83,
                                                "end_line": 366,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 44,
                                                "start_line": 366
                                            },
                                            "While trying to update the implicit return value 'syscall_ptr' in:"
                                        ],
                                        "start_col": 26,
                                        "start_line": 59
                                    },
                                    "While auto generating local variable for 'syscall_ptr'."
                                ],
                                "start_col": 44,
                                "start_line": 366
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 26,
                        "start_line": 59
                    }
                },
                "1910": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 59,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 83,
                                "end_line": 366,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 72,
                                        "end_line": 59,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 83,
                                                "end_line": 366,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 44,
                                                "start_line": 366
                                            },
                                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 46,
                                        "start_line": 59
                                    },
                                    "While auto generating local variable for 'pedersen_ptr'."
                                ],
                                "start_col": 44,
                                "start_line": 366
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 46,
                        "start_line": 59
                    }
                },
                "1911": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 95,
                        "end_line": 367,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 94,
                        "start_line": 367
                    }
                },
                "1913": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 98,
                        "end_line": 367,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 97,
                        "start_line": 367
                    }
                },
                "1915": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 100,
                        "end_line": 367,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 52,
                        "start_line": 367
                    }
                },
                "1917": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 52,
                        "end_line": 369,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 369
                    }
                },
                "1919": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 369,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 369
                    }
                },
                "1921": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 44,
                        "end_line": 59,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 83,
                                "end_line": 366,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 44,
                                        "end_line": 59,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 83,
                                                "end_line": 366,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 39,
                                                        "end_line": 347,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 49,
                                                                "end_line": 370,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 9,
                                                                "start_line": 370
                                                            },
                                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                        ],
                                                        "start_col": 21,
                                                        "start_line": 347
                                                    },
                                                    "While expanding the reference 'syscall_ptr' in:"
                                                ],
                                                "start_col": 44,
                                                "start_line": 366
                                            },
                                            "While trying to update the implicit return value 'syscall_ptr' in:"
                                        ],
                                        "start_col": 26,
                                        "start_line": 59
                                    },
                                    "While auto generating local variable for 'syscall_ptr'."
                                ],
                                "start_col": 44,
                                "start_line": 366
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 26,
                        "start_line": 59
                    }
                },
                "1922": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 59,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 83,
                                "end_line": 366,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 72,
                                        "end_line": 59,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 83,
                                                "end_line": 366,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 67,
                                                        "end_line": 347,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 49,
                                                                "end_line": 370,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 9,
                                                                "start_line": 370
                                                            },
                                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                                        ],
                                                        "start_col": 41,
                                                        "start_line": 347
                                                    },
                                                    "While expanding the reference 'pedersen_ptr' in:"
                                                ],
                                                "start_col": 44,
                                                "start_line": 366
                                            },
                                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 46,
                                        "start_line": 59
                                    },
                                    "While auto generating local variable for 'pedersen_ptr'."
                                ],
                                "start_col": 44,
                                "start_line": 366
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 46,
                        "start_line": 59
                    }
                },
                "1923": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 357,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 100,
                                "end_line": 367,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 84,
                                        "end_line": 347,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 49,
                                                "end_line": 370,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 9,
                                                "start_line": 370
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 69,
                                        "start_line": 347
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 52,
                                "start_line": 367
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 357
                    }
                },
                "1924": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 350,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 31,
                                "end_line": 370,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 17,
                                "start_line": 370
                            },
                            "While expanding the reference 'amountADesired' in:"
                        ],
                        "start_col": 5,
                        "start_line": 350
                    }
                },
                "1925": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 350,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 31,
                                "end_line": 370,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 17,
                                "start_line": 370
                            },
                            "While expanding the reference 'amountADesired' in:"
                        ],
                        "start_col": 5,
                        "start_line": 350
                    }
                },
                "1926": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 351,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 47,
                                "end_line": 370,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 33,
                                "start_line": 370
                            },
                            "While expanding the reference 'amountBDesired' in:"
                        ],
                        "start_col": 5,
                        "start_line": 351
                    }
                },
                "1927": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 351,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 47,
                                "end_line": 370,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 33,
                                "start_line": 370
                            },
                            "While expanding the reference 'amountBDesired' in:"
                        ],
                        "start_col": 5,
                        "start_line": 351
                    }
                },
                "1928": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 49,
                        "end_line": 370,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 370
                    }
                },
                "1929": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 44,
                        "end_line": 59,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 83,
                                "end_line": 366,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 44,
                                        "end_line": 59,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 83,
                                                "end_line": 366,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 31,
                                                        "end_line": 489,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 89,
                                                                "end_line": 372,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 47,
                                                                "start_line": 372
                                                            },
                                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                        ],
                                                        "start_col": 13,
                                                        "start_line": 489
                                                    },
                                                    "While expanding the reference 'syscall_ptr' in:"
                                                ],
                                                "start_col": 44,
                                                "start_line": 366
                                            },
                                            "While trying to update the implicit return value 'syscall_ptr' in:"
                                        ],
                                        "start_col": 26,
                                        "start_line": 59
                                    },
                                    "While auto generating local variable for 'syscall_ptr'."
                                ],
                                "start_col": 44,
                                "start_line": 366
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 26,
                        "start_line": 59
                    }
                },
                "1930": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 59,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 83,
                                "end_line": 366,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 72,
                                        "end_line": 59,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 83,
                                                "end_line": 366,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 59,
                                                        "end_line": 489,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 89,
                                                                "end_line": 372,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 47,
                                                                "start_line": 372
                                                            },
                                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                                        ],
                                                        "start_col": 33,
                                                        "start_line": 489
                                                    },
                                                    "While expanding the reference 'pedersen_ptr' in:"
                                                ],
                                                "start_col": 44,
                                                "start_line": 366
                                            },
                                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 46,
                                        "start_line": 59
                                    },
                                    "While auto generating local variable for 'pedersen_ptr'."
                                ],
                                "start_col": 44,
                                "start_line": 366
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 46,
                        "start_line": 59
                    }
                },
                "1931": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 357,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 100,
                                "end_line": 367,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 76,
                                        "end_line": 489,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 89,
                                                "end_line": 372,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 47,
                                                "start_line": 372
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 61,
                                        "start_line": 489
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 52,
                                "start_line": 367
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 357
                    }
                },
                "1932": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 350,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 68,
                                "end_line": 372,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 54,
                                "start_line": 372
                            },
                            "While expanding the reference 'amountADesired' in:"
                        ],
                        "start_col": 5,
                        "start_line": 350
                    }
                },
                "1933": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 350,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 68,
                                "end_line": 372,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 54,
                                "start_line": 372
                            },
                            "While expanding the reference 'amountADesired' in:"
                        ],
                        "start_col": 5,
                        "start_line": 350
                    }
                },
                "1934": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 365,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 78,
                                "end_line": 372,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 70,
                                "start_line": 372
                            },
                            "While expanding the reference 'reserveA' in:"
                        ],
                        "start_col": 16,
                        "start_line": 365
                    }
                },
                "1935": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 365,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 78,
                                "end_line": 372,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 70,
                                "start_line": 372
                            },
                            "While expanding the reference 'reserveA' in:"
                        ],
                        "start_col": 16,
                        "start_line": 365
                    }
                },
                "1936": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 49,
                        "end_line": 365,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 88,
                                "end_line": 372,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 80,
                                "start_line": 372
                            },
                            "While expanding the reference 'reserveB' in:"
                        ],
                        "start_col": 41,
                        "start_line": 365
                    }
                },
                "1937": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 49,
                        "end_line": 365,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 88,
                                "end_line": 372,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 80,
                                "start_line": 372
                            },
                            "While expanding the reference 'reserveB' in:"
                        ],
                        "start_col": 41,
                        "start_line": 365
                    }
                },
                "1938": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 89,
                        "end_line": 372,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 47,
                        "start_line": 372
                    }
                },
                "1940": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 43,
                        "end_line": 372,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 14,
                        "start_line": 372
                    }
                },
                "1941": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 43,
                        "end_line": 372,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 14,
                        "start_line": 372
                    }
                },
                "1942": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 76,
                        "end_line": 489,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 89,
                                "end_line": 372,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 32,
                                        "end_line": 148,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 10,
                                                "end_line": 375,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 66,
                                                "start_line": 373
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 17,
                                        "start_line": 148
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 47,
                                "start_line": 372
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 61,
                        "start_line": 489
                    }
                },
                "1943": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 372,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 27,
                                "end_line": 374,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 13,
                                "start_line": 374
                            },
                            "While expanding the reference 'amountBOptimal' in:"
                        ],
                        "start_col": 20,
                        "start_line": 372
                    }
                },
                "1944": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 372,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 27,
                                "end_line": 374,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 13,
                                "start_line": 374
                            },
                            "While expanding the reference 'amountBOptimal' in:"
                        ],
                        "start_col": 20,
                        "start_line": 372
                    }
                },
                "1945": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 351,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 374,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 29,
                                "start_line": 374
                            },
                            "While expanding the reference 'amountBDesired' in:"
                        ],
                        "start_col": 5,
                        "start_line": 351
                    }
                },
                "1946": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 351,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 374,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 29,
                                "start_line": 374
                            },
                            "While expanding the reference 'amountBDesired' in:"
                        ],
                        "start_col": 5,
                        "start_line": 351
                    }
                },
                "1947": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 10,
                        "end_line": 375,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 66,
                        "start_line": 373
                    }
                },
                "1949": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 66,
                        "end_line": 376,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 13,
                        "start_line": 376
                    }
                },
                "1951": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 11,
                        "end_line": 376,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 376
                    }
                },
                "1953": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 148,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 10,
                                "end_line": 375,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 32,
                                        "end_line": 148,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 14,
                                                "end_line": 379,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 69,
                                                "start_line": 377
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 17,
                                        "start_line": 148
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 66,
                                "start_line": 373
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 148
                    }
                },
                "1954": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 353,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 27,
                                "end_line": 378,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 17,
                                "start_line": 378
                            },
                            "While expanding the reference 'amountBMin' in:"
                        ],
                        "start_col": 5,
                        "start_line": 353
                    }
                },
                "1955": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 353,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 27,
                                "end_line": 378,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 17,
                                "start_line": 378
                            },
                            "While expanding the reference 'amountBMin' in:"
                        ],
                        "start_col": 5,
                        "start_line": 353
                    }
                },
                "1956": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 372,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 378,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 29,
                                "start_line": 378
                            },
                            "While expanding the reference 'amountBOptimal' in:"
                        ],
                        "start_col": 20,
                        "start_line": 372
                    }
                },
                "1957": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 372,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 378,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 29,
                                "start_line": 378
                            },
                            "While expanding the reference 'amountBOptimal' in:"
                        ],
                        "start_col": 20,
                        "start_line": 372
                    }
                },
                "1958": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 14,
                        "end_line": 379,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 69,
                        "start_line": 377
                    }
                },
                "1960": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 76,
                        "end_line": 381,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 17,
                        "start_line": 381
                    }
                },
                "1962": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 31,
                        "end_line": 489,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 89,
                                "end_line": 372,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 39,
                                        "end_line": 347,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 53,
                                                "end_line": 383,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 13,
                                                "start_line": 383
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 21,
                                        "start_line": 347
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 47,
                                "start_line": 372
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 13,
                        "start_line": 489
                    }
                },
                "1963": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 59,
                        "end_line": 489,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 89,
                                "end_line": 372,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 67,
                                        "end_line": 347,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 53,
                                                "end_line": 383,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 13,
                                                "start_line": 383
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 41,
                                        "start_line": 347
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 47,
                                "start_line": 372
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 33,
                        "start_line": 489
                    }
                },
                "1964": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 148,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 379,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 84,
                                        "end_line": 347,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 53,
                                                "end_line": 383,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 13,
                                                "start_line": 383
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 69,
                                        "start_line": 347
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 69,
                                "start_line": 377
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 148
                    }
                },
                "1965": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 350,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 35,
                                "end_line": 383,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 21,
                                "start_line": 383
                            },
                            "While expanding the reference 'amountADesired' in:"
                        ],
                        "start_col": 5,
                        "start_line": 350
                    }
                },
                "1966": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 350,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 35,
                                "end_line": 383,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 21,
                                "start_line": 383
                            },
                            "While expanding the reference 'amountADesired' in:"
                        ],
                        "start_col": 5,
                        "start_line": 350
                    }
                },
                "1967": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 372,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 51,
                                "end_line": 383,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 37,
                                "start_line": 383
                            },
                            "While expanding the reference 'amountBOptimal' in:"
                        ],
                        "start_col": 20,
                        "start_line": 372
                    }
                },
                "1968": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 372,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 51,
                                "end_line": 383,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 37,
                                "start_line": 383
                            },
                            "While expanding the reference 'amountBOptimal' in:"
                        ],
                        "start_col": 20,
                        "start_line": 372
                    }
                },
                "1969": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 53,
                        "end_line": 383,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 13,
                        "start_line": 383
                    }
                },
                "1970": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 31,
                        "end_line": 489,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 89,
                                "end_line": 372,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 31,
                                        "end_line": 489,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 93,
                                                "end_line": 385,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 51,
                                                "start_line": 385
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 13,
                                        "start_line": 489
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 47,
                                "start_line": 372
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 13,
                        "start_line": 489
                    }
                },
                "1971": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 59,
                        "end_line": 489,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 89,
                                "end_line": 372,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 59,
                                        "end_line": 489,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 93,
                                                "end_line": 385,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 51,
                                                "start_line": 385
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 33,
                                        "start_line": 489
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 47,
                                "start_line": 372
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 33,
                        "start_line": 489
                    }
                },
                "1972": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 148,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 10,
                                "end_line": 375,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 76,
                                        "end_line": 489,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 93,
                                                "end_line": 385,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 51,
                                                "start_line": 385
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 61,
                                        "start_line": 489
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 66,
                                "start_line": 373
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 148
                    }
                },
                "1973": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 351,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 72,
                                "end_line": 385,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 58,
                                "start_line": 385
                            },
                            "While expanding the reference 'amountBDesired' in:"
                        ],
                        "start_col": 5,
                        "start_line": 351
                    }
                },
                "1974": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 351,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 72,
                                "end_line": 385,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 58,
                                "start_line": 385
                            },
                            "While expanding the reference 'amountBDesired' in:"
                        ],
                        "start_col": 5,
                        "start_line": 351
                    }
                },
                "1975": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 49,
                        "end_line": 365,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 82,
                                "end_line": 385,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 74,
                                "start_line": 385
                            },
                            "While expanding the reference 'reserveB' in:"
                        ],
                        "start_col": 41,
                        "start_line": 365
                    }
                },
                "1976": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 49,
                        "end_line": 365,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 82,
                                "end_line": 385,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 74,
                                "start_line": 385
                            },
                            "While expanding the reference 'reserveB' in:"
                        ],
                        "start_col": 41,
                        "start_line": 365
                    }
                },
                "1977": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 365,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 92,
                                "end_line": 385,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 84,
                                "start_line": 385
                            },
                            "While expanding the reference 'reserveA' in:"
                        ],
                        "start_col": 16,
                        "start_line": 365
                    }
                },
                "1978": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 365,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 92,
                                "end_line": 385,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 84,
                                "start_line": 385
                            },
                            "While expanding the reference 'reserveA' in:"
                        ],
                        "start_col": 16,
                        "start_line": 365
                    }
                },
                "1979": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 93,
                        "end_line": 385,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 51,
                        "start_line": 385
                    }
                },
                "1981": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 47,
                        "end_line": 385,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 18,
                        "start_line": 385
                    }
                },
                "1982": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 47,
                        "end_line": 385,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 18,
                        "start_line": 385
                    }
                },
                "1983": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 76,
                        "end_line": 489,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 93,
                                "end_line": 385,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 32,
                                        "end_line": 148,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 14,
                                                "end_line": 388,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 70,
                                                "start_line": 386
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 17,
                                        "start_line": 148
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 51,
                                "start_line": 385
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 61,
                        "start_line": 489
                    }
                },
                "1984": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 385,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 31,
                                "end_line": 387,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 17,
                                "start_line": 387
                            },
                            "While expanding the reference 'amountAOptimal' in:"
                        ],
                        "start_col": 24,
                        "start_line": 385
                    }
                },
                "1985": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 385,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 31,
                                "end_line": 387,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 17,
                                "start_line": 387
                            },
                            "While expanding the reference 'amountAOptimal' in:"
                        ],
                        "start_col": 24,
                        "start_line": 385
                    }
                },
                "1986": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 350,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 47,
                                "end_line": 387,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 33,
                                "start_line": 387
                            },
                            "While expanding the reference 'amountADesired' in:"
                        ],
                        "start_col": 5,
                        "start_line": 350
                    }
                },
                "1987": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 350,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 47,
                                "end_line": 387,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 33,
                                "start_line": 387
                            },
                            "While expanding the reference 'amountADesired' in:"
                        ],
                        "start_col": 5,
                        "start_line": 350
                    }
                },
                "1988": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 14,
                        "end_line": 388,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 70,
                        "start_line": 386
                    }
                },
                "1990": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 73,
                        "end_line": 389,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 13,
                        "start_line": 389
                    }
                },
                "1992": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 148,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 388,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 32,
                                        "end_line": 148,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 14,
                                                "end_line": 392,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 69,
                                                "start_line": 390
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 17,
                                        "start_line": 148
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 70,
                                "start_line": 386
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 148
                    }
                },
                "1993": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 352,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 27,
                                "end_line": 391,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 17,
                                "start_line": 391
                            },
                            "While expanding the reference 'amountAMin' in:"
                        ],
                        "start_col": 5,
                        "start_line": 352
                    }
                },
                "1994": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 352,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 27,
                                "end_line": 391,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 17,
                                "start_line": 391
                            },
                            "While expanding the reference 'amountAMin' in:"
                        ],
                        "start_col": 5,
                        "start_line": 352
                    }
                },
                "1995": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 385,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 391,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 29,
                                "start_line": 391
                            },
                            "While expanding the reference 'amountAOptimal' in:"
                        ],
                        "start_col": 24,
                        "start_line": 385
                    }
                },
                "1996": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 385,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 391,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 29,
                                "start_line": 391
                            },
                            "While expanding the reference 'amountAOptimal' in:"
                        ],
                        "start_col": 24,
                        "start_line": 385
                    }
                },
                "1997": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 14,
                        "end_line": 392,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 69,
                        "start_line": 390
                    }
                },
                "1999": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 76,
                        "end_line": 394,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 17,
                        "start_line": 394
                    }
                },
                "2001": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 31,
                        "end_line": 489,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 93,
                                "end_line": 385,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 39,
                                        "end_line": 347,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 53,
                                                "end_line": 396,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 13,
                                                "start_line": 396
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 21,
                                        "start_line": 347
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 51,
                                "start_line": 385
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 13,
                        "start_line": 489
                    }
                },
                "2002": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 59,
                        "end_line": 489,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 93,
                                "end_line": 385,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 67,
                                        "end_line": 347,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 53,
                                                "end_line": 396,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 13,
                                                "start_line": 396
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 41,
                                        "start_line": 347
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 51,
                                "start_line": 385
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 33,
                        "start_line": 489
                    }
                },
                "2003": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 148,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 392,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 84,
                                        "end_line": 347,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 53,
                                                "end_line": 396,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 13,
                                                "start_line": 396
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 69,
                                        "start_line": 347
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 69,
                                "start_line": 390
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 148
                    }
                },
                "2004": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 385,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 35,
                                "end_line": 396,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 21,
                                "start_line": 396
                            },
                            "While expanding the reference 'amountAOptimal' in:"
                        ],
                        "start_col": 24,
                        "start_line": 385
                    }
                },
                "2005": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 385,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 35,
                                "end_line": 396,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 21,
                                "start_line": 396
                            },
                            "While expanding the reference 'amountAOptimal' in:"
                        ],
                        "start_col": 24,
                        "start_line": 385
                    }
                },
                "2006": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 351,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 51,
                                "end_line": 396,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 37,
                                "start_line": 396
                            },
                            "While expanding the reference 'amountBDesired' in:"
                        ],
                        "start_col": 5,
                        "start_line": 351
                    }
                },
                "2007": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 351,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 51,
                                "end_line": 396,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 37,
                                "start_line": 396
                            },
                            "While expanding the reference 'amountBDesired' in:"
                        ],
                        "start_col": 5,
                        "start_line": 351
                    }
                },
                "2008": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._add_liquidity"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 53,
                        "end_line": 396,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 13,
                        "start_line": 396
                    }
                },
                "2009": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 404,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 404
                    }
                },
                "2011": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 401,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 33,
                                "end_line": 13,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 42,
                                        "end_line": 405,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 27,
                                        "start_line": 405
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 15,
                                "start_line": 13
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 12,
                        "start_line": 401
                    }
                },
                "2012": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 58,
                        "end_line": 401,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 61,
                                "end_line": 13,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 42,
                                        "end_line": 405,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 27,
                                        "start_line": 405
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 35,
                                "start_line": 13
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 32,
                        "start_line": 401
                    }
                },
                "2013": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 75,
                        "end_line": 401,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 78,
                                "end_line": 13,
                                "input_file": {
                                    "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 42,
                                        "end_line": 405,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 27,
                                        "start_line": 405
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 63,
                                "start_line": 13
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 60,
                        "start_line": 401
                    }
                },
                "2014": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 42,
                        "end_line": 405,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 27,
                        "start_line": 405
                    }
                },
                "2016": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 405,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 405
                    }
                },
                "2017": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 406,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 26,
                        "start_line": 406
                    }
                },
                "2019": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 406,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 406
                    }
                },
                "2020": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 406,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 406
                    }
                },
                "2022": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 405,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 30,
                                        "end_line": 401,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 407,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 9,
                                                "start_line": 407
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 12,
                                        "start_line": 401
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 27,
                                "start_line": 405
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 13
                    }
                },
                "2023": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 405,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 58,
                                        "end_line": 401,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 407,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 9,
                                                "start_line": 407
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 32,
                                        "start_line": 401
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 27,
                                "start_line": 405
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 35,
                        "start_line": 13
                    }
                },
                "2024": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 78,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 405,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 75,
                                        "end_line": 401,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 19,
                                                "end_line": 407,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 9,
                                                "start_line": 407
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 60,
                                        "start_line": 401
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 27,
                                "start_line": 405
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 63,
                        "start_line": 13
                    }
                },
                "2025": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 19,
                        "end_line": 407,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 407
                    }
                },
                "2026": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 405,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 37,
                                        "end_line": 440,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 61,
                                                "end_line": 409,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 29,
                                                "start_line": 409
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 19,
                                        "start_line": 440
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 27,
                                "start_line": 405
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 15,
                        "start_line": 13
                    }
                },
                "2027": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 405,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 65,
                                        "end_line": 440,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 61,
                                                "end_line": 409,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 29,
                                                "start_line": 409
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 39,
                                        "start_line": 440
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 27,
                                "start_line": 405
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 35,
                        "start_line": 13
                    }
                },
                "2028": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 78,
                        "end_line": 13,
                        "input_file": {
                            "filename": "autogen/starknet/storage_var/_factory/decl.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 405,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 82,
                                        "end_line": 440,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 61,
                                                "end_line": 409,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 29,
                                                "start_line": 409
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 67,
                                        "start_line": 440
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 27,
                                "start_line": 405
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 63,
                        "start_line": 13
                    }
                },
                "2029": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 48,
                        "end_line": 409,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 42,
                        "start_line": 409
                    }
                },
                "2030": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 60,
                        "end_line": 409,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 50,
                        "start_line": 409
                    }
                },
                "2031": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 409,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 29,
                        "start_line": 409
                    }
                },
                "2033": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 409,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 409
                    }
                },
                "2034": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 15,
                        "end_line": 412,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 412
                    }
                },
                "2035": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 25,
                        "end_line": 412,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 412
                    }
                },
                "2036": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 412,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 412
                    }
                },
                "2038": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 43,
                        "end_line": 413,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 413
                    }
                },
                "2040": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 43,
                        "end_line": 413,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 413
                    }
                },
                "2042": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 414,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 414
                    }
                },
                "2043": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 414,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 414
                    }
                },
                "2044": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 412,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 412
                    }
                },
                "2046": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 416,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 416
                    }
                },
                "2047": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 416,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 416
                    }
                },
                "2048": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 43,
                        "end_line": 417,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 417
                    }
                },
                "2050": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 43,
                        "end_line": 417,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 417
                    }
                },
                "2052": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 440,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 61,
                                "end_line": 409,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 27,
                                        "end_line": 42,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 81,
                                                "end_line": 420,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 44,
                                                "start_line": 420
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 12,
                                        "start_line": 42
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 29,
                                "start_line": 409
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 67,
                        "start_line": 440
                    }
                },
                "2053": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 402,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 63,
                                "end_line": 420,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 50,
                                "start_line": 420
                            },
                            "While expanding the reference 'current_index' in:"
                        ],
                        "start_col": 5,
                        "start_line": 402
                    }
                },
                "2054": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 80,
                        "end_line": 420,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 65,
                        "start_line": 420
                    }
                },
                "2056": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 81,
                        "end_line": 420,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 44,
                        "start_line": 420
                    }
                },
                "2058": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 46,
                        "end_line": 421,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 421
                    }
                },
                "2060": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 421,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 421
                    }
                },
                "2062": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 440,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 61,
                                "end_line": 409,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 34,
                                        "end_line": 460,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 70,
                                                "end_line": 422,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 28,
                                                "start_line": 422
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 16,
                                        "start_line": 460
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 29,
                                "start_line": 409
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 19,
                        "start_line": 440
                    }
                },
                "2063": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 65,
                        "end_line": 440,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 61,
                                "end_line": 409,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 62,
                                        "end_line": 460,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 70,
                                                "end_line": 422,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 28,
                                                "start_line": 422
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 36,
                                        "start_line": 460
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 29,
                                "start_line": 409
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 39,
                        "start_line": 440
                    }
                },
                "2064": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 42,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 81,
                                "end_line": 420,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 79,
                                        "end_line": 460,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 70,
                                                "end_line": 422,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 28,
                                                "start_line": 422
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 64,
                                        "start_line": 460
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 44,
                                "start_line": 420
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 12,
                        "start_line": 42
                    }
                },
                "2065": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 405,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 45,
                                "end_line": 422,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 38,
                                "start_line": 422
                            },
                            "While expanding the reference 'factory' in:"
                        ],
                        "start_col": 16,
                        "start_line": 405
                    }
                },
                "2066": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 57,
                        "end_line": 422,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 47,
                        "start_line": 422
                    }
                },
                "2067": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 69,
                        "end_line": 422,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 59,
                        "start_line": 422
                    }
                },
                "2068": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 70,
                        "end_line": 422,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 28,
                        "start_line": 422
                    }
                },
                "2070": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 422,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 14,
                        "start_line": 422
                    }
                },
                "2071": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 26,
                        "end_line": 423,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 423
                    }
                },
                "2072": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 460,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 70,
                                "end_line": 422,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 42,
                                        "end_line": 424,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 31,
                                        "start_line": 424
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 28,
                                "start_line": 422
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 16,
                        "start_line": 460
                    }
                },
                "2073": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 62,
                        "end_line": 460,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 70,
                                "end_line": 422,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 44,
                                        "end_line": 425,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 32,
                                        "start_line": 425
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 28,
                                "start_line": 422
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 36,
                        "start_line": 460
                    }
                },
                "2074": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 79,
                        "end_line": 460,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 70,
                                "end_line": 422,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 50,
                                        "end_line": 426,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 35,
                                        "start_line": 426
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 28,
                                "start_line": 422
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 64,
                        "start_line": 460
                    }
                },
                "2075": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 421,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 421
                    }
                },
                "2077": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 25,
                        "end_line": 428,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 428
                    }
                },
                "2078": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 440,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 61,
                                "end_line": 409,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 42,
                                        "end_line": 429,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 31,
                                        "start_line": 429
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 29,
                                "start_line": 409
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 19,
                        "start_line": 440
                    }
                },
                "2079": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 65,
                        "end_line": 440,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 61,
                                "end_line": 409,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 44,
                                        "end_line": 430,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 32,
                                        "start_line": 430
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 29,
                                "start_line": 409
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 39,
                        "start_line": 440
                    }
                },
                "2080": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 42,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 81,
                                "end_line": 420,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 50,
                                        "end_line": 431,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 35,
                                        "start_line": 431
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 44,
                                "start_line": 420
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 12,
                        "start_line": 42
                    }
                },
                "2081": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 405,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 41,
                                "end_line": 433,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 34,
                                "start_line": 433
                            },
                            "While expanding the reference 'factory' in:"
                        ],
                        "start_col": 16,
                        "start_line": 405
                    }
                },
                "2082": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 49,
                        "end_line": 433,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 43,
                        "start_line": 433
                    }
                },
                "2083": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 61,
                        "end_line": 433,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 51,
                        "start_line": 433
                    }
                },
                "2084": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 62,
                        "end_line": 433,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 24,
                        "start_line": 433
                    }
                },
                "2086": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 433,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 433
                    }
                },
                "2087": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 460,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 62,
                                "end_line": 433,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 29,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IPair/swap/a698a4a1c6e7fbaf8535ba0f61a54c85006889c49fbeabc3ae990c185771e4da.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 14,
                                                "end_line": 49,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 6,
                                                        "end_line": 436,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 5,
                                                        "start_line": 434
                                                    },
                                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                ],
                                                "start_col": 10,
                                                "start_line": 49
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 11,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 24,
                                "start_line": 433
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 16,
                        "start_line": 460
                    }
                },
                "2088": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 79,
                        "end_line": 460,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 62,
                                "end_line": 433,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 46,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IPair/swap/a698a4a1c6e7fbaf8535ba0f61a54c85006889c49fbeabc3ae990c185771e4da.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 14,
                                                "end_line": 49,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 6,
                                                        "end_line": 436,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 5,
                                                        "start_line": 434
                                                    },
                                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                ],
                                                "start_col": 10,
                                                "start_line": 49
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 31,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 24,
                                "start_line": 433
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 64,
                        "start_line": 460
                    }
                },
                "2089": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 433,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 30,
                                "end_line": 435,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 26,
                                "start_line": 435
                            },
                            "While expanding the reference 'pair' in:"
                        ],
                        "start_col": 16,
                        "start_line": 433
                    }
                },
                "2090": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 410,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 53,
                                "end_line": 435,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 43,
                                "start_line": 435
                            },
                            "While expanding the reference 'amount0Out' in:"
                        ],
                        "start_col": 11,
                        "start_line": 410
                    }
                },
                "2091": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 410,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 53,
                                "end_line": 435,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 43,
                                "start_line": 435
                            },
                            "While expanding the reference 'amount0Out' in:"
                        ],
                        "start_col": 11,
                        "start_line": 410
                    }
                },
                "2092": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 411,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 76,
                                "end_line": 435,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 66,
                                "start_line": 435
                            },
                            "While expanding the reference 'amount1Out' in:"
                        ],
                        "start_col": 11,
                        "start_line": 411
                    }
                },
                "2093": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 411,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 76,
                                "end_line": 435,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 66,
                                "start_line": 435
                            },
                            "While expanding the reference 'amount1Out' in:"
                        ],
                        "start_col": 11,
                        "start_line": 411
                    }
                },
                "2094": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 13,
                        "end_line": 419,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 83,
                                "end_line": 435,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 81,
                                "start_line": 435
                            },
                            "While expanding the reference 'to' in:"
                        ],
                        "start_col": 11,
                        "start_line": 419
                    }
                },
                "2095": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 95,
                        "end_line": 435,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 94,
                        "start_line": 435
                    }
                },
                "2097": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 6,
                        "end_line": 436,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 434
                    }
                },
                "2099": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 29,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/swap/a698a4a1c6e7fbaf8535ba0f61a54c85006889c49fbeabc3ae990c185771e4da.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 49,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 6,
                                        "end_line": 436,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 30,
                                                "end_line": 401,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 88,
                                                        "end_line": 437,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 12,
                                                        "start_line": 437
                                                    },
                                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                ],
                                                "start_col": 12,
                                                "start_line": 401
                                            },
                                            "While expanding the reference 'syscall_ptr' in:"
                                        ],
                                        "start_col": 5,
                                        "start_line": 434
                                    },
                                    "While trying to update the implicit return value 'syscall_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 49
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 11,
                        "start_line": 1
                    }
                },
                "2100": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 62,
                        "end_line": 460,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 62,
                                "end_line": 433,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 58,
                                        "end_line": 401,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 88,
                                                "end_line": 437,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 12,
                                                "start_line": 437
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 32,
                                        "start_line": 401
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 24,
                                "start_line": 433
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 36,
                        "start_line": 460
                    }
                },
                "2101": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 46,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/swap/a698a4a1c6e7fbaf8535ba0f61a54c85006889c49fbeabc3ae990c185771e4da.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 14,
                                "end_line": 49,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 6,
                                        "end_line": 436,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 75,
                                                "end_line": 401,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 88,
                                                        "end_line": 437,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 12,
                                                        "start_line": 437
                                                    },
                                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                ],
                                                "start_col": 60,
                                                "start_line": 401
                                            },
                                            "While expanding the reference 'range_check_ptr' in:"
                                        ],
                                        "start_col": 5,
                                        "start_line": 434
                                    },
                                    "While trying to update the implicit return value 'range_check_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 49
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 31,
                        "start_line": 1
                    }
                },
                "2102": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 35,
                        "end_line": 437,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 18,
                        "start_line": 437
                    }
                },
                "2104": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 43,
                        "end_line": 402,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 48,
                                "end_line": 437,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 37,
                                "start_line": 437
                            },
                            "While expanding the reference 'amounts_len' in:"
                        ],
                        "start_col": 26,
                        "start_line": 402
                    }
                },
                "2105": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 437,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 50,
                        "start_line": 437
                    }
                },
                "2107": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 437,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 74,
                        "start_line": 437
                    }
                },
                "2109": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 86,
                        "end_line": 402,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 87,
                                "end_line": 437,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 84,
                                "start_line": 437
                            },
                            "While expanding the reference '_to' in:"
                        ],
                        "start_col": 77,
                        "start_line": 402
                    }
                },
                "2110": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 88,
                        "end_line": 437,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 12,
                        "start_line": 437
                    }
                },
                "2112": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._swap"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 89,
                        "end_line": 437,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 437
                    }
                },
                "2113": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 443,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 443
                    }
                },
                "2115": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 17,
                        "end_line": 441,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 28,
                                "end_line": 446,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 22,
                                "start_line": 446
                            },
                            "While expanding the reference 'tokenA' in:"
                        ],
                        "start_col": 5,
                        "start_line": 441
                    }
                },
                "2116": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 31,
                        "end_line": 441,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 446,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 30,
                                "start_line": 446
                            },
                            "While expanding the reference 'tokenB' in:"
                        ],
                        "start_col": 19,
                        "start_line": 441
                    }
                },
                "2117": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 446,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 446
                    }
                },
                "2119": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 440,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 32,
                                "end_line": 79,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 64,
                                        "end_line": 447,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 38,
                                        "start_line": 447
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 17,
                                "start_line": 79
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 67,
                        "start_line": 440
                    }
                },
                "2120": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 17,
                        "end_line": 441,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 55,
                                "end_line": 447,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 49,
                                "start_line": 447
                            },
                            "While expanding the reference 'tokenA' in:"
                        ],
                        "start_col": 5,
                        "start_line": 441
                    }
                },
                "2121": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 31,
                        "end_line": 441,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 63,
                                "end_line": 447,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 57,
                                "start_line": 447
                            },
                            "While expanding the reference 'tokenB' in:"
                        ],
                        "start_col": 19,
                        "start_line": 441
                    }
                },
                "2122": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 64,
                        "end_line": 447,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 38,
                        "start_line": 447
                    }
                },
                "2124": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 448,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 448
                    }
                },
                "2126": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 448,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 448
                    }
                },
                "2128": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 449,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 449
                    }
                },
                "2129": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 450,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 450
                    }
                },
                "2130": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 448,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 448
                    }
                },
                "2132": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 452,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 452
                    }
                },
                "2133": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 453,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 453
                    }
                },
                "2134": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 17,
                        "end_line": 444,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 27,
                                "end_line": 456,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 21,
                                "start_line": 456
                            },
                            "While expanding the reference 'token0' in:"
                        ],
                        "start_col": 11,
                        "start_line": 444
                    }
                },
                "2135": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 456,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 456
                    }
                },
                "2137": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 440,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 37,
                                "end_line": 440,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 29,
                                        "end_line": 457,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 457
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 19,
                                "start_line": 440
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 19,
                        "start_line": 440
                    }
                },
                "2138": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 65,
                        "end_line": 440,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 65,
                                "end_line": 440,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 29,
                                        "end_line": 457,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 5,
                                        "start_line": 457
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 39,
                                "start_line": 440
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 39,
                        "start_line": 440
                    }
                },
                "2139": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 79,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math_cmp.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 64,
                                "end_line": 447,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 82,
                                        "end_line": 440,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 29,
                                                "end_line": 457,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 457
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 67,
                                        "start_line": 440
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 38,
                                "start_line": 447
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 79
                    }
                },
                "2140": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 17,
                        "end_line": 444,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 19,
                                "end_line": 457,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 13,
                                "start_line": 457
                            },
                            "While expanding the reference 'token0' in:"
                        ],
                        "start_col": 11,
                        "start_line": 444
                    }
                },
                "2141": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 17,
                        "end_line": 445,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 27,
                                "end_line": 457,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 21,
                                "start_line": 457
                            },
                            "While expanding the reference 'token1' in:"
                        ],
                        "start_col": 11,
                        "start_line": 445
                    }
                },
                "2142": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._sort_tokens"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 29,
                        "end_line": 457,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 457
                    }
                },
                "2143": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._pair_for"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 463,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 463
                    }
                },
                "2145": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._pair_for"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 460,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 37,
                                "end_line": 440,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 68,
                                        "end_line": 464,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 40,
                                        "start_line": 464
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 19,
                                "start_line": 440
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 16,
                        "start_line": 460
                    }
                },
                "2146": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._pair_for"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 62,
                        "end_line": 460,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 65,
                                "end_line": 440,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 68,
                                        "end_line": 464,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 40,
                                        "start_line": 464
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 39,
                                "start_line": 440
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 36,
                        "start_line": 460
                    }
                },
                "2147": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._pair_for"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 79,
                        "end_line": 460,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 82,
                                "end_line": 440,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 68,
                                        "end_line": 464,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 40,
                                        "start_line": 464
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 67,
                                "start_line": 440
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 64,
                        "start_line": 460
                    }
                },
                "2148": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._pair_for"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 461,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 59,
                                "end_line": 464,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 53,
                                "start_line": 464
                            },
                            "While expanding the reference 'tokenA' in:"
                        ],
                        "start_col": 20,
                        "start_line": 461
                    }
                },
                "2149": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._pair_for"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 46,
                        "end_line": 461,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 67,
                                "end_line": 464,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 61,
                                "start_line": 464
                            },
                            "While expanding the reference 'tokenB' in:"
                        ],
                        "start_col": 34,
                        "start_line": 461
                    }
                },
                "2150": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._pair_for"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 68,
                        "end_line": 464,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 40,
                        "start_line": 464
                    }
                },
                "2152": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._pair_for"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 464,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 464
                    }
                },
                "2153": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._pair_for"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 36,
                        "end_line": 464,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 24,
                        "start_line": 464
                    }
                },
                "2154": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._pair_for"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 440,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 68,
                                "end_line": 464,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 33,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IFactory/get_pair/f3ae4d3d9703c6ba4fff0fd10db0740f6555616f3de5dffdec4e7ebb2533c4cb.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 18,
                                                "end_line": 55,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 97,
                                                        "end_line": 465,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 24,
                                                        "start_line": 465
                                                    },
                                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                ],
                                                "start_col": 10,
                                                "start_line": 55
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 15,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 40,
                                "start_line": 464
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 19,
                        "start_line": 440
                    }
                },
                "2155": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._pair_for"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 440,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 68,
                                "end_line": 464,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 50,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IFactory/get_pair/f3ae4d3d9703c6ba4fff0fd10db0740f6555616f3de5dffdec4e7ebb2533c4cb.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 18,
                                                "end_line": 55,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 97,
                                                        "end_line": 465,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 24,
                                                        "start_line": 465
                                                    },
                                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                ],
                                                "start_col": 10,
                                                "start_line": 55
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 35,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 40,
                                "start_line": 464
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 67,
                        "start_line": 440
                    }
                },
                "2156": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._pair_for"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 461,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 66,
                                "end_line": 465,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 59,
                                "start_line": 465
                            },
                            "While expanding the reference 'factory' in:"
                        ],
                        "start_col": 5,
                        "start_line": 461
                    }
                },
                "2157": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._pair_for"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 464,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 81,
                                "end_line": 465,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 75,
                                "start_line": 465
                            },
                            "While expanding the reference 'token0' in:"
                        ],
                        "start_col": 16,
                        "start_line": 464
                    }
                },
                "2158": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._pair_for"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 36,
                        "end_line": 464,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 96,
                                "end_line": 465,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 90,
                                "start_line": 465
                            },
                            "While expanding the reference 'token1' in:"
                        ],
                        "start_col": 30,
                        "start_line": 464
                    }
                },
                "2159": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._pair_for"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 97,
                        "end_line": 465,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 24,
                        "start_line": 465
                    }
                },
                "2161": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._pair_for"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 465,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 465
                    }
                },
                "2162": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._pair_for"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IFactory/get_pair/f3ae4d3d9703c6ba4fff0fd10db0740f6555616f3de5dffdec4e7ebb2533c4cb.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 55,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 97,
                                        "end_line": 465,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 34,
                                                "end_line": 460,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 20,
                                                        "end_line": 466,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 5,
                                                        "start_line": 466
                                                    },
                                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                ],
                                                "start_col": 16,
                                                "start_line": 460
                                            },
                                            "While expanding the reference 'syscall_ptr' in:"
                                        ],
                                        "start_col": 24,
                                        "start_line": 465
                                    },
                                    "While trying to update the implicit return value 'syscall_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 55
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 15,
                        "start_line": 1
                    }
                },
                "2163": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._pair_for"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 65,
                        "end_line": 440,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 68,
                                "end_line": 464,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 62,
                                        "end_line": 460,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 20,
                                                "end_line": 466,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 466
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 36,
                                        "start_line": 460
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 40,
                                "start_line": 464
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 39,
                        "start_line": 440
                    }
                },
                "2164": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._pair_for"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 50,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IFactory/get_pair/f3ae4d3d9703c6ba4fff0fd10db0740f6555616f3de5dffdec4e7ebb2533c4cb.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 55,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 97,
                                        "end_line": 465,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 79,
                                                "end_line": 460,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 20,
                                                        "end_line": 466,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 5,
                                                        "start_line": 466
                                                    },
                                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                ],
                                                "start_col": 64,
                                                "start_line": 460
                                            },
                                            "While expanding the reference 'range_check_ptr' in:"
                                        ],
                                        "start_col": 24,
                                        "start_line": 465
                                    },
                                    "While trying to update the implicit return value 'range_check_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 55
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 35,
                        "start_line": 1
                    }
                },
                "2165": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._pair_for"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 465,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 466,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 13,
                                "start_line": 466
                            },
                            "While expanding the reference 'pair' in:"
                        ],
                        "start_col": 16,
                        "start_line": 465
                    }
                },
                "2166": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._pair_for"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 466,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 466
                    }
                },
                "2167": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 472,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 472
                    }
                },
                "2169": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 469,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 37,
                                "end_line": 440,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 57,
                                        "end_line": 473,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 29,
                                        "start_line": 473
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 19,
                                "start_line": 440
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 20,
                        "start_line": 469
                    }
                },
                "2170": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 66,
                        "end_line": 469,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 65,
                                "end_line": 440,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 57,
                                        "end_line": 473,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 29,
                                        "start_line": 473
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 39,
                                "start_line": 440
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 40,
                        "start_line": 469
                    }
                },
                "2171": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 83,
                        "end_line": 469,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 82,
                                "end_line": 440,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 57,
                                        "end_line": 473,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 29,
                                        "start_line": 473
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 67,
                                "start_line": 440
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 68,
                        "start_line": 469
                    }
                },
                "2172": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 470,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 48,
                                "end_line": 473,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 42,
                                "start_line": 473
                            },
                            "While expanding the reference 'tokenA' in:"
                        ],
                        "start_col": 20,
                        "start_line": 470
                    }
                },
                "2173": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 46,
                        "end_line": 470,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 56,
                                "end_line": 473,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 50,
                                "start_line": 473
                            },
                            "While expanding the reference 'tokenB' in:"
                        ],
                        "start_col": 34,
                        "start_line": 470
                    }
                },
                "2174": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 57,
                        "end_line": 473,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 29,
                        "start_line": 473
                    }
                },
                "2176": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 473,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 473
                    }
                },
                "2177": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 440,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 57,
                                "end_line": 473,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 34,
                                        "end_line": 460,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 58,
                                                "end_line": 474,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 24,
                                                "start_line": 474
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 16,
                                        "start_line": 460
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 29,
                                "start_line": 473
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 19,
                        "start_line": 440
                    }
                },
                "2178": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 65,
                        "end_line": 440,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 57,
                                "end_line": 473,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 62,
                                        "end_line": 460,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 58,
                                                "end_line": 474,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 24,
                                                "start_line": 474
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 36,
                                        "start_line": 460
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 29,
                                "start_line": 473
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 39,
                        "start_line": 440
                    }
                },
                "2179": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 440,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 57,
                                "end_line": 473,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 79,
                                        "end_line": 460,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 58,
                                                "end_line": 474,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 24,
                                                "start_line": 474
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 64,
                                        "start_line": 460
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 29,
                                "start_line": 473
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 67,
                        "start_line": 440
                    }
                },
                "2180": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 470,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 41,
                                "end_line": 474,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 34,
                                "start_line": 474
                            },
                            "While expanding the reference 'factory' in:"
                        ],
                        "start_col": 5,
                        "start_line": 470
                    }
                },
                "2181": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 470,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 49,
                                "end_line": 474,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 43,
                                "start_line": 474
                            },
                            "While expanding the reference 'tokenA' in:"
                        ],
                        "start_col": 20,
                        "start_line": 470
                    }
                },
                "2182": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 46,
                        "end_line": 470,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 57,
                                "end_line": 474,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 51,
                                "start_line": 474
                            },
                            "While expanding the reference 'tokenB' in:"
                        ],
                        "start_col": 34,
                        "start_line": 470
                    }
                },
                "2183": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 58,
                        "end_line": 474,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 24,
                        "start_line": 474
                    }
                },
                "2185": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 474,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 474
                    }
                },
                "2186": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 460,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 58,
                                "end_line": 474,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 37,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IPair/get_reserves/2e31409c9dc0642dd5cf35f3ef58c7f3f9235f4f67d2369960bb668d510e019a.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 40,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 6,
                                                        "end_line": 477,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 65,
                                                        "start_line": 475
                                                    },
                                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                ],
                                                "start_col": 10,
                                                "start_line": 40
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 19,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 24,
                                "start_line": 474
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 16,
                        "start_line": 460
                    }
                },
                "2187": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 79,
                        "end_line": 460,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 58,
                                "end_line": 474,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 54,
                                        "end_line": 1,
                                        "input_file": {
                                            "filename": "autogen/starknet/contract_interface/IPair/get_reserves/2e31409c9dc0642dd5cf35f3ef58c7f3f9235f4f67d2369960bb668d510e019a.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 22,
                                                "end_line": 40,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 6,
                                                        "end_line": 477,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 65,
                                                        "start_line": 475
                                                    },
                                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                ],
                                                "start_col": 10,
                                                "start_line": 40
                                            },
                                            "While handling contract interface function:"
                                        ],
                                        "start_col": 39,
                                        "start_line": 1
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 24,
                                "start_line": 474
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 64,
                        "start_line": 460
                    }
                },
                "2188": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 474,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 30,
                                "end_line": 476,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 26,
                                "start_line": 476
                            },
                            "While expanding the reference 'pair' in:"
                        ],
                        "start_col": 16,
                        "start_line": 474
                    }
                },
                "2189": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 6,
                        "end_line": 477,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 65,
                        "start_line": 475
                    }
                },
                "2191": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 475,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 475
                    }
                },
                "2192": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 475,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 475
                    }
                },
                "2193": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 58,
                        "end_line": 475,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 35,
                        "start_line": 475
                    }
                },
                "2194": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 58,
                        "end_line": 475,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 35,
                        "start_line": 475
                    }
                },
                "2195": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 25,
                        "end_line": 478,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 478
                    }
                },
                "2196": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 478,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 478
                    }
                },
                "2198": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/get_reserves/2e31409c9dc0642dd5cf35f3ef58c7f3f9235f4f67d2369960bb668d510e019a.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 40,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 6,
                                        "end_line": 477,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 38,
                                                "end_line": 469,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 37,
                                                        "end_line": 479,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 9,
                                                        "start_line": 479
                                                    },
                                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                ],
                                                "start_col": 20,
                                                "start_line": 469
                                            },
                                            "While expanding the reference 'syscall_ptr' in:"
                                        ],
                                        "start_col": 65,
                                        "start_line": 475
                                    },
                                    "While trying to update the implicit return value 'syscall_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 40
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 19,
                        "start_line": 1
                    }
                },
                "2199": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 62,
                        "end_line": 460,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 58,
                                "end_line": 474,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 66,
                                        "end_line": 469,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 37,
                                                "end_line": 479,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 9,
                                                "start_line": 479
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 40,
                                        "start_line": 469
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 24,
                                "start_line": 474
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 36,
                        "start_line": 460
                    }
                },
                "2200": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/get_reserves/2e31409c9dc0642dd5cf35f3ef58c7f3f9235f4f67d2369960bb668d510e019a.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 40,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 6,
                                        "end_line": 477,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 83,
                                                "end_line": 469,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 37,
                                                        "end_line": 479,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 9,
                                                        "start_line": 479
                                                    },
                                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                ],
                                                "start_col": 68,
                                                "start_line": 469
                                            },
                                            "While expanding the reference 'range_check_ptr' in:"
                                        ],
                                        "start_col": 65,
                                        "start_line": 475
                                    },
                                    "While trying to update the implicit return value 'range_check_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 40
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 39,
                        "start_line": 1
                    }
                },
                "2201": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 475,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 25,
                                "end_line": 479,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 17,
                                "start_line": 479
                            },
                            "While expanding the reference 'reserve0' in:"
                        ],
                        "start_col": 16,
                        "start_line": 475
                    }
                },
                "2202": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 475,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 25,
                                "end_line": 479,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 17,
                                "start_line": 479
                            },
                            "While expanding the reference 'reserve0' in:"
                        ],
                        "start_col": 16,
                        "start_line": 475
                    }
                },
                "2203": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 49,
                        "end_line": 475,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 35,
                                "end_line": 479,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 27,
                                "start_line": 479
                            },
                            "While expanding the reference 'reserve1' in:"
                        ],
                        "start_col": 41,
                        "start_line": 475
                    }
                },
                "2204": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 49,
                        "end_line": 475,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 35,
                                "end_line": 479,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 27,
                                "start_line": 479
                            },
                            "While expanding the reference 'reserve1' in:"
                        ],
                        "start_col": 41,
                        "start_line": 475
                    }
                },
                "2205": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 479,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 479
                    }
                },
                "2206": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/get_reserves/2e31409c9dc0642dd5cf35f3ef58c7f3f9235f4f67d2369960bb668d510e019a.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 40,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 6,
                                        "end_line": 477,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 38,
                                                "end_line": 469,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 37,
                                                        "end_line": 481,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 9,
                                                        "start_line": 481
                                                    },
                                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                ],
                                                "start_col": 20,
                                                "start_line": 469
                                            },
                                            "While expanding the reference 'syscall_ptr' in:"
                                        ],
                                        "start_col": 65,
                                        "start_line": 475
                                    },
                                    "While trying to update the implicit return value 'syscall_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 40
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 19,
                        "start_line": 1
                    }
                },
                "2207": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 62,
                        "end_line": 460,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 58,
                                "end_line": 474,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 66,
                                        "end_line": 469,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 37,
                                                "end_line": 481,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 9,
                                                "start_line": 481
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 40,
                                        "start_line": 469
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 24,
                                "start_line": 474
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 36,
                        "start_line": 460
                    }
                },
                "2208": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 1,
                        "input_file": {
                            "filename": "autogen/starknet/contract_interface/IPair/get_reserves/2e31409c9dc0642dd5cf35f3ef58c7f3f9235f4f67d2369960bb668d510e019a.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 40,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 6,
                                        "end_line": 477,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 83,
                                                "end_line": 469,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 37,
                                                        "end_line": 481,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "start_col": 9,
                                                        "start_line": 481
                                                    },
                                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                                ],
                                                "start_col": 68,
                                                "start_line": 469
                                            },
                                            "While expanding the reference 'range_check_ptr' in:"
                                        ],
                                        "start_col": 65,
                                        "start_line": 475
                                    },
                                    "While trying to update the implicit return value 'range_check_ptr' in:"
                                ],
                                "start_col": 10,
                                "start_line": 40
                            },
                            "While handling contract interface function:"
                        ],
                        "start_col": 39,
                        "start_line": 1
                    }
                },
                "2209": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 49,
                        "end_line": 475,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 25,
                                "end_line": 481,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 17,
                                "start_line": 481
                            },
                            "While expanding the reference 'reserve1' in:"
                        ],
                        "start_col": 41,
                        "start_line": 475
                    }
                },
                "2210": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 49,
                        "end_line": 475,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 25,
                                "end_line": 481,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 17,
                                "start_line": 481
                            },
                            "While expanding the reference 'reserve1' in:"
                        ],
                        "start_col": 41,
                        "start_line": 475
                    }
                },
                "2211": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 475,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 35,
                                "end_line": 481,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 27,
                                "start_line": 481
                            },
                            "While expanding the reference 'reserve0' in:"
                        ],
                        "start_col": 16,
                        "start_line": 475
                    }
                },
                "2212": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 475,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 35,
                                "end_line": 481,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 27,
                                "start_line": 481
                            },
                            "While expanding the reference 'reserve0' in:"
                        ],
                        "start_col": 16,
                        "start_line": 475
                    }
                },
                "2213": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_reserves"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 481,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 481
                    }
                },
                "2214": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 492,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 492
                    }
                },
                "2216": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 76,
                        "end_line": 489,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 32,
                                "end_line": 133,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 76,
                                        "end_line": 493,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 42,
                                        "start_line": 493
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 17,
                                "start_line": 133
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 61,
                        "start_line": 489
                    }
                },
                "2217": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 62,
                        "end_line": 493,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 61,
                        "start_line": 493
                    }
                },
                "2219": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 65,
                        "end_line": 493,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 64,
                        "start_line": 493
                    }
                },
                "2221": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 490,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 75,
                                "end_line": 493,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 68,
                                "start_line": 493
                            },
                            "While expanding the reference 'amountA' in:"
                        ],
                        "start_col": 5,
                        "start_line": 490
                    }
                },
                "2222": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 490,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 75,
                                "end_line": 493,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 68,
                                "start_line": 493
                            },
                            "While expanding the reference 'amountA' in:"
                        ],
                        "start_col": 5,
                        "start_line": 490
                    }
                },
                "2223": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 76,
                        "end_line": 493,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 42,
                        "start_line": 493
                    }
                },
                "2225": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 49,
                        "end_line": 495,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 495
                    }
                },
                "2227": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 133,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 76,
                                "end_line": 493,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 32,
                                        "end_line": 133,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 78,
                                                "end_line": 497,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 43,
                                                "start_line": 497
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 17,
                                        "start_line": 133
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 42,
                                "start_line": 493
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 133
                    }
                },
                "2228": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 63,
                        "end_line": 497,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 62,
                        "start_line": 497
                    }
                },
                "2230": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 66,
                        "end_line": 497,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 65,
                        "start_line": 497
                    }
                },
                "2232": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 490,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 77,
                                "end_line": 497,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 69,
                                "start_line": 497
                            },
                            "While expanding the reference 'reserveA' in:"
                        ],
                        "start_col": 23,
                        "start_line": 490
                    }
                },
                "2233": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 490,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 77,
                                "end_line": 497,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 69,
                                "start_line": 497
                            },
                            "While expanding the reference 'reserveA' in:"
                        ],
                        "start_col": 23,
                        "start_line": 490
                    }
                },
                "2234": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 78,
                        "end_line": 497,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 43,
                        "start_line": 497
                    }
                },
                "2236": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 133,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 78,
                                "end_line": 497,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 32,
                                        "end_line": 133,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 78,
                                                "end_line": 498,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 43,
                                                "start_line": 498
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 17,
                                        "start_line": 133
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 43,
                                "start_line": 497
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 133
                    }
                },
                "2237": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 63,
                        "end_line": 498,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 62,
                        "start_line": 498
                    }
                },
                "2239": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 66,
                        "end_line": 498,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 65,
                        "start_line": 498
                    }
                },
                "2241": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 59,
                        "end_line": 490,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 77,
                                "end_line": 498,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 69,
                                "start_line": 498
                            },
                            "While expanding the reference 'reserveB' in:"
                        ],
                        "start_col": 42,
                        "start_line": 490
                    }
                },
                "2242": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 59,
                        "end_line": 490,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 77,
                                "end_line": 498,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 69,
                                "start_line": 498
                            },
                            "While expanding the reference 'reserveB' in:"
                        ],
                        "start_col": 42,
                        "start_line": 490
                    }
                },
                "2243": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 78,
                        "end_line": 498,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 43,
                        "start_line": 498
                    }
                },
                "2245": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 50,
                        "end_line": 500,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 500
                    }
                },
                "2247": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 50,
                        "end_line": 501,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 501
                    }
                },
                "2249": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 31,
                        "end_line": 489,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 44,
                                "end_line": 59,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 81,
                                        "end_line": 504,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 43,
                                        "start_line": 504
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 26,
                                "start_line": 59
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 13,
                        "start_line": 489
                    }
                },
                "2250": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 59,
                        "end_line": 489,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 72,
                                "end_line": 59,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 81,
                                        "end_line": 504,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 43,
                                        "start_line": 504
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 46,
                                "start_line": 59
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 33,
                        "start_line": 489
                    }
                },
                "2251": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 133,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 78,
                                "end_line": 498,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 89,
                                        "end_line": 59,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 81,
                                                "end_line": 504,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 43,
                                                "start_line": 504
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 74,
                                        "start_line": 59
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 43,
                                "start_line": 498
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 133
                    }
                },
                "2252": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 490,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 70,
                                "end_line": 504,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 63,
                                "start_line": 504
                            },
                            "While expanding the reference 'amountA' in:"
                        ],
                        "start_col": 5,
                        "start_line": 490
                    }
                },
                "2253": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 490,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 70,
                                "end_line": 504,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 63,
                                "start_line": 504
                            },
                            "While expanding the reference 'amountA' in:"
                        ],
                        "start_col": 5,
                        "start_line": 490
                    }
                },
                "2254": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 59,
                        "end_line": 490,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 80,
                                "end_line": 504,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 72,
                                "start_line": 504
                            },
                            "While expanding the reference 'reserveB' in:"
                        ],
                        "start_col": 42,
                        "start_line": 490
                    }
                },
                "2255": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 59,
                        "end_line": 490,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 80,
                                "end_line": 504,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 72,
                                "start_line": 504
                            },
                            "While expanding the reference 'reserveB' in:"
                        ],
                        "start_col": 42,
                        "start_line": 490
                    }
                },
                "2256": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 81,
                        "end_line": 504,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 43,
                        "start_line": 504
                    }
                },
                "2258": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 44,
                        "end_line": 59,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 81,
                                "end_line": 504,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 44,
                                        "end_line": 59,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 81,
                                                "end_line": 504,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 43,
                                                "start_line": 504
                                            },
                                            "While trying to update the implicit return value 'syscall_ptr' in:"
                                        ],
                                        "start_col": 26,
                                        "start_line": 59
                                    },
                                    "While auto generating local variable for 'syscall_ptr'."
                                ],
                                "start_col": 43,
                                "start_line": 504
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 26,
                        "start_line": 59
                    }
                },
                "2259": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 59,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 81,
                                "end_line": 504,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 72,
                                        "end_line": 59,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 81,
                                                "end_line": 504,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 43,
                                                "start_line": 504
                                            },
                                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 46,
                                        "start_line": 59
                                    },
                                    "While auto generating local variable for 'pedersen_ptr'."
                                ],
                                "start_col": 43,
                                "start_line": 504
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 46,
                        "start_line": 59
                    }
                },
                "2260": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 490,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 88,
                                "end_line": 505,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 80,
                                "start_line": 505
                            },
                            "While expanding the reference 'reserveA' in:"
                        ],
                        "start_col": 23,
                        "start_line": 490
                    }
                },
                "2261": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 490,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 88,
                                "end_line": 505,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 80,
                                "start_line": 505
                            },
                            "While expanding the reference 'reserveA' in:"
                        ],
                        "start_col": 23,
                        "start_line": 490
                    }
                },
                "2262": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 89,
                        "end_line": 505,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 33,
                        "start_line": 505
                    }
                },
                "2264": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 44,
                        "end_line": 59,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 81,
                                "end_line": 504,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 44,
                                        "end_line": 59,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 81,
                                                "end_line": 504,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 31,
                                                        "end_line": 489,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 23,
                                                                "end_line": 506,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 5,
                                                                "start_line": 506
                                                            },
                                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                        ],
                                                        "start_col": 13,
                                                        "start_line": 489
                                                    },
                                                    "While expanding the reference 'syscall_ptr' in:"
                                                ],
                                                "start_col": 43,
                                                "start_line": 504
                                            },
                                            "While trying to update the implicit return value 'syscall_ptr' in:"
                                        ],
                                        "start_col": 26,
                                        "start_line": 59
                                    },
                                    "While auto generating local variable for 'syscall_ptr'."
                                ],
                                "start_col": 43,
                                "start_line": 504
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 26,
                        "start_line": 59
                    }
                },
                "2265": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 59,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 81,
                                "end_line": 504,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 72,
                                        "end_line": 59,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 81,
                                                "end_line": 504,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 59,
                                                        "end_line": 489,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 23,
                                                                "end_line": 506,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 5,
                                                                "start_line": 506
                                                            },
                                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                                        ],
                                                        "start_col": 33,
                                                        "start_line": 489
                                                    },
                                                    "While expanding the reference 'pedersen_ptr' in:"
                                                ],
                                                "start_col": 43,
                                                "start_line": 504
                                            },
                                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 46,
                                        "start_line": 59
                                    },
                                    "While auto generating local variable for 'pedersen_ptr'."
                                ],
                                "start_col": 43,
                                "start_line": 504
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 46,
                        "start_line": 59
                    }
                },
                "2266": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 46,
                        "end_line": 188,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 89,
                                "end_line": 505,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 76,
                                        "end_line": 489,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 23,
                                                "end_line": 506,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 506
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 61,
                                        "start_line": 489
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 33,
                                "start_line": 505
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 31,
                        "start_line": 188
                    }
                },
                "2267": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 26,
                        "end_line": 505,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 506,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 13,
                                "start_line": 506
                            },
                            "While expanding the reference 'amountB' in:"
                        ],
                        "start_col": 10,
                        "start_line": 505
                    }
                },
                "2268": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 26,
                        "end_line": 505,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 506,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 13,
                                "start_line": 506
                            },
                            "While expanding the reference 'amountB' in:"
                        ],
                        "start_col": 10,
                        "start_line": 505
                    }
                },
                "2269": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._quote"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 506,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 506
                    }
                },
                "2270": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 512,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 512
                    }
                },
                "2272": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 85,
                        "end_line": 509,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 32,
                                "end_line": 133,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 78,
                                        "end_line": 513,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 43,
                                        "start_line": 513
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 17,
                                "start_line": 133
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 70,
                        "start_line": 509
                    }
                },
                "2273": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 63,
                        "end_line": 513,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 62,
                        "start_line": 513
                    }
                },
                "2275": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 66,
                        "end_line": 513,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 65,
                        "start_line": 513
                    }
                },
                "2277": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 510,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 77,
                                "end_line": 513,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 69,
                                "start_line": 513
                            },
                            "While expanding the reference 'amountIn' in:"
                        ],
                        "start_col": 5,
                        "start_line": 510
                    }
                },
                "2278": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 510,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 77,
                                "end_line": 513,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 69,
                                "start_line": 513
                            },
                            "While expanding the reference 'amountIn' in:"
                        ],
                        "start_col": 5,
                        "start_line": 510
                    }
                },
                "2279": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 78,
                        "end_line": 513,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 43,
                        "start_line": 513
                    }
                },
                "2281": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 50,
                        "end_line": 515,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 515
                    }
                },
                "2283": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 133,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 78,
                                "end_line": 513,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 32,
                                        "end_line": 133,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 80,
                                                "end_line": 517,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 44,
                                                "start_line": 517
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 17,
                                        "start_line": 133
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 43,
                                "start_line": 513
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 133
                    }
                },
                "2284": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 64,
                        "end_line": 517,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 63,
                        "start_line": 517
                    }
                },
                "2286": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 517,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 66,
                        "start_line": 517
                    }
                },
                "2288": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 42,
                        "end_line": 510,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 79,
                                "end_line": 517,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 70,
                                "start_line": 517
                            },
                            "While expanding the reference 'reserveIn' in:"
                        ],
                        "start_col": 24,
                        "start_line": 510
                    }
                },
                "2289": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 42,
                        "end_line": 510,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 79,
                                "end_line": 517,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 70,
                                "start_line": 517
                            },
                            "While expanding the reference 'reserveIn' in:"
                        ],
                        "start_col": 24,
                        "start_line": 510
                    }
                },
                "2290": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 80,
                        "end_line": 517,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 44,
                        "start_line": 517
                    }
                },
                "2292": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 133,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 80,
                                "end_line": 517,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 32,
                                        "end_line": 133,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 82,
                                                "end_line": 518,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 45,
                                                "start_line": 518
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 17,
                                        "start_line": 133
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 44,
                                "start_line": 517
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 133
                    }
                },
                "2293": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 65,
                        "end_line": 518,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 64,
                        "start_line": 518
                    }
                },
                "2295": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 68,
                        "end_line": 518,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 67,
                        "start_line": 518
                    }
                },
                "2297": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 63,
                        "end_line": 510,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 81,
                                "end_line": 518,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 71,
                                "start_line": 518
                            },
                            "While expanding the reference 'reserveOut' in:"
                        ],
                        "start_col": 44,
                        "start_line": 510
                    }
                },
                "2298": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 63,
                        "end_line": 510,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 81,
                                "end_line": 518,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 71,
                                "start_line": 518
                            },
                            "While expanding the reference 'reserveOut' in:"
                        ],
                        "start_col": 44,
                        "start_line": 510
                    }
                },
                "2299": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 518,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 45,
                        "start_line": 518
                    }
                },
                "2301": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 51,
                        "end_line": 520,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 520
                    }
                },
                "2303": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 52,
                        "end_line": 521,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 521
                    }
                },
                "2305": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 509,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 49,
                                "end_line": 74,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 79,
                                        "end_line": 524,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 40,
                                        "start_line": 524
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 31,
                                "start_line": 74
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 22,
                        "start_line": 509
                    }
                },
                "2306": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 68,
                        "end_line": 509,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 77,
                                "end_line": 74,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 79,
                                        "end_line": 524,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 40,
                                        "start_line": 524
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 51,
                                "start_line": 74
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 42,
                        "start_line": 509
                    }
                },
                "2307": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 133,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 82,
                                "end_line": 518,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 94,
                                        "end_line": 74,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 79,
                                                "end_line": 524,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 40,
                                                "start_line": 524
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 79,
                                        "start_line": 74
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 45,
                                "start_line": 518
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 133
                    }
                },
                "2308": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 510,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 73,
                                "end_line": 524,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 65,
                                "start_line": 524
                            },
                            "While expanding the reference 'amountIn' in:"
                        ],
                        "start_col": 5,
                        "start_line": 510
                    }
                },
                "2309": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 510,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 73,
                                "end_line": 524,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 65,
                                "start_line": 524
                            },
                            "While expanding the reference 'amountIn' in:"
                        ],
                        "start_col": 5,
                        "start_line": 510
                    }
                },
                "2310": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 78,
                        "end_line": 524,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 75,
                        "start_line": 524
                    }
                },
                "2312": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 79,
                        "end_line": 524,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 40,
                        "start_line": 524
                    }
                },
                "2314": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 36,
                        "end_line": 524,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 524,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 524
                            },
                            "While auto generating local variable for 'amountIn_with_fee'."
                        ],
                        "start_col": 10,
                        "start_line": 524
                    }
                },
                "2315": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 36,
                        "end_line": 524,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 524,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 524
                            },
                            "While auto generating local variable for 'amountIn_with_fee'."
                        ],
                        "start_col": 10,
                        "start_line": 524
                    }
                },
                "2316": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 49,
                        "end_line": 74,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 79,
                                "end_line": 524,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 44,
                                        "end_line": 59,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 82,
                                                "end_line": 525,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 32,
                                                "start_line": 525
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 26,
                                        "start_line": 59
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 40,
                                "start_line": 524
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 31,
                        "start_line": 74
                    }
                },
                "2317": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 77,
                        "end_line": 74,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 79,
                                "end_line": 524,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 72,
                                        "end_line": 59,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 82,
                                                "end_line": 525,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 32,
                                                "start_line": 525
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 46,
                                        "start_line": 59
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 40,
                                "start_line": 524
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 51,
                        "start_line": 74
                    }
                },
                "2318": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 94,
                        "end_line": 74,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 79,
                                "end_line": 524,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 89,
                                        "end_line": 59,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 82,
                                                "end_line": 525,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 32,
                                                "start_line": 525
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 74,
                                        "start_line": 59
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 40,
                                "start_line": 524
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 79,
                        "start_line": 74
                    }
                },
                "2319": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 36,
                        "end_line": 524,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 524,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 69,
                                        "end_line": 525,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 52,
                                        "start_line": 525
                                    },
                                    "While expanding the reference 'amountIn_with_fee' in:"
                                ],
                                "start_col": 10,
                                "start_line": 524
                            },
                            "While auto generating local variable for 'amountIn_with_fee'."
                        ],
                        "start_col": 10,
                        "start_line": 524
                    }
                },
                "2320": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 36,
                        "end_line": 524,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 524,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 69,
                                        "end_line": 525,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 52,
                                        "start_line": 525
                                    },
                                    "While expanding the reference 'amountIn_with_fee' in:"
                                ],
                                "start_col": 10,
                                "start_line": 524
                            },
                            "While auto generating local variable for 'amountIn_with_fee'."
                        ],
                        "start_col": 10,
                        "start_line": 524
                    }
                },
                "2321": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 63,
                        "end_line": 510,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 81,
                                "end_line": 525,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 71,
                                "start_line": 525
                            },
                            "While expanding the reference 'reserveOut' in:"
                        ],
                        "start_col": 44,
                        "start_line": 510
                    }
                },
                "2322": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 63,
                        "end_line": 510,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 81,
                                "end_line": 525,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 71,
                                "start_line": 525
                            },
                            "While expanding the reference 'reserveOut' in:"
                        ],
                        "start_col": 44,
                        "start_line": 510
                    }
                },
                "2323": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 525,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 32,
                        "start_line": 525
                    }
                },
                "2325": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 525,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 28,
                                "end_line": 525,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 525
                            },
                            "While auto generating local variable for 'numerator'."
                        ],
                        "start_col": 10,
                        "start_line": 525
                    }
                },
                "2326": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 525,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 28,
                                "end_line": 525,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 525
                            },
                            "While auto generating local variable for 'numerator'."
                        ],
                        "start_col": 10,
                        "start_line": 525
                    }
                },
                "2327": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 44,
                        "end_line": 59,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 82,
                                "end_line": 525,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 49,
                                        "end_line": 74,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 82,
                                                "end_line": 526,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 41,
                                                "start_line": 526
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 31,
                                        "start_line": 74
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 32,
                                "start_line": 525
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 26,
                        "start_line": 59
                    }
                },
                "2328": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 59,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 82,
                                "end_line": 525,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 77,
                                        "end_line": 74,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 82,
                                                "end_line": 526,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 41,
                                                "start_line": 526
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 51,
                                        "start_line": 74
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 32,
                                "start_line": 525
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 46,
                        "start_line": 59
                    }
                },
                "2329": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 89,
                        "end_line": 59,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 82,
                                "end_line": 525,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 94,
                                        "end_line": 74,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 82,
                                                "end_line": 526,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 41,
                                                "start_line": 526
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 79,
                                        "start_line": 74
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 32,
                                "start_line": 525
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 74,
                        "start_line": 59
                    }
                },
                "2330": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 42,
                        "end_line": 510,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 75,
                                "end_line": 526,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 66,
                                "start_line": 526
                            },
                            "While expanding the reference 'reserveIn' in:"
                        ],
                        "start_col": 24,
                        "start_line": 510
                    }
                },
                "2331": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 42,
                        "end_line": 510,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 75,
                                "end_line": 526,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 66,
                                "start_line": 526
                            },
                            "While expanding the reference 'reserveIn' in:"
                        ],
                        "start_col": 24,
                        "start_line": 510
                    }
                },
                "2332": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 81,
                        "end_line": 526,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 77,
                        "start_line": 526
                    }
                },
                "2334": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 526,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 41,
                        "start_line": 526
                    }
                },
                "2336": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 36,
                        "end_line": 524,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 524,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 97,
                                        "end_line": 527,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 80,
                                        "start_line": 527
                                    },
                                    "While expanding the reference 'amountIn_with_fee' in:"
                                ],
                                "start_col": 10,
                                "start_line": 524
                            },
                            "While auto generating local variable for 'amountIn_with_fee'."
                        ],
                        "start_col": 10,
                        "start_line": 524
                    }
                },
                "2337": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 36,
                        "end_line": 524,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 36,
                                "end_line": 524,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 97,
                                        "end_line": 527,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 80,
                                        "start_line": 527
                                    },
                                    "While expanding the reference 'amountIn_with_fee' in:"
                                ],
                                "start_col": 10,
                                "start_line": 524
                            },
                            "While auto generating local variable for 'amountIn_with_fee'."
                        ],
                        "start_col": 10,
                        "start_line": 524
                    }
                },
                "2338": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 98,
                        "end_line": 527,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 40,
                        "start_line": 527
                    }
                },
                "2340": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 36,
                        "end_line": 527,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 527
                    }
                },
                "2341": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 36,
                        "end_line": 527,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 527
                    }
                },
                "2342": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 44,
                        "end_line": 18,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 98,
                                "end_line": 527,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 44,
                                        "end_line": 18,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 98,
                                                "end_line": 527,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 40,
                                                "start_line": 527
                                            },
                                            "While trying to update the implicit return value 'syscall_ptr' in:"
                                        ],
                                        "start_col": 26,
                                        "start_line": 18
                                    },
                                    "While auto generating local variable for 'syscall_ptr'."
                                ],
                                "start_col": 40,
                                "start_line": 527
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 26,
                        "start_line": 18
                    }
                },
                "2343": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 18,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 98,
                                "end_line": 527,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 72,
                                        "end_line": 18,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 98,
                                                "end_line": 527,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 40,
                                                "start_line": 527
                                            },
                                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 46,
                                        "start_line": 18
                                    },
                                    "While auto generating local variable for 'pedersen_ptr'."
                                ],
                                "start_col": 40,
                                "start_line": 527
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 46,
                        "start_line": 18
                    }
                },
                "2344": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 89,
                        "end_line": 18,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 98,
                                "end_line": 527,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 46,
                                        "end_line": 188,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 83,
                                                "end_line": 529,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 35,
                                                "start_line": 529
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 31,
                                        "start_line": 188
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 40,
                                "start_line": 527
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 74,
                        "start_line": 18
                    }
                },
                "2345": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 525,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 28,
                                "end_line": 525,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 69,
                                        "end_line": 529,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 60,
                                        "start_line": 529
                                    },
                                    "While expanding the reference 'numerator' in:"
                                ],
                                "start_col": 10,
                                "start_line": 525
                            },
                            "While auto generating local variable for 'numerator'."
                        ],
                        "start_col": 10,
                        "start_line": 525
                    }
                },
                "2346": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 525,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 28,
                                "end_line": 525,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 69,
                                        "end_line": 529,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 60,
                                        "start_line": 529
                                    },
                                    "While expanding the reference 'numerator' in:"
                                ],
                                "start_col": 10,
                                "start_line": 525
                            },
                            "While auto generating local variable for 'numerator'."
                        ],
                        "start_col": 10,
                        "start_line": 525
                    }
                },
                "2347": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 527,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 82,
                                "end_line": 529,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 71,
                                "start_line": 529
                            },
                            "While expanding the reference 'denominator' in:"
                        ],
                        "start_col": 16,
                        "start_line": 527
                    }
                },
                "2348": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 527,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 82,
                                "end_line": 529,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 71,
                                "start_line": 529
                            },
                            "While expanding the reference 'denominator' in:"
                        ],
                        "start_col": 16,
                        "start_line": 527
                    }
                },
                "2349": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 83,
                        "end_line": 529,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 35,
                        "start_line": 529
                    }
                },
                "2351": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 44,
                        "end_line": 18,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 98,
                                "end_line": 527,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 44,
                                        "end_line": 18,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 98,
                                                "end_line": 527,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 40,
                                                        "end_line": 509,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 25,
                                                                "end_line": 530,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 5,
                                                                "start_line": 530
                                                            },
                                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                        ],
                                                        "start_col": 22,
                                                        "start_line": 509
                                                    },
                                                    "While expanding the reference 'syscall_ptr' in:"
                                                ],
                                                "start_col": 40,
                                                "start_line": 527
                                            },
                                            "While trying to update the implicit return value 'syscall_ptr' in:"
                                        ],
                                        "start_col": 26,
                                        "start_line": 18
                                    },
                                    "While auto generating local variable for 'syscall_ptr'."
                                ],
                                "start_col": 40,
                                "start_line": 527
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 26,
                        "start_line": 18
                    }
                },
                "2352": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 18,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 98,
                                "end_line": 527,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 72,
                                        "end_line": 18,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 98,
                                                "end_line": 527,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 68,
                                                        "end_line": 509,
                                                        "input_file": {
                                                            "filename": "contracts/Router.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 25,
                                                                "end_line": 530,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 5,
                                                                "start_line": 530
                                                            },
                                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                                        ],
                                                        "start_col": 42,
                                                        "start_line": 509
                                                    },
                                                    "While expanding the reference 'pedersen_ptr' in:"
                                                ],
                                                "start_col": 40,
                                                "start_line": 527
                                            },
                                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 46,
                                        "start_line": 18
                                    },
                                    "While auto generating local variable for 'pedersen_ptr'."
                                ],
                                "start_col": 40,
                                "start_line": 527
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 46,
                        "start_line": 18
                    }
                },
                "2353": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 46,
                        "end_line": 188,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 83,
                                "end_line": 529,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 85,
                                        "end_line": 509,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 25,
                                                "end_line": 530,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 530
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 70,
                                        "start_line": 509
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 35,
                                "start_line": 529
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 31,
                        "start_line": 188
                    }
                },
                "2354": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 529,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 530,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 13,
                                "start_line": 530
                            },
                            "While expanding the reference 'amountOut' in:"
                        ],
                        "start_col": 10,
                        "start_line": 529
                    }
                },
                "2355": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 529,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 22,
                                "end_line": 530,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 13,
                                "start_line": 530
                            },
                            "While expanding the reference 'amountOut' in:"
                        ],
                        "start_col": 10,
                        "start_line": 529
                    }
                },
                "2356": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 25,
                        "end_line": 530,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 530
                    }
                },
                "2357": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 536,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 536
                    }
                },
                "2359": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 84,
                        "end_line": 533,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 32,
                                "end_line": 133,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 80,
                                        "end_line": 537,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 44,
                                        "start_line": 537
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 17,
                                "start_line": 133
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 69,
                        "start_line": 533
                    }
                },
                "2360": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 64,
                        "end_line": 537,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 63,
                        "start_line": 537
                    }
                },
                "2362": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 537,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 66,
                        "start_line": 537
                    }
                },
                "2364": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 534,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 79,
                                "end_line": 537,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 70,
                                "start_line": 537
                            },
                            "While expanding the reference 'amountOut' in:"
                        ],
                        "start_col": 5,
                        "start_line": 534
                    }
                },
                "2365": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 534,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 79,
                                "end_line": 537,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 70,
                                "start_line": 537
                            },
                            "While expanding the reference 'amountOut' in:"
                        ],
                        "start_col": 5,
                        "start_line": 534
                    }
                },
                "2366": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 80,
                        "end_line": 537,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 44,
                        "start_line": 537
                    }
                },
                "2368": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 51,
                        "end_line": 539,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 539
                    }
                },
                "2370": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 133,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 80,
                                "end_line": 537,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 32,
                                        "end_line": 133,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 80,
                                                "end_line": 541,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 44,
                                                "start_line": 541
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 17,
                                        "start_line": 133
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 44,
                                "start_line": 537
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 133
                    }
                },
                "2371": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 64,
                        "end_line": 541,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 63,
                        "start_line": 541
                    }
                },
                "2373": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 541,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 66,
                        "start_line": 541
                    }
                },
                "2375": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 43,
                        "end_line": 534,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 79,
                                "end_line": 541,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 70,
                                "start_line": 541
                            },
                            "While expanding the reference 'reserveIn' in:"
                        ],
                        "start_col": 25,
                        "start_line": 534
                    }
                },
                "2376": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 43,
                        "end_line": 534,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 79,
                                "end_line": 541,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 70,
                                "start_line": 541
                            },
                            "While expanding the reference 'reserveIn' in:"
                        ],
                        "start_col": 25,
                        "start_line": 534
                    }
                },
                "2377": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 80,
                        "end_line": 541,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 44,
                        "start_line": 541
                    }
                },
                "2379": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 133,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 80,
                                "end_line": 541,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 32,
                                        "end_line": 133,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 82,
                                                "end_line": 542,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 45,
                                                "start_line": 542
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 17,
                                        "start_line": 133
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 44,
                                "start_line": 541
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 133
                    }
                },
                "2380": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 65,
                        "end_line": 542,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 64,
                        "start_line": 542
                    }
                },
                "2382": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 68,
                        "end_line": 542,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 67,
                        "start_line": 542
                    }
                },
                "2384": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 64,
                        "end_line": 534,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 81,
                                "end_line": 542,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 71,
                                "start_line": 542
                            },
                            "While expanding the reference 'reserveOut' in:"
                        ],
                        "start_col": 45,
                        "start_line": 534
                    }
                },
                "2385": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 64,
                        "end_line": 534,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 81,
                                "end_line": 542,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 71,
                                "start_line": 542
                            },
                            "While expanding the reference 'reserveOut' in:"
                        ],
                        "start_col": 45,
                        "start_line": 534
                    }
                },
                "2386": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 82,
                        "end_line": 542,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 45,
                        "start_line": 542
                    }
                },
                "2388": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 51,
                        "end_line": 544,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 544
                    }
                },
                "2390": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 52,
                        "end_line": 545,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 545
                    }
                },
                "2392": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 39,
                        "end_line": 533,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 44,
                                "end_line": 59,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 87,
                                        "end_line": 548,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 46,
                                        "start_line": 548
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 26,
                                "start_line": 59
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 21,
                        "start_line": 533
                    }
                },
                "2393": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 533,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 72,
                                "end_line": 59,
                                "input_file": {
                                    "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 87,
                                        "end_line": 548,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 46,
                                        "start_line": 548
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 46,
                                "start_line": 59
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 41,
                        "start_line": 533
                    }
                },
                "2394": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 133,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 82,
                                "end_line": 542,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 89,
                                        "end_line": 59,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 87,
                                                "end_line": 548,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 46,
                                                "start_line": 548
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 74,
                                        "start_line": 59
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 45,
                                "start_line": 542
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 17,
                        "start_line": 133
                    }
                },
                "2395": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 534,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 75,
                                "end_line": 548,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 66,
                                "start_line": 548
                            },
                            "While expanding the reference 'amountOut' in:"
                        ],
                        "start_col": 5,
                        "start_line": 534
                    }
                },
                "2396": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 534,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 75,
                                "end_line": 548,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 66,
                                "start_line": 548
                            },
                            "While expanding the reference 'amountOut' in:"
                        ],
                        "start_col": 5,
                        "start_line": 534
                    }
                },
                "2397": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 43,
                        "end_line": 534,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 86,
                                "end_line": 548,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 77,
                                "start_line": 548
                            },
                            "While expanding the reference 'reserveIn' in:"
                        ],
                        "start_col": 25,
                        "start_line": 534
                    }
                },
                "2398": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 43,
                        "end_line": 534,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 86,
                                "end_line": 548,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 77,
                                "start_line": 548
                            },
                            "While expanding the reference 'reserveIn' in:"
                        ],
                        "start_col": 25,
                        "start_line": 534
                    }
                },
                "2399": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 87,
                        "end_line": 548,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 46,
                        "start_line": 548
                    }
                },
                "2401": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 86,
                        "end_line": 549,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 82,
                        "start_line": 549
                    }
                },
                "2403": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 87,
                        "end_line": 549,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 32,
                        "start_line": 549
                    }
                },
                "2405": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 549,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 28,
                                "end_line": 549,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 549
                            },
                            "While auto generating local variable for 'numerator'."
                        ],
                        "start_col": 10,
                        "start_line": 549
                    }
                },
                "2406": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 549,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 28,
                                "end_line": 549,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 10,
                                "start_line": 549
                            },
                            "While auto generating local variable for 'numerator'."
                        ],
                        "start_col": 10,
                        "start_line": 549
                    }
                },
                "2407": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 49,
                        "end_line": 74,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 87,
                                "end_line": 549,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 47,
                                        "end_line": 44,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 81,
                                                "end_line": 550,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 36,
                                                "start_line": 550
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 29,
                                        "start_line": 44
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 32,
                                "start_line": 549
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 31,
                        "start_line": 74
                    }
                },
                "2408": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 77,
                        "end_line": 74,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 87,
                                "end_line": 549,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 75,
                                        "end_line": 44,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 81,
                                                "end_line": 550,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 36,
                                                "start_line": 550
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 49,
                                        "start_line": 44
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 32,
                                "start_line": 549
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 51,
                        "start_line": 74
                    }
                },
                "2409": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 94,
                        "end_line": 74,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 87,
                                "end_line": 549,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 92,
                                        "end_line": 44,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 81,
                                                "end_line": 550,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 36,
                                                "start_line": 550
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 77,
                                        "start_line": 44
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 32,
                                "start_line": 549
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 79,
                        "start_line": 74
                    }
                },
                "2410": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 64,
                        "end_line": 534,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 69,
                                "end_line": 550,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 59,
                                "start_line": 550
                            },
                            "While expanding the reference 'reserveOut' in:"
                        ],
                        "start_col": 45,
                        "start_line": 534
                    }
                },
                "2411": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 64,
                        "end_line": 534,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 69,
                                "end_line": 550,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 59,
                                "start_line": 550
                            },
                            "While expanding the reference 'reserveOut' in:"
                        ],
                        "start_col": 45,
                        "start_line": 534
                    }
                },
                "2412": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 534,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 80,
                                "end_line": 550,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 71,
                                "start_line": 550
                            },
                            "While expanding the reference 'amountOut' in:"
                        ],
                        "start_col": 5,
                        "start_line": 534
                    }
                },
                "2413": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 534,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 80,
                                "end_line": 550,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 71,
                                "start_line": 550
                            },
                            "While expanding the reference 'amountOut' in:"
                        ],
                        "start_col": 5,
                        "start_line": 534
                    }
                },
                "2414": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 81,
                        "end_line": 550,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 36,
                        "start_line": 550
                    }
                },
                "2416": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 77,
                        "end_line": 551,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 74,
                        "start_line": 551
                    }
                },
                "2418": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 78,
                        "end_line": 551,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 34,
                        "start_line": 551
                    }
                },
                "2420": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 49,
                        "end_line": 74,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 78,
                                "end_line": 551,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 49,
                                        "end_line": 74,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 78,
                                                "end_line": 551,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 34,
                                                "start_line": 551
                                            },
                                            "While trying to update the implicit return value 'syscall_ptr' in:"
                                        ],
                                        "start_col": 31,
                                        "start_line": 74
                                    },
                                    "While auto generating local variable for 'syscall_ptr'."
                                ],
                                "start_col": 34,
                                "start_line": 551
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 31,
                        "start_line": 74
                    }
                },
                "2421": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 77,
                        "end_line": 74,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 78,
                                "end_line": 551,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 77,
                                        "end_line": 74,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 78,
                                                "end_line": 551,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 34,
                                                "start_line": 551
                                            },
                                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 51,
                                        "start_line": 74
                                    },
                                    "While auto generating local variable for 'pedersen_ptr'."
                                ],
                                "start_col": 34,
                                "start_line": 551
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 51,
                        "start_line": 74
                    }
                },
                "2422": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 94,
                        "end_line": 74,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 78,
                                "end_line": 551,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 46,
                                        "end_line": 188,
                                        "input_file": {
                                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 84,
                                                "end_line": 553,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 36,
                                                "start_line": 553
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 31,
                                        "start_line": 188
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 34,
                                "start_line": 551
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 79,
                        "start_line": 74
                    }
                },
                "2423": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 549,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 28,
                                "end_line": 549,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 70,
                                        "end_line": 553,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 61,
                                        "start_line": 553
                                    },
                                    "While expanding the reference 'numerator' in:"
                                ],
                                "start_col": 10,
                                "start_line": 549
                            },
                            "While auto generating local variable for 'numerator'."
                        ],
                        "start_col": 10,
                        "start_line": 549
                    }
                },
                "2424": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 549,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 28,
                                "end_line": 549,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 70,
                                        "end_line": 553,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 61,
                                        "start_line": 553
                                    },
                                    "While expanding the reference 'numerator' in:"
                                ],
                                "start_col": 10,
                                "start_line": 549
                            },
                            "While auto generating local variable for 'numerator'."
                        ],
                        "start_col": 10,
                        "start_line": 549
                    }
                },
                "2425": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 551,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 83,
                                "end_line": 553,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 72,
                                "start_line": 553
                            },
                            "While expanding the reference 'denominator' in:"
                        ],
                        "start_col": 10,
                        "start_line": 551
                    }
                },
                "2426": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 551,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 83,
                                "end_line": 553,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 72,
                                "start_line": 553
                            },
                            "While expanding the reference 'denominator' in:"
                        ],
                        "start_col": 10,
                        "start_line": 551
                    }
                },
                "2427": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 84,
                        "end_line": 553,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 36,
                        "start_line": 553
                    }
                },
                "2429": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 49,
                        "end_line": 74,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 78,
                                "end_line": 551,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 49,
                                        "end_line": 74,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 78,
                                                "end_line": 551,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 44,
                                                        "end_line": 18,
                                                        "input_file": {
                                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 83,
                                                                "end_line": 554,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 37,
                                                                "start_line": 554
                                                            },
                                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                                        ],
                                                        "start_col": 26,
                                                        "start_line": 18
                                                    },
                                                    "While expanding the reference 'syscall_ptr' in:"
                                                ],
                                                "start_col": 34,
                                                "start_line": 551
                                            },
                                            "While trying to update the implicit return value 'syscall_ptr' in:"
                                        ],
                                        "start_col": 31,
                                        "start_line": 74
                                    },
                                    "While auto generating local variable for 'syscall_ptr'."
                                ],
                                "start_col": 34,
                                "start_line": 551
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 31,
                        "start_line": 74
                    }
                },
                "2430": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 77,
                        "end_line": 74,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 78,
                                "end_line": 551,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 77,
                                        "end_line": 74,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 78,
                                                "end_line": 551,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "parent_location": [
                                                    {
                                                        "end_col": 72,
                                                        "end_line": 18,
                                                        "input_file": {
                                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                                        },
                                                        "parent_location": [
                                                            {
                                                                "end_col": 83,
                                                                "end_line": 554,
                                                                "input_file": {
                                                                    "filename": "contracts/Router.cairo"
                                                                },
                                                                "start_col": 37,
                                                                "start_line": 554
                                                            },
                                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                                        ],
                                                        "start_col": 46,
                                                        "start_line": 18
                                                    },
                                                    "While expanding the reference 'pedersen_ptr' in:"
                                                ],
                                                "start_col": 34,
                                                "start_line": 551
                                            },
                                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 51,
                                        "start_line": 74
                                    },
                                    "While auto generating local variable for 'pedersen_ptr'."
                                ],
                                "start_col": 34,
                                "start_line": 551
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 51,
                        "start_line": 74
                    }
                },
                "2431": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 46,
                        "end_line": 188,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/uint256.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 84,
                                "end_line": 553,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 89,
                                        "end_line": 18,
                                        "input_file": {
                                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 83,
                                                "end_line": 554,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 37,
                                                "start_line": 554
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 74,
                                        "start_line": 18
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 36,
                                "start_line": 553
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 31,
                        "start_line": 188
                    }
                },
                "2432": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 29,
                        "end_line": 553,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 67,
                                "end_line": 554,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 57,
                                "start_line": 554
                            },
                            "While expanding the reference 'amountIn_0' in:"
                        ],
                        "start_col": 10,
                        "start_line": 553
                    }
                },
                "2433": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 29,
                        "end_line": 553,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 67,
                                "end_line": 554,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 57,
                                "start_line": 554
                            },
                            "While expanding the reference 'amountIn_0' in:"
                        ],
                        "start_col": 10,
                        "start_line": 553
                    }
                },
                "2434": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 78,
                        "end_line": 554,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 77,
                        "start_line": 554
                    }
                },
                "2436": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 81,
                        "end_line": 554,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 80,
                        "start_line": 554
                    }
                },
                "2438": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 83,
                        "end_line": 554,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 37,
                        "start_line": 554
                    }
                },
                "2440": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 554,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 554
                    }
                },
                "2441": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 33,
                        "end_line": 554,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 554
                    }
                },
                "2442": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 44,
                        "end_line": 18,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 83,
                                "end_line": 554,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 39,
                                        "end_line": 533,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 24,
                                                "end_line": 556,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 556
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 21,
                                        "start_line": 533
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 37,
                                "start_line": 554
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 26,
                        "start_line": 18
                    }
                },
                "2443": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 72,
                        "end_line": 18,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 83,
                                "end_line": 554,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 67,
                                        "end_line": 533,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 24,
                                                "end_line": 556,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 556
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 41,
                                        "start_line": 533
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 37,
                                "start_line": 554
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 46,
                        "start_line": 18
                    }
                },
                "2444": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 89,
                        "end_line": 18,
                        "input_file": {
                            "filename": "/home/andy/Documents/JediSwap/contracts/utils/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 83,
                                "end_line": 554,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 84,
                                        "end_line": 533,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 24,
                                                "end_line": 556,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 556
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 69,
                                        "start_line": 533
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 37,
                                "start_line": 554
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 74,
                        "start_line": 18
                    }
                },
                "2445": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 554,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 556,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 13,
                                "start_line": 556
                            },
                            "While expanding the reference 'amountIn' in:"
                        ],
                        "start_col": 16,
                        "start_line": 554
                    }
                },
                "2446": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 554,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 21,
                                "end_line": 556,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 13,
                                "start_line": 556
                            },
                            "While expanding the reference 'amountIn' in:"
                        ],
                        "start_col": 16,
                        "start_line": 554
                    }
                },
                "2447": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amount_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 24,
                        "end_line": 556,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 556
                    }
                },
                "2448": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 562,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 562
                    }
                },
                "2450": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 86,
                        "end_line": 559,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 31,
                                "end_line": 53,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 31,
                                        "end_line": 564,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 9,
                                        "start_line": 564
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 16,
                                "start_line": 53
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 71,
                        "start_line": 559
                    }
                },
                "2451": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 564,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 19,
                        "start_line": 564
                    }
                },
                "2453": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 53,
                        "end_line": 560,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 30,
                                "end_line": 564,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 22,
                                "start_line": 564
                            },
                            "While expanding the reference 'path_len' in:"
                        ],
                        "start_col": 39,
                        "start_line": 560
                    }
                },
                "2454": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 31,
                        "end_line": 564,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 564
                    }
                },
                "2456": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 50,
                        "end_line": 566,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 43,
                        "start_line": 566
                    }
                },
                "2458": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 39,
                        "end_line": 566,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 566
                    }
                },
                "2459": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 41,
                        "end_line": 559,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 574,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 6,
                                        "end_line": 569,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 35,
                                        "start_line": 567
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 25,
                                "start_line": 574
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 23,
                        "start_line": 559
                    }
                },
                "2460": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 69,
                        "end_line": 559,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 71,
                                "end_line": 574,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 6,
                                        "end_line": 569,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 35,
                                        "start_line": 567
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 45,
                                "start_line": 574
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 43,
                        "start_line": 559
                    }
                },
                "2461": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 31,
                        "end_line": 53,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 31,
                                "end_line": 564,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 88,
                                        "end_line": 574,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 6,
                                                "end_line": 569,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 35,
                                                "start_line": 567
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 73,
                                        "start_line": 574
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 9,
                                "start_line": 564
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 16,
                        "start_line": 53
                    }
                },
                "2462": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 560,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 16,
                                "end_line": 568,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 9,
                                "start_line": 568
                            },
                            "While expanding the reference 'factory' in:"
                        ],
                        "start_col": 5,
                        "start_line": 560
                    }
                },
                "2463": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 560,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 26,
                                "end_line": 568,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 18,
                                "start_line": 568
                            },
                            "While expanding the reference 'amountIn' in:"
                        ],
                        "start_col": 20,
                        "start_line": 560
                    }
                },
                "2464": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 560,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 26,
                                "end_line": 568,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 18,
                                "start_line": 568
                            },
                            "While expanding the reference 'amountIn' in:"
                        ],
                        "start_col": 20,
                        "start_line": 560
                    }
                },
                "2465": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 29,
                        "end_line": 568,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 28,
                        "start_line": 568
                    }
                },
                "2467": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 53,
                        "end_line": 560,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 39,
                                "end_line": 568,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 31,
                                "start_line": 568
                            },
                            "While expanding the reference 'path_len' in:"
                        ],
                        "start_col": 39,
                        "start_line": 560
                    }
                },
                "2468": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 66,
                        "end_line": 560,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 45,
                                "end_line": 568,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 41,
                                "start_line": 568
                            },
                            "While expanding the reference 'path' in:"
                        ],
                        "start_col": 55,
                        "start_line": 560
                    }
                },
                "2469": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 29,
                        "end_line": 566,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 60,
                                "end_line": 568,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 47,
                                "start_line": 568
                            },
                            "While expanding the reference 'amounts_start' in:"
                        ],
                        "start_col": 16,
                        "start_line": 566
                    }
                },
                "2470": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 6,
                        "end_line": 569,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 35,
                        "start_line": 567
                    }
                },
                "2472": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 43,
                        "end_line": 574,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 6,
                                "end_line": 569,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 41,
                                        "end_line": 559,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 29,
                                                "end_line": 571,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 571
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 23,
                                        "start_line": 559
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 35,
                                "start_line": 567
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 25,
                        "start_line": 574
                    }
                },
                "2473": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 71,
                        "end_line": 574,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 6,
                                "end_line": 569,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 69,
                                        "end_line": 559,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 29,
                                                "end_line": 571,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 571
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 43,
                                        "start_line": 559
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 35,
                                "start_line": 567
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 45,
                        "start_line": 574
                    }
                },
                "2474": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 88,
                        "end_line": 574,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 6,
                                "end_line": 569,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 86,
                                        "end_line": 559,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 29,
                                                "end_line": 571,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 571
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 71,
                                        "start_line": 559
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 35,
                                "start_line": 567
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 73,
                        "start_line": 574
                    }
                },
                "2475": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 29,
                        "end_line": 566,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 26,
                                "end_line": 571,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 13,
                                "start_line": 571
                            },
                            "While expanding the reference 'amounts_start' in:"
                        ],
                        "start_col": 16,
                        "start_line": 566
                    }
                },
                "2476": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 29,
                        "end_line": 571,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 571
                    }
                },
                "2477": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 582,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 582
                    }
                },
                "2479": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 34,
                        "end_line": 583,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 583
                    }
                },
                "2480": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 583,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 583
                    }
                },
                "2482": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 43,
                        "end_line": 574,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 43,
                                "end_line": 574,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 27,
                                        "end_line": 584,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 9,
                                        "start_line": 584
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 25,
                                "start_line": 574
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 25,
                        "start_line": 574
                    }
                },
                "2483": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 71,
                        "end_line": 574,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 71,
                                "end_line": 574,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 27,
                                        "end_line": 584,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 9,
                                        "start_line": 584
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 45,
                                "start_line": 574
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 45,
                        "start_line": 574
                    }
                },
                "2484": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 88,
                        "end_line": 574,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 88,
                                "end_line": 574,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 27,
                                        "end_line": 584,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 9,
                                        "start_line": 584
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 73,
                                "start_line": 574
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 73,
                        "start_line": 574
                    }
                },
                "2485": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 580,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 584,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 17,
                                "start_line": 584
                            },
                            "While expanding the reference 'amounts' in:"
                        ],
                        "start_col": 5,
                        "start_line": 580
                    }
                },
                "2486": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 584,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 584
                    }
                },
                "2487": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 587,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 587
                    }
                },
                "2489": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 588,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 588
                    }
                },
                "2490": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 588,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 588
                    }
                },
                "2491": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 43,
                        "end_line": 574,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 589,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 31,
                                "start_line": 589
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 25,
                        "start_line": 574
                    }
                },
                "2492": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 71,
                        "end_line": 574,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 44,
                                "end_line": 590,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 32,
                                "start_line": 590
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 45,
                        "start_line": 574
                    }
                },
                "2493": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 88,
                        "end_line": 574,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 50,
                                "end_line": 591,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 35,
                                "start_line": 591
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 73,
                        "start_line": 574
                    }
                },
                "2494": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 587,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 587
                    }
                },
                "2496": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 43,
                        "end_line": 574,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 38,
                                "end_line": 469,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 10,
                                        "end_line": 595,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 69,
                                        "start_line": 593
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 20,
                                "start_line": 469
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 25,
                        "start_line": 574
                    }
                },
                "2497": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 71,
                        "end_line": 574,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 66,
                                "end_line": 469,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 10,
                                        "end_line": 595,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 69,
                                        "start_line": 593
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 40,
                                "start_line": 469
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 45,
                        "start_line": 574
                    }
                },
                "2498": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 88,
                        "end_line": 574,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 83,
                                "end_line": 469,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 10,
                                        "end_line": 595,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 69,
                                        "start_line": 593
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 68,
                                "start_line": 469
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 73,
                        "start_line": 574
                    }
                },
                "2499": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 575,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 594,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 13,
                                "start_line": 594
                            },
                            "While expanding the reference 'factory' in:"
                        ],
                        "start_col": 5,
                        "start_line": 575
                    }
                },
                "2500": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 32,
                        "end_line": 594,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 22,
                        "start_line": 594
                    }
                },
                "2501": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 594,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 34,
                        "start_line": 594
                    }
                },
                "2502": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 10,
                        "end_line": 595,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 69,
                        "start_line": 593
                    }
                },
                "2504": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 593,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 14,
                        "start_line": 593
                    }
                },
                "2505": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 593,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 14,
                        "start_line": 593
                    }
                },
                "2506": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 65,
                        "end_line": 593,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 40,
                        "start_line": 593
                    }
                },
                "2507": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 65,
                        "end_line": 593,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 40,
                        "start_line": 593
                    }
                },
                "2508": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 469,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 10,
                                "end_line": 595,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 40,
                                        "end_line": 509,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 10,
                                                "end_line": 598,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 42,
                                                "start_line": 596
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 22,
                                        "start_line": 509
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 69,
                                "start_line": 593
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 20,
                        "start_line": 469
                    }
                },
                "2509": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 66,
                        "end_line": 469,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 10,
                                "end_line": 595,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 68,
                                        "end_line": 509,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 10,
                                                "end_line": 598,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 42,
                                                "start_line": 596
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 42,
                                        "start_line": 509
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 69,
                                "start_line": 593
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 40,
                        "start_line": 469
                    }
                },
                "2510": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 83,
                        "end_line": 469,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 10,
                                "end_line": 595,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 85,
                                        "end_line": 509,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 10,
                                                "end_line": 598,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 42,
                                                "start_line": 596
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 70,
                                        "start_line": 509
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 69,
                                "start_line": 593
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 68,
                        "start_line": 469
                    }
                },
                "2511": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 597,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 13,
                        "start_line": 597
                    }
                },
                "2512": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 597,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 13,
                        "start_line": 597
                    }
                },
                "2513": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 29,
                        "end_line": 593,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 48,
                                "end_line": 597,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 39,
                                "start_line": 597
                            },
                            "While expanding the reference 'reserveIn' in:"
                        ],
                        "start_col": 20,
                        "start_line": 593
                    }
                },
                "2514": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 29,
                        "end_line": 593,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 48,
                                "end_line": 597,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 39,
                                "start_line": 597
                            },
                            "While expanding the reference 'reserveIn' in:"
                        ],
                        "start_col": 20,
                        "start_line": 593
                    }
                },
                "2515": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 56,
                        "end_line": 593,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 60,
                                "end_line": 597,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 50,
                                "start_line": 597
                            },
                            "While expanding the reference 'reserveOut' in:"
                        ],
                        "start_col": 46,
                        "start_line": 593
                    }
                },
                "2516": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 56,
                        "end_line": 593,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 60,
                                "end_line": 597,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 50,
                                "start_line": 597
                            },
                            "While expanding the reference 'reserveOut' in:"
                        ],
                        "start_col": 46,
                        "start_line": 593
                    }
                },
                "2517": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 10,
                        "end_line": 598,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 42,
                        "start_line": 596
                    }
                },
                "2519": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 596,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 14,
                        "start_line": 596
                    }
                },
                "2520": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 596,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 14,
                        "start_line": 596
                    }
                },
                "2521": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 599,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 599
                    }
                },
                "2522": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 599,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 599
                    }
                },
                "2523": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 509,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 10,
                                "end_line": 598,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 42,
                                        "end_line": 600,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 31,
                                        "start_line": 600
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 42,
                                "start_line": 596
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 22,
                        "start_line": 509
                    }
                },
                "2524": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 68,
                        "end_line": 509,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 10,
                                "end_line": 598,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 44,
                                        "end_line": 601,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 32,
                                        "start_line": 601
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 42,
                                "start_line": 596
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 42,
                        "start_line": 509
                    }
                },
                "2525": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 85,
                        "end_line": 509,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 10,
                                "end_line": 598,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 50,
                                        "end_line": 602,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 35,
                                        "start_line": 602
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 42,
                                "start_line": 596
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 70,
                        "start_line": 509
                    }
                },
                "2526": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 575,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 16,
                                "end_line": 606,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 9,
                                "start_line": 606
                            },
                            "While expanding the reference 'factory' in:"
                        ],
                        "start_col": 5,
                        "start_line": 575
                    }
                },
                "2527": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 576,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 26,
                                "end_line": 606,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 18,
                                "start_line": 606
                            },
                            "While expanding the reference 'amountIn' in:"
                        ],
                        "start_col": 5,
                        "start_line": 576
                    }
                },
                "2528": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 576,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 26,
                                "end_line": 606,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 18,
                                "start_line": 606
                            },
                            "While expanding the reference 'amountIn' in:"
                        ],
                        "start_col": 5,
                        "start_line": 576
                    }
                },
                "2529": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 45,
                        "end_line": 606,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 28,
                        "start_line": 606
                    }
                },
                "2531": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 19,
                        "end_line": 578,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 55,
                                "end_line": 606,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 47,
                                "start_line": 606
                            },
                            "While expanding the reference 'path_len' in:"
                        ],
                        "start_col": 5,
                        "start_line": 578
                    }
                },
                "2532": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 65,
                        "end_line": 606,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 57,
                        "start_line": 606
                    }
                },
                "2534": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 89,
                        "end_line": 606,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 67,
                        "start_line": 606
                    }
                },
                "2536": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 6,
                        "end_line": 607,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 12,
                        "start_line": 605
                    }
                },
                "2538": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_out"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 607,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 605
                    }
                },
                "2539": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 613,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 613
                    }
                },
                "2541": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 85,
                        "end_line": 610,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 31,
                                "end_line": 53,
                                "input_file": {
                                    "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 31,
                                        "end_line": 615,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 9,
                                        "start_line": 615
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 16,
                                "start_line": 53
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 70,
                        "start_line": 610
                    }
                },
                "2542": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 20,
                        "end_line": 615,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 19,
                        "start_line": 615
                    }
                },
                "2544": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 611,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 30,
                                "end_line": 615,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 22,
                                "start_line": 615
                            },
                            "While expanding the reference 'path_len' in:"
                        ],
                        "start_col": 40,
                        "start_line": 611
                    }
                },
                "2545": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 31,
                        "end_line": 615,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 615
                    }
                },
                "2547": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 50,
                        "end_line": 617,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 43,
                        "start_line": 617
                    }
                },
                "2549": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 39,
                        "end_line": 617,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 10,
                        "start_line": 617
                    }
                },
                "2550": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 29,
                        "end_line": 623,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 17,
                        "start_line": 623
                    }
                },
                "2552": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 624,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 26,
                        "start_line": 624
                    }
                },
                "2554": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 624,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 25,
                        "start_line": 624
                    }
                },
                "2556": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 610,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 630,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 6,
                                        "end_line": 625,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 42,
                                        "start_line": 618
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 24,
                                "start_line": 630
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 22,
                        "start_line": 610
                    }
                },
                "2557": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 68,
                        "end_line": 610,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 70,
                                "end_line": 630,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 6,
                                        "end_line": 625,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 42,
                                        "start_line": 618
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 44,
                                "start_line": 630
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 42,
                        "start_line": 610
                    }
                },
                "2558": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 31,
                        "end_line": 53,
                        "input_file": {
                            "filename": "/home/andy/cairo_venv/lib/python3.9/site-packages/starkware/cairo/common/math.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 31,
                                "end_line": 615,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 87,
                                        "end_line": 630,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 6,
                                                "end_line": 625,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 42,
                                                "start_line": 618
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 72,
                                        "start_line": 630
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 9,
                                "start_line": 615
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 16,
                        "start_line": 53
                    }
                },
                "2559": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 611,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 16,
                                "end_line": 619,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 9,
                                "start_line": 619
                            },
                            "While expanding the reference 'factory' in:"
                        ],
                        "start_col": 5,
                        "start_line": 611
                    }
                },
                "2560": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 611,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 620,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 9,
                                "start_line": 620
                            },
                            "While expanding the reference 'amountOut' in:"
                        ],
                        "start_col": 20,
                        "start_line": 611
                    }
                },
                "2561": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 611,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 18,
                                "end_line": 620,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 9,
                                "start_line": 620
                            },
                            "While expanding the reference 'amountOut' in:"
                        ],
                        "start_col": 20,
                        "start_line": 611
                    }
                },
                "2562": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 21,
                        "end_line": 621,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 621
                    }
                },
                "2564": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 611,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 17,
                                "end_line": 622,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 9,
                                "start_line": 622
                            },
                            "While expanding the reference 'path_len' in:"
                        ],
                        "start_col": 40,
                        "start_line": 611
                    }
                },
                "2565": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 30,
                        "end_line": 623,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 623
                    }
                },
                "2566": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 54,
                        "end_line": 624,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 624
                    }
                },
                "2567": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 6,
                        "end_line": 625,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 42,
                        "start_line": 618
                    }
                },
                "2569": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 42,
                        "end_line": 630,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 6,
                                "end_line": 625,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 40,
                                        "end_line": 610,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 29,
                                                "end_line": 627,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 627
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 22,
                                        "start_line": 610
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 42,
                                "start_line": 618
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 24,
                        "start_line": 630
                    }
                },
                "2570": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 70,
                        "end_line": 630,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 6,
                                "end_line": 625,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 68,
                                        "end_line": 610,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 29,
                                                "end_line": 627,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 627
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 42,
                                        "start_line": 610
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 42,
                                "start_line": 618
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 44,
                        "start_line": 630
                    }
                },
                "2571": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 87,
                        "end_line": 630,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 6,
                                "end_line": 625,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 85,
                                        "end_line": 610,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 29,
                                                "end_line": 627,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 5,
                                                "start_line": 627
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 70,
                                        "start_line": 610
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 42,
                                "start_line": 618
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 72,
                        "start_line": 630
                    }
                },
                "2572": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 29,
                        "end_line": 617,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 26,
                                "end_line": 627,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 13,
                                "start_line": 627
                            },
                            "While expanding the reference 'amounts_start' in:"
                        ],
                        "start_col": 16,
                        "start_line": 617
                    }
                },
                "2573": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._get_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 29,
                        "end_line": 627,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 627
                    }
                },
                "2574": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 638,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 638
                    }
                },
                "2576": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 640,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 26,
                        "start_line": 640
                    }
                },
                "2578": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 640,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 640
                    }
                },
                "2579": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 640,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 640
                    }
                },
                "2581": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 641,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 641
                    }
                },
                "2582": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 641,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 641
                    }
                },
                "2583": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 42,
                        "end_line": 630,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 42,
                                "end_line": 642,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 31,
                                "start_line": 642
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 24,
                        "start_line": 630
                    }
                },
                "2584": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 70,
                        "end_line": 630,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 44,
                                "end_line": 643,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 32,
                                "start_line": 643
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 44,
                        "start_line": 630
                    }
                },
                "2585": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 87,
                        "end_line": 630,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 50,
                                "end_line": 644,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 35,
                                "start_line": 644
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 72,
                        "start_line": 630
                    }
                },
                "2586": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 640,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 640
                    }
                },
                "2588": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 42,
                        "end_line": 630,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 38,
                                "end_line": 469,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 10,
                                        "end_line": 648,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 69,
                                        "start_line": 646
                                    },
                                    "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                ],
                                "start_col": 20,
                                "start_line": 469
                            },
                            "While expanding the reference 'syscall_ptr' in:"
                        ],
                        "start_col": 24,
                        "start_line": 630
                    }
                },
                "2589": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 70,
                        "end_line": 630,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 66,
                                "end_line": 469,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 10,
                                        "end_line": 648,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 69,
                                        "start_line": 646
                                    },
                                    "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                ],
                                "start_col": 40,
                                "start_line": 469
                            },
                            "While expanding the reference 'pedersen_ptr' in:"
                        ],
                        "start_col": 44,
                        "start_line": 630
                    }
                },
                "2590": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 87,
                        "end_line": 630,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 83,
                                "end_line": 469,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 10,
                                        "end_line": 648,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 69,
                                        "start_line": 646
                                    },
                                    "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                ],
                                "start_col": 68,
                                "start_line": 469
                            },
                            "While expanding the reference 'range_check_ptr' in:"
                        ],
                        "start_col": 72,
                        "start_line": 630
                    }
                },
                "2591": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 631,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 20,
                                "end_line": 647,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 13,
                                "start_line": 647
                            },
                            "While expanding the reference 'factory' in:"
                        ],
                        "start_col": 5,
                        "start_line": 631
                    }
                },
                "2592": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 28,
                        "end_line": 647,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 22,
                        "start_line": 647
                    }
                },
                "2593": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 40,
                        "end_line": 647,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 30,
                        "start_line": 647
                    }
                },
                "2594": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 10,
                        "end_line": 648,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 69,
                        "start_line": 646
                    }
                },
                "2596": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 646,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 14,
                        "start_line": 646
                    }
                },
                "2597": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 646,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 14,
                        "start_line": 646
                    }
                },
                "2598": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 65,
                        "end_line": 646,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 40,
                        "start_line": 646
                    }
                },
                "2599": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 65,
                        "end_line": 646,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 40,
                        "start_line": 646
                    }
                },
                "2600": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 38,
                        "end_line": 469,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 10,
                                "end_line": 648,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 39,
                                        "end_line": 533,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 10,
                                                "end_line": 651,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 41,
                                                "start_line": 649
                                            },
                                            "While trying to retrieve the implicit argument 'syscall_ptr' in:"
                                        ],
                                        "start_col": 21,
                                        "start_line": 533
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 69,
                                "start_line": 646
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 20,
                        "start_line": 469
                    }
                },
                "2601": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 66,
                        "end_line": 469,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 10,
                                "end_line": 648,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 67,
                                        "end_line": 533,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 10,
                                                "end_line": 651,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 41,
                                                "start_line": 649
                                            },
                                            "While trying to retrieve the implicit argument 'pedersen_ptr' in:"
                                        ],
                                        "start_col": 41,
                                        "start_line": 533
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 69,
                                "start_line": 646
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 40,
                        "start_line": 469
                    }
                },
                "2602": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 83,
                        "end_line": 469,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 10,
                                "end_line": 648,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 84,
                                        "end_line": 533,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "parent_location": [
                                            {
                                                "end_col": 10,
                                                "end_line": 651,
                                                "input_file": {
                                                    "filename": "contracts/Router.cairo"
                                                },
                                                "start_col": 41,
                                                "start_line": 649
                                            },
                                            "While trying to retrieve the implicit argument 'range_check_ptr' in:"
                                        ],
                                        "start_col": 69,
                                        "start_line": 533
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 69,
                                "start_line": 646
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 68,
                        "start_line": 469
                    }
                },
                "2603": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 650,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 13,
                        "start_line": 650
                    }
                },
                "2604": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 650,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 13,
                        "start_line": 650
                    }
                },
                "2605": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 29,
                        "end_line": 646,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 48,
                                "end_line": 650,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 39,
                                "start_line": 650
                            },
                            "While expanding the reference 'reserveIn' in:"
                        ],
                        "start_col": 20,
                        "start_line": 646
                    }
                },
                "2606": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 29,
                        "end_line": 646,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 48,
                                "end_line": 650,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 39,
                                "start_line": 650
                            },
                            "While expanding the reference 'reserveIn' in:"
                        ],
                        "start_col": 20,
                        "start_line": 646
                    }
                },
                "2607": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 56,
                        "end_line": 646,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 60,
                                "end_line": 650,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 50,
                                "start_line": 650
                            },
                            "While expanding the reference 'reserveOut' in:"
                        ],
                        "start_col": 46,
                        "start_line": 646
                    }
                },
                "2608": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 56,
                        "end_line": 646,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 60,
                                "end_line": 650,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 50,
                                "start_line": 650
                            },
                            "While expanding the reference 'reserveOut' in:"
                        ],
                        "start_col": 46,
                        "start_line": 646
                    }
                },
                "2609": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 10,
                        "end_line": 651,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 41,
                        "start_line": 649
                    }
                },
                "2611": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 649,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 14,
                        "start_line": 649
                    }
                },
                "2612": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 649,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 14,
                        "start_line": 649
                    }
                },
                "2613": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 652,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 652
                    }
                },
                "2614": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 37,
                        "end_line": 652,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 652
                    }
                },
                "2615": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 39,
                        "end_line": 533,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 10,
                                "end_line": 651,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 42,
                                        "end_line": 653,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 31,
                                        "start_line": 653
                                    },
                                    "While expanding the reference 'syscall_ptr' in:"
                                ],
                                "start_col": 41,
                                "start_line": 649
                            },
                            "While trying to update the implicit return value 'syscall_ptr' in:"
                        ],
                        "start_col": 21,
                        "start_line": 533
                    }
                },
                "2616": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 67,
                        "end_line": 533,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 10,
                                "end_line": 651,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 44,
                                        "end_line": 654,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 32,
                                        "start_line": 654
                                    },
                                    "While expanding the reference 'pedersen_ptr' in:"
                                ],
                                "start_col": 41,
                                "start_line": 649
                            },
                            "While trying to update the implicit return value 'pedersen_ptr' in:"
                        ],
                        "start_col": 41,
                        "start_line": 533
                    }
                },
                "2617": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 84,
                        "end_line": 533,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 10,
                                "end_line": 651,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "parent_location": [
                                    {
                                        "end_col": 50,
                                        "end_line": 655,
                                        "input_file": {
                                            "filename": "contracts/Router.cairo"
                                        },
                                        "start_col": 35,
                                        "start_line": 655
                                    },
                                    "While expanding the reference 'range_check_ptr' in:"
                                ],
                                "start_col": 41,
                                "start_line": 649
                            },
                            "While trying to update the implicit return value 'range_check_ptr' in:"
                        ],
                        "start_col": 69,
                        "start_line": 533
                    }
                },
                "2618": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 658,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 658
                    }
                },
                "2620": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 22,
                        "end_line": 636,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 24,
                                "end_line": 659,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 17,
                                "start_line": 659
                            },
                            "While expanding the reference 'amounts' in:"
                        ],
                        "start_col": 5,
                        "start_line": 636
                    }
                },
                "2621": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 27,
                        "end_line": 659,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 9,
                        "start_line": 659
                    }
                },
                "2622": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 18,
                        "end_line": 631,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 16,
                                "end_line": 663,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 9,
                                "start_line": 663
                            },
                            "While expanding the reference 'factory' in:"
                        ],
                        "start_col": 5,
                        "start_line": 631
                    }
                },
                "2623": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 632,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 27,
                                "end_line": 663,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 18,
                                "start_line": 663
                            },
                            "While expanding the reference 'amountOut' in:"
                        ],
                        "start_col": 5,
                        "start_line": 632
                    }
                },
                "2624": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 23,
                        "end_line": 632,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 27,
                                "end_line": 663,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 18,
                                "start_line": 663
                            },
                            "While expanding the reference 'amountOut' in:"
                        ],
                        "start_col": 5,
                        "start_line": 632
                    }
                },
                "2625": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 46,
                        "end_line": 663,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 29,
                        "start_line": 663
                    }
                },
                "2627": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 19,
                        "end_line": 634,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "parent_location": [
                            {
                                "end_col": 56,
                                "end_line": 663,
                                "input_file": {
                                    "filename": "contracts/Router.cairo"
                                },
                                "start_col": 48,
                                "start_line": 663
                            },
                            "While expanding the reference 'path_len' in:"
                        ],
                        "start_col": 5,
                        "start_line": 634
                    }
                },
                "2628": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 66,
                        "end_line": 663,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 58,
                        "start_line": 663
                    }
                },
                "2630": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 90,
                        "end_line": 663,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 68,
                        "start_line": 663
                    }
                },
                "2632": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 6,
                        "end_line": 664,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 12,
                        "start_line": 662
                    }
                },
                "2634": {
                    "accessible_scopes": [
                        "__main__",
                        "__main__._build_amounts_in"
                    ],
                    "flow_tracking_data": null,
                    "hints": [],
                    "inst": {
                        "end_col": 7,
                        "end_line": 664,
                        "input_file": {
                            "filename": "contracts/Router.cairo"
                        },
                        "start_col": 5,
                        "start_line": 662
                    }
                }
            }
        },
        "hints": {
            "0": [
                {
                    "accessible_scopes": [
                        "starkware.cairo.common.alloc",
                        "starkware.cairo.common.alloc.alloc"
                    ],
                    "code": "memory[ap] = segments.add()",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 0,
                            "offset": 0
                        },
                        "reference_ids": {}
                    }
                }
            ],
            "6": [
                {
                    "accessible_scopes": [
                        "starkware.cairo.common.memcpy",
                        "starkware.cairo.common.memcpy.memcpy"
                    ],
                    "code": "vm_enter_scope({'n': ids.len})",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 1,
                            "offset": 0
                        },
                        "reference_ids": {
                            "starkware.cairo.common.memcpy.memcpy.len": 0
                        }
                    }
                }
            ],
            "14": [
                {
                    "accessible_scopes": [
                        "starkware.cairo.common.memcpy",
                        "starkware.cairo.common.memcpy.memcpy"
                    ],
                    "code": "n -= 1\nids.continue_copying = 1 if n > 0 else 0",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 1,
                            "offset": 5
                        },
                        "reference_ids": {
                            "starkware.cairo.common.memcpy.memcpy.continue_copying": 1
                        }
                    }
                }
            ],
            "17": [
                {
                    "accessible_scopes": [
                        "starkware.cairo.common.memcpy",
                        "starkware.cairo.common.memcpy.memcpy"
                    ],
                    "code": "vm_exit_scope()",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 1,
                            "offset": 6
                        },
                        "reference_ids": {}
                    }
                }
            ],
            "18": [
                {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_not_zero"
                    ],
                    "code": "from starkware.cairo.common.math_utils import assert_integer\nassert_integer(ids.value)\nassert ids.value % PRIME != 0, f'assert_not_zero failed: {ids.value} = 0.'",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 2,
                            "offset": 0
                        },
                        "reference_ids": {
                            "starkware.cairo.common.math.assert_not_zero.value": 2
                        }
                    }
                }
            ],
            "23": [
                {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_not_equal"
                    ],
                    "code": "from starkware.cairo.lang.vm.relocatable import RelocatableValue\nboth_ints = isinstance(ids.a, int) and isinstance(ids.b, int)\nboth_relocatable = (\n    isinstance(ids.a, RelocatableValue) and isinstance(ids.b, RelocatableValue) and\n    ids.a.segment_index == ids.b.segment_index)\nassert both_ints or both_relocatable, \\\n    f'assert_not_equal failed: non-comparable values: {ids.a}, {ids.b}.'\nassert (ids.a - ids.b) % PRIME != 0, f'assert_not_equal failed: {ids.a} = {ids.b}.'",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 3,
                            "offset": 0
                        },
                        "reference_ids": {
                            "starkware.cairo.common.math.assert_not_equal.a": 3,
                            "starkware.cairo.common.math.assert_not_equal.b": 4
                        }
                    }
                }
            ],
            "29": [
                {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_nn"
                    ],
                    "code": "from starkware.cairo.common.math_utils import assert_integer\nassert_integer(ids.a)\nassert 0 <= ids.a % PRIME < range_check_builtin.bound, f'a = {ids.a} is out of range.'",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 4,
                            "offset": 0
                        },
                        "reference_ids": {
                            "starkware.cairo.common.math.assert_nn.a": 5
                        }
                    }
                }
            ],
            "38": [
                {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "code": "import itertools\n\nfrom starkware.cairo.common.math_utils import assert_integer\nassert_integer(ids.a)\nassert_integer(ids.b)\na = ids.a % PRIME\nb = ids.b % PRIME\nassert a <= b, f'a = {a} is not less than or equal to b = {b}.'\n\n# Find an arc less than PRIME / 3, and another less than PRIME / 2.\nlengths_and_indices = [(a, 0), (b - a, 1), (PRIME - 1 - b, 2)]\nlengths_and_indices.sort()\nassert lengths_and_indices[0][0] <= PRIME // 3 and lengths_and_indices[1][0] <= PRIME // 2\nexcluded = lengths_and_indices[2][1]\n\nmemory[ids.range_check_ptr + 1], memory[ids.range_check_ptr + 0] = (\n    divmod(lengths_and_indices[0][0], ids.PRIME_OVER_3_HIGH))\nmemory[ids.range_check_ptr + 3], memory[ids.range_check_ptr + 2] = (\n    divmod(lengths_and_indices[1][0], ids.PRIME_OVER_2_HIGH))",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 6,
                            "offset": 0
                        },
                        "reference_ids": {
                            "starkware.cairo.common.math.assert_le_felt.a": 6,
                            "starkware.cairo.common.math.assert_le_felt.b": 7,
                            "starkware.cairo.common.math.assert_le_felt.range_check_ptr": 8
                        }
                    }
                }
            ],
            "48": [
                {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "code": "memory[ap] = 1 if excluded != 0 else 0",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 6,
                            "offset": 8
                        },
                        "reference_ids": {}
                    }
                }
            ],
            "62": [
                {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "code": "memory[ap] = 1 if excluded != 1 else 0",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 6,
                            "offset": 9
                        },
                        "reference_ids": {}
                    }
                }
            ],
            "74": [
                {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_le_felt"
                    ],
                    "code": "assert excluded == 2",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 6,
                            "offset": 10
                        },
                        "reference_ids": {}
                    }
                }
            ],
            "83": [
                {
                    "accessible_scopes": [
                        "starkware.cairo.common.math",
                        "starkware.cairo.common.math.assert_lt_felt"
                    ],
                    "code": "from starkware.cairo.common.math_utils import assert_integer\nassert_integer(ids.a)\nassert_integer(ids.b)\nassert (ids.a % PRIME) < (ids.b % PRIME), \\\n    f'a = {ids.a % PRIME} is not less than b = {ids.b % PRIME}.'",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 7,
                            "offset": 0
                        },
                        "reference_ids": {
                            "starkware.cairo.common.math.assert_lt_felt.a": 9,
                            "starkware.cairo.common.math.assert_lt_felt.b": 10
                        }
                    }
                }
            ],
            "101": [
                {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.call_contract"
                    ],
                    "code": "syscall_handler.call_contract(segments=segments, syscall_ptr=ids.syscall_ptr)",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 8,
                            "offset": 1
                        },
                        "reference_ids": {
                            "starkware.starknet.common.syscalls.call_contract.syscall_ptr": 11
                        }
                    }
                }
            ],
            "109": [
                {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.get_caller_address"
                    ],
                    "code": "syscall_handler.get_caller_address(segments=segments, syscall_ptr=ids.syscall_ptr)",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 9,
                            "offset": 1
                        },
                        "reference_ids": {
                            "starkware.starknet.common.syscalls.get_caller_address.syscall_ptr": 12
                        }
                    }
                }
            ],
            "116": [
                {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.get_block_timestamp"
                    ],
                    "code": "syscall_handler.get_block_timestamp(segments=segments, syscall_ptr=ids.syscall_ptr)",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 10,
                            "offset": 1
                        },
                        "reference_ids": {
                            "starkware.starknet.common.syscalls.get_block_timestamp.syscall_ptr": 13
                        }
                    }
                }
            ],
            "124": [
                {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.storage_read"
                    ],
                    "code": "syscall_handler.storage_read(segments=segments, syscall_ptr=ids.syscall_ptr)",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 11,
                            "offset": 1
                        },
                        "reference_ids": {
                            "starkware.starknet.common.syscalls.storage_read.syscall_ptr": 14
                        }
                    }
                }
            ],
            "133": [
                {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.storage_write"
                    ],
                    "code": "syscall_handler.storage_write(segments=segments, syscall_ptr=ids.syscall_ptr)",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 12,
                            "offset": 1
                        },
                        "reference_ids": {
                            "starkware.starknet.common.syscalls.storage_write.syscall_ptr": 15
                        }
                    }
                }
            ],
            "143": [
                {
                    "accessible_scopes": [
                        "starkware.starknet.common.syscalls",
                        "starkware.starknet.common.syscalls.emit_event"
                    ],
                    "code": "syscall_handler.emit_event(segments=segments, syscall_ptr=ids.syscall_ptr)",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 13,
                            "offset": 1
                        },
                        "reference_ids": {
                            "starkware.starknet.common.syscalls.emit_event.syscall_ptr": 16
                        }
                    }
                }
            ],
            "146": [
                {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_nn"
                    ],
                    "code": "memory[ap] = 0 if 0 <= (ids.a % PRIME) < range_check_builtin.bound else 1",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 14,
                            "offset": 0
                        },
                        "reference_ids": {
                            "starkware.cairo.common.math_cmp.is_nn.a": 17
                        }
                    }
                }
            ],
            "156": [
                {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_nn"
                    ],
                    "code": "memory[ap] = 0 if 0 <= ((-ids.a - 1) % PRIME) < range_check_builtin.bound else 1",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 14,
                            "offset": 1
                        },
                        "reference_ids": {
                            "starkware.cairo.common.math_cmp.is_nn.a": 17
                        }
                    }
                }
            ],
            "184": [
                {
                    "accessible_scopes": [
                        "starkware.cairo.common.math_cmp",
                        "starkware.cairo.common.math_cmp.is_le_felt"
                    ],
                    "code": "memory[ap] = 0 if (ids.a % PRIME) <= (ids.b % PRIME) else 1",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 16,
                            "offset": 0
                        },
                        "reference_ids": {
                            "starkware.cairo.common.math_cmp.is_le_felt.a": 18,
                            "starkware.cairo.common.math_cmp.is_le_felt.b": 19
                        }
                    }
                }
            ],
            "211": [
                {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_add"
                    ],
                    "code": "sum_low = ids.a.low + ids.b.low\nids.carry_low = 1 if sum_low >= ids.SHIFT else 0\nsum_high = ids.a.high + ids.b.high + ids.carry_low\nids.carry_high = 1 if sum_high >= ids.SHIFT else 0",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 18,
                            "offset": 4
                        },
                        "reference_ids": {
                            "starkware.cairo.common.uint256.uint256_add.a": 20,
                            "starkware.cairo.common.uint256.uint256_add.b": 21,
                            "starkware.cairo.common.uint256.uint256_add.carry_high": 23,
                            "starkware.cairo.common.uint256.uint256_add.carry_low": 22
                        }
                    }
                }
            ],
            "233": [
                {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.split_64"
                    ],
                    "code": "ids.low = ids.a & ((1<<64) - 1)\nids.high = ids.a >> 64",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 19,
                            "offset": 2
                        },
                        "reference_ids": {
                            "starkware.cairo.common.uint256.split_64.a": 24,
                            "starkware.cairo.common.uint256.split_64.high": 26,
                            "starkware.cairo.common.uint256.split_64.low": 25
                        }
                    }
                }
            ],
            "379": [
                {
                    "accessible_scopes": [
                        "starkware.cairo.common.uint256",
                        "starkware.cairo.common.uint256.uint256_unsigned_div_rem"
                    ],
                    "code": "a = (ids.a.high << 128) + ids.a.low\ndiv = (ids.div.high << 128) + ids.div.low\nquotient, remainder = divmod(a, div)\n\nids.quotient.low = quotient & ((1 << 128) - 1)\nids.quotient.high = quotient >> 128\nids.remainder.low = remainder & ((1 << 128) - 1)\nids.remainder.high = remainder >> 128",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 23,
                            "offset": 5
                        },
                        "reference_ids": {
                            "starkware.cairo.common.uint256.uint256_unsigned_div_rem.a": 27,
                            "starkware.cairo.common.uint256.uint256_unsigned_div_rem.div": 28,
                            "starkware.cairo.common.uint256.uint256_unsigned_div_rem.quotient": 29,
                            "starkware.cairo.common.uint256.uint256_unsigned_div_rem.remainder": 30
                        }
                    }
                }
            ],
            "964": [
                {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.initializer"
                    ],
                    "code": "memory[ap] = segments.add()",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 58,
                            "offset": 0
                        },
                        "reference_ids": {}
                    }
                }
            ],
            "979": [
                {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.factory_encode_return"
                    ],
                    "code": "memory[ap] = segments.add()",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 60,
                            "offset": 0
                        },
                        "reference_ids": {}
                    }
                }
            ],
            "1011": [
                {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.sort_tokens_encode_return"
                    ],
                    "code": "memory[ap] = segments.add()",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 64,
                            "offset": 0
                        },
                        "reference_ids": {}
                    }
                }
            ],
            "1052": [
                {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.quote_encode_return"
                    ],
                    "code": "memory[ap] = segments.add()",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 69,
                            "offset": 0
                        },
                        "reference_ids": {}
                    }
                }
            ],
            "1097": [
                {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_out_encode_return"
                    ],
                    "code": "memory[ap] = segments.add()",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 74,
                            "offset": 0
                        },
                        "reference_ids": {}
                    }
                }
            ],
            "1142": [
                {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amount_in_encode_return"
                    ],
                    "code": "memory[ap] = segments.add()",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 79,
                            "offset": 0
                        },
                        "reference_ids": {}
                    }
                }
            ],
            "1200": [
                {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_out_encode_return"
                    ],
                    "code": "memory[ap] = segments.add()",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 84,
                            "offset": 0
                        },
                        "reference_ids": {}
                    }
                }
            ],
            "1280": [
                {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.get_amounts_in_encode_return"
                    ],
                    "code": "memory[ap] = segments.add()",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 91,
                            "offset": 0
                        },
                        "reference_ids": {}
                    }
                }
            ],
            "1413": [
                {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.add_liquidity_encode_return"
                    ],
                    "code": "memory[ap] = segments.add()",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 100,
                            "offset": 0
                        },
                        "reference_ids": {}
                    }
                }
            ],
            "1545": [
                {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.remove_liquidity_encode_return"
                    ],
                    "code": "memory[ap] = segments.add()",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 107,
                            "offset": 0
                        },
                        "reference_ids": {}
                    }
                }
            ],
            "1662": [
                {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_exact_tokens_for_tokens_encode_return"
                    ],
                    "code": "memory[ap] = segments.add()",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 115,
                            "offset": 0
                        },
                        "reference_ids": {}
                    }
                }
            ],
            "1789": [
                {
                    "accessible_scopes": [
                        "__main__",
                        "__main__",
                        "__wrappers__",
                        "__wrappers__.swap_tokens_for_exact_tokens_encode_return"
                    ],
                    "code": "memory[ap] = segments.add()",
                    "flow_tracking_data": {
                        "ap_tracking": {
                            "group": 125,
                            "offset": 0
                        },
                        "reference_ids": {}
                    }
                }
            ]
        },
        "identifiers": {
            "__main__.HashBuiltin": {
                "destination": "starkware.cairo.common.cairo_builtins.HashBuiltin",
                "type": "alias"
            },
            "__main__.IERC20": {
                "type": "namespace"
            },
            "__main__.IERC20.Args": {
                "full_name": "__main__.IERC20.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__main__.IERC20.ImplicitArgs": {
                "full_name": "__main__.IERC20.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__main__.IERC20.Return": {
                "cairo_type": "()",
                "type": "type_definition"
            },
            "__main__.IERC20.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "__main__.IERC20.TRANSFERFROM_SELECTOR": {
                "type": "const",
                "value": 116061167288211781254449158074459916871457383008289084697957612485591092000
            },
            "__main__.IERC20.alloc": {
                "destination": "starkware.cairo.common.alloc.alloc",
                "type": "alias"
            },
            "__main__.IERC20.call_contract": {
                "destination": "starkware.starknet.common.syscalls.call_contract",
                "type": "alias"
            },
            "__main__.IERC20.library_call": {
                "destination": "starkware.starknet.common.syscalls.library_call",
                "type": "alias"
            },
            "__main__.IERC20.memcpy": {
                "destination": "starkware.cairo.common.memcpy.memcpy",
                "type": "alias"
            },
            "__main__.IERC20.transferFrom": {
                "decorators": [],
                "pc": 739,
                "type": "function"
            },
            "__main__.IERC20.transferFrom.Args": {
                "full_name": "__main__.IERC20.transferFrom.Args",
                "members": {
                    "amount": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 3
                    },
                    "contract_address": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "recipient": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "sender": {
                        "cairo_type": "felt",
                        "offset": 1
                    }
                },
                "size": 5,
                "type": "struct"
            },
            "__main__.IERC20.transferFrom.ImplicitArgs": {
                "full_name": "__main__.IERC20.transferFrom.ImplicitArgs",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 1
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "__main__.IERC20.transferFrom.Return": {
                "cairo_type": "(success: felt)",
                "type": "type_definition"
            },
            "__main__.IERC20.transferFrom.SIZEOF_LOCALS": {
                "type": "const",
                "value": 1
            },
            "__main__.IFactory": {
                "type": "namespace"
            },
            "__main__.IFactory.Args": {
                "full_name": "__main__.IFactory.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__main__.IFactory.CREATE_PAIR_SELECTOR": {
                "type": "const",
                "value": 228159840923688866406267525259648198173531539201120403522757104993212296565
            },
            "__main__.IFactory.GET_PAIR_SELECTOR": {
                "type": "const",
                "value": 1361884049818391466621639291137266571310943900493453366512002203017825139028
            },
            "__main__.IFactory.ImplicitArgs": {
                "full_name": "__main__.IFactory.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__main__.IFactory.Return": {
                "cairo_type": "()",
                "type": "type_definition"
            },
            "__main__.IFactory.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "__main__.IFactory.alloc": {
                "destination": "starkware.cairo.common.alloc.alloc",
                "type": "alias"
            },
            "__main__.IFactory.call_contract": {
                "destination": "starkware.starknet.common.syscalls.call_contract",
                "type": "alias"
            },
            "__main__.IFactory.create_pair": {
                "decorators": [],
                "pc": 887,
                "type": "function"
            },
            "__main__.IFactory.create_pair.Args": {
                "full_name": "__main__.IFactory.create_pair.Args",
                "members": {
                    "contract_address": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "token0": {
                        "cairo_type": "felt",
                        "offset": 1
                    },
                    "token1": {
                        "cairo_type": "felt",
                        "offset": 2
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__.IFactory.create_pair.ImplicitArgs": {
                "full_name": "__main__.IFactory.create_pair.ImplicitArgs",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 1
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "__main__.IFactory.create_pair.Return": {
                "cairo_type": "(pair: felt)",
                "type": "type_definition"
            },
            "__main__.IFactory.create_pair.SIZEOF_LOCALS": {
                "type": "const",
                "value": 1
            },
            "__main__.IFactory.get_pair": {
                "decorators": [],
                "pc": 863,
                "type": "function"
            },
            "__main__.IFactory.get_pair.Args": {
                "full_name": "__main__.IFactory.get_pair.Args",
                "members": {
                    "contract_address": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "token0": {
                        "cairo_type": "felt",
                        "offset": 1
                    },
                    "token1": {
                        "cairo_type": "felt",
                        "offset": 2
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__.IFactory.get_pair.ImplicitArgs": {
                "full_name": "__main__.IFactory.get_pair.ImplicitArgs",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 1
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "__main__.IFactory.get_pair.Return": {
                "cairo_type": "(pair: felt)",
                "type": "type_definition"
            },
            "__main__.IFactory.get_pair.SIZEOF_LOCALS": {
                "type": "const",
                "value": 1
            },
            "__main__.IFactory.library_call": {
                "destination": "starkware.starknet.common.syscalls.library_call",
                "type": "alias"
            },
            "__main__.IFactory.memcpy": {
                "destination": "starkware.cairo.common.memcpy.memcpy",
                "type": "alias"
            },
            "__main__.IPair": {
                "type": "namespace"
            },
            "__main__.IPair.Args": {
                "full_name": "__main__.IPair.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__main__.IPair.BURN_SELECTOR": {
                "type": "const",
                "value": 110517590864022483288709273410208099989999878078159056510309951061542994625
            },
            "__main__.IPair.GET_RESERVES_SELECTOR": {
                "type": "const",
                "value": 1715705677754146725544391220708589383422824993050994982749243481839397737234
            },
            "__main__.IPair.ImplicitArgs": {
                "full_name": "__main__.IPair.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__main__.IPair.MINT_SELECTOR": {
                "type": "const",
                "value": 1329909728320632088402217562277154056711815095720684343816173432540100887380
            },
            "__main__.IPair.Return": {
                "cairo_type": "()",
                "type": "type_definition"
            },
            "__main__.IPair.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "__main__.IPair.SWAP_SELECTOR": {
                "type": "const",
                "value": 602962535134499854912799851629033993488593928113527484350375636311213640489
            },
            "__main__.IPair.alloc": {
                "destination": "starkware.cairo.common.alloc.alloc",
                "type": "alias"
            },
            "__main__.IPair.burn": {
                "decorators": [],
                "pc": 813,
                "type": "function"
            },
            "__main__.IPair.burn.Args": {
                "full_name": "__main__.IPair.burn.Args",
                "members": {
                    "contract_address": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "to": {
                        "cairo_type": "felt",
                        "offset": 1
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "__main__.IPair.burn.ImplicitArgs": {
                "full_name": "__main__.IPair.burn.ImplicitArgs",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 1
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "__main__.IPair.burn.Return": {
                "cairo_type": "(amount0: starkware.cairo.common.uint256.Uint256, amount1: starkware.cairo.common.uint256.Uint256)",
                "type": "type_definition"
            },
            "__main__.IPair.burn.SIZEOF_LOCALS": {
                "type": "const",
                "value": 1
            },
            "__main__.IPair.call_contract": {
                "destination": "starkware.starknet.common.syscalls.call_contract",
                "type": "alias"
            },
            "__main__.IPair.get_reserves": {
                "decorators": [],
                "pc": 765,
                "type": "function"
            },
            "__main__.IPair.get_reserves.Args": {
                "full_name": "__main__.IPair.get_reserves.Args",
                "members": {
                    "contract_address": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "__main__.IPair.get_reserves.ImplicitArgs": {
                "full_name": "__main__.IPair.get_reserves.ImplicitArgs",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 1
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "__main__.IPair.get_reserves.Return": {
                "cairo_type": "(reserve0: starkware.cairo.common.uint256.Uint256, reserve1: starkware.cairo.common.uint256.Uint256, block_timestamp_last: felt)",
                "type": "type_definition"
            },
            "__main__.IPair.get_reserves.SIZEOF_LOCALS": {
                "type": "const",
                "value": 1
            },
            "__main__.IPair.library_call": {
                "destination": "starkware.starknet.common.syscalls.library_call",
                "type": "alias"
            },
            "__main__.IPair.memcpy": {
                "destination": "starkware.cairo.common.memcpy.memcpy",
                "type": "alias"
            },
            "__main__.IPair.mint": {
                "decorators": [],
                "pc": 789,
                "type": "function"
            },
            "__main__.IPair.mint.Args": {
                "full_name": "__main__.IPair.mint.Args",
                "members": {
                    "contract_address": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "to": {
                        "cairo_type": "felt",
                        "offset": 1
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "__main__.IPair.mint.ImplicitArgs": {
                "full_name": "__main__.IPair.mint.ImplicitArgs",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 1
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "__main__.IPair.mint.Return": {
                "cairo_type": "(liquidity: starkware.cairo.common.uint256.Uint256)",
                "type": "type_definition"
            },
            "__main__.IPair.mint.SIZEOF_LOCALS": {
                "type": "const",
                "value": 1
            },
            "__main__.IPair.swap": {
                "decorators": [],
                "pc": 839,
                "type": "function"
            },
            "__main__.IPair.swap.Args": {
                "full_name": "__main__.IPair.swap.Args",
                "members": {
                    "amount0Out": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 1
                    },
                    "amount1Out": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 3
                    },
                    "contract_address": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "data_len": {
                        "cairo_type": "felt",
                        "offset": 6
                    },
                    "to": {
                        "cairo_type": "felt",
                        "offset": 5
                    }
                },
                "size": 7,
                "type": "struct"
            },
            "__main__.IPair.swap.ImplicitArgs": {
                "full_name": "__main__.IPair.swap.ImplicitArgs",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 1
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "__main__.IPair.swap.Return": {
                "cairo_type": "()",
                "type": "type_definition"
            },
            "__main__.IPair.swap.SIZEOF_LOCALS": {
                "type": "const",
                "value": 1
            },
            "__main__.Proxy": {
                "destination": "openzeppelin.upgrades.library.Proxy",
                "type": "alias"
            },
            "__main__.Uint256": {
                "destination": "starkware.cairo.common.uint256.Uint256",
                "type": "alias"
            },
            "__main__._add_liquidity": {
                "decorators": [],
                "pc": 1862,
                "type": "function"
            },
            "__main__._add_liquidity.Args": {
                "full_name": "__main__._add_liquidity.Args",
                "members": {
                    "amountADesired": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 2
                    },
                    "amountAMin": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 6
                    },
                    "amountBDesired": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 4
                    },
                    "amountBMin": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 8
                    },
                    "tokenA": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "tokenB": {
                        "cairo_type": "felt",
                        "offset": 1
                    }
                },
                "size": 10,
                "type": "struct"
            },
            "__main__._add_liquidity.ImplicitArgs": {
                "full_name": "__main__._add_liquidity.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__._add_liquidity.Return": {
                "cairo_type": "(amountA: starkware.cairo.common.uint256.Uint256, amountB: starkware.cairo.common.uint256.Uint256)",
                "type": "type_definition"
            },
            "__main__._add_liquidity.SIZEOF_LOCALS": {
                "type": "const",
                "value": 13
            },
            "__main__._build_amounts_in": {
                "decorators": [],
                "pc": 2574,
                "type": "function"
            },
            "__main__._build_amounts_in.Args": {
                "full_name": "__main__._build_amounts_in.Args",
                "members": {
                    "amountOut": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 1
                    },
                    "amounts": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256*",
                        "offset": 6
                    },
                    "current_index": {
                        "cairo_type": "felt",
                        "offset": 3
                    },
                    "factory": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "path": {
                        "cairo_type": "felt*",
                        "offset": 5
                    },
                    "path_len": {
                        "cairo_type": "felt",
                        "offset": 4
                    }
                },
                "size": 7,
                "type": "struct"
            },
            "__main__._build_amounts_in.ImplicitArgs": {
                "full_name": "__main__._build_amounts_in.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__._build_amounts_in.Return": {
                "cairo_type": "(amounts: starkware.cairo.common.uint256.Uint256*)",
                "type": "type_definition"
            },
            "__main__._build_amounts_in.SIZEOF_LOCALS": {
                "type": "const",
                "value": 6
            },
            "__main__._build_amounts_out": {
                "decorators": [],
                "pc": 2477,
                "type": "function"
            },
            "__main__._build_amounts_out.Args": {
                "full_name": "__main__._build_amounts_out.Args",
                "members": {
                    "amountIn": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 1
                    },
                    "amounts": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256*",
                        "offset": 6
                    },
                    "current_index": {
                        "cairo_type": "felt",
                        "offset": 3
                    },
                    "factory": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "path": {
                        "cairo_type": "felt*",
                        "offset": 5
                    },
                    "path_len": {
                        "cairo_type": "felt",
                        "offset": 4
                    }
                },
                "size": 7,
                "type": "struct"
            },
            "__main__._build_amounts_out.ImplicitArgs": {
                "full_name": "__main__._build_amounts_out.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__._build_amounts_out.Return": {
                "cairo_type": "(amounts: starkware.cairo.common.uint256.Uint256*)",
                "type": "type_definition"
            },
            "__main__._build_amounts_out.SIZEOF_LOCALS": {
                "type": "const",
                "value": 6
            },
            "__main__._ensure_deadline": {
                "decorators": [],
                "pc": 1850,
                "type": "function"
            },
            "__main__._ensure_deadline.Args": {
                "full_name": "__main__._ensure_deadline.Args",
                "members": {
                    "deadline": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "__main__._ensure_deadline.ImplicitArgs": {
                "full_name": "__main__._ensure_deadline.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__._ensure_deadline.Return": {
                "cairo_type": "()",
                "type": "type_definition"
            },
            "__main__._ensure_deadline.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "__main__._factory": {
                "type": "namespace"
            },
            "__main__._factory.Args": {
                "full_name": "__main__._factory.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__main__._factory.HashBuiltin": {
                "destination": "starkware.cairo.common.cairo_builtins.HashBuiltin",
                "type": "alias"
            },
            "__main__._factory.ImplicitArgs": {
                "full_name": "__main__._factory.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__main__._factory.Return": {
                "cairo_type": "()",
                "type": "type_definition"
            },
            "__main__._factory.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "__main__._factory.addr": {
                "decorators": [],
                "pc": 911,
                "type": "function"
            },
            "__main__._factory.addr.Args": {
                "full_name": "__main__._factory.addr.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__main__._factory.addr.ImplicitArgs": {
                "full_name": "__main__._factory.addr.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 0
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 1
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "__main__._factory.addr.Return": {
                "cairo_type": "(res: felt)",
                "type": "type_definition"
            },
            "__main__._factory.addr.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "__main__._factory.hash2": {
                "destination": "starkware.cairo.common.hash.hash2",
                "type": "alias"
            },
            "__main__._factory.normalize_address": {
                "destination": "starkware.starknet.common.storage.normalize_address",
                "type": "alias"
            },
            "__main__._factory.read": {
                "decorators": [],
                "pc": 916,
                "type": "function"
            },
            "__main__._factory.read.Args": {
                "full_name": "__main__._factory.read.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__main__._factory.read.ImplicitArgs": {
                "full_name": "__main__._factory.read.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__._factory.read.Return": {
                "cairo_type": "(address: felt)",
                "type": "type_definition"
            },
            "__main__._factory.read.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "__main__._factory.storage_read": {
                "destination": "starkware.starknet.common.syscalls.storage_read",
                "type": "alias"
            },
            "__main__._factory.storage_write": {
                "destination": "starkware.starknet.common.syscalls.storage_write",
                "type": "alias"
            },
            "__main__._factory.write": {
                "decorators": [],
                "pc": 929,
                "type": "function"
            },
            "__main__._factory.write.Args": {
                "full_name": "__main__._factory.write.Args",
                "members": {
                    "value": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "__main__._factory.write.ImplicitArgs": {
                "full_name": "__main__._factory.write.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__._factory.write.Return": {
                "cairo_type": "()",
                "type": "type_definition"
            },
            "__main__._factory.write.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "__main__._get_amount_in": {
                "decorators": [],
                "pc": 2357,
                "type": "function"
            },
            "__main__._get_amount_in.Args": {
                "full_name": "__main__._get_amount_in.Args",
                "members": {
                    "amountOut": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 0
                    },
                    "reserveIn": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 2
                    },
                    "reserveOut": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 4
                    }
                },
                "size": 6,
                "type": "struct"
            },
            "__main__._get_amount_in.ImplicitArgs": {
                "full_name": "__main__._get_amount_in.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__._get_amount_in.Return": {
                "cairo_type": "(amountIn: starkware.cairo.common.uint256.Uint256)",
                "type": "type_definition"
            },
            "__main__._get_amount_in.SIZEOF_LOCALS": {
                "type": "const",
                "value": 6
            },
            "__main__._get_amount_out": {
                "decorators": [],
                "pc": 2270,
                "type": "function"
            },
            "__main__._get_amount_out.Args": {
                "full_name": "__main__._get_amount_out.Args",
                "members": {
                    "amountIn": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 0
                    },
                    "reserveIn": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 2
                    },
                    "reserveOut": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 4
                    }
                },
                "size": 6,
                "type": "struct"
            },
            "__main__._get_amount_out.ImplicitArgs": {
                "full_name": "__main__._get_amount_out.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__._get_amount_out.Return": {
                "cairo_type": "(amountOut: starkware.cairo.common.uint256.Uint256)",
                "type": "type_definition"
            },
            "__main__._get_amount_out.SIZEOF_LOCALS": {
                "type": "const",
                "value": 8
            },
            "__main__._get_amounts_in": {
                "decorators": [],
                "pc": 2539,
                "type": "function"
            },
            "__main__._get_amounts_in.Args": {
                "full_name": "__main__._get_amounts_in.Args",
                "members": {
                    "amountOut": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 1
                    },
                    "factory": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "path": {
                        "cairo_type": "felt*",
                        "offset": 4
                    },
                    "path_len": {
                        "cairo_type": "felt",
                        "offset": 3
                    }
                },
                "size": 5,
                "type": "struct"
            },
            "__main__._get_amounts_in.ImplicitArgs": {
                "full_name": "__main__._get_amounts_in.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__._get_amounts_in.Return": {
                "cairo_type": "(amounts: starkware.cairo.common.uint256.Uint256*)",
                "type": "type_definition"
            },
            "__main__._get_amounts_in.SIZEOF_LOCALS": {
                "type": "const",
                "value": 1
            },
            "__main__._get_amounts_out": {
                "decorators": [],
                "pc": 2448,
                "type": "function"
            },
            "__main__._get_amounts_out.Args": {
                "full_name": "__main__._get_amounts_out.Args",
                "members": {
                    "amountIn": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 1
                    },
                    "factory": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "path": {
                        "cairo_type": "felt*",
                        "offset": 4
                    },
                    "path_len": {
                        "cairo_type": "felt",
                        "offset": 3
                    }
                },
                "size": 5,
                "type": "struct"
            },
            "__main__._get_amounts_out.ImplicitArgs": {
                "full_name": "__main__._get_amounts_out.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__._get_amounts_out.Return": {
                "cairo_type": "(amounts: starkware.cairo.common.uint256.Uint256*)",
                "type": "type_definition"
            },
            "__main__._get_amounts_out.SIZEOF_LOCALS": {
                "type": "const",
                "value": 1
            },
            "__main__._get_reserves": {
                "decorators": [],
                "pc": 2167,
                "type": "function"
            },
            "__main__._get_reserves.Args": {
                "full_name": "__main__._get_reserves.Args",
                "members": {
                    "factory": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "tokenA": {
                        "cairo_type": "felt",
                        "offset": 1
                    },
                    "tokenB": {
                        "cairo_type": "felt",
                        "offset": 2
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__._get_reserves.ImplicitArgs": {
                "full_name": "__main__._get_reserves.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__._get_reserves.Return": {
                "cairo_type": "(reserveA: starkware.cairo.common.uint256.Uint256, reserveB: starkware.cairo.common.uint256.Uint256)",
                "type": "type_definition"
            },
            "__main__._get_reserves.SIZEOF_LOCALS": {
                "type": "const",
                "value": 6
            },
            "__main__._pair_for": {
                "decorators": [],
                "pc": 2143,
                "type": "function"
            },
            "__main__._pair_for.Args": {
                "full_name": "__main__._pair_for.Args",
                "members": {
                    "factory": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "tokenA": {
                        "cairo_type": "felt",
                        "offset": 1
                    },
                    "tokenB": {
                        "cairo_type": "felt",
                        "offset": 2
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__._pair_for.ImplicitArgs": {
                "full_name": "__main__._pair_for.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__._pair_for.Return": {
                "cairo_type": "(pair: felt)",
                "type": "type_definition"
            },
            "__main__._pair_for.SIZEOF_LOCALS": {
                "type": "const",
                "value": 3
            },
            "__main__._quote": {
                "decorators": [],
                "pc": 2214,
                "type": "function"
            },
            "__main__._quote.Args": {
                "full_name": "__main__._quote.Args",
                "members": {
                    "amountA": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 0
                    },
                    "reserveA": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 2
                    },
                    "reserveB": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 4
                    }
                },
                "size": 6,
                "type": "struct"
            },
            "__main__._quote.ImplicitArgs": {
                "full_name": "__main__._quote.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__._quote.Return": {
                "cairo_type": "(amountB: starkware.cairo.common.uint256.Uint256)",
                "type": "type_definition"
            },
            "__main__._quote.SIZEOF_LOCALS": {
                "type": "const",
                "value": 2
            },
            "__main__._sort_tokens": {
                "decorators": [],
                "pc": 2113,
                "type": "function"
            },
            "__main__._sort_tokens.Args": {
                "full_name": "__main__._sort_tokens.Args",
                "members": {
                    "tokenA": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "tokenB": {
                        "cairo_type": "felt",
                        "offset": 1
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "__main__._sort_tokens.ImplicitArgs": {
                "full_name": "__main__._sort_tokens.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__._sort_tokens.Return": {
                "cairo_type": "(token0: felt, token1: felt)",
                "type": "type_definition"
            },
            "__main__._sort_tokens.SIZEOF_LOCALS": {
                "type": "const",
                "value": 2
            },
            "__main__._swap": {
                "decorators": [],
                "pc": 2009,
                "type": "function"
            },
            "__main__._swap.Args": {
                "full_name": "__main__._swap.Args",
                "members": {
                    "_to": {
                        "cairo_type": "felt",
                        "offset": 4
                    },
                    "amounts": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256*",
                        "offset": 2
                    },
                    "amounts_len": {
                        "cairo_type": "felt",
                        "offset": 1
                    },
                    "current_index": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "path": {
                        "cairo_type": "felt*",
                        "offset": 3
                    }
                },
                "size": 5,
                "type": "struct"
            },
            "__main__._swap.ImplicitArgs": {
                "full_name": "__main__._swap.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__._swap.Return": {
                "cairo_type": "()",
                "type": "type_definition"
            },
            "__main__._swap.SIZEOF_LOCALS": {
                "type": "const",
                "value": 9
            },
            "__main__.add_liquidity": {
                "decorators": [
                    "external"
                ],
                "pc": 1335,
                "type": "function"
            },
            "__main__.add_liquidity.Args": {
                "full_name": "__main__.add_liquidity.Args",
                "members": {
                    "amountADesired": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 2
                    },
                    "amountAMin": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 6
                    },
                    "amountBDesired": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 4
                    },
                    "amountBMin": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 8
                    },
                    "deadline": {
                        "cairo_type": "felt",
                        "offset": 11
                    },
                    "to": {
                        "cairo_type": "felt",
                        "offset": 10
                    },
                    "tokenA": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "tokenB": {
                        "cairo_type": "felt",
                        "offset": 1
                    }
                },
                "size": 12,
                "type": "struct"
            },
            "__main__.add_liquidity.ImplicitArgs": {
                "full_name": "__main__.add_liquidity.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__.add_liquidity.Return": {
                "cairo_type": "(amountA: starkware.cairo.common.uint256.Uint256, amountB: starkware.cairo.common.uint256.Uint256, liquidity: starkware.cairo.common.uint256.Uint256)",
                "type": "type_definition"
            },
            "__main__.add_liquidity.SIZEOF_LOCALS": {
                "type": "const",
                "value": 8
            },
            "__main__.alloc": {
                "destination": "starkware.cairo.common.alloc.alloc",
                "type": "alias"
            },
            "__main__.assert_le": {
                "destination": "starkware.cairo.common.math.assert_le",
                "type": "alias"
            },
            "__main__.assert_not_equal": {
                "destination": "starkware.cairo.common.math.assert_not_equal",
                "type": "alias"
            },
            "__main__.assert_not_zero": {
                "destination": "starkware.cairo.common.math.assert_not_zero",
                "type": "alias"
            },
            "__main__.factory": {
                "decorators": [
                    "view"
                ],
                "pc": 973,
                "type": "function"
            },
            "__main__.factory.Args": {
                "full_name": "__main__.factory.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__main__.factory.ImplicitArgs": {
                "full_name": "__main__.factory.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__.factory.Return": {
                "cairo_type": "(address: felt)",
                "type": "type_definition"
            },
            "__main__.factory.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "__main__.get_amount_in": {
                "decorators": [
                    "view"
                ],
                "pc": 1130,
                "type": "function"
            },
            "__main__.get_amount_in.Args": {
                "full_name": "__main__.get_amount_in.Args",
                "members": {
                    "amountOut": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 0
                    },
                    "reserveIn": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 2
                    },
                    "reserveOut": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 4
                    }
                },
                "size": 6,
                "type": "struct"
            },
            "__main__.get_amount_in.ImplicitArgs": {
                "full_name": "__main__.get_amount_in.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__.get_amount_in.Return": {
                "cairo_type": "(amountIn: starkware.cairo.common.uint256.Uint256)",
                "type": "type_definition"
            },
            "__main__.get_amount_in.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "__main__.get_amount_out": {
                "decorators": [
                    "view"
                ],
                "pc": 1085,
                "type": "function"
            },
            "__main__.get_amount_out.Args": {
                "full_name": "__main__.get_amount_out.Args",
                "members": {
                    "amountIn": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 0
                    },
                    "reserveIn": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 2
                    },
                    "reserveOut": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 4
                    }
                },
                "size": 6,
                "type": "struct"
            },
            "__main__.get_amount_out.ImplicitArgs": {
                "full_name": "__main__.get_amount_out.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__.get_amount_out.Return": {
                "cairo_type": "(amountOut: starkware.cairo.common.uint256.Uint256)",
                "type": "type_definition"
            },
            "__main__.get_amount_out.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "__main__.get_amounts_in": {
                "decorators": [
                    "view"
                ],
                "pc": 1255,
                "type": "function"
            },
            "__main__.get_amounts_in.Args": {
                "full_name": "__main__.get_amounts_in.Args",
                "members": {
                    "amountOut": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 0
                    },
                    "path": {
                        "cairo_type": "felt*",
                        "offset": 3
                    },
                    "path_len": {
                        "cairo_type": "felt",
                        "offset": 2
                    }
                },
                "size": 4,
                "type": "struct"
            },
            "__main__.get_amounts_in.ImplicitArgs": {
                "full_name": "__main__.get_amounts_in.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__.get_amounts_in.Return": {
                "cairo_type": "(amounts_len: felt, amounts: starkware.cairo.common.uint256.Uint256*)",
                "type": "type_definition"
            },
            "__main__.get_amounts_in.SIZEOF_LOCALS": {
                "type": "const",
                "value": 2
            },
            "__main__.get_amounts_out": {
                "decorators": [
                    "view"
                ],
                "pc": 1175,
                "type": "function"
            },
            "__main__.get_amounts_out.Args": {
                "full_name": "__main__.get_amounts_out.Args",
                "members": {
                    "amountIn": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 0
                    },
                    "path": {
                        "cairo_type": "felt*",
                        "offset": 3
                    },
                    "path_len": {
                        "cairo_type": "felt",
                        "offset": 2
                    }
                },
                "size": 4,
                "type": "struct"
            },
            "__main__.get_amounts_out.ImplicitArgs": {
                "full_name": "__main__.get_amounts_out.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__.get_amounts_out.Return": {
                "cairo_type": "(amounts_len: felt, amounts: starkware.cairo.common.uint256.Uint256*)",
                "type": "type_definition"
            },
            "__main__.get_amounts_out.SIZEOF_LOCALS": {
                "type": "const",
                "value": 2
            },
            "__main__.get_block_timestamp": {
                "destination": "starkware.starknet.common.syscalls.get_block_timestamp",
                "type": "alias"
            },
            "__main__.get_caller_address": {
                "destination": "starkware.starknet.common.syscalls.get_caller_address",
                "type": "alias"
            },
            "__main__.initializer": {
                "decorators": [
                    "external"
                ],
                "pc": 941,
                "type": "function"
            },
            "__main__.initializer.Args": {
                "full_name": "__main__.initializer.Args",
                "members": {
                    "factory": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "proxy_admin": {
                        "cairo_type": "felt",
                        "offset": 1
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "__main__.initializer.ImplicitArgs": {
                "full_name": "__main__.initializer.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__.initializer.Return": {
                "cairo_type": "()",
                "type": "type_definition"
            },
            "__main__.initializer.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "__main__.is_le": {
                "destination": "starkware.cairo.common.math_cmp.is_le",
                "type": "alias"
            },
            "__main__.is_le_felt": {
                "destination": "starkware.cairo.common.math_cmp.is_le_felt",
                "type": "alias"
            },
            "__main__.quote": {
                "decorators": [
                    "view"
                ],
                "pc": 1040,
                "type": "function"
            },
            "__main__.quote.Args": {
                "full_name": "__main__.quote.Args",
                "members": {
                    "amountA": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 0
                    },
                    "reserveA": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 2
                    },
                    "reserveB": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 4
                    }
                },
                "size": 6,
                "type": "struct"
            },
            "__main__.quote.ImplicitArgs": {
                "full_name": "__main__.quote.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__.quote.Return": {
                "cairo_type": "(amountB: starkware.cairo.common.uint256.Uint256)",
                "type": "type_definition"
            },
            "__main__.quote.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "__main__.remove_liquidity": {
                "decorators": [
                    "external"
                ],
                "pc": 1456,
                "type": "function"
            },
            "__main__.remove_liquidity.Args": {
                "full_name": "__main__.remove_liquidity.Args",
                "members": {
                    "amountAMin": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 4
                    },
                    "amountBMin": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 6
                    },
                    "deadline": {
                        "cairo_type": "felt",
                        "offset": 9
                    },
                    "liquidity": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 2
                    },
                    "to": {
                        "cairo_type": "felt",
                        "offset": 8
                    },
                    "tokenA": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "tokenB": {
                        "cairo_type": "felt",
                        "offset": 1
                    }
                },
                "size": 10,
                "type": "struct"
            },
            "__main__.remove_liquidity.ImplicitArgs": {
                "full_name": "__main__.remove_liquidity.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__.remove_liquidity.Return": {
                "cairo_type": "(amountA: starkware.cairo.common.uint256.Uint256, amountB: starkware.cairo.common.uint256.Uint256)",
                "type": "type_definition"
            },
            "__main__.remove_liquidity.SIZEOF_LOCALS": {
                "type": "const",
                "value": 11
            },
            "__main__.sort_tokens": {
                "decorators": [
                    "view"
                ],
                "pc": 1003,
                "type": "function"
            },
            "__main__.sort_tokens.Args": {
                "full_name": "__main__.sort_tokens.Args",
                "members": {
                    "tokenA": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "tokenB": {
                        "cairo_type": "felt",
                        "offset": 1
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "__main__.sort_tokens.ImplicitArgs": {
                "full_name": "__main__.sort_tokens.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__.sort_tokens.Return": {
                "cairo_type": "(token0: felt, token1: felt)",
                "type": "type_definition"
            },
            "__main__.sort_tokens.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "__main__.swap_exact_tokens_for_tokens": {
                "decorators": [
                    "external"
                ],
                "pc": 1584,
                "type": "function"
            },
            "__main__.swap_exact_tokens_for_tokens.Args": {
                "full_name": "__main__.swap_exact_tokens_for_tokens.Args",
                "members": {
                    "amountIn": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 0
                    },
                    "amountOutMin": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 2
                    },
                    "deadline": {
                        "cairo_type": "felt",
                        "offset": 7
                    },
                    "path": {
                        "cairo_type": "felt*",
                        "offset": 5
                    },
                    "path_len": {
                        "cairo_type": "felt",
                        "offset": 4
                    },
                    "to": {
                        "cairo_type": "felt",
                        "offset": 6
                    }
                },
                "size": 8,
                "type": "struct"
            },
            "__main__.swap_exact_tokens_for_tokens.ImplicitArgs": {
                "full_name": "__main__.swap_exact_tokens_for_tokens.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__.swap_exact_tokens_for_tokens.Return": {
                "cairo_type": "(amounts_len: felt, amounts: starkware.cairo.common.uint256.Uint256*)",
                "type": "type_definition"
            },
            "__main__.swap_exact_tokens_for_tokens.SIZEOF_LOCALS": {
                "type": "const",
                "value": 3
            },
            "__main__.swap_tokens_for_exact_tokens": {
                "decorators": [
                    "external"
                ],
                "pc": 1723,
                "type": "function"
            },
            "__main__.swap_tokens_for_exact_tokens.Args": {
                "full_name": "__main__.swap_tokens_for_exact_tokens.Args",
                "members": {
                    "amountInMax": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 2
                    },
                    "amountOut": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 0
                    },
                    "deadline": {
                        "cairo_type": "felt",
                        "offset": 7
                    },
                    "path": {
                        "cairo_type": "felt*",
                        "offset": 5
                    },
                    "path_len": {
                        "cairo_type": "felt",
                        "offset": 4
                    },
                    "to": {
                        "cairo_type": "felt",
                        "offset": 6
                    }
                },
                "size": 8,
                "type": "struct"
            },
            "__main__.swap_tokens_for_exact_tokens.ImplicitArgs": {
                "full_name": "__main__.swap_tokens_for_exact_tokens.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__main__.swap_tokens_for_exact_tokens.Return": {
                "cairo_type": "(amounts_len: felt, amounts: starkware.cairo.common.uint256.Uint256*)",
                "type": "type_definition"
            },
            "__main__.swap_tokens_for_exact_tokens.SIZEOF_LOCALS": {
                "type": "const",
                "value": 3
            },
            "__main__.uint256_checked_add": {
                "destination": "contracts.utils.math.uint256_checked_add",
                "type": "alias"
            },
            "__main__.uint256_checked_mul": {
                "destination": "contracts.utils.math.uint256_checked_mul",
                "type": "alias"
            },
            "__main__.uint256_checked_sub_lt": {
                "destination": "contracts.utils.math.uint256_checked_sub_lt",
                "type": "alias"
            },
            "__main__.uint256_eq": {
                "destination": "starkware.cairo.common.uint256.uint256_eq",
                "type": "alias"
            },
            "__main__.uint256_felt_checked_mul": {
                "destination": "contracts.utils.math.uint256_felt_checked_mul",
                "type": "alias"
            },
            "__main__.uint256_le": {
                "destination": "starkware.cairo.common.uint256.uint256_le",
                "type": "alias"
            },
            "__main__.uint256_lt": {
                "destination": "starkware.cairo.common.uint256.uint256_lt",
                "type": "alias"
            },
            "__main__.uint256_unsigned_div_rem": {
                "destination": "starkware.cairo.common.uint256.uint256_unsigned_div_rem",
                "type": "alias"
            },
            "__wrappers__.add_liquidity": {
                "decorators": [
                    "external"
                ],
                "pc": 1427,
                "type": "function"
            },
            "__wrappers__.add_liquidity.Args": {
                "full_name": "__wrappers__.add_liquidity.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.add_liquidity.ImplicitArgs": {
                "full_name": "__wrappers__.add_liquidity.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.add_liquidity.Return": {
                "cairo_type": "(syscall_ptr: felt*, pedersen_ptr: starkware.cairo.common.cairo_builtins.HashBuiltin*, range_check_ptr: felt, size: felt, retdata: felt*)",
                "type": "type_definition"
            },
            "__wrappers__.add_liquidity.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "__wrappers__.add_liquidity.__wrapped_func": {
                "destination": "__main__.add_liquidity",
                "type": "alias"
            },
            "__wrappers__.add_liquidity_encode_return": {
                "decorators": [],
                "pc": 1413,
                "type": "function"
            },
            "__wrappers__.add_liquidity_encode_return.Args": {
                "full_name": "__wrappers__.add_liquidity_encode_return.Args",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 6
                    },
                    "ret_value": {
                        "cairo_type": "(amountA: starkware.cairo.common.uint256.Uint256, amountB: starkware.cairo.common.uint256.Uint256, liquidity: starkware.cairo.common.uint256.Uint256)",
                        "offset": 0
                    }
                },
                "size": 7,
                "type": "struct"
            },
            "__wrappers__.add_liquidity_encode_return.ImplicitArgs": {
                "full_name": "__wrappers__.add_liquidity_encode_return.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.add_liquidity_encode_return.Return": {
                "cairo_type": "(range_check_ptr: felt, data_len: felt, data: felt*)",
                "type": "type_definition"
            },
            "__wrappers__.add_liquidity_encode_return.SIZEOF_LOCALS": {
                "type": "const",
                "value": 1
            },
            "__wrappers__.add_liquidity_encode_return.memcpy": {
                "destination": "starkware.cairo.common.memcpy.memcpy",
                "type": "alias"
            },
            "__wrappers__.factory": {
                "decorators": [
                    "view"
                ],
                "pc": 988,
                "type": "function"
            },
            "__wrappers__.factory.Args": {
                "full_name": "__wrappers__.factory.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.factory.ImplicitArgs": {
                "full_name": "__wrappers__.factory.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.factory.Return": {
                "cairo_type": "(syscall_ptr: felt*, pedersen_ptr: starkware.cairo.common.cairo_builtins.HashBuiltin*, range_check_ptr: felt, size: felt, retdata: felt*)",
                "type": "type_definition"
            },
            "__wrappers__.factory.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "__wrappers__.factory.__wrapped_func": {
                "destination": "__main__.factory",
                "type": "alias"
            },
            "__wrappers__.factory_encode_return": {
                "decorators": [],
                "pc": 979,
                "type": "function"
            },
            "__wrappers__.factory_encode_return.Args": {
                "full_name": "__wrappers__.factory_encode_return.Args",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 1
                    },
                    "ret_value": {
                        "cairo_type": "(address: felt)",
                        "offset": 0
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "__wrappers__.factory_encode_return.ImplicitArgs": {
                "full_name": "__wrappers__.factory_encode_return.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.factory_encode_return.Return": {
                "cairo_type": "(range_check_ptr: felt, data_len: felt, data: felt*)",
                "type": "type_definition"
            },
            "__wrappers__.factory_encode_return.SIZEOF_LOCALS": {
                "type": "const",
                "value": 1
            },
            "__wrappers__.factory_encode_return.memcpy": {
                "destination": "starkware.cairo.common.memcpy.memcpy",
                "type": "alias"
            },
            "__wrappers__.get_amount_in": {
                "decorators": [
                    "view"
                ],
                "pc": 1152,
                "type": "function"
            },
            "__wrappers__.get_amount_in.Args": {
                "full_name": "__wrappers__.get_amount_in.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.get_amount_in.ImplicitArgs": {
                "full_name": "__wrappers__.get_amount_in.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.get_amount_in.Return": {
                "cairo_type": "(syscall_ptr: felt*, pedersen_ptr: starkware.cairo.common.cairo_builtins.HashBuiltin*, range_check_ptr: felt, size: felt, retdata: felt*)",
                "type": "type_definition"
            },
            "__wrappers__.get_amount_in.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "__wrappers__.get_amount_in.__wrapped_func": {
                "destination": "__main__.get_amount_in",
                "type": "alias"
            },
            "__wrappers__.get_amount_in_encode_return": {
                "decorators": [],
                "pc": 1142,
                "type": "function"
            },
            "__wrappers__.get_amount_in_encode_return.Args": {
                "full_name": "__wrappers__.get_amount_in_encode_return.Args",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "ret_value": {
                        "cairo_type": "(amountIn: starkware.cairo.common.uint256.Uint256)",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__wrappers__.get_amount_in_encode_return.ImplicitArgs": {
                "full_name": "__wrappers__.get_amount_in_encode_return.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.get_amount_in_encode_return.Return": {
                "cairo_type": "(range_check_ptr: felt, data_len: felt, data: felt*)",
                "type": "type_definition"
            },
            "__wrappers__.get_amount_in_encode_return.SIZEOF_LOCALS": {
                "type": "const",
                "value": 1
            },
            "__wrappers__.get_amount_in_encode_return.memcpy": {
                "destination": "starkware.cairo.common.memcpy.memcpy",
                "type": "alias"
            },
            "__wrappers__.get_amount_out": {
                "decorators": [
                    "view"
                ],
                "pc": 1107,
                "type": "function"
            },
            "__wrappers__.get_amount_out.Args": {
                "full_name": "__wrappers__.get_amount_out.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.get_amount_out.ImplicitArgs": {
                "full_name": "__wrappers__.get_amount_out.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.get_amount_out.Return": {
                "cairo_type": "(syscall_ptr: felt*, pedersen_ptr: starkware.cairo.common.cairo_builtins.HashBuiltin*, range_check_ptr: felt, size: felt, retdata: felt*)",
                "type": "type_definition"
            },
            "__wrappers__.get_amount_out.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "__wrappers__.get_amount_out.__wrapped_func": {
                "destination": "__main__.get_amount_out",
                "type": "alias"
            },
            "__wrappers__.get_amount_out_encode_return": {
                "decorators": [],
                "pc": 1097,
                "type": "function"
            },
            "__wrappers__.get_amount_out_encode_return.Args": {
                "full_name": "__wrappers__.get_amount_out_encode_return.Args",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "ret_value": {
                        "cairo_type": "(amountOut: starkware.cairo.common.uint256.Uint256)",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__wrappers__.get_amount_out_encode_return.ImplicitArgs": {
                "full_name": "__wrappers__.get_amount_out_encode_return.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.get_amount_out_encode_return.Return": {
                "cairo_type": "(range_check_ptr: felt, data_len: felt, data: felt*)",
                "type": "type_definition"
            },
            "__wrappers__.get_amount_out_encode_return.SIZEOF_LOCALS": {
                "type": "const",
                "value": 1
            },
            "__wrappers__.get_amount_out_encode_return.memcpy": {
                "destination": "starkware.cairo.common.memcpy.memcpy",
                "type": "alias"
            },
            "__wrappers__.get_amounts_in": {
                "decorators": [
                    "view"
                ],
                "pc": 1302,
                "type": "function"
            },
            "__wrappers__.get_amounts_in.Args": {
                "full_name": "__wrappers__.get_amounts_in.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.get_amounts_in.ImplicitArgs": {
                "full_name": "__wrappers__.get_amounts_in.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.get_amounts_in.Return": {
                "cairo_type": "(syscall_ptr: felt*, pedersen_ptr: starkware.cairo.common.cairo_builtins.HashBuiltin*, range_check_ptr: felt, size: felt, retdata: felt*)",
                "type": "type_definition"
            },
            "__wrappers__.get_amounts_in.SIZEOF_LOCALS": {
                "type": "const",
                "value": 2
            },
            "__wrappers__.get_amounts_in.__wrapped_func": {
                "destination": "__main__.get_amounts_in",
                "type": "alias"
            },
            "__wrappers__.get_amounts_in_encode_return": {
                "decorators": [],
                "pc": 1280,
                "type": "function"
            },
            "__wrappers__.get_amounts_in_encode_return.Args": {
                "full_name": "__wrappers__.get_amounts_in_encode_return.Args",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "ret_value": {
                        "cairo_type": "(amounts_len: felt, amounts: starkware.cairo.common.uint256.Uint256*)",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__wrappers__.get_amounts_in_encode_return.ImplicitArgs": {
                "full_name": "__wrappers__.get_amounts_in_encode_return.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.get_amounts_in_encode_return.Return": {
                "cairo_type": "(range_check_ptr: felt, data_len: felt, data: felt*)",
                "type": "type_definition"
            },
            "__wrappers__.get_amounts_in_encode_return.SIZEOF_LOCALS": {
                "type": "const",
                "value": 3
            },
            "__wrappers__.get_amounts_in_encode_return.memcpy": {
                "destination": "starkware.cairo.common.memcpy.memcpy",
                "type": "alias"
            },
            "__wrappers__.get_amounts_out": {
                "decorators": [
                    "view"
                ],
                "pc": 1222,
                "type": "function"
            },
            "__wrappers__.get_amounts_out.Args": {
                "full_name": "__wrappers__.get_amounts_out.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.get_amounts_out.ImplicitArgs": {
                "full_name": "__wrappers__.get_amounts_out.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.get_amounts_out.Return": {
                "cairo_type": "(syscall_ptr: felt*, pedersen_ptr: starkware.cairo.common.cairo_builtins.HashBuiltin*, range_check_ptr: felt, size: felt, retdata: felt*)",
                "type": "type_definition"
            },
            "__wrappers__.get_amounts_out.SIZEOF_LOCALS": {
                "type": "const",
                "value": 2
            },
            "__wrappers__.get_amounts_out.__wrapped_func": {
                "destination": "__main__.get_amounts_out",
                "type": "alias"
            },
            "__wrappers__.get_amounts_out_encode_return": {
                "decorators": [],
                "pc": 1200,
                "type": "function"
            },
            "__wrappers__.get_amounts_out_encode_return.Args": {
                "full_name": "__wrappers__.get_amounts_out_encode_return.Args",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "ret_value": {
                        "cairo_type": "(amounts_len: felt, amounts: starkware.cairo.common.uint256.Uint256*)",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__wrappers__.get_amounts_out_encode_return.ImplicitArgs": {
                "full_name": "__wrappers__.get_amounts_out_encode_return.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.get_amounts_out_encode_return.Return": {
                "cairo_type": "(range_check_ptr: felt, data_len: felt, data: felt*)",
                "type": "type_definition"
            },
            "__wrappers__.get_amounts_out_encode_return.SIZEOF_LOCALS": {
                "type": "const",
                "value": 3
            },
            "__wrappers__.get_amounts_out_encode_return.memcpy": {
                "destination": "starkware.cairo.common.memcpy.memcpy",
                "type": "alias"
            },
            "__wrappers__.initializer": {
                "decorators": [
                    "external"
                ],
                "pc": 954,
                "type": "function"
            },
            "__wrappers__.initializer.Args": {
                "full_name": "__wrappers__.initializer.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.initializer.ImplicitArgs": {
                "full_name": "__wrappers__.initializer.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.initializer.Return": {
                "cairo_type": "(syscall_ptr: felt*, pedersen_ptr: starkware.cairo.common.cairo_builtins.HashBuiltin*, range_check_ptr: felt, size: felt, retdata: felt*)",
                "type": "type_definition"
            },
            "__wrappers__.initializer.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "__wrappers__.initializer.__wrapped_func": {
                "destination": "__main__.initializer",
                "type": "alias"
            },
            "__wrappers__.initializer_encode_return.memcpy": {
                "destination": "starkware.cairo.common.memcpy.memcpy",
                "type": "alias"
            },
            "__wrappers__.quote": {
                "decorators": [
                    "view"
                ],
                "pc": 1062,
                "type": "function"
            },
            "__wrappers__.quote.Args": {
                "full_name": "__wrappers__.quote.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.quote.ImplicitArgs": {
                "full_name": "__wrappers__.quote.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.quote.Return": {
                "cairo_type": "(syscall_ptr: felt*, pedersen_ptr: starkware.cairo.common.cairo_builtins.HashBuiltin*, range_check_ptr: felt, size: felt, retdata: felt*)",
                "type": "type_definition"
            },
            "__wrappers__.quote.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "__wrappers__.quote.__wrapped_func": {
                "destination": "__main__.quote",
                "type": "alias"
            },
            "__wrappers__.quote_encode_return": {
                "decorators": [],
                "pc": 1052,
                "type": "function"
            },
            "__wrappers__.quote_encode_return.Args": {
                "full_name": "__wrappers__.quote_encode_return.Args",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "ret_value": {
                        "cairo_type": "(amountB: starkware.cairo.common.uint256.Uint256)",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__wrappers__.quote_encode_return.ImplicitArgs": {
                "full_name": "__wrappers__.quote_encode_return.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.quote_encode_return.Return": {
                "cairo_type": "(range_check_ptr: felt, data_len: felt, data: felt*)",
                "type": "type_definition"
            },
            "__wrappers__.quote_encode_return.SIZEOF_LOCALS": {
                "type": "const",
                "value": 1
            },
            "__wrappers__.quote_encode_return.memcpy": {
                "destination": "starkware.cairo.common.memcpy.memcpy",
                "type": "alias"
            },
            "__wrappers__.remove_liquidity": {
                "decorators": [
                    "external"
                ],
                "pc": 1557,
                "type": "function"
            },
            "__wrappers__.remove_liquidity.Args": {
                "full_name": "__wrappers__.remove_liquidity.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.remove_liquidity.ImplicitArgs": {
                "full_name": "__wrappers__.remove_liquidity.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.remove_liquidity.Return": {
                "cairo_type": "(syscall_ptr: felt*, pedersen_ptr: starkware.cairo.common.cairo_builtins.HashBuiltin*, range_check_ptr: felt, size: felt, retdata: felt*)",
                "type": "type_definition"
            },
            "__wrappers__.remove_liquidity.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "__wrappers__.remove_liquidity.__wrapped_func": {
                "destination": "__main__.remove_liquidity",
                "type": "alias"
            },
            "__wrappers__.remove_liquidity_encode_return": {
                "decorators": [],
                "pc": 1545,
                "type": "function"
            },
            "__wrappers__.remove_liquidity_encode_return.Args": {
                "full_name": "__wrappers__.remove_liquidity_encode_return.Args",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 4
                    },
                    "ret_value": {
                        "cairo_type": "(amountA: starkware.cairo.common.uint256.Uint256, amountB: starkware.cairo.common.uint256.Uint256)",
                        "offset": 0
                    }
                },
                "size": 5,
                "type": "struct"
            },
            "__wrappers__.remove_liquidity_encode_return.ImplicitArgs": {
                "full_name": "__wrappers__.remove_liquidity_encode_return.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.remove_liquidity_encode_return.Return": {
                "cairo_type": "(range_check_ptr: felt, data_len: felt, data: felt*)",
                "type": "type_definition"
            },
            "__wrappers__.remove_liquidity_encode_return.SIZEOF_LOCALS": {
                "type": "const",
                "value": 1
            },
            "__wrappers__.remove_liquidity_encode_return.memcpy": {
                "destination": "starkware.cairo.common.memcpy.memcpy",
                "type": "alias"
            },
            "__wrappers__.sort_tokens": {
                "decorators": [
                    "view"
                ],
                "pc": 1021,
                "type": "function"
            },
            "__wrappers__.sort_tokens.Args": {
                "full_name": "__wrappers__.sort_tokens.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.sort_tokens.ImplicitArgs": {
                "full_name": "__wrappers__.sort_tokens.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.sort_tokens.Return": {
                "cairo_type": "(syscall_ptr: felt*, pedersen_ptr: starkware.cairo.common.cairo_builtins.HashBuiltin*, range_check_ptr: felt, size: felt, retdata: felt*)",
                "type": "type_definition"
            },
            "__wrappers__.sort_tokens.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "__wrappers__.sort_tokens.__wrapped_func": {
                "destination": "__main__.sort_tokens",
                "type": "alias"
            },
            "__wrappers__.sort_tokens_encode_return": {
                "decorators": [],
                "pc": 1011,
                "type": "function"
            },
            "__wrappers__.sort_tokens_encode_return.Args": {
                "full_name": "__wrappers__.sort_tokens_encode_return.Args",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "ret_value": {
                        "cairo_type": "(token0: felt, token1: felt)",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__wrappers__.sort_tokens_encode_return.ImplicitArgs": {
                "full_name": "__wrappers__.sort_tokens_encode_return.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.sort_tokens_encode_return.Return": {
                "cairo_type": "(range_check_ptr: felt, data_len: felt, data: felt*)",
                "type": "type_definition"
            },
            "__wrappers__.sort_tokens_encode_return.SIZEOF_LOCALS": {
                "type": "const",
                "value": 1
            },
            "__wrappers__.sort_tokens_encode_return.memcpy": {
                "destination": "starkware.cairo.common.memcpy.memcpy",
                "type": "alias"
            },
            "__wrappers__.swap_exact_tokens_for_tokens": {
                "decorators": [
                    "external"
                ],
                "pc": 1684,
                "type": "function"
            },
            "__wrappers__.swap_exact_tokens_for_tokens.Args": {
                "full_name": "__wrappers__.swap_exact_tokens_for_tokens.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.swap_exact_tokens_for_tokens.ImplicitArgs": {
                "full_name": "__wrappers__.swap_exact_tokens_for_tokens.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.swap_exact_tokens_for_tokens.Return": {
                "cairo_type": "(syscall_ptr: felt*, pedersen_ptr: starkware.cairo.common.cairo_builtins.HashBuiltin*, range_check_ptr: felt, size: felt, retdata: felt*)",
                "type": "type_definition"
            },
            "__wrappers__.swap_exact_tokens_for_tokens.SIZEOF_LOCALS": {
                "type": "const",
                "value": 2
            },
            "__wrappers__.swap_exact_tokens_for_tokens.__wrapped_func": {
                "destination": "__main__.swap_exact_tokens_for_tokens",
                "type": "alias"
            },
            "__wrappers__.swap_exact_tokens_for_tokens_encode_return": {
                "decorators": [],
                "pc": 1662,
                "type": "function"
            },
            "__wrappers__.swap_exact_tokens_for_tokens_encode_return.Args": {
                "full_name": "__wrappers__.swap_exact_tokens_for_tokens_encode_return.Args",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "ret_value": {
                        "cairo_type": "(amounts_len: felt, amounts: starkware.cairo.common.uint256.Uint256*)",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__wrappers__.swap_exact_tokens_for_tokens_encode_return.ImplicitArgs": {
                "full_name": "__wrappers__.swap_exact_tokens_for_tokens_encode_return.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.swap_exact_tokens_for_tokens_encode_return.Return": {
                "cairo_type": "(range_check_ptr: felt, data_len: felt, data: felt*)",
                "type": "type_definition"
            },
            "__wrappers__.swap_exact_tokens_for_tokens_encode_return.SIZEOF_LOCALS": {
                "type": "const",
                "value": 3
            },
            "__wrappers__.swap_exact_tokens_for_tokens_encode_return.memcpy": {
                "destination": "starkware.cairo.common.memcpy.memcpy",
                "type": "alias"
            },
            "__wrappers__.swap_tokens_for_exact_tokens": {
                "decorators": [
                    "external"
                ],
                "pc": 1811,
                "type": "function"
            },
            "__wrappers__.swap_tokens_for_exact_tokens.Args": {
                "full_name": "__wrappers__.swap_tokens_for_exact_tokens.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.swap_tokens_for_exact_tokens.ImplicitArgs": {
                "full_name": "__wrappers__.swap_tokens_for_exact_tokens.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.swap_tokens_for_exact_tokens.Return": {
                "cairo_type": "(syscall_ptr: felt*, pedersen_ptr: starkware.cairo.common.cairo_builtins.HashBuiltin*, range_check_ptr: felt, size: felt, retdata: felt*)",
                "type": "type_definition"
            },
            "__wrappers__.swap_tokens_for_exact_tokens.SIZEOF_LOCALS": {
                "type": "const",
                "value": 2
            },
            "__wrappers__.swap_tokens_for_exact_tokens.__wrapped_func": {
                "destination": "__main__.swap_tokens_for_exact_tokens",
                "type": "alias"
            },
            "__wrappers__.swap_tokens_for_exact_tokens_encode_return": {
                "decorators": [],
                "pc": 1789,
                "type": "function"
            },
            "__wrappers__.swap_tokens_for_exact_tokens_encode_return.Args": {
                "full_name": "__wrappers__.swap_tokens_for_exact_tokens_encode_return.Args",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "ret_value": {
                        "cairo_type": "(amounts_len: felt, amounts: starkware.cairo.common.uint256.Uint256*)",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "__wrappers__.swap_tokens_for_exact_tokens_encode_return.ImplicitArgs": {
                "full_name": "__wrappers__.swap_tokens_for_exact_tokens_encode_return.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "__wrappers__.swap_tokens_for_exact_tokens_encode_return.Return": {
                "cairo_type": "(range_check_ptr: felt, data_len: felt, data: felt*)",
                "type": "type_definition"
            },
            "__wrappers__.swap_tokens_for_exact_tokens_encode_return.SIZEOF_LOCALS": {
                "type": "const",
                "value": 3
            },
            "__wrappers__.swap_tokens_for_exact_tokens_encode_return.memcpy": {
                "destination": "starkware.cairo.common.memcpy.memcpy",
                "type": "alias"
            },
            "contracts.utils.math.HashBuiltin": {
                "destination": "starkware.cairo.common.cairo_builtins.HashBuiltin",
                "type": "alias"
            },
            "contracts.utils.math.SignatureBuiltin": {
                "destination": "starkware.cairo.common.cairo_builtins.SignatureBuiltin",
                "type": "alias"
            },
            "contracts.utils.math.Uint256": {
                "destination": "starkware.cairo.common.uint256.Uint256",
                "type": "alias"
            },
            "contracts.utils.math.assert_not_zero": {
                "destination": "starkware.cairo.common.math.assert_not_zero",
                "type": "alias"
            },
            "contracts.utils.math.uint256_add": {
                "destination": "starkware.cairo.common.uint256.uint256_add",
                "type": "alias"
            },
            "contracts.utils.math.uint256_check": {
                "destination": "starkware.cairo.common.uint256.uint256_check",
                "type": "alias"
            },
            "contracts.utils.math.uint256_checked_add": {
                "decorators": [],
                "pc": 485,
                "type": "function"
            },
            "contracts.utils.math.uint256_checked_add.Args": {
                "full_name": "contracts.utils.math.uint256_checked_add.Args",
                "members": {
                    "a": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 0
                    },
                    "b": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 2
                    }
                },
                "size": 4,
                "type": "struct"
            },
            "contracts.utils.math.uint256_checked_add.ImplicitArgs": {
                "full_name": "contracts.utils.math.uint256_checked_add.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "contracts.utils.math.uint256_checked_add.Return": {
                "cairo_type": "(c: starkware.cairo.common.uint256.Uint256)",
                "type": "type_definition"
            },
            "contracts.utils.math.uint256_checked_add.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "contracts.utils.math.uint256_checked_mul": {
                "decorators": [],
                "pc": 540,
                "type": "function"
            },
            "contracts.utils.math.uint256_checked_mul.Args": {
                "full_name": "contracts.utils.math.uint256_checked_mul.Args",
                "members": {
                    "a": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 0
                    },
                    "b": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 2
                    }
                },
                "size": 4,
                "type": "struct"
            },
            "contracts.utils.math.uint256_checked_mul.ImplicitArgs": {
                "full_name": "contracts.utils.math.uint256_checked_mul.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "contracts.utils.math.uint256_checked_mul.Return": {
                "cairo_type": "(c: starkware.cairo.common.uint256.Uint256)",
                "type": "type_definition"
            },
            "contracts.utils.math.uint256_checked_mul.SIZEOF_LOCALS": {
                "type": "const",
                "value": 2
            },
            "contracts.utils.math.uint256_checked_sub_lt": {
                "decorators": [],
                "pc": 508,
                "type": "function"
            },
            "contracts.utils.math.uint256_checked_sub_lt.Args": {
                "full_name": "contracts.utils.math.uint256_checked_sub_lt.Args",
                "members": {
                    "a": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 0
                    },
                    "b": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 2
                    }
                },
                "size": 4,
                "type": "struct"
            },
            "contracts.utils.math.uint256_checked_sub_lt.ImplicitArgs": {
                "full_name": "contracts.utils.math.uint256_checked_sub_lt.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "contracts.utils.math.uint256_checked_sub_lt.Return": {
                "cairo_type": "(c: starkware.cairo.common.uint256.Uint256)",
                "type": "type_definition"
            },
            "contracts.utils.math.uint256_checked_sub_lt.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "contracts.utils.math.uint256_eq": {
                "destination": "starkware.cairo.common.uint256.uint256_eq",
                "type": "alias"
            },
            "contracts.utils.math.uint256_felt_checked_mul": {
                "decorators": [],
                "pc": 576,
                "type": "function"
            },
            "contracts.utils.math.uint256_felt_checked_mul.Args": {
                "full_name": "contracts.utils.math.uint256_felt_checked_mul.Args",
                "members": {
                    "a": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 0
                    },
                    "b": {
                        "cairo_type": "felt",
                        "offset": 2
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "contracts.utils.math.uint256_felt_checked_mul.ImplicitArgs": {
                "full_name": "contracts.utils.math.uint256_felt_checked_mul.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "contracts.utils.math.uint256_felt_checked_mul.Return": {
                "cairo_type": "(c: starkware.cairo.common.uint256.Uint256)",
                "type": "type_definition"
            },
            "contracts.utils.math.uint256_felt_checked_mul.SIZEOF_LOCALS": {
                "type": "const",
                "value": 2
            },
            "contracts.utils.math.uint256_le": {
                "destination": "starkware.cairo.common.uint256.uint256_le",
                "type": "alias"
            },
            "contracts.utils.math.uint256_lt": {
                "destination": "starkware.cairo.common.uint256.uint256_lt",
                "type": "alias"
            },
            "contracts.utils.math.uint256_mul": {
                "destination": "starkware.cairo.common.uint256.uint256_mul",
                "type": "alias"
            },
            "contracts.utils.math.uint256_sub": {
                "destination": "starkware.cairo.common.uint256.uint256_sub",
                "type": "alias"
            },
            "openzeppelin.upgrades.library.AdminChanged": {
                "type": "namespace"
            },
            "openzeppelin.upgrades.library.AdminChanged.Args": {
                "full_name": "openzeppelin.upgrades.library.AdminChanged.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.AdminChanged.ImplicitArgs": {
                "full_name": "openzeppelin.upgrades.library.AdminChanged.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.AdminChanged.Return": {
                "cairo_type": "()",
                "type": "type_definition"
            },
            "openzeppelin.upgrades.library.AdminChanged.SELECTOR": {
                "type": "const",
                "value": 509549417648223859179470485411643110789099628436608877343164389163677637013
            },
            "openzeppelin.upgrades.library.AdminChanged.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "openzeppelin.upgrades.library.AdminChanged.alloc": {
                "destination": "starkware.cairo.common.alloc.alloc",
                "type": "alias"
            },
            "openzeppelin.upgrades.library.AdminChanged.emit": {
                "decorators": [],
                "pc": 609,
                "type": "function"
            },
            "openzeppelin.upgrades.library.AdminChanged.emit.Args": {
                "full_name": "openzeppelin.upgrades.library.AdminChanged.emit.Args",
                "members": {
                    "newAdmin": {
                        "cairo_type": "felt",
                        "offset": 1
                    },
                    "previousAdmin": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.AdminChanged.emit.ImplicitArgs": {
                "full_name": "openzeppelin.upgrades.library.AdminChanged.emit.ImplicitArgs",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 1
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.AdminChanged.emit.Return": {
                "cairo_type": "()",
                "type": "type_definition"
            },
            "openzeppelin.upgrades.library.AdminChanged.emit.SIZEOF_LOCALS": {
                "type": "const",
                "value": 2
            },
            "openzeppelin.upgrades.library.AdminChanged.emit_event": {
                "destination": "starkware.starknet.common.syscalls.emit_event",
                "type": "alias"
            },
            "openzeppelin.upgrades.library.AdminChanged.memcpy": {
                "destination": "starkware.cairo.common.memcpy.memcpy",
                "type": "alias"
            },
            "openzeppelin.upgrades.library.FALSE": {
                "destination": "starkware.cairo.common.bool.FALSE",
                "type": "alias"
            },
            "openzeppelin.upgrades.library.HashBuiltin": {
                "destination": "starkware.cairo.common.cairo_builtins.HashBuiltin",
                "type": "alias"
            },
            "openzeppelin.upgrades.library.Proxy": {
                "type": "namespace"
            },
            "openzeppelin.upgrades.library.Proxy.Args": {
                "full_name": "openzeppelin.upgrades.library.Proxy.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.Proxy.ImplicitArgs": {
                "full_name": "openzeppelin.upgrades.library.Proxy.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.Proxy.Return": {
                "cairo_type": "()",
                "type": "type_definition"
            },
            "openzeppelin.upgrades.library.Proxy.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "openzeppelin.upgrades.library.Proxy._set_admin": {
                "decorators": [],
                "pc": 718,
                "type": "function"
            },
            "openzeppelin.upgrades.library.Proxy._set_admin.Args": {
                "full_name": "openzeppelin.upgrades.library.Proxy._set_admin.Args",
                "members": {
                    "new_admin": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.Proxy._set_admin.ImplicitArgs": {
                "full_name": "openzeppelin.upgrades.library.Proxy._set_admin.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.Proxy._set_admin.Return": {
                "cairo_type": "()",
                "type": "type_definition"
            },
            "openzeppelin.upgrades.library.Proxy._set_admin.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "openzeppelin.upgrades.library.Proxy.get_admin": {
                "decorators": [],
                "pc": 712,
                "type": "function"
            },
            "openzeppelin.upgrades.library.Proxy.get_admin.Args": {
                "full_name": "openzeppelin.upgrades.library.Proxy.get_admin.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.Proxy.get_admin.ImplicitArgs": {
                "full_name": "openzeppelin.upgrades.library.Proxy.get_admin.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.Proxy.get_admin.Return": {
                "cairo_type": "(admin: felt)",
                "type": "type_definition"
            },
            "openzeppelin.upgrades.library.Proxy.get_admin.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "openzeppelin.upgrades.library.Proxy.initializer": {
                "decorators": [],
                "pc": 694,
                "type": "function"
            },
            "openzeppelin.upgrades.library.Proxy.initializer.Args": {
                "full_name": "openzeppelin.upgrades.library.Proxy.initializer.Args",
                "members": {
                    "proxy_admin": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.Proxy.initializer.ImplicitArgs": {
                "full_name": "openzeppelin.upgrades.library.Proxy.initializer.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.Proxy.initializer.Return": {
                "cairo_type": "()",
                "type": "type_definition"
            },
            "openzeppelin.upgrades.library.Proxy.initializer.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "openzeppelin.upgrades.library.Proxy_admin": {
                "type": "namespace"
            },
            "openzeppelin.upgrades.library.Proxy_admin.Args": {
                "full_name": "openzeppelin.upgrades.library.Proxy_admin.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.Proxy_admin.HashBuiltin": {
                "destination": "starkware.cairo.common.cairo_builtins.HashBuiltin",
                "type": "alias"
            },
            "openzeppelin.upgrades.library.Proxy_admin.ImplicitArgs": {
                "full_name": "openzeppelin.upgrades.library.Proxy_admin.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.Proxy_admin.Return": {
                "cairo_type": "()",
                "type": "type_definition"
            },
            "openzeppelin.upgrades.library.Proxy_admin.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "openzeppelin.upgrades.library.Proxy_admin.addr": {
                "decorators": [],
                "pc": 634,
                "type": "function"
            },
            "openzeppelin.upgrades.library.Proxy_admin.addr.Args": {
                "full_name": "openzeppelin.upgrades.library.Proxy_admin.addr.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.Proxy_admin.addr.ImplicitArgs": {
                "full_name": "openzeppelin.upgrades.library.Proxy_admin.addr.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 0
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 1
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.Proxy_admin.addr.Return": {
                "cairo_type": "(res: felt)",
                "type": "type_definition"
            },
            "openzeppelin.upgrades.library.Proxy_admin.addr.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "openzeppelin.upgrades.library.Proxy_admin.hash2": {
                "destination": "starkware.cairo.common.hash.hash2",
                "type": "alias"
            },
            "openzeppelin.upgrades.library.Proxy_admin.normalize_address": {
                "destination": "starkware.starknet.common.storage.normalize_address",
                "type": "alias"
            },
            "openzeppelin.upgrades.library.Proxy_admin.read": {
                "decorators": [],
                "pc": 639,
                "type": "function"
            },
            "openzeppelin.upgrades.library.Proxy_admin.read.Args": {
                "full_name": "openzeppelin.upgrades.library.Proxy_admin.read.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.Proxy_admin.read.ImplicitArgs": {
                "full_name": "openzeppelin.upgrades.library.Proxy_admin.read.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.Proxy_admin.read.Return": {
                "cairo_type": "(admin: felt)",
                "type": "type_definition"
            },
            "openzeppelin.upgrades.library.Proxy_admin.read.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "openzeppelin.upgrades.library.Proxy_admin.storage_read": {
                "destination": "starkware.starknet.common.syscalls.storage_read",
                "type": "alias"
            },
            "openzeppelin.upgrades.library.Proxy_admin.storage_write": {
                "destination": "starkware.starknet.common.syscalls.storage_write",
                "type": "alias"
            },
            "openzeppelin.upgrades.library.Proxy_admin.write": {
                "decorators": [],
                "pc": 652,
                "type": "function"
            },
            "openzeppelin.upgrades.library.Proxy_admin.write.Args": {
                "full_name": "openzeppelin.upgrades.library.Proxy_admin.write.Args",
                "members": {
                    "value": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.Proxy_admin.write.ImplicitArgs": {
                "full_name": "openzeppelin.upgrades.library.Proxy_admin.write.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.Proxy_admin.write.Return": {
                "cairo_type": "()",
                "type": "type_definition"
            },
            "openzeppelin.upgrades.library.Proxy_admin.write.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "openzeppelin.upgrades.library.Proxy_implementation_hash": {
                "type": "namespace"
            },
            "openzeppelin.upgrades.library.Proxy_implementation_hash.Args": {
                "full_name": "openzeppelin.upgrades.library.Proxy_implementation_hash.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.Proxy_implementation_hash.HashBuiltin": {
                "destination": "starkware.cairo.common.cairo_builtins.HashBuiltin",
                "type": "alias"
            },
            "openzeppelin.upgrades.library.Proxy_implementation_hash.ImplicitArgs": {
                "full_name": "openzeppelin.upgrades.library.Proxy_implementation_hash.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.Proxy_implementation_hash.Return": {
                "cairo_type": "()",
                "type": "type_definition"
            },
            "openzeppelin.upgrades.library.Proxy_implementation_hash.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "openzeppelin.upgrades.library.Proxy_implementation_hash.hash2": {
                "destination": "starkware.cairo.common.hash.hash2",
                "type": "alias"
            },
            "openzeppelin.upgrades.library.Proxy_implementation_hash.normalize_address": {
                "destination": "starkware.starknet.common.storage.normalize_address",
                "type": "alias"
            },
            "openzeppelin.upgrades.library.Proxy_implementation_hash.storage_read": {
                "destination": "starkware.starknet.common.syscalls.storage_read",
                "type": "alias"
            },
            "openzeppelin.upgrades.library.Proxy_implementation_hash.storage_write": {
                "destination": "starkware.starknet.common.syscalls.storage_write",
                "type": "alias"
            },
            "openzeppelin.upgrades.library.Proxy_initialized": {
                "type": "namespace"
            },
            "openzeppelin.upgrades.library.Proxy_initialized.Args": {
                "full_name": "openzeppelin.upgrades.library.Proxy_initialized.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.Proxy_initialized.HashBuiltin": {
                "destination": "starkware.cairo.common.cairo_builtins.HashBuiltin",
                "type": "alias"
            },
            "openzeppelin.upgrades.library.Proxy_initialized.ImplicitArgs": {
                "full_name": "openzeppelin.upgrades.library.Proxy_initialized.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.Proxy_initialized.Return": {
                "cairo_type": "()",
                "type": "type_definition"
            },
            "openzeppelin.upgrades.library.Proxy_initialized.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "openzeppelin.upgrades.library.Proxy_initialized.addr": {
                "decorators": [],
                "pc": 664,
                "type": "function"
            },
            "openzeppelin.upgrades.library.Proxy_initialized.addr.Args": {
                "full_name": "openzeppelin.upgrades.library.Proxy_initialized.addr.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.Proxy_initialized.addr.ImplicitArgs": {
                "full_name": "openzeppelin.upgrades.library.Proxy_initialized.addr.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 0
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 1
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.Proxy_initialized.addr.Return": {
                "cairo_type": "(res: felt)",
                "type": "type_definition"
            },
            "openzeppelin.upgrades.library.Proxy_initialized.addr.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "openzeppelin.upgrades.library.Proxy_initialized.hash2": {
                "destination": "starkware.cairo.common.hash.hash2",
                "type": "alias"
            },
            "openzeppelin.upgrades.library.Proxy_initialized.normalize_address": {
                "destination": "starkware.starknet.common.storage.normalize_address",
                "type": "alias"
            },
            "openzeppelin.upgrades.library.Proxy_initialized.read": {
                "decorators": [],
                "pc": 669,
                "type": "function"
            },
            "openzeppelin.upgrades.library.Proxy_initialized.read.Args": {
                "full_name": "openzeppelin.upgrades.library.Proxy_initialized.read.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.Proxy_initialized.read.ImplicitArgs": {
                "full_name": "openzeppelin.upgrades.library.Proxy_initialized.read.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.Proxy_initialized.read.Return": {
                "cairo_type": "(initialized: felt)",
                "type": "type_definition"
            },
            "openzeppelin.upgrades.library.Proxy_initialized.read.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "openzeppelin.upgrades.library.Proxy_initialized.storage_read": {
                "destination": "starkware.starknet.common.syscalls.storage_read",
                "type": "alias"
            },
            "openzeppelin.upgrades.library.Proxy_initialized.storage_write": {
                "destination": "starkware.starknet.common.syscalls.storage_write",
                "type": "alias"
            },
            "openzeppelin.upgrades.library.Proxy_initialized.write": {
                "decorators": [],
                "pc": 682,
                "type": "function"
            },
            "openzeppelin.upgrades.library.Proxy_initialized.write.Args": {
                "full_name": "openzeppelin.upgrades.library.Proxy_initialized.write.Args",
                "members": {
                    "value": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.Proxy_initialized.write.ImplicitArgs": {
                "full_name": "openzeppelin.upgrades.library.Proxy_initialized.write.ImplicitArgs",
                "members": {
                    "pedersen_ptr": {
                        "cairo_type": "starkware.cairo.common.cairo_builtins.HashBuiltin*",
                        "offset": 1
                    },
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.Proxy_initialized.write.Return": {
                "cairo_type": "()",
                "type": "type_definition"
            },
            "openzeppelin.upgrades.library.Proxy_initialized.write.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "openzeppelin.upgrades.library.TRUE": {
                "destination": "starkware.cairo.common.bool.TRUE",
                "type": "alias"
            },
            "openzeppelin.upgrades.library.Upgraded": {
                "type": "namespace"
            },
            "openzeppelin.upgrades.library.Upgraded.Args": {
                "full_name": "openzeppelin.upgrades.library.Upgraded.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.Upgraded.ImplicitArgs": {
                "full_name": "openzeppelin.upgrades.library.Upgraded.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "openzeppelin.upgrades.library.Upgraded.Return": {
                "cairo_type": "()",
                "type": "type_definition"
            },
            "openzeppelin.upgrades.library.Upgraded.SELECTOR": {
                "type": "const",
                "value": 1291924484015741933195105472014600539495279993452337828418116470835452386035
            },
            "openzeppelin.upgrades.library.Upgraded.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "openzeppelin.upgrades.library.Upgraded.alloc": {
                "destination": "starkware.cairo.common.alloc.alloc",
                "type": "alias"
            },
            "openzeppelin.upgrades.library.Upgraded.emit_event": {
                "destination": "starkware.starknet.common.syscalls.emit_event",
                "type": "alias"
            },
            "openzeppelin.upgrades.library.Upgraded.memcpy": {
                "destination": "starkware.cairo.common.memcpy.memcpy",
                "type": "alias"
            },
            "openzeppelin.upgrades.library.assert_not_zero": {
                "destination": "starkware.cairo.common.math.assert_not_zero",
                "type": "alias"
            },
            "openzeppelin.upgrades.library.get_caller_address": {
                "destination": "starkware.starknet.common.syscalls.get_caller_address",
                "type": "alias"
            },
            "starkware.cairo.common.alloc.alloc": {
                "decorators": [],
                "pc": 0,
                "type": "function"
            },
            "starkware.cairo.common.alloc.alloc.Args": {
                "full_name": "starkware.cairo.common.alloc.alloc.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "starkware.cairo.common.alloc.alloc.ImplicitArgs": {
                "full_name": "starkware.cairo.common.alloc.alloc.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "starkware.cairo.common.alloc.alloc.Return": {
                "cairo_type": "(ptr: felt*)",
                "type": "type_definition"
            },
            "starkware.cairo.common.alloc.alloc.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "starkware.cairo.common.bitwise.ALL_ONES": {
                "type": "const",
                "value": -106710729501573572985208420194530329073740042555888586719234
            },
            "starkware.cairo.common.bitwise.BitwiseBuiltin": {
                "destination": "starkware.cairo.common.cairo_builtins.BitwiseBuiltin",
                "type": "alias"
            },
            "starkware.cairo.common.bool.FALSE": {
                "type": "const",
                "value": 0
            },
            "starkware.cairo.common.bool.TRUE": {
                "type": "const",
                "value": 1
            },
            "starkware.cairo.common.cairo_builtins.BitwiseBuiltin": {
                "full_name": "starkware.cairo.common.cairo_builtins.BitwiseBuiltin",
                "members": {
                    "x": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "x_and_y": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "x_or_y": {
                        "cairo_type": "felt",
                        "offset": 4
                    },
                    "x_xor_y": {
                        "cairo_type": "felt",
                        "offset": 3
                    },
                    "y": {
                        "cairo_type": "felt",
                        "offset": 1
                    }
                },
                "size": 5,
                "type": "struct"
            },
            "starkware.cairo.common.cairo_builtins.EcOpBuiltin": {
                "full_name": "starkware.cairo.common.cairo_builtins.EcOpBuiltin",
                "members": {
                    "m": {
                        "cairo_type": "felt",
                        "offset": 4
                    },
                    "p": {
                        "cairo_type": "starkware.cairo.common.ec_point.EcPoint",
                        "offset": 0
                    },
                    "q": {
                        "cairo_type": "starkware.cairo.common.ec_point.EcPoint",
                        "offset": 2
                    },
                    "r": {
                        "cairo_type": "starkware.cairo.common.ec_point.EcPoint",
                        "offset": 5
                    }
                },
                "size": 7,
                "type": "struct"
            },
            "starkware.cairo.common.cairo_builtins.EcPoint": {
                "destination": "starkware.cairo.common.ec_point.EcPoint",
                "type": "alias"
            },
            "starkware.cairo.common.cairo_builtins.HashBuiltin": {
                "full_name": "starkware.cairo.common.cairo_builtins.HashBuiltin",
                "members": {
                    "result": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "x": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "y": {
                        "cairo_type": "felt",
                        "offset": 1
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "starkware.cairo.common.cairo_builtins.KeccakBuiltin": {
                "full_name": "starkware.cairo.common.cairo_builtins.KeccakBuiltin",
                "members": {
                    "input": {
                        "cairo_type": "starkware.cairo.common.keccak_state.KeccakBuiltinState",
                        "offset": 0
                    },
                    "output": {
                        "cairo_type": "starkware.cairo.common.keccak_state.KeccakBuiltinState",
                        "offset": 8
                    }
                },
                "size": 16,
                "type": "struct"
            },
            "starkware.cairo.common.cairo_builtins.KeccakBuiltinState": {
                "destination": "starkware.cairo.common.keccak_state.KeccakBuiltinState",
                "type": "alias"
            },
            "starkware.cairo.common.cairo_builtins.SignatureBuiltin": {
                "full_name": "starkware.cairo.common.cairo_builtins.SignatureBuiltin",
                "members": {
                    "message": {
                        "cairo_type": "felt",
                        "offset": 1
                    },
                    "pub_key": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "starkware.cairo.common.dict_access.DictAccess": {
                "full_name": "starkware.cairo.common.dict_access.DictAccess",
                "members": {
                    "key": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "new_value": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "prev_value": {
                        "cairo_type": "felt",
                        "offset": 1
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "starkware.cairo.common.ec_point.EcPoint": {
                "full_name": "starkware.cairo.common.ec_point.EcPoint",
                "members": {
                    "x": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "y": {
                        "cairo_type": "felt",
                        "offset": 1
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "starkware.cairo.common.hash.HashBuiltin": {
                "destination": "starkware.cairo.common.cairo_builtins.HashBuiltin",
                "type": "alias"
            },
            "starkware.cairo.common.keccak_state.KeccakBuiltinState": {
                "full_name": "starkware.cairo.common.keccak_state.KeccakBuiltinState",
                "members": {
                    "s0": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "s1": {
                        "cairo_type": "felt",
                        "offset": 1
                    },
                    "s2": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "s3": {
                        "cairo_type": "felt",
                        "offset": 3
                    },
                    "s4": {
                        "cairo_type": "felt",
                        "offset": 4
                    },
                    "s5": {
                        "cairo_type": "felt",
                        "offset": 5
                    },
                    "s6": {
                        "cairo_type": "felt",
                        "offset": 6
                    },
                    "s7": {
                        "cairo_type": "felt",
                        "offset": 7
                    }
                },
                "size": 8,
                "type": "struct"
            },
            "starkware.cairo.common.math.FALSE": {
                "destination": "starkware.cairo.common.bool.FALSE",
                "type": "alias"
            },
            "starkware.cairo.common.math.TRUE": {
                "destination": "starkware.cairo.common.bool.TRUE",
                "type": "alias"
            },
            "starkware.cairo.common.math.assert_le": {
                "decorators": [],
                "pc": 33,
                "type": "function"
            },
            "starkware.cairo.common.math.assert_le.Args": {
                "full_name": "starkware.cairo.common.math.assert_le.Args",
                "members": {
                    "a": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "b": {
                        "cairo_type": "felt",
                        "offset": 1
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "starkware.cairo.common.math.assert_le.ImplicitArgs": {
                "full_name": "starkware.cairo.common.math.assert_le.ImplicitArgs",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.cairo.common.math.assert_le.Return": {
                "cairo_type": "()",
                "type": "type_definition"
            },
            "starkware.cairo.common.math.assert_le.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "starkware.cairo.common.math.assert_le_felt": {
                "decorators": [
                    "known_ap_change"
                ],
                "pc": 38,
                "type": "function"
            },
            "starkware.cairo.common.math.assert_le_felt.Args": {
                "full_name": "starkware.cairo.common.math.assert_le_felt.Args",
                "members": {
                    "a": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "b": {
                        "cairo_type": "felt",
                        "offset": 1
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "starkware.cairo.common.math.assert_le_felt.ImplicitArgs": {
                "full_name": "starkware.cairo.common.math.assert_le_felt.ImplicitArgs",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.cairo.common.math.assert_le_felt.PRIME_OVER_2_HIGH": {
                "type": "const",
                "value": 5316911983139663648412552867652567041
            },
            "starkware.cairo.common.math.assert_le_felt.PRIME_OVER_3_HIGH": {
                "type": "const",
                "value": 3544607988759775765608368578435044694
            },
            "starkware.cairo.common.math.assert_le_felt.Return": {
                "cairo_type": "()",
                "type": "type_definition"
            },
            "starkware.cairo.common.math.assert_le_felt.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "starkware.cairo.common.math.assert_le_felt.a": {
                "cairo_type": "felt",
                "full_name": "starkware.cairo.common.math.assert_le_felt.a",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 6,
                            "offset": 0
                        },
                        "pc": 38,
                        "value": "[cast(fp + (-4), felt*)]"
                    }
                ],
                "type": "reference"
            },
            "starkware.cairo.common.math.assert_le_felt.b": {
                "cairo_type": "felt",
                "full_name": "starkware.cairo.common.math.assert_le_felt.b",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 6,
                            "offset": 0
                        },
                        "pc": 38,
                        "value": "[cast(fp + (-3), felt*)]"
                    }
                ],
                "type": "reference"
            },
            "starkware.cairo.common.math.assert_le_felt.range_check_ptr": {
                "cairo_type": "felt",
                "full_name": "starkware.cairo.common.math.assert_le_felt.range_check_ptr",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 6,
                            "offset": 0
                        },
                        "pc": 38,
                        "value": "[cast(fp + (-5), felt*)]"
                    },
                    {
                        "ap_tracking_data": {
                            "group": 6,
                            "offset": 8
                        },
                        "pc": 48,
                        "value": "cast([fp + (-5)] + 4, felt)"
                    }
                ],
                "type": "reference"
            },
            "starkware.cairo.common.math.assert_le_felt.skip_exclude_a": {
                "pc": 62,
                "type": "label"
            },
            "starkware.cairo.common.math.assert_le_felt.skip_exclude_b_minus_a": {
                "pc": 74,
                "type": "label"
            },
            "starkware.cairo.common.math.assert_lt_felt": {
                "decorators": [
                    "known_ap_change"
                ],
                "pc": 83,
                "type": "function"
            },
            "starkware.cairo.common.math.assert_lt_felt.Args": {
                "full_name": "starkware.cairo.common.math.assert_lt_felt.Args",
                "members": {
                    "a": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "b": {
                        "cairo_type": "felt",
                        "offset": 1
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "starkware.cairo.common.math.assert_lt_felt.ImplicitArgs": {
                "full_name": "starkware.cairo.common.math.assert_lt_felt.ImplicitArgs",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.cairo.common.math.assert_lt_felt.Return": {
                "cairo_type": "()",
                "type": "type_definition"
            },
            "starkware.cairo.common.math.assert_lt_felt.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "starkware.cairo.common.math.assert_lt_felt.a": {
                "cairo_type": "felt",
                "full_name": "starkware.cairo.common.math.assert_lt_felt.a",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 7,
                            "offset": 0
                        },
                        "pc": 83,
                        "value": "[cast(fp + (-4), felt*)]"
                    }
                ],
                "type": "reference"
            },
            "starkware.cairo.common.math.assert_lt_felt.b": {
                "cairo_type": "felt",
                "full_name": "starkware.cairo.common.math.assert_lt_felt.b",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 7,
                            "offset": 0
                        },
                        "pc": 83,
                        "value": "[cast(fp + (-3), felt*)]"
                    }
                ],
                "type": "reference"
            },
            "starkware.cairo.common.math.assert_nn": {
                "decorators": [],
                "pc": 29,
                "type": "function"
            },
            "starkware.cairo.common.math.assert_nn.Args": {
                "full_name": "starkware.cairo.common.math.assert_nn.Args",
                "members": {
                    "a": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.cairo.common.math.assert_nn.ImplicitArgs": {
                "full_name": "starkware.cairo.common.math.assert_nn.ImplicitArgs",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.cairo.common.math.assert_nn.Return": {
                "cairo_type": "()",
                "type": "type_definition"
            },
            "starkware.cairo.common.math.assert_nn.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "starkware.cairo.common.math.assert_nn.a": {
                "cairo_type": "felt",
                "full_name": "starkware.cairo.common.math.assert_nn.a",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 4,
                            "offset": 0
                        },
                        "pc": 29,
                        "value": "[cast(fp + (-3), felt*)]"
                    }
                ],
                "type": "reference"
            },
            "starkware.cairo.common.math.assert_not_equal": {
                "decorators": [],
                "pc": 23,
                "type": "function"
            },
            "starkware.cairo.common.math.assert_not_equal.Args": {
                "full_name": "starkware.cairo.common.math.assert_not_equal.Args",
                "members": {
                    "a": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "b": {
                        "cairo_type": "felt",
                        "offset": 1
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "starkware.cairo.common.math.assert_not_equal.ImplicitArgs": {
                "full_name": "starkware.cairo.common.math.assert_not_equal.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "starkware.cairo.common.math.assert_not_equal.Return": {
                "cairo_type": "()",
                "type": "type_definition"
            },
            "starkware.cairo.common.math.assert_not_equal.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "starkware.cairo.common.math.assert_not_equal.a": {
                "cairo_type": "felt",
                "full_name": "starkware.cairo.common.math.assert_not_equal.a",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 3,
                            "offset": 0
                        },
                        "pc": 23,
                        "value": "[cast(fp + (-4), felt*)]"
                    }
                ],
                "type": "reference"
            },
            "starkware.cairo.common.math.assert_not_equal.b": {
                "cairo_type": "felt",
                "full_name": "starkware.cairo.common.math.assert_not_equal.b",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 3,
                            "offset": 0
                        },
                        "pc": 23,
                        "value": "[cast(fp + (-3), felt*)]"
                    }
                ],
                "type": "reference"
            },
            "starkware.cairo.common.math.assert_not_zero": {
                "decorators": [],
                "pc": 18,
                "type": "function"
            },
            "starkware.cairo.common.math.assert_not_zero.Args": {
                "full_name": "starkware.cairo.common.math.assert_not_zero.Args",
                "members": {
                    "value": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.cairo.common.math.assert_not_zero.ImplicitArgs": {
                "full_name": "starkware.cairo.common.math.assert_not_zero.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "starkware.cairo.common.math.assert_not_zero.Return": {
                "cairo_type": "()",
                "type": "type_definition"
            },
            "starkware.cairo.common.math.assert_not_zero.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "starkware.cairo.common.math.assert_not_zero.value": {
                "cairo_type": "felt",
                "full_name": "starkware.cairo.common.math.assert_not_zero.value",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 2,
                            "offset": 0
                        },
                        "pc": 18,
                        "value": "[cast(fp + (-3), felt*)]"
                    }
                ],
                "type": "reference"
            },
            "starkware.cairo.common.math_cmp.RC_BOUND": {
                "type": "const",
                "value": 340282366920938463463374607431768211456
            },
            "starkware.cairo.common.math_cmp.assert_le_felt": {
                "destination": "starkware.cairo.common.math.assert_le_felt",
                "type": "alias"
            },
            "starkware.cairo.common.math_cmp.assert_lt_felt": {
                "destination": "starkware.cairo.common.math.assert_lt_felt",
                "type": "alias"
            },
            "starkware.cairo.common.math_cmp.is_le": {
                "decorators": [
                    "known_ap_change"
                ],
                "pc": 179,
                "type": "function"
            },
            "starkware.cairo.common.math_cmp.is_le.Args": {
                "full_name": "starkware.cairo.common.math_cmp.is_le.Args",
                "members": {
                    "a": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "b": {
                        "cairo_type": "felt",
                        "offset": 1
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "starkware.cairo.common.math_cmp.is_le.ImplicitArgs": {
                "full_name": "starkware.cairo.common.math_cmp.is_le.ImplicitArgs",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.cairo.common.math_cmp.is_le.Return": {
                "cairo_type": "felt",
                "type": "type_definition"
            },
            "starkware.cairo.common.math_cmp.is_le.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "starkware.cairo.common.math_cmp.is_le_felt": {
                "decorators": [
                    "known_ap_change"
                ],
                "pc": 184,
                "type": "function"
            },
            "starkware.cairo.common.math_cmp.is_le_felt.Args": {
                "full_name": "starkware.cairo.common.math_cmp.is_le_felt.Args",
                "members": {
                    "a": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "b": {
                        "cairo_type": "felt",
                        "offset": 1
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "starkware.cairo.common.math_cmp.is_le_felt.ImplicitArgs": {
                "full_name": "starkware.cairo.common.math_cmp.is_le_felt.ImplicitArgs",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.cairo.common.math_cmp.is_le_felt.Return": {
                "cairo_type": "felt",
                "type": "type_definition"
            },
            "starkware.cairo.common.math_cmp.is_le_felt.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "starkware.cairo.common.math_cmp.is_le_felt.a": {
                "cairo_type": "felt",
                "full_name": "starkware.cairo.common.math_cmp.is_le_felt.a",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 16,
                            "offset": 0
                        },
                        "pc": 184,
                        "value": "[cast(fp + (-4), felt*)]"
                    }
                ],
                "type": "reference"
            },
            "starkware.cairo.common.math_cmp.is_le_felt.b": {
                "cairo_type": "felt",
                "full_name": "starkware.cairo.common.math_cmp.is_le_felt.b",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 16,
                            "offset": 0
                        },
                        "pc": 184,
                        "value": "[cast(fp + (-3), felt*)]"
                    }
                ],
                "type": "reference"
            },
            "starkware.cairo.common.math_cmp.is_le_felt.not_le": {
                "pc": 196,
                "type": "label"
            },
            "starkware.cairo.common.math_cmp.is_nn": {
                "decorators": [
                    "known_ap_change"
                ],
                "pc": 146,
                "type": "function"
            },
            "starkware.cairo.common.math_cmp.is_nn.Args": {
                "full_name": "starkware.cairo.common.math_cmp.is_nn.Args",
                "members": {
                    "a": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.cairo.common.math_cmp.is_nn.ImplicitArgs": {
                "full_name": "starkware.cairo.common.math_cmp.is_nn.ImplicitArgs",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.cairo.common.math_cmp.is_nn.Return": {
                "cairo_type": "felt",
                "type": "type_definition"
            },
            "starkware.cairo.common.math_cmp.is_nn.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "starkware.cairo.common.math_cmp.is_nn.a": {
                "cairo_type": "felt",
                "full_name": "starkware.cairo.common.math_cmp.is_nn.a",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 14,
                            "offset": 0
                        },
                        "pc": 146,
                        "value": "[cast(fp + (-3), felt*)]"
                    }
                ],
                "type": "reference"
            },
            "starkware.cairo.common.math_cmp.is_nn.need_felt_comparison": {
                "pc": 170,
                "type": "label"
            },
            "starkware.cairo.common.math_cmp.is_nn.out_of_range": {
                "pc": 156,
                "type": "label"
            },
            "starkware.cairo.common.memcpy.memcpy": {
                "decorators": [],
                "pc": 3,
                "type": "function"
            },
            "starkware.cairo.common.memcpy.memcpy.Args": {
                "full_name": "starkware.cairo.common.memcpy.memcpy.Args",
                "members": {
                    "dst": {
                        "cairo_type": "felt*",
                        "offset": 0
                    },
                    "len": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "src": {
                        "cairo_type": "felt*",
                        "offset": 1
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "starkware.cairo.common.memcpy.memcpy.ImplicitArgs": {
                "full_name": "starkware.cairo.common.memcpy.memcpy.ImplicitArgs",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "starkware.cairo.common.memcpy.memcpy.LoopFrame": {
                "full_name": "starkware.cairo.common.memcpy.memcpy.LoopFrame",
                "members": {
                    "dst": {
                        "cairo_type": "felt*",
                        "offset": 0
                    },
                    "src": {
                        "cairo_type": "felt*",
                        "offset": 1
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "starkware.cairo.common.memcpy.memcpy.Return": {
                "cairo_type": "()",
                "type": "type_definition"
            },
            "starkware.cairo.common.memcpy.memcpy.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "starkware.cairo.common.memcpy.memcpy.continue_copying": {
                "cairo_type": "felt",
                "full_name": "starkware.cairo.common.memcpy.memcpy.continue_copying",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 1,
                            "offset": 3
                        },
                        "pc": 10,
                        "value": "[cast(ap, felt*)]"
                    }
                ],
                "type": "reference"
            },
            "starkware.cairo.common.memcpy.memcpy.len": {
                "cairo_type": "felt",
                "full_name": "starkware.cairo.common.memcpy.memcpy.len",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 1,
                            "offset": 0
                        },
                        "pc": 3,
                        "value": "[cast(fp + (-3), felt*)]"
                    }
                ],
                "type": "reference"
            },
            "starkware.cairo.common.memcpy.memcpy.loop": {
                "pc": 8,
                "type": "label"
            },
            "starkware.cairo.common.pow.assert_le": {
                "destination": "starkware.cairo.common.math.assert_le",
                "type": "alias"
            },
            "starkware.cairo.common.pow.get_ap": {
                "destination": "starkware.cairo.common.registers.get_ap",
                "type": "alias"
            },
            "starkware.cairo.common.pow.get_fp_and_pc": {
                "destination": "starkware.cairo.common.registers.get_fp_and_pc",
                "type": "alias"
            },
            "starkware.cairo.common.registers.get_ap": {
                "destination": "starkware.cairo.lang.compiler.lib.registers.get_ap",
                "type": "alias"
            },
            "starkware.cairo.common.registers.get_fp_and_pc": {
                "destination": "starkware.cairo.lang.compiler.lib.registers.get_fp_and_pc",
                "type": "alias"
            },
            "starkware.cairo.common.uint256.ALL_ONES": {
                "type": "const",
                "value": 340282366920938463463374607431768211455
            },
            "starkware.cairo.common.uint256.BitwiseBuiltin": {
                "destination": "starkware.cairo.common.cairo_builtins.BitwiseBuiltin",
                "type": "alias"
            },
            "starkware.cairo.common.uint256.HALF_SHIFT": {
                "type": "const",
                "value": 18446744073709551616
            },
            "starkware.cairo.common.uint256.SHIFT": {
                "type": "const",
                "value": 340282366920938463463374607431768211456
            },
            "starkware.cairo.common.uint256.Uint256": {
                "full_name": "starkware.cairo.common.uint256.Uint256",
                "members": {
                    "high": {
                        "cairo_type": "felt",
                        "offset": 1
                    },
                    "low": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "starkware.cairo.common.uint256.assert_in_range": {
                "destination": "starkware.cairo.common.math.assert_in_range",
                "type": "alias"
            },
            "starkware.cairo.common.uint256.assert_le": {
                "destination": "starkware.cairo.common.math.assert_le",
                "type": "alias"
            },
            "starkware.cairo.common.uint256.assert_nn_le": {
                "destination": "starkware.cairo.common.math.assert_nn_le",
                "type": "alias"
            },
            "starkware.cairo.common.uint256.assert_not_zero": {
                "destination": "starkware.cairo.common.math.assert_not_zero",
                "type": "alias"
            },
            "starkware.cairo.common.uint256.bitwise_and": {
                "destination": "starkware.cairo.common.bitwise.bitwise_and",
                "type": "alias"
            },
            "starkware.cairo.common.uint256.bitwise_or": {
                "destination": "starkware.cairo.common.bitwise.bitwise_or",
                "type": "alias"
            },
            "starkware.cairo.common.uint256.bitwise_xor": {
                "destination": "starkware.cairo.common.bitwise.bitwise_xor",
                "type": "alias"
            },
            "starkware.cairo.common.uint256.get_ap": {
                "destination": "starkware.cairo.common.registers.get_ap",
                "type": "alias"
            },
            "starkware.cairo.common.uint256.get_fp_and_pc": {
                "destination": "starkware.cairo.common.registers.get_fp_and_pc",
                "type": "alias"
            },
            "starkware.cairo.common.uint256.is_le": {
                "destination": "starkware.cairo.common.math_cmp.is_le",
                "type": "alias"
            },
            "starkware.cairo.common.uint256.pow": {
                "destination": "starkware.cairo.common.pow.pow",
                "type": "alias"
            },
            "starkware.cairo.common.uint256.split_64": {
                "decorators": [],
                "pc": 231,
                "type": "function"
            },
            "starkware.cairo.common.uint256.split_64.Args": {
                "full_name": "starkware.cairo.common.uint256.split_64.Args",
                "members": {
                    "a": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.cairo.common.uint256.split_64.ImplicitArgs": {
                "full_name": "starkware.cairo.common.uint256.split_64.ImplicitArgs",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.cairo.common.uint256.split_64.Return": {
                "cairo_type": "(low: felt, high: felt)",
                "type": "type_definition"
            },
            "starkware.cairo.common.uint256.split_64.SIZEOF_LOCALS": {
                "type": "const",
                "value": 2
            },
            "starkware.cairo.common.uint256.split_64.a": {
                "cairo_type": "felt",
                "full_name": "starkware.cairo.common.uint256.split_64.a",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 19,
                            "offset": 0
                        },
                        "pc": 231,
                        "value": "[cast(fp + (-3), felt*)]"
                    }
                ],
                "type": "reference"
            },
            "starkware.cairo.common.uint256.split_64.high": {
                "cairo_type": "felt",
                "full_name": "starkware.cairo.common.uint256.split_64.high",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 19,
                            "offset": 2
                        },
                        "pc": 233,
                        "value": "[cast(fp + 1, felt*)]"
                    }
                ],
                "type": "reference"
            },
            "starkware.cairo.common.uint256.split_64.low": {
                "cairo_type": "felt",
                "full_name": "starkware.cairo.common.uint256.split_64.low",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 19,
                            "offset": 2
                        },
                        "pc": 233,
                        "value": "[cast(fp, felt*)]"
                    }
                ],
                "type": "reference"
            },
            "starkware.cairo.common.uint256.uint256_add": {
                "decorators": [],
                "pc": 209,
                "type": "function"
            },
            "starkware.cairo.common.uint256.uint256_add.Args": {
                "full_name": "starkware.cairo.common.uint256.uint256_add.Args",
                "members": {
                    "a": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 0
                    },
                    "b": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 2
                    }
                },
                "size": 4,
                "type": "struct"
            },
            "starkware.cairo.common.uint256.uint256_add.ImplicitArgs": {
                "full_name": "starkware.cairo.common.uint256.uint256_add.ImplicitArgs",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.cairo.common.uint256.uint256_add.Return": {
                "cairo_type": "(res: starkware.cairo.common.uint256.Uint256, carry: felt)",
                "type": "type_definition"
            },
            "starkware.cairo.common.uint256.uint256_add.SIZEOF_LOCALS": {
                "type": "const",
                "value": 4
            },
            "starkware.cairo.common.uint256.uint256_add.a": {
                "cairo_type": "starkware.cairo.common.uint256.Uint256",
                "full_name": "starkware.cairo.common.uint256.uint256_add.a",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 18,
                            "offset": 0
                        },
                        "pc": 209,
                        "value": "[cast(fp + (-6), starkware.cairo.common.uint256.Uint256*)]"
                    }
                ],
                "type": "reference"
            },
            "starkware.cairo.common.uint256.uint256_add.b": {
                "cairo_type": "starkware.cairo.common.uint256.Uint256",
                "full_name": "starkware.cairo.common.uint256.uint256_add.b",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 18,
                            "offset": 0
                        },
                        "pc": 209,
                        "value": "[cast(fp + (-4), starkware.cairo.common.uint256.Uint256*)]"
                    }
                ],
                "type": "reference"
            },
            "starkware.cairo.common.uint256.uint256_add.carry_high": {
                "cairo_type": "felt",
                "full_name": "starkware.cairo.common.uint256.uint256_add.carry_high",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 18,
                            "offset": 4
                        },
                        "pc": 211,
                        "value": "[cast(fp + 3, felt*)]"
                    }
                ],
                "type": "reference"
            },
            "starkware.cairo.common.uint256.uint256_add.carry_low": {
                "cairo_type": "felt",
                "full_name": "starkware.cairo.common.uint256.uint256_add.carry_low",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 18,
                            "offset": 4
                        },
                        "pc": 211,
                        "value": "[cast(fp + 2, felt*)]"
                    }
                ],
                "type": "reference"
            },
            "starkware.cairo.common.uint256.uint256_check": {
                "decorators": [],
                "pc": 204,
                "type": "function"
            },
            "starkware.cairo.common.uint256.uint256_check.Args": {
                "full_name": "starkware.cairo.common.uint256.uint256_check.Args",
                "members": {
                    "a": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 0
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "starkware.cairo.common.uint256.uint256_check.ImplicitArgs": {
                "full_name": "starkware.cairo.common.uint256.uint256_check.ImplicitArgs",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.cairo.common.uint256.uint256_check.Return": {
                "cairo_type": "()",
                "type": "type_definition"
            },
            "starkware.cairo.common.uint256.uint256_check.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "starkware.cairo.common.uint256.uint256_eq": {
                "decorators": [],
                "pc": 463,
                "type": "function"
            },
            "starkware.cairo.common.uint256.uint256_eq.Args": {
                "full_name": "starkware.cairo.common.uint256.uint256_eq.Args",
                "members": {
                    "a": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 0
                    },
                    "b": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 2
                    }
                },
                "size": 4,
                "type": "struct"
            },
            "starkware.cairo.common.uint256.uint256_eq.ImplicitArgs": {
                "full_name": "starkware.cairo.common.uint256.uint256_eq.ImplicitArgs",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.cairo.common.uint256.uint256_eq.Return": {
                "cairo_type": "(res: felt)",
                "type": "type_definition"
            },
            "starkware.cairo.common.uint256.uint256_eq.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "starkware.cairo.common.uint256.uint256_le": {
                "decorators": [],
                "pc": 352,
                "type": "function"
            },
            "starkware.cairo.common.uint256.uint256_le.Args": {
                "full_name": "starkware.cairo.common.uint256.uint256_le.Args",
                "members": {
                    "a": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 0
                    },
                    "b": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 2
                    }
                },
                "size": 4,
                "type": "struct"
            },
            "starkware.cairo.common.uint256.uint256_le.ImplicitArgs": {
                "full_name": "starkware.cairo.common.uint256.uint256_le.ImplicitArgs",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.cairo.common.uint256.uint256_le.Return": {
                "cairo_type": "(res: felt)",
                "type": "type_definition"
            },
            "starkware.cairo.common.uint256.uint256_le.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "starkware.cairo.common.uint256.uint256_lt": {
                "decorators": [],
                "pc": 335,
                "type": "function"
            },
            "starkware.cairo.common.uint256.uint256_lt.Args": {
                "full_name": "starkware.cairo.common.uint256.uint256_lt.Args",
                "members": {
                    "a": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 0
                    },
                    "b": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 2
                    }
                },
                "size": 4,
                "type": "struct"
            },
            "starkware.cairo.common.uint256.uint256_lt.ImplicitArgs": {
                "full_name": "starkware.cairo.common.uint256.uint256_lt.ImplicitArgs",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.cairo.common.uint256.uint256_lt.Return": {
                "cairo_type": "(res: felt)",
                "type": "type_definition"
            },
            "starkware.cairo.common.uint256.uint256_lt.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "starkware.cairo.common.uint256.uint256_mul": {
                "decorators": [],
                "pc": 247,
                "type": "function"
            },
            "starkware.cairo.common.uint256.uint256_mul.Args": {
                "full_name": "starkware.cairo.common.uint256.uint256_mul.Args",
                "members": {
                    "a": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 0
                    },
                    "b": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 2
                    }
                },
                "size": 4,
                "type": "struct"
            },
            "starkware.cairo.common.uint256.uint256_mul.ImplicitArgs": {
                "full_name": "starkware.cairo.common.uint256.uint256_mul.ImplicitArgs",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.cairo.common.uint256.uint256_mul.Return": {
                "cairo_type": "(low: starkware.cairo.common.uint256.Uint256, high: starkware.cairo.common.uint256.Uint256)",
                "type": "type_definition"
            },
            "starkware.cairo.common.uint256.uint256_mul.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "starkware.cairo.common.uint256.uint256_neg": {
                "decorators": [],
                "pc": 432,
                "type": "function"
            },
            "starkware.cairo.common.uint256.uint256_neg.Args": {
                "full_name": "starkware.cairo.common.uint256.uint256_neg.Args",
                "members": {
                    "a": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 0
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "starkware.cairo.common.uint256.uint256_neg.ImplicitArgs": {
                "full_name": "starkware.cairo.common.uint256.uint256_neg.ImplicitArgs",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.cairo.common.uint256.uint256_neg.Return": {
                "cairo_type": "(res: starkware.cairo.common.uint256.Uint256)",
                "type": "type_definition"
            },
            "starkware.cairo.common.uint256.uint256_neg.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "starkware.cairo.common.uint256.uint256_not": {
                "decorators": [],
                "pc": 424,
                "type": "function"
            },
            "starkware.cairo.common.uint256.uint256_not.Args": {
                "full_name": "starkware.cairo.common.uint256.uint256_not.Args",
                "members": {
                    "a": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 0
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "starkware.cairo.common.uint256.uint256_not.ImplicitArgs": {
                "full_name": "starkware.cairo.common.uint256.uint256_not.ImplicitArgs",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.cairo.common.uint256.uint256_not.Return": {
                "cairo_type": "(res: starkware.cairo.common.uint256.Uint256)",
                "type": "type_definition"
            },
            "starkware.cairo.common.uint256.uint256_not.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "starkware.cairo.common.uint256.uint256_sub": {
                "decorators": [],
                "pc": 447,
                "type": "function"
            },
            "starkware.cairo.common.uint256.uint256_sub.Args": {
                "full_name": "starkware.cairo.common.uint256.uint256_sub.Args",
                "members": {
                    "a": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 0
                    },
                    "b": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 2
                    }
                },
                "size": 4,
                "type": "struct"
            },
            "starkware.cairo.common.uint256.uint256_sub.ImplicitArgs": {
                "full_name": "starkware.cairo.common.uint256.uint256_sub.ImplicitArgs",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.cairo.common.uint256.uint256_sub.Return": {
                "cairo_type": "(res: starkware.cairo.common.uint256.Uint256)",
                "type": "type_definition"
            },
            "starkware.cairo.common.uint256.uint256_sub.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "starkware.cairo.common.uint256.uint256_unsigned_div_rem": {
                "decorators": [],
                "pc": 364,
                "type": "function"
            },
            "starkware.cairo.common.uint256.uint256_unsigned_div_rem.Args": {
                "full_name": "starkware.cairo.common.uint256.uint256_unsigned_div_rem.Args",
                "members": {
                    "a": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 0
                    },
                    "div": {
                        "cairo_type": "starkware.cairo.common.uint256.Uint256",
                        "offset": 2
                    }
                },
                "size": 4,
                "type": "struct"
            },
            "starkware.cairo.common.uint256.uint256_unsigned_div_rem.ImplicitArgs": {
                "full_name": "starkware.cairo.common.uint256.uint256_unsigned_div_rem.ImplicitArgs",
                "members": {
                    "range_check_ptr": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.cairo.common.uint256.uint256_unsigned_div_rem.Return": {
                "cairo_type": "(quotient: starkware.cairo.common.uint256.Uint256, remainder: starkware.cairo.common.uint256.Uint256)",
                "type": "type_definition"
            },
            "starkware.cairo.common.uint256.uint256_unsigned_div_rem.SIZEOF_LOCALS": {
                "type": "const",
                "value": 4
            },
            "starkware.cairo.common.uint256.uint256_unsigned_div_rem.a": {
                "cairo_type": "starkware.cairo.common.uint256.Uint256",
                "full_name": "starkware.cairo.common.uint256.uint256_unsigned_div_rem.a",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 23,
                            "offset": 0
                        },
                        "pc": 364,
                        "value": "[cast(fp + (-6), starkware.cairo.common.uint256.Uint256*)]"
                    }
                ],
                "type": "reference"
            },
            "starkware.cairo.common.uint256.uint256_unsigned_div_rem.div": {
                "cairo_type": "starkware.cairo.common.uint256.Uint256",
                "full_name": "starkware.cairo.common.uint256.uint256_unsigned_div_rem.div",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 23,
                            "offset": 0
                        },
                        "pc": 364,
                        "value": "[cast(fp + (-4), starkware.cairo.common.uint256.Uint256*)]"
                    }
                ],
                "type": "reference"
            },
            "starkware.cairo.common.uint256.uint256_unsigned_div_rem.quotient": {
                "cairo_type": "starkware.cairo.common.uint256.Uint256",
                "full_name": "starkware.cairo.common.uint256.uint256_unsigned_div_rem.quotient",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 23,
                            "offset": 5
                        },
                        "pc": 379,
                        "value": "[cast(fp, starkware.cairo.common.uint256.Uint256*)]"
                    }
                ],
                "type": "reference"
            },
            "starkware.cairo.common.uint256.uint256_unsigned_div_rem.remainder": {
                "cairo_type": "starkware.cairo.common.uint256.Uint256",
                "full_name": "starkware.cairo.common.uint256.uint256_unsigned_div_rem.remainder",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 23,
                            "offset": 5
                        },
                        "pc": 379,
                        "value": "[cast(fp + 2, starkware.cairo.common.uint256.Uint256*)]"
                    }
                ],
                "type": "reference"
            },
            "starkware.starknet.common.storage.ADDR_BOUND": {
                "type": "const",
                "value": -106710729501573572985208420194530329073740042555888586719489
            },
            "starkware.starknet.common.storage.MAX_STORAGE_ITEM_SIZE": {
                "type": "const",
                "value": 256
            },
            "starkware.starknet.common.storage.assert_250_bit": {
                "destination": "starkware.cairo.common.math.assert_250_bit",
                "type": "alias"
            },
            "starkware.starknet.common.syscalls.CALL_CONTRACT_SELECTOR": {
                "type": "const",
                "value": 20853273475220472486191784820
            },
            "starkware.starknet.common.syscalls.CallContract": {
                "full_name": "starkware.starknet.common.syscalls.CallContract",
                "members": {
                    "request": {
                        "cairo_type": "starkware.starknet.common.syscalls.CallContractRequest",
                        "offset": 0
                    },
                    "response": {
                        "cairo_type": "starkware.starknet.common.syscalls.CallContractResponse",
                        "offset": 5
                    }
                },
                "size": 7,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.CallContractRequest": {
                "full_name": "starkware.starknet.common.syscalls.CallContractRequest",
                "members": {
                    "calldata": {
                        "cairo_type": "felt*",
                        "offset": 4
                    },
                    "calldata_size": {
                        "cairo_type": "felt",
                        "offset": 3
                    },
                    "contract_address": {
                        "cairo_type": "felt",
                        "offset": 1
                    },
                    "function_selector": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "selector": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 5,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.CallContractResponse": {
                "full_name": "starkware.starknet.common.syscalls.CallContractResponse",
                "members": {
                    "retdata": {
                        "cairo_type": "felt*",
                        "offset": 1
                    },
                    "retdata_size": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.DELEGATE_CALL_SELECTOR": {
                "type": "const",
                "value": 21167594061783206823196716140
            },
            "starkware.starknet.common.syscalls.DELEGATE_L1_HANDLER_SELECTOR": {
                "type": "const",
                "value": 23274015802972845247556842986379118667122
            },
            "starkware.starknet.common.syscalls.DEPLOY_SELECTOR": {
                "type": "const",
                "value": 75202468540281
            },
            "starkware.starknet.common.syscalls.Deploy": {
                "full_name": "starkware.starknet.common.syscalls.Deploy",
                "members": {
                    "request": {
                        "cairo_type": "starkware.starknet.common.syscalls.DeployRequest",
                        "offset": 0
                    },
                    "response": {
                        "cairo_type": "starkware.starknet.common.syscalls.DeployResponse",
                        "offset": 6
                    }
                },
                "size": 9,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.DeployRequest": {
                "full_name": "starkware.starknet.common.syscalls.DeployRequest",
                "members": {
                    "class_hash": {
                        "cairo_type": "felt",
                        "offset": 1
                    },
                    "constructor_calldata": {
                        "cairo_type": "felt*",
                        "offset": 4
                    },
                    "constructor_calldata_size": {
                        "cairo_type": "felt",
                        "offset": 3
                    },
                    "contract_address_salt": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "deploy_from_zero": {
                        "cairo_type": "felt",
                        "offset": 5
                    },
                    "selector": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 6,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.DeployResponse": {
                "full_name": "starkware.starknet.common.syscalls.DeployResponse",
                "members": {
                    "constructor_retdata": {
                        "cairo_type": "felt*",
                        "offset": 2
                    },
                    "constructor_retdata_size": {
                        "cairo_type": "felt",
                        "offset": 1
                    },
                    "contract_address": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.DictAccess": {
                "destination": "starkware.cairo.common.dict_access.DictAccess",
                "type": "alias"
            },
            "starkware.starknet.common.syscalls.EMIT_EVENT_SELECTOR": {
                "type": "const",
                "value": 1280709301550335749748
            },
            "starkware.starknet.common.syscalls.EmitEvent": {
                "full_name": "starkware.starknet.common.syscalls.EmitEvent",
                "members": {
                    "data": {
                        "cairo_type": "felt*",
                        "offset": 4
                    },
                    "data_len": {
                        "cairo_type": "felt",
                        "offset": 3
                    },
                    "keys": {
                        "cairo_type": "felt*",
                        "offset": 2
                    },
                    "keys_len": {
                        "cairo_type": "felt",
                        "offset": 1
                    },
                    "selector": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 5,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.GET_BLOCK_NUMBER_SELECTOR": {
                "type": "const",
                "value": 1448089106835523001438702345020786
            },
            "starkware.starknet.common.syscalls.GET_BLOCK_TIMESTAMP_SELECTOR": {
                "type": "const",
                "value": 24294903732626645868215235778792757751152
            },
            "starkware.starknet.common.syscalls.GET_CALLER_ADDRESS_SELECTOR": {
                "type": "const",
                "value": 94901967781393078444254803017658102643
            },
            "starkware.starknet.common.syscalls.GET_CONTRACT_ADDRESS_SELECTOR": {
                "type": "const",
                "value": 6219495360805491471215297013070624192820083
            },
            "starkware.starknet.common.syscalls.GET_SEQUENCER_ADDRESS_SELECTOR": {
                "type": "const",
                "value": 1592190833581991703053805829594610833820054387
            },
            "starkware.starknet.common.syscalls.GET_TX_INFO_SELECTOR": {
                "type": "const",
                "value": 1317029390204112103023
            },
            "starkware.starknet.common.syscalls.GET_TX_SIGNATURE_SELECTOR": {
                "type": "const",
                "value": 1448089128652340074717162277007973
            },
            "starkware.starknet.common.syscalls.GetBlockNumber": {
                "full_name": "starkware.starknet.common.syscalls.GetBlockNumber",
                "members": {
                    "request": {
                        "cairo_type": "starkware.starknet.common.syscalls.GetBlockNumberRequest",
                        "offset": 0
                    },
                    "response": {
                        "cairo_type": "starkware.starknet.common.syscalls.GetBlockNumberResponse",
                        "offset": 1
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.GetBlockNumberRequest": {
                "full_name": "starkware.starknet.common.syscalls.GetBlockNumberRequest",
                "members": {
                    "selector": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.GetBlockNumberResponse": {
                "full_name": "starkware.starknet.common.syscalls.GetBlockNumberResponse",
                "members": {
                    "block_number": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.GetBlockTimestamp": {
                "full_name": "starkware.starknet.common.syscalls.GetBlockTimestamp",
                "members": {
                    "request": {
                        "cairo_type": "starkware.starknet.common.syscalls.GetBlockTimestampRequest",
                        "offset": 0
                    },
                    "response": {
                        "cairo_type": "starkware.starknet.common.syscalls.GetBlockTimestampResponse",
                        "offset": 1
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.GetBlockTimestampRequest": {
                "full_name": "starkware.starknet.common.syscalls.GetBlockTimestampRequest",
                "members": {
                    "selector": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.GetBlockTimestampResponse": {
                "full_name": "starkware.starknet.common.syscalls.GetBlockTimestampResponse",
                "members": {
                    "block_timestamp": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.GetCallerAddress": {
                "full_name": "starkware.starknet.common.syscalls.GetCallerAddress",
                "members": {
                    "request": {
                        "cairo_type": "starkware.starknet.common.syscalls.GetCallerAddressRequest",
                        "offset": 0
                    },
                    "response": {
                        "cairo_type": "starkware.starknet.common.syscalls.GetCallerAddressResponse",
                        "offset": 1
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.GetCallerAddressRequest": {
                "full_name": "starkware.starknet.common.syscalls.GetCallerAddressRequest",
                "members": {
                    "selector": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.GetCallerAddressResponse": {
                "full_name": "starkware.starknet.common.syscalls.GetCallerAddressResponse",
                "members": {
                    "caller_address": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.GetContractAddress": {
                "full_name": "starkware.starknet.common.syscalls.GetContractAddress",
                "members": {
                    "request": {
                        "cairo_type": "starkware.starknet.common.syscalls.GetContractAddressRequest",
                        "offset": 0
                    },
                    "response": {
                        "cairo_type": "starkware.starknet.common.syscalls.GetContractAddressResponse",
                        "offset": 1
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.GetContractAddressRequest": {
                "full_name": "starkware.starknet.common.syscalls.GetContractAddressRequest",
                "members": {
                    "selector": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.GetContractAddressResponse": {
                "full_name": "starkware.starknet.common.syscalls.GetContractAddressResponse",
                "members": {
                    "contract_address": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.GetSequencerAddress": {
                "full_name": "starkware.starknet.common.syscalls.GetSequencerAddress",
                "members": {
                    "request": {
                        "cairo_type": "starkware.starknet.common.syscalls.GetSequencerAddressRequest",
                        "offset": 0
                    },
                    "response": {
                        "cairo_type": "starkware.starknet.common.syscalls.GetSequencerAddressResponse",
                        "offset": 1
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.GetSequencerAddressRequest": {
                "full_name": "starkware.starknet.common.syscalls.GetSequencerAddressRequest",
                "members": {
                    "selector": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.GetSequencerAddressResponse": {
                "full_name": "starkware.starknet.common.syscalls.GetSequencerAddressResponse",
                "members": {
                    "sequencer_address": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.GetTxInfo": {
                "full_name": "starkware.starknet.common.syscalls.GetTxInfo",
                "members": {
                    "request": {
                        "cairo_type": "starkware.starknet.common.syscalls.GetTxInfoRequest",
                        "offset": 0
                    },
                    "response": {
                        "cairo_type": "starkware.starknet.common.syscalls.GetTxInfoResponse",
                        "offset": 1
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.GetTxInfoRequest": {
                "full_name": "starkware.starknet.common.syscalls.GetTxInfoRequest",
                "members": {
                    "selector": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.GetTxInfoResponse": {
                "full_name": "starkware.starknet.common.syscalls.GetTxInfoResponse",
                "members": {
                    "tx_info": {
                        "cairo_type": "starkware.starknet.common.syscalls.TxInfo*",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.GetTxSignature": {
                "full_name": "starkware.starknet.common.syscalls.GetTxSignature",
                "members": {
                    "request": {
                        "cairo_type": "starkware.starknet.common.syscalls.GetTxSignatureRequest",
                        "offset": 0
                    },
                    "response": {
                        "cairo_type": "starkware.starknet.common.syscalls.GetTxSignatureResponse",
                        "offset": 1
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.GetTxSignatureRequest": {
                "full_name": "starkware.starknet.common.syscalls.GetTxSignatureRequest",
                "members": {
                    "selector": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.GetTxSignatureResponse": {
                "full_name": "starkware.starknet.common.syscalls.GetTxSignatureResponse",
                "members": {
                    "signature": {
                        "cairo_type": "felt*",
                        "offset": 1
                    },
                    "signature_len": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.LIBRARY_CALL_L1_HANDLER_SELECTOR": {
                "type": "const",
                "value": 436233452754198157705746250789557519228244616562
            },
            "starkware.starknet.common.syscalls.LIBRARY_CALL_SELECTOR": {
                "type": "const",
                "value": 92376026794327011772951660
            },
            "starkware.starknet.common.syscalls.LibraryCall": {
                "full_name": "starkware.starknet.common.syscalls.LibraryCall",
                "members": {
                    "request": {
                        "cairo_type": "starkware.starknet.common.syscalls.LibraryCallRequest",
                        "offset": 0
                    },
                    "response": {
                        "cairo_type": "starkware.starknet.common.syscalls.CallContractResponse",
                        "offset": 5
                    }
                },
                "size": 7,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.LibraryCallRequest": {
                "full_name": "starkware.starknet.common.syscalls.LibraryCallRequest",
                "members": {
                    "calldata": {
                        "cairo_type": "felt*",
                        "offset": 4
                    },
                    "calldata_size": {
                        "cairo_type": "felt",
                        "offset": 3
                    },
                    "class_hash": {
                        "cairo_type": "felt",
                        "offset": 1
                    },
                    "function_selector": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "selector": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 5,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.SEND_MESSAGE_TO_L1_SELECTOR": {
                "type": "const",
                "value": 433017908768303439907196859243777073
            },
            "starkware.starknet.common.syscalls.STORAGE_READ_SELECTOR": {
                "type": "const",
                "value": 100890693370601760042082660
            },
            "starkware.starknet.common.syscalls.STORAGE_WRITE_SELECTOR": {
                "type": "const",
                "value": 25828017502874050592466629733
            },
            "starkware.starknet.common.syscalls.SendMessageToL1SysCall": {
                "full_name": "starkware.starknet.common.syscalls.SendMessageToL1SysCall",
                "members": {
                    "payload_ptr": {
                        "cairo_type": "felt*",
                        "offset": 3
                    },
                    "payload_size": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "selector": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "to_address": {
                        "cairo_type": "felt",
                        "offset": 1
                    }
                },
                "size": 4,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.StorageRead": {
                "full_name": "starkware.starknet.common.syscalls.StorageRead",
                "members": {
                    "request": {
                        "cairo_type": "starkware.starknet.common.syscalls.StorageReadRequest",
                        "offset": 0
                    },
                    "response": {
                        "cairo_type": "starkware.starknet.common.syscalls.StorageReadResponse",
                        "offset": 2
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.StorageReadRequest": {
                "full_name": "starkware.starknet.common.syscalls.StorageReadRequest",
                "members": {
                    "address": {
                        "cairo_type": "felt",
                        "offset": 1
                    },
                    "selector": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.StorageReadResponse": {
                "full_name": "starkware.starknet.common.syscalls.StorageReadResponse",
                "members": {
                    "value": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.StorageWrite": {
                "full_name": "starkware.starknet.common.syscalls.StorageWrite",
                "members": {
                    "address": {
                        "cairo_type": "felt",
                        "offset": 1
                    },
                    "selector": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "value": {
                        "cairo_type": "felt",
                        "offset": 2
                    }
                },
                "size": 3,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.TxInfo": {
                "full_name": "starkware.starknet.common.syscalls.TxInfo",
                "members": {
                    "account_contract_address": {
                        "cairo_type": "felt",
                        "offset": 1
                    },
                    "chain_id": {
                        "cairo_type": "felt",
                        "offset": 6
                    },
                    "max_fee": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "nonce": {
                        "cairo_type": "felt",
                        "offset": 7
                    },
                    "signature": {
                        "cairo_type": "felt*",
                        "offset": 4
                    },
                    "signature_len": {
                        "cairo_type": "felt",
                        "offset": 3
                    },
                    "transaction_hash": {
                        "cairo_type": "felt",
                        "offset": 5
                    },
                    "version": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 8,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.call_contract": {
                "decorators": [],
                "pc": 94,
                "type": "function"
            },
            "starkware.starknet.common.syscalls.call_contract.Args": {
                "full_name": "starkware.starknet.common.syscalls.call_contract.Args",
                "members": {
                    "calldata": {
                        "cairo_type": "felt*",
                        "offset": 3
                    },
                    "calldata_size": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "contract_address": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "function_selector": {
                        "cairo_type": "felt",
                        "offset": 1
                    }
                },
                "size": 4,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.call_contract.ImplicitArgs": {
                "full_name": "starkware.starknet.common.syscalls.call_contract.ImplicitArgs",
                "members": {
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.call_contract.Return": {
                "cairo_type": "(retdata_size: felt, retdata: felt*)",
                "type": "type_definition"
            },
            "starkware.starknet.common.syscalls.call_contract.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "starkware.starknet.common.syscalls.call_contract.syscall_ptr": {
                "cairo_type": "felt*",
                "full_name": "starkware.starknet.common.syscalls.call_contract.syscall_ptr",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 8,
                            "offset": 0
                        },
                        "pc": 94,
                        "value": "[cast(fp + (-7), felt**)]"
                    },
                    {
                        "ap_tracking_data": {
                            "group": 8,
                            "offset": 1
                        },
                        "pc": 101,
                        "value": "cast([fp + (-7)] + 7, felt*)"
                    }
                ],
                "type": "reference"
            },
            "starkware.starknet.common.syscalls.emit_event": {
                "decorators": [],
                "pc": 136,
                "type": "function"
            },
            "starkware.starknet.common.syscalls.emit_event.Args": {
                "full_name": "starkware.starknet.common.syscalls.emit_event.Args",
                "members": {
                    "data": {
                        "cairo_type": "felt*",
                        "offset": 3
                    },
                    "data_len": {
                        "cairo_type": "felt",
                        "offset": 2
                    },
                    "keys": {
                        "cairo_type": "felt*",
                        "offset": 1
                    },
                    "keys_len": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 4,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.emit_event.ImplicitArgs": {
                "full_name": "starkware.starknet.common.syscalls.emit_event.ImplicitArgs",
                "members": {
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.emit_event.Return": {
                "cairo_type": "()",
                "type": "type_definition"
            },
            "starkware.starknet.common.syscalls.emit_event.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "starkware.starknet.common.syscalls.emit_event.syscall_ptr": {
                "cairo_type": "felt*",
                "full_name": "starkware.starknet.common.syscalls.emit_event.syscall_ptr",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 13,
                            "offset": 0
                        },
                        "pc": 136,
                        "value": "[cast(fp + (-7), felt**)]"
                    },
                    {
                        "ap_tracking_data": {
                            "group": 13,
                            "offset": 1
                        },
                        "pc": 143,
                        "value": "cast([fp + (-7)] + 5, felt*)"
                    }
                ],
                "type": "reference"
            },
            "starkware.starknet.common.syscalls.get_block_timestamp": {
                "decorators": [],
                "pc": 113,
                "type": "function"
            },
            "starkware.starknet.common.syscalls.get_block_timestamp.Args": {
                "full_name": "starkware.starknet.common.syscalls.get_block_timestamp.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.get_block_timestamp.ImplicitArgs": {
                "full_name": "starkware.starknet.common.syscalls.get_block_timestamp.ImplicitArgs",
                "members": {
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.get_block_timestamp.Return": {
                "cairo_type": "(block_timestamp: felt)",
                "type": "type_definition"
            },
            "starkware.starknet.common.syscalls.get_block_timestamp.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "starkware.starknet.common.syscalls.get_block_timestamp.syscall_ptr": {
                "cairo_type": "felt*",
                "full_name": "starkware.starknet.common.syscalls.get_block_timestamp.syscall_ptr",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 10,
                            "offset": 0
                        },
                        "pc": 113,
                        "value": "[cast(fp + (-3), felt**)]"
                    },
                    {
                        "ap_tracking_data": {
                            "group": 10,
                            "offset": 1
                        },
                        "pc": 116,
                        "value": "cast([fp + (-3)] + 2, felt*)"
                    }
                ],
                "type": "reference"
            },
            "starkware.starknet.common.syscalls.get_caller_address": {
                "decorators": [],
                "pc": 106,
                "type": "function"
            },
            "starkware.starknet.common.syscalls.get_caller_address.Args": {
                "full_name": "starkware.starknet.common.syscalls.get_caller_address.Args",
                "members": {},
                "size": 0,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.get_caller_address.ImplicitArgs": {
                "full_name": "starkware.starknet.common.syscalls.get_caller_address.ImplicitArgs",
                "members": {
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.get_caller_address.Return": {
                "cairo_type": "(caller_address: felt)",
                "type": "type_definition"
            },
            "starkware.starknet.common.syscalls.get_caller_address.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "starkware.starknet.common.syscalls.get_caller_address.syscall_ptr": {
                "cairo_type": "felt*",
                "full_name": "starkware.starknet.common.syscalls.get_caller_address.syscall_ptr",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 9,
                            "offset": 0
                        },
                        "pc": 106,
                        "value": "[cast(fp + (-3), felt**)]"
                    },
                    {
                        "ap_tracking_data": {
                            "group": 9,
                            "offset": 1
                        },
                        "pc": 109,
                        "value": "cast([fp + (-3)] + 2, felt*)"
                    }
                ],
                "type": "reference"
            },
            "starkware.starknet.common.syscalls.storage_read": {
                "decorators": [],
                "pc": 120,
                "type": "function"
            },
            "starkware.starknet.common.syscalls.storage_read.Args": {
                "full_name": "starkware.starknet.common.syscalls.storage_read.Args",
                "members": {
                    "address": {
                        "cairo_type": "felt",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.storage_read.ImplicitArgs": {
                "full_name": "starkware.starknet.common.syscalls.storage_read.ImplicitArgs",
                "members": {
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.storage_read.Return": {
                "cairo_type": "(value: felt)",
                "type": "type_definition"
            },
            "starkware.starknet.common.syscalls.storage_read.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "starkware.starknet.common.syscalls.storage_read.syscall_ptr": {
                "cairo_type": "felt*",
                "full_name": "starkware.starknet.common.syscalls.storage_read.syscall_ptr",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 11,
                            "offset": 0
                        },
                        "pc": 120,
                        "value": "[cast(fp + (-4), felt**)]"
                    },
                    {
                        "ap_tracking_data": {
                            "group": 11,
                            "offset": 1
                        },
                        "pc": 124,
                        "value": "cast([fp + (-4)] + 3, felt*)"
                    }
                ],
                "type": "reference"
            },
            "starkware.starknet.common.syscalls.storage_write": {
                "decorators": [],
                "pc": 128,
                "type": "function"
            },
            "starkware.starknet.common.syscalls.storage_write.Args": {
                "full_name": "starkware.starknet.common.syscalls.storage_write.Args",
                "members": {
                    "address": {
                        "cairo_type": "felt",
                        "offset": 0
                    },
                    "value": {
                        "cairo_type": "felt",
                        "offset": 1
                    }
                },
                "size": 2,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.storage_write.ImplicitArgs": {
                "full_name": "starkware.starknet.common.syscalls.storage_write.ImplicitArgs",
                "members": {
                    "syscall_ptr": {
                        "cairo_type": "felt*",
                        "offset": 0
                    }
                },
                "size": 1,
                "type": "struct"
            },
            "starkware.starknet.common.syscalls.storage_write.Return": {
                "cairo_type": "()",
                "type": "type_definition"
            },
            "starkware.starknet.common.syscalls.storage_write.SIZEOF_LOCALS": {
                "type": "const",
                "value": 0
            },
            "starkware.starknet.common.syscalls.storage_write.syscall_ptr": {
                "cairo_type": "felt*",
                "full_name": "starkware.starknet.common.syscalls.storage_write.syscall_ptr",
                "references": [
                    {
                        "ap_tracking_data": {
                            "group": 12,
                            "offset": 0
                        },
                        "pc": 128,
                        "value": "[cast(fp + (-5), felt**)]"
                    },
                    {
                        "ap_tracking_data": {
                            "group": 12,
                            "offset": 1
                        },
                        "pc": 133,
                        "value": "cast([fp + (-5)] + 3, felt*)"
                    }
                ],
                "type": "reference"
            }
        },
        "main_scope": "__main__",
        "prime": "0x800000000000011000000000000000000000000000000000000000000000001",
        "reference_manager": {
            "references": [
                {
                    "ap_tracking_data": {
                        "group": 1,
                        "offset": 0
                    },
                    "pc": 3,
                    "value": "[cast(fp + (-3), felt*)]"
                },
                {
                    "ap_tracking_data": {
                        "group": 1,
                        "offset": 3
                    },
                    "pc": 10,
                    "value": "[cast(ap, felt*)]"
                },
                {
                    "ap_tracking_data": {
                        "group": 2,
                        "offset": 0
                    },
                    "pc": 18,
                    "value": "[cast(fp + (-3), felt*)]"
                },
                {
                    "ap_tracking_data": {
                        "group": 3,
                        "offset": 0
                    },
                    "pc": 23,
                    "value": "[cast(fp + (-4), felt*)]"
                },
                {
                    "ap_tracking_data": {
                        "group": 3,
                        "offset": 0
                    },
                    "pc": 23,
                    "value": "[cast(fp + (-3), felt*)]"
                },
                {
                    "ap_tracking_data": {
                        "group": 4,
                        "offset": 0
                    },
                    "pc": 29,
                    "value": "[cast(fp + (-3), felt*)]"
                },
                {
                    "ap_tracking_data": {
                        "group": 6,
                        "offset": 0
                    },
                    "pc": 38,
                    "value": "[cast(fp + (-4), felt*)]"
                },
                {
                    "ap_tracking_data": {
                        "group": 6,
                        "offset": 0
                    },
                    "pc": 38,
                    "value": "[cast(fp + (-3), felt*)]"
                },
                {
                    "ap_tracking_data": {
                        "group": 6,
                        "offset": 0
                    },
                    "pc": 38,
                    "value": "[cast(fp + (-5), felt*)]"
                },
                {
                    "ap_tracking_data": {
                        "group": 7,
                        "offset": 0
                    },
                    "pc": 83,
                    "value": "[cast(fp + (-4), felt*)]"
                },
                {
                    "ap_tracking_data": {
                        "group": 7,
                        "offset": 0
                    },
                    "pc": 83,
                    "value": "[cast(fp + (-3), felt*)]"
                },
                {
                    "ap_tracking_data": {
                        "group": 8,
                        "offset": 0
                    },
                    "pc": 94,
                    "value": "[cast(fp + (-7), felt**)]"
                },
                {
                    "ap_tracking_data": {
                        "group": 9,
                        "offset": 0
                    },
                    "pc": 106,
                    "value": "[cast(fp + (-3), felt**)]"
                },
                {
                    "ap_tracking_data": {
                        "group": 10,
                        "offset": 0
                    },
                    "pc": 113,
                    "value": "[cast(fp + (-3), felt**)]"
                },
                {
                    "ap_tracking_data": {
                        "group": 11,
                        "offset": 0
                    },
                    "pc": 120,
                    "value": "[cast(fp + (-4), felt**)]"
                },
                {
                    "ap_tracking_data": {
                        "group": 12,
                        "offset": 0
                    },
                    "pc": 128,
                    "value": "[cast(fp + (-5), felt**)]"
                },
                {
                    "ap_tracking_data": {
                        "group": 13,
                        "offset": 0
                    },
                    "pc": 136,
                    "value": "[cast(fp + (-7), felt**)]"
                },
                {
                    "ap_tracking_data": {
                        "group": 14,
                        "offset": 0
                    },
                    "pc": 146,
                    "value": "[cast(fp + (-3), felt*)]"
                },
                {
                    "ap_tracking_data": {
                        "group": 16,
                        "offset": 0
                    },
                    "pc": 184,
                    "value": "[cast(fp + (-4), felt*)]"
                },
                {
                    "ap_tracking_data": {
                        "group": 16,
                        "offset": 0
                    },
                    "pc": 184,
                    "value": "[cast(fp + (-3), felt*)]"
                },
                {
                    "ap_tracking_data": {
                        "group": 18,
                        "offset": 0
                    },
                    "pc": 209,
                    "value": "[cast(fp + (-6), starkware.cairo.common.uint256.Uint256*)]"
                },
                {
                    "ap_tracking_data": {
                        "group": 18,
                        "offset": 0
                    },
                    "pc": 209,
                    "value": "[cast(fp + (-4), starkware.cairo.common.uint256.Uint256*)]"
                },
                {
                    "ap_tracking_data": {
                        "group": 18,
                        "offset": 4
                    },
                    "pc": 211,
                    "value": "[cast(fp + 2, felt*)]"
                },
                {
                    "ap_tracking_data": {
                        "group": 18,
                        "offset": 4
                    },
                    "pc": 211,
                    "value": "[cast(fp + 3, felt*)]"
                },
                {
                    "ap_tracking_data": {
                        "group": 19,
                        "offset": 0
                    },
                    "pc": 231,
                    "value": "[cast(fp + (-3), felt*)]"
                },
                {
                    "ap_tracking_data": {
                        "group": 19,
                        "offset": 2
                    },
                    "pc": 233,
                    "value": "[cast(fp, felt*)]"
                },
                {
                    "ap_tracking_data": {
                        "group": 19,
                        "offset": 2
                    },
                    "pc": 233,
                    "value": "[cast(fp + 1, felt*)]"
                },
                {
                    "ap_tracking_data": {
                        "group": 23,
                        "offset": 0
                    },
                    "pc": 364,
                    "value": "[cast(fp + (-6), starkware.cairo.common.uint256.Uint256*)]"
                },
                {
                    "ap_tracking_data": {
                        "group": 23,
                        "offset": 0
                    },
                    "pc": 364,
                    "value": "[cast(fp + (-4), starkware.cairo.common.uint256.Uint256*)]"
                },
                {
                    "ap_tracking_data": {
                        "group": 23,
                        "offset": 5
                    },
                    "pc": 379,
                    "value": "[cast(fp, starkware.cairo.common.uint256.Uint256*)]"
                },
                {
                    "ap_tracking_data": {
                        "group": 23,
                        "offset": 5
                    },
                    "pc": 379,
                    "value": "[cast(fp + 2, starkware.cairo.common.uint256.Uint256*)]"
                }
            ]
        }
    }
}

export default router;