import './style.scss';

const ComingSoonPage = () => {
    return (
        <div className="coming-soon-page flex flex-1 center p-20">
            <h3>Coming soon .......</h3>
        </div>
    );
};

export default ComingSoonPage;
