const DUMMY_DATA = {
    data: [
        {
            uv: 1,
        },
        {
            uv: 3,
        },
        {
            uv: 1,
        },
        {
            uv: 5,
        },
        {
            uv: 9,
        },
        {
            uv: 2,
        },
        {
            uv: 10,
        },
    ],
};

export default DUMMY_DATA;
